import { ReactElement, useCallback } from 'react';

import { AxiosResultDefaultError } from '../../../api/request';
import { AuthenticationError } from '../../../api/requests/tuitionProcess';
import { createFetchContext } from '../../../components/contexts/FetchContextFactory';
import DisplayError from '../../../components/info/DisplayError';
import Loading from '../../../components/info/Loading';
import { getPostulationDocumentsReview } from '../api';
import { PostulationDocumentationReview } from '../types';

export interface PostulationDocumentsReviewLoaderProps<T> {
  postulationDetailId: number;
  children: (props: { data: T; refresh: () => Promise<void> }) => ReactElement;
}

const { FetchProvider, FetchConsumer } = createFetchContext<
  undefined,
  PostulationDocumentationReview,
  AuthenticationError | AxiosResultDefaultError
>();

export const PostulationDocumentsReviewLoaderConsumer = FetchConsumer;

export default function PostulationDocumentsReviewLoader({
  postulationDetailId,
  children,
}: PostulationDocumentsReviewLoaderProps<PostulationDocumentationReview>) {
  const request = useCallback(() => {
    return getPostulationDocumentsReview(postulationDetailId);
  }, [postulationDetailId]);

  return (
    <FetchProvider request={request} defaultQuery={undefined} fetchImmediately>
      <FetchConsumer>
        {({ data, loading, error, refresh }) => {
          if (error) {
            return (
              <DisplayError
                insideCard
                textBody={error.code}
                retryAction={refresh}
                loadingAction={loading}
              />
            );
          }

          if (loading) {
            return <Loading insideCard />;
          }

          if (!data) {
            return (
              <DisplayError
                insideCard
                textBody="Data no cargada"
                retryAction={refresh}
                loadingAction={loading}
              />
            );
          }

          return children({ data, refresh });
        }}
      </FetchConsumer>
    </FetchProvider>
  );
}
