import { Select, addToast } from '@octano/global-ui';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import { Card } from 'reactstrap';

import {
  getCurrentPeriodAndFaculties,
  getStudyPlansByFaculty,
  processGraduatesByStudyPlan,
} from '../../api/requests/graduationProcessMassive';
import { PathsLayouts } from '../../config/routes';
import { CurrentPeriodAndFacultiesResponse } from '../../types/graduationProcessMassive';
import {
  ExecutedProcessesHistoryTable,
  FailedProcessingGraduatesModal,
  ProcessingGraduatesModal,
  TableRowData,
} from './parts';

const dictPrefix = 'graduationProcessMassive';

type FacultyOption = {
  label: string;
  value: CurrentPeriodAndFacultiesResponse['schools'][number]['id'];
};

type CurrentPeriod = CurrentPeriodAndFacultiesResponse['currentPeriod'];

export default function GraduationProcessMasive() {
  const { t } = useTranslation();
  const history = useHistory();

  const { state: routerState } = useLocation<{ facultyId: number }>();
  const [
    initializedFacultyWithRouter,
    setInitializedFacultyWithRouter,
  ] = useState(false);

  const [currentPeriod, setCurrentPeriod] = useState<CurrentPeriod>();
  const [facultiesOptions, setFacultiesOptions] = useState<FacultyOption[]>([]);
  const [loadingFacultiesAndPeriod, setLoadingFacultiesAndPeriod] = useState(
    false,
  );
  const [selectedFaculty, setSelectedFaculty] = useState<FacultyOption>();

  const [tableData, setTableData] = useState<TableRowData[]>([]);
  const [loadingTableData, setLoadingTableData] = useState(false);
  const [showNoResults, setShowNoResults] = useState(false);
  const [showTableError, setShowTableError] = useState(false);

  const [processingGraduates, setProcessingGraduates] = useState(false);
  const [showFailedProcessing, setShowFailedProcessing] = useState(false);

  // Actualiza la info de la tabla en funcion de la facultad seleccionada
  const handleFacultySelection = async (faculty: FacultyOption) => {
    setSelectedFaculty(faculty);

    if (!faculty) {
      setTableData([]);
      return;
    }

    setShowNoResults(false);
    setShowTableError(false);
    setLoadingTableData(true);

    const { error, data } = await getStudyPlansByFaculty(+faculty.value);

    if (error) {
      setShowTableError(true);
    }

    if (data) {
      setTableData(data.data);

      if (data.data.length === 0) setShowNoResults(true);
    }

    setLoadingTableData(false);
  };

  // Lanza proceso de egreso masivo
  const handleGraduatesProcessing = async (studyPlanId: number) => {
    setProcessingGraduates(true);

    const { data, error } = await processGraduatesByStudyPlan(studyPlanId);

    if (error) {
      setShowFailedProcessing(true);
    } else {
      addToast({
        text: t(`${dictPrefix}.graduationProcessSuccessfullyGenerated`),
        icon: 'success',
        color: 'success',
      });

      history.push(`${PathsLayouts.graduationProcessMassive}/graduates-list`, {
        graduates: data?.data,
        currentPeriod,
        facultyId: selectedFaculty ? +selectedFaculty.value : undefined,
        studyPlanId,
      });
    }

    setProcessingGraduates(false);
  };

  // Obtiene lista de facultades y periodo actual
  useEffect(() => {
    const getInitialData = async () => {
      setLoadingFacultiesAndPeriod(true);

      const { error, data } = await getCurrentPeriodAndFaculties();

      if (error) {
        addToast({
          text: 'Error cargando data',
          icon: 'error',
          color: 'danger',
        });
      }

      if (data) {
        setCurrentPeriod(data.data.currentPeriod);
        setFacultiesOptions(() => {
          return data.data.schools.map((s) => ({
            label: s.name,
            value: s.id,
          }));
        });
      }

      setLoadingFacultiesAndPeriod(false);
    };

    getInitialData();
  }, []);

  // Selecciona la facultad en caso de que venga del routerState
  useEffect(() => {
    if (
      !initializedFacultyWithRouter &&
      routerState?.facultyId &&
      facultiesOptions.length
    ) {
      const matchedFaculty = facultiesOptions.find(
        (fac) => fac.value === routerState.facultyId,
      );

      handleFacultySelection(matchedFaculty!);
      setSelectedFaculty(matchedFaculty);
      setInitializedFacultyWithRouter(true);
    }
  }, [initializedFacultyWithRouter, facultiesOptions, routerState]);

  return (
    <>
      <Card className="mx-3 p-4">
        <div className="d-flex justify-content-between mb-3 mt-1">
          <div>
            <h2 className="text-primary text-uppercase fs-20 fw-600 mb-1">
              {t(`${dictPrefix}.processGraduatesTitle`)}
            </h2>
            <p className="mb-0">
              {t(`${dictPrefix}.processGraduatesDescription`)}
            </p>
          </div>
        </div>

        <div className="mb-3">
          <h2 className="text-primary text-uppercase fs-20 fw-600 mb-3">
            {t(`${dictPrefix}.faculty`)}
          </h2>

          <Select
            name=""
            label={t(`${dictPrefix}.faculty`)}
            options={facultiesOptions}
            disabled={loadingFacultiesAndPeriod}
            onChange={handleFacultySelection}
            value={selectedFaculty}
          />
        </div>

        <ExecutedProcessesHistoryTable
          showNoResults={showNoResults}
          showError={showTableError}
          data={tableData}
          isLoadingResults={loadingTableData}
          onProcessGraduatesClick={handleGraduatesProcessing}
        />
      </Card>

      <ProcessingGraduatesModal isOpen={processingGraduates} />

      <FailedProcessingGraduatesModal
        isOpen={showFailedProcessing}
        onAcceptClick={() => setShowFailedProcessing(false)}
      />
    </>
  );
}
