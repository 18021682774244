import {
  Button,
  Modal,
  Select,
  SelectOptionType,
  Table,
  TextInput,
  addToast,
} from '@octano/global-ui';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Col, Form, Label, Row } from 'reactstrap';

import {
  requestAdministrativeEnrollment,
  requestAvailableCoursesEnrollment,
  requestAvailableSectionsEnrollment,
} from '../../../api/requests/courseRegistration';
import Loading from '../../../components/info/Loading';
import { useValidations } from '../../../hooks/useValidations';
import {
  AvailableCourseEnrollment,
  AvailableCourseType,
  AvailableSectionEnrollment,
} from '../../../types/courseRegistrationTypes';
import { isOvercrowded } from '../../../utils/courseSection';
import ModalConfirmNoRequirements from './ModalConfirmNoRequirements';
import ModalExceedCredits from './ModalExceedCredits';
import ModalExceedQuota from './ModalExceedQuota';
import { useColumnsSectionQuotas } from './useColumnsSectionQuotas';

type ModalNewCourseProps = {
  isOpen: boolean;
  toggle: () => void;
  onSave: () => void;
  enrollmentData: {
    studyPlanEnrollmentId: number;
    studyPlanVersionId: number;
    accountId: number;
  };
  excludedCourses?: number[]; // Los ids de las materias a excluir
};

interface InputsNewCourse {
  course: SelectOptionType;
  section: SelectOptionType | null;
}

interface CourseOptions extends AvailableCourseEnrollment {
  value: number | string;
  label: string;
}

interface SectionOptions extends AvailableSectionEnrollment {
  value: number | string;
  label: string;
}

enum ValidationsNewCourse {
  NO_REQUIREMENTS = 'noRequirements',
  EXCEED_QUOTA = 'exceedQuota',
  EXCEED_CREDITS = 'exceedCredits',
}

enum StatusValidation {
  WAITING = 'waiting',
  FINISHED = 'finished',
}

const ModalNewCourse = ({
  isOpen,
  toggle,
  onSave,
  enrollmentData,
  excludedCourses,
}: ModalNewCourseProps) => {
  const prefix = 'courseRegistrationList.detail.newCourse';
  const { t } = useTranslation();
  const {
    control,
    handleSubmit,
    formState,
    watch,
    setValue,
  } = useForm<InputsNewCourse>();
  const [loading, setLoading] = useState<boolean>(true);
  const [sectionsLoading, setSectionsLoading] = useState<boolean>(false);

  const { msgValidations } = useValidations();
  const [courses, setCourses] = useState<CourseOptions[]>([]);
  const [sections, setSections] = useState<SectionOptions[]>([]);
  const [expectValidation, setExpectValidation] = useState<
    {
      name: ValidationsNewCourse;
      status: StatusValidation;
      response?: boolean;
    }[]
  >([]);
  const [isOpenModal, setIsOpenModal] = useState({
    [ValidationsNewCourse.NO_REQUIREMENTS]: false,
    [ValidationsNewCourse.EXCEED_QUOTA]: false,
    [ValidationsNewCourse.EXCEED_CREDITS]: false,
  });
  const [pendingRequirements, setPendingRequirements] = useState<
    { name: string; shortening: string }[]
  >([]);

  const selectedCourse = watch('course');
  const selectedSection = watch('section');

  const loadCourses = useCallback(async () => {
    const { data, error } = await requestAvailableCoursesEnrollment(
      enrollmentData.studyPlanVersionId,
    );
    if (data) {
      const filteredCourses = data.filter(
        (course: AvailableCourseEnrollment) => {
          return !excludedCourses?.some((ec) => ec === course.id);
        },
      );

      setCourses(
        filteredCourses.map((course: AvailableCourseEnrollment) => ({
          value: course.id,
          label: course.name,
          ...course,
        })),
      );
    }
    if (error) {
      console.log(error);
    }
    setLoading(false);
  }, [enrollmentData.studyPlanVersionId, excludedCourses]);

  const loadSection = useCallback(
    async (courseId: number | string) => {
      setSectionsLoading(true);
      const { data, error } = await requestAvailableSectionsEnrollment(
        +courseId,
        enrollmentData.studyPlanVersionId,
      );
      if (data) {
        setSections(
          data.map((section: AvailableSectionEnrollment) => ({
            value: section.id,
            label: section.name,
            ...section,
          })),
        );
      }
      if (error) {
        setSections([]);
        console.log(error);
      }
      setSectionsLoading(false);
    },
    [enrollmentData.studyPlanVersionId],
  );

  useEffect(() => {
    loadCourses();
  }, [loadCourses]);

  useEffect(() => {
    if (selectedCourse?.value) {
      setValue('section', null);
      loadSection(selectedCourse.value);
    } else {
      setSections([]);
    }
  }, [selectedCourse, loadSection, setValue]);

  const addCourse = useCallback(
    async (values: InputsNewCourse) => {
      const { error } = await requestAdministrativeEnrollment({
        accountId: enrollmentData.accountId,
        studyPlanEnrollmentId: enrollmentData.studyPlanEnrollmentId,
        sectionId: values.section?.id || 0,
        ignoreWarning: false,
      });

      if (!error) {
        addToast({
          icon: 'success',
          text: t(`${prefix}.successMessages.enrolledNewCourse`),
          color: 'success',
        });
        onSave();
        return;
      }

      /**
       * Si hay conflictos se encola una lista de confirmaciones para el usuario
       */
      const validations: typeof expectValidation = [];

      error.data.message.forEach((err: string) => {
        switch (err) {
          case 'MAX_CREDITS': {
            validations.push({
              name: ValidationsNewCourse.EXCEED_CREDITS,
              status: StatusValidation.WAITING,
            });
            break;
          }
          case 'MAX_QUOTA': {
            validations.push({
              name: ValidationsNewCourse.EXCEED_QUOTA,
              status: StatusValidation.WAITING,
            });
            break;
          }
          default: {
            const requirements = JSON.parse(err);
            setPendingRequirements(requirements.dependOn);

            validations.push({
              name: ValidationsNewCourse.NO_REQUIREMENTS,
              status: StatusValidation.WAITING,
            });
          }
        }
      });

      setExpectValidation(validations);
    },
    [enrollmentData.accountId, enrollmentData.studyPlanEnrollmentId, onSave, t],
  );

  const requestForcedEnrollment = useCallback(async () => {
    await requestAdministrativeEnrollment({
      accountId: enrollmentData.accountId,
      studyPlanEnrollmentId: enrollmentData.studyPlanEnrollmentId,
      sectionId: selectedSection?.id || 0,
      ignoreWarning: true,
    });

    addToast({
      icon: 'success',
      text: t(`${prefix}.successMessages.enrolledNewCourse`),
      color: 'success',
    });

    onSave();
  }, [
    enrollmentData.accountId,
    enrollmentData.studyPlanEnrollmentId,
    selectedSection?.id,
    t,
    onSave,
  ]);

  /**
   * Si hay validaciones en estado waiting se abren los modals correspondientes
   * si alguno de ellos responde cancelar expect validation se vuelve a null
   */
  useEffect(() => {
    if (expectValidation.length > 0) {
      const toValidate = expectValidation.find(
        (e) => e.status === StatusValidation.WAITING,
      );
      const someCancel = expectValidation.some((e) => e.response === false);
      // Alguna de las validaciones se canceló por lo que no se continúa validando
      if (someCancel) {
        setExpectValidation([]);
        return;
      }
      // Aún quedan validaciones a realizar
      // por lo que se abre el modal correspondiente
      if (toValidate) {
        setIsOpenModal((prevState) => ({
          ...prevState,
          [toValidate.name]: true,
        }));
      } else {
        requestForcedEnrollment();
      }
    }
  }, [expectValidation, requestForcedEnrollment]);

  /**
   * Array con los nombres de los tipos de la asignatura seleccionada
   */
  const typeSelectedCourse = useMemo(() => {
    const types: string[] = [];
    selectedCourse?.types.forEach((type: AvailableCourseType) => {
      types.push(type.name);
    });
    return types;
  }, [selectedCourse]);

  /**
   * Se formatea la data necesitada por la Tabla,
   * para mostrar el horario y cupos de la sección seleccionada
   */
  const dataTableSections: AvailableSectionEnrollment[] = useMemo(() => {
    if (selectedSection) {
      return [
        {
          id: selectedSection.id,
          name: selectedSection.name,
          maximumStudent: selectedSection.maximumStudent,
          slots: selectedSection.slots,
          campus: selectedSection.campus,
          professors: selectedSection.professors,
          sectionSchedules: selectedSection.sectionSchedules,
        },
      ];
    }
    return [];
  }, [selectedSection]);

  const columns = useColumnsSectionQuotas({
    tableData: dataTableSections?.length ? dataTableSections[0] : undefined,
  });

  const onChangeValidation = useCallback(
    (name: ValidationsNewCourse, response: boolean) => {
      setExpectValidation((prevState) => {
        return prevState.map((validation) => {
          if (validation.name === name) {
            return {
              name: validation.name,
              status: StatusValidation.FINISHED,
              response: response,
            };
          }
          return { ...validation };
        });
      });
    },
    [setExpectValidation],
  );

  const onCloseModal = useCallback(
    (name: ValidationsNewCourse) => {
      setIsOpenModal((prevState) => ({
        ...prevState,
        [name]: false,
      }));
    },
    [setIsOpenModal],
  );

  if (loading) {
    return <Loading />;
  }
  return (
    <Modal isOpen={isOpen} toggle={toggle} closeOnBackdrop={true} size="lg">
      <Form onSubmit={handleSubmit(addCourse)}>
        <Row>
          <Col xs={12} className="pb-3 text-center">
            <span className="fs-22 text-dark">{t(`${prefix}.title`)}</span>
          </Col>

          <Col xs={12} className="pt-4 pb-3">
            <Row>
              <Col xs={12} sm={8}>
                <Select
                  name="course"
                  label={t(`${prefix}.nameCourse`)}
                  options={courses}
                  control={control}
                  rules={{ required: msgValidations.required }}
                  isClearable
                  shouldUnregister
                />
                {typeSelectedCourse.length > 0 && (
                  <div className="pb-4">
                    <Label className="text-uppercase text-light">
                      {t(`${prefix}.type`)}
                    </Label>
                    <div>
                      <span className="text-dark">
                        {typeSelectedCourse.toString()}
                      </span>
                    </div>
                  </div>
                )}
              </Col>
              <Col xs={12} sm={4}>
                <TextInput
                  name="credits"
                  label={t(`${prefix}.credits`)}
                  value={selectedCourse?.credits}
                  disabled
                />
              </Col>

              <Col xs={12}>
                <Select
                  name="section"
                  label={t(`${prefix}.section`)}
                  options={sections}
                  control={control}
                  rules={{ required: msgValidations.required }}
                  disabled={!selectedCourse || sectionsLoading}
                  shouldUnregister
                />
              </Col>

              {selectedSection && (
                <Col xs={12}>
                  <Table columns={columns} data={dataTableSections} />
                  {dataTableSections?.length > 0 &&
                    isOvercrowded(dataTableSections[0].slots) && (
                      <p className="fs-12 text-danger">
                        {t(`${prefix}.alreadyOvercrowdedMsg`)}
                      </p>
                    )}
                </Col>
              )}
            </Row>
          </Col>
        </Row>
        <Row className="pt-3">
          <Col xs={12} sm={6} className="pb-2 px-3 px-sm-2 mx-auto">
            <Button
              outlined
              onClick={toggle}
              text={t('common.actions.cancel')}
              disabled={formState.isSubmitting}
              fullwidth
            />
          </Col>

          <Col xs={12} sm={6} className="pb-2 px-3 px-sm-2 mx-auto">
            <Button
              type="submit"
              text={t('common.actions.add')}
              loading={formState.isSubmitting}
              disabled={formState.isSubmitting}
              fullwidth
            />
          </Col>
        </Row>
      </Form>
      <ModalConfirmNoRequirements
        pendingRequirements={pendingRequirements}
        isOpen={isOpenModal[ValidationsNewCourse.NO_REQUIREMENTS]}
        toggle={() => onCloseModal(ValidationsNewCourse.NO_REQUIREMENTS)}
        onConfirm={() =>
          onChangeValidation(ValidationsNewCourse.NO_REQUIREMENTS, true)
        }
        onCancel={() =>
          onChangeValidation(ValidationsNewCourse.NO_REQUIREMENTS, false)
        }
      />
      <ModalExceedQuota
        isOpen={isOpenModal[ValidationsNewCourse.EXCEED_QUOTA]}
        toggle={() => onCloseModal(ValidationsNewCourse.EXCEED_QUOTA)}
        onConfirm={() =>
          onChangeValidation(ValidationsNewCourse.EXCEED_QUOTA, true)
        }
        onCancel={() =>
          onChangeValidation(ValidationsNewCourse.EXCEED_QUOTA, false)
        }
      />
      <ModalExceedCredits
        isOpen={isOpenModal[ValidationsNewCourse.EXCEED_CREDITS]}
        toggle={() => onCloseModal(ValidationsNewCourse.EXCEED_CREDITS)}
        onConfirm={() =>
          onChangeValidation(ValidationsNewCourse.EXCEED_CREDITS, true)
        }
        onCancel={() =>
          onChangeValidation(ValidationsNewCourse.EXCEED_CREDITS, false)
        }
      />
    </Modal>
  );
};

export default ModalNewCourse;
