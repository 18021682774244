import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { RetractedModal } from '../../admission/AdmissionsListEnrolled/hooks/useValidateRetracted';
import NoResultsModal from '../../views/Reports/parts/NoResultsModal';
import { ModalConfirmRetract } from './ModalConfirmRetract';

interface ModalsValidateRetractProps {
  studentName: string;
  selectRetractedID: number | undefined;
  setSelectRetractedID: React.Dispatch<
    React.SetStateAction<number | undefined>
  >;
  showRetractedModal: string;
  setRetractedModal: React.Dispatch<React.SetStateAction<RetractedModal>>;
  updateQuery: (query?: { page: number; search?: string }) => void;
}

export const ModalsValidateRetract = ({
  updateQuery,
  studentName,
  selectRetractedID,
  setSelectRetractedID,
  showRetractedModal,
  setRetractedModal,
}: ModalsValidateRetractProps) => {
  const { t } = useTranslation();

  const onCloseRetractedModal = useCallback(() => {
    setRetractedModal(RetractedModal.none);
  }, [setRetractedModal]);

  return (
    <>
      <ModalConfirmRetract
        open={showRetractedModal === RetractedModal.confirm}
        postulationDetailId={selectRetractedID ?? 0}
        onClose={() => {
          setSelectRetractedID(undefined);
          setRetractedModal(RetractedModal.none);
        }}
        onConfirm={() => updateQuery()}
        student={studentName}
      />

      <NoResultsModal
        isOpen={showRetractedModal === RetractedModal.out}
        icon="error"
        texts={{
          title: t('admission.modalRetractOutEvent.title'),
          description: t('admission.modalRetractOutEvent.description'),
        }}
        onConfirm={onCloseRetractedModal}
      />

      <NoResultsModal
        isOpen={showRetractedModal === RetractedModal.empty}
        icon="error"
        texts={{
          title: t('admission.modalRetractNotEvent.title'),
          description: t('admission.modalRetractNotEvent.description'),
        }}
        onConfirm={onCloseRetractedModal}
      />
    </>
  );
};
