import {
  Button,
  OutlinedSelect,
  SelectOptionType,
  addToast,
  useMobile,
} from '@octano/global-ui';
import { useCallback, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Col, Form, Row } from 'reactstrap';

import { modifyEnrollment } from '../api';
import { ModifyEnrollmentFormDataResponse } from '../types';

interface FormValues {
  'offer.current': SelectOptionType | null;
  'offer': SelectOptionType | null;
}

interface ChangeFormProps {
  studyPlanEnrollmentId: number;
  studyPlanVersionOfferId: number;
  offers: ModifyEnrollmentFormDataResponse['offers'];
  defaultValues: {
    'offer.current': SelectOptionType;
  };
  onUpdate: () => void;
  onCancel: () => void;
}
export default function ChangeForm(props: ChangeFormProps) {
  const { t } = useTranslation();
  const texts = useMemo(
    () => ({
      form: {
        currentOffer: t('changeStudyPlan.continuityDetail.form.currentOffer'),
        offer: t('changeStudyPlan.continuityDetail.form.offer'),
        cancel: t('changeStudyPlan.continuityDetail.form.cancel'),
        confirm: t('changeStudyPlan.continuityDetail.form.confirm'),
      },
      sameOfferError: t('changeStudyPlan.continuityDetail.sameOfferError'),
      unknownError: t('changeStudyPlan.continuityDetail.unknownError'),
    }),
    [t],
  );

  const {
    studyPlanEnrollmentId,
    studyPlanVersionOfferId,
    defaultValues,
    onUpdate,
    onCancel,
  } = props;
  const isMobile = useMobile();
  const form = useForm<FormValues>({ defaultValues });
  const { handleSubmit, control, formState } = form;

  const offersOptions = props.offers.map((offer) => ({
    label: `${offer.studyPlanVersionName} - ${offer.campusName} - ${offer.scheduleName}`,
    value: offer.id,
  }));

  const onSubmit = useCallback(
    async (data: FormValues) => {
      if (data.offer?.value === studyPlanVersionOfferId) {
        addToast({
          icon: 'error',
          color: 'danger',
          text: texts.sameOfferError,
        });
        return;
      }
      if (typeof data.offer?.value !== 'number') {
        throw new Error(
          `La oferta debe ser un number. Su valor es ${data.offer?.value}`,
        );
      }
      const result = await modifyEnrollment(studyPlanEnrollmentId, {
        studyPlanVersionOfferId: data.offer?.value,
      });
      if (result.error) {
        addToast({
          icon: 'error',
          color: 'danger',
          text: texts.unknownError,
        });
      } else {
        onUpdate();
      }
    },
    [
      studyPlanEnrollmentId,
      studyPlanVersionOfferId,
      texts.sameOfferError,
      texts.unknownError,
      onUpdate,
    ],
  );
  return (
    <Form
      onSubmit={handleSubmit(onSubmit)}
      className=" h-100 d-flex flex-column"
    >
      <div className={isMobile ? undefined : 'w-50 m-auto'}>
        <OutlinedSelect
          isClearable={false}
          control={control}
          label={texts.form.currentOffer}
          name="offer.current"
          options={[defaultValues['offer.current']]}
          rules={{
            required: true,
          }}
          disabled
        />

        <OutlinedSelect
          isClearable={false}
          control={control}
          label={texts.form.offer}
          name="offer"
          options={offersOptions}
          rules={{
            required: true,
          }}
        />
      </div>
      <Row className="px-2">
        <Col sm={3} className="pb-2 px-3 px-sm-2 ml-auto">
          <Button
            text={texts.form.cancel}
            onClick={onCancel}
            outlined
            fullwidth
          />
        </Col>
        <Col sm={3} className="pb-2 px-3 px-sm-2">
          <Button
            type="submit"
            text={texts.form.confirm}
            fullwidth
            loading={formState.isSubmitting}
          />
        </Col>
      </Row>
    </Form>
  );
}
