import { TextField } from '@octano/global-ui';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'reactstrap';

import GoBackButton from '../../../../../components/buttons/GoBackButton';
import { useStepState } from '../../../../../components/step/useStepState';
import { STATUS_POSTULATION } from '../../../../../types/tuitionProcessOnSite';
import { useTuitionProcessNoSua } from '../hooks/useTuitionProcessNoSua';

interface HeaderStepProps {
  prefixStep: string;
  showBackBtn?: boolean;
}

const HeaderStep = ({ prefixStep, showBackBtn = false }: HeaderStepProps) => {
  const { t } = useTranslation();
  const prefix = 'tuitionProcessNoSua';
  const {
    postulant,
    statusPostulation,
    postulationDetail,
    newPostulationDetail,
  } = useTuitionProcessNoSua();
  const { prevStep, currentStep } = useStepState();

  const bodyStatus: string = useMemo(() => {
    if (statusPostulation === STATUS_POSTULATION.STUDY_PLAN_CHANGE) {
      return (
        t(`tuitionProcessNoSua.status.STUDY_PLAN_CHANGE`) +
        ' ' +
        newPostulationDetail?.studyPlanName +
        ' - ' +
        newPostulationDetail?.campus.name +
        ' - ' +
        newPostulationDetail?.schedule.name
      );
    }
    if (statusPostulation === STATUS_POSTULATION.ENROLLED) {
      return t(`tuitionProcessNoSua.status.SELECT_NEW_STUDY_PLAN`);
    }
    return t(`tuitionProcessNoSua.status.${statusPostulation}`);
  }, [t, statusPostulation, newPostulationDetail]);

  const isStudyPlanChange = useMemo(() => {
    return (
      statusPostulation === STATUS_POSTULATION.ENROLLED ||
      statusPostulation === STATUS_POSTULATION.STUDY_PLAN_CHANGE
    );
  }, [statusPostulation]);

  return (
    <Row className="pb-3 pb-lg-4">
      {showBackBtn && (
        <Col xs={12} lg={6} className="pb-3">
          <GoBackButton
            onClick={() => prevStep()}
            text={t(`common.btn.goBackStep`)}
          />
        </Col>
      )}
      <Col
        xs={12}
        lg={showBackBtn ? 12 : 6}
        className={showBackBtn ? 'order-lg-3' : ''}
      >
        <span className="fs-22 fw-600 text-primary">
          {t(`${prefix}.titleInstructions`)}
        </span>
        <p className="fs-16">
          {t(
            `${prefixStep}.${
              isStudyPlanChange && currentStep === 0
                ? 'instructionsChange'
                : 'instructions'
            }`,
          )}
        </p>
      </Col>
      {isStudyPlanChange ? (
        <>
          <Col xs={12} lg={6}>
            <Row className="">
              <Col xs={12} lg={5} className="pr-lg-0 mb-n2">
                <TextField
                  placeholder={t(`${prefix}.student`)}
                  border={false}
                  style={{ borderRadius: '0px' }}
                >
                  {postulant?.fullName ?? ''}
                </TextField>
              </Col>
              <Col xs={12} lg={7} className="p-lg-0 mb-n2">
                <TextField
                  placeholder={t(`${prefix}.titleStatus`)}
                  border={false}
                  style={{ borderRadius: '0px' }}
                >
                  {bodyStatus}
                </TextField>
              </Col>
            </Row>
            <Row>
              <Col xs={12} lg={6} className="pr-lg-0 mb-n2">
                <TextField
                  placeholder={t(`${prefix}.studyPlan`)}
                  border={false}
                  style={{ borderRadius: '0px' }}
                >
                  {t(`${prefix}.enrolledStudyPlan`) +
                    ' ' +
                    postulationDetail?.studyPlanName}
                </TextField>
              </Col>
              <Col xs={6} lg={3} className="p-lg-0 pr-0 mb-n2">
                <TextField
                  placeholder={t(`${prefix}.campus`)}
                  border={false}
                  style={{ borderRadius: '0px' }}
                >
                  {postulationDetail?.campus.name ?? ''}
                </TextField>
              </Col>
              <Col xs={6} lg={3} className="p-lg-0 pl-0 mb-n2">
                <TextField
                  placeholder={t(`${prefix}.schedule`)}
                  border={false}
                  style={{ borderRadius: '0px' }}
                >
                  {postulationDetail?.schedule.name ?? ''}
                </TextField>
              </Col>
            </Row>
          </Col>
        </>
      ) : (
        <>
          <Col xs={12} lg={4}>
            <Row>
              <Col xs={12} lg={6} className="pr-lg-0 mb-n2">
                <TextField
                  placeholder={t(`${prefix}.postulant`)}
                  border={false}
                  style={{ borderRadius: '0px' }}
                >
                  {postulant?.fullName ?? ''}
                </TextField>
              </Col>
              <Col xs={12} lg={6} className="pl-lg-0 mb-n2 pr-lg-0">
                <TextField
                  placeholder={t(`${prefix}.titleStatus`)}
                  border={false}
                  style={{ borderRadius: '0px' }}
                >
                  {bodyStatus}
                </TextField>
              </Col>
            </Row>
          </Col>
          <Col xs={12} lg={2} className="mb-n2">
            <TextField placeholder={t(`${prefix}.admissionType`)}>
              {t(`admissionTypes.SIN_SUA`)}
            </TextField>
          </Col>
        </>
      )}
    </Row>
  );
};

export default HeaderStep;
