import { Button, addToast } from '@octano/global-ui';
import { useCallback } from 'react';

import { AxiosResult, AxiosResultDefaultError } from '../../../api/request';
import { downloadFromBlob } from '../../../utils/blob';

interface DownloadProps {
  beforeDownload?: () => void;
  afterDownload?: () => void;
  disabled?: boolean;
  filename: string;
  request: () => Promise<AxiosResult<any, AxiosResultDefaultError>>;
  texts: {
    error: string;
    action: string;
  };
}

export default function Download({
  beforeDownload,
  afterDownload,
  disabled,
  filename,
  request,
  texts,
}: DownloadProps): JSX.Element {
  const handleDocumentDownload = useCallback(async () => {
    if (beforeDownload) {
      beforeDownload();
    }
    const res = await request();

    if (res.error) {
      addToast({
        icon: 'error',
        color: 'danger',
        text: texts.error,
      });
    } else {
      const blob = new Blob([res.data], { type: 'vnd.ms-excel' });
      downloadFromBlob(blob, `${filename}.xlsx`);
    }
    if (afterDownload) {
      afterDownload();
    }
  }, [beforeDownload, afterDownload, request, texts, filename]);

  return (
    <Button
      outlined
      size="md"
      icon="download"
      color="primary"
      text={texts.action}
      onClick={handleDocumentDownload}
      disabled={disabled}
    />
  );
}
