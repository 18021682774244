import { Button, Icon, Table, TablePagination } from '@octano/global-ui';
import { CellFormatOptions } from '@octano/global-ui/dist/components/Table/types/TableTypes';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useHistory } from 'react-router-dom';
import { Badge } from 'reactstrap';
import DisplayError from '../../../components/info/DisplayError';
import Loading from '../../../components/info/Loading';
import TableEmptyContent from '../../../components/text/TableEmptyContent';
import { PathsLayouts } from '../../../config/routes';
import { useLayoutState } from '../../../hooks/useLayoutState';
import { useLoadingState } from '../../../hooks/useLoadingState';
import { getStudyPlansRequest } from './api/study-plan';
import { StudyPlanItem, Version } from './interfaces/sudy-plan-item.interface';
import styles from './parts/StudyPlansList.module.scss';
import StudyPlansSearch, { SearchBody } from './parts/StudyPlansSearch';

const prefix = 'studyPlans.list';

interface TableColumns {
  width?: string;
  bold?: boolean;
  headerText: string;
  columnName: string;
  [key: string]: any;
}

const getQuerySearchValue = (search?: SearchBody) => {
  let searchValues: string[] = [];
  if (search) {
    const values = Object.values(search);
    searchValues = [];
    Object.keys(search).forEach((key: string, i: number) => {
      if (values[i]) {
        searchValues.push(
          `${key}=${
            typeof values[i] === 'object' ? values[i].value : values[i]
          }`,
        );
      }
    });
  }
  return searchValues.join('&');
};

const getBadgeColor = (status: string) =>
  ({
    Cerrado: 'danger',
    Activo: 'primary',
    Borrador: 'danger',
  }[status]);

const StudyPlansList = () => {
  const [studyPlans, setStudyPlans] = useState<StudyPlanItem[]>([]);
  const [totalItems, setTotalItems] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(1);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [isFiltering, setIsFiltering] = useState<boolean>(false);

  const [showPagination, setShowPagination] = useState<boolean>(false);
  const { loading, setLoading, errorLoading } = useLoadingState();
  const { showTabs } = useLayoutState();
  useEffect(() => {
    showTabs();
  }, [showTabs]);
  const history = useHistory();
  const { t } = useTranslation();

  const commonPrefix = 'common.forms';

  /**
   * Ordena las versiones de los planes de estudio priorizando estado activo,
   * vigente y luego su fecha de modificación
   */
  const sorter = (a: Version, b: Version) => {
    return (
      Number(b.status === 'Activo') - Number(a.status === 'Activo') ||
      Number(b.status === 'Vigente') - Number(a.status === 'Vigente') ||
      new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime()
    );
  };

  const columns: TableColumns[] = [
    {
      columnName: 'code',
      headerText: t(`${commonPrefix}.code`),
      bold: true,
    },
    {
      columnName: 'name',
      headerText: t(`${commonPrefix}.name`),
    },
    {
      columnName: 'school',
      headerText: t(`${commonPrefix}.school`),
      cellFormat: (options: CellFormatOptions) => options.row?.school?.name,
    },
    {
      columnName: 'versions',
      headerText: t(`${prefix}.currentVersion`),
      cellFormat: (options: CellFormatOptions) => {
        const versions: Version[] = options.value.sort(sorter);
        const version = versions[0]?.name ?? '';
        return (
          <div className="d-flex justify-content-center">
            <span>{version}</span>
            <div></div>
          </div>
        );
      },
    },
    {
      columnName: 'versions',
      headerText: t(`${prefix}.stateVersion`),
      width: '120px',
      cellFormat: (options: CellFormatOptions) => {
        if (!options.value.length) return null;

        const versions: Version[] = options.value.sort(sorter);
        const label = versions[0].status;
        const color = getBadgeColor(label);

        return (
          <Badge
            className={label === 'Vigente' ? styles.vigente : ''}
            color={color}
          >
            {label}
          </Badge>
        );
      },
    },
    {
      columnName: 'id',
      headerText: '',
      width: '40px',
      cellFormat: (options: CellFormatOptions) => {
        return (
          <Link
            to={`${PathsLayouts.academicOffers}/study-plans/record/${options.value}`}
          >
            <Icon className="custom-eye" name="eye" key="eye" size="22px" />
          </Link>
        );
      },
    },
  ];

  const getTableEmptyTexts = useCallback(
    () =>
      isFiltering
        ? {
            title: t(`${prefix}.emptySearchTitle`),
            subtitle: t(`${prefix}.emptySearchSubtitle`),
          }
        : {
            title: t(`${prefix}.emptyTitle`),
            subtitle: t(`${prefix}.emptySubtitle`),
          },
    [isFiltering, t],
  );

  const getStudyPlans = useCallback(
    async (items: number = 10, page: number = 0, search?: SearchBody) => {
      const { data, error } = await getStudyPlansRequest(
        items,
        page,
        getQuerySearchValue(search),
      );
      setLoading(false);

      if (error) {
        return;
      }

      if (data) {
        const { data: studyPlansItems = [], total = 0, total_pages = 1 } = data;
        setIsFiltering(!!search);
        setStudyPlans(studyPlansItems);
        setTotalItems(total);
        setTotalPages(total_pages);
        setShowPagination(true);
      }
    },
    [setLoading],
  );

  const changePage = async (page: number = 1) => {
    await getStudyPlans(10, page - 1);
    setCurrentPage(page);
  };

  useEffect(() => {
    getStudyPlans();
  }, [getStudyPlans]);

  if (errorLoading) {
    return (
      <div className="mx-3">
        <DisplayError
          insideCard
          textBody={errorLoading}
          retryAction={() => setLoading(true)}
          loadingAction={loading}
        />
      </div>
    );
  }
  if (loading) {
    return (
      <div className="mx-3">
        <Loading insideCard className={styles.loaderWrapper} />
      </div>
    );
  }

  return (
    <div className="g-table-container mx-3">
      <StudyPlansSearch
        isFiltering={isFiltering}
        onSubmit={(search) => getStudyPlans(10, 0, search)}
      />
      <Button
        text={t(`${prefix}.addTitle`)}
        type="button"
        size="sm"
        icon="plus"
        onClick={() =>
          history.push(`${PathsLayouts.academicOffers}/study-plans/record`)
        }
        className="g-add-button mt-3"
      />
      <div className={styles.studyPlansTable}>
        <Table
          noResultsText={<TableEmptyContent {...getTableEmptyTexts()} />}
          columns={columns}
          data={studyPlans}
        />
        {showPagination && totalItems > 10 && (
          <TablePagination
            pagination={{
              currentPage: currentPage,
              itemsPerPage: 10,
              onChangePage: (page) => {
                changePage(page);
              },
              totalItems: totalItems,
              totalPages: totalPages,
            }}
          />
        )}
      </div>
    </div>
  );
};

export default StudyPlansList;
