import { ModalityBody, ModalityListItem } from '../../types/ModalityTypes';
import { PaginationRequestType } from '../../types/paginationRequestType';
import { ACADEMIC_STRUCTURE } from '../endpoints';
import request from '../request';

export interface ModalityListResponse {
  data: ModalityListItem;
}

/**
 * Obtiene el listado de modalidades
 */
export const getModalitiesRequest = (items: number = 10, page: number = 0) => {
  const url = `${ACADEMIC_STRUCTURE.MODALITY}?items_per_page=${items}&page=${page}`;
  return request<PaginationRequestType<ModalityListItem>>(url);
};

/**
 *  Crear o editar una modalidad
 */
export const saveModalityRequest = (data: ModalityBody) => {
  const isEdit = !!data.id;

  const url = `${ACADEMIC_STRUCTURE.MODALITY}${isEdit ? `/${data.id}` : ''}`;
  return request<ModalityListResponse>(url, {
    method: isEdit ? 'put' : 'post',
    data,
  });
};
