import { Table } from '@octano/global-ui';
import {
  CellFormatOptions,
  PaginationType,
} from '@octano/global-ui/dist/components/Table/types/TableTypes';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { getCampusRequest } from '../../../api/requests/campus';
import DisplayError from '../../../components/info/DisplayError';
import Loading from '../../../components/info/Loading';
import { TextEllipsis } from '../../../components/text';
import TableEmptyContent from '../../../components/text/TableEmptyContent';
import { useLayoutState } from '../../../hooks/useLayoutState';
import { useLoadingState } from '../../../hooks/useLoadingState';
import { CampusBody } from '../../../types/CampusTypes';
import { PaginationRequestType } from '../../../types/paginationRequestType';
import styles from './CampusList.module.scss';
import CampusModal from './CampusModal';

const ITEMS_PER_PAGE_DEFAULT = 10;
const PAGE_INITIAL_DEFAULT = 1;

const CampusList = () => {
  const { showTabs } = useLayoutState();

  useEffect(() => {
    showTabs();
  }, [showTabs]);

  const { t } = useTranslation();

  const [campusData, setCampusData] = useState<
    PaginationRequestType<CampusBody>
  >({
    data: [],
    total_pages: 0,
    total: 0,
  });

  const [currentPage, setCurrentPage] = useState<number>(PAGE_INITIAL_DEFAULT);
  const [itemsPerPage] = useState<number>(ITEMS_PER_PAGE_DEFAULT);
  const {
    loading,
    setLoading,
    errorLoading,
    setErrorLoading,
  } = useLoadingState();

  const getCampusList = useCallback(async () => {
    const { error, data: response } = await getCampusRequest(
      itemsPerPage,
      currentPage - 1,
    );
    if (response) {
      setCampusData(response);
      setErrorLoading(undefined);
    } else if (error) {
      setErrorLoading(error.code);
      return;
    }
    setLoading(false);
  }, [itemsPerPage, currentPage, setErrorLoading, setLoading]);

  const handlerOnChangePage = useCallback(
    async (page: number) => setCurrentPage(page),
    [],
  );

  const pagination = useMemo<PaginationType | undefined>(() => {
    if (campusData.total > itemsPerPage) {
      return {
        totalItems: campusData.total,
        itemsPerPage: itemsPerPage,
        totalPages: campusData.total_pages,
        currentPage: currentPage,
        onChangePage: handlerOnChangePage,
      };
    }
  }, [campusData, itemsPerPage, currentPage, handlerOnChangePage]);

  const columns = [
    {
      columnName: 'code',
      headerText: t('common.forms.code'),
      bold: true,
      width: '20%',
    },
    {
      columnName: 'name',
      headerText: t('common.forms.name'),
      width: '20%',
    },
    {
      columnName: 'addressStreet',
      headerText: t('common.forms.address'),
      cellFormat: (options: CellFormatOptions) => {
        const { addressNumber, commune, addressExtra } = options.row;

        const addressExtraText = addressExtra ? ` - ${addressExtra}` : '';
        return (
          <TextEllipsis key={options.index} textWidth={350}>
            {`${options.value} ${addressNumber}, ${commune.name}${addressExtraText}`}
          </TextEllipsis>
        );
      },
    },
    {
      columnName: 'id',
      headerText: '',
      width: '40px',
      cellFormat: (options: CellFormatOptions) => (
        <CampusModal
          reloadCampusList={() => getCampusList()}
          recordToEdit={options.row}
        />
      ),
    },
  ];

  useEffect(() => {
    getCampusList();
  }, [getCampusList]);

  if (errorLoading) {
    return (
      <DisplayError
        insideCard
        textBody={errorLoading}
        retryAction={() => setLoading(true)}
        loadingAction={loading}
      />
    );
  }
  if (loading) {
    return <Loading insideCard className={styles.loaderWrapper} />;
  }
  return (
    <div className="g-table-container">
      <CampusModal reloadCampusList={() => getCampusList()} isCreate />
      <div className={styles.campusTable}>
        <Table
          columns={columns}
          data={campusData.data}
          pagination={pagination}
          noResultsText={
            <TableEmptyContent
              title={t('maintainers.campusList.campusTableEmptyTitle')}
              subtitle={t('maintainers.campusList.campusTableEmptySubtitle')}
            />
          }
        />
      </div>
    </div>
  );
};

export default CampusList;
