import { RemoteSelect, RemoteSelectProps } from '@octano/global-ui';
import { FieldValues } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

type RemoteSelectText = {
  placeholder?: string;
  noOptionsMessage?: string;
  placeholderSearchText?: string;
  loadingMessage?: string;
};
/**
 * Instancia del RemoteSelect de librería global-ui
 * contiene por defecto los valores noOptionsMessage, placeholderSearchText, loadingMessage
 * para evitar pasarle los valores cada vez que necesite ser utilizado
 * @param props
 * @returns
 */
const RemoteSearchSelect = <T extends FieldValues>(
  props: RemoteSelectText &
    Omit<
      RemoteSelectProps<T>,
      | 'placeholder'
      | 'noOptionsMessage'
      | 'placeholderSearchText'
      | 'loadingMessage'
    >,
) => {
  const { t } = useTranslation();
  const prefix = 'common.remoteSelect';

  return (
    <RemoteSelect
      {...props}
      placeholder={props.placeholder ?? t(`${prefix}.placeholder`)}
      noOptionsMessage={
        props.noOptionsMessage ?? t(`${prefix}.noOptionsMessage`)
      }
      placeholderSearchText={
        props.placeholderSearchText ?? t(`${prefix}.placeholderSearchText`)
      }
      loadingMessage={props.loadingMessage ?? t(`${prefix}.loadingMessage`)}
    />
  );
};

export default RemoteSearchSelect;
