import { addToast } from '@octano/global-ui';
import dayjs from 'dayjs';
import { useCallback, useState } from 'react';

import { downloadFromBlob } from '../../../utils/blob';
import { getCourseHistoryPdf } from '../requests';

export default function useDownload({
  studyPlanEnrollmentId,
  texts,
}: {
  studyPlanEnrollmentId: number;
  texts: {
    failed: string;
    success: string;
  };
}) {
  const [loading, setLoading] = useState(false);
  const download = useCallback(async () => {
    setLoading(true);
    const { data, error } = await getCourseHistoryPdf(studyPlanEnrollmentId);
    if (error) {
      addToast({
        color: 'danger',
        icon: 'error',
        text: texts.failed,
      });
    }
    if (data) {
      addToast({
        color: 'success',
        icon: 'check',
        text: texts.success,
      });
      const name = `historial_${dayjs().format('YYYY-MM-DD')}`;
      downloadFromBlob(data, name);
    }
    setLoading(false);
  }, [texts, studyPlanEnrollmentId]);

  return { loading, download };
}
