import FileSaver from 'file-saver';
import XLSX, { JSON2SheetOpts } from 'sheetjs-style';

import { ExcelData } from '../types/excel';

export default class ExcelCreate {
  constructor(private readonly book = XLSX.utils.book_new()) {}

  addSheet<T extends ReadonlyArray<string>>({
    sheetName,
    sheetOptions,
    colOptions,
    cellStyle,
    header,
    rows,
  }: ExcelData<T>): void {
    const sheetOpts: JSON2SheetOpts = sheetOptions ?? {
      header: header as any,
    };
    const sheet = XLSX.utils.json_to_sheet(rows, sheetOpts);
    sheet['!cols'] = colOptions;
    if (cellStyle) {
      Object.entries(cellStyle).forEach(([key, value]) => {
        sheet[key].s = value;
      });
    }
    XLSX.utils.book_append_sheet(this.book, sheet, sheetName);
  }

  addManySheet(sheets: ExcelData<any>[]) {
    for (const sheet of sheets) {
      this.addSheet(sheet);
    }
  }

  exportToFile(
    filename = `excel-${Date.now()}`,
    fileFormat?: { bookType: XLSX.BookType; contentType: string },
  ) {
    const { bookType, contentType } = fileFormat ?? {
      bookType: 'xlsx',
      contentType: 'vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    };
    const buffer = XLSX.write(this.book, { bookType, type: 'array' });
    const data = new Blob([buffer], { type: contentType });
    FileSaver.saveAs(data, `${filename}.${bookType}`);
  }

  exportWorkBook() {
    return this.book;
  }
}
