import { Button, addToast } from '@octano/global-ui';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { Card, Col, Container, Row } from 'reactstrap';

import GoBackButton from '../../../../../components/buttons/GoBackButton';
import DisplayError from '../../../../../components/info/DisplayError';
import { PathsLayouts } from '../../../../../config/routes';
import { useLoadingState } from '../../../../../hooks/useLoadingState';
import { getStudyPlanRequest } from '../../api/study-plan';
import {
  createVersionsRequest,
  getStudyPlanVersionCreateForm,
} from '../../api/study-plan-version';
import { StudyPlanItem } from '../../interfaces/sudy-plan-item.interface';
import VersionRecordForm from './VersionRecordForm';

const VersionRecordCreate = () => {
  const { t } = useTranslation();
  const history = useHistory();

  // obtener el id de plan de estudio
  const { id: studyPlanId } = useParams<{ id?: string }>();

  const prefix = 'studyPlans';

  // ir para atras
  const goBack = useCallback(() => {
    history.push(
      `${PathsLayouts.academicOffers}/study-plans/record/${studyPlanId}`,
    );
  }, [history, studyPlanId]);

  // loading
  const { loading, setLoading, errorLoading, setErrorLoading } =
    useLoadingState();

  // data para la  tabla
  const [planStudy, setPlanStudy] = useState<StudyPlanItem>();
  const [isSoftlandEnabled, setIsSoftlandEnabled] = useState<boolean>(false);

  // obtener el plan de estudio
  const getPlanStudy = useCallback(async () => {
    if (!studyPlanId) {
      return;
    }
    // get plan study
    const { error, data: response } = await getStudyPlanRequest(+studyPlanId);

    if (response) {
      setPlanStudy(response.data);
    } else if (error) {
      setErrorLoading(error.code);
      return;
    }
    setLoading(false);
  }, [studyPlanId, setErrorLoading, setLoading]);

  // obtener el form de creacion
  const getCreateForm = useCallback(async () => {
    if (!studyPlanId) {
      return;
    }
    // get plan study
    const { error, data: response } = await getStudyPlanVersionCreateForm();

    if (response) {
      setIsSoftlandEnabled(response.isSoftlandEnabled);
    } else if (error) {
      setErrorLoading(error.code);
      return;
    }
    setLoading(false);
  }, [studyPlanId, setErrorLoading, setLoading]);

  useEffect(() => {
    getPlanStudy();
  }, [getPlanStudy]);

  useEffect(() => {
    getCreateForm();
  }, [getCreateForm]);

  // enviar formulario
  const onSubmit = useCallback(
    async (values) => {
      const response = await createVersionsRequest(values);
      if (!response.error) {
        addToast({
          icon: 'success',
          color: 'success',
          text: t(`${prefix}.version.createSuccess`),
        });
        goBack();
      } else {
        return response.error.code;
      }
    },
    [goBack, t],
  );

  if (errorLoading) {
    return (
      <DisplayError
        insideCard
        textBody={errorLoading}
        retryAction={() => setLoading(true)}
        loadingAction={loading}
      />
    );
  }
  if (loading) {
    return <></>;
  }

  return (
    <>
      <Container>
        <Card className="p-3 p-md-4">
          <div className="mb-4">
            <GoBackButton onClick={goBack} />
          </div>
          {planStudy && (
            <VersionRecordForm
              studyPlan={planStudy}
              isSoftlandEnabled={isSoftlandEnabled}
              onSubmit={onSubmit}
            >
              {({ isSubmitting }) => (
                <Row className="pt-5">
                  <Col
                    xs={{ order: 2, size: 12 }}
                    md={{ order: 1, size: 4 }}
                    className="ml-auto"
                  >
                    <div>
                      <Button
                        type="button"
                        text={t(`common.actions.cancel`)}
                        outlined
                        fullwidth
                        onClick={() => goBack()}
                      />
                    </div>
                  </Col>

                  <Col xs={{ order: 1, size: 12 }} md={{ order: 2, size: 4 }}>
                    <div className="pb-3 pb-md-0">
                      <Button
                        type="submit"
                        text={t(`${prefix}.version.createVersion`)}
                        loading={isSubmitting}
                        fullwidth
                      />
                    </div>
                  </Col>
                </Row>
              )}
            </VersionRecordForm>
          )}
        </Card>
      </Container>
    </>
  );
};

export default VersionRecordCreate;
