import { StudentPostCloseSearch } from '../../types/studentPostClose';

// Mapea la parte del estudiante
function mapSearchResultStudent(
  account: StudentPostCloseSearch.SearchEntry['account'],
) {
  return {
    fullName: account.fullName,
    passportNumber: account.passportNumber,
    run: account.run,
  };
}

// Mapea la parte del courseEnrollment
function mapSearchResultCourseEnrrollment(
  courseEnrrollment: StudentPostCloseSearch.SearchEntry['studyPlanEnrollments'][number]['courseEnrollments'][number],
) {
  const { id, state, section } = courseEnrrollment;

  return {
    courseEnrollmentId: id,
    state,
    sectionName: section.name,
    period: section.period.name,
    closedAt: section.closedAt,
    courseName: section.course.name,
  };
}

type MappedCourseEnrollment = ReturnType<
  typeof mapSearchResultCourseEnrrollment
>;
type MappedCourseEnrollmentWithStudyPlan = MappedCourseEnrollment & {
  studyPlanName: string;
};

// Mapea toda la respuesta de la busqueda
export function mapSearchResult(entry: StudentPostCloseSearch.SearchEntry) {
  const student = mapSearchResultStudent(entry.account);
  const { studyPlanEnrollments } = entry;

  const courseEnrollments = studyPlanEnrollments.reduce((cEs, current) => {
    const studyPlanName =
      current.academicOfferStudyPlanVersionOffer.studyPlanVersionOffer
        .studyPlanVersion.name;

    const mappedEnrrollments = current.courseEnrollments
      .map(mapSearchResultCourseEnrrollment)
      .map((cE) => ({ ...cE, studyPlanName }));

    return [...cEs, ...mappedEnrrollments];
  }, [] as MappedCourseEnrollmentWithStudyPlan[]);

  return {
    student,
    courseEnrollments,
  };
}

export type MappedSearchResults = ReturnType<typeof mapSearchResult>[];
