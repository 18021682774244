import { useTranslation } from 'react-i18next';

import { Card } from '../../components/article/Card';
import { PathsLayouts } from '../../config/routes';

export interface PackagesListHeaderCardProps {
  academicOfferStudyPlanVersionOfferId: number;
  location: string;
  totalStudents: number;
  studentsWithoutRegistration: number;
}

export function PackagesListHeaderCard({
  academicOfferStudyPlanVersionOfferId,
  location,
  totalStudents,
  studentsWithoutRegistration,
}: PackagesListHeaderCardProps) {
  const { t } = useTranslation();

  const isComplete = studentsWithoutRegistration === 0;

  const mainCardStyles = isComplete
    ? 'main-card complete-card'
    : 'main-card incomplete-card';

  return (
    <Card
      title=""
      subtitle={location}
      body={[
        {
          label: t('packages.firstTimeEnrolled'),
          value: totalStudents,
        },
        {
          label: t('packages.noInscribed'),
          value: studentsWithoutRegistration,
        },
      ]}
      markedText={{
        label: t('packages.noInscribed'),
        value: totalStudents
          ? `${Math.round(
              (studentsWithoutRegistration * 100) / totalStudents,
            )}%`
          : '-',
      }}
      to={
        isComplete
          ? undefined
          : `${PathsLayouts.packages}/pending-students/${academicOfferStudyPlanVersionOfferId}`
      }
      toText={t('packages.viewDetails')}
      className={`package-card ${mainCardStyles}`}
    />
  );
}
