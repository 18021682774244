import { TextInput } from '@octano/global-ui';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'reactstrap';
import { useValidations } from '../../../../../../../hooks/useValidations';
import SendEmail from './SendEmail';

const fields = {
  email: 'email',
  phone: 'phone',
};

const CreateUserFormContactFields = ({
  prefix: subPrefix,
  isFormCreate,
}: {
  prefix: string;
  isFormCreate: boolean;
}) => {
  const { t } = useTranslation();
  const prefix = `${subPrefix}.contact`;
  const { validateTextNotEmpty, validatePhone, validateEmail } =
    useValidations();

  const { control } = useFormContext();

  return (
    <Row>
      <Col md={4}>
        <TextInput
          name={`${fields.phone}`}
          control={control}
          formatter="phone"
          label={t(prefix + '.' + fields.phone)}
          placeholder={t('common.placeholder.cellphone')}
          rules={{
            validate: {
              notEmpty: validateTextNotEmpty,
              phone: validatePhone,
            },
          }}
        />
      </Col>
      <Col md={4}>
        <TextInput
          name={`${fields.email}`}
          control={control}
          label={t(prefix + '.' + fields.email)}
          rules={{
            validate: {
              notEmpty: validateTextNotEmpty,
              email: validateEmail,
            },
          }}
          tooltip={t(`${prefix}.tooltip.email`)}
        />
        {!isFormCreate && <SendEmail />}
      </Col>
    </Row>
  );
};

export default CreateUserFormContactFields;
