import {
  Button,
  CellFormatOptions,
  ColumnTable,
  Icon,
  PaginationType,
  Table,
} from '@octano/global-ui';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';

import { PathsLayouts } from '../../config/routes';
import {
  PERIOD_STATES,
  PERIOD_STATES_TRANSLATION,
  PERIOD_STATE_BUTTONS,
  PERIOD_STATE_ICONS,
  PeriodStates,
  PeriodType,
} from '../../types/sectionsAndPackageTypes';
import { formatDate } from '../../utils/dates';

export interface PeriodsTableProps {
  periods: PeriodType[];
  pagination?: PaginationType;
  loading?: boolean;
}

const PeriodsTable = ({ periods, pagination, loading }: PeriodsTableProps) => {
  const { t } = useTranslation();
  const prefix = 'packagesMaintainer.table';
  const history = useHistory();
  const { periodId } = useParams<{ periodId?: string }>();

  const getProperIcon = (state: PeriodStates) => {
    if (state === PERIOD_STATES.LOADED) return PERIOD_STATE_ICONS.WARNING;
    if (state === PERIOD_STATES.CLOSED) return PERIOD_STATE_ICONS.SUCCESS;
    return PERIOD_STATE_ICONS.ERROR;
  };

  const getStateName = useCallback(
    (state: PeriodStates) => t(`${prefix}.${PERIOD_STATES_TRANSLATION[state]}`),
    [t],
  );

  const getButtonName = useCallback(
    (state: PeriodStates) => t(`${prefix}.${PERIOD_STATE_BUTTONS[state]}`),
    [t],
  );

  const goToPeriodDetails = useCallback(
    (id: number) => history.push(`${PathsLayouts.packagesMaintainer}/${id}`),
    [history],
  );

  const columns = useMemo<ColumnTable<PeriodType>[]>(() => {
    const defaultColumns = [
      {
        columnName: 'periodCode',
        headerText: t(`${prefix}.period`),
      },
      {
        columnName: 'sectionCount',
        headerText: t(`${prefix}.nSections`),
      },
      {
        columnName: 'packageCount',
        headerText: t(`${prefix}.nPackages`),
      },
      {
        columnName: 'lastLoadDate',
        headerText: t(`${prefix}.lastLoadDate`),
        cellFormat: (options: CellFormatOptions<PeriodType>) =>
          formatDate(options.row.lastLoadDate || '', 'DD/MM/YYYY'),
      },
      {
        columnName: 'loadingDeadline',
        headerText: t(`${prefix}.endLoadDate`),
        cellFormat: (options: CellFormatOptions<PeriodType>) =>
          formatDate(options.row.loadingDeadline || '', 'DD/MM/YYYY'),
      },
      {
        columnName: 'state',
        headerText: t(`${prefix}.periodState`),
        cellFormat: (options: CellFormatOptions<PeriodType>) => {
          const icon = getProperIcon(options.row.state);
          return (
            <div className="text-center">
              <Icon color={icon.color} name={icon.name} className="mr-2" />
              {getStateName(options.row.state)}
            </div>
          );
        },
      },
    ];
    const listColumns = [
      {
        width: '16%',
        columnName: 'id',
        headerText: t(`${prefix}.action`),
        cellFormat: (options: CellFormatOptions<PeriodType>) => (
          <Button
            size="sm"
            fullwidth
            color="primary"
            text={getButtonName(options.row.state)}
            onClick={() => goToPeriodDetails(options.row.id)}
          />
        ),
      },
    ];
    return periodId ? defaultColumns : [...defaultColumns, ...listColumns];
  }, [periodId, getStateName, getButtonName, goToPeriodDetails, t]);

  return (
    <Table
      columns={columns}
      borderless
      isLoadingResults={loading}
      data={periods}
      noResultsText={t(`${prefix}.noPeriodsBody`)}
      pagination={pagination}
    />
  );
};

export default PeriodsTable;
