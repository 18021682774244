import { SectionDetail } from '../../../types/sectionMaintainerTypes';
import GeneralLoader from '../contexts/GeneralLoader';
import SectionForm from './SectionForm';

export default function SectionUpdateGeneral({
  section,
  onRefresh,
}: {
  section: SectionDetail;
  onRefresh: () => void;
}) {
  return (
    <GeneralLoader sectionId={section.id}>
      {({ data }) => (
        <div className="mt-2 mx-3">
          <SectionForm
            section={section}
            activities={data.activities}
            campuses={data.campuses}
            onSave={() => {
              onRefresh();
            }}
          />
        </div>
      )}
    </GeneralLoader>
  );
}
