import { Button, SelectOptionType, addToast } from '@octano/global-ui';
import { useCallback, useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { Col, Form, Row } from 'reactstrap';
import { v4 as uuidv4 } from 'uuid';

import {
  asignPackageToSectionRequest,
  getPackagesByPeriodIdRequest,
} from '../../../api/requests/sectionsMaintainer';
import DisplayError from '../../../components/info/DisplayError';
import { SectionTitle } from '../../../components/text';
import { PackageList } from '../../../types/packageTypes';
import AsignPackageToSectionFields from './AsignPackageToSectionFields';
import SectionSchedule from './SectionSchedule';

export type AsignPackageToSectionFormType = {
  pkg: SelectOptionType | null;
  pkgs: (SelectOptionType | null)[];
};

const AsignPackageToSectionForm = ({ periodId }: { periodId: number }) => {
  const { t } = useTranslation();
  const prefix = 'sectionsMaintainer.create';
  const [isUnexpectedError, setIsUnexpectedError] = useState<boolean>(false);
  const { sectionId } = useParams<{ sectionId: string }>();
  const [packages, setPackages] = useState<PackageList[]>();
  const [isTherePackages, setIsTherePackages] = useState<boolean>(false);
  const [pkgId, setPkgId] = useState<number | undefined>(undefined);
  const [newPkgs, setNewPkgs] = useState<SelectOptionType[]>([]);

  const getPackages = useCallback(
    async (period: number, idSection: string) => {
      const { data, error } = await getPackagesByPeriodIdRequest(
        idSection,
        period,
        idSection,
      );

      if (data) {
        setPackages(data);
      }
      if (error) {
        setIsUnexpectedError(true);
      }
    },
    [setIsUnexpectedError],
  );

  const methods = useForm<AsignPackageToSectionFormType>({
    defaultValues: {
      pkgs: [],
      pkg: undefined,
    },
  });
  const { watch, reset } = methods;
  const packageId = watch('pkg');

  const onSubmit = useCallback(
    async (values: AsignPackageToSectionFormType) => {
      if (isTherePackages) {
        const { pkg, pkgs } = values;
        const auxPackages: number[] =
          pkgs && pkgs.length > 0
            ? pkgs.filter((item) => item?.value).map((item) => +item!.value)
            : [];
        if (pkg?.value) auxPackages.push(+pkg.value);

        const { data, error } = await asignPackageToSectionRequest(sectionId, {
          packagesIds: auxPackages,
        });
        if (data && !error) {
          addToast({
            icon: 'success',
            color: 'success',
            text: t(`${prefix}.packageSuccess`),
          });
        } else if (error && error.status && error.status === 400) {
          addToast({
            icon: 'error',
            color: 'danger',
            text: t(`${prefix}.duplicatedSectionError`),
          });
        } else {
          setIsUnexpectedError(true);
        }
      }
    },
    [t, setIsUnexpectedError, sectionId, isTherePackages],
  );

  const setDefaultValues = useCallback(() => {
    const mapPackage = (pkg: PackageList, withUUID = false) => {
      const mapped = {
        value: pkg.id,
        label: [
          pkg.name,
          pkg.academicOfferStudyPlanVersionOffer.studyPlanVersionOffer
            .studyPlanVersion.studyPlan.name,
          pkg.academicOfferStudyPlanVersionOffer.studyPlanVersionOffer
            .studyPlanVersion.studyPlan.school.name,
          pkg.academicOfferStudyPlanVersionOffer.studyPlanVersionOffer
            .studyPlanVersion.studyPlan.modality.name,
        ].join(' - '),
      };

      return withUUID ? { ...mapped, code: uuidv4() } : mapped;
    };

    reset({
      pkg: packages && mapPackage(packages[0]),
      pkgs: packages?.slice(1).map((el) => mapPackage(el, true)),
    });
  }, [packages, reset]);

  const setNextPackageId = useCallback(
    (type: string) => {
      if (type === 'pkg') setPkgId(packageId ? +packageId.value : undefined);
      if (type === 'pkgs') {
        const lastNewPkg = newPkgs[newPkgs.length - 1];

        setPkgId(() => {
          if (newPkgs?.length && +lastNewPkg.value) {
            return +lastNewPkg.value;
          } else if (packageId) {
            return +packageId?.value;
          }

          return undefined;
        });
      }
    },
    [packageId, newPkgs],
  );

  useEffect(() => {
    if (sectionId && periodId) {
      getPackages(periodId, sectionId);
    }
  }, [getPackages, periodId, sectionId]);

  useEffect(() => {
    if (packages && packages.length > 0) setDefaultValues();
  }, [setDefaultValues, packages]);

  useEffect(() => {
    if (packageId?.value) {
      setNextPackageId('pkg');
    } else {
      setNextPackageId('pkgs');
    }
  }, [packageId, setNextPackageId]);

  useEffect(() => {
    if (newPkgs && newPkgs.length > 0) {
      setNextPackageId('pkgs');
    } else {
      setNextPackageId('pkg');
    }
  }, [newPkgs, setNextPackageId]);

  if (isUnexpectedError) {
    return (
      <div className="mx-3">
        <DisplayError
          title={t('packages.loadErrorTitle')}
          textBody={t('packages.loadErrorBody')}
          retryAction={methods.handleSubmit(onSubmit)}
          loadingAction={methods.formState.isSubmitting}
          insideCard
        />
      </div>
    );
  }

  return (
    <div className="mt-2 mx-3">
      <SectionTitle text={t(`${prefix}.step2.title`)} />
      <p className="fs-16 pb-4">{t(`${prefix}.step2.desc`)}</p>

      <FormProvider {...methods}>
        <Form onSubmit={methods.handleSubmit(onSubmit)}>
          <AsignPackageToSectionFields
            sectionId={sectionId}
            periodId={periodId}
            setIsTherePackages={setIsTherePackages}
            onChangeArray={setNewPkgs}
          />
          <SectionSchedule sectionId={sectionId} packageId={pkgId} />

          <Row className="py-5 justify-content-end pt-5">
            <Col xs={12} lg={3} className="pb-2 order-2 order-lg-1"></Col>
            <Col xs={12} lg={3} className="pb-2 order-1 order-lg-2">
              <Button
                type="submit"
                text={
                  isTherePackages
                    ? t(`common.actions.saveAndContinue`)
                    : t(`${prefix}.continue`)
                }
                loading={methods.formState.isSubmitting}
                fullwidth
              />
            </Col>
          </Row>
        </Form>
      </FormProvider>
    </div>
  );
};

export default AsignPackageToSectionForm;
