import { ColumnTable } from '@octano/global-ui';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { AvailableSectionEnrollment } from '../../../types/courseRegistrationTypes';
import { isOvercrowded } from '../../../utils/courseSection';
import { formatClassSchedule } from '../../../utils/schedule';

interface Params {
  // Se necesita pasar la data, porque esta es una tabla de una sola fila y con esa fila se calcula que se muestra
  // en el header, si "Sobrecupo" o "Cupos disponibles"
  tableData?: AvailableSectionEnrollment;
}

export const useColumnsSectionQuotas = ({ tableData }: Params) => {
  const prefix = 'courseRegistrationList.detail.newCourse';
  const { t } = useTranslation();

  const showOvercrowdedHeader = isOvercrowded(tableData?.slots || 0);

  const columns: ColumnTable<AvailableSectionEnrollment>[] = useMemo(() => {
    return [
      {
        columnName: 'maximumStudent',
        width: '15%',
        thClassName: 'text-center',
        tdClassName: 'text-center align-text-top',
        headerText: t(`${prefix}.totalQuota`),
      },
      {
        columnName: 'slots',
        width: '15%',
        thClassName: 'text-center',
        tdClassName: 'text-center align-text-top',
        headerText: showOvercrowdedHeader
          ? t(`${prefix}.overcrowed`)
          : t(`${prefix}.availableQuota`),

        cellFormat: ({ value }) => {
          const overcrowed = isOvercrowded(value);
          return (
            <p className={overcrowed ? 'text-danger' : ''}>{Math.abs(value)}</p>
          );
        },
      },
      {
        columnName: 'professors',
        width: '25%',
        thClassName: 'text-center',
        tdClassName: 'text-center align-text-top',
        headerText: t(`${prefix}.teacher`),
        cellFormat: ({ row }) => {
          return row.professors.map((professor, i) => {
            return (
              <div key={`professor_${i}`}>{professor.account.fullName}</div>
            );
          });
        },
      },
      {
        columnName: 'SectionSchedule',
        width: '25%',
        thClassName: 'text-center',
        tdClassName: 'text-center align-text-top',
        headerText: t(`${prefix}.schedule`),
        cellFormat: ({ row }) => {
          return row.sectionSchedules.map((schedule, i) => {
            return (
              <div key={`schedule_${i}`}>
                {schedule.day}{' '}
                {formatClassSchedule(schedule.startModule, schedule.endModule)}
              </div>
            );
          });
        },
      },
      {
        columnName: 'asd',
        width: '20%',
        thClassName: 'text-center',
        tdClassName: 'text-center align-text-top',
        headerText: t(`${prefix}.classroom`),
        cellFormat: ({ row }) => {
          return row.sectionSchedules.map((schedule, i) => {
            return <div key={`schedule_${i}`}>{schedule.classroom.name}</div>;
          });
        },
      },
    ];
  }, [t, showOvercrowdedHeader]);

  return columns;
};
