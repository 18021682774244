import {
  Button,
  DateInput,
  FileInputControlled,
  Modal,
  Select,
  SelectOptionType,
  TextAreaInput,
  TextInput,
} from '@octano/global-ui';
import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from 'react';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { Col, Container, Row } from 'reactstrap';
import { useValidations } from '../../../../../../../hooks/useValidations';
import { useTexts } from '../../hooks/textsContext';
import { UseActions } from '../../hooks/useActions';
import { EducationalBackgroundType } from '../../type';
import { useEducationalBackgroundFormDataLoader } from '../Loaders/EducationalBackgroundFormDataLoader';

export interface CreateOrUpdateRef {
  closeModal: () => void;
  openModal: (row?: any) => void;
}

interface Props {
  actions: UseActions;
  modalType: EducationalBackgroundType;
}

interface FormData {
  id: number | null;
  name: string;
  studyCarriedOut: string;
  originInstitute: string;
  dateOfIssue: Date;
  description: string;
  country: SelectOptionType | null;
  degreeLevel: SelectOptionType | null;
  linkedFile: File & { url: string; name: string; extension: string };
  type: EducationalBackgroundType;
}

const optionFormat = (option: { id: number; name: string }) => ({
  label: option.name,
  value: option.id,
});

const CreateOrUpdateModal = forwardRef<CreateOrUpdateRef, Props>(
  ({ actions, modalType }, ref) => {
    const { teacherId } = useParams<{ teacherId: string }>();
    const texts = useTexts();

    const forms = useForm<FormData>({
      mode: 'onChange',
    });
    const {
      control,
      reset,
      handleSubmit,
      formState: { isSubmitting },
    } = forms;

    const { data } = useEducationalBackgroundFormDataLoader();
    const { msgValidations } = useValidations();
    const [modal, setModal] = useState(false);
    const [formData, setFormData] = useState<FormData | null>(null);

    const countries = useMemo(() => data?.countries || [], [
      data?.countries,
    ]) as SelectOptionType[];

    const degreeLevels = useMemo(() => data?.degreeLevels || [], [
      data?.degreeLevels,
    ]) as SelectOptionType[];

    const specialtyLevels = useMemo(() => data?.specialtyLevels || [], [
      data?.specialtyLevels,
    ]) as SelectOptionType[];

    const currentType = useMemo(() => {
      return formData?.type ?? modalType;
    }, [formData, modalType]);

    useImperativeHandle(ref, () => ({
      openModal(row) {
        setFormData(row ?? null);
        setModal(true);
      },
      closeModal() {
        setModal(false);
      },
    }));

    useEffect(() => {
      if (formData) {
        reset({
          id: formData.id,
          name: formData.name,
          country: formData.country
            ? optionFormat(formData.country as any)
            : null,
          degreeLevel: formData.degreeLevel
            ? optionFormat(formData.degreeLevel as any)
            : null,
          studyCarriedOut: formData?.studyCarriedOut,
          originInstitute: formData?.originInstitute,
          dateOfIssue: formData.dateOfIssue,
          description: formData?.description,
          linkedFile: formData?.linkedFile,
          type: currentType,
        });
      } else {
        reset({
          id: null,
          name: '',
          country: null,
          degreeLevel: null,
          studyCarriedOut: '',
          originInstitute: '',
          dateOfIssue: '',
          description: '',
          type: currentType,
        });
      }
    }, [formData, reset, currentType]);

    const onSubmit = async (values: FormData) => {
      const data = {
        name: values.name,
        originInstitute: values.originInstitute,
        dateOfIssue: values.dateOfIssue,
        studyCarriedOut: values?.studyCarriedOut,
        description: values?.description,
        countryId: values.country?.value ? values.country?.value : undefined,
        degreeLevelId: values.degreeLevel?.value
          ? Number(values.degreeLevel?.value)
          : undefined,
        doc: values?.linkedFile,
        type: currentType,
      };

      if (formData) {
        await actions.updateTitle(Number(formData.id), data);
      } else {
        await actions.createTitle(Number(teacherId), data);
      }
      reset();
      setModal(false);
    };

    return (
      <Modal isOpen={modal} toggle={() => setModal(!modal)} size="lg">
        <Container fluid>
          <h1 className="text-dark fs-20 mt-3 mb-4 text-center">
            {texts.modal[currentType]}
          </h1>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Row>
              {currentType !== EducationalBackgroundType.Certificate && (
                <>
                  <Col md={6} xs={12}>
                    <Select
                      name="degreeLevel"
                      options={
                        currentType === EducationalBackgroundType.AcademicDegree
                          ? degreeLevels
                          : specialtyLevels
                      }
                      label={texts.form.degreeLevel}
                      placeholder={texts.form.degreeLevel}
                      control={control}
                      rules={{ required: msgValidations.required }}
                    />
                  </Col>
                  <Col md={6} xs={12}>
                    <TextInput
                      name="studyCarriedOut"
                      label={texts.form.studyCarriedOut}
                      control={control}
                      rules={{ required: msgValidations.required }}
                    />
                  </Col>
                </>
              )}
              <Col md={6} xs={12}>
                <TextInput
                  name="name"
                  label={
                    currentType === EducationalBackgroundType.Certificate
                      ? texts.form.name
                      : texts.form.title
                  }
                  control={control}
                  rules={{ required: msgValidations.required }}
                />
              </Col>
              <Col md={6} xs={12}>
                <Select
                  name="country"
                  options={countries}
                  label={texts.form.country}
                  control={control}
                  rules={{ required: msgValidations.required }}
                />
              </Col>
              <Col md={6} xs={12}>
                <TextInput
                  name="originInstitute"
                  label={
                    currentType === EducationalBackgroundType.Certificate
                      ? texts.form.originInstituteOrPlataform
                      : texts.form.originInstitute
                  }
                  placeholder={
                    currentType === EducationalBackgroundType.Certificate
                      ? texts.form.originInstituteOrPlataform
                      : texts.form.originInstitute
                  }
                  control={control}
                  rules={{ required: msgValidations.required }}
                />
              </Col>
              <Col md={6} xs={12}>
                <DateInput
                  name="dateOfIssue"
                  label={texts.form.dateOfIssue}
                  control={control}
                  rules={{ required: msgValidations.required }}
                />
              </Col>

              <Col md={12} xs={12}>
                <TextAreaInput
                  name="description"
                  label={texts.form.description}
                  placeholder={''}
                  control={control}
                />
              </Col>

              <Col md={12} xs={12} className="pb-5">
                <FileInputControlled
                  name="linkedFile"
                  label={texts.form.linkedFile}
                  accept="image/png, image/jpeg, application/pdf"
                  btnText={texts.actions.upload}
                  control={control}
                />
              </Col>

              <hr className="py-5" />
              <Col xs={12} md={6}>
                <Button
                  outlined
                  text={texts.actions.cancel}
                  fullwidth
                  onClick={() => {
                    reset();
                    setModal(false);
                  }}
                  className="mb-3"
                />
              </Col>
              <Col xs={12} md={6}>
                <Button
                  type="submit"
                  text={
                    currentType === EducationalBackgroundType.Certificate
                      ? texts.actions.add
                      : texts.actions.save
                  }
                  className="mb-3"
                  fullwidth
                  loading={isSubmitting}
                  disabled={isSubmitting}
                />
              </Col>
            </Row>
          </form>
        </Container>
      </Modal>
    );
  },
);

export default CreateOrUpdateModal;
