import React from 'react';
import { useTranslation } from 'react-i18next';

import ConfirmationModal from '../../../components/modals/ConfirmationModal';

interface ModalExceedQuotaProps {
  isOpen: boolean;
  toggle: () => void;
  onConfirm: () => void;
  onCancel: () => void;
}

const ModalExceedQuota = ({
  isOpen,
  toggle,
  onConfirm,
  onCancel,
}: ModalExceedQuotaProps) => {
  const prefix = 'courseRegistrationList.detail.modalExceedQuota';
  const { t } = useTranslation();

  return (
    <ConfirmationModal
      iconName="warning"
      isOpen={isOpen}
      toggle={toggle}
      title={t(`${prefix}.title`)}
      body={t(`${prefix}.body`)}
      primaryBtn={{
        text: t(`common.actions.confirm`),
        action: () => {
          onConfirm();
          toggle();
        },
      }}
      secondaryBtn={{
        text: t(`common.actions.cancel`),
        action: () => {
          onCancel();
          toggle();
        },
      }}
    />
  );
};

export default ModalExceedQuota;
