import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Card, Container } from 'reactstrap';
import TabControl from '../../../components/tabs/TabControl';
import { PathsLayouts } from '../../../config/routes';
import RoleList from './Role/RoleList/';
import AdminRoleLoader from './Role/RoleList/parts/AdminRoleLoader';
import AdminAccountLoader from './User/UserList/parts/AdminAccountLoader';
import UserList from './User/UserList/UserList';

export default function UsersAndRoles() {
  const tabsPrefix = 'usersAndRoles';
  const { t } = useTranslation();

  const tabs = useMemo(
    () => [
      {
        name: 'users',
        content: (
          <AdminAccountLoader>
            <UserList dictPrefix={`${tabsPrefix}.users`} />
          </AdminAccountLoader>
        ),
      },
      {
        name: 'roles',
        content: <AdminRoleLoader children={<RoleList />} />,
      },
    ],
    [tabsPrefix],
  );

  return (
    <Container fluid>
      <Card className="">
        <p className="text-medium fs-16 mt-3 mb-5 mx-4">
          {t(`${tabsPrefix}.title`)}
        </p>
        <TabControl
          tabsPrefix={`${tabsPrefix}.tabs`}
          tabs={tabs}
          path={PathsLayouts.usersAndRoles}
        />
      </Card>
    </Container>
  );
}
