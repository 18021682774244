import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Col } from 'reactstrap';

import DisplayInfo from '../../../components/info/DisplayInfo';
import Loading from '../../../components/info/Loading';
import TuitionContinuityDocumentReviewDetail, {
  TuitionContinuityDocumentReviewDetailProps,
} from './TuitionContinuityDocumentReviewDetail';

export default function TuitionContinuityDocumentReviewDetailList({
  offerList,
  isLoading,
}: {
  offerList: TuitionContinuityDocumentReviewDetailProps[];
  isLoading: boolean;
}) {
  const { t } = useTranslation();

  const text = useMemo(() => {
    const prefix = 'TuitionContinuityDocumentReview.listEmpty';
    return {
      title: t(`${prefix}.title`),
      body: t(`${prefix}.body`),
    };
  }, [t]);

  if (isLoading) {
    return (
      <Col md={12}>
        <Loading insideCard />
      </Col>
    );
  }
  if (offerList.length <= 0) {
    return (
      <Col md={12}>
        <DisplayInfo insideCard title={text.title} textBody={text.body} />
      </Col>
    );
  }

  return (
    <>
      {offerList.map((off, index) => (
        <Col md={4} xs={12} key={index} className="mb-4">
          <TuitionContinuityDocumentReviewDetail {...off} />
        </Col>
      ))}
    </>
  );
}
