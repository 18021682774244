import { ColumnTable, DisplayInfo, Table } from '@octano/global-ui';
import { useTranslation } from 'react-i18next';

const dictPrefix =
  'graduationProcessSinglePendingRequirerments.pendingOptativeCreditsTable';

type PendingOptativeCreditsTableRow = any;

interface Props {
  data: any[];
}

export function PendingOptativeCreditsTable({ data }: Props) {
  const { t } = useTranslation();

  const columns: ColumnTable<PendingOptativeCreditsTableRow>[] = [
    {
      headerText: t(`${dictPrefix}.optativeType`),
      columnName: 'name',
      thClassName: 'text-center',
      tdClassName: ({ row }) =>
        row.isTotalRow ? 'text-center bg-tertiary fw-600' : 'text-center',
    },
    {
      headerText: '',
      columnName: '',
      width: '60%',
      tdClassName: ({ row }) => (row.isTotalRow ? 'bg-tertiary ' : ''),
    },
    {
      headerText: t(`${dictPrefix}.missingCredits`),
      columnName: 'credits',
      thClassName: 'text-center',
      tdClassName: ({ row }) =>
        row.isTotalRow ? 'text-center bg-tertiary fw-600' : 'text-center',
    },
  ];

  return (
    <Table
      data={data}
      columns={columns}
      noResultsText={
        <div className="d-flex justify-content-center align-items-center">
          <DisplayInfo
            iconName="information"
            textBody={t(`${dictPrefix}.missingOptionalCoursesAlert`)}
            maxWidth="650px"
          />
        </div>
      }
    />
  );
}
