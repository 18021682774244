import { ACADEMIC_OFFER_INTERIM_DEGREE } from '../endpoints';
import request from '../request';
import { PaginationRequestType } from '../../types/paginationRequestType';
import { Curriculum } from '../../views/academicOffers/InterimDegree/interfaces/curriculum.interface';
import {
  InterimDegree,
  InterimDegreeStatus,
} from '../../views/academicOffers/InterimDegree/interfaces/interimDegree.interface';
import { CurriculumForm } from '../../views/academicOffers/InterimDegree/interfaces/curriculum-form.interface';
import { CurriculumFilters } from '../../views/academicOffers/InterimDegree/interfaces/curriculum-filters.interface';
import { InterimDegreeActivityFormItem } from '../../views/academicOffers/InterimDegree/parts/InterimDegreeForm';
import { InterimDegreeActivity } from '../../views/academicOffers/InterimDegree/interfaces/interim-degree-activity.interface';

/**
 * Obtiene el listado de actividades
 */
export const getInterimDegreeActivities = () => {
  const url = `${ACADEMIC_OFFER_INTERIM_DEGREE.INTERIM_DEGREES_ACTIVITIES}`;
  return request<InterimDegreeActivity[]>(url);
};

/**
 * Obtiene el listado de mallas
 */
export const getCurriculums = ({
  items = 10,
  page = 0,
  ...filters
}: {
  items: number;
  page: number;
} & CurriculumFilters) => {
  let url = `${ACADEMIC_OFFER_INTERIM_DEGREE.CURRICULUMS_LIST}?items_per_page=${items}&page=${page}`;
  Object.entries(filters).forEach(([key, value]) => {
    if (value) {
      url += `&${key}=${value}`;
    }
  });
  return request<PaginationRequestType<Curriculum>>(url);
};

/**
 * Obtiene el listado de mallas
 */
export const getInterimDegress = ({
  items = 10,
  page = 0,
  curriculumId = 0,
}: {
  items: number;
  page: number;
  curriculumId: string | number;
}) => {
  const url = `${ACADEMIC_OFFER_INTERIM_DEGREE.INTERIM_DEGREES}?items_per_page=${items}&page=${page}&curriculumId=${curriculumId}`;
  return request<PaginationRequestType<InterimDegree>>(url);
};

/**
 * Obtiene el listado de mallas
 */
export const createInterimDegree = (data: {
  description: string;
  name: string;
  curriculumId: number;
  hasActivities: boolean;
  semesterCoursesIds: number[];
  activities: InterimDegreeActivityFormItem[];
}) => {
  const url = `${ACADEMIC_OFFER_INTERIM_DEGREE.INTERIM_DEGREES}`;
  return request<InterimDegree>(url, { method: 'POST', data });
};

/**
 * Muestra un titulo intermedio
 */
export const showInterimDegree = (id: string | number) => {
  const url = ACADEMIC_OFFER_INTERIM_DEGREE.INTERIM_DEGREE(id);
  return request<InterimDegree>(url);
};

/**
 * Actualiza un titulo intermedio
 */
export const updateInterimDegree = ({
  id,
  ...data
}: {
  description: string;
  name: string;
  id: number;
  hasActivities: boolean;
  semesterCoursesIds: number[];
  activities: InterimDegreeActivityFormItem[];
}) => {
  const url = ACADEMIC_OFFER_INTERIM_DEGREE.INTERIM_DEGREE(id);
  return request<InterimDegree>(url, { method: 'PUT', data });
};

/**
 * Actualiza el estado de un titulo intermedio
 */
export const updateInterimDegreeStatus = (
  id: string | number,
  status: InterimDegreeStatus,
) => {
  const url = ACADEMIC_OFFER_INTERIM_DEGREE.INTERIM_DEGREE_STATUS(id);
  return request<InterimDegree>(url, { method: 'PUT', data: { status } });
};

/**
 * Borra un titulo intermedio
 */
export const deleteInterimDegree = (id: number) => {
  const url = ACADEMIC_OFFER_INTERIM_DEGREE.INTERIM_DEGREE(id);
  return request<InterimDegree>(url, { method: 'DELETE' });
};

/**
 * Obtiene los detalles de una malla
 */
export const getCurriculum = (id: string | number, create?: boolean) => {
  const url = create
    ? ACADEMIC_OFFER_INTERIM_DEGREE.CURRICULUM_FULL(id)
    : ACADEMIC_OFFER_INTERIM_DEGREE.CURRICULUM(id);
  return request<Curriculum>(url);
};

/**
 * Obtiene los datos para filtrar las mallas
 */
export const curriculumForms = () => {
  const url = ACADEMIC_OFFER_INTERIM_DEGREE.CURRICULUMS_FORM;
  return request<CurriculumForm>(url);
};
