import { Box, Select } from '@octano/global-ui';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'reactstrap';
import { useParameters } from '../../../../../../../hooks/useParameters';

const fields = {
  role: 'role',
};

const CreateUserFormRolFields = ({ prefix: subPrefix }: { prefix: string }) => {
  const { t } = useTranslation();
  const prefix = `${subPrefix}.role`;

  const { control, watch } = useFormContext();
  const { roleOptions } = useParameters();

  const role = watch(`${fields.role}`);
  return (
    <>
      <Row>
        <Col md={12}>
          <Select
            label={t(prefix + '.' + fields.role)}
            name={`${fields.role}`}
            control={control}
            isSearchable={true}
            options={roleOptions}
          />
        </Col>
        {role && (
          <Col md={12}>
            <Box
              variant="outlined"
              color="secondary"
              title={`Descripción de rol`}
              body={role?.description ?? '-'}
              fullwidth
              style={{ padding: '10px 15px' }}
            />
          </Col>
        )}
      </Row>
    </>
  );
};

export default CreateUserFormRolFields;
