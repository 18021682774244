import React from 'react';

import StudentStatus from './parts/StudentStatus';
import StudentStatusFilterLoader from './parts/StudentStatusFilterLoader';

function index() {
  return (
    <StudentStatusFilterLoader>
      {(props) => <StudentStatus {...props} />}
    </StudentStatusFilterLoader>
  );
}

export default index;
