import { Button, ColumnTable, DisplayInfo, Table } from '@octano/global-ui';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { TeacherFileItemAction } from '../enums/teacher-file-item-action.enum';
import { TeacherFileItem } from '../interfaces/teacher-file-item.interface';
import BadgeState from './BadgeState';

const dictPrefix = 'teacherFile.list';

interface Props<T = TeacherFileItem> {
  data: T[];
  isLoadingResults: boolean;
  itemPerPage: number;
  totalItems: number;
  totalPages: number;
  currentPage: number;
  hasActivationPermissions?: boolean;
  onChangePage: (page: number) => void;
  onPressAction: (action: TeacherFileItemAction, item: T) => void;
}

export function TeachersTable({
  data,
  isLoadingResults,
  itemPerPage,
  totalPages,
  totalItems,
  currentPage,
  hasActivationPermissions = false,
  onChangePage,
  onPressAction,
}: Props) {
  const { t } = useTranslation();

  const columns = useMemo(() => {
    const cols: ColumnTable<TeacherFileItem>[] = [
      {
        headerText: t(`${dictPrefix}.table.name`),
        columnName: 'identification',
        tdClassName: 'text-center',
        thClassName: 'text-center',
        width: 200,
        cellFormat: ({ row }) => (
          <div>
            <span className="d-block text-center">{row?.name}</span>
            <span className="d-block text-center">{row?.identification}</span>
          </div>
        ),
      },
      {
        headerText: t(`${dictPrefix}.table.email`),
        columnName: 'email',
        tdClassName: 'text-center',
        thClassName: 'text-center',
        width: 200,
      },
      {
        headerText: t(`${dictPrefix}.table.studyPlan`),
        columnName: 'studyPlan',
        tdClassName: 'text-center',
        thClassName: 'text-center',
      },
      {
        headerText: t(`${dictPrefix}.table.campus`),
        columnName: 'campus',
        tdClassName: 'text-center',
        thClassName: 'text-center',
        width: 180,
      },
      {
        headerText: t(`${dictPrefix}.table.status`),
        columnName: 'status',
        tdClassName: 'text-center',
        thClassName: 'text-center',
        width: 180,
        cellFormat: ({ value }) => (
          <>
            <BadgeState
              text={t(`${dictPrefix}.statuses.${value}`)}
              status={value}
            />
          </>
        ),
      },
      {
        headerText: t(`${dictPrefix}.table.status`),
        columnName: 'id',
        tdClassName: 'text-center',
        thClassName: 'text-center',
        width: 170 * (hasActivationPermissions ? 2 : 1) + 15,
        cellFormat: ({ row }) => (
          <>
            <div
              className="d-flex justify-content-center align-items-center"
              style={{
                gap: 5,
              }}
            >
              {row.actions.includes(TeacherFileItemAction.Edit) && (
                <Button
                  type="button"
                  text={t(`${dictPrefix}.actions.edit`)}
                  style={{ width: 170 }}
                  size="sm"
                  onClick={() => onPressAction(TeacherFileItemAction.Edit, row)}
                />
              )}
            </div>
          </>
        ),
      },
    ];
    return cols;
  }, [hasActivationPermissions, t, onPressAction]);

  return (
    <div className="w-100">
      <Table
        isLoadingResults={isLoadingResults}
        data={data}
        columns={columns}
        pagination={{
          currentPage: currentPage + 1,
          itemsPerPage: itemPerPage,
          totalItems: totalItems,
          totalPages: totalPages,
          onChangePage: (page) => onChangePage(page - 1),
        }}
        noResultsText={
          <div className="d-flex justify-content-center align-items-center py-5">
            <DisplayInfo
              iconName="information"
              textBody={t(`${dictPrefix}.noResults.description`)}
              maxWidth="650px"
            />
          </div>
        }
      />
    </div>
  );
}
