import { Card, Col, Row } from 'reactstrap';
import { useState, useEffect, useCallback, useRef } from 'react';
import { getStudents } from '../../api/requests/interimDegreeGraduationSingle';

import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { addToast, showDialogConfirm, showDialogInfo } from '@octano/global-ui';
import useUserState from '../../hooks/useUserState';
import { PermissionName } from '../../types/Auth';
import DisplayPermissionError from '../../components/info/DisplayPermissionError';
import { InterimDegreesSigleSearchControls } from './parts/InterimDegreesSigleSearchControls';
import InterimDegreeGenerateModal, {
  InterimDegreeGenerateModalMethods,
} from './parts/InterimDegreeGenerateModal';
import { InterimDegreeAuthorizedStudentSingleFilters } from './interfaces/interim-degree-authorized-student-single-filters.interface';
import { InterimDegreeSingleStudentTable } from './parts/InterimDegreeSingleStudentTable';
import { PathsLayouts } from '../../config/routes';
import { InterimDegreeAuthorizedStudentSingle } from './interfaces/interim-degree-authorized-student-single.interface';

export const MODULE_PERMISSIONS = [
  PermissionName.INTERIM_DEGREE_GRADUATION_PROCESS_ALL,
];
const dictPrefix = 'interimDegreeGraduationProcess.single';
const ITEMS_PER_PAGE = 10;

const InterimDegreeGraduationProcessSingle = () => {
  const { t } = useTranslation();
  const { isAuthorizedTo } = useUserState();

  const history = useHistory();

  const generateModalRef = useRef<InterimDegreeGenerateModalMethods>(null);

  const [filters, setFilters] =
    useState<InterimDegreeAuthorizedStudentSingleFilters>();
  const [tableData, setTableData] = useState<
    InterimDegreeAuthorizedStudentSingle[]
  >([]);
  const [isLoadingTable, setIsTableLoading] = useState<boolean>(false);

  const [currentPage, setCurrentPage] = useState<number>(0);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [totalItems, setTotalItems] = useState<number>(0);

  const handleSearch = useCallback(
    async (
      params: { page: number } & InterimDegreeAuthorizedStudentSingleFilters,
    ) => {
      setIsTableLoading(true);
      const { data, error } = await getStudents({
        ...params,
        items_per_page: ITEMS_PER_PAGE,
      });

      if (error) {
        addToast({
          text: t(`${dictPrefix}.errorOnSearch`),
          color: 'danger',
          icon: 'error',
        });
      } else {
        setTableData(data?.data ?? []);
        setTotalPages(data?.total_pages || 0);
        setTotalItems(data?.total || 0);
      }
      setIsTableLoading(false);
    },
    [t],
  );

  const handleProcessFilters = useCallback(
    (next?: InterimDegreeAuthorizedStudentSingleFilters) => {
      if (!next) {
        setCurrentPage(0);
        setFilters(undefined);
        setTableData([]);
        setTotalPages(0);
        setTotalItems(0);
        return;
      }
      if (next !== filters && !isLoadingTable) {
        setCurrentPage(0);
        setFilters(next);
      }
    },
    [filters, isLoadingTable],
  );

  useEffect(() => {
    if (filters) {
      handleSearch({ ...filters, page: currentPage });
    }
  }, [handleSearch, currentPage, filters]);

  const handleGenerate = useCallback(
    (studyPlanEnrollmentId: number, interimDegreeId: number) => {
      generateModalRef?.current?.openSingle(
        studyPlanEnrollmentId,
        interimDegreeId,
      );
    },
    [],
  );

  const handleGenerated = useCallback(() => {
    addToast({
      text: t(`${dictPrefix}.successGenerated`),
      color: 'success',
      icon: 'check',
    });
    if (filters) {
      handleSearch({ ...filters, page: currentPage });
    }
  }, [handleSearch, t, filters, currentPage]);

  const handleGeneratedFaild = useCallback(
    (
      studyPlanEnrollmentId: number,
      interimDegreeId: number,
      reason: string,
    ) => {
      if (reason === 'NOT_MET_REQUIREMENTES') {
        showDialogConfirm({
          icon: {
            name: 'warning',
            color: 'primary',
          },
          title: t(`${dictPrefix}.failedGenerated.title`),
          subtitle: t(`${dictPrefix}.failedGenerated.${reason}`),
          btnClose: {
            text: t(`${dictPrefix}.failedGenerated.cancel`),
            onClose: () => {},
          },
          btnConfirm: {
            text: t(`${dictPrefix}.failedGenerated.confirm`),
            onConfirm: () => {
              history.push(
                `${PathsLayouts.interimDegreeGraduationProcess}/failed/${studyPlanEnrollmentId}/${interimDegreeId}`,
              );
            },
          },
        });
      } else {
        showDialogInfo({
          icon: {
            name: 'warning',
            color: 'primary',
          },
          title: t(`${dictPrefix}.failedGenerated.title`),
          subtitle: t(`${dictPrefix}.failedGenerated.${reason}`),
          btnConfirm: {
            text: t(`${dictPrefix}.failedGenerated.confirmClose`),
            onConfirm: () => {},
          },
        });
      }
    },
    [t, history],
  );

  if (!isAuthorizedTo(MODULE_PERMISSIONS, true)) {
    return (
      <div className="w-full px-3">
        <DisplayPermissionError permissions={MODULE_PERMISSIONS} insideCard />
      </div>
    );
  }

  return (
    <>
      <Card className="p-4 mx-3">
        <Row>
          <Col className="mb-4" xs={12}>
            <p className="fs-20 text-uppercase text-primary mb-0 fw-700">
              {t(`${dictPrefix}.title`)}
            </p>
            <p className="fs-16 mb-0">{t(`${dictPrefix}.description`)}</p>
          </Col>
          <Col className="mb-4" xs={12}>
            <InterimDegreesSigleSearchControls
              onSearch={handleProcessFilters}
              onClear={handleProcessFilters}
            />
          </Col>
          <Col xs={12}>
            <InterimDegreeSingleStudentTable
              data={tableData}
              itemPerPage={Math.max(ITEMS_PER_PAGE, totalItems)}
              totalItems={totalItems}
              totalPages={totalPages}
              currentPage={currentPage}
              isLoadingResults={isLoadingTable}
              hasFilters={!!filters}
              onChangePage={(page) => {
                setCurrentPage(page);
              }}
              onPressGenerate={handleGenerate}
            />
          </Col>
        </Row>
      </Card>
      <InterimDegreeGenerateModal
        ref={generateModalRef}
        onGenerate={handleGenerated}
        onFailedSingle={handleGeneratedFaild}
      />
    </>
  );
};

export default InterimDegreeGraduationProcessSingle;
