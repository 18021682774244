import {
  Button,
  OutlinedSelect,
  OutlinedSelectOptionType,
  SearchBox,
  useMobile,
} from '@octano/global-ui';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'reactstrap';
import { useParameters } from '../../../../../../hooks/useParameters';
import { useValidations } from '../../../../../../hooks/useValidations';
import { useAdminAccountLoader } from './AdminAccountLoader';

const DEFAULT_VALUES = {
  search: '',
  email: '',
  roleId: null,
  hasFilters: false,
};

interface FormValues {
  search: string;
  email: string;
  roleId: OutlinedSelectOptionType | null;
}

interface SearchControlsProps {
  dictPrefix: string;
}

export function SearchControls({ dictPrefix }: SearchControlsProps) {
  const { t } = useTranslation();
  const isMobile = useMobile();
  const { validateMinLength } = useValidations();

  const { roleOptions } = useParameters();

  const { updateQuery } = useAdminAccountLoader();

  const { handleSubmit, control, reset } = useForm<FormValues>({
    defaultValues: DEFAULT_VALUES,
  });

  const handleSearch = (values: FormValues) => {
    const searchParams = {
      search: values.search?.trim() !== '' ? values.search : undefined,
      email: values.email?.trim() !== '' ? values.email : undefined,
      roleId: values.roleId?.value as number,
      hasFilters: Object.values(values).some((value) => value !== null),
    };

    updateQuery({
      page: 1,
      searchParams,
    });
  };

  const handleClear = () => {
    reset();
    updateQuery();
  };

  return (
    <form
      onSubmit={handleSubmit(handleSearch)}
      style={{ gap: isMobile ? 0 : 4 }}
    >
      <Row className="pb-4 gutters-filters">
        <Col xs={12} md={6} lg={4}>
          <SearchBox
            name="search"
            label={t(`${dictPrefix}.nameOrId`)}
            placeholder={t(`${dictPrefix}.nameOrIdPlaceholder`)}
            control={control}
            rules={{
              validate: {
                minLength: validateMinLength(3),
              },
            }}
          />
        </Col>

        <Col xs={12} md={6} lg={4}>
          <OutlinedSelect
            name="roleId"
            label={t(`${dictPrefix}.roles`)}
            placeholder={t(`${dictPrefix}.rolesDefaultValue`)}
            control={control}
            options={roleOptions}
          />
        </Col>

        <Col xs={12} md={6} lg={4}>
          <SearchBox
            name="email"
            label={t(`${dictPrefix}.email`)}
            placeholder={t(`${dictPrefix}.emailPlaceholder`)}
            control={control}
            rules={{
              validate: {
                minLength: validateMinLength(3),
              },
            }}
          />
        </Col>

        <Col
          xs={12}
          md={{ size: 3, offset: 6 }}
          lg={{ size: 2, offset: 8 }}
          style={{ marginTop: isMobile ? 0 : '1.5rem' }}
        >
          <Button
            type="submit"
            text={t(`common.actions.search`)}
            size="md"
            fullwidth
          />
        </Col>
        <Col
          xs={12}
          md={{ size: 3 }}
          lg={2}
          style={{ marginTop: isMobile ? '1rem' : '1.5rem' }}
        >
          <Button
            text={t(`common.actions.clean`)}
            size="md"
            fullwidth
            outlined
            onClick={handleClear}
          />
        </Col>
      </Row>
    </form>
  );
}
