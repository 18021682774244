import { Button } from '@octano/global-ui';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Card, Col, Row } from 'reactstrap';

import { AdmissionListPostulansPending } from '../../../admission/AdmissionListPostulansPending/AdmissionListPostulansPending';
import AdmissionPendingListProvider from '../../../admission/contexts/AdmissionPendingListContext';
import { PathsLayouts } from '../../../config/routes';

export default function AdmissionsPendingList() {
  const { t } = useTranslation();
  const history = useHistory();

  return (
    <Card className="mx-3 px-3">
      <Row className="pt-4 mb-5">
        <Col>
          <Button
            size="sm"
            icon="back"
            onClick={() => history.push(PathsLayouts.admissions)}
            text={t('admission.back')}
            rounded
            outlined
          />
        </Col>
      </Row>
      <AdmissionPendingListProvider>
        <AdmissionListPostulansPending />
      </AdmissionPendingListProvider>
    </Card>
  );
}
