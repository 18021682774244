import { useCallback } from 'react';
import { updateTitle, addTitle, deleteTitle } from '../api';
import { FormDataAction } from '../type';

export interface UseActions {
  createTitle: (teacherId: number, data: FormDataAction) => Promise<void>;
  updateTitle: (degreedId: number, data: FormDataAction) => Promise<void>;
  deleteTitle: (degreedId: number) => Promise<void>;
  downloadTitle: (id: number) => Promise<void>;
}
type ActionType = 'create' | 'update' | 'delete';

export default function useActions(props: {
  onSuccess: (action: ActionType) => void;
  onError: (action: ActionType, message?: string) => void;
}) {
  const { onSuccess, onError } = props;
  const addTitleAction = useCallback(
    async (teacherId: number, data: FormDataAction) => {
      const result = await addTitle(teacherId, data);
      if (!result.error) {
        return onSuccess('create');
      }
      onError('create');
    },
    [onSuccess, onError],
  );

  const updateTitleAction = useCallback(
    async (degreedId, data: FormDataAction) => {
      const result = await updateTitle(degreedId, data);
      if (!result.error) {
        return onSuccess('update');
      }
      onError('update');
    },
    [onSuccess, onError],
  );

  const deleteTitleAction = useCallback(
    async (sectionId: number) => {
      const result = await deleteTitle(sectionId);
      if (!result.error) {
        return onSuccess('delete');
      }
      onError('delete');
    },
    [onSuccess, onError],
  );

  const downloadTitleAction = useCallback(async (file) => {
    if (file) {
      const link = document.createElement('a');
      link.download = file?.name;
      link.href = file?.url;
      link.target = '_blank';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }, []);
  return {
    createTitle: addTitleAction,
    updateTitle: updateTitleAction,
    deleteTitle: deleteTitleAction,
    downloadTitle: downloadTitleAction,
  };
}
