import { useTranslation } from 'react-i18next';

import { ModalConfirmation } from '../../../../components/modals/Confirmation';

export interface PlansNotUpdated {
  studyPlanName: string;
  campusName: string;
  scheduleName: string;
}
interface AcademicOfferConfirmModalProps {
  open: boolean;
  onClose: () => void;
  onConfirm: () => void;
  plansNotUpdated?: Array<PlansNotUpdated>;
}

function AcademicOfferConfirmModal(props: AcademicOfferConfirmModalProps) {
  const { t } = useTranslation();
  const { open, onClose, onConfirm, plansNotUpdated = [] } = props;
  const array = plansNotUpdated.map(
    ({ studyPlanName, campusName, scheduleName }, key) => {
      return (
        <div key={key} style={{ marginBottom: '8px' }}>
          <li>
            {t('academicOffers.modalConfirm.studyPlanes')}:{' '}
            <b>{studyPlanName}</b>
          </li>
          <ul>
            <li>
              {t('academicOffers.modalConfirm.schelude')}: <b>{scheduleName}</b>
            </li>
            <li>
              {t('academicOffers.modalConfirm.campusName')}: <b>{campusName}</b>
            </li>
          </ul>
        </div>
      );
    },
  );
  return (
    <ModalConfirmation
      open={open}
      title={t('academicOffers.modalConfirm.title')}
      subtitle={t('academicOffers.modalConfirm.body')}
      onClose={onClose}
      onConfirm={onConfirm}
      columns={{
        title: t('academicOffers.modalConfirm.plansNotUpdated'),
        li: array,
      }}
    />
  );
}
export default AcademicOfferConfirmModal;
