import { useTranslation } from 'react-i18next';

import ConfirmationModal from '../../../components/modals/ConfirmationModal';

interface ModalConfirmNoRequirementsProps {
  isOpen: boolean;
  toggle: () => void;
  onConfirm: () => void;
  onCancel: () => void;
  pendingRequirements: { name: string; shortening: string }[];
}

const ModalConfirmNoRequirements = ({
  isOpen,
  toggle,
  onConfirm,
  onCancel,
  pendingRequirements,
}: ModalConfirmNoRequirementsProps) => {
  const prefix = 'courseRegistrationList.detail.modalConfirmNoRequirements';
  const { t } = useTranslation();

  return (
    <ConfirmationModal
      iconName="warning"
      isOpen={isOpen}
      toggle={toggle}
      title={t(`${prefix}.title`)}
      body={t(`${prefix}.body`)}
      boxDetail={{
        title: t(`${prefix}.detailTitle`),
        body: pendingRequirements.map((pr) => {
          return (
            <div>
              {t(`${prefix}.shortening`)}:<strong> {pr.shortening}</strong>
              <br />
              {t(`${prefix}.nameCourse`)}:<strong> {pr.name}</strong>
            </div>
          );
        }),
      }}
      primaryBtn={{
        text: t(`common.actions.confirm`),
        action: () => {
          onConfirm();
          toggle();
        },
      }}
      secondaryBtn={{
        text: t(`common.actions.cancel`),
        action: () => {
          onCancel();
          toggle();
        },
      }}
    />
  );
};

export default ModalConfirmNoRequirements;
