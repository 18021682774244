import { Schedule, ScheduleModuleType } from '@octano/global-ui';
import { useTranslation } from 'react-i18next';

import {
  PackagesViewsSection,
  SectionScheduleType,
} from '../../types/packageTypes';
import CourseTypeLegends from './CourseTypeLegends';

export interface PackageViewScheduleSchedules {
  id: number;
  name: string;
  shortening: string;
  startTime: string;
  endTime: string;
}

export interface PackagesViewScheduleProps {
  timeModules: ScheduleModuleType[];
  sections: PackagesViewsSection[] | null;
}

export default function PackagesViewSchedule({
  timeModules,
  sections,
}: PackagesViewScheduleProps) {
  const { t } = useTranslation();

  const teachersTitle = t('packages.view.schedule.teachers');

  return (
    <div>
      <div className="mb-4">
        <Schedule
          modules={timeModules}
          schedules={
            sections?.flatMap((section) =>
              section.sectionSchedules
                .filter(
                  (schedule) =>
                    !schedule.deletedAt &&
                    schedule.type === SectionScheduleType.Default,
                )
                .map((schedule) => ({
                  sectionId: section.id,
                  scheduleId: schedule.id,
                  courseCode: section.course.code,
                  sectionName: section.name,
                  day: schedule.day,
                  activityId: schedule.activity.id,
                  moduleStartId: schedule.startModule.id,
                  moduleEndId:
                    schedule?.endModule?.id ?? schedule.startModule.id,
                  tooltip: {
                    classroom: schedule.classroom?.name || '',
                    courseName: section.course.name,
                    sectionName: section.name,
                    sectionTitle: '',
                    teachers: section.professors.map(
                      (p) => p?.account?.fullName ?? '',
                    ),
                    teachersTitle: teachersTitle,
                  },
                })),
            ) ?? []
          }
        />
      </div>
      <CourseTypeLegends />
    </div>
  );
}
