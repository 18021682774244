import { STUDENT_PETITION_DETAIL } from '../../../api/endpoints';
import request from '../../../api/request';
import { ResponseStory } from '../types/PetitionsStory';

export function getStudentPetitionStory(petitionId: number | string) {
  const url = STUDENT_PETITION_DETAIL.GET_STUDENT_PETITION_STORY(petitionId);
  return request<ResponseStory>(url, {
    method: 'get',
  });
}
