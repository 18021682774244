import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Card } from '../../../components/article/Card';

function toPercentage(value: number) {
  return `${value.toFixed(1)}%`;
}

export interface TuitionContinuityProcessManagementTotalItemProps {
  total: number;
  completed: number;
  started: number;
  notStarted: number;
}

export default function TuitionContinuityProcessManagementTotalItem(
  props: TuitionContinuityProcessManagementTotalItemProps,
) {
  const { t } = useTranslation();
  const prefix = 'tuitionContinuityProcessManagement.totalItem';

  const texts = useMemo(
    () => ({
      title: t(`${prefix}.title`),
      total: t(`${prefix}.total`),
      completed: t(`${prefix}.completed`),
      started: t(`${prefix}.started`),
      notStarted: t(`${prefix}.notStarted`),
      enrolled: t(`${prefix}.enrolled`),
    }),
    [t],
  );

  return (
    <Card
      title={texts.title}
      body={[
        {
          label: texts.total,
          value: props.total,
        },
        {
          label: texts.completed,
          value: props.completed,
        },
        {
          label: texts.started,
          value: props.started,
        },
        {
          label: texts.notStarted,
          value: props.notStarted,
        },
      ]}
      markedText={{
        label: texts.enrolled,
        value: props.total
          ? toPercentage((props.completed * 100) / props.total)
          : '-',
      }}
    />
  );
}
