export const ITEMS_PER_PAGE_DEFAULT = 10;

export interface AdminAccountRow {
  accountId: number;
  fullName: string;
  run: string;
  passportNumber?: string | null;
  email: string;
  phone: string;
  updatedAt: Date;
  countryId: string;
  countryName: string;
  roleId: number;
  roleKey: string;
  roleName: string;
  roleDescription: string;
  isActive: boolean;
  isEnabled: boolean;
  description: string;
}

export interface ResponseAdminAccounts {
  data: AdminAccountRow[];
  total_pages: number;
  total: number;
}
