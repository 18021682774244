import React from 'react';
import DebtLoader from './components/DebtLoader';
import DebtTable from './components/DebtTable';

function Debt() {
  return (
    <DebtLoader>
      <DebtTable />
    </DebtLoader>
  );
}

export default Debt;
