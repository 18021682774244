export interface StudentForm {
  rut: string;
  passportNumber: string;
  country: string;
  names: string;
  lastnamePather: string;
  lastnameMother: string;
  email: string;
  phone: string;
  birthdate?: string;
}

export enum STATUS_POSTULATION {
  PRE_STAGE = 'PRE_STAGE',
  IN_WAITING_LIST = 'IN_WAITING_LIST',
  ENROLLED = 'ENROLLED',
  STUDY_PLAN_CHANGE = 'STUDY_PLAN_CHANGE',
}

export enum POSTULATION_STATUS {
  ENROLLED = 'matriculado',
  IN_PROCESS = 'en_proceso',
  IN_WAITING_LIST = 'en_espera',
  DESISTED = 'desistido',
  NO_POSTULATION = 'sin_postulacion',
  STUDY_PLAN_CHANGE = 'cambio_de_plan_de_studio',
}

export interface ActionForm {
  type: 'button' | 'submit';
  outlined?: boolean;
  color: 'danger' | 'primary' | 'secondary' | undefined;
  text: string;
  onClick?: () => void;
  loading?: boolean;
  value?: string;
  disabled?: boolean;
}

export interface Student extends StudentForm {
  status: string;
  admissionTypeId?: string;
  studyPlanVersion_name?: string;
  campus_name?: string;
  schedule_name?: string;
  postulant_id?: number;
  Details_id?: number;
  newAdmission?: Admission;
  hasMultiTuition?: boolean;
  hasCampusesWithAccess?: boolean;
}

interface Admission {
  postulationDetailId: number;
  postulationDetailStatus: string;
  admissionTypeId: string;
  studyPlanVersionName: string;
  campusName: string;
  scheduleName: string;
}
