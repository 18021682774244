import { isValidNumber } from 'libphonenumber-js';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { clean, validate } from 'rut.js';
import validator from 'validator';

export const useValidations = () => {
  const { t } = useTranslation();
  const prefixValidations = 'common.validations';
  const msgValidations = useMemo(() => {
    return {
      required: t(`${prefixValidations}.required`),
      invalidPhone: t(`${prefixValidations}.invalidPhone`),
      invalidEmail: t(`${prefixValidations}.invalidEmail`),
      invalidRut: t(`${prefixValidations}.invalidRut`),
      invalidBeforeDate: t(`${prefixValidations}.invalidBeforeDate`),
      invalidAfterDate: t(`${prefixValidations}.invalidAfterDate`),
      invalidGreaterField: t(`${prefixValidations}.invalidGreaterValue`),
      invalidAlphaNumeric: t(`${prefixValidations}.invalidAlphanumeric`),
      invalidPassword: t(`${prefixValidations}.invalidPassword`),
      invalidRepeatPassword: t(`${prefixValidations}.invalidRepeatPassword`),
      invalidMinLength: (length: number) =>
        t(`${prefixValidations}.minLength`, { length: length }),
      invalidMaxLength: (length: number) =>
        t(`${prefixValidations}.maxLength`, {
          length: length,
        }),
      invalidIntNumeric: t(`${prefixValidations}.invalidIntNumeric`),
      invalidDecimalNumeric: t(`${prefixValidations}.invalidDecimalNumeric`),
      inValidBeforeModule: t(`${prefixValidations}.invalidBeforeModule`),
    };
  }, [t]);

  /**
   * Valida el formato de la contraseña
   * @param password
   * @returns
   */
  const validatePassword = (password: string) => {
    return password.length >= 8 ? undefined : msgValidations.invalidPassword;
  };

  /**
   * Esta funcion retorna otra funcion de validacion que retorna si la password pasada por parametros es igual a la contraseña repetida
   * @param password
   * @returns
   */
  const validateRepeatPassword = (password: string) => (
    repeatPassword: string,
  ) => {
    return password === repeatPassword
      ? undefined
      : msgValidations.invalidRepeatPassword;
  };

  /**
   * Valida que telefono tenga formato +XXXXXXXXXXX
   * @param phone
   * @returns
   */
  const validatePhone = (phone: string | undefined) => {
    if (phone && phone !== '') {
      const cleanPhone = phone.replaceAll(' ', '');
      if (!isValidNumber(cleanPhone)) {
        return msgValidations.invalidPhone;
      }
    }
    return undefined;
  };

  /**
   * Valida que el texto ingresado tenga formato de correo
   * @param email
   * @returns
   */
  const validateEmail = (email: string | undefined) => {
    if (email && !validator.isEmail(email)) {
      return msgValidations.invalidEmail;
    }
    return undefined;
  };

  /**
   * Función que valida que el texto ingresado no sea vacío
   * y que no contenga sólo espacios.
   * @param value
   * @returns
   */
  const validateTextNotEmpty = (value: any) => {
    if (value === null || value === undefined) {
      return msgValidations.required;
    }

    if (typeof value === 'string' && value.trim().length === 0) {
      return msgValidations.required;
    }

    return undefined;
  };

  /**
   * Función que valida que el texto ingresado sea un rut válido
   * @param value
   * @returns
   */
  const validateRut = (value: any) => {
    if (value && typeof value === 'string' && !validate(clean(value))) {
      return msgValidations.invalidRut;
    }
    return undefined;
  };

  /**
   * Función que valida que el texto solo tenga números y letras
   * @param value
   * @returns
   */
  const validateAlphaNumeric = (value: string | undefined) => {
    if (!value || !/^[a-zA-Z0-9]+$/.test(value)) {
      return;
    }
    return undefined;
  };

  /**
   * Función que valida que la fecha de fin sea mayor que la de inicio
   * @param endDate
   * @returns
   */
  const validateBeforeDate = (endDate?: string) => (
    value: string | undefined,
  ) => {
    if (value && endDate) {
      if (Date.parse(endDate) < Date.parse(value)) {
        return msgValidations.invalidBeforeDate;
      }
    }
    return undefined;
  };

  /**
   * Función que valida que la fecha de inicio sea menor que la de inicio
   * @param endDate
   * @returns
   */
  const validateAfterDate = (startDate?: string) => (
    value: string | undefined,
  ) => {
    if (value && startDate) {
      if (Date.parse(startDate) > Date.parse(value)) {
        return msgValidations.invalidAfterDate;
      }
    }
    return undefined;
  };

  /**
   * Función que valida que el valueA sea mayor que el valueB
   * @param valueA string
   * @param valueB string
   * @returns
   */
  const validateGreaterField = (valueA?: string, valueB?: string) => {
    if (valueB && valueA) {
      if (Number(valueA) > Number(valueB)) {
        return msgValidations.invalidGreaterField;
      }
    }
    return undefined;
  };

  /**
   * Recibe un numero de caracteres y retorna otra funcion que valida la longitud minima del string ingresado
   * @param minLength
   * @returns
   */
  const validateMinLength = (minLength: number) => {
    return (value?: any) =>
      value && typeof value === 'string' && value?.length < minLength
        ? msgValidations.invalidMinLength(minLength)
        : undefined;
  };

  /**
   * Retorna los valores necesario para agregar un pattern en rules
   * para validar que el valor ingresado sea numérico
   */
  const rulesPatternNumeric = useMemo(() => {
    return {
      value: /^\d*$/,
      message: msgValidations.invalidIntNumeric,
    };
  }, [msgValidations]);

  /**
   * Retorna los valores necesario para agregar un pattern en rules
   * para validar que el valor ingresado sea numérico y decimal con (,)
   */
  const rulesPatternDecimal = useMemo(() => {
    return {
      value: /^[0-9]+([.][0-9]+)?$/,
      message: msgValidations.invalidDecimalNumeric,
    };
  }, [msgValidations]);

  return {
    rulesPatternDecimal,
    rulesPatternNumeric,
    validatePhone,
    validateEmail,
    validateTextNotEmpty,
    validateRut,
    validatePassword,
    validateRepeatPassword,
    msgValidations,
    validateBeforeDate,
    validateAfterDate,
    validateAlphaNumeric,
    validateMinLength,
    validateGreaterField,
  };
};
