import { Select, TextInput } from '@octano/global-ui';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'reactstrap';

import { useParameters } from '../../../hooks/useParameters';
import { Postulant } from '../types';

export function FormDataPostulantProccess(props: { postulant: Postulant }) {
  const { t } = useTranslation();
  const { postulant } = props;
  const { countryOptions } = useParameters();
  const countrySelect = useMemo(() => {
    return countryOptions.find(
      ({ value }) =>
        value ===
        (postulant.PostulantPassport_countryId ||
          postulant.Postulant_passportCountryId),
    );
  }, [countryOptions, postulant]);

  const colID = useMemo(() => {
    return postulant.Postulant_rut ? (
      <TextInput
        name="rut"
        label={t('documents.studyDocuments.form.id')}
        type="text"
        formatter="rut"
        disabled
        value={postulant.Postulant_rut}
      />
    ) : (
      <Row>
        <Col
          sm={'auto'}
          lg={'auto'}
          xs="auto"
          className="pr-0"
          style={{ width: '160px' }}
        >
          <Select
            name="passport-country"
            isSearchable={false}
            disabled
            value={countrySelect}
            label={t('documents.searchStudent.inputCountry')}
            options={countryOptions}
          />
        </Col>
        <Col>
          <TextInput
            type="text"
            name="passport-number"
            disabled
            value={
              postulant.PostulantPassport_number ||
              postulant.Postulant_passportNumber
            }
            label={t('documents.searchStudent.inputPassport')}
          />
        </Col>
      </Row>
    );
  }, [postulant, countrySelect, countryOptions, t]);

  return (
    <>
      <Row>
        <Col md={4}>{colID}</Col>
        <Col md={4}>
          <TextInput
            name="name"
            label={t('documents.studyDocuments.form.names')}
            type="text"
            disabled
            value={postulant.Postulant_names}
          />
        </Col>
        <Col md={4}>
          <TextInput
            name="lastnames"
            label={t('documents.studyDocuments.form.lastnames')}
            type="text"
            disabled
            value={`${postulant.Postulant_paternalLastName} ${postulant.Postulant_maternalLastName}`}
          />
        </Col>
      </Row>
      <Row className="mb-5">
        <Col md={6}>
          <TextInput
            name="phone"
            label={t('documents.studyDocuments.form.phone')}
            type="text"
            disabled
            value={postulant.Contact_cellPhone}
          />
        </Col>
        <Col md={6}>
          <TextInput
            name="email"
            label={t('documents.studyDocuments.form.email')}
            type="text"
            disabled
            value={postulant.Contact_email}
          />
        </Col>
      </Row>
    </>
  );
}
