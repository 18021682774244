const withProvider = (
  Component: React.FunctionComponent,
  Provider: React.FunctionComponent,
) => {
  return () => (
    <Provider>
      <Component />
    </Provider>
  );
};

export default withProvider;
