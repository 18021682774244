import React, { useState } from 'react';
import { Button, Collapse } from 'reactstrap';

import styles from './accordion.module.scss';

interface IAccordionProps {
  children: any;
  open: number | undefined;
}

interface IAccordionItemProps {
  children: any;
  isOpen?: boolean;
  onClick?: () => void;
}

interface IAccordionHeaderProps {
  children: React.ReactChild[];
  isOpen?: boolean;
  onClick?: () => void;
}

interface IAccordionBodyProps {
  children: React.ReactChild;
  isOpen?: boolean;
}

export function Accordion(props: IAccordionProps) {
  const [open, setOpen] = useState<number | undefined>(props.open);

  const toggleSection = (index: number) => () => {
    if (index !== open) setOpen(index);
    else setOpen(undefined);
  };

  return (
    <div>
      {React.Children.map(props.children, (child, index) => {
        if (child.type !== AccordionItem) return null;
        return React.cloneElement(child, {
          isOpen: child.props.open || open === index,
          onClick: toggleSection(index),
        });
      })}
    </div>
  );
}

const AccordionItem = ({ children, isOpen, onClick }: IAccordionItemProps) => (
  <div>
    {React.Children.map(children, (child) => {
      if (child.type === AccordionHeader) {
        return React.cloneElement(child, { onClick, isOpen });
      }

      if (child.type === AccordionBody) {
        return React.cloneElement(child, { isOpen });
      }

      return null;
    })}
  </div>
);

const AccordionHeader = ({
  children,
  onClick,
  isOpen,
}: IAccordionHeaderProps) => (
  <div className="d-flex m-0 p-0">
    {children.map((child, index) => {
      return (
        <div key={index} className={index === 0 ? 'mr-auto p-2' : ' p-2'}>
          {child}
        </div>
      );
    })}

    <div className="m-2">
      <Button
        type="button"
        color="white"
        onClick={onClick}
        className={`bg-secondary d-flex align-items-center justify-content-center ${styles.plusButton} `}
      >
        {isOpen ? <>&#x2212;</> : <>&#x2b;</>}
      </Button>
    </div>
  </div>
);

const AccordionBody = ({ children, isOpen }: IAccordionBodyProps) => (
  <Collapse isOpen={isOpen}>
    <div className="bg-white">{children}</div>
  </Collapse>
);

Accordion.Item = AccordionItem;
Accordion.Header = AccordionHeader;
Accordion.Body = AccordionBody;
