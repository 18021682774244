import { ColumnTable, Button } from '@octano/global-ui';
import { useMemo } from 'react';
import useUserState from '../../../../hooks/useUserState';
import { PermissionName } from '../../../../types/Auth';
import { useTexts } from './textsContext';

interface actionsColumns {
  onClickEdit: (row: any) => void;
}

export default function useColumnActions({
  onClickEdit,
}: actionsColumns): ColumnTable[] {
  const texts = useTexts();
  const { isAuthorizedTo } = useUserState();

  const showSave = useMemo(() => {
    return !!isAuthorizedTo([PermissionName.SETTINGS]);
  }, [isAuthorizedTo]);

  return [
    {
      columnName: 'Acciones',
      headerText: texts.table.gradingStructures.columns.actions,
      tdClassName: 'text-center',
      thClassName: 'text-center',
      cellFormat: ({ row }) => {
        return (
          <div className="d-flex justify-content-center">
            {showSave && (
              <Button
                size="sm"
                onClick={() => onClickEdit(row)}
                text={texts.table.gradingStructures.add}
              />
            )}
          </div>
        );
      },
    },
  ];
}
