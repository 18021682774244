import { SECTION_MAINTAINER } from '../../../api/endpoints';
import request from '../../../api/request';
import {
  GeneralResponse,
  SectionDetailResponse,
  SectionScheduleGetResponse,
  UpdateSectionBody,
  UpdateSectionScheduleBody,
} from '../types';

/**
 * Obtiene el detalle de una sección
 * @param sectionId
 * @returns
 */
export const getBase = (sectionId: number) => {
  return request<SectionDetailResponse>(
    SECTION_MAINTAINER.GET_DETAIL(sectionId),
  );
};

export const getGeneral = (sectionId: number) => {
  return request<GeneralResponse>(SECTION_MAINTAINER.GET_GENERAL(sectionId));
};

export const putGeneral = (sectionId: number, data: UpdateSectionBody) => {
  return request<SectionDetailResponse>(
    SECTION_MAINTAINER.PUT_GENERAL(sectionId),
    { method: 'put', data },
  );
};

/**
 * Obtiene las opciones para los select del mantenedor de
 * horarios de una sección
 * @param sectionId
 * @returns
 */
export const getSchedules = (sectionId: number) => {
  return request<SectionScheduleGetResponse>(
    SECTION_MAINTAINER.GET_SCHEDULES(sectionId),
  );
};

export const putSchedules = (
  sectionId: number,
  data: UpdateSectionScheduleBody,
) => {
  return request<any>(SECTION_MAINTAINER.PUT_SCHEDULES(sectionId), {
    method: 'put',
    data,
  });
};
