import { Button } from '@octano/global-ui';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import {
  SearchParamsExcelF,
  ShowSearchParamsExcelF,
} from './PetitionsExcelReportForm';
import { PetitionsExcelReportModal } from './PetitionsExcelReportModal';

interface PetitionsExcelReportProps {
  disabledExcelReport: boolean;
  showModalExcelReport: boolean;
  toggleModalExcelReport: () => void;
  onDownload?: (requestBody?: any) => void;
  filters: SearchParamsExcelF;
  showFilters?: ShowSearchParamsExcelF;
}

const prefix = 'studentPetitions.common.excel';

const PetitionsExcelReport = ({
  disabledExcelReport,
  showModalExcelReport,
  toggleModalExcelReport,
  onDownload,
  filters,
  showFilters,
}: PetitionsExcelReportProps) => {
  const { t } = useTranslation();

  const texts = useMemo(
    () => ({
      title: t(`${prefix}.title`),
      subTitle: t(`${prefix}.subTitle`),
      body: {
        petitionTitle: t(`${prefix}.body.petitionTitle`),
        petitionReason: t(`${prefix}.body.petitionReason`),
        dateStart: t(`${prefix}.dateStartLabel`),
        dateEnd: t(`${prefix}.dateEndLabel`),
        state: t(`${prefix}.statusLabel`),
        studyPlan: t(`${prefix}.studyPlanLabel`),
        petitionType: t(`${prefix}.petitionTypeLabel`),
        default: {
          statusValue: t(`${prefix}.statusDefaultValue`),
          studyPlanValue: t(`${prefix}.studyPlanDefaultValue`),
          petitionTypeValue: t(`${prefix}.petitionTypeDefaultValue`),
        },
      },
      btn: {
        showModal: t(`${prefix}.btn.generateReport`),
        cancel: t(`${prefix}.btn.finish`),
        action: t(`${prefix}.btn.action`),
      },
      msg: {
        success: t(`${prefix}.createSuccess`),
      },
    }),
    [t],
  );

  return (
    <>
      <div className="d-flex flex-justify-content">
        <Button
          className="ml-auto"
          size="md"
          text={texts.btn.showModal}
          icon="download"
          disabled={disabledExcelReport}
          onClick={() => toggleModalExcelReport()}
        />

        <PetitionsExcelReportModal
          texts={texts}
          show={showModalExcelReport}
          toggleModal={toggleModalExcelReport}
          onDownload={onDownload}
          filters={filters}
          showFilters={showFilters}
        />
      </div>
    </>
  );
};
export default PetitionsExcelReport;
