import { Modal as ModalCore } from '@octano/global-ui';
import { useState } from 'react';
import { Col, Row } from 'reactstrap';

import ModalConfirmBody from './ModalConfirmBody';
import ModalSelectBody from './ModalSelectBody';

interface CourseEnrollment {
  id: number;
  course: { name: string; shortening: string; credits: number };
  grade: number | string | null;
}

type ModalProps = {
  studyPlanEnrollmentId: number;
  isOpen: boolean;
  onClose: () => void;
  onSuccess: () => void;
  semesterCourseToValidate: { id: number; name: string };
  courseEnrollments: CourseEnrollment[];
};

export default function Modal({
  studyPlanEnrollmentId,
  isOpen,
  onClose,
  onSuccess,
  semesterCourseToValidate,
  courseEnrollments,
}: ModalProps) {
  const [selectedCourse, setSelectedCourse] = useState<CourseEnrollment>();

  return (
    <ModalCore
      isOpen={isOpen}
      toggle={onClose}
      closeOnBackdrop={true}
      size="lg"
    >
      {!selectedCourse && (
        <ModalSelectBody
          onClose={onClose}
          onSuccess={(values) => setSelectedCourse(values.courseEnrollment)}
          courseEnrollments={courseEnrollments}
          semesterCourseToValidate={semesterCourseToValidate}
        />
      )}
      {selectedCourse && (
        <Row>
          <Col xs="auto" className="m-auto">
            <ModalConfirmBody
              studyPlanEnrollmentId={studyPlanEnrollmentId}
              course={semesterCourseToValidate}
              courseEnrollment={selectedCourse}
              onClose={onClose}
              onSuccess={onSuccess}
            />
          </Col>
        </Row>
      )}
    </ModalCore>
  );
}
