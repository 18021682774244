import { SelectOptionType } from '@octano/global-ui';
import { useCallback } from 'react';

import {
  getEditTeachersRequest,
  getTeachersRequest,
} from '../../api/requests/sectionsMaintainer';

export const useSearchTeachers = (sectionId?: number | string) => {
  return useCallback(
    async (searchText: string): Promise<SelectOptionType[]> => {
      const { data } = await (sectionId
        ? getEditTeachersRequest(sectionId, searchText)
        : getTeachersRequest(searchText));
      if (data) {
        return data.map((teacher) => ({
          value: teacher.id,
          label: teacher.account?.fullName ?? '',
        }));
      }
      return [];
    },
    [sectionId],
  );
};
