import { ColumnTable, Icon, Table, useMobile } from '@octano/global-ui';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';

import { StudentStatusBadge } from '.';
import { MappedSearchResults } from '../helpers';

export type SummaryTableRowData = MappedSearchResults[number]['courseEnrollments'][number];

interface StudentSummaryTableProps {
  summary: SummaryTableRowData[];
  onRecordEdit?: (record: SummaryTableRowData) => void;
}

export function StudentSummaryTable({
  summary,
  onRecordEdit = () => null,
}: StudentSummaryTableProps) {
  const { t } = useTranslation();
  const isMobile = useMobile();
  const prefix = `studentGradesClosure.search.summaryTable`;

  const COLUMNS: ColumnTable<SummaryTableRowData>[] = [
    {
      headerText: t(`${prefix}.period`),
      columnName: 'period',
      thClassName: 'text-center',
      tdClassName: 'text-center',
      hideOnMobile: true,
    },
    {
      headerText: t(`${prefix}.courseName`),
      columnName: 'courseName',
      thClassName: 'text-center',
      tdClassName: 'text-center',
      cellFormat: ({ value, row }) => {
        if (!isMobile) return value;

        return (
          <>
            <p className="mb-0">{value}</p>
            <p className="mb-0 text-medium">{row.sectionName}</p>
          </>
        );
      },
    },
    {
      headerText: t(`${prefix}.sectionName`),
      columnName: 'sectionName',
      thClassName: 'text-center',
      tdClassName: 'text-center',
      hideOnMobile: true,
    },
    {
      headerText: t(`${prefix}.closedAt`),
      columnName: 'closedAt',
      thClassName: 'text-center',
      tdClassName: 'text-center',
      cellFormat: (options) => {
        const formattedDate = dayjs(options.value).format('DD/MM/YY');
        return formattedDate;
      },
    },
    {
      headerText: t(`${prefix}.state`),
      columnName: 'state',
      thClassName: 'text-center',
      tdClassName: 'text-center',
      cellFormat: (options) => (
        <StudentStatusBadge status={options.value} shortVersion={isMobile} />
      ),
    },
    {
      headerText: t(`${prefix}.action`),
      columnName: 'courseEnrollmentId',
      thClassName: 'text-center',
      tdClassName: 'text-center',
      cellFormat: (options) => {
        return (
          <button
            className="border-0 bg-transparent"
            onClick={() => onRecordEdit(options.row)}
          >
            <Icon name="edit" />
          </button>
        );
      },
    },
  ];

  return (
    <Table
      data={summary}
      columns={COLUMNS}
      color="transparent"
      borderless={false}
    />
  );
}
