import { Button } from '@octano/global-ui';
import React, { useEffect } from 'react';
import { Col, Container, Row } from 'reactstrap';
import { useRoleManageLoader } from '../../RoleManageEditForm/RoleManageLoader';
import { useActionRolePermissionsForm } from '../hooks/useActionRolePermissionForm';
import { usePermissionState } from '../hooks/userPermissionState';
import { usePermissionsLoader } from './PermissionsLoader';
import PermissionTable from './PermissionTable';
import { useHistory } from 'react-router-dom';

function PermissionDisplay() {
  const history = useHistory();
  const { data } = usePermissionsLoader();
  const role = useRoleManageLoader();
  const { updatePermissions, loading } = useActionRolePermissionsForm();
  const { permissionState, setPermissionState } = usePermissionState();

  //Los permisos del rol se cargan en el state de la vista padre.
  useEffect(() => {
    setPermissionState(role.rolePermissions);
  }, [role.rolePermissions, setPermissionState]);

  return (
    <>
      <Col sm={12}>
        <p className="text-primary fs-20 fw-700 mt-4 mb-2 text-uppercase">
          Estás editando los permisos del rol:
        </p>
        <p className="fs-20  mb-4">
          {role?.name} - {role?.description}
        </p>
      </Col>
      {data?.permissions.map((permissions, index) => {
        return (
          <Container className="pt-5" key={index} fluid>
            <p className="text-primary fs-20 fw-700 mt-4 mb-2 text-uppercase">
              {permissions[0].group}
            </p>
            <PermissionTable permissions={permissions} />
          </Container>
        );
      })}

      <Container fluid className="mt-5">
        <Row>
          <div className="col-sm-12 col-md-3 offset-md-6">
            <Button
              type="button"
              onClick={() => history.push('/users-and-roles/roles')}
              text="Cancelar"
              outlined
              disabled={loading}
              fullwidth
            />
          </div>
          <div className="col-sm-12 mt-sm-3 col-md-3 mt-md-0">
            <Button
              type="button"
              text="Guardar"
              loading={loading}
              fullwidth
              onClick={async () => {
                await updatePermissions(role.id!, permissionState);
              }}
            />
          </div>
        </Row>
      </Container>
    </>
  );
}

export default PermissionDisplay;
