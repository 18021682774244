import { Tab, TabPanel, TabPanels, Tabs, TabsList } from '@octano/global-ui';
import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router';
import { Card } from 'reactstrap';

import useUserState from '../../hooks/useUserState';

export interface TabControlProps {
  tabsPrefix?: string;
  tabs: {
    name: string;
    content: JSX.Element;
    permissions?: string[];
    literalName?: string;
  }[];
  path: string;
}

export default function TabControl({
  tabs: allTabs,
  tabsPrefix = '',
  path,
}: TabControlProps) {
  const { t } = useTranslation();

  const location = useLocation();
  const history = useHistory();

  const { isAuthorizedTo } = useUserState();

  const tabs = useMemo(
    () =>
      allTabs.filter((t) => !t.permissions || isAuthorizedTo(t.permissions)),
    [allTabs, isAuthorizedTo],
  );

  const initialTab = useMemo(() => {
    const currentTab = location.pathname.replace(`${path}/`, '');
    if (tabs.some((tab) => tab.name === currentTab)) {
      return currentTab;
    }

    return tabs[0].name;
  }, [location.pathname, path, tabs]);

  const handleTabSelection = useCallback(
    (tab: string | number) => {
      const routeToGo = `${path}/${tab}`;
      history.push(routeToGo);
      return null;
    },
    [history, path],
  );

  return (
    <Card className="mx-3">
      <Tabs defaultTab={initialTab} onSelection={handleTabSelection}>
        <TabsList>
          {tabs.map((tab) => (
            <Tab
              key={tab.name}
              id={tab.name}
              children={tab.literalName ?? t(`${tabsPrefix}.${tab.name}`)}
            />
          ))}
        </TabsList>
        <TabPanels>
          {tabs.map((tab) => (
            <TabPanel key={tab.name} id={tab.name} children={tab.content} />
          ))}
        </TabPanels>
      </Tabs>
    </Card>
  );
}
