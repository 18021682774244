import {
  useCallback,
  useState,
  useImperativeHandle,
  forwardRef,
  Ref,
} from 'react';

import EmailConfirmModal from './EmailConfirmModal';

export interface ConfirmEmailActionModalContext {
  email: string;
  loading?: boolean;
  onConfirm?: (newEmail: string) => Promise<void>;
}

export interface ConfirmEmailActionModalMethods {
  open: (ctx: ConfirmEmailActionModalContext) => void;
  close: () => void;
}

interface Props {
  onConfirm?: (newEmail: string) => void;
}

const ConfirmEmailActionModal = (
  { onConfirm }: Props,
  ref: Ref<ConfirmEmailActionModalMethods>,
) => {
  const [visible, setVisible] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [context, setContext] = useState<ConfirmEmailActionModalContext>();

  const handleClose = useCallback(() => {
    setVisible(false);
    setLoading(false);
    setContext(undefined);
  }, []);

  const handleOpen = useCallback((ctx: ConfirmEmailActionModalContext) => {
    setVisible(true);
    setLoading(!!ctx?.loading);
    setContext(ctx);
  }, []);

  const handleCancel = useCallback(() => {
    handleClose();
  }, [handleClose]);

  const handleConfirm = useCallback(
    async ({ email }: { email: string }) => {
      if (loading) {
        return;
      }
      if (!context) {
        return handleCancel();
      }

      if (context?.onConfirm) {
        setLoading(true);
        await context?.onConfirm(email);
        !!onConfirm && onConfirm(email);
        setLoading(false);
      }

      handleClose();
    },
    [context, loading, handleCancel, handleClose, onConfirm],
  );

  useImperativeHandle(ref, () => ({
    open: handleOpen,
    close: handleClose,
  }));

  return (
    <EmailConfirmModal
      isOpen={visible}
      initialEmail={context?.email}
      isLoading={loading}
      onCancel={handleCancel}
      onConfirm={handleConfirm}
    />
  );
};

export default forwardRef(ConfirmEmailActionModal);
