import { Button, Icon, Modal } from '@octano/global-ui';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'reactstrap';

import styles from './../AcademicOffers.module.scss';

interface AccademicOffersModalProps {
  show: boolean;
  onCloseModal: () => void;
  onConfirm: () => void;
  code: number | null;
}

const getTexts = (code: number | null, t: Function) => {
  if (!code) return null;

  if (code === -1) {
    return {
      title: t('academicOffers.form.newTotalQuotaErrorTitle2'),
      subtitle: t('academicOffers.form.newTotalQuotaErrorSubtitle2'),
    };
  }
  return {
    title: t('academicOffers.form.newTotalQuotaErrorTitle1'),
    subtitle: t('academicOffers.form.newTotalQuotaErrorSubtitle1'),
  };
};
const AcademicOfferValidateQuotaModal = ({
  show,
  onCloseModal,
  onConfirm,
  code,
}: AccademicOffersModalProps) => {
  const { t } = useTranslation();

  const texts = getTexts(code, t);

  if (!texts) return null;

  return (
    <Modal
      isOpen={show}
      toggle={() => {
        onCloseModal();
      }}
      size="md"
      unmountOnClose
    >
      <div className="d-flex flex-column align-items-center">
        <Icon name="warning" size="52px" color="secondary" />
        <div className="mt-4 fw-600 text-black fs-22 text-center">
          {texts.title}
        </div>
        <div
          className={`${styles.modalSubtitle} mx-auto mt-4 mb-4 fs-18 text-center`}
        >
          {texts.subtitle}
        </div>
      </div>
      <Row className="pt-5">
        <Col
          xs={{ order: 2, size: 12 }}
          md={{ order: 1, size: 6 }}
          className="ml-auto"
        >
          <Button
            type="button"
            text={t(`common.actions.cancel`)}
            outlined
            onClick={() => onCloseModal()}
            fullwidth
          />
        </Col>
        <Col xs={{ order: 1, size: 12 }} md={{ order: 2, size: 6 }}>
          <div className="pb-3 pb-md-0">
            <Button
              type="button"
              text={t('common.actions.confirm')}
              fullwidth
              onClick={onConfirm}
            />
          </div>
        </Col>
      </Row>
    </Modal>
  );
};
export default AcademicOfferValidateQuotaModal;
