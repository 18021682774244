import {
  Button,
  OutlinedSelect,
  SearchBox,
  SelectOptionType,
  useMobile,
} from '@octano/global-ui';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Col, Form, Row } from 'reactstrap';

export interface SearchFiltersType {
  schools: SelectOptionType;
  nameOrCode: string | null;
}

interface SearchFiltersProps {
  onSearch: (filters: SearchFiltersType) => void;
  schools: SelectOptionType[];
}

const SearchFilters = ({ onSearch, schools }: SearchFiltersProps) => {
  const prefix = 'courses.filters';
  const { t } = useTranslation();
  const { control, handleSubmit, setValue } = useForm<SearchFiltersType>();
  const isMobile = useMobile();

  const onSubmit = (values: SearchFiltersType) => {
    onSearch(values);
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Row className="pb-4 gutters-filters">
        <Col xs={12} md={6} lg={5}>
          <OutlinedSelect
            name="schools"
            options={schools}
            label={t(`${prefix}.schools`)}
            placeholder={t(`${prefix}.placeholderSchools`)}
            control={control}
            isClearable
          />
        </Col>
        <Col xs={12} md={6} lg={5}>
          <SearchBox
            name="nameOrCode"
            label={t(`${prefix}.nameOrCode`)}
            placeholder={t(`${prefix}.placeholderNameOrCode`)}
            control={control}
            clear={() => setValue('nameOrCode', '')}
          />
        </Col>
        <Col
          xs={12}
          md={{ size: 4, offset: 8 }}
          lg={{ size: 2, offset: 0 }}
          style={{ marginTop: isMobile ? 0 : '1.5rem' }}
        >
          <Button
            type="submit"
            text={t(`common.actions.search`)}
            size="md"
            fullwidth
          />
        </Col>
      </Row>
    </Form>
  );
};

export default SearchFilters;
