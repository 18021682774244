import { DateInput, Select, TextInput } from '@octano/global-ui';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'reactstrap';

import { useParameters } from '../../../../../../../hooks/useParameters';
import { useValidations } from '../../../../../../../hooks/useValidations';

const fields = {
  rut: 'rut',
  passportNumber: 'passportNumber',
  passportCountry: 'passportCountry',
  names: 'names',
  lastnamePather: 'lastnamePather',
  lastnameMother: 'lastnameMother',
  birthdate: 'birthdate',
  nationality: 'nationality',
  personalEmail: 'personalEmail',
};

const CreateUserFormPersonalFields = ({
  prefix: subPrefix,
  passport,
  disabledInputs,
}: {
  prefix: string;
  passport: boolean;
  disabledInputs?: boolean;
}) => {
  const { t } = useTranslation();
  const prefix = `${subPrefix}.personal`;
  const { validateTextNotEmpty, validateEmail, msgValidations } =
    useValidations();

  const { control } = useFormContext();
  const { countryOptions, nationalityOptions } = useParameters();

  return (
    <Row>
      {passport ? (
        <>
          <Col md={2}>
            <Select
              label={t(prefix + '.' + fields.passportCountry)}
              name={`${fields.passportCountry}`}
              control={control}
              isSearchable={true}
              disabled={true}
              options={countryOptions}
            />
          </Col>
          <Col md={3}>
            <TextInput
              name={`${fields.passportNumber}`}
              control={control}
              label={t(prefix + '.' + fields.passportNumber)}
              readOnly
            />
          </Col>
        </>
      ) : (
        <Col md={5}>
          <TextInput
            name={`${fields.rut}`}
            control={control}
            formatter="rut"
            label={t(prefix + '.' + fields.rut)}
            readOnly
          />
        </Col>
      )}
      <Col md={7}>
        <TextInput
          name={`${fields.names}`}
          control={control}
          label={t(prefix + '.' + fields.names)}
          rules={{ validate: validateTextNotEmpty }}
        />
      </Col>
      <Col md={6}>
        <TextInput
          name={`${fields.lastnamePather}`}
          control={control}
          label={t(prefix + '.' + fields.lastnamePather)}
          rules={{ validate: validateTextNotEmpty }}
        />
      </Col>
      <Col md={6}>
        <TextInput
          name={`${fields.lastnameMother}`}
          control={control}
          label={t(prefix + '.' + fields.lastnameMother)}
          rules={{ validate: validateTextNotEmpty }}
        />
      </Col>
      <Col md={4}>
        <DateInput
          name={`${fields.birthdate}`}
          control={control}
          label={t(prefix + '.' + fields.birthdate)}
          rules={{ required: msgValidations.required }}
        />
      </Col>
      <Col md={4}>
        <Select
          label={t(prefix + '.' + fields.nationality)}
          name={`${fields.nationality}`}
          isSearchable={true}
          control={control}
          options={nationalityOptions}
        />
      </Col>
      <Col md={4}>
        <TextInput
          name={`${fields.personalEmail}`}
          control={control}
          label={t(prefix + '.' + fields.personalEmail)}
          rules={{
            validate: {
              notEmpty: validateTextNotEmpty,
              email: validateEmail,
            },
          }}
        />
      </Col>
    </Row>
  );
};

export default CreateUserFormPersonalFields;
