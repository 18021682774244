import { useMemo } from 'react';
import { Card, Container } from 'reactstrap';

import TabControl from '../../components/tabs/TabControl';
import { PathsLayouts } from '../../config/routes';
import { STUDENT_STATUS } from '../../types/studentStatusTypes';
import GraduateList from './GraduateList';
import TitledList from './TitledList';
import StudentsDegreeFormLoader from './contexts/StudentsDegreeFormLoader';
import StudentsDegreeLoader from './contexts/StudentsDegreeLoader';

export default function StudentsDegree() {
  const tabsPrefix = 'studentsDegree';

  const tabs = useMemo(
    () => [
      {
        name: 'graduate',
        content: (
          <StudentsDegreeFormLoader>
            <StudentsDegreeLoader status={STUDENT_STATUS.GRADUATE}>
              <GraduateList />
            </StudentsDegreeLoader>
          </StudentsDegreeFormLoader>
        ),
      },
      {
        name: 'titled',
        content: (
          <StudentsDegreeFormLoader>
            <StudentsDegreeLoader status={STUDENT_STATUS.TITLED}>
              <TitledList />
            </StudentsDegreeLoader>
          </StudentsDegreeFormLoader>
        ),
      },
    ],
    [],
  );

  return (
    <Container fluid>
      <Card>
        <TabControl
          tabsPrefix={`${tabsPrefix}.tabs`}
          tabs={tabs}
          path={PathsLayouts.studentsDegree}
        />
      </Card>
    </Container>
  );
}
