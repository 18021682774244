import { Card, Col, Row } from 'reactstrap';
import { useState, useEffect, useCallback } from 'react';
import {
  getStudents,
  studentsDownload,
  getForm,
} from '../../api/requests/interimDegreeGraduationStudents';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { addToast } from '@octano/global-ui';
import { InterimDegressAuthorizedStudentTable } from './parts/InterimDegressAuthorizedStudentTable';
import { InterimDegreeAuthorizedStudentMassive } from './interfaces/interim-degree-authorized-student-massive.interface';
import { InterimDegreesAuthorizedStudentStatus } from '../academicOffers/InterimDegree/interfaces/interim-degree-authorized-student.interface';
import { InterimDegreesStudentsSearchControls } from './parts/InterimDegreesStudentsSearchControls';
import { InterimDegreeStudentsForm } from './interfaces/interim-degree-students-form.interface';
import { InterimDegreeAuthorizedStudentFilters } from './interfaces/interim-degree-authorized-student-filters.interface';
import { downloadFromBlob } from '../../utils/blob';
import { PathsLayouts } from '../../config/routes';

const dictPrefix = 'interimDegreeGraduationProcess.students.authorizedsList';
const ITEMS_PER_PAGE = 20;

const AuthorizedStudents = () => {
  const { t } = useTranslation();
  const history = useHistory();

  const [filters, setFilters] = useState<InterimDegreeAuthorizedStudentFilters>(
    {},
  );
  const [tableData, setTableData] = useState<
    InterimDegreeAuthorizedStudentMassive[]
  >([]);
  const [isLoadingTable, setIsTableLoading] = useState<boolean>(false);
  const [isDownloadLoading, setDownloadLoading] = useState<boolean>(false);

  const [currentPage, setCurrentPage] = useState<number>(0);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [totalItems, setTotalItems] = useState<number>(0);

  const [filterForm, setFilterForm] = useState<InterimDegreeStudentsForm>();

  const handleSearch = useCallback(
    async (
      params: { page: number } & InterimDegreeAuthorizedStudentFilters,
    ) => {
      setIsTableLoading(true);
      const { data, error } = await getStudents({
        ...params,
        status: InterimDegreesAuthorizedStudentStatus.Authorized,
        items_per_page: ITEMS_PER_PAGE,
      });

      if (error) {
        addToast({
          text: t(`${dictPrefix}.errorOnSearch`),
          color: 'danger',
          icon: 'error',
        });
      } else {
        setTableData(data?.data ?? []);
        setTotalPages(data?.total_pages || 0);
        setTotalItems(data?.total || 0);
      }
      setIsTableLoading(false);
    },
    [t],
  );

  const handleForm = useCallback(async () => {
    const { error, data } = await getForm();
    if (error) {
      addToast({
        text: t(`${dictPrefix}.errorOnSearch`),
        color: 'danger',
        icon: 'error',
      });
    } else {
      setFilterForm(data ?? undefined);
    }
  }, [t]);

  const handleProcessFilters = useCallback(
    (next: InterimDegreeAuthorizedStudentFilters) => {
      if (!isLoadingTable) {
        setCurrentPage(0);
        setFilters(next);
      }
    },
    [isLoadingTable],
  );

  const handleDownload = useCallback(async () => {
    if (isDownloadLoading) {
      return;
    }
    setDownloadLoading(true);
    const response = await studentsDownload({
      ...filters,
      status: InterimDegreesAuthorizedStudentStatus.Authorized,
    });
    const { data, error, status } = response;

    if (error) {
      if (error?.status === 400) {
        addToast({
          text: t(`${dictPrefix}.generatingFile.errorOnDownload`),
          color: 'danger',
          icon: 'error',
        });
      } else {
        addToast({
          text: t(`${dictPrefix}.generatingFile.errorOnDownload`),
          color: 'danger',
          icon: 'error',
        });
      }
    }
    if (status === 200) {
      addToast({
        text: t(`${dictPrefix}.generatingFile.download`),
        color: 'success',
        icon: 'success',
      });
      const blob = new Blob([data], {
        type: 'vnd.ms-excel',
      });
      downloadFromBlob(blob, `estudiantes-habilitados.xlsx`);
    }
    setDownloadLoading(false);
  }, [t, filters, isDownloadLoading]);

  useEffect(() => {
    handleSearch({ ...filters, page: currentPage });
  }, [handleSearch, currentPage, filters]);

  useEffect(() => {
    handleForm();
  }, [handleForm]);

  const handleEdit = useCallback(
    (id: number) => {
      history.push(
        `${PathsLayouts.interimDegreeGraduationProcess}/activities/${id}`,
      );
    },
    [history],
  );

  return (
    <>
      <Card className="p-4">
        <Row>
          <Col className="mb-4" xs={12}>
            <div className="d-flex flex-wrap w-100 align-items-center justify-content-center">
              <div>
                <p className="fs-20 text-uppercase text-primary mb-0 fw-700">
                  {t(`${dictPrefix}.title`)}
                </p>
                <p className="fs-16 mb-0">{t(`${dictPrefix}.description`)}</p>
              </div>
              <div className="flex-fill px-2" />
            </div>
          </Col>
          <Col className="mb-4" xs={12}>
            <InterimDegreesStudentsSearchControls
              {...filterForm}
              loading={isLoadingTable || isDownloadLoading}
              onSearch={handleProcessFilters}
              onDownload={totalItems ? handleDownload : undefined}
            />
          </Col>
          <Col xs={12}>
            <InterimDegressAuthorizedStudentTable
              data={tableData}
              itemPerPage={ITEMS_PER_PAGE}
              totalItems={totalItems}
              totalPages={totalPages}
              currentPage={currentPage}
              isLoadingResults={isLoadingTable}
              onPressEdit={handleEdit}
              onChangePage={(page) => {
                setCurrentPage(page);
              }}
            />
          </Col>
        </Row>
      </Card>
    </>
  );
};

export default AuthorizedStudents;
