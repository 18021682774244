import { addToast, Button, Table } from '@octano/global-ui';
import { useRef } from 'react';
import { SectionTitle } from '../../../../../../components/text';
import HeaderInfo from '../../parts/Header/HeaderInfo';
import { useTexts } from '../hooks/textsContext';
import useActions from '../hooks/useActions';
import useColumnActions from '../hooks/useColumnActions';
import useColumns from '../hooks/useColumns';
import { EmploymentBackgroundRecord } from '../type';
import { useEmploymentBackgroundLoader } from './Loaders/EmploymentBackgroundLoader';
import ConfirmModal, { ConfirmModalRef } from './Modals/ConfirmModal';
import CreateOrUpdateModal, {
  CreateOrUpdateRef,
} from './Modals/CreateOrUpdateModal';

function EmploymentBackgroundTable() {
  const texts = useTexts();

  const { data, loading, refresh } = useEmploymentBackgroundLoader();

  const actions = useActions({
    onSuccess: refresh,
    onError: (_, messsage) => {
      addToast({
        icon: 'error',
        color: 'danger',
        text: messsage ?? 'Ha ocurrido un error',
      });
    },
  });

  //Mandamos data por referencia al modal
  const ConfirmModalRef = useRef<ConfirmModalRef | undefined>();
  const createOrUpdateModalRef = useRef<CreateOrUpdateRef | undefined>();

  const onClickEdit = (row: EmploymentBackgroundRecord) => {
    createOrUpdateModalRef.current?.openModal(row);
  };
  const onClickRemove = (row: EmploymentBackgroundRecord) => {
    ConfirmModalRef.current?.openModal(row);
  };
  const onClickDownload = (row: EmploymentBackgroundRecord) => {
    actions.downloadTitle(row?.linkedFile);
  };

  //desde la tabla solo se permite editar o eliminar una seccion
  const columns = useColumnActions({
    onClickEdit,
    onClickRemove,
    onClickDownload,
  });
  const columnsOthers = useColumns(columns);

  return (
    <>
      <header className="mb-3">
        <HeaderInfo />
        <div className="mt-3">
          <SectionTitle text={texts.title} />
          <p className="fs-16 text-light">{texts.subtitle}</p>
        </div>
      </header>

      <div className="mb-5">
        <div className="d-flex flex-wrap w-100 align-items-center justify-content-between">
          <h4 className={'fs-18 text-uppercase text-primary fw-700'}>
            {/* {texts.title} */}
          </h4>
          <Button
            outlined
            text={texts.table.workExperience.add}
            color="primary"
            icon="plus"
            size="md"
            className="mb-3"
            style={{ minWidth: '254px' }}
            onClick={() => {
              createOrUpdateModalRef.current?.openModal();
            }}
          />
        </div>
        <Table
          columns={columnsOthers}
          data={data?.workExperience ?? []}
          isLoadingResults={loading}
          loadingView={{
            title: 'Cargando titulos y grados...',
            subtitle: 'Espere un momento',
          }}
        />
      </div>

      <CreateOrUpdateModal
        ref={createOrUpdateModalRef as React.Ref<CreateOrUpdateRef>}
        actions={actions}
      />
      <ConfirmModal
        onDecline={() => ConfirmModalRef.current?.closeModal()}
        onConfirm={(row) => {
          actions.deleteTitle(row.id);
        }}
        ref={ConfirmModalRef as React.Ref<ConfirmModalRef>}
      />
    </>
  );
}

export default EmploymentBackgroundTable;
