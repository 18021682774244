import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Card } from '../../../components/article/Card';
import { PathsLayouts } from '../../../config/routes';
import { TuitionContinuityDocumentStatusEnum } from '../../../types/tuitionContinuityDocumentReview';

export interface TuitionContinuityDocumentReviewDetailProps {
  studyPlanVersionOfferId: number;
  title: string;
  campusName?: string;
  scheduleName?: string;
  enrolled: number;
  withoutReview: number;
  pending: number;
  passed: number;
  periodId: number | string;
  schoolId: number;
}

export default function TuitionContinuityDocumentReviewDetail({
  enrolled,
  withoutReview,
  pending,
  title,
  campusName,
  scheduleName,
  passed,
  periodId,
  studyPlanVersionOfferId,
}: TuitionContinuityDocumentReviewDetailProps) {
  const { t } = useTranslation();
  const text = useMemo(() => {
    const prefix = 'TuitionContinuityDocumentReview.details';
    return {
      enrolled: t(`${prefix}.enrolled`),
      withoutReview: t(`${prefix}.withoutReview`),
      pending: t(`${prefix}.pending`),
      passed: t(`${prefix}.passed`),
    };
  }, [t]);
  const subtitle = useMemo<string | undefined>(() => {
    if (campusName && scheduleName) {
      return `${campusName} - ${scheduleName}`;
    }
  }, [campusName, scheduleName]);

  const goTo = useMemo<string | undefined>(() => {
    if (studyPlanVersionOfferId !== 0 && campusName) {
      return `${PathsLayouts.tuitionContinuityDocumentReview}/student-list/${periodId}/${studyPlanVersionOfferId}/${TuitionContinuityDocumentStatusEnum.NoReview}`;
    }
  }, [campusName, periodId, studyPlanVersionOfferId]);
  return (
    <Card
      title={title}
      subtitle={subtitle}
      body={[
        {
          label: text.enrolled,
          value: enrolled,
        },
        {
          label: text.withoutReview,
          value: withoutReview,
        },
        {
          label: text.pending,
          value: pending,
        },
        {
          label: text.passed,
          value: passed,
        },
      ]}
      markedText={{
        label: text.passed,
        value: ((passed * 100) / enrolled).toFixed(1) + '%',
      }}
      to={goTo}
    />
  );
}
