import { Button, Table, TablePagination } from '@octano/global-ui';
import { CellFormatOptions } from '@octano/global-ui/dist/components/Table/types/TableTypes';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import DisplayError from '../../components/info/DisplayError';
import SearchRut from '../../components/inputs/SearchRutValue';
import TableEmptyContent from '../../components/text/TableEmptyContent';
import { useLayoutState } from '../../hooks/useLayoutState';
import { IdNotFound } from '../components/IdNotFound';
import { useAdmissionStatusList } from '../contexts/AdmissionStatusListContext';
import { DesistAdmissionModal } from '../inProcess/DesistAdmissionModal';
import { MoveToInProcessModal } from './MoveToInProcessModal';

export interface WaitingListItem {
  Postulant_rut: string | null;
  PostulantPassport_number: string | null;
  Postulant_names: string;
  Postulant_paternalLastName: string;
  Postulant_maternalLastName: string;
  Contact_cellPhone: string;
  Contact_email: string;
  Discount_discountValue: number | null;
  Details_enrolledDate: string | null;
  Details_retractedDate: string | null;
  Details_postulationId: number;
  Details_id: number;
  Discount_discountCode: string | null;
  Discount_discountName: string | null;
}

interface TableColumns {
  width?: string;
  bold?: boolean;
  headerText: string;
  columnName: string;

  [key: string]: any;
}

function WaitingList() {
  const { loading, data, error, query, updateQuery } = useAdmissionStatusList();
  const totalItems = data.total;
  const totalPages = data.total_pages;
  const currentPage = query.page;
  const showPagination = data.total_pages > 1;

  const [studentId, setStudentId] = useState<number>(0);
  const [name, setName] = useState<string>('');
  const [showMoveInProcess, setShowMoveInProcess] = useState<boolean>(false);
  const [showDesistAdmission, setShowDesistAdmission] = useState<boolean>(
    false,
  );

  const { showTabs } = useLayoutState();
  useEffect(() => {
    showTabs();
  }, [showTabs]);

  const { t } = useTranslation();
  const prefix = 'admission';

  const toDesist = (id: number, postulantName: string) => {
    setStudentId(id);
    setName(postulantName);
    setShowDesistAdmission(true);
  };

  const toInProcess = (id: number, postulantName: string) => {
    setStudentId(id);
    setName(postulantName);
    setShowMoveInProcess(true);
  };

  //Discounts columns
  const columns: TableColumns[] = [
    {
      columnName: 'Postulant_rut',
      headerText: t(`common.forms.rut`),
      width: '110px',
      cellFormat: (options: CellFormatOptions<WaitingListItem>) =>
        `${
          options.row.Postulant_rut
            ? options.row.Postulant_rut
            : options.row.PostulantPassport_number
        }`,
    },
    {
      columnName: 'Postulant_names',
      headerText: t(`common.forms.names`),
      width: '160px',
    },
    {
      columnName: 'Postulant_paternalLastName',
      headerText: t(`common.forms.lastName`),
      width: '160px',
      cellFormat: (options: CellFormatOptions<WaitingListItem>) => {
        return `${options.row.Postulant_paternalLastName} ${options.row.Postulant_maternalLastName}`;
      },
    },
    {
      columnName: 'Contact_cellPhone',
      headerText: t(`common.forms.phone`),
      width: '150px',
    },
    {
      columnName: 'Contact_email',
      headerText: t(`common.forms.email`),
      width: '150px',
    },
    {
      columnName: 'Details_id',
      headerText: '',
      width: '20%',
      tdClassName: 'text-nowrap',
      cellFormat: (options: CellFormatOptions<WaitingListItem>) => (
        <div className="d-flex justify-content-center">
          <Button
            size="sm"
            outlined
            color="danger"
            style={{ marginLeft: '10px', minWidth: '100px' }}
            onClick={() =>
              toDesist(
                options.value,
                `${options.row.Postulant_names} ${options.row.Postulant_paternalLastName} ${options.row.Postulant_maternalLastName}`,
              )
            }
            text={t(`${prefix}.desist`)}
          />
          <Button
            text={t(`${prefix}.moveToProcess`)}
            size="sm"
            style={{ marginLeft: '10px' }}
            onClick={() =>
              toInProcess(
                options.value,
                `${options.row.Postulant_names} ${options.row.Postulant_paternalLastName} ${options.row.Postulant_maternalLastName}`,
              )
            }
          />
        </div>
      ),
    },
  ];

  const TableEmpty = useMemo(() => {
    if (query.search) {
      return <IdNotFound />;
    } else {
      return (
        <TableEmptyContent
          title={t(`${prefix}.studentWaitingList.emptyList`)}
          subtitle={''}
        />
      );
    }
  }, [query, t]);

  if (error) {
    return (
      <div className="mt-3">
        <DisplayError
          insideCard
          textBody={error}
          retryAction={() => updateQuery()}
        />
      </div>
    );
  }

  return (
    <div className="mt-3 text-info">
      <SearchRut
        value={query.search}
        onChange={(search) => updateQuery({ page: 1, search })}
        searching={loading}
      />
      <div>
        <Table
          columns={columns}
          data={data.data}
          noResultsText={TableEmpty}
          isLoadingResults={loading}
        />
        {showPagination && (
          <TablePagination
            pagination={{
              currentPage,
              itemsPerPage: 10,
              onChangePage: (page) => {
                updateQuery({ page, search: query.search });
              },
              totalItems,
              totalPages,
            }}
          />
        )}
      </div>
      <DesistAdmissionModal
        show={showDesistAdmission}
        toggleModal={() => {
          setShowDesistAdmission(false);
        }}
        reloadList={() => updateQuery()}
        id={studentId}
        name={name}
      />
      <MoveToInProcessModal
        show={showMoveInProcess}
        toggleModal={() => {
          setShowMoveInProcess(false);
        }}
        reloadList={() => updateQuery()}
        id={studentId}
        name={name}
      />
    </div>
  );
}

export default WaitingList;
