import { ReactElement, useCallback } from 'react';

import { AxiosResult, AxiosResultDefaultError } from '../../../api/request';
import {
  AcademicOfferStudyPlansOfferDocumentationByStatus,
  DocumentationReviewStatus,
} from '../../../api/requests/studyPlansOffer';
import { createFetchContext } from '../../../components/contexts/FetchContextFactory';
import { PaginationRequestType } from '../../../types/paginationRequestType';
import { getDocumentationByStatus } from '../api';

const ITEMS_PER_PAGE_DEFAULT = 10;

export type PostulationDocumentsLoaderQuery = {
  page: number;
  status: DocumentationReviewStatus | null;
  searchText?: string;
};

export interface PostulationDocumentsLoaderProps {
  academicOfferStudyPlanVersionOffersId: number | string;
  children: ReactElement;
}

export type PostulationDocumentsLoaderResponse = PaginationRequestType<AcademicOfferStudyPlansOfferDocumentationByStatus> & {
  forceTab?: DocumentationReviewStatus;
};

const { FetchProvider, FetchConsumer, useFetch } = createFetchContext<
  PostulationDocumentsLoaderQuery,
  PostulationDocumentsLoaderResponse,
  AxiosResultDefaultError
>();

export function usePostulationDocumentsLoader() {
  const context = useFetch();

  return {
    error: context.error,
    loading: context.loading,
    data: context.data,
    query: context.query,
    updateQuery: context.updateQuery,
  };
}
export const PostulationDocumentsLoaderConsumer = FetchConsumer;

export default function PostulationDocumentsLoader({
  academicOfferStudyPlanVersionOffersId,
  children,
}: PostulationDocumentsLoaderProps) {
  const request = useCallback(
    async (
      query: PostulationDocumentsLoaderQuery,
    ): Promise<
      AxiosResult<PostulationDocumentsLoaderResponse, AxiosResultDefaultError>
    > => {
      return getDocumentationByStatus(academicOfferStudyPlanVersionOffersId, {
        status: query.status ?? undefined,
        items: ITEMS_PER_PAGE_DEFAULT,
        page: query.page - 1,
        searchText: query.searchText,
      });
    },
    [academicOfferStudyPlanVersionOffersId],
  );

  return (
    <FetchProvider
      request={request}
      defaultQuery={{ page: 1, status: DocumentationReviewStatus.NOT_REVIEWED }}
      fetchImmediately
    >
      {children}
    </FetchProvider>
  );
}
