import { useCallback } from 'react';
import { createRecord, deleteRecord, updateRecord } from '../api';
import {
  CreateTeacherAssignmentRecordRequest,
  TeacherAssignmentRecord,
  UpdateTeacherAssignmentRecordRequest,
} from '../type';

export interface UseActions {
  createRecord: (data: CreateTeacherAssignmentRecordRequest) => Promise<void>;
  updateRecord: (data: UpdateTeacherAssignmentRecordRequest) => Promise<void>;
  deleteRecord: (recordId: TeacherAssignmentRecord['id']) => Promise<void>;
}
type ActionType = 'create' | 'update' | 'delete';

export default function useActions(props: {
  onSuccess: (action: ActionType) => void;
  onError: (action: ActionType, message?: string) => void;
}) {
  const addRecordAction = useCallback(
    async (data: CreateTeacherAssignmentRecordRequest) => {
      const result = await createRecord(data);
      if (!result.error) {
        return props.onSuccess('create');
      }
      props.onError('create');
    },
    [props],
  );

  const updateRecordAction = useCallback(
    async (params: UpdateTeacherAssignmentRecordRequest) => {
      const result = await updateRecord(params);
      if (!result.error) {
        return props.onSuccess('update');
      }
      props.onError('update');
    },
    [props],
  );

  const deleteRecordAction = useCallback(
    async (recordId: TeacherAssignmentRecord['id']) => {
      const result = await deleteRecord(recordId);
      if (!result.error) {
        return props.onSuccess('delete');
      }
      props.onError('delete');
    },
    [props],
  );

  return {
    createRecord: addRecordAction,
    updateRecord: updateRecordAction,
    deleteRecord: deleteRecordAction,
  };
}
