import { Modal, DisplayInfo, Button } from '@octano/global-ui';
import React, {
  forwardRef,
  useImperativeHandle,
  useState,
  useCallback,
} from 'react';
import { useTranslation } from 'react-i18next';

import { InterimDegreeFormFields } from './InterimDegreeForm';

type Payload = InterimDegreeFormFields;

export type InterimDegreeEmptyActivitiesModalMethods = {
  open: (payload: Payload) => void;
  close: () => void;
};

interface InterimDegreeEmptyActivitiesModalProps {
  dictPrefix: string;
  onSave?: (payload: Payload) => void;
}
const InterimDegreeEmptyActivitiesModal = (
  { dictPrefix, onSave }: InterimDegreeEmptyActivitiesModalProps,
  ref: React.Ref<InterimDegreeEmptyActivitiesModalMethods>,
) => {
  const { t } = useTranslation();

  const [visible, setVisible] = useState<boolean>(false);
  const [payload, setPayload] = useState<Payload>();

  const handleOpen = useCallback((nextPayload: Payload) => {
    setVisible(true);
    setPayload(nextPayload);
  }, []);

  const handleClose = useCallback(() => {
    setVisible(false);
    setPayload(undefined);
  }, []);

  useImperativeHandle(ref, () => ({
    open: handleOpen,
    close: handleClose,
  }));

  const handleSave = useCallback(() => {
    handleClose();
    if (!payload) {
      return;
    }
    !!onSave && onSave(payload);
  }, [handleClose, onSave, payload]);

  return (
    <Modal
      isOpen={visible}
      closeOnBackdrop={false}
      toggle={handleClose}
      size="md"
      unmountOnClose
    >
      <DisplayInfo
        title={t(`${dictPrefix}.title`)}
        textBody={t(`${dictPrefix}.description`)}
        iconName="warning"
      />
      <div className="w-100">
        <div className="d-flex flex-wrap w-100 align-items-center justify-content-center">
          <div className="flex-fill px-2">
            <Button
              type="button"
              outlined
              text={t(`${dictPrefix}.close`)}
              className="w-100"
              onClick={handleClose}
            />
          </div>
          <div className="flex-fill px-2">
            <Button
              type="button"
              text={t(`${dictPrefix}.confirm`)}
              className="w-100"
              onClick={handleSave}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default forwardRef(InterimDegreeEmptyActivitiesModal);
