import { SelectOptionType } from '@octano/global-ui';
import { useCallback } from 'react';

import {
  getAssistantsRequest,
  getEditAssistantsRequest,
} from '../../api/requests/sectionsMaintainer';

export const useSearchAssistant = (sectionId?: number | string) => {
  return useCallback(
    async (searchText: string): Promise<SelectOptionType[]> => {
      const { data } = await (sectionId
        ? getEditAssistantsRequest(sectionId, searchText)
        : getAssistantsRequest(searchText));
      if (data) {
        return data.map((assist) => ({
          value: assist.id,
          label: assist.account?.fullName ?? '',
        }));
      }
      return [];
    },
    [sectionId],
  );
};
