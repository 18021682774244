import { Button, TextOutlinedInput } from '@octano/global-ui';
import { useCallback } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Col, Form, Row } from 'reactstrap';
import { clean } from 'rut.js';

import { useValidations } from '../../../../../../../hooks/useValidations';

interface SearchUserValues {
  rut: string;
}

interface SearchUserFormProps {
  prefix: string;
  defaultValues: SearchUserValues;
  onSubmit: (values: SearchUserValues) => void;
}

const SearchUserForm = (props: SearchUserFormProps) => {
  const { onSubmit: onSubmitProp, defaultValues, prefix } = props;
  const { t } = useTranslation();
  const { control, handleSubmit, formState } = useForm<SearchUserValues>({
    defaultValues,
    mode: 'all',
  });

  const { validateRut } = useValidations();
  const onSubmit = useCallback(
    (values: SearchUserValues) => {
      onSubmitProp({ rut: clean(values.rut) });
    },
    [onSubmitProp],
  );

  const { isDirty, isValid } = formState;

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <label>{t('common.forms.labelRut')}</label>
      <Row>
        <Col md={10} lg={8}>
          <Row>
            <Col xs={9}>
              <TextOutlinedInput
                name="rut"
                control={control}
                formatter="rut"
                placeholder={t(`${prefix}.idPlaceholder`)}
                rules={{
                  validate: validateRut,
                }}
              />
            </Col>
            <Col xs={3} sm={3} style={{ padding: '0px', paddingRight: '16px' }}>
              <Button
                size="sm"
                type="submit"
                text={t(`common.actions.search`)}
                fullwidth
                disabled={!isDirty || !isValid}
                style={{ height: '42px' }}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </Form>
  );
};

export default SearchUserForm;
