import { PaginationType } from '@octano/global-ui';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Card } from 'reactstrap';

import { STUDENT_STATUS } from '../../../types/studentStatusTypes';
import {
  ITEMS_PER_PAGE_DEFAULT,
  useStudentsDegreeLoader,
} from '../contexts/StudentsDegreeLoader';
import { SearchControls } from '../parts/SearchControls';
import StudentsDegreeExcelReportInfoModal from '../parts/StudentsDegreeExcelReportInfoModal';
import StudentsDegreeTable from '../parts/StudentsDegreeTable';

const dictPrefix = 'studentsDegree.graduate';

export default function GraduateList() {
  const { t } = useTranslation();
  const [showModal, setShowModal] = useState<boolean>(false);
  const [showInfoModal, setShowInfoModal] = useState<boolean>(false);

  const { data, query, loading, updateQuery } = useStudentsDegreeLoader();
  const paginationTable = useMemo<PaginationType | undefined>(() => {
    if (data) {
      return {
        totalItems: data.total,
        onChangePage: (page) => updateQuery({ ...query, page }),
        itemsPerPage: ITEMS_PER_PAGE_DEFAULT,
        totalPages: data.total_pages,
        currentPage: query.page,
      };
    } else {
      return undefined;
    }
  }, [data, query, updateQuery]);

  return (
    <Card className="mx-3 p-4">
      <h2 className="text-primary text-uppercase fs-20 fw-600">
        {t(`${dictPrefix}.title`)}
      </h2>
      <p className="text-medium fs-16 py-2">{t(`${dictPrefix}.description`)}</p>

      <div className="mb-5">
        <SearchControls />
      </div>

      <StudentsDegreeExcelReportInfoModal
        open={showInfoModal}
        onClose={() => setShowInfoModal(!showInfoModal)}
        onAfterClose={() => setShowModal(!showModal)}
      />

      <StudentsDegreeTable
        status={STUDENT_STATUS.GRADUATE}
        isLoadingResults={loading}
        data={data?.data ?? []}
        pagination={paginationTable}
        hasFilters={true}
      />
    </Card>
  );
}
