import { SelectOptionType } from '@octano/global-ui';
import { useEffect, useMemo, useState } from 'react';
import { DefaultValues } from 'react-hook-form';
import { Card } from 'reactstrap';
import { FieldValues } from 'react-hook-form';
import { getFormStructure } from '../../api/requests/dynamicForm';
import DisplayError from '../info/DisplayError';
import Loading from '../info/Loading';
import RenderForm, { ButtonData } from './parts/RenderSections';

interface DynamicFormProps {
  defaultValues?: DefaultValues<any>;
  /**
   * optionalValues corresponde a valores por defecto
   * que dependen de una condicion para mostrarse.
   */
  optionalValues?: DefaultValues<any>;
  /**
   * Cuando dynamic form recibe un objeto con las opciones,
   * estas se mostrarán el el campo que coincida con el nombre
   * de la propiedad.
   */
  options?: Record<string, SelectOptionType[]>;
  /**
   * sectionComponents en el json se puede definir un componente a una seccion
   * cuando esto ocurre es necesario pasar un objeto con componentes a renderizar.
   */
  sectionComponents?: Record<string, JSX.Element>;
  /**
   * Buttons para el formulario
   */
  formButtons?: ButtonData[];
  dynamicFormId: number;
  onSubmit: (data: FieldValues) => Promise<void>;
}

function DynamicForm(props: DynamicFormProps) {
  const {
    dynamicFormId,
    defaultValues,
    optionalValues,
    sectionComponents,
    options,
    onSubmit,
    formButtons,
  } = props;

  const [error, setError] = useState<string[]>();
  const [structure, setStructure] = useState<any>([]);

  const dynamicFormUrl: Record<number, string> = useMemo(() => {
    return {
      // student file
      1: 'maintainers-portal/tuition-process-steps/student-file/form-structure',
      // sustainer file
      2: 'maintainers-portal/tuition-process-steps/sustainer/form-structure',
      // personal file
      3: 'maintainers-portal/teacher-file/personal-background/form-structure',
    };
  }, []);

  useEffect(() => {
    async function fetchFormularioConfig() {
      try {
        //obtenemos la estructura del formulario: 1 student file, 2 sustainer
        const formStructure = await getFormStructure(
          dynamicFormUrl[dynamicFormId],
        );
        setStructure(formStructure.data.sections);
      } catch (error) {
        console.error('Error al cargar la configuración del formulario', error);
      }
    }
    fetchFormularioConfig();
  }, [dynamicFormId, dynamicFormUrl]);

  if (error) {
    return <DisplayError insideCard textBody={'Error de dependencias'} />;
  }

  if (!structure) {
    return <Loading insideCard textBody={'Cargando formulario'} />;
  }

  return (
    <Card>
      <RenderForm
        sectionComponents={sectionComponents}
        structureBtns={formButtons}
        structure={structure}
        onErrors={setError}
        onSubmit={onSubmit}
        defaultValues={defaultValues}
        optionalValues={optionalValues}
        options={options}
      />
    </Card>
  );
}
export default DynamicForm;
