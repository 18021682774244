import {
  Modal,
  DisplayInfo,
  SelectOptionType,
  Select,
  CheckInput,
  Button,
} from '@octano/global-ui';
import React, {
  forwardRef,
  useImperativeHandle,
  useState,
  useCallback,
  useMemo,
} from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { InterimDegreeStatus } from '../interfaces/interimDegree.interface';

export type InterimStatusModalMethods = {
  open: (id: number, status: InterimDegreeStatus) => void;
  close: () => void;
};

interface InterimStatusModalProps {
  dictPrefix: string;
  onSave?: (id: number, status: InterimDegreeStatus) => void;
}

interface InterimStatusForm {
  id: number;
  original: SelectOptionType | null;
  status: SelectOptionType | null;
  confirmed: boolean;
}

const InterimStatusModal = (
  { dictPrefix, onSave }: InterimStatusModalProps,
  ref: React.Ref<InterimStatusModalMethods>,
) => {
  const { t } = useTranslation();

  const [visible, setVisible] = useState<boolean>(false);

  const { control, reset, handleSubmit, setError, watch } =
    useForm<InterimStatusForm>({
      defaultValues: {
        id: 0,
        original: null,
        status: null,
        confirmed: false,
      },
    });

  const [originalStatus, nextStatus] = watch(['original', 'status']);

  const statuses = useMemo<SelectOptionType[]>(
    () => [
      {
        value: InterimDegreeStatus.Active,
        label: t(`${dictPrefix}.statuses.active`),
        icon: {
          name: 'check',
          color: 'success',
        },
      },
      {
        value: InterimDegreeStatus.Draft,
        label: t(`${dictPrefix}.statuses.draft`),
        icon: {
          name: 'warning',
          color: 'warning',
        },
      },
      {
        value: InterimDegreeStatus.Closed,
        label: t(`${dictPrefix}.statuses.closed`),
        icon: {
          name: 'close',
          color: 'danger',
        },
      },
    ],
    [t, dictPrefix],
  );

  const getStatuses = useCallback(
    (status: InterimDegreeStatus) => {
      if (status !== InterimDegreeStatus.Draft) {
        return statuses.filter((s) => s?.value !== InterimDegreeStatus.Draft);
      }
      return statuses;
    },
    [statuses],
  );

  const getStatus = useCallback(
    (status: InterimDegreeStatus) => {
      return getStatuses(status).find((s) => s?.value === status) ?? undefined;
    },
    [getStatuses],
  );

  const handleOpen = useCallback(
    (interimDegreeId: number, current: InterimDegreeStatus) => {
      setVisible(true);
      reset({
        id: interimDegreeId,
        original: getStatus(current),
        status: getStatus(current),
        confirmed: current !== InterimDegreeStatus.Draft,
      });
    },
    [reset, getStatus],
  );

  const handleClose = useCallback(() => {
    setVisible(false);
    reset({
      id: 0,
      original: null,
      status: null,
      confirmed: false,
    });
  }, [reset]);

  useImperativeHandle(ref, () => ({
    open: handleOpen,
    close: handleClose,
  }));

  const handleValidate = useCallback(
    (values: InterimStatusForm) => {
      let hasErrors = false;
      if (!values?.confirmed) {
        setError('confirmed', {
          message: t(`${dictPrefix}.confirmationIsRequired`),
        });
        hasErrors = true;
      }
      return !hasErrors;
    },
    [t, dictPrefix, setError],
  );

  const handleSave = useCallback(
    (values: InterimStatusForm) => {
      if (!handleValidate(values) || !values?.status?.value) {
        return;
      }
      handleClose();
      !!onSave && onSave(values.id, values.status?.value as any);
    },
    [handleClose, onSave, handleValidate],
  );

  return (
    <Modal
      isOpen={visible}
      closeOnBackdrop={false}
      toggle={handleClose}
      size="md"
      unmountOnClose
    >
      <DisplayInfo
        title={t(`${dictPrefix}.title`)}
        textBody={
          originalStatus?.value === InterimDegreeStatus.Draft
            ? t(`${dictPrefix}.descriptionWarning`)
            : t(`${dictPrefix}.description`)
        }
        iconName="warning"
      />
      <div className="w-100">
        <div className="w-100 mb-3">
          <Select
            control={control}
            name="status"
            label={t(`${dictPrefix}.status`)}
            placeholder="..."
            options={getStatuses(originalStatus?.value as any)}
            isSearchable={false}
            isClearable={false}
          />
        </div>
        {originalStatus?.value === InterimDegreeStatus.Draft && (
          <CheckInput
            control={control}
            name="confirmed"
            label={t(`${dictPrefix}.confirmedCheck`)}
          />
        )}
        <div className="d-flex flex-wrap w-100 align-items-center justify-content-center">
          <div className="flex-fill px-2" />
          <Button
            type="button"
            outlined
            text={t(`${dictPrefix}.cancel`)}
            onClick={handleClose}
            size="md"
          />
          <Button
            type="button"
            text={t(`${dictPrefix}.save`)}
            className="ml-2"
            size="md"
            onClick={handleSubmit(handleSave)}
            disabled={originalStatus === nextStatus}
          />
        </div>
      </div>
    </Modal>
  );
};

export default forwardRef(InterimStatusModal);
