import { ColumnTable } from '@octano/global-ui';
import { formatDate } from '../../../../utils/dates';
import Leyenda from '../parts/Leyenda';
import { useTexts } from './textsContext';

export default function useColumns(
  actionsColumns: ColumnTable[],
): ColumnTable[] {
  const texts = useTexts();
  return [
    {
      columnName: 'name',
      headerText: texts.table.gradingStructures.columns.name,
      tdClassName: 'text-left',
      thClassName: 'text-left',
    },
    {
      columnName: 'structureType',
      headerText: texts.table.gradingStructures.columns.structureType,
      tdClassName: 'text-left',
      thClassName: 'text-left',
      cellFormat: (options) => (
        <Leyenda structureType={options.row?.structureType} />
      ),
    },
    {
      columnName: 'updatedAt',
      headerText: texts.table.gradingStructures.columns.updatedAt,
      tdClassName: 'text-center',
      thClassName: 'text-center',
      cellFormat: (options) => formatDate(options.row?.updatedAt, 'DD/MM/YYYY'),
    },
    ...actionsColumns,
  ];
}
