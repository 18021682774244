import { useCallback, useImperativeHandle, forwardRef, Ref } from 'react';

import { addToast, showDialogConfirm } from '@octano/global-ui';
import { clean } from 'rut.js';

import { useTranslation } from 'react-i18next';

import { TeacherFileItem } from '../../TeacherFile/TeacherList/interfaces/teacher-file-item.interface';
import { deactivateTeacherAccount } from '../../../../api/requests/teacherActivation';

export interface ConfirmDisableTeacherMethods {
  open: (teacherFileItem: TeacherFileItem) => void;
}

interface Props {
  onConfirm?: (teacherFileItem: TeacherFileItem) => void;
}

const prefix = 'teacherActivation.teacherForm';

const ConfirmDisableTeacher = (
  { onConfirm }: Props,
  ref: Ref<ConfirmDisableTeacherMethods>,
) => {
  const { t } = useTranslation();

  const handleConfirm = useCallback(
    async (teacherFile: TeacherFileItem) => {
      if (!teacherFile) {
        return;
      }

      const { error } = await deactivateTeacherAccount({
        rut: clean(teacherFile.identification),
        email: teacherFile.email,
      });

      if (error) {
        return addToast({
          icon: 'error',
          color: 'danger',
          text: t(`${prefix}.failedDeactivatingTeacherAccount`),
        });
      }

      addToast({
        icon: 'success',
        color: 'success',
        text: t(`${prefix}.deactivatedTeacherAccount`),
      });

      !!onConfirm && onConfirm(teacherFile);
    },
    [t, onConfirm],
  );

  const handleOpen = useCallback(
    (teacherFileItem: TeacherFileItem) => {
      showDialogConfirm({
        icon: {
          name: 'warning',
          color: 'warning',
        },
        title: t('teacherActivation.deactivateConfirmation.title'),
        subtitle: t('teacherActivation.deactivateConfirmation.description'),
        btnConfirm: {
          text: t(`common.actions.confirm`),
          onConfirm: () => {
            handleConfirm(teacherFileItem);
          },
        },
        btnClose: {
          text: t(`common.actions.cancel`),
        },
      });
    },
    [handleConfirm, t],
  );

  useImperativeHandle(ref, () => ({
    open: handleOpen,
  }));

  return <></>;
};

export default forwardRef(ConfirmDisableTeacher);
