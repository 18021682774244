import request from '../../../api/request';
import {
  AcademicLevelInfoResponse,
  AcademicLevelsFormDataResponse,
  AcademicLevelsResponse,
  FormDataAction,
} from './interfaces/type';

const SETTINGS_ACADEMIC_STRUCTURE = {
  FORM_DATA: () =>
    `maintainers-portal/general-configuration/academic-structure/form`,
  GET_ALL_TITLES: () =>
    `maintainers-portal/general-configuration/academic-structure/`,
  GET_TEACHER_INFO: (teacherId: string | number) =>
    `maintainers-portal/general-configuration/academic-structure/${teacherId}`,
  ADD_TITLE: (teacherId: string | number) =>
    `maintainers-portal/general-configuration/academic-structure/${teacherId}`,
  UPDATE_TITLE: (educationalBackgroundId: string | number) =>
    `maintainers-portal/general-configuration/academic-structure/${educationalBackgroundId}`,
};

export async function getAllGradingStructure() {
  return request<AcademicLevelsResponse>(
    SETTINGS_ACADEMIC_STRUCTURE.GET_ALL_TITLES(),
    {
      method: 'GET',
    },
  );
}

export async function getFormData() {
  return request<AcademicLevelsFormDataResponse>(
    SETTINGS_ACADEMIC_STRUCTURE.FORM_DATA(),
    {
      method: 'GET',
    },
  );
}

export async function getGradingStructureInfo(teacherId: number) {
  return request<AcademicLevelInfoResponse>(
    SETTINGS_ACADEMIC_STRUCTURE.GET_TEACHER_INFO(teacherId),
    {
      method: 'GET',
    },
  );
}

export async function addGradingStructure(
  teacherId: number,
  data: FormDataAction,
) {
  const formData = new FormData();
  Object.entries(data).forEach(([key, value]: [string, any]) => {
    if (value instanceof File) formData.append(key, value, value.name);
    else if (value) {
      formData.append(key, value);
    }
  });
  return request(SETTINGS_ACADEMIC_STRUCTURE.ADD_TITLE(teacherId), {
    method: 'POST',
    data: formData,
  });
}

export async function updateGradingStructure(id: number, data: FormDataAction) {
  return request(SETTINGS_ACADEMIC_STRUCTURE.UPDATE_TITLE(id), {
    method: 'PUT',
    data,
  });
}
