import { OutlinedSelect } from '@octano/global-ui';
import { useCallback } from 'react';

import {
  StudentDocumentReviewInfDocument,
  TuitionContinuityDocumentStatusEnum,
} from '../../../types/tuitionContinuityDocumentReviewDocuments';

export interface TuitionContinuityDocumentReviewDocumentsTableStatusCellProps {
  options: any[];
  onChange: (
    row: StudentDocumentReviewInfDocument,
    state: TuitionContinuityDocumentStatusEnum,
  ) => void;
  row: StudentDocumentReviewInfDocument;
  placeholder: string;
}

export function TuitionContinuityDocumentReviewDocumentsTableStatusCell({
  options,
  onChange: onChangeProps,
  row,
  placeholder,
}: TuitionContinuityDocumentReviewDocumentsTableStatusCellProps) {
  const onChange = useCallback(
    ({ value: newState }) => {
      onChangeProps(row, newState);
    },
    [onChangeProps, row],
  );

  let value = null;
  if (row.id) {
    value = options.find((o) => o.value === row.status);
  }
  return (
    <div className="d-flex justify-content-center">
      <div
        style={{
          width: '180px',
        }}
      >
        <OutlinedSelect
          name="status"
          isSearchable={false}
          disabled={!row.id}
          value={value}
          isClearable={false}
          options={options}
          menuPosition="fixed"
          onChange={onChange}
          menuShouldBlockScroll
          placeholder={placeholder}
        />
      </div>
    </div>
  );
}
