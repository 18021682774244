import {
  ColumnTable,
  DisplayInfo,
  SelectOptionType,
  Table,
} from '@octano/global-ui';
import dayjs from 'dayjs';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { format } from 'rut.js';

import { moneyFormatter } from '../../../../utils/currency';
import { CollectionTypeCode } from '../../enums/collection-type-code.enum';
import { IdentificationDocumentCode } from '../../enums/identification-document-code.enum';
import { Agreed } from '../../interfaces/agreed';
import { IdentificationDocument } from '../../interfaces/collection';

const dictPrefix = 'paymentHub.paymentsTable';

interface Props {
  data: Agreed[];
  programs: SelectOptionType[];
  periods: SelectOptionType[];
  detailsOptions: SelectOptionType[];
  isLoadingResults: boolean;
  totalPayments: number;
  totalPages: number;
  currentPage: number;
  hasFilters?: boolean;
  onChangePage: (page: number) => void;
}

export function AgreedPaymentsTable({
  data,
  programs,
  periods,
  detailsOptions,
  isLoadingResults,
  totalPages,
  totalPayments,
  currentPage,
  onChangePage,
  hasFilters,
}: Props) {
  const ITEMS_PER_PAGE = 10;

  const { t } = useTranslation();

  const formatAccountDocument = ({
    code,
    document,
    country,
  }: IdentificationDocument) => {
    switch (code) {
      case IdentificationDocumentCode.CL_RUT:
        return format(document);
      case IdentificationDocumentCode.Passport:
        return `${country}|${document}`;
    }
  };

  const formatDetails = useCallback(
    ({ type, installment, maxInstallment }: Agreed) => {
      let text = t(`${dictPrefix}.concepts.${type}`);

      if (maxInstallment > 1 || type === CollectionTypeCode.Quota) {
        if (maxInstallment <= 0) {
          text += ` (${installment})`;
        } else {
          text += ` (${installment}/${maxInstallment ?? 1})`;
        }
      }
      return text;
    },
    [t],
  );

  const columns = useMemo(() => {
    const cols: ColumnTable<Agreed>[] = [
      {
        headerText: t(`${dictPrefix}.name`),
        columnName: 'student',
        tdClassName: 'text-center',
        thClassName: 'text-center',
        cellFormat: ({ row }) => (
          <div>
            <span className="w-100 d-block text-center">
              {row?.student?.fullname}
            </span>
            <span className="w-100 d-block text-center">
              {formatAccountDocument(row?.student?.identificationDocument)}
            </span>
          </div>
        ),
      },
      {
        headerText: t(`${dictPrefix}.sustainer`),
        columnName: 'sustainer',
        tdClassName: 'text-center',
        thClassName: 'text-center',
        cellFormat: ({ row }) => (
          <div>
            <span className="w-100 d-block text-center">
              {row?.sustainer?.fullname}
            </span>
            <span className="w-100 d-block text-center">
              {formatAccountDocument(row?.sustainer?.identificationDocument)}
            </span>
          </div>
        ),
      },
      {
        headerText: t(`${dictPrefix}.matriculationDate`),
        columnName: 'matriculationDate',
        tdClassName: 'text-center',
        thClassName: 'text-center',
        cellFormat: ({ value }) => dayjs(value).format('DD/MM/YYYY'),
      },
      {
        headerText: t(`${dictPrefix}.program`),
        columnName: 'program',
        tdClassName: 'text-center',
        thClassName: 'text-center',
        cellFormat: ({ value }) =>
          programs?.find((v) => `${v?.value}` === `${value}`)?.label ??
          value ??
          '-',
      },
      {
        headerText: t(`${dictPrefix}.period`),
        columnName: 'period',
        tdClassName: 'text-center',
        thClassName: 'text-center',
        cellFormat: ({ value }) =>
          periods?.find((v) => `${v?.value}` === `${value}`)?.label ??
          value ??
          '-',
      },
      {
        headerText: t(`${dictPrefix}.details`),
        columnName: 'type',
        tdClassName: 'text-center',
        thClassName: 'text-center',
        cellFormat: ({ row }) => (
          <div>
            <span className="w-100 text-center d-block">{formatDetails(row)}</span>
            <span className="w-100 text-center d-block">{row?.reference}</span>
          </div>
        ),
      },
      {
        headerText: t(`${dictPrefix}.installmentDueDate`),
        columnName: 'installmentDueDate',
        tdClassName: 'text-center',
        thClassName: 'text-center',
        cellFormat: ({ value }) => dayjs(value).utc().format('DD/MM/YYYY'),
      },
      {
        headerText: t(`${dictPrefix}.type`),
        columnName: 'paymentType',
        tdClassName: 'text-center',
        thClassName: 'text-center',
        cellFormat: ({ value }) => t(`${dictPrefix}.types.${value}`),
      },
      {
        headerText: t(`${dictPrefix}.documentType`),
        columnName: 'documentTypeCode',
        tdClassName: 'text-center',
        thClassName: 'text-center',
        cellFormat: ({ value }) => t(`${dictPrefix}.documentTypes.${value}`),
      },
      {
        headerText: t(`${dictPrefix}.contract`),
        columnName: 'contract',
        tdClassName: 'text-center',
        thClassName: 'text-center',
        cellFormat: ({ value }) => value,
      },
      {
        headerText: t(`${dictPrefix}.amount`),
        columnName: 'amount',
        tdClassName: 'text-right',
        thClassName: 'text-right',
        cellFormat: ({ value }) => (
          <strong>{moneyFormatter().format(Math.ceil(value))}</strong>
        ),
      },
    ];
    return cols;
  }, [formatDetails, periods, programs, t]);

  return (
    <Table
      isLoadingResults={isLoadingResults}
      data={data}
      columns={columns}
      pagination={{
        currentPage: currentPage + 1,
        itemsPerPage: ITEMS_PER_PAGE,
        totalItems: totalPayments,
        totalPages: totalPages,
        onChangePage: (page) => onChangePage(page - 1),
      }}
      noResultsText={
        <div className="d-flex justify-content-center align-items-center py-5">
          <DisplayInfo
            iconName="information"
            textBody={t(
              `${dictPrefix}.${
                hasFilters ? 'noResultsFilter' : 'noResults'
              }.description`,
            )}
            maxWidth="650px"
          />
        </div>
      }
    />
  );
}
