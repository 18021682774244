import { useCallback } from 'react';
import {
  createDocument,
  updateDocument,
  deleteDocument,
  documentURL,
} from '../api';
import {
  CreateTeacherAdditionalDocumentRequest,
  UpdateTeacherAdditionalDocumentRequest,
} from '../type';

export interface UseActions {
  createDocument: (
    data: CreateTeacherAdditionalDocumentRequest,
  ) => Promise<void>;
  updateDocument: (
    data: UpdateTeacherAdditionalDocumentRequest,
  ) => Promise<void>;
  deleteDocument: (documentId: number) => Promise<void>;
  downloadDocument: (id: number) => Promise<void>;
}
type ActionType = 'create' | 'update' | 'delete';

export default function useActions(props: {
  onSuccess: (action: ActionType) => void;
  onError: (action: ActionType, message?: string) => void;
}) {
  const { onSuccess, onError } = props;
  const createDocumentAction = useCallback(
    async (data: CreateTeacherAdditionalDocumentRequest) => {
      const result = await createDocument(data);
      if (!result.error) {
        return onSuccess('create');
      }
      onError('create');
    },
    [onSuccess, onError],
  );

  const updateDocumentAction = useCallback(
    async (data: UpdateTeacherAdditionalDocumentRequest) => {
      const result = await updateDocument(data);
      if (!result.error) {
        return onSuccess('update');
      }
      onError('update');
    },
    [onSuccess, onError],
  );

  const deleteDocumentAction = useCallback(
    async (documentId: number) => {
      const result = await deleteDocument(documentId);
      if (!result.error) {
        return onSuccess('delete');
      }
      onError('delete');
    },
    [onSuccess, onError],
  );

  const downloadDocumentAction = useCallback(
    async (documentId: number) => {
      const result = await documentURL(documentId);
      if (!result.error) {
        const file = result?.data;
        const link = document.createElement('a');
        link.download = file?.name;
        link.href = file?.url;
        link.target = '_blank';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        return onSuccess('delete');
      }
      onError('delete');
    },
    [onSuccess, onError],
  );

  return {
    createDocument: createDocumentAction,
    updateDocument: updateDocumentAction,
    deleteDocument: deleteDocumentAction,
    downloadDocument: downloadDocumentAction,
  };
}
