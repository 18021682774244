import { Agreed } from '../../views/PaymentHub/interfaces/agreed';
import { Collection } from '../../views/PaymentHub/interfaces/collection';
import {
  PaymentHubFormResponse,
  PaymentHubRowResponse,
} from '../../views/PaymentHub/types';
import { PAYMENT_HUB } from '../endpoints';
import request from '../request';

/**
 * Obtiene el listado del centro de pago (pagados), paginados
 * @param items
 * @param page
 * @returns
 */
export function collected(filter: { items: number; page: number }) {
  const URL = PAYMENT_HUB.PAYMENT_HUB_COLLECTED_LIST;
  const method = 'GET';
  const params = {
    ...filter,
    items_per_page: filter.items,
  };
  return request<PaymentHubRowResponse<Collection>>(URL, { method, params });
}

/**
 * Descarga el listado del centro de pago fitlrados (recaudacion electronica)
 * @returns
 */
export function collectedDownload(filter: any) {
  const method = 'GET';
  return request(PAYMENT_HUB.PAYMENT_HUB_COLLECTED_DOWNLOAD, {
    responseType: 'blob',
    method,
    params: filter,
  });
}
/**
 * Obtiene el listado del centro de pago (pendientes), paginados
 * @param items
 * @param page
 * @returns
 */
export function agreed(filter: { items: number; page: number }) {
  const URL = PAYMENT_HUB.PAYMENT_HUB_AGREED_LIST;
  const method = 'GET';
  const params = {
    ...filter,
    items_per_page: filter.items,
  };
  return request<PaymentHubRowResponse<Agreed>>(URL, { method, params });
}

/**
 * Descarga el listado del centro de pago fitlrados (excel)
 * @returns
 */
export function agreedDownload(filter: any) {
  const method = 'GET';
  return request(PAYMENT_HUB.PAYMENT_HUB_AGREED_DOWNLOAD, {
    responseType: 'blob',
    method,
    params: filter,
  });
}

/**
 * Obtiene el listado de datos necesarios para el filtrado
 * @returns
 */
export function getPaymentHubFormFields() {
  const URL = PAYMENT_HUB.PAYMENT_HUB_FORM_FIELDS;
  const method = 'GET';
  return request<PaymentHubFormResponse>(URL, { method });
}
