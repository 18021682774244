import request from '../../../api/request';
import { PaymentAgreementResponse } from './types';

const CURRENT_ACCOUNT = {
  PAYMENT_AGREEMENT: (studentId: string | number) =>
    `maintainers-portal/current-accounts/students/${studentId}/payment-agreement`,
};

export function getPaymentAgreement(studentId: string | number) {
  return request<PaymentAgreementResponse>(
    CURRENT_ACCOUNT.PAYMENT_AGREEMENT(studentId),
  );
}
