import request from '../../../api/request';
import { DebtRsponse } from '../Debt/types';

const CURRENT_ACCOUNT = {
  DEBTS: (studentId: string | number) =>
    `maintainers-portal/current-accounts/students/${studentId}/debts`,
};

export function getDebts(studentId: string | number) {
  return request<DebtRsponse>(CURRENT_ACCOUNT.DEBTS(studentId));
}
