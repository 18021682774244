import { Alert, addToast } from '@octano/global-ui';
import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { Card, Col, Row } from 'reactstrap';

import GoBackToListButton from '../../components/buttons/GoBackToListButton';
import { SectionTitle } from '../../components/text';
import BaseLoader from './contexts/BaseLoader';
import { CreateRenditionModal } from './parts/CreateRendition/CreateRenditionModal';
import Form from './parts/Form';
import FormView from './parts/FormView';
import StudentBox from './parts/StudentBox';
import Table from './parts/Table';
import { createRendition } from './requests';
import { CreateRenditionParams, CreateRenditionWithRefresh } from './types';

const dictPrefix = 'studentsDegreeStudent';

export default function StudentsDegreeStudent() {
  const { t } = useTranslation();
  const [activityId, setActivityId] = useState<number | string>('');
  const [showModal, setShowModal] = useState<boolean>(false);

  const { studyPlanEnrollmentId } = useParams<{
    studyPlanEnrollmentId: string;
  }>();

  const handleSaveRendition = useCallback(
    async ({
      searchParams,
      refresh = async () => {},
    }: CreateRenditionWithRefresh) => {
      const { data, error } = await createRendition(activityId, searchParams);

      if (error) {
        addToast({
          text: t(`${dictPrefix}.tableRenditions.errorOnSearch`),
          color: 'danger',
          icon: 'error',
        });
      }
      if (data) {
        addToast({
          icon: 'success',
          color: 'success',
          text: t(`${dictPrefix}.tableRenditions.successfullyOnCreate`),
        });
        setShowModal(false);
        refresh();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [t, activityId, showModal],
  );

  const texts = useMemo(
    () => ({
      title: t(`${dictPrefix}.title`),
      subtitle: t(`${dictPrefix}.subtitle`),
      subtitleWitoutActivities: t(`${dictPrefix}.subtitleWitoutActivities`),
      box: t<
        string,
        Record<
          'student' | 'studyPlan' | 'schedule' | 'semester' | 'campus',
          string
        >
      >(`${dictPrefix}.box`, {
        returnObjects: true,
      }),
      noActivities: t(`${dictPrefix}.noActivities`),
    }),
    [t],
  );

  return (
    <BaseLoader studyPlanEnrollmentId={studyPlanEnrollmentId}>
      {({ data, refresh }) => {
        return (
          <Card className="p-4 mt-2 mx-3">
            <section className="petition-info mb-4">
              <Row>
                <Col xs={12} sm={3}>
                  <GoBackToListButton />
                </Col>
                <Col xs={12} sm={9}>
                  <StudentBox
                    studentName={`${data.student.name}, ${data.student.lastNames}`}
                    studyPlanName={data.studyPlan.name}
                    scheduleName={data.schedule.name}
                    campusName={data.campus.name}
                    texts={texts.box}
                  />
                </Col>
              </Row>
              <br className="mt-4" />
            </section>

            <section className="petition-summary mb-4">
              <div className="mb-3">
                <SectionTitle text={texts.title} />
              </div>
              <div className="mb-3">
                {Boolean(data.degreeProcess.activities.length) ? (
                  <p>{texts.subtitle}</p>
                ) : (
                  <p>{texts.subtitleWitoutActivities}</p>
                )}
              </div>
              {Boolean(data.degreeProcess.activities.length) && (
                <div className="mb-4">
                  <Table
                    data={data.degreeProcess.activities}
                    toggleModalRendition={(activityId) => {
                      setActivityId(activityId);
                      setShowModal(!showModal);
                    }}
                    enrollmentStatus={data.studyPlanEnrollment.status}
                  />
                  <CreateRenditionModal
                    show={showModal}
                    toggleModal={() => {
                      setShowModal(!showModal);
                    }}
                    onSave={(searchParams: CreateRenditionParams) =>
                      handleSaveRendition({ searchParams, refresh })
                    }
                    activityId={activityId}
                  />
                </div>
              )}
              {!data.degreeProcess.activities.length && (
                <div className="mb-4">
                  <Alert
                    className=""
                    color="info"
                    icon="information"
                    size="lg"
                    text={texts.noActivities}
                  />
                </div>
              )}

              <div>
                {data.degreeProcess.summary ? (
                  <FormView
                    date={data.degreeProcess.summary.date}
                    graduationGrade={data.degreeProcess.summary.gradeGraduation}
                    degreeGrade={data.degreeProcess.summary.gradeDegreeProcess}
                    distinctionRank={
                      data.degreeProcess.summary.distinctionRank?.name
                    }
                  />
                ) : (
                  <Form
                    studyPlanEnrollmentId={studyPlanEnrollmentId}
                    graduationGrade={data.graduation.grade}
                    distinctionRanks={data.distinctionRanks}
                    financialBlockade={data.financialBlockade}
                    onComplete={() => refresh()}
                    disabled={
                      data.degreeProcess.activities.length > 0 &&
                      data.degreeProcess.activities.some(
                        (a) => a.state?.rendition.approved !== true,
                      )
                    }
                  />
                )}
              </div>
            </section>
          </Card>
        );
      }}
    </BaseLoader>
  );
}
