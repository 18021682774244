interface UrlTuitionProcess {
  studentPostulantId?: string | number | null;
  studentDetailId?: string | number;
  studentAdmissionTypeId?: string;
  fromStart: boolean;
}

export const generateUrlToTuitionProcess = ({
  studentPostulantId,
  studentDetailId,
  studentAdmissionTypeId,
  fromStart,
}: UrlTuitionProcess) => {
  return `/tuition-process/postulation/${studentPostulantId}/${studentDetailId}/${studentAdmissionTypeId}/${fromStart}`;
};
