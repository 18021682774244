import { CampusBody } from '../../types/CampusTypes';
import { PaginationRequestType } from '../../types/paginationRequestType';
import { ACADEMIC_STRUCTURE } from '../endpoints';
import request from '../request';

export interface CampusListResponse {
  data: CampusBody;
}

/**
 * Obtiene el listado de sedes
 */
export const getCampusRequest = (items: number = 10, page: number = 0) => {
  const url = `${ACADEMIC_STRUCTURE.CAMPUS}?items_per_page=${items}&page=${page}`;
  return request<PaginationRequestType<CampusBody>>(url);
};

/**
 *  Crear o editar una sede
 */
export const saveCampusRequest = (data: CampusBody) => {
  const isEdit = !!data.id;

  const url = `${ACADEMIC_STRUCTURE.CAMPUS}/${isEdit ? data.id : ''}`;
  return request<CampusListResponse>(url, {
    method: isEdit ? 'put' : 'post',
    data,
  });
};
