import { DateInput, Select, TextInput } from '@octano/global-ui';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'reactstrap';

import { useParameters } from '../../../../../hooks/useParameters';
import { useValidations } from '../../../../../hooks/useValidations';
import { Student } from '../../../../../types/tuitionProcessOnSite';
import { getSelectedOption } from '../../../../../utils/selectFormat';

const TuitionProcessFormFields = ({
  student,
  passport,
  disabledInputs,
}: {
  student: Student;
  passport: boolean;
  disabledInputs?: boolean;
}) => {
  const { t } = useTranslation();
  const prefix = 'tuitionProcess.form.';
  const {
    validateTextNotEmpty,
    validatePhone,
    validateEmail,
    msgValidations,
  } = useValidations();

  const { control } = useFormContext();

  const { countryOptions } = useParameters();

  return (
    <>
      <Row>
        <Col md={4}>
          {passport ? (
            <Row>
              <Col>
                <Select
                  label={t('tuitionProcess.searchStudent.inputCountry')}
                  name="country"
                  isSearchable={true}
                  disabled={true}
                  value={getSelectedOption(student.country, countryOptions)}
                  options={countryOptions}
                />
              </Col>
              <Col>
                <TextInput
                  name="passportNumber"
                  control={control}
                  label={t(prefix + 'passport')}
                  readOnly
                />
              </Col>
            </Row>
          ) : (
            <TextInput
              name="rut"
              control={control}
              formatter="rut"
              value={student.rut}
              label={t(prefix + 'rut')}
              readOnly
              defaultValue={student?.rut}
            />
          )}
        </Col>
        <Col md={4}>
          <TextInput
            name="names"
            control={control}
            label={t(prefix + 'names')}
            rules={{ validate: validateTextNotEmpty }}
            disabled={disabledInputs}
          />
        </Col>
        <Col md={4}>
          <TextInput
            name="lastnamePather"
            control={control}
            label={t(prefix + 'lastnamePather')}
            rules={{ validate: validateTextNotEmpty }}
            disabled={disabledInputs}
          />
        </Col>
      </Row>
      <Row>
        <Col md={4}>
          <TextInput
            name="lastnameMother"
            control={control}
            label={t(prefix + 'lastnameMother')}
            rules={{ validate: validateTextNotEmpty }}
            disabled={disabledInputs}
          />
        </Col>
        <Col md={4}>
          <TextInput
            name="email"
            control={control}
            label={t(prefix + 'email')}
            disabled={disabledInputs}
            rules={{
              validate: {
                notEmpty: validateTextNotEmpty,
                email: validateEmail,
              },
            }}
          />
        </Col>
        <Col md={4}>
          <TextInput
            name="phone"
            control={control}
            formatter="phone"
            label={t(prefix + 'phone')}
            disabled={disabledInputs}
            placeholder={t('common.placeholder.cellphone')}
            rules={{
              validate: {
                notEmpty: validateTextNotEmpty,
                phone: validatePhone,
              },
            }}
          />
        </Col>
      </Row>
      <Row>
        <Col md={4}>
          <DateInput
            name="birthdate"
            control={control}
            label={t(prefix + 'birthdate')}
            disabled={disabledInputs}
            rules={{ required: msgValidations.required }}
          />
        </Col>
      </Row>
    </>
  );
};

export default TuitionProcessFormFields;
