import { addToast } from '@octano/global-ui';
import {
  forwardRef,
  useCallback,
  useImperativeHandle,
  useMemo,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';

import ConfirmationModal, {
  ConfirmationModalProps,
} from '../../../../../../components/modals/ConfirmationModal';

export enum SelectCareerFailedModalScope {
  AdmissionError = 'EXISTING_ADMISSION',
  InvalidTuition = 'INVALID_REGISTRATION',
  ChangeCareer = 'CHANGE_CAREER',
  QuotaError = 'QUOTA_EXCEEDED',
  QuotaErrorJoinWaitlist = 'QUOTA_EXCEEDED_JOIN_WAITLIST',
}
export type SelectCareerFailedModalMethodParams = {
  phone?: string;
  email?: string;
  currentStudyPlanName?: string;
  allowWaitlistJoin?: boolean;
  boxDetail?: ConfirmationModalProps['boxDetail'];
};

export type SelectCareerFailedModalMethods = {
  open: (
    scope: SelectCareerFailedModalScope,
    params?: SelectCareerFailedModalMethodParams,
  ) => void;
  openConditional: (
    scope: SelectCareerFailedModalScope | string | null,
    error?: string,
    params?: SelectCareerFailedModalMethodParams,
  ) => void;
  close: () => void;
};

type SelectCareerFailedModalProps = {
  loading?: boolean;
  onJoin?: (params?: SelectCareerFailedModalMethodParams) => void;
  onChangeCareer?: (params?: SelectCareerFailedModalMethodParams) => void;
};

const validScopes = [
  SelectCareerFailedModalScope.AdmissionError,
  SelectCareerFailedModalScope.InvalidTuition,
  SelectCareerFailedModalScope.ChangeCareer,
  SelectCareerFailedModalScope.QuotaError,
  SelectCareerFailedModalScope.QuotaErrorJoinWaitlist,
];

const secondaryBtnScopes = [
  SelectCareerFailedModalScope.ChangeCareer,
  SelectCareerFailedModalScope.QuotaErrorJoinWaitlist,
];

const boxDetailScopes = [SelectCareerFailedModalScope.ChangeCareer];

const transaltions = {
  [SelectCareerFailedModalScope.AdmissionError]:
    'tuitionProcessNoSua.selectCareer.admissionExists',
  [SelectCareerFailedModalScope.InvalidTuition]:
    'tuitionProcessNoSua.selectCareer.invalidTuition',
  [SelectCareerFailedModalScope.ChangeCareer]:
    'tuitionProcessNoSua.selectCareer.modalChangeCareer',
  [SelectCareerFailedModalScope.QuotaError]:
    'tuitionProcessNoSua.selectCareer.modalQuotaExceed',
  [SelectCareerFailedModalScope.QuotaErrorJoinWaitlist]:
    'tuitionProcessNoSua.selectCareer.modalWaitingList',
};

const SelectCareerFailedModal = (
  { loading = false, onJoin, onChangeCareer }: SelectCareerFailedModalProps,
  ref: React.Ref<SelectCareerFailedModalMethods>,
) => {
  const { t } = useTranslation();
  const [visible, setVisible] = useState<boolean>(false);
  const [params, setParams] = useState<SelectCareerFailedModalMethodParams>();
  const [scope, setScope] = useState<SelectCareerFailedModalScope>(
    SelectCareerFailedModalScope.QuotaError,
  );

  const prefix = useMemo(() => transaltions[scope] ?? scope, [scope]);

  const handleClose = useCallback(() => {
    setVisible(false);
    setParams(undefined);
  }, []);

  const handleOpen = useCallback(
    (
      nextScope: SelectCareerFailedModalScope,
      params?: SelectCareerFailedModalMethodParams,
    ) => {
      if (
        nextScope === SelectCareerFailedModalScope.QuotaError &&
        params?.allowWaitlistJoin
      ) {
        setScope(SelectCareerFailedModalScope.QuotaErrorJoinWaitlist);
      } else {
        setScope(nextScope);
      }
      setParams(params);
      setVisible(true);
    },
    [],
  );

  const handleOpenConditional = useCallback(
    (
      nextScope: SelectCareerFailedModalScope | string | null,
      error?: string,
      params?: SelectCareerFailedModalMethodParams,
    ) => {
      if (
        nextScope &&
        validScopes.includes(nextScope as SelectCareerFailedModalScope)
      ) {
        handleOpen(nextScope as SelectCareerFailedModalScope, params);
      } else if (error?.trim()) {
        addToast({
          icon: 'error',
          color: 'danger',
          text: error?.trim(),
        });
      }
    },
    [handleOpen],
  );

  useImperativeHandle(ref, () => ({
    open: handleOpen,
    close: handleClose,
    openConditional: handleOpenConditional,
  }));

  const handlePrimary = useCallback(() => {
    if (scope === SelectCareerFailedModalScope.QuotaErrorJoinWaitlist) {
      !!onJoin && onJoin(params);
    }
    if (scope === SelectCareerFailedModalScope.ChangeCareer) {
      !!onChangeCareer && onChangeCareer(params);
    }
    handleClose();
  }, [handleClose, onChangeCareer, onJoin, params, scope]);

  const handleSecondary = useCallback(() => {
    handleClose();
  }, [handleClose]);

  const primaryBtn = useMemo(() => {
    return {
      loading,
      text: t(`${prefix}.actions.primary`, params),
      action: handlePrimary,
    };
  }, [handlePrimary, loading, params, prefix, t]);

  const secondaryBtn = useMemo(() => {
    if (secondaryBtnScopes.includes(scope)) {
      return {
        text: t(`${prefix}.actions.secondary`, params),
        action: handleSecondary,
      };
    }

    return undefined;
  }, [handleSecondary, params, prefix, scope, t]);

  const boxDetail = useMemo(() => {
    if (params?.boxDetail) {
      return params?.boxDetail;
    }
    if (boxDetailScopes.includes(scope)) {
      return {
        title: t(`${prefix}.boxDetailScopes.title`, params),
        body: [t(`${prefix}.boxDetailScopes.body`, params)],
      };
    }

    return undefined;
  }, [params, prefix, scope, t]);

  return (
    <ConfirmationModal
      isOpen={visible}
      iconName={
        scope === SelectCareerFailedModalScope.ChangeCareer
          ? 'warning'
          : undefined
      }
      toggle={handleClose}
      title={t(`${prefix}.title`, params)}
      body={t(`${prefix}.body`, params)}
      boxDetail={boxDetail}
      primaryBtn={primaryBtn}
      secondaryBtn={secondaryBtn}
    />
  );
};

export default forwardRef(SelectCareerFailedModal);
