import { addToast } from '@octano/global-ui';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Card, Col, Row } from 'reactstrap';

import { saveSustainer } from '../../../../../../api/requests/tuitionProcess';
import DynamicForm from '../../../../../../components/DynamicForm/DynamicForm';
import DynamicContact from '../../../../../../components/DynamicForm/parts/DynamicContact';
import DynamicContactCampus from '../../../../../../components/DynamicForm/parts/DynamicContactCampus';
import DynamicInformation from '../../../../../../components/DynamicForm/parts/DynamicInformation';
import DynamicInformationCampus from '../../../../../../components/DynamicForm/parts/DynamicInformationCampus';
import GoBackButton from '../../../../../../components/buttons/GoBackButton';
import { useStepState } from '../../../../../../components/step/useStepState';
import {
  RELATIONSHIP_NO_FAMILY,
  RELATIONSHIP_OWN_SUSTAINER,
  SustainerType,
  relationshipOptions,
  sustainerTypeOptions,
  sustainerTypeWithPassportOptions,
} from '../../../../../../utils/selectOptions';
import { useTuitionProcess } from '../../../../../TuitionProcess/TuitionProcessContext';
import { useTuitionProcessNoSua } from '../../hooks/useTuitionProcessNoSua';
import HeaderStep from '../HeaderStep';
import SustainerFileLoader from './parts/SustainerFileLoader';

const SustainerFileNoSuaStep = () => {
  //Custom Hooks
  const {
    prefix: rootPrefix,
    postulationDetailId,
    onError,
  } = useTuitionProcess();
  const { nextStep, prevStep } = useStepState();
  const { postulationDetail } = useTuitionProcessNoSua();
  const { t } = useTranslation();

  const filteredRelationshipOptions = useMemo(
    () =>
      relationshipOptions.filter(
        (row) =>
          (true && row.value === RELATIONSHIP_OWN_SUSTAINER) ||
          row.value !== RELATIONSHIP_OWN_SUSTAINER,
      ),
    [],
  );
  //Step Prefix
  const prefix = useMemo(() => `${rootPrefix}.sustainer`, [rootPrefix]);

  //Submit form function
  const onSubmit = async (values: any) => {
    if (postulationDetailId) {
      let responses = Object.entries(values)
        .map(([key, value]) => ({
          fieldName: key,
          value,
        }))
        .filter(({ value }) => value);
      /**
       * en caso de ser persona  juridica se rellena esa data con guiones
       */
      if (values.sustainerType?.value === SustainerType.LegalPerson) {
        [
          'maternalLastName',
          'paternalLastName',
          'occupation',
          'relationship',
        ].forEach((key) => {
          responses.push({
            fieldName: key,
            value: key === 'relationship' ? RELATIONSHIP_NO_FAMILY : '-',
          });
        });
      }

      /**
       * si el país es chile igual mando location y custom city
       */
      if (String(values.country?.label).toLowerCase().includes('chile')) {
        responses.push(
          { fieldName: 'location', value: values.commune?.label },
          { fieldName: 'customCity', value: values.city?.label ?? '-' },
        );
      }

      const { error } = await saveSustainer(postulationDetailId, {
        responses,
      });
      if (error) {
        addToast({
          icon: 'error',
          color: 'danger',
          text: 'Ha ocurrido un error al guardar la información',
        });
      } else {
        addToast({
          icon: 'success',
          color: 'success',
          text: 'Datos guardados correctamente',
        });
        nextStep();
      }
    }
  };

  const sectionComponents = (
    sustainerPassportAvailable: boolean,
    postulantHasPassport: boolean,
  ) => ({
    sustainerInformation: (
      <DynamicInformation
        type="sustainerFile"
        key={Math.floor(Math.random() * 100)}
        sustainerPassportAvailable={sustainerPassportAvailable}
        postulantHasPassport={postulantHasPassport}
      />
    ),
    sustainerInformationCampus: (
      <DynamicInformationCampus
        type="sustainerFile"
        key={Math.floor(Math.random() * 100)}
        sustainerPassportAvailable={sustainerPassportAvailable}
        postulantHasPassport={postulantHasPassport}
      />
    ),
    academicContact: (
      <DynamicContact
        type="sustainerFile"
        key={Math.floor(Math.random() * 100)}
        prefix={`${prefix}.contact`}
        disabled={false}
        hasEmail
      />
    ),
    academicContactCampus: (
      <DynamicContactCampus
        type="sustainerFile"
        key={Math.floor(Math.random() * 100)}
        prefix={`${prefix}.contact`}
        disabled={false}
        hasEmail
      />
    ),
  });

  return (
    <Card className="px-4 px-md-5 py-4">
      <SustainerFileLoader {...{ postulationDetailId, onError }}>
        {({
          sustainerPassportAvailable,
          studentFullName,
          defaultValues,
          ownSustainer,
        }) => {
          return (
            <>
              {postulationDetail?.admissionMethod === 'sin-sua' ? (
                <HeaderStep prefixStep={prefix} showBackBtn />
              ) : (
                <Row className="pb-4 pb-md-0">
                  <Col xs={12}>
                    <GoBackButton onClick={() => prevStep()} />
                  </Col>
                  <Col className="py-3" xs={12} lg={7} md={8} xl={9}>
                    <span className="fs-18 text-primary">
                      {t(`${prefix}.student`)}
                    </span>
                    <br />
                    <span className="fs-18 text-primary fw-600">
                      {studentFullName}
                    </span>
                  </Col>
                </Row>
              )}
              <DynamicForm
                dynamicFormId={2}
                defaultValues={defaultValues}
                optionalValues={ownSustainer}
                sectionComponents={sectionComponents(
                  sustainerPassportAvailable,
                  Boolean(ownSustainer.passportNumber),
                )}
                options={{
                  relationship: filteredRelationshipOptions,
                  sustainerType: sustainerPassportAvailable
                    ? sustainerTypeOptions
                    : sustainerTypeWithPassportOptions,
                }}
                onSubmit={onSubmit}
              />
            </>
          );
        }}
      </SustainerFileLoader>
    </Card>
  );
};

export default SustainerFileNoSuaStep;
