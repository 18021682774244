import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

export const useDetailBoxColumns = (section?: {
  name: string;
  course?: { shortening: string; name: string; school?: { name: string } };
}) => {
  const { t } = useTranslation();
  const prefix = 'sectionsMaintainer.update';

  return useMemo(() => {
    return [
      {
        title: t(`${prefix}.sectionCode`),
        body: section?.name,
        size: { xs: 12, md: 3 },
      },
      {
        title: t(`${prefix}.courseCode`),
        body: section?.course?.shortening,
        size: { xs: 12, md: 3 },
      },
      {
        title: t(`${prefix}.courseName`),
        body: section?.course?.name,
        size: { xs: 12, md: 3 },
      },
      {
        title: t(`${prefix}.faculty`),
        body: section?.course?.school?.name,
        size: { xs: 12, md: 3 },
      },
    ];
  }, [t, section]);
};
