import { generatePath } from 'react-router-dom';
import request from '../../api/request';
import { GetPeriodOFMantainerPortalResponseV2 } from '../../api/requests/MantainerPortal';
import {
  AcademicOfferStudyPlansOfferDocumentationByStatus,
  DocumentationReviewStatus,
  StudyPlansOfferCampusSchedule,
  StudyPlansOfferDocumentationReview,
} from '../../api/requests/studyPlansOffer';
import { PaginationRequestType } from '../../types/paginationRequestType';
import {
  OtherDocumentOfPostulantProcess,
  PostulationDocumentationReview,
} from './types';

export const DOCUMENTATION_REVIEW = {
  FORM_DATA: '/maintainers-portal/documentation-review-process/form-data',
  INFORMATION: (academicOfferStudyPlanVersionOffersId: string | number) =>
    `/maintainers-portal/documentation-review-process/study-plan-version-offers/academic-offer-study-plan-version-offers/${academicOfferStudyPlanVersionOffersId}/information`,
  POSTULANTS_ACADEMIC_OFFER_STUDY_PLAN_VERSION_OFFER: (
    academicOfferStudyPlanVersionOffersId: string | number,
  ) =>
    `/maintainers-portal/documentation-review-process/study-plan-version-offers/academic-offer-study-plan-version-offers/${academicOfferStudyPlanVersionOffersId}/postulants`,
  STUDY_PLAN_VERSION_OFFERS:
    '/maintainers-portal/documentation-review-process/study-plan-version-offers',
  REVIEW_UPLOAD_DOCUMENT: (postulationDetailId: number | string) =>
    `/maintainers-portal/postulation-documentation-review/${postulationDetailId}/upload-document`,
  REVIEW_UPLOAD_IDENTITY_CARD: (postulationDetailId: number | string) =>
    `/maintainers-portal/postulation-documentation-review/${postulationDetailId}/upload-identity-card`,
  GET_DOCUMENTS: (postulationDetailId: number) =>
    generatePath(
      '/maintainers-portal/postulation-documentation-review/:postulationDetailId',
      {
        postulationDetailId,
      },
    ),

  /**
   * TODO:
   * Identificar y eliminar los endpoints queu no se usen
   * Transformar string a funciones anonimas
   */
  CHANGE_DOCUMENT_STATE:
    '/maintainers-portal/documentation-review-process/change-document-state',
  CHANGE_IDENTITY_CARD_STATE:
    '/maintainers-portal/documentation-review-process/change-identity-card-state',
  DELETE_DOCUMENT:
    '/maintainers-portal/documentation-review-process/delete-document',
  DELETE_IDENTITY_CARD:
    '/maintainers-portal/documentation-review-process/delete-identity-card',
  GET_LINK_DOWNLOAD_DOCUMENT:
    '/maintainers-portal/documentation-review-process/download-document',
  GET_LINK_DOWNLOAD_IDENTITY_CARD:
    '/maintainers-portal/documentation-review-process/download-identity-card',
  VIEW_OTHER_DOCUMENTS:
    '/maintainers-portal/documentation-review-process/view-document',
  VIEW_IDENTITY_CARD:
    '/maintainers-portal/documentation-review-process/view-identity-card',
  DELETE_OTHER_DOCUEMNTS:
    '/maintainers-portal/documentation-review-process/postulant-other-documents',
  UPLOAD_OTHER_DOCUMENTS:
    '/maintainers-portal/documentation-review-process/postulant-other-documents',
  DOWNLOAD_OTHER_DOCUMENTS:
    '/maintainers-portal/documentation-review-process/postulant-other-documents/download',
};

export const getStudyPlanCampusSchedule = (
  academicOfferStudyPlanVersionOffersId: string | number,
) => {
  return request<StudyPlansOfferCampusSchedule>(
    DOCUMENTATION_REVIEW.INFORMATION(academicOfferStudyPlanVersionOffersId),
    { method: 'GET' },
  );
};

export const getDocumentationReview = ({
  periodId,
  campusId,
  schoolId,
  items_per_page,
  page,
}: {
  periodId: number;
  campusId?: number;
  schoolId?: number;
  items_per_page: number;
  page: number;
}) => {
  return request<PaginationRequestType<StudyPlansOfferDocumentationReview>>(
    DOCUMENTATION_REVIEW.STUDY_PLAN_VERSION_OFFERS,
    {
      method: 'GET',
      params: { items_per_page, page: page - 1, periodId, campusId, schoolId },
    },
  );
};

export const getDocumentationByStatus = (
  academicOfferStudyPlanVersionOffersId: number | string,
  {
    status,
    items,
    page,
    searchText,
  }: {
    status?: DocumentationReviewStatus;
    items: number;
    page: number;
    searchText?: string;
  },
) => {
  return request<
    PaginationRequestType<AcademicOfferStudyPlansOfferDocumentationByStatus>
  >(
    DOCUMENTATION_REVIEW.POSTULANTS_ACADEMIC_OFFER_STUDY_PLAN_VERSION_OFFER(
      academicOfferStudyPlanVersionOffersId,
    ),
    {
      method: 'GET',
      params: {
        items_per_page: items,
        page,
        searchText,
        status,
      },
    },
  );
};

export function getPeriodOFMantainerPortalV2() {
  const url = DOCUMENTATION_REVIEW.FORM_DATA;
  return request<GetPeriodOFMantainerPortalResponseV2>(url, {
    method: 'get',
  });
}

export function uploadDocumentOfPostulantProcess(
  postulationDetailId: number,
  formData: FormData,
) {
  const url = DOCUMENTATION_REVIEW.REVIEW_UPLOAD_DOCUMENT(postulationDetailId);
  return request(url, {
    method: 'post',
    data: formData,
  });
}

export function uploadIdentityCardPostulantProcess(
  postulationDetailId: number,
  formData: FormData,
) {
  const url = DOCUMENTATION_REVIEW.REVIEW_UPLOAD_IDENTITY_CARD(
    postulationDetailId,
  );
  return request(url, {
    method: 'post',
    data: formData,
  });
}

export function getPostulationDocumentsReview(postulationDetailId: number) {
  let url = DOCUMENTATION_REVIEW.GET_DOCUMENTS(postulationDetailId);
  return request<PostulationDocumentationReview>(url, {
    method: 'GET',
  });
}

export function changeDocumentStateOfPostulantProcess(
  id: number,
  data: { status: string },
) {
  const url = `${DOCUMENTATION_REVIEW.CHANGE_DOCUMENT_STATE}/${id}`;
  return request(url, {
    method: 'PUT',
    data,
  });
}

export function changeIdentityCardStateOfPostulantProcess(
  id: number,
  data: { status: string },
) {
  const url = `${DOCUMENTATION_REVIEW.CHANGE_IDENTITY_CARD_STATE}/${id}`;
  return request<any>(url, {
    method: 'PUT',
    data,
  });
}

export function deleteDocumentOfPostulantProcess(id: number) {
  const url = `${DOCUMENTATION_REVIEW.DELETE_DOCUMENT}/${id}`;
  return request<any>(url, {
    method: 'delete',
  });
}

export function getLinkDownloadDocumentOfPostulantProcess(id: number) {
  const url = `${DOCUMENTATION_REVIEW.GET_LINK_DOWNLOAD_DOCUMENT}/${id}`;
  return request<any>(url, {
    method: 'get',
  });
}

export function getLinkDownloadIdentityCardOfPostulantProcess(id: number) {
  const url = `${DOCUMENTATION_REVIEW.GET_LINK_DOWNLOAD_IDENTITY_CARD}/${id}`;
  return request<any>(url, {
    method: 'get',
  });
}

export function uploadOtherDocumentOfPostulantProcess(formData: FormData) {
  const url = `${DOCUMENTATION_REVIEW.UPLOAD_OTHER_DOCUMENTS}`;
  return request<OtherDocumentOfPostulantProcess[]>(url, {
    method: 'post',
    data: formData,
  });
}

export function showIdentityCardDocumentOfPostulantProcess(id: number) {
  const url = `${DOCUMENTATION_REVIEW.VIEW_IDENTITY_CARD}/${id}`;
  return request<any>(url, {
    method: 'get',
  });
}

export function showOtherDocumentOfPostulantProcess(id: number) {
  const url = `${DOCUMENTATION_REVIEW.VIEW_OTHER_DOCUMENTS}/${id}`;
  return request(url, {
    method: 'get',
  });
}

export function deleteIdentityCardOfPostulantProcess(id: number) {
  const url = `${DOCUMENTATION_REVIEW.DELETE_IDENTITY_CARD}/${id}`;
  return request<any>(url, {
    method: 'delete',
  });
}

export function deleteOtherDocumentOfPostulantProcess(id: number) {
  const url = `${DOCUMENTATION_REVIEW.DELETE_OTHER_DOCUEMNTS}/${id}`;
  return request<any>(url, {
    method: 'delete',
  });
}

export function downloadOtherDocumentOfPostulantProcess(id: number) {
  const url = `${DOCUMENTATION_REVIEW.DOWNLOAD_OTHER_DOCUMENTS}/${id}`;
  return request<string>(url, {
    method: 'get',
  });
}
