import { ColumnTable, TableHead } from '@octano/global-ui';
import React, { useMemo } from 'react';
import { Table as BaseTable } from 'reactstrap';

import { TableBody, TableBodyProps } from './body';

export type TableProps<T> = {
  /**
   * Array con las columnas que contendrá la tabla,
   * Se debe agregar el columnName que corresponde al key de data que se renderizará en esta columna
   * también se puede formatear con la función cellFormat que entrega la siguiente información
   * (row, value, index, data)
   */
  columns: ColumnTable<T>[];
  /**
   * Array con la información que se quiere renderizar en cada fila de la tabla
   */
  data: T[];
  /**
   * Texto o componente que se mostrará cuando el array data esté vacío
   * NOTA: La paginación se mostrará SOLO si existe más de una página de resultados
   */
  noResultsText?: string | React.ReactNode;
  /**
   * Indica si las filas de la tabla se verán de colores alternados (blanco/gris). Por defecto es true.
   * Nota: Si el color de la tabla es gray el valor striped no tendrá efecto.
   */
  striped?: boolean;
  /**
   * Fila adicional bajo el header, puede ser utilizada para agregar buscador o alguna información adicional
   */
  subHeader?: JSX.Element;
  /**
   * Altura máxima en pixeles que tendrá la tabla, se agrega scroll lateral dejando el header fijo
   */
  height?: number;
  /**
   * Muestra la tabla con header color secondary o gris (cuando es gris las filas también serán un tono más claro de gris)
   */
  color?: 'gray' | 'default' | 'transparent';
  /**
   * Para indicar el alto de las filas de la tabla
   */
  size?: 'small' | 'normal';
  /**
   * Indica si las filas quedan sin borde entre ellas.
   */
  borderless?: boolean;

  secondColumn: TableBodyProps['secondColumn'];
};

/**
 * Componente tabla utilizado para renderizar un listado de resultados.
 * Se le puede agregar paginador agregando el prop "pagination"<br/>
 * Si sólo deseas utilizar los estilos de la tabla, puedes utilizar las tablas de reactstrap
 * https://reactstrap.github.io/components/tables/ y utilizar la tabla con el className="g-table"
 * y las propiedades "striped" y "borderless"
 *
 */
export function Table<T>({
  columns: columnsProp,
  data,
  noResultsText = 'Sin resultados.',
  striped = true,
  subHeader,
  height,
  color = 'default',
  size = 'normal',
  borderless = true,
  secondColumn,
}: TableProps<T>) {
  const noResults = (
    <tfoot>
      <tr>
        <td colSpan={columnsProp.length} className="text-center py-4">
          {typeof noResultsText === 'string' ? (
            <span>{noResultsText}</span>
          ) : (
            noResultsText
          )}
        </td>
      </tr>
    </tfoot>
  );

  const columnHeader = useMemo(() => {
    return [
      ...columnsProp,
      {
        columnName: 'collapseAction',
        headerText: '',
        width: '40px',
      },
    ];
  }, [columnsProp]);

  return (
    <React.Fragment>
      <div
        className={`g-table-${color} g-table-${size} ${
          height ? 'fixed-header' : ''
        }`}
        style={{ maxHeight: `${height ? height + 'px' : 'auto'}` }}
      >
        <BaseTable
          className={`g-table`}
          striped={striped}
          borderless={borderless}
          responsive={color !== 'transparent'}
        >
          <TableHead columns={columnHeader} subHeader={subHeader} />
          <TableBody
            columns={columnsProp}
            secondColumn={secondColumn}
            data={data}
          />
          {/* MENSAJE INDICANDO QUE NO HAY RESULTADOS */}
          {data.length === 0 && noResults}
        </BaseTable>
      </div>
    </React.Fragment>
  );
}
