import {
  addToast,
  Button,
  CheckInput,
  Modal,
  Select,
  SelectOptionType,
  TextInput,
} from '@octano/global-ui';
import { useEffect, useMemo, useRef } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'reactstrap';
import { CardTitle } from '../../../../../../../components/text';
import { useLayoutState } from '../../../../../../../hooks/useLayoutState';
import { useValidations } from '../../../../../../../hooks/useValidations';
import { saveDiscountPostulant } from '../api';
import { Discount, DiscountTypes, PostulationDetailDiscount } from '../type';

interface DiscountFormProps {
  show: boolean;
  toggleModal: () => void;
  allDiscounts: Discount[];
  postulantDiscounts: PostulationDetailDiscount[];
  reloadList: () => void;
  postulationDetailId: number;
}

interface DiscountFormValues {
  code: string;
  name: string;
  percentage: string;
  type: SelectOptionType | null;
  giveCustomDiscount: boolean;
}

export const InProcessDiscountModal = ({
  show,
  toggleModal,
  allDiscounts,
  postulantDiscounts,
  reloadList,
  postulationDetailId,
}: DiscountFormProps) => {
  const prefix = 'admission';
  const { t } = useTranslation();
  const form = useForm<DiscountFormValues>({ mode: 'all' });
  const { handleSubmit, control, watch, formState, setValue } = form;
  const { isSubmitting } = formState;
  const { msgValidations, validateTextNotEmpty } = useValidations();

  const discountTypesOptions: SelectOptionType[] = useMemo(() => {
    return [
      { value: DiscountTypes.Tuition, label: t(`${prefix}.tuition`) },
      { value: DiscountTypes.Tariff, label: t(`${prefix}.tariff`) },
    ];
  }, [t]);

  const validateCode = () => {
    return !allDiscounts.some((discount) => discount.code === code) &&
      !giveCustomDiscount
      ? 'Descuento no existe'
      : undefined;
  };

  const showToast = () => {
    toggleModal();
    addToast({
      icon: 'success',
      color: 'success',
      text: t(`${prefix}.discountAssignedMessage`),
    });
  };

  const { showErrorModal } = useLayoutState();

  const submit = async (values: DiscountFormValues) => {
    const { data, error } = await saveDiscountPostulant(postulationDetailId, {
      discountCode: values.code,
      discountName: values.name,
      discountValue: Number(values.percentage),
      type: String(values.type?.value),
    });
    if (error && error.code === 'CONNECTION') {
      showErrorModal();
      return;
    } else if (error) {
      addToast({
        icon: 'error',
        color: 'danger',
        text: t('common.errors.save'),
      });
      toggleModal();
    }
    if (data) {
      showToast();
      reloadList();
      toggleModal();
    }
  };

  const type = watch('type');
  const code = watch('code');
  const giveCustomDiscount = watch('giveCustomDiscount');
  const prevCodeRef = useRef<string | undefined>();

  useEffect(() => {
    prevCodeRef.current = code;
  }, [code]);

  const prevCode = prevCodeRef.current;

  // effect para manejar cambios en 'code'
  useEffect(() => {
    if (code !== prevCode) {
      const existsInputCode = allDiscounts.find(
        (discount) => discount.code === code,
      );
      if (existsInputCode) {
        setValue('name', existsInputCode.name);
        setValue('percentage', String(existsInputCode.percentage));
      }
    }
  }, [code, allDiscounts, prevCode, setValue]);

  // effect para manejar cambios en 'type'
  useEffect(() => {
    if (type?.value) {
      const discount = postulantDiscounts.find(
        (discount) => discount.type === type?.value,
      );
      setValue('name', discount?.discountName ?? '');
      setValue('percentage', String(discount?.discountValue ?? ''));
      setValue('code', discount?.discountCode ?? '');
    }
  }, [type, postulantDiscounts, setValue]);

  return (
    <Modal isOpen={show} toggle={toggleModal} size="md" unmountOnClose>
      <form onSubmit={handleSubmit(submit)}>
        <Row>
          <Col>
            <div className="text-center">
              <CardTitle children={t(`${prefix}.titleModalDiscount`)} />
            </div>
          </Col>
        </Row>
        <Row className="mt-0 mt-md-4">
          <Col xs="12" md="12">
            <Select
              name="type"
              options={discountTypesOptions}
              label={t('common.forms.type')}
              isClearable={false}
              control={control}
              rules={{ required: msgValidations.required }}
            />
          </Col>
          <Col xs="12" md="12">
            <TextInput
              name="name"
              readOnly={!giveCustomDiscount}
              label={t('common.forms.name')}
              control={control}
              rules={{ required: msgValidations.required }}
            />
          </Col>
        </Row>
        <Row className="mt-3">
          <Col md={12} xs={12}>
            <TextInput
              name="code"
              label={t('tariffAndTuition.discounts.discount.labelDiscountCode')}
              control={control}
              rules={{
                validate: { validateTextNotEmpty, validateCode },
              }}
            />
          </Col>
          <Col md={6} xs={12}>
            <TextInput
              name="percentage"
              readOnly={!giveCustomDiscount}
              label={t('tariffAndTuition.discounts.discount.percentage')}
              control={control}
              rules={{ required: msgValidations.required }}
            />
          </Col>
        </Row>
        <CheckInput
          name="giveCustomDiscount"
          label={t(`${prefix}.btn.giveCustomDiscount`)}
          control={control}
        />
        <Row className="mt-5">
          <Col xs={{ size: 12, order: 2 }} md={{ size: 6, order: 1 }}>
            <Button
              type="button"
              text={t('common.actions.cancel')}
              outlined
              onClick={() => toggleModal()}
              fullwidth
            />
          </Col>
          <Col xs={{ size: 12, order: 1 }} md={{ size: 6, order: 2 }}>
            <Button
              type="submit"
              text={t('common.actions.confirm')}
              fullwidth
              className="mb-3 mb-md-0"
              disabled={isSubmitting}
            />
          </Col>
        </Row>
      </form>
    </Modal>
  );
};
