import { Button, addToast, useMobile } from '@octano/global-ui';
import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import useUserState from '../../../../hooks/useUserState';
import { PermissionName } from '../../../../types/Auth';
import { downloadFromBlob } from '../../../../utils/blob';
import GeneratingFileModal from '../GeneratingFileModal';
import NoResultsModal from '../NoResultsModal';
import SelectStudyPlanVersionModal from '../SelectStudyPlanVersionModal';
import { createReportDownloadHandler } from './handlerCreator';

enum ModalType {
  NONE,
  STUDYPLAN_SELECTOR,
  GENERATING_FILE,
  NO_RESULTS,
}

interface IStudentProgressActions {
  downloadUrl: string;
}

const HTTP_STATUS_NO_CONTENT = 204;

export default function StudentProgressActions({
  downloadUrl,
}: IStudentProgressActions) {
  const { isAuthorizedTo } = useUserState();
  const { t } = useTranslation();
  const isMobile = useMobile();

  const [openedModal, setOpenedModal] = useState<ModalType>(ModalType.NONE);
  const fileName = useRef('');

  const handleReportDownload = createReportDownloadHandler({
    beforeDownload: () => setOpenedModal(ModalType.GENERATING_FILE),
    afterDownload: ({ status }) => {
      if (status === HTTP_STATUS_NO_CONTENT) {
        setOpenedModal(ModalType.NO_RESULTS);
      } else {
        setOpenedModal(ModalType.NONE);
      }
    },
    onSuccess: (data: Blob) => {
      if (data.size > 0) {
        const blob = new Blob([data], { type: 'vnd.ms-excel' });
        downloadFromBlob(blob, fileName.current);
      }
    },
    onError: () => {
      addToast({
        icon: 'error',
        color: 'danger',
        text: t(`reports.errors.errorGeneratingFile`),
      });
    },
  });

  const handleParamsSelection = (studyPlanVersionId: number) => {
    const reportName = t(`reports.studentProgress.fileNameTitle`);
    fileName.current = `${reportName}${studyPlanVersionId}.xlsx`;
    handleReportDownload(`${downloadUrl}/${studyPlanVersionId}`);
  };

  return (
    <>
      <NoResultsModal
        isOpen={openedModal === ModalType.NO_RESULTS}
        onConfirm={() => setOpenedModal(ModalType.NONE)}
        texts={{
          title: t('reports.studentProgress.noResults.title'),
          description: t('reports.studentProgress.noResults.description'),
        }}
      />

      <GeneratingFileModal isOpen={openedModal === ModalType.GENERATING_FILE} />

      <SelectStudyPlanVersionModal
        isOpen={openedModal === ModalType.STUDYPLAN_SELECTOR}
        modalTitle={t(`reports.studentProgress.title`)}
        onConfirm={handleParamsSelection}
        onCancel={() => setOpenedModal(ModalType.NONE)}
      />

      <div className="d-flex flex-wrap align-items-center " style={{ gap: 12 }}>
        <Button
          text={t(`common.actions.download`)}
          icon="download"
          size="sm"
          outlined
          style={{ width: isMobile ? '90%' : 123 }}
          disabled={!isAuthorizedTo([PermissionName.REPORTS])}
          onClick={() => setOpenedModal(ModalType.STUDYPLAN_SELECTOR)}
        />
      </div>
    </>
  );
}
