import { ReactElement, useCallback } from 'react';
import { AxiosResultDefaultError } from '../../../../api/request';
import { AuthenticationError } from '../../../../api/requests/tuitionProcess';
import { createFetchContext } from '../../../../components/contexts/FetchContextFactory';
import DisplayError from '../../../../components/info/DisplayError';
import Loading from '../../../../components/info/Loading';
import { useStudentCurrentAccountLoader } from '../../components/StudentCurrentAccountLoader';
import { getPaymentAgreement } from '../api';
import { PaymentAgreementResponse } from '../types';

interface BaseLoaderProps {
  children: ReactElement;
}

const { FetchProvider, FetchConsumer, useFetch } = createFetchContext<
  undefined,
  PaymentAgreementResponse,
  AuthenticationError | AxiosResultDefaultError
>();

export function usePaymentAgreetmentLoader() {
  return useFetch();
}

export const paymentAgreetmentLoaderConsumer = FetchConsumer;

export default function PaymentAgreementLoader({ children }: BaseLoaderProps) {
  const { data } = useStudentCurrentAccountLoader();
  const request = useCallback(async () => {
    return getPaymentAgreement(data.studentId);
  }, [data.studentId]);
  return (
    <FetchProvider
      request={request}
      defaultQuery={undefined}
      fetchImmediately={true}
    >
      <FetchConsumer>
        {({ loading, data, error, refresh }) => {
          if (loading) {
            return <Loading insideCard />;
          } else if (error) {
            return (
              <DisplayError
                insideCard
                textBody={error.code}
                retryAction={refresh}
              />
            );
          } else if (data) {
            return children;
          } else {
            return null;
          }
        }}
      </FetchConsumer>
    </FetchProvider>
  );
}
