import { useParams } from 'react-router-dom';
import { Container } from 'reactstrap';
import { TextsProvider } from './hooks/textsContext';
import EducationalBackgroundTable from './parts/EducationalBackgroundTable';
import EducationalBackgroundFormDataLoader from './parts/Loaders/EducationalBackgroundFormDataLoader';
import EducationalBackgroundLoader from './parts/Loaders/EducationalBackgroundLoader';

function EducationalBackground() {
  const prefix = 'teacherFile.tabs.educationalBackground';
  const { teacherId } = useParams<{ teacherId: string }>();

  return (
    <Container fluid className="mt-4">
      <TextsProvider prefix={prefix}>
        <EducationalBackgroundFormDataLoader>
          <EducationalBackgroundLoader teacherId={Number(teacherId)}>
            <EducationalBackgroundTable />
          </EducationalBackgroundLoader>
        </EducationalBackgroundFormDataLoader>
      </TextsProvider>
    </Container>
  );
}
export default EducationalBackground;
