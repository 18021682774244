import {
  ProcessGraduateResponse,
  SearchStudentsResponse,
  StudentSummary,
} from '../../types/graduationProcessSingle';
import { GRADUATION_PROCESS_SINGLE } from '../endpoints';
import request from '../request';

/**
 * Busca estudiantes para lanzar el proceso de egreso
 */
export function searchStudents(params: {
  searchBy: 'identificator' | 'name';
  search: string;
}) {
  return request<SearchStudentsResponse>(
    GRADUATION_PROCESS_SINGLE.SEARCH_STUDENTS,
    { params },
  );
}

/**
 * Lanza el proceso de egreso para un estudiante
 * @param studyPlanEnrollmentId
 * @returns
 */
export function processGraduate(studyPlanEnrollmentId: number) {
  return request<ProcessGraduateResponse>(
    GRADUATION_PROCESS_SINGLE.PROCESS_GRADUATE,
    {
      method: 'POST',
      data: { studyPlanEnrollmentId },
    },
  );
}

/**
 * Trae el resumen cuando no se cumplen los requisitos
 */
export function getSummary(studyPlanEnrollmentId: number) {
  const params = { studyPlanEnrollmentId };
  return request<StudentSummary>(GRADUATION_PROCESS_SINGLE.GET_SUMMARY, {
    params,
  });
}
