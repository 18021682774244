import { Button, ColumnTable, Table } from '@octano/global-ui';
import dayjs from 'dayjs';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import DisplayInfo from '../../../components/info/DisplayInfo';
import { StudyPlansByFacultyResponse } from '../../../types/graduationProcessMassive';

const dictPrefix = 'graduationProcessMassive';

export type TableRowData = StudyPlansByFacultyResponse[number];

interface Props {
  data: TableRowData[];
  isLoadingResults?: boolean;
  showNoResults?: boolean;
  showError?: boolean;
  onProcessGraduatesClick?: (studyPlanId: TableRowData['id']) => void;
}

export function ExecutedProcessesHistoryTable({
  data,
  isLoadingResults = false,
  showNoResults = false,
  showError = false,
  onProcessGraduatesClick = () => null,
}: Props) {
  const { t } = useTranslation();

  const COLUMNS: ColumnTable<TableRowData>[] = [
    {
      headerText: t(`${dictPrefix}.studyPlan`),
      columnName: 'name',
      thClassName: 'text-center',
      tdClassName: 'text-center',
    },
    {
      headerText: t(`${dictPrefix}.period`),
      columnName: 'period',
      thClassName: 'text-center',
      tdClassName: 'text-center',
    },
    {
      headerText: t(`${dictPrefix}.lastGraduationProcessDate`),
      columnName: 'lastGraduationProcess',
      thClassName: 'text-center',
      tdClassName: 'text-center',
      cellFormat: ({ value }) =>
        value ? dayjs(value?.executionDate).format('DD/MM/YYYY') : ' - ',
    },
    {
      headerText: t(`${dictPrefix}.action`),
      columnName: 'id',
      thClassName: 'text-center',
      tdClassName: 'text-center',
      cellFormat: ({ value }) => {
        return (
          <Button
            text={t(`${dictPrefix}.processGraduates`)}
            size="sm"
            onClick={() => onProcessGraduatesClick(value)}
          />
        );
      },
    },
  ];

  const noResultsTexts = useMemo(() => {
    if (showError) {
      return {
        title: t(`${dictPrefix}.failedToGetTableData.title`),
        textBody: t(`${dictPrefix}.failedToGetTableData.description`),
      };
    }

    if (showNoResults) {
      return { title: t(`${dictPrefix}.noResultsMsg`), textBody: '' };
    }

    return { title: t(`${dictPrefix}.selectFaculty`), textBody: '' };
  }, [showNoResults, showError, t]);

  return (
    <Table
      data={data}
      columns={COLUMNS}
      isLoadingResults={isLoadingResults}
      noResultsText={
        <div className="d-flex justify-content-center">
          <DisplayInfo
            title={noResultsTexts.title}
            textBody={noResultsTexts.textBody}
            maxWidth={600}
          />
        </div>
      }
    />
  );
}
