import { Table as TableCore } from '@octano/global-ui';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { Table as TableCollapsable } from '../../../components/table-collapsable';
import { STUDENT_STATUS } from '../../../types/studentStatusTypes';
import { DegreeProcessActivity, DegreeProcessRenditionRow } from '../types';
import { useColumns } from './useColumns';
import { useColumnsRenditions } from './useColumnsRenditions';

export interface TableProps {
  data: DegreeProcessActivity[];
  toggleModalRendition: (activityId: number | string) => any;
  enrollmentStatus: string;
}

export default function Table({
  data,
  toggleModalRendition,
  enrollmentStatus,
}: TableProps) {
  const { t } = useTranslation();

  const columns = useColumns();
  const columnsRenditions = useColumnsRenditions({
    toggleModalRendition,
  });

  const dataBuildEmptyRow = useCallback(
    (id: number | string) => ({
      buildEmptyRow: true,
      activityId: id,
    }),
    [],
  );

  return (
    <TableCollapsable<DegreeProcessActivity>
      columns={columns}
      secondColumn={{
        tdClassName: 'px-4',
        columnName: 'quota',
        headerText: t(`table.quota`),
        cellFormat: ({ row }) => {
          const buildEmptyRow =
            enrollmentStatus === STUDENT_STATUS.GRADUATE
              ? dataBuildEmptyRow(row.id)
              : dataBuildEmptyRow(0);
          return (
            <TableCore<DegreeProcessRenditionRow>
              borderless={false}
              color="transparent"
              striped
              columns={columnsRenditions}
              data={
                enrollmentStatus === STUDENT_STATUS.GRADUATE
                  ? [...row.renditions, buildEmptyRow]
                  : row.renditions
              }
            />
          );
        },
      }}
      data={data}
    />
  );
}
