import { ReactElement, useCallback } from 'react';
import { useParams } from 'react-router-dom';

import { AxiosResultDefaultError } from '../../../../api/request';
import { AuthenticationError } from '../../../../api/requests/tuitionProcess';
import { createFetchContext } from '../../../../components/contexts/FetchContextFactory';
import DisplayError from '../../../../components/info/DisplayError';
import Loading from '../../../../components/info/Loading';
import { useParameters } from '../../../../hooks/useParameters';
import { formatDefaultValuesStudentFileNoSua } from '../../../../utils/tuitionProcess';
import studenFileFormatPreviusData from '../../../AdmissionAndEnrollments/OnSiteEnrollment/TuitionProcessNoSua/steps/studentFile/utils/studentFileFormatPreviusData';
import { getPostulant } from '../api';
import { StudentFileShowResponse } from '../types';

export interface StudentFileFilterLoaderProps {
  onError?: (code: string, onUnknown?: (() => void) | undefined) => void;
  children: (props: {
    refresh: () => Promise<void>;
    postulant: any;
    defaultValues: any;
    photo: string | null;
  }) => ReactElement;
}

const { FetchProvider, FetchConsumer } = createFetchContext<
  undefined,
  StudentFileShowResponse,
  AuthenticationError | AxiosResultDefaultError
>();

export const SustainerLoaderConsumer = FetchConsumer;

export default function PostulantLoader({
  onError,
  children,
}: StudentFileFilterLoaderProps) {
  const { postulantId } = useParams<{ postulantId: string }>();
  const {
    countryOptions,
    communeOptions,
    regionOptions,
    institutionTypeOptions,
  } = useParameters();

  const request = useCallback(async () => {
    return getPostulant(postulantId);
  }, [postulantId]);

  const isNotDynamicData = (data: StudentFileShowResponse) =>
    Object.keys(data.responses).length === 0;

  return (
    <FetchProvider request={request} defaultQuery={undefined} fetchImmediately>
      <FetchConsumer>
        {({ data, loading, error, refresh }) => {
          if (error) {
            onError && onError(error.code);
            return (
              <DisplayError
                insideCard
                textBody={error.code}
                retryAction={refresh}
                loadingAction={loading}
              />
            );
          }

          if (loading) {
            return <Loading insideCard />;
          }

          if (!data) {
            return (
              <DisplayError
                insideCard
                textBody="Data no cargada"
                retryAction={refresh}
                loadingAction={loading}
              />
            );
          }

          return children({
            defaultValues: isNotDynamicData(data)
              ? formatDefaultValuesStudentFileNoSua(
                  data,
                  communeOptions,
                  regionOptions,
                  countryOptions,
                  institutionTypeOptions,
                )
              : studenFileFormatPreviusData(data, countryOptions),
            photo: data.student?.photo,
            postulant: {
              id: postulantId,
            },
            refresh,
            ...data,
          });
        }}
      </FetchConsumer>
    </FetchProvider>
  );
}
