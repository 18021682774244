import { CheckInput, TextInput } from '@octano/global-ui';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'reactstrap';
import { clean } from 'rut.js';

import { SectionTitle } from '../../../../components/text';
import { useValidations } from '../../../../hooks/useValidations';
import { useTuitionProcess } from '../../TuitionProcessContext';
import { FieldsCosignerForm } from './CosignerForm';

type PersonalInformationProps = {
  studentRut: string;
  disabledCheckSustainer: boolean;
};

/**
 * Sección datos personales del formulario perteneciente
 * al paso "Datos Aval" del proceso de matrícula
 */
const PersonalInformation = ({
  studentRut,
  disabledCheckSustainer,
}: PersonalInformationProps) => {
  const { control, watch } = useFormContext<FieldsCosignerForm>();
  const { prefix: translationPrefix } = useTuitionProcess();
  const prefix = `${translationPrefix}.cosigner.personalInformation`;
  const { t } = useTranslation();
  const { validateTextNotEmpty, validateRut } = useValidations();
  const checkSustainer = watch('checkSustainer');

  return (
    <>
      <Row>
        <Col xs={12}>
          <SectionTitle text={t(`${prefix}.title`)} />
        </Col>
        <Col xs={12} className="pb-4">
          <span className="fs-18 text-light fw-300">
            {t(`${prefix}.subtitle`)}
          </span>
        </Col>
      </Row>
      <Row>
        {/* Esta opción solo se despliega cuando el sostendor es distinto al alumno */}
        {!disabledCheckSustainer && (
          <Col className="pb-3" xs={12}>
            <CheckInput
              name="checkSustainer"
              label={t(`${prefix}.checkSustainer`)}
              control={control}
              shouldUnregister
            />
          </Col>
        )}
        <Col className="pb-3" xs={12} lg={6}>
          <TextInput<FieldsCosignerForm>
            name="names"
            label={t(`${prefix}.names`)}
            control={control}
            rules={{ validate: validateTextNotEmpty }}
            disabled={checkSustainer}
          />
        </Col>
        <Col className="pb-3" xs={12} lg={6}>
          <TextInput<FieldsCosignerForm>
            name="paternalLastName"
            label={t(`${prefix}.paternalLastName`)}
            control={control}
            rules={{ validate: validateTextNotEmpty }}
            disabled={checkSustainer}
          />
        </Col>
        <Col className="pb-3" xs={12} lg={6}>
          <TextInput
            name="maternalLastName"
            label={t(`${prefix}.maternalLastName`)}
            control={control}
            rules={{ validate: validateTextNotEmpty }}
            disabled={checkSustainer}
          />
        </Col>
        <Col className="pb-3" xs={12} lg={6}>
          <TextInput
            name="rut"
            label={t(`${prefix}.rut`)}
            control={control}
            formatter="rut"
            rules={{
              validate: {
                empty: validateTextNotEmpty,
                rut: validateRut,
                studentAsCosigner: (value) => {
                  if (
                    typeof value === 'string' &&
                    clean(value) === studentRut
                  ) {
                    return 'No puedes ser tu propio aval';
                  } else {
                    return undefined;
                  }
                },
              },
            }}
            disabled={checkSustainer}
          />
        </Col>
      </Row>
    </>
  );
};

export default PersonalInformation;
