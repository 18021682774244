import {
  Button,
  ColumnTable,
  DisplayInfo,
  Icon,
  Table,
} from '@octano/global-ui';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { useHistory } from 'react-router-dom';

import { useMemo, useCallback } from 'react';
import { Curriculum } from '../interfaces/curriculum.interface';
import { PathsLayouts } from '../../../../config/routes';
import Tooltip from '../../../../components/tooltip/Tooltip';

const dictPrefix = 'interimDegree.curriculums';

interface Props {
  data: Curriculum[];
  isLoadingResults: boolean;
  itemPerPage: number;
  totalItems: number;
  totalPages: number;
  currentPage: number;
  hasFilters?: boolean;
  manageEnabled?: boolean;
  onChangePage: (page: number) => void;
}

export function InterimDegreeCurriculumsTable({
  data,
  isLoadingResults,
  itemPerPage,
  totalPages,
  totalItems,
  currentPage,
  onChangePage,
  manageEnabled = false,
  hasFilters,
}: Props) {
  const { t } = useTranslation();

  const history = useHistory();
  const goToInterimDegrees = useCallback(
    (curriculumId: number) => {
      history.push(
        `${PathsLayouts.academicOffers}/interim-degree/curriculums/${curriculumId}`,
      );
    },
    [history],
  );

  const validInterimDegrees = useCallback((item: Curriculum) => {
    const valids =
      item?.interimDegrees?.filter((e) => e?.status !== 'draft') ?? [];
    let names = valids?.map((e) => e?.name) ?? [];
    if (names.length > 3) {
      names.slice(0, 3);
      names.push('...');
    }
    return names;
  }, []);

  const columns = useMemo(() => {
    const cols: ColumnTable<Curriculum>[] = [
      {
        headerText: t(`${dictPrefix}.table.school`),
        columnName: 'studyPlanVersion',
        tdClassName: 'text-left',
        thClassName: 'text-left',
        cellFormat: ({ value }) => `${value?.studyPlan?.school?.name}`,
      },
      {
        headerText: t(`${dictPrefix}.table.studyPlan`),
        columnName: 'studyPlanVersion',
        tdClassName: 'text-center',
        thClassName: 'text-center',
        cellFormat: ({ value }) =>
          `${value?.studyPlan?.code} - ${value?.studyPlan?.name}`,
      },
      {
        headerText: t(`${dictPrefix}.table.version`),
        columnName: 'studyPlanVersion',
        tdClassName: 'text-center',
        thClassName: 'text-center',
        cellFormat: ({ value }) => `${value?.code} - ${value?.name}`,
      },
      {
        headerText: t(`${dictPrefix}.table.mention`),
        columnName: 'isPrimary',
        tdClassName: 'text-center',
        thClassName: 'text-center',
        cellFormat: ({ value, row }) =>
          !value ? `${row?.code} - ${row?.name}` : '-',
      },
      {
        headerText: t(`${dictPrefix}.table.date`),
        columnName: 'studyPlanVersion',
        tdClassName: 'text-center',
        thClassName: 'text-center',
        cellFormat: ({ value }) => dayjs(value?.createdAt).format('DD/MM/YYYY'),
      },
      {
        headerText: t(`${dictPrefix}.table.interimDegree`),
        columnName: 'createdAt',
        tdClassName: 'text-left',
        thClassName: 'text-center',
        width: 150,
        cellFormat: ({ row }) =>
          validInterimDegrees(row)?.length ? (
            <>
              <Tooltip
                id={`hint_interim_degress_${row.id}`}
                placement="bottom"
                text={validInterimDegrees(row).join(', ')}
              >
                <div>
                  <Icon
                    name="eye"
                    color={
                      validInterimDegrees(row)?.length ? 'primary' : 'disabled'
                    }
                  />
                  <span className="ml-2">
                    {validInterimDegrees(row)?.length
                      ? t(`${dictPrefix}.table.assigned`)
                      : t(`${dictPrefix}.table.notAssigned`)}
                  </span>
                </div>
              </Tooltip>
            </>
          ) : (
            <div>
              <Icon
                name="eye"
                color={
                  validInterimDegrees(row)?.length ? 'primary' : 'disabled'
                }
              />
              <span className="ml-2">
                {validInterimDegrees(row)?.length
                  ? t(`${dictPrefix}.table.assigned`)
                  : t(`${dictPrefix}.table.notAssigned`)}
              </span>
            </div>
          ),
      },
      {
        headerText: t(`${dictPrefix}.table.action`),
        columnName: 'id',
        tdClassName: 'text-center',
        thClassName: 'text-center',
        cellFormat: ({ value }) => (
          <>
            <Button
              text={t(`${dictPrefix}.actions.manageInterimDegree`)}
              disabled={!manageEnabled}
              size="sm"
              onClick={() => !!manageEnabled && goToInterimDegrees(value)}
            />
          </>
        ),
      },
    ];
    return cols;
  }, [t, manageEnabled, goToInterimDegrees, validInterimDegrees]);

  return (
    <Table
      isLoadingResults={isLoadingResults}
      data={data}
      columns={columns}
      pagination={{
        currentPage: currentPage + 1,
        itemsPerPage: itemPerPage,
        totalItems: totalItems,
        totalPages: totalPages,
        onChangePage: (page) => onChangePage(page - 1),
      }}
      noResultsText={
        <div className="d-flex justify-content-center align-items-center py-5">
          <DisplayInfo
            iconName="information"
            textBody={t(
              `${dictPrefix}.${
                hasFilters ? 'noResultsFilter' : 'noResults'
              }.description`,
            )}
            maxWidth="650px"
          />
        </div>
      }
    />
  );
}
