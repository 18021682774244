import { Button, ColumnTable } from '@octano/global-ui';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { formatDate } from '../../../utils/dates';
import { DegreeProcessRenditionRow } from '../types';
import BadgeState from './BadgeState';

interface Props {
  toggleModalRendition: (activityId: number | string) => any;
}

export function useColumnsRenditions({ toggleModalRendition }: Props) {
  const prefix = 'studentsDegreeStudent.tableRenditions';
  const { t } = useTranslation();
  const texts = useMemo(
    () => ({
      headers: t<string, Record<string, string>>(`${prefix}.headers`, {
        returnObjects: true,
      }),
      badgeState: t<string, Record<'approved' | 'failed', string>>(
        `${prefix}.badgeState`,
        {
          returnObjects: true,
        },
      ),
    }),
    [t],
  );

  const columns: ColumnTable<DegreeProcessRenditionRow>[] = useMemo(() => {
    const columnWidth = `${100 / 6}%`;
    return [
      {
        columnName: 'date',
        headerText: 'Fecha de Rendición',
        thClassName: 'text-center',
        tdClassName: 'text-center',
        width: columnWidth,
        cellFormat: ({ row }) =>
          row?.buildEmptyRow ? <></> : formatDate(row.date, 'DD/MM/YYYY'),
      },
      {
        columnName: 'state',
        headerText: 'Estado',
        thClassName: 'text-center',
        tdClassName: 'text-center',
        width: columnWidth,
        cellFormat: ({ row }) => {
          if (row?.buildEmptyRow) return <></>;
          if (row.approved) {
            return (
              <BadgeState status="approved" text={texts.badgeState.approved} />
            );
          }
          return <BadgeState status="failed" text={texts.badgeState.failed} />;
        },
      },
      {
        columnName: 'grade',
        headerText: 'Nota',
        thClassName: 'text-center',
        tdClassName: 'text-center',
        width: columnWidth,
        cellFormat: ({ row }) => (row?.buildEmptyRow ? <></> : row.grade),
      },
      {
        columnName: 'gradeMax',
        headerText: 'Nota Máxima',
        thClassName: 'text-center',
        tdClassName: 'text-center',
        width: columnWidth,
        cellFormat: ({ row }) => (row?.buildEmptyRow ? <></> : row.gradeMax),
      },
      {
        columnName: 'comment',
        headerText: 'Comentario',
        thClassName: 'text-center',
        tdClassName: 'text-center',
        width: columnWidth,
        cellFormat: ({ row }) => (row?.buildEmptyRow ? <></> : row.comment),
      },
      {
        columnName: 'creator',
        headerText: 'Editado por',
        thClassName: 'text-center',
        tdClassName: 'text-center',
        width: columnWidth,
        cellFormat: ({ row }) =>
          row?.buildEmptyRow ? <></> : row.creator.fullName,
      },
      {
        columnName: 'buildEmptyRow',
        headerText: '',
        tdClassName: 'text-center td-build-empty-row',
        thClassName: 'text-center',
        width: columnWidth,
        cellFormat: ({ row }) => {
          if (row?.buildEmptyRow) {
            return (
              <>
                {row.activityId !== 0 ? (
                  <Button
                    className="td-btn-action"
                    text={t(`${prefix}.btn.addRendition`)}
                    onClick={() => {
                      toggleModalRendition(row.activityId);
                    }}
                  />
                ) : (
                  <Button
                    className="td-btn-action"
                    text={t(`${prefix}.btn.addRendition`)}
                    disabled={true}
                  />
                )}
              </>
            );
          }

          return <></>;
        },
      },
    ];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [texts]);

  return columns;
}
