export interface AcademicLevels {
  id: number;
  name: string;
  structureTypeId: number;
  structureType: StructureType;
}

export interface StructureType {
  id: number;
  name: string;
  code: string;
  description: string;
  color: string;
}

// Table
export interface AcademicLevelsResponse {
  data: AcademicLevels[];
}

// Form selects
export interface AcademicLevelsFormDataResponse {
  academicLevels: AcademicLevels[];
  structureType: StructureType[];
}

// Form Data
export interface FormDataAction {
  structureTypeId: number | undefined;
}

// Details
export interface AcademicLevelInfoResponse {
  id: number;
  name: string;
  structureTypeId: number;
  structureType: StructureType;
}

export enum StructureTypeCode {
  FREE = 1,
  FIXED,
  SEMIFIXED,
}
