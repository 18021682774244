import { MODIFY_ENROLLMENT } from '../../../../api/endpoints';
import request from '../../../../api/request';
import {
  CampusOrScheduleFilterResponse,
  CampusOrScheduleResponse,
} from '../types/CampusOrSchedule';
import { ContinuityResponse } from '../types/ContinuityRow';
import { SearchParams } from '../types/Search';

/**
 * Obtiene el listado de los estudiantes regulares
 * @param items
 * @param page
 * @returns
 */
export function getRegularStudentsList(
  filter: { items: number; page: number } & SearchParams,
) {
  const URL = MODIFY_ENROLLMENT.GET_REGULAR_STUDENTS_LIST;
  const method = 'GET';
  const params = { ...filter, items_per_page: filter.items };
  return request<CampusOrScheduleResponse>(URL, { method, params });
}

/**
 * Obtiene el listado de los estudiantes regulares para matricula de continuidad
 * @param items
 * @param page
 * @returns
 */
export function getRegularStudentsListContinuity(
  filter: { items: number; page: number } & SearchParams,
) {
  const URL = MODIFY_ENROLLMENT.GET_REGULAR_STUDENTS_LIST_CONTINUITY;
  const method = 'GET';
  const params = { ...filter, items_per_page: filter.items };
  return request<ContinuityResponse>(URL, { method, params });
}

/**
 * Obtiene el listado de los filtros a mostrar para el listado de estudiantes regulares
 * @returns
 */
export function getRegularStudentsListFilter() {
  const URL = MODIFY_ENROLLMENT.GET_FILTERS;
  const method = 'GET';
  return request<CampusOrScheduleFilterResponse>(URL, { method });
}

/**
 * Verifica que el plan de estudio de continuidad se pueda modificar
 * @returns
 */
export function getVerifySoftchangeToContinuity(
  accountId: string,
  studyPlanEnrollmentId: string,
) {
  const URL = MODIFY_ENROLLMENT.VERIFY_SOFTCHANGE_TO_CONTINUITY(
    accountId,
    studyPlanEnrollmentId,
  );
  const method = 'GET';
  return request<any>(URL, { method });
}
