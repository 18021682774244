export enum SectionsScheduleDays {
  Monday = 'Lu',
  Tuesday = 'Ma',
  Wednesday = 'Mi',
  Thursday = 'Ju',
  Friday = 'Vi',
  Saturday = 'Sa',
  Sunday = 'Do',
}

export type Module = {
  id: number;
  name: string;
  shortening: string;
  startTime: string;
  endTime: string;
};
