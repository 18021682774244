import { addToast } from '@octano/global-ui';
import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { uploadPostulationProcessDocument } from '../../../../../../api/requests/tuitionProcess';
import ConfirmationModal from '../../../../../../components/modals/ConfirmationModal';
import UploadModalCore, {
  IInput,
} from '../../../../../../components/modals/UploadModal';
import { getInputs } from './utils';

export interface UploadModalProps {
  postulationDetailId: number | string;
  open: boolean;
  closeModal: () => void;
  code: string;
  name: string;
  description: string | null;
  uploadMethod: 'signature' | 'identification' | 'default' | 'confirmation';
  commentRequired: boolean;
  onUploaded: () => void;
}

export default function UploadModal({
  postulationDetailId,
  open,
  closeModal,
  code,
  name,
  description,
  uploadMethod,
  commentRequired,
  onUploaded,
}: UploadModalProps) {
  const prefix = 'postulationDocs';
  const { t } = useTranslation();
  const requiredMsgError = 'Requerido';
  const inputValues = getInputs(uploadMethod, commentRequired);
  const inputs = useMemo(
    () =>
      inputValues.map(({ name, labelPath, type }, index) => {
        if (type === 'file') {
          return (
            <UploadModalCore.Input
              key={index}
              name={name}
              label={t(labelPath)}
              requiredMsgError={requiredMsgError}
            />
          );
        }
        return (
          <UploadModalCore.TextArea
            key={index}
            name={name}
            label={t(labelPath)}
            requiredMsgError={requiredMsgError}
          />
        );
      }),
    [inputValues, t],
  );

  const [confirming, setConfirming] = useState<boolean>(false);
  const submitFunction = useCallback(
    async (values?: IInput) => {
      setConfirming(true);
      const { error } = await uploadPostulationProcessDocument(
        postulationDetailId,
        uploadMethod,
        code,
        values,
      );
      if (!error) {
        onUploaded();
        addToast({
          icon: 'success',
          color: 'success',
          text: t(`${prefix}.uploadSuccess`),
        });
      } else {
        addToast({
          icon: 'error',
          color: 'danger',
          text: t(`${prefix}.uploadError`),
        });
      }
      setConfirming(false);
    },
    [postulationDetailId, uploadMethod, onUploaded, code, t],
  );

  if (uploadMethod === 'confirmation') {
    return (
      <ConfirmationModal
        isOpen={open}
        toggle={closeModal}
        title={t(`${prefix}.confirmationModal.title`)}
        body={t(`${prefix}.confirmationModal.body`)}
        loading={confirming}
        primaryBtn={{
          text: t(`${prefix}.confirmationModal.primaryBtn`),
          action: () => submitFunction(),
        }}
        secondaryBtn={{
          text: t(`${prefix}.confirmationModal.secondaryBtn`),
          action: closeModal,
        }}
      />
    );
  }

  return (
    <UploadModalCore
      prefix={`${prefix}.${code}`}
      title={name}
      subtitle={description ?? ''}
      isOpen={open}
      onClose={closeModal}
      submitFunction={submitFunction}
      onSuccess={() => null}
      onError={() => null}
    >
      {inputs}
    </UploadModalCore>
  );
}
