import { DetailBox, DetailBoxColumn } from '@octano/global-ui';
import dayjs from 'dayjs';
import { useMemo } from 'react';
import { Card } from 'reactstrap';

interface PetitionsStoryBoxProps {
  petitionPlan: string;
  petitiontype: string;
  petitionDate: string;
  texts: {
    petitionPlan: string;
    petitiontype: string;
    petitionDate: string;
  };
}

export default function PetitionsStoryBox(props: PetitionsStoryBoxProps) {
  const texts = props.texts;
  const columns = useMemo<DetailBoxColumn[]>(() => {
    return [
      {
        title: texts.petitionPlan,
        body: props.petitionPlan,
      },
      {
        title: texts.petitiontype,
        body: props.petitiontype,
      },
      {
        title: texts.petitionDate,
        body: dayjs(props.petitionDate).format('DD/MM/YYYY'),
      },
    ];
  }, [props, texts]);

  return (
    <Card className="d-flex">
      <DetailBox columns={columns} />
    </Card>
  );
}
