import request from '../api/request';
import { Discount, PostulationDetailDiscount } from './types';

const ADMISSION = {
  DISCOUNTS: '/maintainers-portal/admission-management/discounts',
  GET_DISCOUNT: (postulationDetailId: number | string) =>
    `/maintainers-portal/admission-management/discounts/postulation-details/${postulationDetailId}`,
  SET_DISCOUNT: (postulationDetailId: number) =>
    `/maintainers-portal/admission-management/discounts/postulation-details/${postulationDetailId}`,
};

/**
 *Obtiene el listado de todos los descuentos
 */
export const getAllDiscounts = () => {
  return request<Discount[]>(ADMISSION.DISCOUNTS);
};

export const getDiscountFromPostulationDetail = (
  postulationDetailId: number | string,
) => {
  return request<PostulationDetailDiscount[]>(
    ADMISSION.GET_DISCOUNT(postulationDetailId),
  );
};

/**
 * guarda descuento a postulante
 */
export const saveDiscountPostulant = (
  postulationDetailId: number,
  data: {
    discountCode: string;
    discountName: string;
    discountValue: number;
    type: string;
  },
) => {
  return request<Discount>(ADMISSION.SET_DISCOUNT(postulationDetailId), {
    method: 'POST',
    data,
  });
};
