import { CheckInput } from '@octano/global-ui';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { Card, Col, Container, Row } from 'reactstrap';

import GoBackButton from '../../../../../components/buttons/GoBackButton';
import { SectionTitle } from '../../../../../components/text';
import { PathsLayouts } from '../../../../../config/routes';
import { PeriodType } from '../../../../../types/PeriodType';
import CurriculumMentionList from '../CurriculumMentionList';
import CurriculumStudyPlanInfo from '../CurriculumStudyPlanInfo';

type PropsParams = {
  id: string;
  versionId: string;
};

const CurriculumListMention = () => {
  const prefix = 'studyPlans.curriculum';
  const hasMentions: boolean = true;

  const history = useHistory();
  const { t } = useTranslation();
  const { id: studyPlanId, versionId } = useParams<PropsParams>();

  const [, setPeriodType] = useState<PeriodType>();

  const goBack = useCallback(() => {
    history.push(
      `${PathsLayouts.academicOffers}/study-plans/record/${studyPlanId}`,
    );
  }, [history, studyPlanId]);

  return (
    <Container>
      <Card className="p-3 p-md-4">
        <Row>
          <Col xs={12} md={7}>
            <div className="mb-4">
              <GoBackButton onClick={goBack} />
            </div>
          </Col>
          <Col xs={12} md={5}>
            <CurriculumStudyPlanInfo
              id={studyPlanId}
              versionId={versionId}
              setPeriodType={setPeriodType}
            />
          </Col>
        </Row>

        <Row>
          <Col xs={12} md={12}>
            <SectionTitle text={t(`${prefix}.mentions`)} />
            <Row className="p-3">
              <span className="fs-20">{t(`${prefix}.hasMentions`)}</span>
            </Row>
            <Row className="p-3">
              <CheckInput
                label={t(`${prefix}.hasMentionsYes`)}
                name="hasMentions"
                value={hasMentions}
                disabled
              />
            </Row>
          </Col>
        </Row>

        <Row>
          <Col xs={12} md={12}>
            <CurriculumMentionList />
          </Col>
        </Row>
      </Card>
    </Container>
  );
};

export default CurriculumListMention;
