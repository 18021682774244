import { Button, Table, addToast } from '@octano/global-ui';
import dayjs from 'dayjs';
import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'reactstrap';

import { SectionTitle } from '../../../components/text';
import TableEmptyContent from '../../../components/text/TableEmptyContent';
import { SectionSchedule } from '../../../types/sectionMaintainerTypes';
import { formatDate } from '../../../utils/dates';
import { putSchedules } from '../requests';
import { SectionScheduleBody, SectionScheduleGetResponse } from '../types';
import ModalMaintainerSchedule, {
  ScheduleSectionForm,
} from './ModalMaintainerSchedule';
import { ScheduleList } from './SectionForm';
import { useColumnsSectionSchedule } from './useColumnsSectionSchedule';

type SectionScheduleFormProps = {
  data: SectionScheduleGetResponse;
  onSave: () => void;
};

const SectionScheduleForm = ({ data, onSave }: SectionScheduleFormProps) => {
  const { t } = useTranslation();
  const prefix = 'sectionsMaintainer.update';
  const [loading, setLoading] = useState(false);

  const setDefaultEndModule = (schedule: SectionSchedule) => {
    return {
      value: schedule.endModule?.id ?? schedule.startModule.id,
      label: schedule.endModule?.shortening ?? schedule.startModule.shortening,
    };
  };

  const setDefaultDate = (date?: string | null) => {
    return date ? dayjs(date).toString() : undefined;
  };

  const [schedulesList, setSchedulesList] = useState<ScheduleList[]>(
    data.section.sectionSchedules.map((schedule: SectionSchedule) => {
      return {
        id: schedule.id,
        isActive: !schedule.deletedAt,
        activity: {
          value: schedule.activity.id,
          label: schedule.activity.name,
        },
        campus: schedule.classroom?.campus
          ? {
              value: schedule.classroom.campus?.id,
              label: schedule.classroom.campus?.name,
            }
          : null,
        classroom: schedule.classroom
          ? { value: schedule.classroom.id, label: schedule.classroom.name }
          : null,
        day: {
          value: schedule.day,
          label: t(`common.days.${schedule.day}`),
        },
        startModule: {
          value: schedule.startModule.id,
          label: schedule.startModule.shortening,
        },
        endModule: setDefaultEndModule(schedule),
        startDate: setDefaultDate(schedule.startDate),
        endDate: setDefaultDate(schedule.endDate),
        responsible: schedule.responsibles?.[0]
          ? {
              value: schedule.responsibles[0].id,
              label: schedule.responsibles[0].account?.fullName ?? '',
              account: schedule.responsibles[0].account,
            }
          : undefined,
      };
    }),
  );

  const onDelete = useCallback(
    (index: number) => {
      schedulesList[index].isActive = false;
      const newSchedules = [...schedulesList];
      setSchedulesList(newSchedules);
    },
    [schedulesList],
  );

  const section = data.section;
  const onSubmit = useCallback(async () => {
    setLoading(true);
    const sectionSchedules: SectionScheduleBody[] = schedulesList
      .filter((schedule) => schedule.isActive)
      .map((schedule) => {
        let responsibleAccount;
        if (schedule.responsible?.account?.id) {
          responsibleAccount = {
            accountId: schedule.responsible?.account?.id,
          };
        } else if (schedule.responsible?.value) {
          responsibleAccount = {
            accountId: schedule.responsible?.value,
          };
        }

        return {
          id: schedule.id ?? null,
          day: schedule.day.value.toString(),
          startModuleId: +schedule.startModule.value,
          endModuleId: +schedule.endModule.value,
          classroomId: schedule.classroom?.value
            ? +schedule.classroom.value
            : undefined,
          activityId: +schedule.activity.value,
          responsibleAccount,
          startDate: schedule.startDate
            ? formatDate(schedule.startDate, 'YYYY-MM-DD')
            : '',
          endDate: schedule.endDate
            ? formatDate(schedule.endDate, 'YYYY-MM-DD')
            : '',
        };
      });
    const { error } = await putSchedules(section.id, {
      sectionId: section.id,
      sectionSchedules,
    });
    if (error) {
      addToast({
        icon: 'error',
        color: 'danger',
        text: t(`common.errors.save`),
      });
    } else {
      onSave();
      addToast({
        icon: 'success',
        color: 'success',
        text: t('common.messages.editSuccess'),
      });
    }
    setLoading(false);
  }, [section, schedulesList, onSave, t]);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [scheduleIndex, setScheduleIndex] = useState<number>();

  const openCreateOrUpdate = (index?: number) => {
    setIsModalOpen(true);
    setScheduleIndex(index);
  };

  const columns = useColumnsSectionSchedule({ openCreateOrUpdate, onDelete });

  const onCreateOrUpdateSchedule = (
    values: ScheduleSectionForm,
    index?: number,
  ) => {
    const newSchedules = [...schedulesList];

    if (index !== undefined) {
      let updatedSchedule = { ...newSchedules[index] };
      updatedSchedule = {
        id: updatedSchedule.id,
        isActive: true,
        ...values,
        campus: values.classroom?.value ? values.campus : null,
      };
      newSchedules[index] = updatedSchedule;
    } else {
      newSchedules.push({
        id: null,
        isActive: true,
        ...values,
      });
    }
    setSchedulesList(newSchedules);
    setIsModalOpen(false);
  };

  const scheduleToUpdate = useMemo(() => {
    return scheduleIndex !== undefined
      ? schedulesList?.[scheduleIndex]
      : undefined;
  }, [schedulesList, scheduleIndex]);

  return (
    <>
      <Row>
        <Col xs={12}>
          <SectionTitle text={t(`${prefix}.classesSection`)} />
        </Col>
        <Col xs={12}>
          <p className="fs-16">{t(`${prefix}.classesSectionDescription`)}</p>
        </Col>
        <Col xs={{ size: 6, offset: 6 }} className="text-right pb-3">
          <Button
            text={t(`${prefix}.addClass`)}
            size="md"
            icon="plus"
            onClick={() => {
              openCreateOrUpdate();
            }}
          />
        </Col>
        <Col xs={12}>
          <Table
            columns={columns}
            data={schedulesList}
            noResultsText={
              <TableEmptyContent
                title={t(`${prefix}.noResultsClassesTitle`)}
                subtitle={t(`${prefix}.noResultsClassesBody`)}
              />
            }
          />
        </Col>
      </Row>
      <Row className="py-5 justify-content-end pt-5">
        <Col xs={12} lg={3} className="pb-2 order-2 order-lg-1"></Col>
        <Col xs={12} lg={3} className="pb-2 order-1 order-lg-2">
          <Button
            onClick={onSubmit}
            text={t(`common.actions.save`)}
            fullwidth
            loading={loading}
          />
        </Col>
      </Row>
      <ModalMaintainerSchedule
        sectionId={section.id}
        isModalOpen={isModalOpen}
        closeModal={() => setIsModalOpen(false)}
        onSave={onCreateOrUpdateSchedule}
        scheduleIndex={scheduleIndex}
        schedule={scheduleToUpdate}
        activities={data.activities}
        campuses={data.campuses}
        modules={data.timeModules}
        responsibles={data.teachers}
        period={section.period}
      />
    </>
  );
};

export default SectionScheduleForm;
