import { Modal, addToast } from '@octano/global-ui';
import { useTranslation } from 'react-i18next';

import { AxiosResult, AxiosResultDefaultError } from '../../../api/request';
import { SchoolsListItem } from '../../../api/requests/schools';
import { useLayoutState } from '../../../hooks/useLayoutState';
import SchoolForm, { School } from './SchoolForm';

interface SchoolModalProps {
  reloadSchoolsList: () => void;
  recordToEdit: School | null;
  show: boolean;
  toggleModal: () => void;
}

const getTranslationsTexts = (isEditionMode: boolean) => {
  const translationPreffix = 'maintainers.schoolsForm';
  return isEditionMode
    ? {
        create: 'common.actions.saveChanges',
        success: 'common.messages.editSuccess',
        formTitle: 'maintainers.formEditTitle',
      }
    : {
        create: `${translationPreffix}.create`,
        success: `${translationPreffix}.createSuccess`,
        formTitle: `${translationPreffix}.addTitle`,
      };
};

const SchoolModal = ({
  recordToEdit,
  reloadSchoolsList,
  show,
  toggleModal,
}: SchoolModalProps) => {
  const { t } = useTranslation();
  const { showErrorModal } = useLayoutState();

  const isEditionMode = !!recordToEdit;
  const texts = getTranslationsTexts(isEditionMode);

  const onSubmit = async (
    result: AxiosResult<SchoolsListItem, AxiosResultDefaultError>,
  ) => {
    const { error, data } = result;
    if (error && error.code === 'CONNECTION') {
      showErrorModal();
    } else if (error) {
      addToast({
        icon: 'error',
        color: 'danger',
        text: t('common.errors.save'),
      });
    } else if (data) {
      reloadSchoolsList();
      addToast({
        icon: 'success',
        color: 'success',
        text: t(texts.success),
      });
    }
  };

  return (
    <Modal isOpen={show} toggle={toggleModal} size="lg" unmountOnClose>
      <SchoolForm
        recordToEdit={recordToEdit}
        onSubmit={onSubmit}
        onCancel={toggleModal}
      />
    </Modal>
  );
};
export default SchoolModal;
