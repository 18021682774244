import BaseLoader from './parts/BaseLoader';
import TariffForm from './parts/TariffForm';
import TariffLoader from './parts/TariffLoader';

export default function Tariff() {
  return (
    <BaseLoader>
      {({ selectOptions, settings }) => (
        <TariffLoader options={selectOptions}>
          {({ tariffData, defaultValues, refresh }) => (
            <TariffForm
              {...{
                selectOptions,
                settings,
                defaultValues,
                tariffData,
                refresh,
              }}
            />
          )}
        </TariffLoader>
      )}
    </BaseLoader>
  );
}
