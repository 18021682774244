import { ACADEMIC_RECORD } from '../../api/endpoints';
import request from '../../api/request';

export function getBase() {
  const url = ACADEMIC_RECORD.BASE;
  return request<any>(url, { responseType: 'blob' });
}

export function getTemplate() {
  const url = ACADEMIC_RECORD.TEMPLATE;
  return request<any>(url, { responseType: 'blob' });
}

export function upload(file: File) {
  const url = ACADEMIC_RECORD.UPLOAD;
  const data = new FormData();
  data.append('file', file);
  return request<any>(url, { responseType: 'blob', data, method: 'POST' });
}
