import { CheckInput } from '@octano/global-ui';

export type IdentityCardProps = {
  type: string;
  name: string;
  btnUpload: string;
  btnUploaded: string;
  isUploaded: boolean;
  onChange: () => void;
};

/**
 * Contenedor de información de documento que debe ser firmado
 * en el paso "Firmar Documentación"
 */
const IdentityCard = ({
  type,
  name,
  isUploaded,
  onChange,
}: IdentityCardProps) => {
  return (
    <CheckInput
      name={type}
      label={name}
      value={isUploaded}
      onChange={onChange}
    />
  );
};

export default IdentityCard;
