import { ReactElement, useCallback } from 'react';

import { getOfferReportRequest } from '../../../../api/requests/academicOffers';
import { createFetchContext } from '../../../../components/contexts/FetchContextFactory';
import DisplayError from '../../../../components/info/DisplayError';
import Loading from '../../../../components/info/Loading';
import {
  AcademicOfferViewTableInfoQuery,
  AcademicOfferViewTableInfoResponse,
} from '../types';

const ITEMS_PER_PAGE_DEFAULT = 10;

export interface AcademicOfferViewTableInfoLoaderProps {
  academicOfferId: number;
  children: ReactElement;
}

const { FetchProvider, FetchConsumer, useFetch } = createFetchContext<
  AcademicOfferViewTableInfoQuery,
  AcademicOfferViewTableInfoResponse
>();

export function useAcademicOfferViewTableInfoLoader() {
  const context = useFetch();
  if (!context.data) {
    throw new Error('data no cargada en useAcademicOfferViewTableInfoLoader');
  }
  return {
    data: context.data,
    query: context.query,
    updateQuery: context.updateQuery,
  };
}
export const AcademicOfferViewTableInfoLoaderConsumer = FetchConsumer;

export default function AcademicOfferViewTableInfoLoader({
  academicOfferId,
  children,
}: AcademicOfferViewTableInfoLoaderProps) {
  const request = useCallback(
    ({ page }: AcademicOfferViewTableInfoQuery) => {
      return getOfferReportRequest(
        academicOfferId,
        ITEMS_PER_PAGE_DEFAULT,
        page - 1,
      );
    },
    [academicOfferId],
  );

  return (
    <FetchProvider
      request={request}
      defaultQuery={{ page: 1 }}
      fetchImmediately
    >
      <FetchConsumer>
        {({ data, loading, error, refresh }) => {
          if (error) {
            return (
              <DisplayError
                insideCard
                textBody={error.code}
                retryAction={refresh}
                loadingAction={loading}
              />
            );
          }

          if (loading) {
            return <Loading insideCard />;
          }

          if (!data) {
            return (
              <DisplayError
                insideCard
                textBody="Data no cargada"
                retryAction={refresh}
                loadingAction={loading}
              />
            );
          }

          return children;
        }}
      </FetchConsumer>
    </FetchProvider>
  );
}
