import { Button, Icon, Modal, useMobile } from '@octano/global-ui';
import { useTranslation } from 'react-i18next';

const dictPrefix = 'graduationProcessSingle.failedProcessingModal';

interface Props {
  isOpen: boolean;
  onGoBack?: () => void;
  onGoToSummary?: () => void;
}

export function FailedProcessingGraduateModal({
  isOpen,
  onGoBack = () => null,
  onGoToSummary = () => null,
}: Props) {
  const { t } = useTranslation();
  const isMobile = useMobile();

  return (
    <Modal isOpen={isOpen} toggle={() => null}>
      <div className="d-flex flex-column align-items-center text-center">
        <Icon name="warning" size={52} />
        <h1 className="text-dark fs-22 mt-4">{t(`${dictPrefix}.title`)}</h1>
        <p className="text-light fs-18 mt-3 mb-5">
          {t(`${dictPrefix}.description`)}
        </p>

        <div className="d-flex flex-wrap w-100" style={{ gap: 16 }}>
          <Button
            text={t(`common.actions.back`)}
            outlined
            style={{ width: isMobile ? '100%' : 200 }}
            onClick={() => onGoBack()}
          />
          <Button
            text={t(`${dictPrefix}.checkSummary`)}
            style={{ width: isMobile ? '100%' : 200 }}
            onClick={() => onGoToSummary()}
          />
        </div>
      </div>
    </Modal>
  );
}
