import { addToast } from '@octano/global-ui';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  requestActivationEmail,
  requestForgotPasswordEmail,
} from '../../../../../../../api/requests/auth';

const SendEmail = () => {
  const { t } = useTranslation();

  const { watch } = useFormContext();

  const email = watch('email');
  const isActive = watch('isActive');

  const sendEmail = async () => {
    let res = undefined;
    if (isActive) {
      res = await requestForgotPasswordEmail(email);
    } else {
      res = await requestActivationEmail(email);
    }

    if (res?.error) {
      addToast({
        icon: 'error',
        color: 'danger',
        text: t(`auth.emailRequestError`),
      });
      return;
    }

    addToast({
      icon: 'success',
      color: 'success',
      text: isActive
        ? t(`auth.recoverPwdEmailSentMsg`)
        : t(`auth.activateEmailSentMsg`),
    });
  };
  return (
    <p>
      Enviar{' '}
      <span
        onClick={() => sendEmail()}
        style={{
          color: '#007bff',
          textDecoration: 'underline',
          cursor: 'pointer',
        }}
      >
        {isActive ? t(`common.emails.password`) : t(`common.emails.active`)}
      </span>
    </p>
  );
};

export default SendEmail;
