import { useCallback, useContext } from 'react';

import { AdmissionType } from '../../../../../api/requests/tuitionProcess';
import { TuitionProcessContext } from '../../../../../providers/TuitionProcessInfoProvider';
import {
  PostulantInfoType,
  PostulationDetailType,
  TuitionInfo,
  TuitionProcessActionTypes,
} from '../../../../../types/tuitionProcessTypes';

/**
 * Provee el estado y el manejo de estado del usuario autenticado
 */
export const useTuitionProcessNoSua = () => {
  const { state, dispatch } = useContext(TuitionProcessContext);

  const setPostulant = useCallback(
    async (postulant: PostulantInfoType) => {
      dispatch({
        type: TuitionProcessActionTypes.SET_POSTULANT,
        payload: postulant,
      });
    },
    [dispatch],
  );

  const setPostulationDetail = useCallback(
    async (data: PostulationDetailType | null) => {
      dispatch({
        type: TuitionProcessActionTypes.SET_POSTULATION_DETAIL,
        payload: data,
      });
    },
    [dispatch],
  );

  const setStatusPostulation = useCallback(
    async (statusPostulation: string) => {
      dispatch({
        type: TuitionProcessActionTypes.SET_STATUS,
        payload: statusPostulation,
      });
    },
    [dispatch],
  );

  const setNewPostulationDetail = useCallback(
    async (data: AdmissionType | null) => {
      dispatch({
        type: TuitionProcessActionTypes.SET_NEW_POSTULATION_DETAIL,
        payload: data,
      });
    },
    [dispatch],
  );

  const setTuitionInfo = useCallback(
    async (data: TuitionInfo) => {
      dispatch({
        type: TuitionProcessActionTypes.SET_TUITION_INFO,
        payload: data,
      });
    },
    [dispatch],
  );

  return {
    ...state,
    setPostulant,
    setPostulationDetail,
    setStatusPostulation,
    setNewPostulationDetail,
    setTuitionInfo,
  };
};
