import { Icon } from '@octano/global-ui';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Col, Row } from 'reactstrap';

import './card.scss';

interface CardProps {
  title: string;
  subtitle?: string;
  body?: Array<{
    label: string;
    value: number | string;
  }>;
  markedText: {
    value: string;
    label: string;
  };
  to?: string;
  toText?: string;
  onClick?: (e: any) => void;
  className?: string;
}

export function Card(props: CardProps) {
  const {
    title,
    subtitle,
    body,
    markedText,
    to,
    toText,
    onClick,
    className,
  } = props;
  const { t } = useTranslation();
  return (
    <article className={clsx('cardInfo', className)}>
      <header>
        <h6 className="text-ellipsis" title={title}>
          {title}
        </h6>
        {subtitle && <span>{subtitle}</span>}
      </header>
      <section>
        <Row style={{ height: '100%' }}>
          {body && (
            <Col
              style={{ display: 'flex', flexDirection: 'column', gap: '4px' }}
            >
              {body.map(({ label, value }, key) => {
                return (
                  <p key={key}>
                    <span>{label}:</span>
                    <b>{value}</b>
                  </p>
                );
              })}
            </Col>
          )}
          <Col>
            <div className="occupiedSeats">
              <h6>{markedText.value}</h6>
              <span>{markedText.label}</span>
            </div>
          </Col>
        </Row>
      </section>
      {to !== undefined && (
        <nav>
          <Link to={to} className="text-ellipsis" onClick={onClick}>
            {toText ? toText : t('card.viewCompleteList')}{' '}
            <Icon name="chevron_right" size={12} />
          </Link>
        </nav>
      )}
    </article>
  );
}
