import { ReactNode, useCallback } from 'react';

import { getTcTariffList } from '../../../../api/requests/tuitionContinuityTariff';
import FetchProvider from '../../../../components/contexts/FetchContext';

export interface TcTariffTableLoaderProps {
  data: {
    schoolId?: number;
    periodId?: number;
  };
  children: ReactNode;
}

export default function TcTariffTableLoader({
  children,
  data,
}: TcTariffTableLoaderProps) {
  const request = useCallback(async () => {
    const { schoolId, periodId } = data;
    return getTcTariffList(periodId ?? 0, schoolId ?? 0);
  }, [data]);

  return (
    <FetchProvider request={request} defaultQuery={{}} fetchImmediately>
      {children}
    </FetchProvider>
  );
}
