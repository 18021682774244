import { createContext } from 'react';

import { FinantialSettingsContextData } from '../interfaces/finantial-config.interface';

export const FinantialSettingsContext = createContext<FinantialSettingsContextData>(
  {
    loading: false,
    disabled: true,
  },
);
