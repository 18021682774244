import {
  Button,
  OutlinedSelect,
  SearchBox,
  useMobile,
} from '@octano/global-ui';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Col, Form, Row } from 'reactstrap';

import { StudenFileSearchFiltersType, StudentFileFilterProps } from '../types';

const DEFAULT_VALUES = {
  nameOrId: null,
  schoolId: null,
  studyPlanId: null,
  campusId: null,
};

const SearchFilters = (props: StudentFileFilterProps) => {
  const {
    onSearch,
    campusOptions,
    schoolOptions,
    studyPlanOptions: studyplanOptions,
  } = props;
  const prefix = 'studentFile.filters';
  const { t } = useTranslation();
  const {
    control,
    handleSubmit,
    setValue,
    watch,
    reset,
  } = useForm<StudenFileSearchFiltersType>({ defaultValues: DEFAULT_VALUES });
  const currentFiltersValues = watch();
  const isMobile = useMobile();

  const onSubmit = (values: StudenFileSearchFiltersType) => {
    onSearch(values);
  };

  const cleanFilters = () => {
    const needToClean = Object.values(currentFiltersValues).some(
      (value) => value !== null,
    );
    if (needToClean) {
      reset();
      onSearch(DEFAULT_VALUES);
    }
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Row className="pb-4 gutters-filters">
        <Col xs={12} md={4}>
          <SearchBox
            name="nameOrId"
            label="Nombre o ID"
            placeholder="Ingresa un nombre o ID"
            control={control}
            clear={() => setValue('nameOrId', '')}
          />
        </Col>
        <Col xs={12} md={4}>
          <OutlinedSelect
            name="studyPlanId"
            options={studyplanOptions}
            label={t(`${prefix}.studyPlan`)}
            placeholder={t(`${prefix}.placeholderStudyPlan`)}
            control={control}
            isClearable
          />
        </Col>
        <Col xs={12} md={4}>
          <OutlinedSelect
            name="schoolId"
            options={schoolOptions}
            label={t(`${prefix}.school`)}
            placeholder={t(`${prefix}.placeholderSchool`)}
            control={control}
            isClearable
          />
        </Col>
        <Col xs={12} md={4}>
          <OutlinedSelect
            name="campusId"
            options={campusOptions}
            label={t(`${prefix}.campus`)}
            placeholder={t(`${prefix}.placeholderCampus`)}
            control={control}
            isClearable
          />
        </Col>
        <Col>
          <div className="float-right">
            <Button
              type="submit"
              text={t(`common.actions.search`)}
              size="sm"
              style={{
                width: isMobile ? '100%' : 125,
                height: 41,
                marginTop: isMobile ? 0 : '1.5rem',
              }}
            />
            <Button
              text={t(`common.actions.clean`)}
              size="sm"
              outlined
              onClick={cleanFilters}
              style={{
                width: isMobile ? '100%' : 125,
                height: 41,
                marginTop: isMobile ? '1rem' : '1.5rem',
                marginLeft: 10,
              }}
            />
          </div>
        </Col>
      </Row>
    </Form>
  );
};

export default SearchFilters;
