import { Col, Row } from 'reactstrap';
import DisplayInfo from '../../../../../components/info/DisplayInfo';
import { useTexts } from '../../hooks/textsContext';

const UpdateStructureTypeFormFreeFields = () => {
  const texts = useTexts();
  return (
    <>
      <Row>
        <Col md={12}>
          <div className="w-100 d-flex justify-content-center">
            <DisplayInfo
              maxWidth="100%"
              title={texts.info.free.title}
              textBody={texts.info.free.body}
            />
          </div>
        </Col>
      </Row>
    </>
  );
};

export default UpdateStructureTypeFormFreeFields;
