import {
  OutlinedSelect,
  SelectOptionType,
  TextOutlinedInput,
  Button,
} from '@octano/global-ui';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'reactstrap';
import { useCallback } from 'react';

import { TeacherFileFilters } from '../interfaces/teacher-file-filters.interface';

type FormValues = {
  search: string | null;
  studyPlan: SelectOptionType | null;
  campus: SelectOptionType | null;
};

const DEFAULT_FORM_VALUES: FormValues = {
  search: '',
  studyPlan: null,
  campus: null,
};

const dictPrefix = 'teacherFile.searchControls';

interface Props {
  studyPlans?: SelectOptionType[];
  campuses?: SelectOptionType[];
  loading?: boolean;
  onSearch?: (filters?: TeacherFileFilters) => void;
}

export function SearchControls({
  studyPlans = [],
  campuses = [],
  loading = false,
  onSearch,
}: Props) {
  const { t } = useTranslation();

  const { control, handleSubmit, reset } = useForm<FormValues>({
    defaultValues: DEFAULT_FORM_VALUES,
  });

  const handleSearch = useCallback(
    (values: FormValues) => {
      if (loading || !onSearch) {
        return;
      }
      if (
        values?.search?.trim() ||
        values?.studyPlan?.value ||
        values?.campus?.value
      ) {
        onSearch({
          search: values?.search?.trim() ? values?.search?.trim() : undefined,
          studyPlanId: values?.studyPlan?.value ?? undefined,
          campusId: values?.campus?.value ?? undefined,
        });
      } else {
        onSearch(undefined);
      }
    },
    [onSearch, loading],
  );

  const handleClear = useCallback(() => {
    if (loading) {
      return;
    }
    reset(DEFAULT_FORM_VALUES);
    !!onSearch && onSearch(undefined);
  }, [onSearch, reset, loading]);

  return (
    <Row className="mb-5">
      <Col xs={12} sm={6} md={6} lg={4}>
        <TextOutlinedInput
          control={control}
          name="search"
          label={t(`${dictPrefix}.search.label`)}
          placeholder={t(`${dictPrefix}.search.placeholder`)}
          disabled={loading}
        />
      </Col>
      <Col xs={12} sm={6} md={6} lg={4}>
        <OutlinedSelect
          control={control}
          name="studyPlan"
          label={t(`${dictPrefix}.studyPlan.label`)}
          placeholder={t(`${dictPrefix}.studyPlan.placeholder`)}
          options={studyPlans ?? []}
          disabled={!studyPlans?.length || loading}
        />
      </Col>
      <Col xs={12} sm={6} md={6} lg={4}>
        <OutlinedSelect
          control={control}
          name="campus"
          label={t(`${dictPrefix}.campus.label`)}
          placeholder={t(`${dictPrefix}.campus.placeholder`)}
          options={campuses ?? []}
          disabled={!campuses?.length || loading}
        />
      </Col>
      <Col xs={12}>
        <div className="d-flex flex-wrap w-100 align-items-center justify-content-center">
          <div className="flex-fill px-2" />
          <div>
            <div className="d-flex w-100 align-items-center" style={{ gap: 8 }}>
              <div className="flex-fill">
                <Button
                  size="md"
                  text={t(`${dictPrefix}.actions.search`)}
                  loading={loading}
                  onClick={handleSubmit(handleSearch)}
                  className="w-100"
                />
              </div>
              <div className="flex-fill">
                <Button
                  size="md"
                  text={t(`${dictPrefix}.actions.clear`)}
                  className="w-100"
                  loading={loading}
                  onClick={handleClear}
                  outlined
                />
              </div>
            </div>
          </div>
        </div>
      </Col>
    </Row>
  );
}
