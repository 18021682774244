import { useMemo } from 'react';
import { Badge } from 'reactstrap';

export interface BadgeStateProps {
  status: 'pending' | 'approved' | 'failed';
  text: string;
}
export default function BadgeState({ status, text }: BadgeStateProps) {
  const colors = useMemo(() => {
    return {
      pending: '#FFE6A1',
      approved: '#CEF0DA',
      failed: '#F2C0BD',
    };
  }, []);

  return (
    <>
      <div className="d-flex align-items-center justify-content-center student-degree-status-badge">
        <Badge
          className="status-badge fs-16 fw-400 py-2 px-4 text-dark"
          style={{ backgroundColor: colors[status], width: '110px' }}
          pill
        >
          {text}
        </Badge>
      </div>
    </>
  );
}
