import { Button, OutlinedSelect, SelectOptionType } from '@octano/global-ui';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'reactstrap';

import { CurriculumFilters } from '../interfaces/curriculum-filters.interface';

type FormValues = {
  school: SelectOptionType | null;
  studyPlan: SelectOptionType | null;
  version: SelectOptionType | null;
  mention: SelectOptionType | null;
};

const DEFAULT_FORM_VALUES: FormValues = {
  school: null,
  studyPlan: null,
  version: null,
  mention: null,
};

const dictPrefix = 'interimDegree.curriculums.searchControls';

interface Props {
  schools?: SelectOptionType[];
  studyPlans?: SelectOptionType[];
  versions?: SelectOptionType[];
  mentions?: SelectOptionType[];
  onSearch?: (filters: CurriculumFilters) => void;
  onClear?: () => void;
}

export function CurriculumsSearchControls({
  schools = [],
  studyPlans = [],
  versions = [],
  mentions = [],
  onSearch = () => null,
  onClear = () => null,
}: Props) {
  const { t } = useTranslation();

  const { handleSubmit, control, reset } = useForm<FormValues>({
    defaultValues: DEFAULT_FORM_VALUES,
  });

  const handleSearch = (values: FormValues) => {
    onSearch({
      schoolId: values?.school?.value ?? undefined,
      studyPlanId: values?.studyPlan?.value ?? undefined,
      studyPlanVersionId: values?.version?.value ?? undefined,
      curriculumId: values?.mention?.value ?? undefined,
    });
  };

  const handleClear = () => {
    reset(DEFAULT_FORM_VALUES);
    onClear();
  };

  return (
    <Row className="mb-5">
      <Col xs={12} sm={6} md={6} lg={4}>
        <OutlinedSelect
          control={control}
          name="school"
          label={t(`${dictPrefix}.school.label`)}
          placeholder={t(`${dictPrefix}.school.placeholder`)}
          options={schools ?? []}
          disabled={!schools?.length}
        />
      </Col>
      <Col xs={12} sm={6} md={6} lg={4}>
        <OutlinedSelect
          name="studyPlan"
          label={t(`${dictPrefix}.studyPlan.label`)}
          placeholder={t(`${dictPrefix}.studyPlan.placeholder`)}
          options={studyPlans ?? []}
          control={control}
          disabled={!studyPlans?.length}
        />
      </Col>
      <Col xs={12} sm={6} md={6} lg={4}>
        <OutlinedSelect
          name="version"
          label={t(`${dictPrefix}.version.label`)}
          placeholder={t(`${dictPrefix}.version.placeholder`)}
          options={versions ?? []}
          control={control}
          disabled={!versions?.length}
        />
      </Col>
      <Col xs={12} sm={6} md={6} lg={4}>
        <OutlinedSelect
          name="mention"
          label={t(`${dictPrefix}.mention.label`)}
          placeholder={t(`${dictPrefix}.mention.placeholder`)}
          options={mentions ?? []}
          control={control}
          disabled={!mentions?.length}
        />
      </Col>
      <Col xs={12} sm={12} md={12} lg={8}>
        <div className="d-flex flex-column w-100 h-100 align-items-end justify-content-center">
          <div>
            <Button
              className="mx-1"
              type="button"
              size="md"
              text={t(`${dictPrefix}.search`)}
              onClick={handleSubmit(handleSearch)}
            />
            <Button
              className="mx-1"
              type="button"
              size="md"
              text={t(`${dictPrefix}.clear`)}
              outlined
              onClick={handleClear}
            />
          </div>
        </div>
      </Col>
    </Row>
  );
}
