import GoBackToListButton from '../../../../../components/buttons/GoBackToListButton';
import GradingStructureDetailBox from './GradingStructureDetailBox';

const HeaderInfo = () => {
  return (
    <div className="d-flex align-items-center justify-content-between mb-4">
      <GoBackToListButton />
      <GradingStructureDetailBox />
    </div>
  );
};

export default HeaderInfo;
