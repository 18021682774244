import {
  Button,
  OutlinedSelect,
  SelectOptionType,
  addToast,
} from '@octano/global-ui';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { Card, Col, Form, Row } from 'reactstrap';

import { AxiosResultDefaultError } from '../../../../../../api/request';
import {
  AuthenticationError,
  getStudyPlanQuota,
  requestChangeEnrolledStudyPlan,
  requestSaveCareerSelection,
  requestUpdateCareerSelection,
} from '../../../../../../api/requests/tuitionProcess';
import DisplayError from '../../../../../../components/info/DisplayError';
import Loading from '../../../../../../components/info/Loading';
import { useStepState } from '../../../../../../components/step/useStepState';
import { useLoadingState } from '../../../../../../hooks/useLoadingState';
import { useValidations } from '../../../../../../hooks/useValidations';
import { PostulationDetailStatus } from '../../../../../../types/postulationDetailStatus';
import { STATUS_POSTULATION } from '../../../../../../types/tuitionProcessOnSite';
import { StudyPlanQuotaResponse } from '../../../../../../types/tuitionProcessResponseTypes';
import { generateUrlToTuitionProcess } from '../../../TuitionProcessSearchStudent/helper/generateUrlToTuitionProcess';
import { useTuitionProcessNoSua } from '../../hooks/useTuitionProcessNoSua';
import { TuitionProcessNoSuaStepsParams } from '../../interfaces';
import HeaderStep from '../HeaderStep';
import SelectCareerFailedModal, {
  SelectCareerFailedModalMethods,
  SelectCareerFailedModalScope,
} from './SelectCareerFailedModal';

type InputsForm = {
  career: SelectOptionType;
};

const SelectCareer = () => {
  const { t } = useTranslation();
  const prefix = 'tuitionProcessNoSua.selectCareer';
  const history = useHistory();

  const failedModalRef = useRef<SelectCareerFailedModalMethods>(null);

  const { postulantId } = useParams<TuitionProcessNoSuaStepsParams>();
  const { handleSubmit, control, formState, watch, getValues } =
    useForm<InputsForm>();
  const { msgValidations } = useValidations();
  const { loading, setLoading, errorLoading, setErrorLoading } =
    useLoadingState();

  const {
    postulationDetail,
    setStatusPostulation,
    statusPostulation,
    postulant,
  } = useTuitionProcessNoSua();
  const { nextStep } = useStepState();

  const [careers, setCareers] = useState<StudyPlanQuotaResponse[]>([]);
  const [optionsCareer, setOptionsCareer] = useState<SelectOptionType[]>([]);
  const [confirming, setConfirming] = useState<boolean>(false);
  const [confirmChangeCareer, setConfirmChangeCareer] =
    useState<boolean>(false);

  const [selectedCareerId] = watch(['career']);
  const selectedCareer = useMemo(
    () => careers?.find((e) => `${e?.id}` === `${selectedCareerId?.value}`),
    [careers, selectedCareerId?.value],
  );

  const msgValidationChangeStudyPlan = t(
    `${prefix}.msgValidationChangeStudyPlan`,
  );

  const currentStudyPlanName = useMemo(() => {
    if (postulationDetail) {
      return `${postulationDetail.studyPlanName} - ${postulationDetail.campus.name} - ${postulationDetail.schedule.name}`;
    }
    return '';
  }, [postulationDetail]);

  const getCareers = useCallback(async () => {
    setLoading(true);
    const { data, error } = await getStudyPlanQuota();
    if (data) {
      setCareers(data ?? []);
      setOptionsCareer(
        data.map((career: any) => {
          return {
            value: career.id,
            label: `${career.studyPlanVersion.name} - Campus ${career.campus.name} - ${career.schedule.name}`,
          };
        }),
      );
      setErrorLoading(undefined);
    }
    if (error) {
      setErrorLoading('error');
    }
    setLoading(false);
  }, [setLoading, setErrorLoading]);

  useEffect(() => {
    getCareers();
  }, [getCareers]);

  const processError = useCallback(
    (error: AxiosResultDefaultError | AuthenticationError) => {
      const errors: string[] = [];
      if (!error) {
        return null;
      }
      if (Array.isArray(error?.data?.message)) {
        error.data.message.forEach((message: string) => {
          !!message?.trim() && errors.push(message?.trim());
        });
      } else if (typeof error?.data?.message === 'string') {
        !!error?.data?.message?.trim() &&
          errors.push(error?.data?.message?.trim());
      }
      if (!errors.length) {
        return null;
      }
      return errors[0];
    },
    [],
  );

  const saveChangeEnrolledCareer = useCallback(
    async (admissionId: number) => {
      const { data, error } = await requestChangeEnrolledStudyPlan({
        admissionId: admissionId,
        sub: postulant?.id ? postulant?.id : +postulantId,
        currentPostulationDetailId: postulationDetail?.id,
      });
      if (data) {
        addToast({
          icon: 'success',
          color: 'success',
          text: t('common.messages.editSuccess'),
        });
        const url = generateUrlToTuitionProcess({
          studentPostulantId: postulantId,
          studentDetailId: data.data.id,
          studentAdmissionTypeId: 'SIN_SUA',
          fromStart: false,
        });
        history.replace(url);
      }
      if (error) {
        failedModalRef?.current?.openConditional(
          processError(error),
          t('common.errors.save'),
          {
            phone: selectedCareer?.helpPhone,
            email: selectedCareer?.helpPhone,
            currentStudyPlanName,
          },
        );
      }
    },
    [
      currentStudyPlanName,
      history,
      postulant?.id,
      postulantId,
      postulationDetail?.id,
      processError,
      selectedCareer?.helpPhone,
      t,
    ],
  );

  const onSubmit = (values: InputsForm) => {
    if (statusPostulation === STATUS_POSTULATION.ENROLLED) {
      // Si selecciona una carrera distinta a la actual
      if (
        postulationDetail?.admissionId &&
        postulationDetail.admissionId !== values.career.value
      ) {
        saveChangeEnrolledCareer(+values.career.value);
      }
    } else {
      if (
        Number.isInteger(postulationDetail?.admissionId) &&
        postulationDetail?.admissionId !== values.career.value &&
        !confirmChangeCareer
      ) {
        failedModalRef?.current?.open(
          SelectCareerFailedModalScope.ChangeCareer,
          {
            phone: selectedCareer?.helpPhone,
            email: selectedCareer?.helpPhone,
            currentStudyPlanName,
          },
        );
      } else if (postulationDetail?.admissionId === values.career.value) {
        // Si quiere cambiar por la misma carrera, solo se pasa al siguiente paso
        nextStep();
      } else {
        saveSelectionCareer(values.career.value, false);
      }
    }
  };

  const saveSelectionCareer = async (
    admissionId: number | string,
    acceptsWaitingList: boolean,
  ) => {
    const studentAdmissionId =
      typeof admissionId === 'string' ? parseInt(admissionId) : admissionId;
    const isUpdate =
      postulationDetail?.id && postulationDetail?.id !== studentAdmissionId;

    const dataRequest = {
      admissionId: studentAdmissionId,
      acceptsWaitingList,
      sub: Number(postulantId),
    };

    const { data, error } = isUpdate
      ? await requestUpdateCareerSelection(dataRequest, postulationDetail?.id)
      : await requestSaveCareerSelection(dataRequest);
    if (data) {
      addToast({
        icon: 'success',
        color: 'success',
        text: t(`${prefix}.saveSuccess`),
      });
      if (acceptsWaitingList) {
        setStatusPostulation(STATUS_POSTULATION.IN_WAITING_LIST);
      }

      setConfirmChangeCareer(false);
      setConfirming(false);

      const { postulantId: studentPostulantId, admissionType, details } = data;
      // Sólo debería existir un detalle por admissionId
      // en alguno de estos estados el cual correspondería
      // a la postulación actual
      const postDetail = details
        .filter((detail) => detail.admissionId === studentAdmissionId)
        .find(
          (detail) =>
            detail.status === PostulationDetailStatus.EN_PROCESO ||
            detail.status === PostulationDetailStatus.EN_ESPERA ||
            detail.status === PostulationDetailStatus.MATRICULADO,
        );

      const url = generateUrlToTuitionProcess({
        studentPostulantId,
        studentDetailId: postDetail?.id ?? undefined,
        studentAdmissionTypeId: admissionType?.id ?? 'SIN_SUA',
        fromStart: false,
      });
      history.replace(url);
    } else if (error) {
      failedModalRef?.current?.openConditional(
        processError(error),
        t(`${prefix}.saveError`),
        {
          phone: selectedCareer?.helpPhone,
          email: selectedCareer?.helpPhone,
          currentStudyPlanName,
          allowWaitlistJoin: !acceptsWaitingList,
        },
      );
    }
  };

  /**
   * Se utiliza para inscribir al alumno en lista de espera luego de confirmar.
   */
  const acceptWaitingList = async () => {
    try {
      setConfirming(true);
      const admissionId = getValues('career').value;
      await saveSelectionCareer(
        typeof admissionId === 'string' ? parseInt(admissionId) : admissionId,
        true,
      );
      setConfirming(false);
    } catch (error) {
      setConfirming(false);
    }
  };

  /**
   * Se utiliza para confirmar el cambio de una carrera a otra
   */
  const acceptChangeCareer = async () => {
    setConfirmChangeCareer(true);
    const admissionId = getValues('career').value;
    await saveSelectionCareer(
      typeof admissionId === 'string' ? parseInt(admissionId) : admissionId,
      false,
    );
  };

  if (loading && !errorLoading) {
    return <Loading insideCard />;
  }
  if (errorLoading) {
    return (
      <DisplayError
        insideCard
        textBody={t('common.selectCareer.errorLoadingInfo')}
        title={t('common.selectCareer.titleError')}
        buttonText={t('common.selectCareer.underStood')}
        retryAction={() => history.push('/tuition-process')}
        loadingAction={loading}
      />
    );
  }
  return (
    <Card className="px-4 px-lg-5 py-4">
      <HeaderStep prefixStep={prefix} />
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Row>
          <Col xs={12} className="pt-lg-4 pb-lg-5">
            <div
              className="w-100 mx-auto pb-5 pt-lg-5 pb-lg-5"
              style={{ maxWidth: '600px' }}
            >
              <span className="fs-14 text-medium">{t(`${prefix}.career`)}</span>
              <OutlinedSelect
                name={'career'}
                options={optionsCareer}
                control={control}
                rules={{
                  required: msgValidations.required,
                  validate: {
                    career: (career) => {
                      if (
                        statusPostulation === STATUS_POSTULATION.ENROLLED &&
                        postulationDetail?.admissionId &&
                        postulationDetail.admissionId === career.value
                      )
                        return msgValidationChangeStudyPlan;
                      return undefined;
                    },
                  },
                }}
                disabled={formState.isSubmitting}
              />
            </div>
          </Col>
        </Row>

        <Row className="py-5 justify-content-end">
          <Col xs={12} lg={4} className="pb-2 order-2 order-lg-1">
            <Button
              type="button"
              outlined
              onClick={() => history.push('/tuition-process')}
              text={t(`common.actions.cancel`)}
              loading={formState.isSubmitting}
              fullwidth
            />
          </Col>

          <Col xs={12} lg={4} className="pb-2 order-1 order-lg-2">
            <Button
              type="submit"
              text={t(`common.actions.next`)}
              loading={formState.isSubmitting}
              fullwidth
            />
          </Col>
        </Row>
      </Form>

      <SelectCareerFailedModal
        ref={failedModalRef}
        loading={confirming}
        onJoin={acceptWaitingList}
        onChangeCareer={acceptChangeCareer}
      />
    </Card>
  );
};

export default SelectCareer;
