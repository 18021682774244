import { ReactElement, useCallback } from 'react';
import { AxiosResultDefaultError } from '../../../../../../../api/request';
import { createFetchContext } from '../../../../../../../components/contexts/FetchContextFactory';
import { getRecords } from '../../api';
import { HiringInformationResponse } from '../../type';

interface BaseLoaderProps {
  teacherId: string;
  children: ReactElement;
}

const { FetchProvider, FetchConsumer, useFetch } = createFetchContext<
  undefined,
  HiringInformationResponse,
  AxiosResultDefaultError
>();

export function useHiringInformationLoader() {
  return useFetch();
}

export const HiringInformationLoaderConsumer = FetchConsumer;

export default function HiringInformationLoader({
  teacherId,
  children,
}: BaseLoaderProps) {
  const request = useCallback(async () => {
    return getRecords({ teacherId });
  }, [teacherId]);

  return (
    <FetchProvider request={request} defaultQuery={undefined} fetchImmediately>
      {children}
    </FetchProvider>
  );
}
