import request from '../../../api/request';
import {
  StudentFileFormDataResponse,
  StudentFilePostulantRequest,
  StudentFileShowResponse,
} from './types';

const STUDENT_FILE = {
  GET_POSTULANTS: 'maintainers-portal/student-management/student-file',
  GET_POSTULANT: (postulantId: string | number) =>
    `maintainers-portal/student-management/student-file/show/${postulantId}`,
  UPDATE_POSTULANT_PHOTO: (postulantId: string | number) =>
    `maintainers-portal/student-management/student-file/photo/${postulantId}`,
  UPDATE_POSTULANT: (postulantId: string | number) =>
    `maintainers-portal/student-management/student-file/update/${postulantId}`,
  GET_FORM_DATA: 'maintainers-portal/student-management/student-file/form',
};

export const getPostulants = (params: StudentFilePostulantRequest) => {
  return request<any>(STUDENT_FILE.GET_POSTULANTS, {
    params,
  });
};

export const getPostulant = (id: number | string) => {
  return request<StudentFileShowResponse>(STUDENT_FILE.GET_POSTULANT(id));
};

export const getFormData = () => {
  return request<StudentFileFormDataResponse>(STUDENT_FILE.GET_FORM_DATA);
};

export const savePostulantPhoto = (id: number | string, photo?: File) => {
  const formData = new FormData();
  if (photo) {
    formData.append('photo', photo);
  }
  return request(STUDENT_FILE.UPDATE_POSTULANT_PHOTO(id), {
    method: 'post',
    data: formData,
  });
};
export const savePostulant = (
  id: number | string,
  body: { responses: any },
) => {
  return request(STUDENT_FILE.UPDATE_POSTULANT(id), {
    method: 'post',
    data: body,
  });
};
