import { ReactElement, useCallback } from 'react';
import { AxiosResultDefaultError } from '../../../../../../../api/request';
import { createFetchContext } from '../../../../../../../components/contexts/FetchContextFactory';
import { getTitles } from '../../api';
import { EducationalBackgroundResponse } from '../../type';

interface BaseLoaderProps {
  teacherId: number;
  children: ReactElement;
}

const { FetchProvider, FetchConsumer, useFetch } = createFetchContext<
  undefined,
  EducationalBackgroundResponse,
  AxiosResultDefaultError
>();

export function useEducationalBackgroundLoader() {
  return useFetch();
}

export const EducationalBackgroundLoaderConsumer = FetchConsumer;

export default function EducationalBackgroundLoader({
  teacherId,
  children,
}: BaseLoaderProps) {
  const request = useCallback(async () => {
    return getTitles(teacherId);
  }, [teacherId]);

  return (
    <FetchProvider request={request} defaultQuery={undefined} fetchImmediately>
      {children}
    </FetchProvider>
  );
}
