import { Curriculum } from "./curriculum.interface";
import { InterimDegreeActivity } from "./interim-degree-activity.interface";

export enum InterimDegreeStatus {
  Draft = 'draft',
  Active = 'active',
  Closed = 'closed',
}

export interface InterimDegreeSemesterCourse {
  id: number;
  interimDegreeId: number;
  semesterCourseId: number;
}

export interface InterimDegree {
  id: number;
  name: string;
  description: string;
  status: InterimDegreeStatus;
  hasActivities: boolean;
  curriculum?: Curriculum;
  interimDegreeSemesterCourse?: InterimDegreeSemesterCourse[];
  interimDegreeActivities?: InterimDegreeActivity[];
  activatedAt: string | null;
  closedAt: string | null;
  createdAt: string;
  updatedAt: string;
  
}
