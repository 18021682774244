// Components
import { Select } from '@octano/global-ui';
// Hooks
import { useContext, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'reactstrap';

import { useValidations } from '../../../../hooks/useValidations';
import { QuotaRoundingMethod } from '../../../../types/tariffTypes';

// Utils
import { FinantialSettingsContext } from '../contexts/finantial-settings.context';
// Types
import { FinantialConfig } from '../interfaces/finantial-config.interface';

// Render
export default function QuotaRoundingSection() {
  const { t } = useTranslation();
  const prefix = 'configurations.finantial.quotaRounding';

  const finantialSettingsContext = useContext(FinantialSettingsContext);

  const { msgValidations } = useValidations();

  const { setValue, watch } = useFormContext<FinantialConfig>();

  const [quotaRoundingMethod] = watch(['quotaRoundingMethod']);

  const quotaRoundingMethodsOptions = useMemo(
    () => [
      {
        value: QuotaRoundingMethod.NotRound,
        label: t(`${prefix}.quotaRoundingMethods.notRound`),
      },
      {
        value: QuotaRoundingMethod.RoundToSimple,
        label: t(`${prefix}.quotaRoundingMethods.simple`),
      },
      {
        value: QuotaRoundingMethod.RoundToBalanced,
        label: t(`${prefix}.quotaRoundingMethods.balanced`),
      },
      {
        value: QuotaRoundingMethod.RoundToTruncateToEnd,
        label: t(`${prefix}.quotaRoundingMethods.trucantedToEnd`),
      },
    ],
    [t],
  );

  return (
    <Row className="mt-4">
      <Col xs={12}>
        <h2 className="text-primary fs-16 fw-600 text-uppercase">
          {t(`${prefix}.title`)}
        </h2>
      </Col>
      <Col sm={12} md={6} lg={6}>
        <Select
          name="quotaRoundingMethod"
          label={t(`${prefix}.inputs.quotaRoundingMethod`)}
          options={quotaRoundingMethodsOptions}
          value={
            quotaRoundingMethodsOptions?.find(
              (e) => e?.value === quotaRoundingMethod,
            ) ?? quotaRoundingMethodsOptions[0]
          }
          disabled={finantialSettingsContext.disabled}
          onChange={(e) => setValue('quotaRoundingMethod', e?.value)}
          rules={{ required: msgValidations.required }}
        />
      </Col>
    </Row>
  );
}
