import { TimeInput } from '@octano/global-ui';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'reactstrap';

type StartEndHourProps = {
  day: string;
  startId: string;
  endId: string;
  control?: any;
  disabled?: boolean;
};

const StartEndHour = ({
  day,
  startId,
  endId,
  control,
  disabled = false,
}: StartEndHourProps) => {
  const { t } = useTranslation();

  return (
    <Row className="pb-2">
      <Col xs={3} sm={3} md={2}>
        <span className="g-day-label">{day}</span>
      </Col>
      <Col xs={9} sm={9} md={5}>
        <TimeInput
          id={startId}
          name={startId}
          control={control}
          disabled={disabled}
          label={t('startEndHour.start')}
        />
      </Col>
      <Col
        xs={{ size: 9, offset: 3 }}
        sm={{ size: 9, offset: 3 }}
        md={{ size: 5, offset: 0 }}
      >
        <TimeInput
          id={endId}
          name={endId}
          control={control}
          disabled={disabled}
          label={t('startEndHour.end')}
        />
      </Col>
    </Row>
  );
};

export default StartEndHour;
