import { Modal, addToast } from '@octano/global-ui';
import { useTranslation } from 'react-i18next';

import {
  AxiosResult,
  AxiosResultDefaultError,
} from '../../../../../api/request';
import { useLayoutState } from '../../../../../hooks/useLayoutState';
import { TuitionItem } from '../../../../../types/periodTuition';
import { PeriodsLoader } from './PeriodsLoader';
import { TuitionForm } from './TuitionForm';

interface DiscountFormProps {
  show: boolean;
  recordToEdit: TuitionItem | null;
  toggleModal: () => void;
  reloadDiscountList: () => void;

  isContinuity?: boolean;
}

export const TuitionModal = ({
  recordToEdit,
  show,
  toggleModal,
  reloadDiscountList,
  isContinuity = false,
}: DiscountFormProps) => {
  const editing = !!recordToEdit;

  const prefix = 'tariffAndTuition.tuition';
  const { t } = useTranslation();
  const { showErrorModal } = useLayoutState();

  const onSubmit = async (
    result: AxiosResult<TuitionItem, AxiosResultDefaultError>,
  ) => {
    const { error, data } = result;
    if (error && error.code === 'CONNECTION') {
      showErrorModal();
    } else if (error) {
      addToast({
        icon: 'error',
        color: 'danger',
        text: t('common.errors.save'),
      });
    }
    if (data) {
      reloadDiscountList();
      toggleModal();
      addToast({
        icon: 'success',
        color: 'success',
        text: editing ? t(`${prefix}.editChanges`) : t(`${prefix}.saveChanges`),
      });
    }
  };

  return (
    <Modal isOpen={show} toggle={toggleModal} size="lg" unmountOnClose>
      <PeriodsLoader isTuitionContinuity={isContinuity}>
        {({ periods }) => {
          return (
            <TuitionForm
              recordToEdit={recordToEdit}
              onSubmit={onSubmit}
              onCancel={toggleModal}
              periods={periods}
              isContinuity={isContinuity}
            />
          );
        }}
      </PeriodsLoader>
    </Modal>
  );
};
