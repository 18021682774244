import { useCallback, useEffect, useState } from 'react';

import { getGradesEntryConfig } from '../../../api/requests/gradesEntry';

interface GradesConfig {
  max: number;
  min: number;
  decimalsLength: number;
  pass: number;
}

const DEFAULT_GRADES_CONFIG: GradesConfig = {
  max: 0,
  min: 0,
  decimalsLength: 0,
  pass: 0,
};

export function useConfigurableGradesValidator() {
  const [isRequestingConfig, setIsRequestingConfig] = useState<boolean>(false);
  const [errorGettingConfig, setErrorGettingConfig] = useState<boolean>(false);
  const [config, setConfig] = useState<GradesConfig>(DEFAULT_GRADES_CONFIG);

  const requestConfig = useCallback(async () => {
    setIsRequestingConfig(true);

    const { data, error } = await getGradesEntryConfig();

    if (error) {
      setErrorGettingConfig(true);
    }

    if (data) {
      const max = data.find(
        (config) => config.key === 'grades-entry.maximum-grade',
      );
      const min = data.find(
        (config) => config.key === 'grades-entry.minimum-grade',
      );
      const decimalsLength = data.find(
        (config) => config.key === 'grades-entry.number-of-decimals-to-show',
      );
      const pass = data.find(
        (config) => config.key === 'grades-entry.passing-grade',
      );

      setConfig({
        max: max?.value || DEFAULT_GRADES_CONFIG.max,
        min: min?.value || DEFAULT_GRADES_CONFIG.min,
        decimalsLength: decimalsLength?.value || DEFAULT_GRADES_CONFIG.max,
        pass: pass?.value || DEFAULT_GRADES_CONFIG.max,
      });
    }

    setIsRequestingConfig(false);
  }, []);

  const formatNumber = useCallback(
    (value: string) => {
      const { max, min, decimalsLength } = config;
      const numberValue = Number(value);

      const conditionsToFullfil = [
        numberValue >= min,
        numberValue <= max,
        value.length <= decimalsLength + 2,
      ];

      if (conditionsToFullfil.some((cond) => !cond))
        return `${value}`.slice(0, value.length - 1);

      return value;
    },
    [config],
  );

  const formatGrade = useCallback(
    (value: string) => {
      if (/[0-9]|[0-9].[0-9]/.test(value)) {
        return formatNumber(value);
      }

      if (
        value.toLocaleLowerCase() === 'j' ||
        value.toLocaleLowerCase() === 'n' ||
        value.toLocaleLowerCase() === 'nr'
      ) {
        return value;
      }

      return `${value}`.slice(0, value.length - 1);
    },
    [formatNumber],
  );

  useEffect(() => {
    requestConfig();
  }, [requestConfig]);

  return {
    config,
    isRequestingConfig,
    formatGrade,
    errorGettingConfig,
  };
}
