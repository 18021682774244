import {
  OutlinedSelect,
  SelectOptionType,
  TextOutlinedInput,
  Button,
} from '@octano/global-ui';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useMemo, useCallback } from 'react';
import { InterimDegreeAuthorizedStudentSingleFilters } from '../interfaces/interim-degree-authorized-student-single-filters.interface';

type FormValues = {
  type: SelectOptionType | null;
  search: string | null;
};

const DEFAULT_FORM_VALUES: FormValues = {
  type: null,
  search: '',
};

const dictPrefix = 'interimDegreeGraduationProcess.single.searchControls';

interface Props {
  loading?: boolean;
  onSearch?: (filters: InterimDegreeAuthorizedStudentSingleFilters) => void;
  onClear?: () => void;
}

export function InterimDegreesSigleSearchControls({
  loading = false,
  onClear,
  onSearch,
}: Props) {
  const { t } = useTranslation();

  const types = useMemo<SelectOptionType[]>(
    () => [
      {
        label: t(`${dictPrefix}.types.identification`),
        value: 'identification',
      },
      {
        label: t(`${dictPrefix}.types.name`),
        value: 'name',
      },
    ],
    [t],
  );

  const { control, handleSubmit, setError, reset } = useForm<FormValues>({
    defaultValues: DEFAULT_FORM_VALUES,
  });

  const handleClear = useCallback(() => {
    if (loading) {
      return;
    }
    reset(DEFAULT_FORM_VALUES);
    !!onClear && onClear();
  }, [onClear, reset, loading]);

  const handleSearch = useCallback(
    (values: FormValues) => {
      if (loading) {
        return;
      }

      if (!values?.search?.trim() && !values?.type?.value) {
        return handleClear();
      }
      if (!values?.type?.value) {
        setError('type', t(`${dictPrefix}.errors.required`));
        return;
      }
      if (!values?.search?.trim()) {
        setError('search', t(`${dictPrefix}.errors.required`));
        return;
      }
      !!onSearch &&
        onSearch({
          search: values?.search?.trim(),
          type: values?.type?.value as any,
        });
    },
    [onSearch, handleClear, setError, loading, t],
  );

  return (
    <div
      className="d-flex flex-wrap w-100 align-items-start justify-content-center"
      style={{ gap: 16 }}
    >
      <div
        className="flex-fill d-flex flex-wrap align-items-start justify-content-center"
        style={{ gap: 12 }}
      >
        <div style={{ minWidth: 300 }}>
          <OutlinedSelect
            control={control}
            name="type"
            label={t(`${dictPrefix}.type.label`)}
            placeholder={t(`${dictPrefix}.type.placeholder`)}
            options={types}
            disabled={!types?.length || loading}
          />
        </div>
        <div className="flex-fill">
          <TextOutlinedInput
            control={control}
            name="search"
            label={t(`${dictPrefix}.type.label`)}
            placeholder={t(`${dictPrefix}.type.placeholder`)}
            disabled={loading}
          />
        </div>
      </div>
      <div
        className="d-flex flex-wrap align-items-end justify-content-center align-self-stretch mb-3"
        style={{ gap: 12 }}
      >
        <div className="flex-fill">
          <Button
            text={t(`${dictPrefix}.actions.search`)}
            size="md"
            loading={loading}
            onClick={handleSubmit(handleSearch)}
          />
        </div>
        <div className="flex-fill">
          <Button
            text={t(`${dictPrefix}.actions.clear`)}
            size="md"
            loading={loading}
            onClick={handleClear}
            outlined
          />
        </div>
      </div>
    </div>
  );
}
