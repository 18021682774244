import { SectionsScheduleDays } from './sectionSchedules';
import { TimeModuleType } from './timeModuleTypes';

export interface PackagesListItem {
  /**
   * Corresponde al section package id
   */
  id: number;
  name: string;
  location: string;
  sections: number;
  quota: number;
  enrolled: number;
  spaceAvailable: number | null;
}

export interface PackagesListHeader {
  /**
   * ID del AcademicOfferStudyPlanVersionOffer
   */
  academicOfferStudyPlanVersionOfferId: number;
  /**
   * ID del StudyPlanVersionOffer
   */
  studyPlanVersionOfferId: number;
  /**
   * Nombre de la oferta de plan de estudio
   */
  location: string;
  totalStudents: number;
  studentsWithoutRegistration: number;
  packages: PackagesListItem[];
}

export enum SectionScheduleType {
  Default = 'default',
  Recovery = 'recovery',
}

export interface PackagesViewsSection {
  id: number;
  name: string;
  sectionSchedules: {
    id: number;
    type: SectionScheduleType;
    day: SectionsScheduleDays;
    classroom: {
      id: number;
      name: string;
    };
    startModule: TimeModuleType;
    endModule: TimeModuleType;
    activity: {
      id: number;
      name: string;
    };
    deletedAt: string;
  }[];
  professors: {
    id: number;
    account: {
      fullName: string;
    };
  }[];
  course: {
    id: number;
    code: string;
    name: string;
  };
  campus: {
    id: number;
    code: string;
    name: string;
  };
}

export interface PackagesViewsStudent {
  email: string;
  fullName: string;
  id: number;
  maternalLastName: string;
  name: string;
  paternalLastName: string;
  phone: { cellPhone: string } | null;
  run: string;
}

export interface PackageList {
  id: number;
  name: string;
  academicOfferStudyPlanVersionOffer: {
    studyPlanVersionOffer: {
      studyPlanVersion: {
        studyPlan: {
          name: string;
          school: {
            name: string;
          };
          modality: {
            name: string;
          };
        };
        name: string;
      };
    };
  };
}
