import { Alert, Button, addToast } from '@octano/global-ui';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Col } from 'reactstrap';

import { useStepState } from '../../../../../../../../components/step/useStepState';
import { useTuitionProcessNoSua } from '../../../../hooks/useTuitionProcessNoSua';
import { finishPaymentStep } from '../../services/requests';
import { GatewayPaymentResponse } from '../../types';
import PaymentDetails from './PaymentDetails';
import PaymentTable from './PaymentTable';

export default function Payment({
  paymentData,
}: {
  paymentData: GatewayPaymentResponse;
}) {
  const prefix = `tuitionProcessNoSua.payment`;

  const [finishing, setFinishing] = useState<boolean>(false);

  //Custom Hooks
  const { t } = useTranslation();
  const { postulationDetail } = useTuitionProcessNoSua();
  const { nextStep } = useStepState();

  const handleSubmit = useCallback(async () => {
    try {
      if (!postulationDetail) {
        return;
      }

      if (finishing) {
        return;
      }
      setFinishing(true);
      const { error, data } = await finishPaymentStep(postulationDetail.id);
      if (error) {
        throw error;
      }
      if (data?.notGenerateSalenote) {
        addToast({
          icon: 'error',
          color: 'danger',
          text: t(`${prefix}.notGenerateSalenote`),
        });
      }
      nextStep();

      setFinishing(false);
    } catch (error: any) {
      console.log('error', error);
      setFinishing(false);
      if (error && error.code === 'HTTP_ERROR' && error.status === 401) {
      } else {
        addToast({
          icon: 'error',
          color: 'danger',
          text: t(`${prefix}.errorOnFinish`),
        });
      }
    }
  }, [finishing, nextStep, postulationDetail, prefix, t]);

  return (
    <>
      <Col
        xs={12}
        sm={12}
        lg={6}
        xl={4}
        className="pt-lg-4 pb-lg-5 d-flex flex-column"
        style={{ gap: '24px' }}
      >
        <PaymentDetails
          postulant={{
            name: paymentData?.details?.student?.name ?? '',
            rut: paymentData?.details?.student?.rut ?? '',
            passport: paymentData?.details?.student?.passport ?? '',
          }}
          sustainer={
            paymentData?.sustainerName
              ? {
                  name: paymentData?.sustainerName,
                  rut: paymentData?.sustainerRut,
                  passport: paymentData?.sustainerPassport,
                }
              : undefined
          }
        />
      </Col>
      <Col
        xs={12}
        sm={12}
        lg={6}
        xl={8}
        className="pt-lg-4 pb-lg-5 d-flex flex-column"
        style={{ gap: '24px' }}
      >
        <>
          {!!paymentData.message?.trim() && (
            <Alert
              isOpen={true}
              text={paymentData.message?.trim()}
              className="mx-auto"
              style={{ maxWidth: 370 }}
              icon="information"
              fade
            />
          )}

          <span className="fs-22 fw-600 text-primary text-uppercase">
            {t(`${prefix}.table.title`)}
          </span>

          <PaymentTable
            data={paymentData.details?.items ?? []}
            loading={false}
          />

          <Button
            type="button"
            text={t(`${prefix}.submit`)}
            className="mx-auto mt-2"
            loading={finishing}
            fullwidth
            onClick={handleSubmit}
          />
        </>
      </Col>
    </>
  );
}
