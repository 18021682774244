import { Box, useMobile } from '@octano/global-ui';
import { useTexts } from '../../hooks/textsContext';
import { useGradingStructureInfoLoader } from '../Loaders/GradingStructureInfoLoader';

export default function GradingStructureDetailBox() {
  const texts = useTexts();
  const isMobile = useMobile();

  const { data } = useGradingStructureInfoLoader();

  return (
    <Box
      color="primary"
      variant="outlined"
      style={{ borderRadius: 4 }}
      className={`py-2 px-3 ${isMobile && 'mt-4 w-100'}`}
      body={
        <div className="d-flex justify-content-between">
          <Box
            color="primary"
            variant="outlined"
            className="p-0 border-0 w-70 text-uppercase"
            title={texts.box.academicLevel}
            style={{ minWidth: isMobile ? 0 : 140 }}
            body={
              <p className="fs-14 mb-0 pr-2 text-capitalize">{data?.name}</p>
            }
          />
        </div>
      }
    />
  );
}
