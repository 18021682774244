import {
  CellFormatOptions,
  ColumnTable,
  Icon,
  TableCell,
  useMobile,
} from '@octano/global-ui';
import { useMemo, useState } from 'react';

export type TableBodyProps = {
  columns: ColumnTable[];
  secondColumn: ColumnTable;
  data: any[];
};

export const TableBody = ({
  columns: columnsProp,
  secondColumn,
  data,
}: TableBodyProps) => {
  const isMobile = useMobile();

  const columns = useMemo(() => {
    return columnsProp.filter((col) => {
      return !(isMobile && col.hideOnMobile);
    });
  }, [columnsProp, isMobile]);

  const [expanded, setExpanded] = useState<Record<number, boolean>>({});

  return (
    <tbody>
      {data.map((res, i) => {
        return (
          <>
            <tr key={`row_${i}`} data-key={`row_${i}`}>
              {columns.map((col, j) => {
                const options: CellFormatOptions<any> = {
                  row: res,
                  value: res[col.columnName],
                  index: i,
                  data,
                  column: col,
                };

                const className =
                  typeof col.tdClassName === 'function'
                    ? col.tdClassName(options)
                    : col.tdClassName;

                return (
                  <TableCell
                    className={className}
                    key={`body_${i}_${j}`}
                    data-key={`body_${i}_${j}`}
                    bold={col.bold}
                    width={col.width}
                  >
                    {col.cellFormat
                      ? col.cellFormat(options)
                      : res[col.columnName]}
                  </TableCell>
                );
              })}
              <td key="collpase">
                <span
                  onClick={() => setExpanded((e) => ({ ...e, [i]: !e[i] }))}
                  className="mr-2"
                >
                  <Icon
                    className={`cursor-pointer`}
                    name={expanded[i] ? 'chevron_up' : 'chevron_down'}
                    size="18px"
                  />
                </span>
              </td>
            </tr>
            {expanded[i] && (
              <>
                <tr key={`row_phantom_${i}`} data-key={`row_phantom_${i}`}></tr>
                <tr key={`row_second_${i}`} data-key={`row_second_${i}`}>
                  {[secondColumn].map((col, j) => {
                    const options: CellFormatOptions<any> = {
                      row: res,
                      value: res[col.columnName],
                      index: i,
                      data,
                      column: col,
                    };

                    const className =
                      typeof col.tdClassName === 'function'
                        ? col.tdClassName(options)
                        : col.tdClassName;

                    return (
                      <TableCell
                        className={className}
                        key={`body_second_${i}_${j}`}
                        data-key={`body_second_${i}_${j}`}
                        bold={col.bold}
                        width={col.width}
                        colSpan={columns.length + 1}
                      >
                        {col.cellFormat
                          ? col.cellFormat(options)
                          : res[col.columnName]}
                      </TableCell>
                    );
                  })}
                </tr>
              </>
            )}
          </>
        );
      })}
    </tbody>
  );
};
