import { OutlinedSelect, OutlinedSelectOptionType } from '@octano/global-ui';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'reactstrap';

import DisplayError from '../../../components/info/DisplayError';
import { isNumber } from '../../../utils/math';
import { PeriodSelectorForm } from '../types';

interface FilterListProps extends PeriodSelectorForm {}

export const FilterList = ({
  periods,
  currentPeriod,
  campuses,
  children,
}: Readonly<FilterListProps>) => {
  const { t } = useTranslation();
  const dictPrefix = 'packages';

  const optionsCampuses = useMemo<OutlinedSelectOptionType[]>(() => {
    return campuses.map((p) => {
      return {
        value: p.id,
        label: p.name,
      };
    });
  }, [campuses]);

  const optionsPeriods = useMemo<OutlinedSelectOptionType[]>(() => {
    return periods.map((p) => {
      return {
        value: p.id,
        label: p.name,
      };
    });
  }, [periods]);

  const [campus, setCampus] = useState<OutlinedSelectOptionType | undefined>(
    () => {
      return undefined;
    },
  );

  const [period, setPeriod] = useState(() => {
    const option = optionsPeriods.find((o) => o.value === currentPeriod?.id);
    if (option) {
      return option;
    }
    if (optionsPeriods.length > 0) {
      return optionsPeriods[0];
    }
    return undefined;
  });

  const campusId = campus ? +campus?.value : campus;
  const periodId = period?.value;

  if (Array.isArray(optionsPeriods) && optionsPeriods.length === 0) {
    return (
      <DisplayError
        insideCard
        textBody="No hay eventos de matricula de continuidad definidos. Intenta mas tarde"
      />
    );
  }

  if (!periodId || !isNumber(periodId)) {
    return (
      <DisplayError
        insideCard
        textBody="Ha ocurrido un error al seleccionar el periodId"
      />
    );
  }

  return (
    <div id="admissionsPlanesList">
      <div className="g-table-container mt-2 pb-4 pt-4">
        <div className="mb-5">
          {t('tuitionContinuityProcessManagement.selectFilters')}
        </div>
        <Row>
          <Col xs={12} md={4} lg={4} className="pb-3">
            <OutlinedSelect
              name="period"
              label={t('packages.period')}
              options={optionsPeriods}
              value={period}
              onChange={(v) => {
                setPeriod(v);
              }}
              isClearable={false}
            />
          </Col>

          <Col xs={12} md={4} lg={4}>
            <OutlinedSelect
              name="campus"
              label={t(`${dictPrefix}.campus`)}
              placeholder="Todas las sedes"
              options={optionsCampuses}
              value={campus}
              onChange={(v) => {
                setCampus(v);
              }}
              isClearable={true}
            />
          </Col>
          {children && children(periodId, campusId)}
        </Row>
      </div>
    </div>
  );
};
