import { CheckInput } from '@octano/global-ui';
import { useCallback } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';

import { PathsLayouts } from '../../../../../../../config/routes';
import { useParameters } from '../../../../../../../hooks/useParameters';
import { getSelectedOption } from '../../../../../../../utils/selectFormat';
import useGetRutOrPassport from '../../hooks/useGetRutOrPassport';
import { onSearch } from '../../types';
import SearchUserForm from './SearchUserForm';
import { SearchUserFormByPassport } from './SearchUserFormByPassport';

interface SearchControlsProps {
  prefix: string;
}

const SearchControls = ({ prefix }: SearchControlsProps) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { countryOptions } = useParameters();

  const { showPassport: hasPassport, queryParams } = useGetRutOrPassport();
  const { control, watch } = useForm<{
    showPassport: boolean;
  }>({
    defaultValues: {
      showPassport: hasPassport,
    },
  });

  const showPassport = watch('showPassport');

  const onSubmit = useCallback(
    async (data: onSearch) => {
      const queryID = showPassport
        ? `${data.country}|${data.passport}`
        : data.rut;
      history.replace(`${PathsLayouts.usersAndRoles}/users/create/${queryID}`);
    },
    [showPassport, history],
  );
  return (
    <>
      <div className="mb-n2">
        {!showPassport ? (
          <SearchUserForm
            prefix={`${prefix}.searchControls`}
            defaultValues={{ rut: queryParams?.rut ?? '' }}
            onSubmit={onSubmit}
          />
        ) : (
          <SearchUserFormByPassport
            prefix={`${prefix}.searchControls`}
            defaultValues={{
              passport: queryParams?.passport ?? '',
              country: getSelectedOption(
                queryParams?.country ?? '',
                countryOptions,
              ),
            }}
            onSubmit={onSubmit}
          />
        )}
      </div>
      <CheckInput
        name="showPassport"
        control={control}
        label={t(`${prefix}.searchControls.checkPassport`)}
      />
    </>
  );
};

export default SearchControls;
