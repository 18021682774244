import { USERS_AND_ROLES } from '../../../../../../api/endpoints';
import request from '../../../../../../api/request';
import {
  CreateAdminAccountBody,
  SearchAdminAccount,
  UpdateAdminAccountBody,
} from '../types';

export const searchByRut = (rut: string) => {
  let url = USERS_AND_ROLES.GET_ADMIN_ACCOUNT_BY_RUT(rut);
  return request<SearchAdminAccount>(url);
};

export const searchByPassport = (country: string, passportNumber: string) => {
  let url = USERS_AND_ROLES.GET_ADMIN_ACCOUNT(country, passportNumber);
  return request<SearchAdminAccount>(url);
};

export const createAdminAccount = (data: CreateAdminAccountBody) => {
  let url = USERS_AND_ROLES.POST_CREATE_ADMIN_ACCOUNT;
  return request<{ data: { id: number } }>(url, {
    method: 'POST',
    data,
  });
};

export const updateAdminAccount = (
  id: number,
  data: UpdateAdminAccountBody,
) => {
  let url = USERS_AND_ROLES.UPDATE_ADMIN_ACCOUNT(id);
  return request<SearchAdminAccount>(url, {
    method: 'PUT',
    data,
  });
};

export const deleteAdminAccount = (id: number) => {
  let url = USERS_AND_ROLES.PUT_ADMIN_ACCOUNT_STATUS(id);
  return request<SearchAdminAccount>(url, {
    method: 'PUT',
  });
};

export const enableAdminAccount = (id: number) => {
  let url = USERS_AND_ROLES.PUT_ADMIN_ACCOUNT_STATUS(id);
  return request<SearchAdminAccount>(url, {
    method: 'PUT',
  });
};
