export function getColor(state?: string) {
  const colors: Record<string, string> = {
    A: '#adf7a8',
    P: '#abcce9',
    R: '#ffa19d',
    V: '#ee9ef8',
    E: '#fff784',
  };
  if (!state) {
    return undefined;
  }
  return colors[state] ?? undefined;
}
