import { Box, useMobile } from '@octano/global-ui';
import { useTranslation } from 'react-i18next';

export interface Props {
  shortening: string;
  sectionName: string;
  courseName: string;
}

export default function SectionInfoBox({
  shortening,
  sectionName,
  courseName,
}: Props) {
  const isMobile = useMobile();
  const { t } = useTranslation();
  const prefix = `courseRegistrationList.detail.modalSectionChange`;

  return (
    <Box
      color="primary"
      variant="outlined"
      style={{
        borderRadius: 4,
        width: '100%',
      }}
      className="py-2 px-3"
      body={
        <div
          style={{ gap: 12 }}
          className={`d-flex justify-content-between py-2 ${
            isMobile ? 'flex-wrap' : ''
          }`}
        >
          <Box
            color="primary"
            variant="outlined"
            className="p-0 border-0 "
            title={t(`${prefix}.infoBox.shortening`)}
            body={<p className="fs-14 mb-0">{shortening}</p>}
            style={{ minWidth: 'fit-content' }}
          />
          <Box
            color="primary"
            variant="outlined"
            className="p-0 border-0"
            title={t(`${prefix}.infoBox.sectionName`)}
            body={<p className="fs-14 mb-0">{sectionName}</p>}
            style={{ minWidth: 'fit-content' }}
          />
          <Box
            color="primary"
            variant="outlined"
            className="p-0 border-0"
            title={t(t(`${prefix}.infoBox.courseName`))}
            body={<p className="fs-14 mb-0">{courseName}</p>}
            style={{ minWidth: 'fit-content' }}
          />
        </div>
      }
    />
  );
}
