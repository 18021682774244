import { Select, SelectOptionType } from '@octano/global-ui';
import { useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { Col, Row } from 'reactstrap';
import { useTexts } from '../../hooks/textsContext';
import { useGradingStructureFormLoader } from '../Loaders/GradingStructureFormLoader';

const fields = {
  structureTypeId: 'structureTypeId',
};

const UpdateStructureTypeFormFields = () => {
  const texts = useTexts();

  const { data } = useGradingStructureFormLoader();
  const { control } = useFormContext();

  const structureType = useMemo(
    () =>
      data?.structureType?.map((item: any) => ({
        value: item.id,
        label: item.name,
      })) || [],
    [data?.structureType],
  ) as SelectOptionType[];

  return (
    <Row>
      <Col md={4}>
        <Select
          label={texts.form.structureTypeId}
          name={`${fields.structureTypeId}`}
          isSearchable={true}
          control={control}
          options={structureType}
        />
      </Col>
    </Row>
  );
};

export default UpdateStructureTypeFormFields;
