import GoBackToListButton from '../../../../../../components/buttons/GoBackToListButton';
import TeacherDetailBox from './TeacherDetailBox';

const HeaderInfo = () => {
  return (
    <div className="d-flex align-items-center justify-content-between mb-4">
      <GoBackToListButton />
      <TeacherDetailBox />
    </div>
  );
};

export default HeaderInfo;
