import {
  Button,
  OutlinedSelect,
  SearchBox,
  SelectOptionType,
  useMobile,
} from '@octano/global-ui';
import { useCallback, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Col, Form, Row } from 'reactstrap';

import { useFetch } from '../../../components/contexts/FetchContext';
import {
  CourseValidationInternalStudents,
  CourseValidationInternalStudentsQuery,
} from '../types';

export interface SearchFiltersType {
  studyPlanVersionId: SelectOptionType | null;
  searchText: string | null;
  periodId: SelectOptionType | null;
  campusId: SelectOptionType | null;
}

interface SearchFiltersProps {
  studyPlanVersions: { id: number; name: string }[];
  periods: { id: number; name: string }[];
  campuses: { id: number; name: string }[];
}

const SearchFilters = ({
  studyPlanVersions,
  periods,
  campuses,
}: SearchFiltersProps) => {
  const prefix = 'courseValidationInternal.filters';
  const { t } = useTranslation();
  const texts = useMemo(
    () => ({
      label: {
        studyPlanVersionId: t(`${prefix}.label.studyPlanVersionId`),
        searchText: t(`${prefix}.label.searchText`),
        periodId: t(`${prefix}.label.periodId`),
        campusId: t(`${prefix}.label.campusId`),
      },
      placeholder: {
        studyPlanVersionId: t(`${prefix}.placeholder.studyPlanVersionId`),
        searchText: t(`${prefix}.placeholder.searchText`),
        periodId: t(`${prefix}.placeholder.periodId`),
        campusId: t(`${prefix}.placeholder.campusId`),
      },
      action: {
        search: t(`${prefix}.action.search`),
        clean: t(`${prefix}.action.clean`),
      },
    }),
    [t],
  );

  const { updateQuery } = useFetch<
    CourseValidationInternalStudentsQuery,
    CourseValidationInternalStudents
  >();
  const { control, handleSubmit, setValue, reset } = useForm<SearchFiltersType>(
    {
      defaultValues: {
        studyPlanVersionId: null,
        searchText: null,
        periodId: null,
        campusId: null,
      },
    },
  );
  const isMobile = useMobile();

  const onSubmit = useCallback(
    (values: SearchFiltersType) => {
      updateQuery({
        search: {
          studyPlanVersionId:
            values.studyPlanVersionId &&
            typeof values.studyPlanVersionId.value === 'number'
              ? values.studyPlanVersionId.value
              : undefined,
          searchText: values.searchText ?? undefined,
          periodId:
            values.periodId && typeof values.periodId.value === 'number'
              ? values.periodId.value
              : undefined,
          campusId:
            values.campusId && typeof values.campusId.value === 'number'
              ? values.campusId.value
              : undefined,
        },
        page: 1,
      });
    },
    [updateQuery],
  );

  const onClear = useCallback(() => {
    reset();
    updateQuery();
  }, [reset, updateQuery]);

  const studyPlanVersionsOptions = useMemo<SelectOptionType[]>(() => {
    return studyPlanVersions.map((s) => ({
      value: s.id,
      label: s.name,
    }));
  }, [studyPlanVersions]);

  const periodsOptions = useMemo<SelectOptionType[]>(() => {
    return periods.map((p) => {
      return {
        value: p.id,
        label: p.name,
      };
    });
  }, [periods]);

  const campusesOptions = useMemo<SelectOptionType[]>(() => {
    return campuses.map((p) => {
      return {
        value: p.id,
        label: p.name,
      };
    });
  }, [campuses]);

  const clearNameOrId = useCallback(() => setValue('searchText', ''), [
    setValue,
  ]);

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Row className="pb-4 gutters-filters">
        <Col xs={12} md={6} lg={4}>
          <OutlinedSelect
            name="studyPlanVersionId"
            options={studyPlanVersionsOptions}
            label={texts.label.studyPlanVersionId}
            placeholder={texts.placeholder.studyPlanVersionId}
            control={control}
            isClearable
          />
        </Col>
        <Col xs={12} md={6} lg={4}>
          <OutlinedSelect
            name="campusId"
            options={campusesOptions}
            label={texts.label.campusId}
            placeholder={texts.placeholder.campusId}
            control={control}
            isClearable
          />
        </Col>
        <Col xs={12} md={6} lg={4}>
          <SearchBox
            name="searchText"
            label={texts.label.searchText}
            placeholder={texts.placeholder.searchText}
            control={control}
            clear={clearNameOrId}
          />
        </Col>

        <Col xs={12} md={6} lg={4}>
          <OutlinedSelect
            name="periodId"
            options={periodsOptions}
            label={texts.label.periodId}
            placeholder={texts.placeholder.periodId}
            control={control}
            isClearable
          />
        </Col>

        <Col
          xs={12}
          md={{ size: 2, offset: 8 }}
          lg={{ size: 2, offset: 8 }}
          style={{ marginTop: isMobile ? 0 : '1.5rem' }}
        >
          <Button
            type="submit"
            text={texts.action.search}
            size="md"
            fullwidth
          />
        </Col>
        <Col
          xs={12}
          md={{ size: 2 }}
          lg={2}
          style={{ marginTop: isMobile ? 0 : '1.5rem' }}
        >
          <Button
            text={texts.action.clean}
            size="md"
            outlined
            onClick={onClear}
            fullwidth
          />
        </Col>
      </Row>
    </Form>
  );
};

export default SearchFilters;
