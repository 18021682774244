import { Button } from '@octano/global-ui';
import { useTranslation } from 'react-i18next';

type CreateAssistantButtonProps = {
  onClick: () => void;
};

const CreateAssistantButton = ({ onClick }: CreateAssistantButtonProps) => {
  const { t } = useTranslation();
  const prefix = 'sectionsMaintainer.update.createAsistantForm';

  return (
    <>
      <Button
        type="button"
        outlined
        size="sm"
        onClick={onClick}
        text={t(`${prefix}.createAssistant`)}
        fullwidth
        icon="plus"
        className="mb-4"
      />
    </>
  );
};

export default CreateAssistantButton;
