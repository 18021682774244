import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Card, Container, Row } from 'reactstrap';

import GoBackToListButton from '../../../components/buttons/GoBackToListButton';
import DisplayError from '../../../components/info/DisplayError';
import { PathsLayouts } from '../../../config/routes';
import useUserState from '../../../hooks/useUserState';
import { PermissionName } from '../../../types/Auth';
import PostulantLoader from './parts/PostulantLoader';
import PostulantUpdateForm from './parts/PostulantUpdateForm';

const UpdateScreen = () => {
  const { t } = useTranslation();
  const { isAuthorizedTo } = useUserState();
  const history = useHistory();

  const hasPermission = useMemo(
    () =>
      isAuthorizedTo([PermissionName.STUDENT_MANAGEMENT_STUDENT_FILE], true),
    [isAuthorizedTo],
  );

  return (
    <Container fluid>
      <Card className="p-4 mt-2 mx-3">
        <Row className="p-3">
          <GoBackToListButton path={PathsLayouts.studentFile} />
        </Row>
        {hasPermission ? (
          <PostulantLoader>
            {(props) => (
              <>
                <PostulantUpdateForm
                  prefix="tuitionProcess.onSite"
                  postulantId={props?.postulant?.id}
                  defaultValues={props?.defaultValues}
                  photo={props?.photo}
                  onError={() => {}}
                />
              </>
            )}
          </PostulantLoader>
        ) : (
          <DisplayError
            insideCard
            textBody={t('common.errors.permissions')}
            buttonText={t('common.actions.back')}
            retryAction={() => history?.goBack()}
          />
        )}
      </Card>
    </Container>
  );
};

export default UpdateScreen;
