import {
  Button,
  OutlinedSelect,
  SearchBox,
  useMobile,
} from '@octano/global-ui';
import { useCallback, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Col, Form, Row } from 'reactstrap';

import { DocumentationReviewStatus } from '../../../api/requests/studyPlansOffer';
import { usePostulationDocumentsLoader } from '../contexts/PostulationDocumentsLoader';

export interface SearchPostulantProccessByStatusForm {
  searchText: string;
  status: { value: DocumentationReviewStatus; label: string } | null;
}

export function SearchFormPostulantProccessByStatus(props: {
  onSearch: (
    params: {
      searchText?: string;
      status: DocumentationReviewStatus | null;
    } | null,
  ) => void;
}) {
  const { onSearch } = props;
  const { t } = useTranslation();

  const defaultValues = useMemo(() => {
    return {
      status: {
        value: DocumentationReviewStatus.NOT_REVIEWED,
        label: t('documents.notReview'),
      },
      country: undefined,
      passport: undefined,
      rut: undefined,
      isActiveSearchByPassport: false,
    };
  }, [t]);

  const {
    control,
    handleSubmit,
    reset,
    setValue,
  } = useForm<SearchPostulantProccessByStatusForm>({
    mode: 'all',
    defaultValues,
  });

  const isMobile = useMobile();

  const { updateQuery } = usePostulationDocumentsLoader();

  const onSubmit = useCallback(
    async (params: {
      status: { value: DocumentationReviewStatus; label: string } | null;
      searchText?: string;
    }) => {
      onSearch({
        searchText: params.searchText,
        status: params.status ? params.status.value : null,
      });
    },
    [onSearch],
  );

  const options = useMemo(() => {
    return [
      {
        value: DocumentationReviewStatus.NOT_REVIEWED,
        label: t('documents.notReview'),
      },
      {
        value: DocumentationReviewStatus.WAITING,
        label: t('documents.inWaiting'),
      },
      {
        value: DocumentationReviewStatus.APPROVED,
        label: t('documents.approved'),
      },
    ];
  }, [t]);

  const clearNameOrId = useCallback(() => setValue('searchText', ''), [
    setValue,
  ]);

  const onClear = useCallback(() => {
    reset();
    updateQuery();
  }, [reset, updateQuery]);

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Row className="pb-4 gutters-filters">
        <Col xs={12} sm={6} md={4}>
          <SearchBox
            name="searchText"
            label={t('documents.searchStudent.inputSearchText')}
            placeholder={t('documents.searchStudent.inputSearchText')}
            control={control}
            clear={clearNameOrId}
          />
        </Col>
        <Col xs={12} sm={6} md={4}>
          <OutlinedSelect
            label={t('documents.searchStudent.inputStatus')}
            name="status"
            isClearable={true}
            options={options}
            control={control}
          />
        </Col>
        <Col
          xs={12}
          md={{ size: 2, offset: 8 }}
          lg={{ size: 2, offset: 8 }}
          style={{ marginTop: isMobile ? 0 : '0.5rem' }}
        >
          <Button
            type="submit"
            text={t('common.actions.search')}
            size="md"
            fullwidth
          />
        </Col>
        <Col
          xs={12}
          md={{ size: 2 }}
          lg={2}
          style={{ marginTop: isMobile ? 0 : '0.5rem' }}
        >
          <Button
            text={t('common.actions.clean')}
            size="md"
            outlined
            onClick={onClear}
            fullwidth
          />
        </Col>
      </Row>
    </Form>
  );
}
