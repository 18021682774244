import {
  Credit,
  MissingOptionalCourses,
} from '../../../types/graduationProcessSingle';

export function mapCredits({
  obtained,
  required,
}: {
  obtained: Credit;
  required: Credit;
}) {
  const keys = ['obligatory', 'optional', 'others'];

  const credits = keys.map((key) => ({
    text: key,
    required: required[key as keyof Credit],
    obtained: obtained[key as keyof Credit],
    remaining: required[key as keyof Credit] - obtained[key as keyof Credit],
  }));

  const totals = credits.reduce((acc, obj) => {
    return {
      text: 'total',
      required: acc.required + obj.required,
      obtained: acc.obtained + obj.obtained,
      remaining: acc.remaining + obj.remaining,
    };
  });
  return [...credits, ...[totals]];
}

export function mapOptionalCredits(optionalsCredits: MissingOptionalCourses) {
  const credits = Object.entries(optionalsCredits).map(([_, value]) => value);
  if (credits.length === 0) return credits;
  const total = credits.reduce(
    (acc, obj) => {
      return {
        name: 'Total Créditos',
        credits: acc.credits + obj.credits,
        isTotalRow: true,
      };
    },
    { name: 'Total Créditos', credits: 0, isTotalRow: true },
  );

  return [...credits, ...[total]];
}
