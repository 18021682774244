import { ColumnTable, Icon } from '@octano/global-ui';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { PathsLayouts } from '../../../../config/routes';

import { CourseMaintainerListType } from '../../../../types/courseMaintainerTypes';
import { formatDate } from '../../../../utils/dates';

export const useColumnsCoursesList = () => {
  const prefix = 'courses.list.columns';
  const { t } = useTranslation();
  const history = useHistory();

  const watchCourse = useCallback(
    (courseId: number) => {
      history.push(
        `${PathsLayouts.academicOffers}/curricular-subjects/watch/${courseId}`,
      );
    },
    [history],
  );

  const updateCourse = useCallback(
    (courseId: number) => {
      history.push(
        `${PathsLayouts.academicOffers}/curricular-subjects/update/${courseId}`,
      );
    },
    [history],
  );

  const columns: ColumnTable<CourseMaintainerListType>[] = useMemo(() => {
    return [
      {
        columnName: 'shortening',
        width: '10%',
        thClassName: 'text-center',
        tdClassName: 'text-center text-nowrap',
        headerText: t(`${prefix}.code`),
        cellFormat: ({ row }) => row.shortening,
      },
      {
        columnName: 'name',
        width: '25%',
        thClassName: 'text-center',
        tdClassName: 'text-center',
        headerText: t(`${prefix}.name`),
      },
      {
        columnName: 'school',
        width: '25%',
        thClassName: 'text-center',
        tdClassName: 'text-center',
        headerText: t(`${prefix}.school`),
        cellFormat: ({ row }) => row.school.name,
      },
      {
        columnName: 'credits',
        width: '15%',
        thClassName: 'text-center',
        tdClassName: 'text-center',
        headerText: t(`${prefix}.credits`),
        cellFormat: ({ row }) => row.credits,
      },
      {
        columnName: 'createdAt',
        width: '15%',
        thClassName: 'text-center',
        tdClassName: 'text-center',
        headerText: t(`${prefix}.createdAt`),
        cellFormat: ({ row }) => formatDate(row.createdAt, 'DD-MM-YYYY'),
      },
      {
        columnName: 'createdAt',
        width: '10%',
        thClassName: 'text-center',
        tdClassName: 'text-center',
        headerText: t(`common.actions.action`),
        cellFormat: ({ row }) => {
          return (
            <div className="d-flex d-flex justify-content-center">
              <div
                className="cursor-pointer mr-2"
                onClick={() => {
                  watchCourse(row.id);
                }}
              >
                <Icon size="15" name="eye" />
              </div>
              <div
                className="cursor-pointer"
                onClick={() => {
                  updateCourse(row.id);
                }}
              >
                <Icon size="15" name="edit" />
              </div>
            </div>
          );
        },
      },
    ];
  }, [t, watchCourse, updateCourse]);

  return columns;
};
