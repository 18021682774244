import { Button } from '@octano/global-ui';
import { Trans } from 'react-i18next';
import { useHistory } from 'react-router';
import { Col, Row } from 'reactstrap';

import { ReactComponent as CompletedImage } from '../../../../../../assets/svg/completed_image.svg';
import { CompletedStepResponse } from '../../../../../../types/completedTypes';
import PaymentDetails from '../payment/parts/PaymentOnSite/PaymentDetails';

const CompletedSoftland = ({
  title,
  body,
  newSearchText,
  completedData,
  studyPlanNameByStatus,
}: {
  title: string;
  body: string;
  newSearchText: string;
  completedData: CompletedStepResponse;
  studyPlanNameByStatus: string;
}) => {
  const history = useHistory();

  return (
    <Col xs={12}>
      <Row>
        <Col xs={12} sm={12} md={12} lg={6} xl={4}>
          <PaymentDetails
            postulant={{
              name: completedData?.student?.name ?? '',
              rut: completedData?.student?.rut ?? '',
              passport: completedData?.student?.passport ?? '',
            }}
            sustainer={completedData?.sustainer}
            program={studyPlanNameByStatus}
            operation={completedData?.operation ?? 0}
            transactions={completedData?.transactions ?? []}
          />
        </Col>
        <Col xs={12} sm={12} md={12} lg={6} xl={8}>
          <div
            className="d-flex py-4 flex-column align-items-center m-auto"
            style={{ maxWidth: '694px', gap: 24 }}
          >
            <CompletedImage width="230px" />
            <span className="text-center fs-20 text-dark">{title}</span>
            <span className="text-center fs-18 fw-300">
              <Trans i18nKey={body} />
            </span>
          </div>
          <Row className="pt-3 pb-5 justify-content-end">
            <Col xs={12}>
              <Button
                className="mx-auto"
                style={{ maxWidth: 694 }}
                text={newSearchText}
                onClick={() => history.replace('/tuition-process')}
                fullwidth
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </Col>
  );
};

export default CompletedSoftland;
