import { Button, addToast } from '@octano/global-ui';
import { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Card, Col, Container, Row } from 'reactstrap';

import GoBackButton from '../../../../components/buttons/GoBackButton';
import { PathsLayouts } from '../../../../config/routes';
import { useLayoutState } from '../../../../hooks/useLayoutState';
import { saveStudyPlanRequest } from '../api/study-plan';
import StudyPlanForm from './StudyPlanForm';
import { StudyPlanRecordLoader } from './StudyPlanRecordLoader';

const StudyPlanRecordCreate = () => {
  const { hideTabs, hideErrorModal } = useLayoutState();
  useEffect(() => {
    hideTabs();
    hideErrorModal();
  }, [hideTabs, hideErrorModal]);

  const history = useHistory();

  const goBack = useCallback(() => {
    history.push(`${PathsLayouts.academicOffers}/study-plans`);
  }, [history]);

  const prefix = 'studyPlans.form';
  const { t } = useTranslation();

  const onSubmit = useCallback(
    async (values) => {
      const requestBody = {
        name: values.name,
        code: values.code,
        modalityId: values.modality.value,
        schoolId: values.school.value,
        periodTypeId: values.periodType.value,
        academicLevelId: values.academicLevel.value,
      };
      const response = await saveStudyPlanRequest(requestBody);
      if (!response.error) {
        addToast({
          icon: 'success',
          color: 'success',
          text: t(`${prefix}.createSuccess`),
          className: 'long-toast',
        });
        const { id } = response.data;
        history.push(`${PathsLayouts.academicOffers}/study-plans/record/${id}`);
      } else {
        return response.error.code;
      }
    },
    [history, t],
  );

  return (
    <Container>
      <Card className="p-3 p-md-4">
        <div className="mb-4">
          <GoBackButton onClick={goBack} />
        </div>
        <StudyPlanRecordLoader>
          {({ modalities, schools, periodTypes, academicLevels }) => {
            return (
              <StudyPlanForm
                modalities={modalities}
                periodTypes={periodTypes}
                schools={schools}
                academicLevels={academicLevels}
                onSubmit={onSubmit}
              >
                {({ isSubmitting }) => (
                  <Row className="pt-5">
                    <Col
                      xs={{ order: 2, size: 12 }}
                      md={{ order: 1, size: 4 }}
                      className="ml-auto"
                    >
                      <div>
                        <Button
                          type="button"
                          text={t(`common.actions.cancel`)}
                          outlined
                          onClick={() => goBack()}
                          fullwidth
                        />
                      </div>
                    </Col>

                    <Col xs={{ order: 1, size: 12 }} md={{ order: 2, size: 4 }}>
                      <div className="pb-3 pb-md-0">
                        <Button
                          type="submit"
                          text={t(`${prefix}.create`)}
                          loading={isSubmitting}
                          fullwidth
                        />
                      </div>
                    </Col>
                  </Row>
                )}
              </StudyPlanForm>
            );
          }}
        </StudyPlanRecordLoader>
      </Card>
    </Container>
  );
};

export default StudyPlanRecordCreate;
