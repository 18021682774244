import { generatePath } from 'react-router-dom';
import request from '../../../../api/request';
import { CurriculumInfoBody } from '../../../../api/requests/studyPlans';
import { CurriculumsResponse } from '../../../../types/CurriculumTypes';
import { CreateActivityParams } from '../curriculum/parts/CurriculumDegreeActivities/types';
import {
  ResponseGetCurriculumDegreeActivity,
  ResponseGetDegreeActivityTypes,
  ResponsePostCreateDegreeActivity,
} from '../curriculum/parts/CurriculumDegreeActivities/types/response';

/**
 * CURRICULUM
 */
const STUDY_PLAN_CURRICULUMS = {
  LIST: 'maintainers-portal/academic-offer/curriculums',
  CREATE: 'maintainers-portal/academic-offer/curriculums',
  GET: (curriculumId: string) =>
    `maintainers-portal/academic-offer/curriculums/${curriculumId}`,
  PUT: (curriculumId: string) =>
    `maintainers-portal/academic-offer/curriculums/${curriculumId}`,
  GET_CURRICULUM_INFO: (studyPlanId: number, versionId: number) =>
    `/maintainers-portal/academic-offer/curriculums/${studyPlanId}/${versionId}`,

  //activities
  GET_DEGREE_ACTIVITY_TYPES:
    '/maintainers-portal/academic-offer/curriculums/activity-types',
  CREATE_DEGREE_ACTIVITY_BY_CURRICULUM:
    '/maintainers-portal/academic-offer/curriculums/activity-types',
  GET_DEGREE_ACTIVITY_BY_CURRICULUM: (curriculumId: number | string) =>
    generatePath(
      '/maintainers-portal/academic-offer/curriculums/:curriculumId/activity',
      { curriculumId },
    ),
  COURSES_PER_TYPES: (courseTypeId: number) =>
    `/maintainers-portal/academic-offer/study-plans/curricular-subjects/type/${courseTypeId}`,
};

export type CourseType = {
  id: number;
  name: string;
  code: string;
  shortening?: string;
  credits: number;
};

export const requestAvailableCourses = (courseTypeId: number) => {
  return request(STUDY_PLAN_CURRICULUMS.COURSES_PER_TYPES(courseTypeId), {
    method: 'get',
  });
};

export const getCurriculumsByVersionRequest = (
  versionId: number,
  items: number = 10,
  page: number = 0,
) => {
  const url = `${STUDY_PLAN_CURRICULUMS.LIST}/study-plan-versions/${versionId}?items_per_page=${items}&page=${page}`;
  return request<{ data: CurriculumsResponse }>(url);
};

export const createCurriculumRequest = (data: any) => {
  return request(STUDY_PLAN_CURRICULUMS.CREATE, {
    method: 'post',
    data,
  });
};

export const getCurriculumRequest = (curriculumId: string) => {
  return request(STUDY_PLAN_CURRICULUMS.GET(curriculumId));
};

export const editCurriculumRequest = (data: any, curriculumId: string = '') => {
  return request(STUDY_PLAN_CURRICULUMS.PUT(curriculumId), {
    method: 'put',
    data,
  });
};

export const editCurriculumMentionStatus = (
  curriculumId: number,
  status: string,
) => {
  const url = `${STUDY_PLAN_CURRICULUMS.LIST}/change-mention-status`;
  return request<any>(url, {
    method: 'put',
    data: {
      curriculumId,
      status,
    },
  });
};

/**
 * Obtiene info de plan de estudios y version
 */
export const getCurriculumInfoByStudyPlan = (
  studyPlanId: number,
  versionId: number,
) => {
  const url = `${STUDY_PLAN_CURRICULUMS.GET_CURRICULUM_INFO(
    studyPlanId,
    versionId,
  )}`;
  return request<{ data: CurriculumInfoBody }>(url);
};

//activities

/**
 * Obtiene el listado de los DegreeActivityTypes a mostrar para un curriculum a editar
 * @returns
 */
export const getDegreeActivityTypes = () => {
  const URL = STUDY_PLAN_CURRICULUMS.GET_DEGREE_ACTIVITY_TYPES;
  const method = 'GET';
  return request<ResponseGetDegreeActivityTypes>(URL, { method });
};

/**
 * Obtiene el listado de los DegreeActivities a mostrar para un curriculum a editar
 * @param items
 * @param page
 * @returns
 */
export const getDegreeActivityByCurriculum = (
  curriculumId: number | string,
  filter: {
    items: number;
    page: number;
  },
) => {
  const URL = STUDY_PLAN_CURRICULUMS.GET_DEGREE_ACTIVITY_BY_CURRICULUM(
    curriculumId,
  );
  const params = { ...filter, items_per_page: filter.items };
  const method = 'GET';
  return request<ResponseGetCurriculumDegreeActivity>(URL, { method, params });
};

/**
 * Crea un DegreeActivityType para un curriculum a editar
 * @returns
 */
export const postCreateDegreeActivity = (data: CreateActivityParams) => {
  return request<ResponsePostCreateDegreeActivity>(
    STUDY_PLAN_CURRICULUMS.CREATE_DEGREE_ACTIVITY_BY_CURRICULUM,
    {
      method: 'POST',
      data,
    },
  );
};
