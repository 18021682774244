import { STUDENT_COURSE_VALIDATION } from '../../api/endpoints';
import request from '../../api/request';
import {
  CourseValidationInternalStudyPlanEnrollmentBase,
  CourseValidationInternalStudyPlanEnrollmentCurriculum,
} from './types';

export function getCourseValidationInternalStudyPlanEnrollment(
  studyPlanEnrollmentId: number | string,
) {
  const url = STUDENT_COURSE_VALIDATION.INTERNAL.STUDY_PLAN_ENROLLMENT(
    studyPlanEnrollmentId,
  );
  return request<CourseValidationInternalStudyPlanEnrollmentBase>(url, {
    method: 'get',
  });
}

export async function getCourseValidationInternalStudyPlanEnrollmentCurriculum(
  studyPlanEnrollmentId: number | string,
) {
  const url = STUDENT_COURSE_VALIDATION.INTERNAL.CURRICULUM(
    studyPlanEnrollmentId,
  );
  return request<CourseValidationInternalStudyPlanEnrollmentCurriculum>(url, {
    method: 'get',
  });
}

export async function saveCourseValidationInternal(
  studyPlanEnrollmentId: number | string,
  data: {
    // El curso que se usará para convalidar
    courseEnrollmentId: number;
    // El curso que se va a convalidar
    semesterCourseId: number;
  },
) {
  const url = STUDENT_COURSE_VALIDATION.INTERNAL.VALIDATE(
    studyPlanEnrollmentId,
  );
  return request(url, {
    method: 'post',
    data,
  });
}
