import {
  Button,
  CheckInput,
  OutlinedSelectOptionType,
  TextAreaInput,
  TextInput,
} from '@octano/global-ui';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Col, Form, Row } from 'reactstrap';

import { OutlinedCreateableSelect } from '../../../../../../../../components/form/select/OutlinedCreateableSelect';
import { useValidations } from '../../../../../../../../hooks/useValidations';
import { isNumber } from '../../../../../../../../utils/math';
import { getDegreeActivityTypes } from '../../../../../api/study-plan-curriculums';
import { CreateActivityDefaultParams, CreateActivityParams } from '../../types';
import {
  DegreeActivityType,
  ResponseGetDegreeActivityTypes,
} from '../../types/response';

interface CreateActivityModalFormProps {
  texts: any;
  onSave: (formParams: CreateActivityParams) => Promise<void>;
  onCancel: () => void;
}

const findCurrentActivityType = (
  activityTypes: DegreeActivityType[],
  activityTypeId: number,
): DegreeActivityType => {
  const current: DegreeActivityType = activityTypes.find(
    (activityType: DegreeActivityType) => activityType.id === activityTypeId,
  ) as DegreeActivityType;
  return current;
};

const CreateActivityModalForm = ({
  texts,
  onSave,
  onCancel,
}: CreateActivityModalFormProps) => {
  const [
    activityTypesObj,
    setActivityTypesObj,
  ] = useState<ResponseGetDegreeActivityTypes>([]);
  const [activityTypes, setActivityTypes] = useState<
    OutlinedSelectOptionType[]
  >([]);
  const [disableFormFields, setDisableFormFields] = useState<
    boolean | undefined
  >(true);

  const getDefaultValues: CreateActivityDefaultParams = useMemo(() => {
    return {
      activityName: null,
      activityCode: '',
      activityDescription: '',
      activityAllowGrades: false,
    };
  }, []);

  const methods = useForm<CreateActivityDefaultParams>({
    mode: 'onChange',
    defaultValues: getDefaultValues,
    shouldUnregister: false,
  });

  const {
    handleSubmit,
    formState: { isSubmitting },
    control,
    reset,
    setValue,
    watch,
  } = methods;

  const [activityNameWatch] = watch(['activityName']);

  const { msgValidations } = useValidations();

  const onSubmit = useCallback(
    async (formValues: CreateActivityDefaultParams) => {
      let {
        activityName,
        activityCode,
        activityDescription,
        activityAllowGrades,
      } = formValues;

      const formParams = {
        id:
          activityName && isNumber(activityName?.value)
            ? Number(activityName.value)
            : undefined,
        name: activityName ? String(activityName.label) : undefined,
        code: activityCode ? String(activityCode) : undefined,
        description: activityDescription
          ? String(activityDescription)
          : undefined,
        allowGrades: activityAllowGrades,
      };
      await onSave(formParams);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [onSave],
  );

  useEffect(() => {
    if (!activityNameWatch) return;
    if (!activityNameWatch?.__isNew__) {
      const currentActivityType = findCurrentActivityType(
        activityTypesObj,
        Number(activityNameWatch.value),
      );
      setValue('activityCode', currentActivityType.code);
      setValue('activityDescription', currentActivityType.description);
      setValue('activityAllowGrades', currentActivityType.allowGrades);
      !disableFormFields && setDisableFormFields(true);
      return;
    }
    setDisableFormFields(false);
    setValue('activityCode', '');
    setValue('activityDescription', '');
    setValue('activityAllowGrades', false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activityNameWatch, setValue]);

  const getFilters = useCallback(async () => {
    const { data } = await getDegreeActivityTypes();
    if (data) {
      setActivityTypes(
        data.map(({ id: value, name: label, code }) => ({
          label: `${label} ${code}`,
          value,
        })),
      );
      setActivityTypesObj(data);
    }
  }, []);

  useEffect(() => {
    getFilters();
  }, [getFilters]);

  const handleClear = () => {
    reset(getDefaultValues);
  };

  return (
    <FormProvider {...methods}>
      <Form>
        <Row>
          <Col>
            <Row className="mt-0 mt-md-3">
              <Col xs="12" md="12">
                <OutlinedCreateableSelect
                  name="activityName"
                  label={texts.modal.activityName}
                  placeholder={texts.modal.activityName}
                  control={control}
                  options={activityTypes}
                  rules={{
                    required: msgValidations.required,
                  }}
                />
              </Col>
            </Row>

            <Row className="mt-0 mt-md-3">
              <Col xs="12" md="12">
                <TextInput
                  name="activityCode"
                  control={control}
                  label={texts.modal.activityCode}
                  readOnly={disableFormFields}
                  rules={{
                    required: msgValidations.required,
                  }}
                />
              </Col>
            </Row>

            <Row className="mt-0 mt-md-3">
              <Col xs="12" md="12">
                <TextAreaInput
                  name="activityDescription"
                  control={control}
                  label={texts.modal.activityDescription}
                  readOnly={disableFormFields}
                  rules={{
                    required: msgValidations.required,
                  }}
                />
              </Col>
            </Row>

            <Row className="mt-0 mt-md-3">
              <Col xs="12" md="12">
                <CheckInput
                  name="activityAllowGrades"
                  label={texts.modal.activityAllowGrades}
                  control={control}
                  readOnly={disableFormFields}
                  disabled={disableFormFields}
                />
              </Col>
            </Row>
          </Col>
        </Row>

        <Row>
          <Col className="mt-5">
            <Row>
              <Col xs={{ size: 12, order: 2 }} md={{ size: 6, order: 1 }}>
                <Button
                  type="button"
                  text={texts.action.cancel}
                  outlined
                  onClick={() => {
                    onCancel();
                    handleClear();
                  }}
                  fullwidth
                />
              </Col>
              <Col xs={{ size: 12, order: 1 }} md={{ size: 6, order: 2 }}>
                <Button
                  type="button"
                  text={texts.action.send}
                  loading={isSubmitting}
                  fullwidth
                  onClick={handleSubmit(onSubmit)}
                  className="mb-3 mb-md-0"
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </Form>
    </FormProvider>
  );
};
export default CreateActivityModalForm;
