import { Alert, Icon } from '@octano/global-ui';
import { useTranslation } from 'react-i18next';
import { Col } from 'reactstrap';
import { AdminAccountForm, PermissionType } from '../../types';

interface DisplayInfoUserProps {
  user: AdminAccountForm;
}

const prefix = `usersAndRoles.usersCreate`;

export function DisplayInfoUser({ user }: DisplayInfoUserProps): JSX.Element {
  const { t } = useTranslation();

  const getPermissionsText = (user: AdminAccountForm) => {
    const { studentId, teacherId } = user;
    const permissions: PermissionType[] = [];

    if (studentId) {
      permissions.push(PermissionType.Student);
    }
    if (teacherId) {
      permissions.push(PermissionType.Teacher);
    }
    if (permissions.length === 0) {
      return '';
    }

    const permissionsText = permissions.join(', ');
    return `Este usuario actualmente posee permisos de: ${permissionsText}.`;
  };

  return (
    <Col md={12}>
      <Alert
        className="mt-3 mb-2"
        color="info"
        icon="information"
        size="lg"
        text={t(`${prefix}.form.${user.id ? 'infoFound' : 'info'}`)}
        toggle={() => {}}
      />
      {getPermissionsText(user) && (
        <div className="py-2  d-flex justify-content-start align-items-center">
          <Icon name="information" color="secondary" size="16px" />
          <span className="fs-16 px-2">{getPermissionsText(user)}</span>
        </div>
      )}
    </Col>
  );
}
