import { Students } from '../../types/graduationProcessSingle';

export type StudentRow = {
  id: number;
  fullName: string;
  run: string;
  studyPlanName: string;
  studyPlanEnrolmentId: number;
};

export function getReducedAndMappedSearchResponse(rows: Students[]) {
  return rows.reduce<StudentRow[]>((finalRows, currStudent) => {
    const newRows = currStudent.studyPlanEnrollments.map(
      (studyPlanEnrolment) => ({
        id: currStudent.id,
        fullName: currStudent.account.fullName,
        run: currStudent.account.run,
        studyPlanName:
          studyPlanEnrolment.studyPlanVersionOffer.studyPlanVersion.name,
        studyPlanEnrolmentId: studyPlanEnrolment.id,
      }),
    );

    return [...finalRows, ...newRows];
  }, []);
}
