import { useMemo } from 'react';
import { Badge } from 'reactstrap';

import { DocumentationReviewStatus } from '../../../api/requests/studyPlansOffer';

export interface DocumentationReviewStatusBadgeProps {
  status: DocumentationReviewStatus;
  name: string;
}
export default function DocumentationReviewStatusBadge({
  status,
  name,
}: DocumentationReviewStatusBadgeProps) {
  const data = useMemo<{ color: string; text: string; tooltip: string }>(() => {
    const colors: Record<DocumentationReviewStatus, string> = {
      [DocumentationReviewStatus.WAITING]: '#E0EEFD',
      [DocumentationReviewStatus.NOT_REVIEWED]: '#FFE6A1',
      [DocumentationReviewStatus.APPROVED]: '#CEF0DA',
    };
    return {
      color: colors[status],
      text: name,
      tooltip: name,
    };
  }, [status, name]);

  return (
    <>
      <div className="d-flex align-items-center justify-content-center">
        <Badge
          className="fs-16 fw-400 py-2 px-4 text-dark"
          style={{ backgroundColor: data.color, width: '150px' }}
          pill
        >
          {data.text}
        </Badge>
      </div>
    </>
  );
}
