import { ColumnTable, DisplayInfo, Table } from '@octano/global-ui';
import { CellFormatOptions } from '@octano/global-ui/dist/components/Table/types/TableTypes';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';

import PetitionStatusBadge from '../../../../components/badgeStatus/PetitionStatusBadge';
import { TextEllipsis } from '../../../../components/text';
import { History } from '../../types/PetitionsStory';

const dictPrefix = 'studentPetitions.common.story.table';

interface Props {
  data: History[];
  isLoadingResults: boolean;
  hasFilters?: boolean;
}

export default function PetitionsStoryTable({
  data,
  isLoadingResults,
  hasFilters,
}: Props) {
  const { t } = useTranslation();

  const columns: ColumnTable<History>[] = [
    {
      headerText: t(`${dictPrefix}.columns.status`),
      columnName: 'changeTo',
      thClassName: 'text-center',
      tdClassName: 'text-center',
      cellFormat: ({ row, value }) => {
        return (
          <PetitionStatusBadge
            status={value}
            name={row.id}
            showToolTips={false}
          />
        );
      },
    },
    {
      headerText: t(`${dictPrefix}.columns.createdAt`),
      columnName: 'createdAt',
      tdClassName: 'text-center',
      thClassName: 'text-center',
      cellFormat: (options) => dayjs(options.value).format('DD/MM/YYYY'),
    },

    {
      headerText: t(`${dictPrefix}.columns.hour`),
      columnName: 'createdAt',
      tdClassName: 'text-center',
      thClassName: 'text-center',
      cellFormat: (options: CellFormatOptions) =>
        dayjs(options.value).format('hh:mm'),
    },
    {
      headerText: t(`${dictPrefix}.columns.message`),
      columnName: 'comment',
      tdClassName: 'text-center',
      thClassName: 'text-center',
      cellFormat: (options: CellFormatOptions) => (
        <TextEllipsis
          key={options.index}
          textWidth={250}
          tooltip={options.value}
        >
          {options.value || 'N/A'}
        </TextEllipsis>
      ),
    },
    {
      headerText: t(`${dictPrefix}.columns.modifiedBy`),
      columnName: 'changeBy',
      tdClassName: 'text-center',
      thClassName: 'text-center',
      cellFormat: ({ row }) => <p>{row?.changeBy?.fullName}</p>,
    },
  ];

  return (
    <>
      <Table
        isLoadingResults={isLoadingResults}
        data={data}
        columns={columns}
        noResultsText={
          <div className="d-flex justify-content-center align-items-center py-5">
            <DisplayInfo
              iconName="information"
              title={t(
                `${dictPrefix}.${
                  hasFilters ? 'noResultsFilter' : 'noResults'
                }.description`,
              )}
              textBody=""
              maxWidth="650px"
            />
          </div>
        }
      />
    </>
  );
}
