import { Tab, TabPanel, TabPanels, Tabs, TabsList } from '@octano/global-ui';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router';
import { Card } from 'reactstrap';

import { PathsLayouts } from '../../config/routes';
import CampusList from './campus/CampusList';
import { ModalityList } from './modality/ModalityList';
import { PeriodsList } from './periods/PeriodsList';
import SchedulesList from './schedule/ScheduleList';
import SchoolsList from './schools/SchoolsList';

export default function AcademicStructure() {
  const { t } = useTranslation();
  const tabsPrefix = 'maintainers.tabs';

  const location = useLocation();
  const history = useHistory();

  const tabs = useMemo(
    () => [
      { name: 'periods', content: <PeriodsList /> },
      { name: 'campus', content: <CampusList /> },
      { name: 'schools', content: <SchoolsList /> },
      { name: 'schedules', content: <SchedulesList /> },
      { name: 'modalities', content: <ModalityList /> },
    ],
    [],
  );

  const initialTab = useMemo(() => {
    const currentTab = location.pathname.split('/').reverse()[0];

    if (tabs.some((tab) => tab.name === currentTab)) {
      return currentTab;
    }

    return tabs[0].name;
  }, [location, tabs]);

  const handleTabSelection = (tab: string | number) => {
    const routeToGo = `${PathsLayouts.academicStructure}/${tab}`;
    history.push(routeToGo);
    return null;
  };

  return (
    <Card className="mx-3">
      <Tabs defaultTab={initialTab} onSelection={handleTabSelection}>
        <TabsList>
          {tabs.map((tab) => (
            <Tab
              key={tab.name}
              id={tab.name}
              children={t(`${tabsPrefix}.${tab.name}`)}
            />
          ))}
        </TabsList>
        <TabPanels>
          {tabs.map((tab) => (
            <TabPanel key={tab.name} id={tab.name} children={tab.content} />
          ))}
        </TabPanels>
      </Tabs>
    </Card>
  );
}
