import { Col, Row } from 'reactstrap';
import { useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Semester } from '../../../../components/curriculum/CoursesBySemesters';
import { COURSE_TYPES } from '../../../../types/CurriculumTypes';
import { InterimDegreeFormFields } from './InterimDegreeForm';

enum CreditType {
  Mandatory = 'Mandatory',
  Activity = 'Activity',
  Optional = 'Optional',
  Other = 'Other',
}

type CreditItem = {
  type: CreditType;
  value: number;
};

const mandatoryAliases = ['Obligatorio'];
const activityAliases = ['Actividad'];
const optionalAliases = [
  'Optativo - Otro',
  'Optativo - Deportivo',
  'Optativo - Religioso',
];

const sumCredits = (items: CreditItem[], types?: CreditType[]) => {
  return items
    ?.filter((e) => !types || types?.includes(e.type))
    .map((e) => e.value)
    .reduce((a, b) => a + b, 0);
};

const InterimDegreeCreditsForm = ({
  dictPrefix,
  semesters = [],
}: {
  dictPrefix: string;
  semesters?: Semester[];
}) => {
  const { t } = useTranslation();

  const { watch } = useFormContext<InterimDegreeFormFields>();
  const [semesterCoursesIds] = watch(['semesterCoursesIds']);

  const credits = useMemo<CreditItem[]>(() => {
    const items: CreditItem[] = [];

    semesters?.forEach((semester) => {
      semester.semesterCourses?.forEach((semesterCourse) => {
        if (semesterCoursesIds?.includes(semesterCourse?.id)) {
          const courseTypeAlias =
            Object.values(COURSE_TYPES)?.find(
              (t) => t?.id === semesterCourse?.courseTypeId,
            )?.alias ?? '';

          if (mandatoryAliases.includes(courseTypeAlias)) {
            items.push({
              type: CreditType.Mandatory,
              value: Number(semesterCourse?.course?.credits ?? 0),
            });
          } else if (activityAliases.includes(courseTypeAlias)) {
            items.push({
              type: CreditType.Activity,
              value: Number(semesterCourse?.course?.credits ?? 0),
            });
          } else if (optionalAliases.includes(courseTypeAlias)) {
            items.push({
              type: CreditType.Optional,
              value: Number(semesterCourse?.course?.credits ?? 0),
            });
          } else {
            items.push({
              type: CreditType.Other,
              value: Number(semesterCourse?.course?.credits ?? 0),
            });
          }
        }
      });
    });
    return items;
  }, [semesters, semesterCoursesIds]);

  const resumen = useMemo(() => {
    const total = sumCredits(credits);
    const mandatory = sumCredits(credits, [CreditType.Mandatory]);
    const optional = sumCredits(credits, [CreditType.Optional]);
    const others = total - mandatory - optional;

    return [
      {
        label: t(`${dictPrefix}.credits.labels.total`),
        value: total,
      },
      {
        label: t(`${dictPrefix}.credits.labels.mandatory`),
        value: mandatory,
      },
      {
        label: t(`${dictPrefix}.credits.labels.optional`),
        value: optional,
      },
      {
        label: t(`${dictPrefix}.credits.labels.other`),
        value: others,
      },
    ];
  }, [credits, t, dictPrefix]);

  return (
    <Row>
      <Col className="mb-3" xs={12}>
        <p className="fs-20 text-uppercase text-primary mb-0 fw-700">
          {t(`${dictPrefix}.credits.title`)}
        </p>
      </Col>
      {resumen?.map((item, i) => (
        <Col key={i.toString()} xs={12} sm={2}>
          <div className="d-flex flex-column">
            <span className="fs-16 fw-700 text-dark">{item.label}</span>
            <span className="fs-16 text-light">{item.value}</span>
          </div>
        </Col>
      ))}
    </Row>
  );
};

export default InterimDegreeCreditsForm;
