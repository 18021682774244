import { ReactElement, useCallback } from 'react';

import { createFetchContext } from '../../../components/contexts/FetchContextFactory';
import DisplayError from '../../../components/info/DisplayError';
import Loading from '../../../components/info/Loading';
import { getGeneral } from '../requests';
import { GeneralResponse } from '../types';

export interface GeneralLoaderProps<T> {
  sectionId: number;
  children: (props: { data: T; refresh: () => Promise<void> }) => ReactElement;
}

const { FetchProvider, FetchConsumer } = createFetchContext<
  undefined,
  GeneralResponse
>();

export const GeneralLoaderConsumer = FetchConsumer;

export default function GeneralLoader({
  sectionId,
  children,
}: GeneralLoaderProps<GeneralResponse>) {
  const request = useCallback(() => {
    return getGeneral(sectionId);
  }, [sectionId]);

  return (
    <FetchProvider request={request} defaultQuery={undefined} fetchImmediately>
      <FetchConsumer>
        {({ data, loading, error, refresh }) => {
          if (error) {
            return (
              <DisplayError
                insideCard
                textBody={error.code}
                retryAction={refresh}
                loadingAction={loading}
              />
            );
          }

          if (loading) {
            return <Loading insideCard />;
          }

          if (!data) {
            return (
              <DisplayError
                insideCard
                textBody="Data no cargada"
                retryAction={refresh}
                loadingAction={loading}
              />
            );
          }

          return children({ data, refresh });
        }}
      </FetchConsumer>
    </FetchProvider>
  );
}
