import {
  Button,
  ColumnTable,
  DisplayInfo,
  Icon,
  PaginationType,
  Table,
} from '@octano/global-ui';
import dayjs from 'dayjs';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { PopoverBody, PopoverHeader, UncontrolledPopover } from 'reactstrap';
import { Role } from '../../types/Roles';

interface Props {
  data: Role[];
  isLoadingResults: boolean;
  pagination: PaginationType | undefined;
  hasFilters?: boolean;
  hasError?: boolean;
}
const prefix = 'usersAndRoles.roles.table';

export default function RoleTable({
  data,
  isLoadingResults,
  pagination,
  hasFilters,
  hasError,
}: Props) {
  const { t } = useTranslation();
  const history = useHistory();

  const columns: ColumnTable<Role>[] = [
    {
      headerText: t(`${prefix}.columns.name`),
      columnName: 'name',
      tdClassName: 'text-center',
      thClassName: 'text-center',
      width: '20%',
    },
    {
      headerText: t(`${prefix}.columns.description`),
      columnName: 'description',
      tdClassName: 'text-center',
      thClassName: 'text-center',
      width: '20%',
    },
    {
      headerText: t(`${prefix}.columns.createdAt`),
      columnName: 'createdAt',
      tdClassName: 'text-center',
      thClassName: 'text-center',
      cellFormat: (options) => dayjs(options.value).format('DD/MM/YYYY'),
    },
    {
      headerText: t(`${prefix}.columns.updateAt`),
      columnName: 'updatedAt',
      tdClassName: 'text-center',
      thClassName: 'text-center',
      cellFormat: (options) => (
        <>
          <p>
            {dayjs(options.value).format('DD/MM/YYYY')}
            <br />
            {options.row.lastEditor?.fullName ?? ''}
          </p>
        </>
      ),
    },
    {
      headerText: t(`${prefix}.columns.permissions`),
      columnName: 'permissions',
      tdClassName: 'text-center',
      thClassName: 'text-center',
      cellFormat: ({ row }) => {
        const key = `key_${row.id?.toString()}_${new Date().getTime()}`;
        const permissions = row.rolePermissions.flatMap(
          (rolePermission) => rolePermission.permission,
        );
        return permissions.length > 0 ? (
          <>
            <span id={key}>
              <Icon name="eye" size={16} />
            </span>
            <UncontrolledPopover placement="left" target={key}>
              <PopoverHeader>
                {t(`${prefix}.columns.permissions`)}
              </PopoverHeader>
              <PopoverBody style={{ maxHeight: '300px', overflow: 'auto' }}>
                <ul>
                  {permissions.map((permission) => (
                    <li key={permission.id}>{permission.name}</li>
                  ))}
                </ul>
              </PopoverBody>
            </UncontrolledPopover>
          </>
        ) : null;
      },
    },
    {
      headerText: t(`${prefix}.columns.action`),
      columnName: 'accountId',
      thClassName: 'text-center',
      tdClassName: 'text-center',
      cellFormat: ({ row }) => {
        return (
          <div
            className="d-flex justify-content-center standard-grid"
            style={{ gap: '16px' }}
          >
            <Button
              text={t(`${prefix}.columns.edit`)}
              icon="edit"
              size="sm"
              outlined
              onClick={() => {
                history.push(`/users-and-roles/roles/${row.id}/update`);
              }}
            />
            <Button
              text={t(`${prefix}.columns.assignPermissions`)}
              icon="edit"
              size="sm"
              onClick={() => {
                history.push(`/users-and-roles/roles/${row.id}/permissions`);
              }}
            />
          </div>
        );
      },
    },
  ];

  const noResultsText = useMemo(() => {
    let title: string;
    if (hasFilters) {
      title = t(`${prefix}.noResultsFilter.description`);
    } else {
      title = t(`${prefix}.noResults.description`);
    }

    return (
      <div className="d-flex justify-content-center align-items-center py-5">
        <DisplayInfo
          iconName="information"
          title={title}
          textBody=""
          maxWidth="650px"
        />
      </div>
    );
  }, [t, hasFilters]);

  return (
    <>
      <Table
        isLoadingResults={isLoadingResults}
        data={data}
        columns={columns}
        pagination={pagination}
        noResultsText={noResultsText}
      />
    </>
  );
}
