import request from '../../../../api/request';
import { AcademicOffersTypeDuplicate as AcademicOffers } from '../../../../types/AcademicOffersType';
import { PaginationRequestType } from '../../../../types/paginationRequestType';
import { StudyPlanVersion } from '../../../../types/studyPlan';

/**
 * TARIFFS
 */
const TARIFF = {
  ACADEDMIC_OFFERS:
    '/maintainers-portal/admission-and-enrollments/tariffs/academic-offers',
  GET_VERSIONS_BY_ACADEMIC_OFFER:
    '/maintainers-portal/admission-and-enrollments/tariffs/academic-offers/versions',
  TARIFF: '/tariff',
  ALL_TARIFF: '/tariff/all',
};

/**
 * Obtiene el listado de todas las ofertas academicas
 */
export const getAllAcademicOffersRequest = () => {
  const url = `${TARIFF.ACADEDMIC_OFFERS}`;
  return request<PaginationRequestType<AcademicOffers>>(url);
};

/**
 * Obtiene el listado de todas las ofertas academicas
 */
export const getAcademicOffersTariffRequest = (
  academicOfferId: number,
  items: number = 10,
  page: number = 0,
) => {
  return request<PaginationRequestType<StudyPlanVersion>>(
    TARIFF.GET_VERSIONS_BY_ACADEMIC_OFFER,
    { params: { items_per_page: items, page: page - 1, academicOfferId } },
  );
};

/**
 * Crear/editar una matrícula
 */
export const saveTariffRequest = (data: any) => {
  const url = `${TARIFF.ACADEDMIC_OFFERS}`;
  return request(url, {
    method: 'post',
    data,
  });
};
