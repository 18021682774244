import { useParams } from 'react-router-dom';
import { Container } from 'reactstrap';
import AdditionalDocumentationTable from './parts/AdditionalDocumentsTable';
import AdditionalDocumentationLoader from './parts/Loaders/AdditionalDocumentsLoader';

function AdditionalDocumentation() {
  const { teacherId } = useParams<{ teacherId: string }>();

  return (
    <Container fluid className="mt-4">
      <AdditionalDocumentationLoader teacherId={teacherId}>
        <AdditionalDocumentationTable />
      </AdditionalDocumentationLoader>
    </Container>
  );
}
export default AdditionalDocumentation;
