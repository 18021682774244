// Components
import { Button, addToast } from '@octano/global-ui';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Card, Col, Row } from 'reactstrap';

import { updateFinantialConfig } from '../../../api/requests/settings';
import useUserState from '../../../hooks/useUserState';
import { PermissionName } from '../../../types/Auth';
import { FinantialSettingsContext } from './contexts/finantial-settings.context';
// Hooks
import useFinantialSettings from './hooks/useFinantialSettings';
// Types
import { FinantialConfig } from './interfaces/finantial-config.interface';
import InterestsRatesSection from './parts/InterestsRatesSection';
import QuotaRoundingSection from './parts/QuotaRoundingSection';
import TariffDownPaymentSection from './parts/TariffDownPaymentSection';
import TuitionPaymentSection from './parts/TuitionPaymentSection';
// Utils
import {
  DEFAULT_VALUES,
  parseDefaultValuesFromFetch,
  parseDefaultValuesToFetch,
} from './utils/parse-default-values';
import { hasError } from './utils/validate-fields';

const prefix = 'configurations.finantial';

// Render
export default function Finantial() {
  const { t } = useTranslation();
  const { isAuthorizedTo } = useUserState();

  const {
    config: fetchedConfigs,
    isLoading,
    isRefetching,
  } = useFinantialSettings();

  const [isUpdating, setIsUpdating] = useState<boolean>(false);

  const methods = useForm<FinantialConfig>({
    defaultValues: DEFAULT_VALUES,
  });

  const { reset, handleSubmit } = methods;

  const showSave = useMemo(() => {
    return !isLoading && !!isAuthorizedTo([PermissionName.SETTINGS]);
  }, [isAuthorizedTo, isLoading]);

  useEffect(() => {
    if (fetchedConfigs) reset(parseDefaultValuesFromFetch(fetchedConfigs));
  }, [fetchedConfigs, isRefetching, reset]);

  const handleSave = useCallback(
    async (values: FinantialConfig) => {
      if (isUpdating || isLoading) {
        return;
      }
      const payload = parseDefaultValuesToFetch(values);
      const validationError = hasError(payload);
      if (validationError) {
        addToast({
          icon: 'error',
          text: t(validationError),
          color: 'danger',
        });
        return;
      }

      setIsUpdating(true);

      const { error } = await updateFinantialConfig(payload);
      setIsUpdating(false);

      if (error) {
        addToast({
          icon: 'error',
          text: t(`${prefix}.toasts.errorSavingChanges`),
          color: 'danger',
        });
      } else {
        addToast({
          icon: 'success',
          text: t(`${prefix}.toasts.changesSaved`),
          color: 'success',
        });
      }
    },
    [t, isUpdating, isLoading],
  );

  return (
    <FinantialSettingsContext.Provider
      value={{
        loading: isLoading,
        disabled: isUpdating || isLoading || !showSave,
      }}
    >
      <FormProvider {...methods}>
        <form className="w-100" onSubmit={handleSubmit(handleSave)}>
          <Card className="p-4">
            <Row>
              <Col sm={12}>
                <h1 className="text-primary fs-24 fw-600 text-uppercase">
                  {t(`${prefix}.title`)}
                </h1>
                <p className="fs-14 text-medium mb-3">
                  {t(`${prefix}.description`)}
                </p>
              </Col>
              <Col sm={12}>
                <InterestsRatesSection />
              </Col>
              <Col sm={12}>
                <QuotaRoundingSection />
              </Col>
              <Col sm={12}>
                <TariffDownPaymentSection />
              </Col>
              <Col sm={12}>
                <TuitionPaymentSection />
              </Col>
              <Col sm={12}>
                {showSave && (
                  <Button
                    type="submit"
                    text={t(`${prefix}.actions.save`)}
                    size="md"
                    className="float-right mt-5"
                    disabled={isRefetching || isUpdating}
                    loading={isUpdating}
                  />
                )}
              </Col>
            </Row>
          </Card>
        </form>
      </FormProvider>
    </FinantialSettingsContext.Provider>
  );
}
