export enum InterimDegreesAuthorizedStudentStatus {
  Authorized = 'authorized',
  Graduated = 'graduated',
}

export interface InterimDegreeAuthorizedStudent {
  id: number;
  interimDegreeId: number;
  studyPlanEnrollmentId: number;
  studyPlanEnrollment?: {
    id: number;
    studyPlanVersionOffer: {
      studyPlanVersion: {
        studyPlan: {
          name: string;
          school: {
            name: string;
          };
        };
      };
    };
    student: {
      account: {
        run: string;
        fullName: string;
      };
    };
  };
  status: InterimDegreesAuthorizedStudentStatus;
  batch: number;
  createdAt: string;
  updatedAt: string;
}
