import { ReactElement, useCallback } from 'react';

import { createFetchContext } from '../../../components/contexts/FetchContextFactory';
import DisplayError from '../../../components/info/DisplayError';
import Loading from '../../../components/info/Loading';
import { getSchedules } from '../requests';
import { SectionScheduleGetResponse } from '../types';

export interface SchedulesLoaderProps<T> {
  sectionId: number;
  children: (props: { data: T; refresh: () => Promise<void> }) => ReactElement;
}

const { FetchProvider, FetchConsumer } = createFetchContext<
  undefined,
  SectionScheduleGetResponse
>();

export const SchedulesLoaderConsumer = FetchConsumer;

export default function SchedulesLoader({
  sectionId,
  children,
}: SchedulesLoaderProps<SectionScheduleGetResponse>) {
  const request = useCallback(() => {
    return getSchedules(sectionId);
  }, [sectionId]);

  return (
    <FetchProvider request={request} defaultQuery={undefined} fetchImmediately>
      <FetchConsumer>
        {({ data, loading, error, refresh }) => {
          if (error) {
            return (
              <DisplayError
                insideCard
                textBody={error.code}
                retryAction={refresh}
                loadingAction={loading}
              />
            );
          }

          if (loading) {
            return <Loading insideCard />;
          }

          if (!data) {
            return (
              <DisplayError
                insideCard
                textBody="Data no cargada"
                retryAction={refresh}
                loadingAction={loading}
              />
            );
          }

          return children({ data, refresh });
        }}
      </FetchConsumer>
    </FetchProvider>
  );
}
