export enum DiscountTypes {
  Tuition = 'tuition',
  Tariff = 'tariff',
}

export interface Discount {
  id?: number;
  code: string;
  name: string;
  description: string | null;
  percentage: number;
}

export interface PostulationDetailDiscount {
  id?: number;
  discountName: string;
  discountCode: string;
  discountValue: number;
  amountWithDiscount: number;
  createdAt?: string;
  updatedAt?: string;
  postulationDetailId: number;
  type: DiscountTypes;
}
