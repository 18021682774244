import { AccountType } from '../../types/account';
import { PackageList, PackagesViewsSection } from '../../types/packageTypes';
import {
  AsignPackageToSectionBody,
  CourseListResponseType,
  CreateSectionBody,
  FormDataResponseType,
  SectionMaintainerListResponse,
  Teacher,
} from '../../types/sectionMaintainerTypes';
import { TimeModuleType } from '../../types/timeModuleTypes';
import { CreateAssistantForm } from '../../views/SectionsMaintainer/parts/ModalCreateAssistant';
import { SECTION_MAINTAINER } from '../endpoints';
import request from '../request';

/**
 * Obtiene listado de las secciones
 */
export const requestSectionsMaintainerList = (data: {
  items_per_page: number;
  page: number;
  periodId?: string | number;
  schoolId?: string | number;
  studyPlanId?: string | number;
  campusId?: string | number;
  courseNameCode?: string | null;
  profesorId?: string | number;
}) => {
  return request<SectionMaintainerListResponse>(`${SECTION_MAINTAINER.LIST}`, {
    params: data,
  });
};

/**
 * Obtiene los datos para llenar el filtro en el mantenedor de secciones
 * @returns
 */
export const getFormDataRequest = () => {
  return request<FormDataResponseType>(SECTION_MAINTAINER.FORM_DATA);
};

export const getCreateFormDataRequest = () => {
  return request<FormDataResponseType>(SECTION_MAINTAINER.GET_CREATE);
};

/**
 * Obtiene el listado de todos los cursos (filtrado opcional) para llenar el selector del filtro en el mantenedor de secciones
 * @returns
 */
export const getCoursesListRequest = (school?: number) => {
  const faculty = school ? `?schoolId=${school}` : '';

  return request<CourseListResponseType>(
    SECTION_MAINTAINER.COURSES_LIST + faculty,
  );
};

/**
 * Obtiene listado con los profesores en base a un texto de búsqueda
 * @param searchText
 * @returns
 */
export const getTeachersRequest = (searchText: string) => {
  return request<Teacher[]>(SECTION_MAINTAINER.SEARCH_TEACHERS, {
    params: { search_text: searchText },
  });
};

/**
 * Obtiene listado con los ayudantes en base a un texto de búsqueda
 * @param searchText
 * @returns
 */
export const getAssistantsRequest = (searchText: string) => {
  return request<Teacher[]>(SECTION_MAINTAINER.SEARCH_ASSISTANT, {
    params: { search_text: searchText },
  });
};

/**
 * Obtiene listado con los profesores en base a un texto de búsqueda
 * @param searchText
 * @returns
 */
export const getEditTeachersRequest = (
  sectionId: number | string,
  searchText: string,
) => {
  return request<Teacher[]>(
    SECTION_MAINTAINER.EDIT_SEARCH_TEACHERS(sectionId),
    {
      params: { search_text: searchText },
    },
  );
};

/**
 * Obtiene listado con los ayudantes en base a un texto de búsqueda
 * @param searchText
 * @returns
 */
export const getEditAssistantsRequest = (
  sectionId: number | string,
  searchText: string,
) => {
  return request<Teacher[]>(
    SECTION_MAINTAINER.EDIT_SEARCH_ASSISTANT(sectionId),
    {
      params: { search_text: searchText },
    },
  );
};

/**
 * Obtiene listado con los responsables (docente o ayudante) en base a un texto de búsqueda
 * @param searchText
 * @returns
 */
export const getEditResponsiblesRequest = (
  sectionId: number | string,
  searchText: string,
) => {
  return request<AccountType[]>(
    SECTION_MAINTAINER.EDIT_SEARCH_RESPONSIBLE(sectionId),
    {
      params: { search_text: searchText },
    },
  );
};

/**
 * Crea una sección nueva
 * @returns
 */
export const createSectionRequest = (data: CreateSectionBody) => {
  return request<any>(SECTION_MAINTAINER.CREATE_SECTION, {
    method: 'POST',
    data,
  });
};

/**
 * Obtiene listado con los paquetes en base a un texto de búsqueda pertenecientes a un periodo específico
 * @param searchText
 * @returns
 */
export const getPackagesByPeriodIdRequest = (
  requiredSectionId: number | string,
  periodId: number,
  sectionId?: string,
) => {
  const params = sectionId ? `?sectionId=${sectionId}` : '';
  return request<PackageList[]>(
    SECTION_MAINTAINER.EDIT_PACKAGES_SEARCH_PACKAGES(
      requiredSectionId,
      periodId,
    ) + params,
  );
};

/**
 * Asigna Paquetes a una sección
 * @returns
 */
export const asignPackageToSectionRequest = (
  sectionId: string | number,
  data: AsignPackageToSectionBody,
) => {
  return request<any>(SECTION_MAINTAINER.EDIT_PACKAGES(sectionId), {
    method: 'PUT',
    data,
  });
};

/**
 * crea un asistente
 * @param values Datos del asistente
 */
export const createAssistantRequest = (
  sectionId: number | string,
  values: CreateAssistantForm,
) => {
  const data = {
    ...values,
    passportCountry: values.passportCountry?.value,
    type: values.type ? 'passport' : 'rut',
  };
  return request<any>(SECTION_MAINTAINER.EDIT_CREATE_ASSISTANT(sectionId), {
    data,
    method: 'POST',
  });
};

/**
 * Obtiene el horario del paquete
 */
export async function getPackageTimeCalendar(sectionId: number | string) {
  const url = SECTION_MAINTAINER.EDIT_PACKAGES_TIME_CALENDAR(sectionId);

  return request<TimeModuleType[]>(url, {
    method: 'GET',
  });
}

/**
 * Obtiene los cursos del paquete
 */
export async function getPackageSections(
  sectionId: number | string,
  packageId: number | string,
) {
  const url = SECTION_MAINTAINER.EDIT_PACKAGES_PACKAGE(sectionId, packageId);

  return request<PackagesViewsSection[]>(url, {
    method: 'GET',
  });
}
