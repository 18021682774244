import { INTERIM_DEGREE_GRADUATION_PROCESS_ACTIVITIES } from '../endpoints';
import request from '../request';
import { InterimDegreeAuthorizedStudentActivitiesDetails } from '../../views/InterimDegreeGraduationProcess/interfaces/interim-degree-authorized-student-activities-details.interface';
import { InterimDegreeStudentActivityCreation } from '../../views/InterimDegreeGraduationProcess/interfaces/interim-degree-student-activity-creation.interface';

export const getActivitiesDetails = (params: {
  interimDegreeAuthorizedStudentId: number | string;
}) =>
  request<InterimDegreeAuthorizedStudentActivitiesDetails>(
    INTERIM_DEGREE_GRADUATION_PROCESS_ACTIVITIES.DETAILS(params),
  );

export const createStudentActivityRecord = (
  data: InterimDegreeStudentActivityCreation,
) =>
  request<any>(
    INTERIM_DEGREE_GRADUATION_PROCESS_ACTIVITIES.CREATE_STUDENT_RECORD,
    {
      data,
      method: 'post',
    },
  );
