import { useRef, useCallback } from 'react';
import { Col, Row } from 'reactstrap';
import { Button, CheckInput } from '@octano/global-ui';
import { useTranslation } from 'react-i18next';
import { useFormContext, Controller } from 'react-hook-form';
import { InterimDegreeTableActivitiesTable } from './InterimDegreeTableActivitiesTable';
import {
  InterimDegreeActivityFormItem,
  InterimDegreeFormFields,
} from './InterimDegreeForm';
import InterimDegreeTableActivitiesModal, {
  InterimDegreeTableActivitiesModalMethods,
} from './InterimDegreeTableActivitiesModal';

const handleRemove = (
  index: number,
  items: InterimDegreeActivityFormItem[],
) => {
  items.splice(index, 1);
  return items;
};

const handleAdd = (
  next: InterimDegreeActivityFormItem,
  items: InterimDegreeActivityFormItem[],
) => {
  items.push(next);
  return items;
};

const InterimDegreeActivitiesForm = ({
  dictPrefix,
  disabled,
}: {
  dictPrefix: string;
  disabled?: boolean;
}) => {
  const { t } = useTranslation();

  const modalRef = useRef<InterimDegreeTableActivitiesModalMethods>(null);

  const { watch, setValue, control } =
    useFormContext<InterimDegreeFormFields>();

  const [hasntActivities, activities] = watch([
    'hasntActivities',
    'activities',
  ]);

  const handleCreate = useCallback(() => {
    if (hasntActivities || disabled) {
      return;
    }
    modalRef?.current?.open();
  }, [hasntActivities, disabled]);

  const toggleActivities = useCallback(() => {
    if (disabled) {
      return;
    }
    setValue('hasntActivities', !hasntActivities);
  }, [hasntActivities, disabled, setValue]);

  return (
    <>
      <Row>
        <Col className="mb-2" xs={12}>
          <div className="d-flex flex-wrap w-100 align-items-center justify-content-center">
            <div>
              <p className="fs-20 text-uppercase text-primary mb-0 fw-700">
                {t(`${dictPrefix}.activities.title`)}
              </p>
              <p className="mb-0">
                {t(`${dictPrefix}.activities.description`)}
              </p>
            </div>
            <div className="flex-fill px-2" />
            {!disabled && (
              <Button
                text={t(`${dictPrefix}.activities.actions.create`)}
                size="md"
                disabled={hasntActivities}
                onClick={handleCreate}
              />
            )}
          </div>
        </Col>
        {!disabled && (
          <Col className="mb-4" xs={12}>
            <div className="d-flex flex-wrap w-100 align-items-center justify-content-center">
              <div className="flex-fill px-2" />
              <CheckInput
                name="hasntActivities"
                label={t(`${dictPrefix}.activities.hasntActivities`)}
                value={hasntActivities}
                onChange={toggleActivities}
              />
            </div>
          </Col>
        )}
        <Col xs={12}>
          <Controller
            control={control}
            name="activities"
            render={({ field }) => (
              <>
                <InterimDegreeTableActivitiesTable
                  dictPrefix={`${dictPrefix}.activities`}
                  data={!hasntActivities ? activities : []}
                  removeEnabled={!disabled}
                  onPressDelete={(i) =>
                    field?.onChange(handleRemove(i, field?.value))
                  }
                />
                <InterimDegreeTableActivitiesModal
                  ref={modalRef}
                  dictPrefix={`${dictPrefix}.activities`}
                  onSave={(next) =>
                    field?.onChange(handleAdd(next, field?.value))
                  }
                />
              </>
            )}
          />
        </Col>
      </Row>
    </>
  );
};

export default InterimDegreeActivitiesForm;
