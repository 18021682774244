export interface GatewayPaymentUpdateOptions {
  charges?: GatewayPaymentItem[];
  externalId?: string | null;
  name?: string;
  rut?: string;
  status?: GatewayPaymentStatus;
}

export interface GatewayPaymentCreateOptions
  extends GatewayPaymentUpdateOptions {
  name: string;
  rut: string;
  code: GatewayPaymentCode;
  charges: GatewayPaymentItem[];
}

export interface GatewayPaymentBaseModel {
  id: string | number;
}

export enum IntegrationFinantialSystemEnum {
  ERP = 'erp',
  SOFTLAND = 'softland',
}

export interface GatewayPaymentResponse {
  integration: IntegrationFinantialSystemEnum;
  status: GatewayPaymentStatus;
  message?: string;
  details?: GatewayPaymentDetail;
  receipt?: GatewayPaymentReceipt;
  sustainerName?: string;
  sustainerRut?: string;
  sustainerPassport?: string;
  operation: number;
}

export interface GatewayPaymentReceipt {
  order?: string | null;
  date?: string | null;
  hour?: string | null;
  amount?: string | null;
  transactionCode?: string | null;
  transactionDate?: string | null;
  paymentType?: string | null;
  installments?: string | null;
  cardNumber?: string | null;
  description?: string[];
}

export interface GatewayPaymentLinkResponse {
  code: GatewayPaymentCode;
  method: 'POST' | 'GET';
  action: string;
  inputs: GatewayPaymentInput[];
}

export interface GatewayPaymentItem {
  detail: string;
  total: number;
  isFinalRow?: boolean;
}

export interface GatewayPaymentInput {
  name: string;
  value: string;
}

export interface GatewayGetPaymentStatusResponse {
  status: GatewayPaymentStatus;
  message?: string;
}

export interface GatewayGetPaymentStatusOptions {
  autoConfirm: boolean;
  tokenConfirmation?: string;
}

export interface GatewayPaymentDetail {
  student: {
    name: string;
    rut: string | null;
    passport: string | null;
  };
  items: GatewayPaymentItem[];
  total: number;
}

export enum GatewayPaymentStatus {
  Pending = 'pending',
  Failed = 'failed',
  Success = 'success',
}

export enum GatewayPaymentModelCode {
  ActivePostulationDetail = 'ActivePostulationDetail',
}

export enum GatewayPaymentCode {
  WebpayPlus = 'webpay-plus',
  WebpayOneclick = 'webpay-oneclick',
}

export interface GatewayPaymentCharge {
  id: number;
  gatewayPaymentId: number;
  detail: string;
  amount: number;
  createdAt: Date;
  updatedAt: Date;
  gatewayPayment?: GatewayPayment;
}

export interface GatewayPaymentEntity {
  id: number;
  gatewayPaymentId: number;
  modelCode: string;
  modelId: string;
  createdAt: Date;
  updatedAt: Date;
  gatewayPayment?: GatewayPayment;
}

export enum TransbankPaymentTypeCode {
  DebitSale = 'VD',
  RegularSale = 'VN',
  InstallmentsSale = 'VC',
  Installments3NoInterestsSale = 'SI',
  Installments2NoInterestsSale = 'S2',
  InstallmentsNoInterestsSale = 'NC',
  PrepaidSale = 'VP',
}

export interface GatewayPayment {
  id: number;
  gatewayPaymentCode: GatewayPaymentCode;
  externalId: string | null;
  authorizationCodeId: string | null;
  transbankPaymentTypeCode: TransbankPaymentTypeCode | null;
  transbankCardLast4: string | null;
  name: string;
  rut: string;
  status: GatewayPaymentStatus;
  createdAt: Date;
  updatedAt: Date;
  gatewayPaymentCharges: GatewayPaymentCharge[];
  gatewayPaymentEntities: GatewayPaymentEntity[];
}
