import { Select, SelectOptionType } from '@octano/global-ui';
import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'reactstrap';

import { getPackagesByPeriodIdRequest } from '../../../api/requests/sectionsMaintainer';
import { useLoadingState } from '../../../hooks/useLoadingState';
import { PackageList } from '../../../types/packageTypes';
import { AsignPackageToSectionFormType } from './AsignPackageToSectionForm';
import ExtraSelectFields from './ExtraSelectFields';

type AsignPackageToSectionFieldsProps = {
  sectionId: number | string;
  periodId: number | undefined;
  setIsTherePackages: Dispatch<SetStateAction<boolean>>;
  onChangeArray: (param: SelectOptionType[]) => void;
};

const AsignPackageToSectionFields = ({
  sectionId,
  periodId,
  setIsTherePackages,
  onChangeArray,
}: AsignPackageToSectionFieldsProps) => {
  const { t } = useTranslation();
  const prefix = 'sectionsMaintainer.create.step2';
  const [pkgOptions, setPkgOptions] = useState<SelectOptionType[]>([]);
  const { loading, setLoading, setErrorLoading } = useLoadingState();

  const { control, watch } = useFormContext<AsignPackageToSectionFormType>();

  // Paquete principal seleccionado
  const pkg = watch('pkg');

  // Lista de paquetes extra
  const pkgs = watch('pkgs');

  const getPackages = useCallback(
    async (period: number) => {
      setLoading(true);
      const { data, error } = await getPackagesByPeriodIdRequest(
        sectionId,
        period,
      );

      if (data) {
        setIsTherePackages(data.length > 0);

        const options = data.map(
          (pk: PackageList): SelectOptionType => {
            const label = [
              pk.name,
              pk.academicOfferStudyPlanVersionOffer.studyPlanVersionOffer
                .studyPlanVersion.studyPlan.name,
              pk.academicOfferStudyPlanVersionOffer.studyPlanVersionOffer
                .studyPlanVersion.studyPlan.school.name,
              pk.academicOfferStudyPlanVersionOffer.studyPlanVersionOffer
                .studyPlanVersion.studyPlan.modality.name,
            ].join(' - ');

            return {
              value: pk.id,
              label,
            };
          },
        );

        setPkgOptions(options);
      }
      if (error) {
        setErrorLoading(error.code);
      }
      setLoading(false);
    },
    [sectionId, setErrorLoading, setLoading, setIsTherePackages],
  );

  useEffect(() => {
    if (periodId) getPackages(periodId);
  }, [getPackages, periodId]);

  // Obtenemos las opciones que no esten seleccionadas
  // El pkg corresponde al primer select y pkgs a los extras
  // Por eso se unen en un solo array antes de usarlo para filtrar los items seleccionados
  const filteredOptions = useMemo(() => {
    const hasAlreadyBeenSelected = (option: SelectOptionType) => {
      return [pkg, ...pkgs].find((p) => p?.value === option?.value);
    };

    return pkgOptions.filter((option) => !hasAlreadyBeenSelected(option));
  }, [pkg, pkgs, pkgOptions]);

  return (
    <>
      <Row className="pb-5">
        <Col xs={12} md={6}>
          <Row>
            <Col>
              <Select
                name="pkg"
                label={t(`${prefix}.pkgLabel`)}
                options={filteredOptions}
                control={control}
                disabled={loading}
                isClearable
              />
            </Col>
          </Row>
          <ExtraSelectFields
            title="pkgs"
            inputLabel={t(`${prefix}.pkgLabel`)}
            options={filteredOptions}
            buttonLabel={t(`${prefix}.addPkg`)}
            onChangeArray={onChangeArray}
            disableAdd={loading || filteredOptions.length === 0}
          />
        </Col>
      </Row>
    </>
  );
};

export default AsignPackageToSectionFields;
