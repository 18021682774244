import { ColumnTable, Table as TableCore } from '@octano/global-ui';
import { ReactElement, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

export interface TableProps {
  children: ReactElement;
}

export default function Table({ children }: TableProps) {
  const prefix = 'academicRecord';
  const { t } = useTranslation();

  const texts = useMemo(
    () => ({
      title: t(`${prefix}.academicRecord`),
      headers: {
        actions: t(`${prefix}.actions`),
      },
    }),
    [t],
  );

  const columns: ColumnTable[] = useMemo(() => {
    return [
      {
        columnName: 'name',
        width: '20%',
        thClassName: 'text-center',
        tdClassName: 'text-center',
        headerText: '',
        cellFormat: () => texts.title,
      },
      {
        columnName: 'actions',
        width: '80%',
        thClassName: 'text-center',
        tdClassName: 'text-center',
        headerText: texts.headers.actions,
      },
    ];
  }, [texts]);

  const data = useMemo(() => {
    return [
      {
        actions: children,
      },
    ];
  }, [children]);

  return <TableCore columns={columns} data={data} />;
}
