import { PaginationType, Table } from '@octano/global-ui';
import { useTranslation } from 'react-i18next';
import { format } from 'rut.js';

import { PackagesViewsStudent } from '../../types/packageTypes';

export interface PackagesViewPendingStudentsProps {
  data: PackagesViewsStudent[];
  loading: boolean;
  pagination?: PaginationType;
}

export default function PackagesViewPendingStudents({
  data,
  pagination,
  loading,
}: PackagesViewPendingStudentsProps) {
  const { t } = useTranslation();
  const prefix = 'packages.view.enrolledStudents';

  return (
    <Table
      pagination={pagination}
      borderless
      noResultsText={t(`${prefix}.noStudents`)}
      columns={[
        {
          columnName: 'run',
          headerText: t(`${prefix}.id`),
          cellFormat: ({ row }) => format(row.run),
        },
        {
          columnName: 'name',
          headerText: t(`${prefix}.names`),
        },
        {
          columnName: 'lastName',
          headerText: t(`${prefix}.lastNames`),
          cellFormat: ({ row }) =>
            `${row.paternalLastName} ${row.maternalLastName}`,
        },
        {
          columnName: 'phone',
          headerText: t(`${prefix}.phone`),
          cellFormat: ({ row }) => row.phone?.cellPhone,
        },
        {
          columnName: 'email',
          headerText: t(`${prefix}.email`),
          cellFormat: ({ row }) => (
            <div className="text-center">{row.email}</div>
          ),
        },
      ]}
      data={data}
      isLoadingResults={loading}
    />
  );
}
