import { Alert } from '@octano/global-ui';
import { Card, Col, Row } from 'reactstrap';
import { useState, useEffect, useCallback } from 'react';
import { getAuthorizationDetails } from '../../api/requests/interimDegreeGraduationSingle';

import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import useUserState from '../../hooks/useUserState';
import { PermissionName } from '../../types/Auth';
import DisplayPermissionError from '../../components/info/DisplayPermissionError';
import GoBackToListButton from '../../components/buttons/GoBackToListButton';
import { InterimDegreeFailedStudentTable } from './parts/InterimDegreeFailedStudentTable';
import DisplayError from '../../components/info/DisplayError';
import Loading from '../../components/info/Loading';
import { InterimDegreeAuthorizedStudentSingle } from './interfaces/interim-degree-authorized-student-single.interface';
import {
  CreditsSummaryTable,
  FailedMandatoryCoursesTable,
  PendingOptativeCreditsTable,
} from '../GraduationProcessSinglePendingRequirerments/parts';
import {
  mapCredits,
  mapOptionalCredits,
} from '../GraduationProcessSinglePendingRequirerments/parts/helpers';
import {
  Credit,
  MissingObligatoryCourses,
  MissingOptionalCourses,
} from '../../types/graduationProcessSingle';

export const MODULE_PERMISSIONS = [
  PermissionName.INTERIM_DEGREE_GRADUATION_PROCESS_ALL,
];
const dictPrefix = 'interimDegreeGraduationProcess.failed';

const InterimDegreeGraduationProcessFailedResumen = () => {
  const { t } = useTranslation();
  const { isAuthorizedTo } = useUserState();

  const { studyPlanEnrollmentId, interimDegreeId } = useParams<{
    studyPlanEnrollmentId: string;
    interimDegreeId: string;
  }>();

  const [summary, setSummary] = useState<{
    student?: InterimDegreeAuthorizedStudentSingle;
    missingObligatoryCourses?: MissingObligatoryCourses[];
    missingOptionalCourses?: MissingOptionalCourses;
    requiredCredits?: Credit;
    obtainedCredits?: Credit;
  }>();
  const [isLoadingData, setIsLoadingData] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>();
  const [isMounted, setIsMounted] = useState<boolean>(false);

  const handleData = useCallback(async () => {
    if (isLoadingData) {
      return;
    }
    setIsLoadingData(true);
    setErrorMessage(undefined);
    const { data, error } = await getAuthorizationDetails({
      studyPlanEnrollmentId,
      interimDegreeId,
    });

    if (error || !data) {
      if ((error as any)?.data?.statusCode === 404) {
        setErrorMessage(t(`${dictPrefix}.errors.DATA_NOT_FOUND`));
      } else {
        setErrorMessage(
          t(`${dictPrefix}.errors.${(error as any)?.data?.message}`),
        );
      }
    } else {
      setSummary(data);
    }
    setIsLoadingData(false);
  }, [t, studyPlanEnrollmentId, interimDegreeId, isLoadingData]);

  useEffect(() => {
    if (studyPlanEnrollmentId && interimDegreeId && !isMounted) {
      handleData();
      setIsMounted(true);
    }
  }, [handleData, isMounted, studyPlanEnrollmentId, interimDegreeId]);

  if (!isAuthorizedTo(MODULE_PERMISSIONS, true)) {
    return (
      <div className="w-full px-3">
        <DisplayPermissionError permissions={MODULE_PERMISSIONS} insideCard />
      </div>
    );
  }

  if (isLoadingData) {
    return (
      <div className="w-full px-3">
        <Loading insideCard />
      </div>
    );
  }

  if (errorMessage?.trim()) {
    return (
      <div className="w-full px-3">
        <DisplayError textBody={errorMessage?.trim()} insideCard />
      </div>
    );
  }

  return (
    <>
      <Card className="p-4 mx-3">
        <Row>
          <Col className="mb-4" xs={12}>
            <div className="d-flex flex-wrap w-100 align-items-start justify-content-center">
              <GoBackToListButton text={t(`${dictPrefix}.back`)} />
              <div className="flex-fill px-2" />
            </div>
          </Col>
          <Col xs={12}>
            <InterimDegreeFailedStudentTable
              data={summary?.student ? [summary?.student] : []}
            />
            <Alert
              isOpen={true}
              text={t(`${dictPrefix}.errors.NOT_MET_REQUIREMENTES`)}
              className="mt-4"
              color="danger"
              fade
            />
          </Col>
          <Col className="mt-5" xs={12}>
            <h2 className="text-primary fw-600 fs-20">
              {t(`${dictPrefix}.coursedCreditsSummary.title`)}
            </h2>
            <CreditsSummaryTable
              data={mapCredits({
                obtained: summary?.obtainedCredits ?? {
                  obligatory: 0,
                  optional: 0,
                  others: 0,
                },
                required: summary?.requiredCredits ?? {
                  obligatory: 0,
                  optional: 0,
                  others: 0,
                },
              })}
            />
          </Col>

          <Col className="mt-5" xs={12}>
            <h2 className="text-primary fw-600 fs-20">
              {t(`${dictPrefix}.mandatoryFailedCourses.title`)}
            </h2>

            <FailedMandatoryCoursesTable
              data={summary?.missingObligatoryCourses ?? []}
            />
          </Col>

          <Col className="mt-5" xs={12}>
            <h2 className="text-primary fw-600 fs-20">
              {t(`${dictPrefix}.pendingOptativeCredits.title`)}
            </h2>
            <PendingOptativeCreditsTable
              data={mapOptionalCredits(summary?.missingOptionalCourses ?? {})}
            />
          </Col>
        </Row>
      </Card>
    </>
  );
};

export default InterimDegreeGraduationProcessFailedResumen;
