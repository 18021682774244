import {
  Button,
  CellFormatOptions,
  ColumnTable,
  Table,
} from '@octano/global-ui';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { Section } from '../../../api/requests/MantainerPortal';
import TableEmptyContent from '../../../components/text/TableEmptyContent';
import { PathsLayouts } from '../../../config/routes';
import { ITEMS_PER_PAGE_DEFAULT } from '../ClosingPeriod';

interface TableClosingPeriodProps {
  state: string;
  data: any[];
  isLoadingResults: boolean;
  totalItems: number;
  totalPages: number;
  currentPage: number;
  onChangePage: (page: number) => void;
  hasFilters?: boolean;
}

export default function TableClosingPeriod(props: TableClosingPeriodProps) {
  const { t } = useTranslation();
  const history = useHistory();

  const {
    state,
    data,
    isLoadingResults,
    totalItems,
    totalPages,
    currentPage,
    onChangePage,
  } = props;

  const defaultColumns = useMemo<ColumnTable[]>(
    () => [
      {
        headerText: t(`closingPeriod.table.period`),
        columnName: 'periodName',
        tdClassName: 'text-center',
        thClassName: 'text-center',
        cellFormat: (options) => options.row.period?.name,
      },
      {
        headerText: t(`closingPeriod.table.schoolName`),
        columnName: 'schoolName',
        tdClassName: 'text-center',
        thClassName: 'text-center',
        cellFormat: (options) => options.row.course?.school?.name,
      },
      {
        columnName: 'campus',
        headerText: t('closingPeriod.table.campus'),
        tdClassName: 'text-center',
        thClassName: 'text-center',
        cellFormat: (options: CellFormatOptions<Section>) => {
          return options.row.campus.name;
        },
      },
      {
        columnName: 'Subject',
        headerText: t('closingPeriod.table.Subject'),
        tdClassName: 'text-center',
        thClassName: 'text-center',
        cellFormat: (options: CellFormatOptions<Section>) => {
          return options.row.course.name;
        },
      },
      {
        columnName: 'name',
        headerText: t('closingPeriod.table.section'),
        tdClassName: 'text-center',
        thClassName: 'text-center',
      },
      {
        columnName: 'teacher',
        headerText: t('closingPeriod.table.teacher'),
        tdClassName: 'text-center',
        thClassName: 'text-center',
        cellFormat: (options: CellFormatOptions<Section>) => {
          return options.row.professors.slice(0, 3).map(({ account }) => {
            return <p key={`teacher-${account.email}`}>{account.fullName}</p>;
          });
        },
      },
      {
        columnName: 'email',
        headerText: t('closingPeriod.table.email'),
        tdClassName: 'text-center',
        thClassName: 'text-center',
        cellFormat: (options: CellFormatOptions<Section>) => {
          return options.row.professors.slice(0, 3).map(({ account }) => {
            return <p key={`email-${account.email}`}>{account.email}</p>;
          });
        },
      },
    ],
    [t],
  );

  const percentageSummaryColumn = useMemo<ColumnTable>(() => {
    return {
      columnName: 'percentageApproved',
      headerText: t('closingPeriod.table.percentageApproved'),
      tdClassName: 'text-center',
      thClassName: 'text-center',
      cellFormat: (options: CellFormatOptions<Section>) => {
        return `${Math.round(
          options.row.closedWith?.passPercentage as number,
        )}%`;
      },
    };
  }, [t]);

  const actionColumn = useMemo<ColumnTable>(() => {
    return {
      columnName: 'action',
      headerText: t('closingPeriod.table.action'),
      tdClassName: 'text-center',
      thClassName: 'text-center',
      cellFormat: (options: CellFormatOptions<Section>) => {
        return (
          <Button
            text={t('closingPeriod.table.btnSeeClosedSection')}
            size="sm"
            onClick={() => {
              history.push(`${PathsLayouts.closingPeriod}/${options.row.id}`);
            }}
          />
        );
      },
    };
  }, [t, history]);

  const columns = useMemo<ColumnTable[]>(() => {
    const array = defaultColumns;
    if (state !== 'open') {
      array.push(percentageSummaryColumn);
    }
    array.push(actionColumn);
    return array;
  }, [state, defaultColumns, percentageSummaryColumn, actionColumn]);

  const noResultsText = useMemo(() => {
    if (state === 'open') {
      return (
        <TableEmptyContent
          title={t('closingPeriod.table.notResultStateOpen.title')}
          subtitle={t('closingPeriod.table.notResultStateOpen.subtitle')}
        />
      );
    }
    return (
      <TableEmptyContent
        title={t('closingPeriod.table.notResult.title')}
        subtitle={t('closingPeriod.table.notResult.subtitle')}
      />
    );
  }, [t, state]);

  return (
    <Table
      columns={columns}
      data={data}
      pagination={{
        currentPage: currentPage + 1,
        itemsPerPage: ITEMS_PER_PAGE_DEFAULT,
        totalItems,
        totalPages,
        onChangePage: (page) => onChangePage(page - 1),
      }}
      isLoadingResults={isLoadingResults}
      noResultsText={noResultsText}
    />
  );
}
