import request from '../../../../../api/request';
import { EmploymentBackgroundResponse, FormDataAction } from './type';

const EMPLOYMENT_BACKGROUND = {
  GET_EMPLOYMENT_BACKGROUND: (teacherId: string | number) =>
    `maintainers-portal/management-tools/teacher-file-employment-background/${teacherId}`,
  ADD_EMPLOYMENT_BACKGROUND: (teacherId: string | number) =>
    `maintainers-portal/management-tools/teacher-file-employment-background/${teacherId}`,
  UPDATE_EMPLOYMENT_BACKGROUND: (EmploymentBackgroundId: string | number) =>
    `maintainers-portal/management-tools/teacher-file-employment-background/${EmploymentBackgroundId}`,
  DELETE_EMPLOYMENT_BACKGROUND: (EmploymentBackgroundId: string | number) =>
    `maintainers-portal/management-tools/teacher-file-employment-background/${EmploymentBackgroundId}`,
};

export async function getTitles(teacherId: number) {
  return request<EmploymentBackgroundResponse>(
    EMPLOYMENT_BACKGROUND.GET_EMPLOYMENT_BACKGROUND(teacherId),
    {
      method: 'GET',
    },
  );
}

export async function addTitle(teacherId: number, data: FormDataAction) {
  const formData = new FormData();
  Object.entries(data).forEach(([key, value]: [string, any]) => {
    if (value instanceof File) formData.append(key, value, value.name);
    else if (value) {
      formData.append(key, value);
    }
  });
  return request(EMPLOYMENT_BACKGROUND.ADD_EMPLOYMENT_BACKGROUND(teacherId), {
    method: 'POST',
    data: formData,
  });
}

export async function updateTitle(id: number, data: FormDataAction) {
  const formData = new FormData();
  Object.entries(data).forEach(([key, value]: [string, any]) => {
    if (value instanceof File) formData.append(key, value, value.name);
    else if (value) {
      formData.append(key, value);
    }
  });
  return request(EMPLOYMENT_BACKGROUND.UPDATE_EMPLOYMENT_BACKGROUND(id), {
    method: 'PUT',
    data: formData,
  });
}

export async function deleteTitle(EmploymentBackgroundId: number) {
  return request(
    EMPLOYMENT_BACKGROUND.DELETE_EMPLOYMENT_BACKGROUND(EmploymentBackgroundId),
    {
      method: 'DELETE',
    },
  );
}
