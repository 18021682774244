import { Card, Col, Row } from 'reactstrap';
import { useState, useEffect, useCallback, useRef } from 'react';
import {
  getForm,
  getInterimDegress,
} from '../../api/requests/interimDegreeGraduationMassive';

import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { addToast, showDialogInfo } from '@octano/global-ui';
import { InterimDegressTable } from './parts/InterimDegressTable';
import useUserState from '../../hooks/useUserState';
import { PermissionName } from '../../types/Auth';
import DisplayPermissionError from '../../components/info/DisplayPermissionError';
import { CurriculumForm } from '../academicOffers/InterimDegree/interfaces/curriculum-form.interface';
import { InterimDegree } from '../academicOffers/InterimDegree/interfaces/interimDegree.interface';
import { InterimDegreesSearchControls } from './parts/InterimDegreesSearchControls';
import { InterimDegreesFilters } from './interfaces/interim-degrees-filters.interface';
import InterimDegreeGenerateModal, {
  InterimDegreeGenerateModalMethods,
} from './parts/InterimDegreeGenerateModal';
import { PathsLayouts } from '../../config/routes';

export const MODULE_PERMISSIONS = [
  PermissionName.INTERIM_DEGREE_GRADUATION_PROCESS_ALL,
];
const dictPrefix = 'interimDegreeGraduationProcess.massive';
const ITEMS_PER_PAGE = 10;

const InterimDegreeGraduationProcessMassive = () => {
  const { t } = useTranslation();
  const { isAuthorizedTo } = useUserState();

  const history = useHistory();

  const generateModalRef = useRef<InterimDegreeGenerateModalMethods>(null);

  const [filters, setFilters] = useState<InterimDegreesFilters>({});
  const [tableData, setTableData] = useState<InterimDegree[]>([]);
  const [isLoadingTable, setIsTableLoading] = useState<boolean>(false);
  const [formLoaded, setFormLoaded] = useState<boolean>(false);

  const [form, setForm] = useState<CurriculumForm>();
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [totalItems, setTotalItems] = useState<number>(0);

  const handleForm = useCallback(async () => {
    const { data, error } = await getForm();

    if (error) {
      addToast({
        text: t(`${dictPrefix}.errorOnSearch`),
        color: 'danger',
        icon: 'error',
      });
    } else {
      setForm(data ?? undefined);
    }
  }, [t]);

  const handleSearch = useCallback(
    async (params: { page: number } & InterimDegreesFilters) => {
      setIsTableLoading(true);
      const { data, error } = await getInterimDegress({
        ...params,
        items_per_page: ITEMS_PER_PAGE,
      });

      if (error) {
        addToast({
          text: t(`${dictPrefix}.errorOnSearch`),
          color: 'danger',
          icon: 'error',
        });
      } else {
        setTableData(data?.data ?? []);
        setTotalPages(data?.total_pages || 0);
        setTotalItems(data?.total || 0);
      }
      setIsTableLoading(false);
    },
    [t],
  );

  const handleProcessFilters = useCallback(
    (next: InterimDegreesFilters) => {
      if (next?.schoolId !== filters?.schoolId && !isLoadingTable) {
        setCurrentPage(0);
        setFilters(next);
      }
    },
    [filters, isLoadingTable],
  );

  useEffect(() => {
    if (!formLoaded) {
      handleForm();
      setFormLoaded(true);
    }
  }, [handleForm, formLoaded]);

  useEffect(() => {
    handleSearch({ ...filters, page: currentPage });
  }, [handleSearch, currentPage, filters]);

  const handleGenerate = useCallback((id: number) => {
    generateModalRef?.current?.open(id);
  }, []);

  const handleGenerated = useCallback(
    (id: number, batch: number) => {
      addToast({
        text: t(`${dictPrefix}.successGenerated`),
        color: 'success',
        icon: 'check',
      });
      history.push(
        `${PathsLayouts.interimDegreeGraduationProcess}/${id}/${batch}`,
      );
    },
    [history, t],
  );

  const handleGeneratedFaild = useCallback(() => {
    showDialogInfo({
      icon: {
        name: 'warning',
        color: 'primary',
      },
      title: t(`${dictPrefix}.failedGenerated.title`),
      subtitle: t(`${dictPrefix}.failedGenerated.description`),
      btnConfirm: {
        text: t(`${dictPrefix}.failedGenerated.confirm`),
        onConfirm: () => '',
      },
    });
  }, [t]);

  if (!isAuthorizedTo(MODULE_PERMISSIONS, true)) {
    return (
      <div className="w-full px-3">
        <DisplayPermissionError permissions={MODULE_PERMISSIONS} insideCard />
      </div>
    );
  }

  return (
    <>
      <Card className="p-4 mx-3">
        <Row>
          <Col className="mb-4" xs={12}>
            <p className="fs-20 text-uppercase text-primary mb-0 fw-700">
              {t(`${dictPrefix}.title`)}
            </p>
            <p className="fs-16 mb-0">{t(`${dictPrefix}.description`)}</p>
          </Col>
          <Col className="mb-4" xs={12}>
            <InterimDegreesSearchControls
              schools={form?.schools}
              onSearch={handleProcessFilters}
            />
          </Col>
          <Col xs={12}>
            <InterimDegressTable
              data={tableData}
              itemPerPage={ITEMS_PER_PAGE}
              totalItems={totalItems}
              totalPages={totalPages}
              currentPage={currentPage}
              isLoadingResults={isLoadingTable}
              hasFilters={!!filters?.schoolId}
              onChangePage={(page) => {
                setCurrentPage(page);
              }}
              onPressGenerate={handleGenerate}
            />
          </Col>
        </Row>
      </Card>
      <InterimDegreeGenerateModal
        ref={generateModalRef}
        onGenerate={handleGenerated}
        onFailed={handleGeneratedFaild}
      />
    </>
  );
};

export default InterimDegreeGraduationProcessMassive;
