import {
  addToast,
  Button,
  Icon,
  Table,
  TablePagination,
} from '@octano/global-ui';
import { CellFormatOptions } from '@octano/global-ui/dist/components/Table/types/TableTypes';
import dayjs from 'dayjs';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useHistory, useParams } from 'react-router-dom';
import Select from 'react-select';
import DisplayError from '../../../../components/info/DisplayError';
import Loading from '../../../../components/info/Loading';
import { SectionTitle } from '../../../../components/text';
import TableEmptyContent from '../../../../components/text/TableEmptyContent';
import { PathsLayouts } from '../../../../config/routes';
import { useLoadingState } from '../../../../hooks/useLoadingState';
import {
  CurriculumListItem,
  CURRICULUM_STATUS,
} from '../../../../types/CurriculumTypes';
import {
  editCurriculumMentionStatus,
  getCurriculumsByVersionRequest,
} from '../api/study-plan-curriculums';

type SelectOptions = {
  value: keyof typeof CURRICULUM_STATUS;
  label: string;
};

type SelectIcons = {
  value: keyof typeof CURRICULUM_STATUS;
  label: string;
};

enum STATUS_COLOR {
  borrador = 'danger',
  activo = 'success',
  vigente = 'primary',
  cerrado = 'danger',
}

enum STATUS_ICON {
  borrador = 'warning',
  activo = 'success',
  vigente = 'success',
  cerrado = 'error',
}

const IconOption = (props: { data: SelectIcons }) => (
  <div>
    <Icon
      className={'icon-select'}
      color={STATUS_COLOR[props.data.value]}
      name={STATUS_ICON[props.data.value]}
      size="15px"
    />
    <span>{props.data.label}</span>
  </div>
);

const CurriculumMentionList = () => {
  // TODO: AL quitar warnings se definen como constantes ya que nunca eran modificadas
  // Para que se definen si nunca se cambian?
  const totalItems = 1;
  const totalPages = 1;
  const showPagination = false;
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [curriculums, setCurriculums] = useState<CurriculumListItem[]>([]);
  const [reload, setReload] = useState<boolean>(false);

  const history = useHistory();
  const { t } = useTranslation();
  const prefix = 'studyPlans.curriculum';
  const {
    loading,
    setLoading,
    errorLoading,
    setErrorLoading,
  } = useLoadingState();
  const { id: studyPlanId, versionId } = useParams<{
    id?: string;
    versionId?: string;
  }>();

  const optionsSelectForDraft: SelectOptions[] = [
    {
      value: CURRICULUM_STATUS.borrador,
      label: t(`${prefix}.mentionTable.draft`),
    },
    {
      value: CURRICULUM_STATUS.activo,
      label: t(`${prefix}.mentionTable.active`),
    },
  ];

  const optionsSelectForActive: SelectOptions[] = [
    {
      value: CURRICULUM_STATUS.activo,
      label: t(`${prefix}.mentionTable.active`),
    },
    {
      value: CURRICULUM_STATUS.vigente,
      label: t(`${prefix}.mentionTable.valid`),
    },
    {
      value: CURRICULUM_STATUS.cerrado,
      label: t(`${prefix}.mentionTable.closed`),
    },
  ];

  const changeMentionStatus = async (id: number, newStatus: string) => {
    const { data, error } = await editCurriculumMentionStatus(id, newStatus);
    if (data) {
      setReload(!reload);
    }
    if (error) {
      addToast({
        icon: 'error',
        color: 'danger',
        text: t(`${prefix}.mentionTable.changeStatusError`),
        className: 'long-toast',
      });
    }
  };

  const columns = [
    {
      columnName: 'name',
      headerText: t(`${prefix}.mentionTable.name`),
      width: '20%',
    },
    {
      columnName: 'date',
      headerText: t(`${prefix}.mentionTable.date`),
      cellFormat: (options: CellFormatOptions) =>
        dayjs(options.value).format('DD/MM/YYYY'),
      width: '20%',
    },
    {
      columnName: 'code',
      headerText: t(`${prefix}.mentionTable.code`),
      width: '20%',
    },
    {
      columnName: 'status',
      headerText: t(`${prefix}.mentionTable.status`),
      cellFormat: (options: CellFormatOptions) => {
        let optionsSelect: SelectOptions[] = [];

        if (options.value === CURRICULUM_STATUS.borrador) {
          optionsSelect = optionsSelectForDraft;
        } else {
          optionsSelect = optionsSelectForActive;
        }
        const value = optionsSelect.find(
          (select) =>
            select.value.toString().toLowerCase() ===
            options.value.toLowerCase(),
        );
        return (
          <Select
            options={optionsSelect}
            styles={{
              indicatorSeparator: () => ({}),
              container: (styles) => ({
                ...styles,
                width: '50%',
                margin: '0 auto',
              }),
            }}
            className="react-select secondary"
            classNamePrefix="react-select"
            defaultValue={value}
            components={{ SingleValue: IconOption }}
            isSearchable={false}
            onChange={(current) => {
              if (current) {
                return changeMentionStatus(options.row.id, current.value);
              }
            }}
          />
        );
      },
    },
    {
      columnName: 'id',
      headerText: t(`${prefix}.mentionTable.malla`),
      cellFormat: (options: CellFormatOptions) => {
        return (
          <div className="justify-content-center">
            <Button
              type="button"
              text={t(`${prefix}.mentionTable.edit`)}
              size="sm"
              outlined
              fullwidth
              color="primary"
              onClick={() => goToEditCurriculum(options?.value)}
              disabled={
                options.row && options.row.status !== CURRICULUM_STATUS.borrador
              }
            />
          </div>
        );
      },
      width: '15%',
    },
    {
      columnName: 'id',
      headerText: '',
      width: '40px',
      cellFormat: (options: CellFormatOptions) => {
        return (
          <Link
            to={`${PathsLayouts.academicOffers}/study-plans/record/${studyPlanId}/version/${versionId}/mention/${options.value}/view`}
          >
            <Icon className="custom-eye" name="eye" key="eye" size="22px" />
          </Link>
        );
      },
    },
  ];

  const getCurriculumMentions = useCallback(
    async (items: number = 10, page: number = 0) => {
      const { data, error } = await getCurriculumsByVersionRequest(
        /** QUITAR EL VALOR SETEADO "3" */
        parseInt(versionId || ''),
        items,
        page,
      );
      setLoading(false);
      if (error) {
        setErrorLoading(error.code);
        return;
      }
      if (data) {
        const {
          curriculums: curriculumList = [],
          isPrimary = false,
        } = data.data;
        if (isPrimary) {
          setCurriculums([]);
        } else {
          setCurriculums(curriculumList);
        }
      }
    },
    [setLoading, setErrorLoading, versionId],
  );

  const changePage = async (page: number = 1) => {
    await getCurriculumMentions(10, page - 1);
    setCurrentPage(page);
  };

  const goToCreateCurriculum = useCallback(() => {
    history.push(
      `${PathsLayouts.academicOffers}/study-plans/record/${studyPlanId}/version/${versionId}/mention/create`,
    );
  }, [history, studyPlanId, versionId]);

  const goToEditCurriculum = useCallback(
    (id: string) => {
      history.push(
        `${PathsLayouts.academicOffers}/study-plans/record/${studyPlanId}/version/${versionId}/mention/${id}`,
      );
    },
    [history, studyPlanId, versionId],
  );

  useEffect(() => {
    getCurriculumMentions();
  }, [getCurriculumMentions, reload]);

  if (errorLoading) {
    return (
      <DisplayError
        insideCard
        textBody={errorLoading}
        retryAction={() => setLoading(true)}
        loadingAction={loading}
      />
    );
  }
  if (loading) {
    return <Loading insideCard />;
  }

  return (
    <div className="g-table-container padding-none">
      <SectionTitle text={t(`${prefix}.mentionList`)} />
      <Button
        text={t(`${prefix}.mentionRecord`)}
        type="button"
        size="sm"
        icon="plus"
        className="g-add-button"
        onClick={() => goToCreateCurriculum()}
      />
      <div id={'curriculum-mention-list-table'}>
        <Table
          columns={columns}
          data={curriculums}
          noResultsText={
            <TableEmptyContent
              title={t(`${prefix}.mentionTableEmptyTitle`)}
              subtitle={t(`${prefix}.mentionTableEmptySubtitle`)}
            />
          }
        />
      </div>
      {showPagination && totalItems > 10 && (
        <TablePagination
          pagination={{
            currentPage: currentPage,
            itemsPerPage: 10,
            onChangePage: (page) => {
              changePage(page);
            },
            totalItems: totalItems,
            totalPages: totalPages,
          }}
        />
      )}
    </div>
  );
};

export default CurriculumMentionList;
