import { ReactElement, useCallback } from 'react';

import { AxiosResult, AxiosResultDefaultError } from '../../../api/request';
import { getStudentsDegreeList } from '../../../api/requests/studentsDegree';
import { createFetchContext } from '../../../components/contexts/FetchContextFactory';
import { PaginationRequestType } from '../../../types/paginationRequestType';
import { StudentsDegreeRow } from '../../../types/studentsDegree';

export const ITEMS_PER_PAGE_DEFAULT = 10;

export type StudentsDegreeLoaderQuery = {
  page: number;
  search?: string;
  studyPlanId?: number;
  campusId?: number;
};

export interface StudentsDegreeLoaderProps {
  status: string;
  children: ReactElement;
}

export type StudentsDegreeLoaderResponse = PaginationRequestType<StudentsDegreeRow>;

const { FetchProvider, FetchConsumer, useFetch } = createFetchContext<
  StudentsDegreeLoaderQuery,
  StudentsDegreeLoaderResponse,
  AxiosResultDefaultError
>();

export function useStudentsDegreeLoader() {
  const context = useFetch();

  return {
    error: context.error,
    loading: context.loading,
    data: context.data,
    query: context.query,
    updateQuery: context.updateQuery,
  };
}
export const StudentsDegreeLoaderConsumer = FetchConsumer;

export default function StudentsDegreeLoader({
  status,
  children,
}: StudentsDegreeLoaderProps) {
  const request = useCallback(
    async (
      query: StudentsDegreeLoaderQuery,
    ): Promise<
      AxiosResult<StudentsDegreeLoaderResponse, AxiosResultDefaultError>
    > => {
      return getStudentsDegreeList({
        status,
        page: query.page - 1,
        items: ITEMS_PER_PAGE_DEFAULT,
        search: query.search,
        studyPlanId: query.studyPlanId,
        campusId: query.campusId,
      });
    },
    [status],
  );

  return (
    <FetchProvider
      request={request}
      defaultQuery={{ page: 1 }}
      fetchImmediately
    >
      {children}
    </FetchProvider>
  );
}
