import TabControl from '../../components/tabs/TabControl';
import { PathsLayouts } from '../../config/routes';
import { PermissionName } from '../../types/Auth';
import TcTariff from './tcTariff';
import TcTuition from './tcTuition';

export default function TuitionContinuityTariffAndTuition() {
  return (
    <>
      <TabControl
        tabsPrefix={'tuitionContinuityTariff.tabs'}
        tabs={[
          { name: 'tuitions', content: <TcTuition /> },
          {
            name: 'tariff',
            content: <TcTariff />,
            permissions: [PermissionName.TUITION_CONTINUITY_TARIFF_AND_TUITION],
          },
        ]}
        path={PathsLayouts.tuitionContinuityTariffAndTuition}
      />
    </>
  );
}
