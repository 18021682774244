import dayjs from 'dayjs';

/**
 * Función para formatear una fecha con hora
 * Formato por defecto: 00:00 horas del día lunes 01 de marzo
 */
export function formatDateTimeText(date: string | Date, format?: string) {
  if (!format) {
    format = 'HH:mm [horas del día] dddd DD [de] MMMM';
  }
  if (dayjs(date).isValid()) {
    return dayjs.utc(date).format(format);
  } else {
    return '';
  }
}
/**
 * Función que formatea una fecha en distintos formatos disponibles.
 * @param date Fecha ha formatear
 * @param format "DD-MM-YYYY" | "DD/MM/YYYY" | "YYYY-MM-DD" (Default: "DD-MM-YYYY")
 * @returns
 */
export function formatDate(
  date: string | Date,
  format: 'DD-MM-YYYY' | 'DD/MM/YYYY' | 'YYYY-MM-DD' = 'DD-MM-YYYY',
): string {
  if (dayjs(date).isValid()) {
    return dayjs.utc(date).format(format);
  } else {
    return '';
  }
}

export function getDate(data: string) {
  let date = new Date(data);
  date.setHours(date.getHours() + date.getTimezoneOffset() / 60);
  return date;
}
