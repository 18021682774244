import { addToast } from '@octano/global-ui';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { showToast } from '../../../../../../utils/toast';
import { deleteAdminAccount, enableAdminAccount } from '../../UserNew/services';
import { AdminAccountRow } from '../types/Users';

interface UseActionUserFormProps {
  refresh: () => void;
}

export const useActionAdminAccountStatus = ({
  refresh,
}: UseActionUserFormProps) => {
  const { t } = useTranslation();

  const [loading, setLoading] = useState<boolean>(false);

  const disableAdminAccountS = useCallback(
    async (values: AdminAccountRow) => {
      setLoading(true);
      const { data, error } = await deleteAdminAccount(values?.accountId);
      setLoading(false);

      if (data) {
        addToast({
          icon: 'success',
          color: 'success',
          text: 'Usuario deshabilitado con éxito',
        });
        setTimeout(() => {
          refresh();
        }, 1000);
      }

      if (error) {
        showToast(true, t);
      }
    },
    [t, refresh],
  );

  const enableAdminAccountS = useCallback(
    async (values: AdminAccountRow) => {
      setLoading(true);
      const { data, error } = await enableAdminAccount(values?.accountId);
      setLoading(false);

      if (data) {
        addToast({
          icon: 'success',
          color: 'success',
          text: 'Usuario habilitado con éxito',
        });
        setTimeout(() => {
          refresh();
        }, 1000);
      }

      if (error) {
        showToast(true, t);
      }
    },
    [t, refresh],
  );

  return {
    disableAdminAccountS,
    enableAdminAccountS,
    loading,
  };
};
