import { Table } from '@octano/global-ui';
import useColumns from '../hooks/useColumns';
import { useDebtLoader } from './DebtLoader';

function DebtTable() {
  const { data, loading } = useDebtLoader();

  const onClickDetail = (row: any) => {
    console.log(row);
  };

  const columns = useColumns({
    onClickDetail,
  });

  return (
    <>
      <div className="divider my-4" />
      <Table
        columns={columns}
        data={data?.debts ?? []}
        isLoadingResults={loading}
      />
    </>
  );
}

export default DebtTable;
