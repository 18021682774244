import { useParams } from 'react-router-dom';

import BaseLoader from './contexts/BaseLoader';
import SectionUpdateTabs from './parts/SectionUpdateTabs';

export default function SectionUpdate() {
  const { sectionId: sectionIdRaw } = useParams<{ sectionId: string }>();

  const sectionId = parseInt(sectionIdRaw);

  if (isNaN(sectionId)) {
    return <></>;
  }

  return (
    <BaseLoader sectionId={sectionId}>
      {({ data: { section }, refresh }) => (
        <SectionUpdateTabs
          section={section}
          onRefresh={() => {
            refresh();
          }}
        />
      )}
    </BaseLoader>
  );
}
