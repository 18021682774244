import { Button, SelectOptionType, addToast } from '@octano/global-ui';
import { useCallback, useEffect } from 'react';
import { DefaultValues, FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Col, Form, Row } from 'reactstrap';
import { clean } from 'rut.js';

import { saveSustainer } from '../../../../api/requests/tuitionProcess';
import ContactSection from '../../../../components/form/ContactSection';
import { useStepState } from '../../../../components/step/useStepState';
import { DataOwnSustainer } from '../../../../types/sustainerTypes';
import { useTuitionProcess } from '../../TuitionProcessContext';
import PersonalInformation from './PersonalInformation';

export type FieldsSustainerForm = {
  sustainerType: SelectOptionType | null;
  relationship: SelectOptionType | null;
  occupation: string;
  names: string;
  paternalLastName: string;
  maternalLastName: string;
  hasPassport: boolean;
  rut: string;
  passportNumber: string;
  passportCountry: SelectOptionType | null;
  ownSustainer: boolean;
  addressStreet: string;
  addressNumber: string;
  addressExtra: string;
  commune: SelectOptionType | null;
  region: SelectOptionType | null;
  phone: string;
  cellPhone: string;
  email: string;
  maritalStatus: SelectOptionType | null;
  nationality: SelectOptionType | null;
  businessName: string;
};

type SustainerFormProps = {
  sustainerPassportAvailable: boolean;
  defaultValues?: DefaultValues<FieldsSustainerForm>;
  dataOwnSustainer?: DataOwnSustainer;
};

const SustainerForm = ({
  sustainerPassportAvailable,
  defaultValues,
  dataOwnSustainer,
}: SustainerFormProps) => {
  const {
    prefix: translationPrefix,
    postulationDetailId,
    onError,
  } = useTuitionProcess();
  const prefix = `${translationPrefix}.sustainer`;
  const { t } = useTranslation();
  const history = useHistory();
  const methods = useForm<FieldsSustainerForm>({
    mode: 'onSubmit',
    defaultValues: defaultValues,
  });
  const {
    setValue,
    handleSubmit,
    formState: { isSubmitting },
    watch,
  } = methods;
  const { nextStep } = useStepState();
  const ownSustainer = watch('ownSustainer');
  const postulantHasPassport = Boolean(dataOwnSustainer?.passportNumber);

  const setOwnSustainerPersonalInfo = useCallback(() => {
    setValue('relationship', dataOwnSustainer?.relationship ?? null);
    setValue('rut', dataOwnSustainer?.rut ?? '');
    setValue('passportCountry', dataOwnSustainer?.passportCountry ?? null);
    setValue('passportNumber', dataOwnSustainer?.passportNumber ?? '');
    setValue('names', dataOwnSustainer?.names ?? '');
    setValue('paternalLastName', dataOwnSustainer?.paternalLastName ?? '');
    setValue('maternalLastName', dataOwnSustainer?.maternalLastName ?? '');
    // setValue('nationality', dataOwnSustainer?.nationality ?? null);
    // setValue('maritalStatus', dataOwnSustainer?.maritalStatus ?? null);
  }, [dataOwnSustainer, setValue]);

  const setOwnSustainerContact = useCallback(() => {
    setValue('addressStreet', dataOwnSustainer?.addressStreet ?? '');
    setValue('addressNumber', dataOwnSustainer?.addressNumber ?? '');
    setValue('addressExtra', dataOwnSustainer?.addressExtra ?? '');
    setValue('region', dataOwnSustainer?.region ?? null);
    setValue('commune', dataOwnSustainer?.commune ?? null);
    setValue('phone', dataOwnSustainer?.phone ?? '');
    setValue('cellPhone', dataOwnSustainer?.cellPhone ?? '');
    setValue('email', dataOwnSustainer?.email ?? '');
  }, [dataOwnSustainer, setValue]);

  const onChangeOwnSustainer = useCallback(() => {
    if (ownSustainer) {
      setOwnSustainerPersonalInfo();
      setOwnSustainerContact();
    } else {
      setValue('relationship', null);
      setValue('occupation', '');
      setValue('rut', '');
      setValue('names', '');
      setValue('paternalLastName', '');
      setValue('maternalLastName', '');
      setValue('addressStreet', '');
      setValue('addressNumber', '');
      setValue('addressExtra', '');
      setValue('region', null);
      setValue('commune', null);
      setValue('phone', '');
      setValue('cellPhone', '');
      setValue('email', '');
      setValue('nationality', null);
      setValue('maritalStatus', null);
    }
  }, [
    ownSustainer,
    setValue,
    setOwnSustainerPersonalInfo,
    setOwnSustainerContact,
  ]);

  useEffect(() => {
    if (ownSustainer !== undefined) {
      onChangeOwnSustainer();
    }
  }, [ownSustainer, onChangeOwnSustainer]);

  const onSubmit = async (values: FieldsSustainerForm) => {
    let saveValues = values;
    if (values.ownSustainer) {
      // Para guardar los valores del alumno como su propio sustantador,
      // se mezcla los values con los datos de dataOwnSustainer
      // ya que al dejar los inputs disabled react hook forms retorna los valores como undefined
      // los datos de contactos son opcionales y la comuna puede ser nula
      // if (dataOwnSustainer?.commune === undefined) {
      //   delete dataOwnSustainer?.commune;
      // }
      saveValues = { ...values, ...dataOwnSustainer };
    }
    if (
      saveValues.commune &&
      saveValues.relationship &&
      saveValues.maritalStatus &&
      saveValues.nationality
    ) {
      const { data, error } = await saveSustainer(postulationDetailId, {
        type:
          saveValues?.passportNumber && saveValues.passportNumber !== ''
            ? 'passport'
            : 'rut',
        rut: saveValues.rut ? clean(saveValues.rut) : undefined,
        passportCountry:
          saveValues?.passportCountry?.value.toString() ?? undefined,
        passportNumber: saveValues?.passportNumber,
        names: saveValues.names,
        paternalLastName: saveValues.paternalLastName,
        maternalLastName: saveValues.maternalLastName,
        relationshipId: +saveValues.relationship?.value,
        occupation: saveValues.occupation,
        addressStreet: saveValues.addressStreet,
        addressNumber: saveValues.addressNumber,
        addressExtra: saveValues.addressExtra ?? null,
        communeId: +saveValues.commune?.value,
        phone: saveValues.phone ?? null,
        cellPhone: saveValues.cellPhone,
        email: saveValues.email,
        maritalStatusId: +saveValues.maritalStatus.value,
        nationalityId: saveValues.nationality.value.toString(),
      });

      if (data && error === false) {
        addToast({
          icon: 'success',
          color: 'success',
          text: t(`${prefix}.saveSuccess`),
        });
        nextStep();
      } else if (error) {
        onError(error.code, () => {
          addToast({
            icon: 'error',
            color: 'danger',
            text: t(`${prefix}.saveError`),
          });
        });
      }
    }
  };

  return (
    <FormProvider {...methods}>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <PersonalInformation
          sustainerPassportAvailable={sustainerPassportAvailable}
          postulantHasPassport={postulantHasPassport}
        />
        <ContactSection prefix={`${prefix}.contact`} disabled={false} />
        <Row className="py-5 justify-content-end">
          <Col xs={12} lg={4} className="pb-2 order-2 order-lg-1">
            <Button
              type="button"
              outlined
              onClick={() => history.push('/tuition-process')}
              text={t(`common.actions.cancel`)}
              loading={isSubmitting}
              fullwidth
            />
          </Col>

          <Col xs={12} lg={4} className="pb-2 order-1 order-lg-2">
            <Button
              type="submit"
              text={t(`common.actions.next`)}
              loading={isSubmitting}
              fullwidth
            />
          </Col>
        </Row>
      </Form>
    </FormProvider>
  );
};

export default SustainerForm;
