import { Select, TextInput } from '@octano/global-ui';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'reactstrap';

import { SectionTitle } from '../../../../components/text';
import { useValidations } from '../../../../hooks/useValidations';
import { CurriculumValues } from '../../../../types/CurriculumTypes';

type CurriculumFormContentProps = {
  hasPeriodType?: boolean;
  readOnly: boolean;
  optionsSelectSemesters: any[];
  periodTypes: any[];
  isMention: boolean;
  semesterCount: number;
  printSemesters: (count: number) => JSX.Element[];
};

const CurriculumFormContent = ({
  hasPeriodType,
  readOnly,
  optionsSelectSemesters,
  periodTypes,
  isMention,
  semesterCount,
  printSemesters,
}: CurriculumFormContentProps) => {
  const { control } = useFormContext<CurriculumValues>();
  const prefix = 'studyPlans.curriculum';
  const { t } = useTranslation();

  const { msgValidations, validateTextNotEmpty } = useValidations();

  return (
    <>
      <SectionTitle text={t(`${prefix}.title`)} />
      <Row className="pt-3">
        {!!hasPeriodType && (
          <Col xs={12} md={3}>
            <div className="pb-3">
              <Select
                name="periodType"
                label={t(`${prefix}.periodType`)}
                options={periodTypes}
                control={control}
                disabled={true}
              />
            </div>
          </Col>
        )}
        {isMention && (
          <>
            <Col xs={12} md={3}>
              <div className="pb-3">
                <TextInput
                  name="mentionCode"
                  label={t(`${prefix}.mentionCode`)}
                  control={control}
                  rules={{
                    required: msgValidations.required,
                    maxLength: {
                      value: 50,
                      message: t('common.validations.maxLength', {
                        length: 50,
                      }),
                    },
                    pattern: {
                      value: /^[0-9a-zA-Z]+$/,
                      message: t('common.validations.invalidAlphanumeric'),
                    },
                  }}
                  disabled={readOnly}
                />
              </div>
            </Col>
            <Col xs={12} md={3}>
              <div className="pb-3">
                <TextInput
                  name="mentionName"
                  label={t(`${prefix}.mentionName`)}
                  control={control}
                  rules={{
                    required: msgValidations.required,
                    minLength: {
                      value: 5,
                      message: t('common.validations.minLength', {
                        length: 5,
                      }),
                    },
                    maxLength: {
                      value: 255,
                      message: t('common.validations.maxLength', {
                        length: 255,
                      }),
                    },
                    validate: validateTextNotEmpty,
                  }}
                  disabled={readOnly}
                />
              </div>
            </Col>
          </>
        )}
        <Col xs={12} md={3}>
          <div className="pb-3">
            <Select
              name="semestersAmount"
              label={t(`${prefix}.duration`)}
              options={optionsSelectSemesters}
              control={control}
              rules={{ required: msgValidations.required }}
              disabled={readOnly}
            />
          </div>
        </Col>
        <Col xs={12} md={3}>
          <div className="pb-3">
            <TextInput
              name="totalCredits"
              label={t(`${prefix}.totalCredits`)}
              control={control}
              rules={{
                min: {
                  value: 1,
                  message: t('common.validations.minNumber', {
                    num: 1,
                  }),
                },
                required: msgValidations.required,
                maxLength: {
                  value: 50,
                  message: t('common.validations.maxLength', {
                    length: 50,
                  }),
                },
                pattern: {
                  value: /^[0-9]*$/,
                  message: t('common.validations.invalidIntNumeric'),
                },
              }}
              disabled={readOnly}
            />
          </div>
        </Col>
        <Col xs={12} md={3}>
          <div className="pb-3">
            <TextInput
              name="requiredCredits"
              label={t(`${prefix}.requiredCredits`)}
              control={control}
              rules={{
                required: msgValidations.required,
                maxLength: {
                  value: 50,
                  message: t('common.validations.maxLength', {
                    length: 50,
                  }),
                },
                pattern: {
                  value: /^[0-9]*$/,
                  message: t('common.validations.invalidIntNumeric'),
                },
              }}
              disabled={readOnly}
            />
          </div>
        </Col>
        <Col xs={12} md={3}>
          <div className="pb-3">
            <TextInput
              name="optionalCredits"
              label={t(`${prefix}.optionalCredits`)}
              control={control}
              rules={{
                required: msgValidations.required,
                maxLength: {
                  value: 50,
                  message: t('common.validations.maxLength', {
                    length: 50,
                  }),
                },
                pattern: {
                  value: /^[0-9]*$/,
                  message: t('common.validations.invalidIntNumeric'),
                },
              }}
              disabled={readOnly}
            />
          </div>
        </Col>
        <Col xs={12} md={3}>
          <div className="pb-3">
            <TextInput
              name="otherCredits"
              label={t(`${prefix}.otherCredits`)}
              control={control}
              rules={{
                required: msgValidations.required,
                maxLength: {
                  value: 50,
                  message: t('common.validations.maxLength', {
                    length: 50,
                  }),
                },
                pattern: {
                  value: /^[0-9]*$/,
                  message: t('common.validations.invalidIntNumeric'),
                },
              }}
              disabled={readOnly}
            />
          </div>
        </Col>
        <Col xs={12} md={3}>
          <div className="pb-3">
            <TextInput
              name="activitiesNumber"
              label={t(`${prefix}.activitiesNumber`)}
              control={control}
              rules={{
                required: msgValidations.required,
                maxLength: {
                  value: 50,
                  message: t('common.validations.maxLength', {
                    length: 50,
                  }),
                },
                pattern: {
                  value: /^[0-9]*$/,
                  message: t('common.validations.invalidIntNumeric'),
                },
              }}
              disabled={readOnly}
            />
          </div>
        </Col>
      </Row>
      {semesterCount > 0 && (
        <Row className="pt-3 pb-5">
          <Col xs={12}>
            <SectionTitle text={t(`${prefix}.semestersList.title`)} />
            <div className="semesters-list pt-3">
              {printSemesters(semesterCount)}
            </div>
          </Col>
        </Row>
      )}
    </>
  );
};

export default CurriculumFormContent;
