import { Button, Icon, IconPaths } from '@octano/global-ui';
import { Col, Row } from 'reactstrap';

type PaymentStatusProps = {
  iconName: keyof typeof IconPaths;
  title?: string;
  body: string;
  textBtn: string;
  actionBtn: () => void;
};

const PaymentStatus = ({
  iconName,
  title,
  body,
  textBtn,
  actionBtn,
}: PaymentStatusProps) => {
  return (
    <Row className="text-center">
      <Col xs={12} className="py-2">
        <Icon name={iconName} size="60px" color="secondary" />
      </Col>
      {title && (
        <Col xs={12} className="pt-3">
          <span className="text-dark fs-20">{title}</span>
        </Col>
      )}
      <Col xs={12} className="py-4">
        <div className="mx-auto" style={{ maxWidth: '530px' }}>
          <span className="fs-18 lh-30 fw-300">{body}</span>
        </div>
      </Col>
      <Col xs={12} className="pt-3 pb-5">
        <div className="mx-auto" style={{ maxWidth: '300px' }}>
          <Button text={textBtn} onClick={actionBtn} fullwidth />
        </div>
      </Col>
    </Row>
  );
};

export default PaymentStatus;
