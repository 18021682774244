import { PaginationType } from '@octano/global-ui';
import { ReactElement, useCallback, useEffect, useState } from 'react';

import { getPackagePendingStudents } from '../../api/requests/packages';
import DisplayError from '../../components/info/DisplayError';
import { PackagesViewsStudent } from '../../types/packageTypes';
import { PackagesViewPendingStudentsProps } from './PackagesViewPendingStudents';

export interface PackagesPendingStudentsLoaderProps {
  academicOfferStudyPlanVersionOfferId: number | string;
  children: (props: PackagesViewPendingStudentsProps) => ReactElement;
}

export function PackagesPendingStudentsLoader({
  academicOfferStudyPlanVersionOfferId,
  children,
}: PackagesPendingStudentsLoaderProps) {
  const totalPages = 10;

  const [loading, setLoading] = useState(true);
  const [data, setData] = useState<Array<PackagesViewsStudent>>([]);
  const [pagination, setPagination] = useState<
    Omit<PaginationType, 'onChangePage'>
  >();
  const [error, setError] = useState<'UNKNOWN' | undefined>(undefined);

  const fetchData = useCallback(
    async (query: { page: number } = { page: 1 }) => {
      setLoading(true);
      const result = await getPackagePendingStudents(
        academicOfferStudyPlanVersionOfferId,
        { ...query, totalPages },
      );
      if (result.error) {
        setError('UNKNOWN');
      } else {
        setError(undefined);
        setData(result.data.data);
        setPagination({
          currentPage: query.page,
          itemsPerPage: totalPages,
          totalItems: result.data.total,
          totalPages: result.data.total_pages,
        });
      }
      setLoading(false);
    },
    [academicOfferStudyPlanVersionOfferId],
  );

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  if (error) {
    return <DisplayError textBody={error} />;
  }

  return children({
    loading,
    data,
    pagination: pagination
      ? {
          ...pagination,
          onChangePage: (newPage) => {
            fetchData({ page: newPage });
          },
        }
      : undefined,
  });
}
