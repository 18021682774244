import { Button, TextAreaInput, TextInput } from '@octano/global-ui';
import { useCallback } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Col, Form, Row } from 'reactstrap';

import { AxiosResult, AxiosResultDefaultError } from '../../../api/request';
import {
  SchoolsListItem,
  saveSchoolRequest,
} from '../../../api/requests/schools';
import { CardTitle } from '../../../components/text';
import { useValidations } from '../../../hooks/useValidations';

export interface School {
  id?: number;
  name: string;
  code: string;
  description?: string | null;
}

interface SchoolFormProps {
  recordToEdit: School | null;
  onSubmit: (
    result: AxiosResult<SchoolsListItem, AxiosResultDefaultError>,
  ) => void;
  onCancel: () => void;
}

const getTranslationsTexts = (isEditionMode: boolean) => {
  const translationPreffix = 'maintainers.schoolsForm';
  return isEditionMode
    ? {
        create: 'common.actions.saveChanges',
        success: 'common.messages.editSuccess',
        formTitle: `${translationPreffix}.editTitle`,
      }
    : {
        create: `${translationPreffix}.create`,
        success: `${translationPreffix}.createSuccess`,
        formTitle: `${translationPreffix}.addTitle`,
      };
};

const getDefaultValues = (recordToEdit: School | null) =>
  recordToEdit
    ? {
        code: recordToEdit.code,
        name: recordToEdit.name,
        description: recordToEdit.description ?? '',
      }
    : {
        code: '',
        name: '',
        descripion: '',
      };

const CampusForm = ({
  recordToEdit,
  onSubmit: onSubmitProp,
  onCancel,
}: SchoolFormProps) => {
  const { t } = useTranslation();
  const methods = useForm<School>({
    mode: 'onSubmit',
    defaultValues: getDefaultValues(recordToEdit),
  });

  const {
    handleSubmit,
    formState: { isSubmitting, isDirty },
    control,
    setError,
  } = methods;

  const onSubmit = useCallback(
    async (values: School) => {
      const { code, name, description } = values;

      const requestBody = {
        code,
        name,
        description,
        id: recordToEdit?.id,
      };

      const result = await saveSchoolRequest(requestBody);

      if (result.error && result.error.code === 'HTTP_ERROR') {
        setError('code', {
          message: t('common.errors.codeError', { code: values.code }),
        });
        return;
      }
      onSubmitProp(result);
    },
    [onSubmitProp, setError, t, recordToEdit],
  );

  const isEditionMode = !!recordToEdit;
  const { msgValidations } = useValidations();

  const texts = getTranslationsTexts(isEditionMode);

  return (
    <FormProvider {...methods}>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Row>
          <Col>
            <div className="text-center">
              <CardTitle children={t(texts.formTitle)} />
            </div>
            <Row className="mt-0 mt-md-5">
              <Col xs="12" md="6">
                <TextInput
                  name="code"
                  label={t('common.forms.code')}
                  control={control}
                  rules={{
                    required: msgValidations.required,
                    pattern: {
                      value: /^[0-9a-zA-Z]+$/,
                      message: t('common.validations.invalidAlphanumeric'),
                    },
                  }}
                />
              </Col>
              <Col xs="12" md="6" className="mt-2 mt-md-0">
                <TextInput
                  name="name"
                  label={t('common.forms.name')}
                  control={control}
                  rules={{
                    required: msgValidations.required,
                    minLength: {
                      value: 3,
                      message: t('common.validations.minLength', {
                        length: 3,
                      }),
                    },
                  }}
                />
              </Col>
            </Row>
            <Row className="mt-0 mt-md-3">
              <Col xs="12" className="mt-2 mt-md-0">
                <TextAreaInput
                  name="description"
                  label={`${t('common.forms.description')} ${t(
                    'common.forms.optionalLabel',
                  )}`}
                  control={control}
                  height={95}
                  placeholder={t(`common.forms.descriptionPlaceholder`)}
                />
              </Col>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col className="mt-5">
            <Row>
              <Col xs={{ size: 12, order: 2 }} md={{ size: 6, order: 1 }}>
                <Button
                  type="button"
                  text={t('common.actions.cancel')}
                  outlined
                  onClick={onCancel}
                  fullwidth
                />
              </Col>
              <Col xs={{ size: 12, order: 1 }} md={{ size: 6, order: 2 }}>
                <Button
                  type="submit"
                  text={t(texts.create)}
                  loading={isSubmitting}
                  fullwidth
                  className="mb-3 mb-md-0"
                  disabled={isEditionMode && !isDirty}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </Form>
    </FormProvider>
  );
};
export default CampusForm;
