import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import DisplayError from './DisplayError';

type DisplayPermissionErrorProps = {
  title?: string;
  insideCard?: boolean;
  permissions?: string[];
};

/**
 * Componente utilizada para mostrar mensaje de error al cargar información.
 * Se puede agregar botón con acción para reintentar la carga.
 * Titulo por defecto es "No se pudo cargar la información",
 */
const DisplayPermissionError = ({
  permissions = [],
  ...props
}: DisplayPermissionErrorProps) => {
  const prefix = 'common.displayPermissionError';
  const { t } = useTranslation();

  const textBody = useMemo(() => {
    if (!permissions?.length) {
      return t(`${prefix}.body`)
    } else {
      return t(`${prefix}.bodyWithNames`, {
        names: permissions?.join(', ')
      })
    }
  }, [permissions, t])

  return <DisplayError {...props} textBody={textBody} />;
};

export default DisplayPermissionError;
