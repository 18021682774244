import { SelectOptionType, addToast } from '@octano/global-ui';
import dayjs from 'dayjs';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Card, Col, Row } from 'reactstrap';

import {
  collected,
  collectedDownload,
  getPaymentHubFormFields,
} from '../../../api/requests/paymentHub';
import { downloadFromBlob } from '../../../utils/blob';
import { CollectionTypeCode } from '../enums/collection-type-code.enum';
import { Collection } from '../interfaces/collection';
import { CollectedFilters } from './interfaces/collected-filters.interface';
import { CollectedPaymentsTable } from './parts/PaymentsTable';
import { SearchControls } from './parts/SearchControls';

const dictPrefix = 'paymentHub';
const ITEMS_PER_PAGE = 10;

const TYPE_SORT_VALUE = {
  [CollectionTypeCode.Matriculation]: 0,
  [CollectionTypeCode.TariffDownPayment]: 1,
  [CollectionTypeCode.Tariff]: 2,
  [CollectionTypeCode.Quota]: 3,
};

export default function CollectedPayments() {
  const { t } = useTranslation();
  const [tableData, setTableData] = useState<Collection[]>([]);
  const [filters, setFilters] = useState<CollectedFilters>({});
  const [isLoadingTable, setIsTableLoading] = useState<boolean>(false);
  const [isMounted, setIsMounted] = useState<boolean>(false);
  const [isDownloadLoading, setDownloadLoading] = useState<boolean>(false);

  const [currentPage, setCurrentPage] = useState<number>(0);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [totalPayments, setTotalPayments] = useState<number>(0);

  const [programs, setPrograms] = useState<SelectOptionType[]>([]);
  const [periods, setPeriods] = useState<SelectOptionType[]>([]);

  const sortedData = useMemo(() => {
    const data = [...tableData];
    data.sort((a, b) => {
      if (a.salenote === b.salenote) {
        if (a.type === b.type) {
          return b.installment - a.installment;
        }
        return TYPE_SORT_VALUE[b.type] - TYPE_SORT_VALUE[a.type];
      }
      return Number(b.salenote ?? 0) - Number(a.salenote ?? 0);
    });
    return data;
  }, [tableData]);

  const detailsOptions = useMemo<SelectOptionType[]>(
    () => [
      {
        value: 'matriculation',
        label: t('paymentHub.paymentsTable.concepts.matriculation'),
      },
      {
        value: 'tariff',
        label: t('paymentHub.paymentsTable.concepts.tariff'),
      },
      {
        value: 'tariffDownPayment',
        label: t('paymentHub.paymentsTable.concepts.tariffDownPayment'),
      },
      {
        value: 'quota',
        label: t('paymentHub.paymentsTable.concepts.quota'),
      },
    ],
    [t],
  );

  const handleSearch = useCallback(
    async ({
      searchParams,
      page = 0,
    }: {
      searchParams?: CollectedFilters;
      page: number;
    }) => {
      setIsTableLoading(true);
      const { data, error } = await collected({
        ...searchParams,
        items: ITEMS_PER_PAGE,
        page: page,
      });

      if (error) {
        addToast({
          text: t(`${dictPrefix}.errorOnSearch`),
          color: 'danger',
          icon: 'error',
        });
      } else {
        setTableData(data?.data ?? []);
        setTotalPages(data?.total_pages || 0);
        setTotalPayments(data?.total || 0);
      }
      setIsTableLoading(false);
    },
    [t],
  );

  const handleDownload = useCallback(async () => {
    setDownloadLoading(true);
    const response = await collectedDownload({
      ...filters,
    });
    const { data, error, status } = response;

    if (error) {
      if (error?.status === 400) {
        addToast({
          text: t(`${dictPrefix}.generatingFile.errorEmptyRecordsCollection`),
          color: 'danger',
          icon: 'error',
        });
      } else {
        addToast({
          text: t(`${dictPrefix}.generatingFile.errorOnDownload`),
          color: 'danger',
          icon: 'error',
        });
      }
    }
    if (status === 200) {
      addToast({
        text: t(`${dictPrefix}.generatingFile.download`),
        color: 'success',
        icon: 'success',
      });
      const blob = new Blob([data], {
        type: 'text/csv',
      });
      downloadFromBlob(blob, `${dayjs().format('YYYYMMDD')}.csv`);
    }
    setDownloadLoading(false);
  }, [filters, t]);

  const handleClear = async () => {
    setTableData([]);
    setFilters({});
    setCurrentPage(0);
  };

  useEffect(() => {
    handleSearch({ searchParams: filters, page: currentPage });
  }, [handleSearch, currentPage, filters]);

  const findForm = useCallback(async () => {
    const { data, error } = await getPaymentHubFormFields();
    if (data && !error) {
      setPrograms(
        data?.programs?.map((e) => ({
          value: e?.code,
          label: e?.name,
        })) ?? [],
      );
      setPeriods(
        data?.periods?.map((e) => ({
          value: e?.code,
          label: e?.name,
        })) ?? [],
      );
    }
  }, []);

  useEffect(() => {
    if (!isMounted) {
      findForm();
      setIsMounted(true);
    }
  }, [findForm, isMounted]);

  return (
    <Card className="p-4">
      <Row>
        <Col xs={12}>
          <SearchControls
            isDownloadLoading={isDownloadLoading}
            detailsOptions={detailsOptions}
            programs={programs}
            periods={periods}
            onSearch={(filters) => {
              setCurrentPage(0);
              setFilters(filters);
            }}
            onClear={handleClear}
            onDownload={handleDownload}
          />
        </Col>
        <Col xs={12}>
          <CollectedPaymentsTable
            data={sortedData}
            detailsOptions={detailsOptions}
            programs={programs}
            periods={periods}
            totalPayments={totalPayments}
            totalPages={totalPages}
            currentPage={currentPage}
            isLoadingResults={isLoadingTable}
            onChangePage={(page) => {
              setCurrentPage(page);
            }}
          />
        </Col>
      </Row>
    </Card>
  );
}
