import { Button, SelectOptionType, addToast } from '@octano/global-ui';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { DefaultValues, FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { Col, Form, Row } from 'reactstrap';
import { clean } from 'rut.js';
import { v4 as uuidv4 } from 'uuid';

import { createAssistantRequest } from '../../../api/requests/sectionsMaintainer';
import DisplayError from '../../../components/info/DisplayError';
import { SectionTitle } from '../../../components/text';
import { PathsLayouts } from '../../../config/routes';
import { Campus } from '../../../types/campus';
import { SectionDetail } from '../../../types/sectionMaintainerTypes';
import { formatDate } from '../../../utils/dates';
import { getSelectedOption } from '../../../utils/selectFormat';
import { putGeneral } from '../requests';
import ActivityAttendanceFieldsTable from './ActivityAttendanceFieldsTable';
import ModalCreateAssistant, {
  CreateAssistantForm,
} from './ModalCreateAssistant';
import { ScheduleSectionForm } from './ModalMaintainerSchedule';
import SectionFormFields from './SectionFormFields';

export type SectionValuesForm = {
  quota: string;
  campus: SelectOptionType;
  teacher: SelectOptionType;
  teachers: SelectOptionType[];
  assistant: SelectOptionType;
  assistants: SelectOptionType[];
};

const FORM_ERRORS = {
  RUT_OR_PASSPORT: 'USER_ALREADY_EXIST',
  EMAIL: 'EMAIL_ALREADY_EXIST',
};

export interface ScheduleList extends ScheduleSectionForm {
  id: number | null;
  isActive: boolean;
}

interface Props {
  section: SectionDetail;
  campuses: Campus[];
  onSave: () => void;
  activities: { id: number; name: string }[];
}
const SectionForm = ({
  section: sectionProp,
  activities,
  campuses,
  onSave,
}: Props) => {
  const { sectionId } = useParams<{ sectionId: string }>();
  const { t } = useTranslation();
  const prefix = 'sectionsMaintainer.update';
  const history = useHistory();

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [isUnexpectedError, setIsUnexpectedError] = useState<boolean>(false);
  const [assistanceRequirements, setAssistanceRequirements] = useState<object>(
    {},
  );

  const campusOptions = useMemo(() => {
    return campuses.map(
      (row: Campus): SelectOptionType => ({
        value: row.id,
        label: row.name,
      }),
    );
  }, [campuses]);

  const section = useMemo(() => {
    const assistanceRequirements: SectionDetail['assistanceRequirements'] = activities.map(
      (a) => {
        const assistanceRequirement = sectionProp.assistanceRequirements.find(
          (b) => b.activityTypeId === a.id,
        );
        return (
          assistanceRequirement || {
            id: a.id,
            minPercentage: 0,
            activityTypeId: a.id,
            sectionId: sectionProp.id,
            activityType: a,
          }
        );
      },
    );
    return { ...sectionProp, assistanceRequirements };
  }, [sectionProp, activities]);

  const defaultValues: DefaultValues<SectionValuesForm> = useMemo(() => {
    return {
      quota: section?.maximumStudent.toString() || undefined,
      campus: getSelectedOption(section?.campus.id, campusOptions),
      teacher: section?.professors?.[0]
        ? {
            value: section.professors[0].id,
            label: section.professors[0].account?.fullName ?? '',
          }
        : undefined,
      teachers: section?.professors?.[1]
        ? section?.professors?.slice(1).map((elem) => ({
            value: elem.id,
            label: elem.account?.fullName ?? '',
            code: uuidv4(), // se usa el uuidv4 para crear una referencia única porque cuando se agregan campos nuevos, el "value" y el "label" están en blanco, y al imprimirlos en pantalla no hay forma de referenciarlos
          }))
        : [],
      assistant: section?.assistant?.[0]
        ? {
            value: section.assistant[0].id,
            label: section.assistant[0].account?.fullName ?? '',
          }
        : undefined,
      assistants: section?.assistant?.[1]
        ? section?.assistant?.slice(1).map((elem) => ({
            value: elem.id,
            label: elem.account?.fullName ?? '',
            code: Math.floor(Math.random() * 10000 + 1),
          }))
        : [],
    };
  }, [section, campusOptions]);

  const methods = useForm<SectionValuesForm>({ defaultValues: defaultValues });

  const onSubmit = useCallback(
    async (values: SectionValuesForm) => {
      const {
        campus,
        teacher,
        teachers,
        quota,
        assistant,
        assistants,
      } = values;

      const auxTeachers =
        teachers?.filter((item) => item).map((item) => +item.value) ?? [];

      const auxAssistants =
        assistants?.filter((item) => !!item).map((item) => +item.value) ?? [];

      if (teacher?.value) auxTeachers.push(+teacher.value);
      if (assistant?.value) auxAssistants.push(+assistant.value);

      const assistanceReqToSend = Object.values(assistanceRequirements).map(
        (item) => {
          return {
            ...item,
            activityTypeId: item.activityTypeId,
            minPercentage: Number(item.minPercentage),
          };
        },
      );

      const { data, error } = await putGeneral(+sectionId, {
        maximumStudent: +quota,
        campusId: +campus.value,
        professorIds: auxTeachers,
        assistantIds: auxAssistants,
        assistanceRequirements: assistanceReqToSend,
      });

      if (data && !error) {
        addToast({
          icon: 'success',
          color: 'success',
          text: t('common.messages.editSuccess'),
        });
        onSave();
      } else {
        setIsUnexpectedError(true);
      }
    },
    [sectionId, t, onSave, setIsUnexpectedError, assistanceRequirements],
  );

  const createAssistant = useCallback(
    async (values: CreateAssistantForm, reset) => {
      if (values.run) clean(values.run);
      values.birthday = formatDate(values.birthday, 'YYYY-MM-DD');
      const { data, error } = await createAssistantRequest(sectionId, values);

      if (data && !error) {
        addToast({
          icon: 'success',
          color: 'success',
          text: t('common.messages.editSuccess'),
        });
        reset();
        setIsModalOpen(false);
      } else if (
        error &&
        error.data.statusCode === 400 &&
        error.data.message &&
        error.data.message.length > 0
      ) {
        if (error.data.message[0] === FORM_ERRORS.EMAIL) {
          addToast({
            icon: 'error',
            color: 'danger',
            text: t(`sectionsMaintainer.update.createAsistantForm.ErrorEmail`),
          });
        } else if (error.data.message[0] === FORM_ERRORS.RUT_OR_PASSPORT) {
          addToast({
            icon: 'error',
            color: 'danger',
            text: t(
              `sectionsMaintainer.update.createAsistantForm.ErrorRutOrPassport`,
            ),
          });
        } else {
          addToast({
            icon: 'error',
            color: 'danger',
            text: t(`common.errors.save`),
          });
        }
      } else {
        addToast({
          icon: 'error',
          color: 'danger',
          text: t(`common.errors.save`),
        });
      }
    },
    [sectionId, setIsModalOpen, t],
  );

  useEffect(() => {
    if (section) {
      let aux: object = {};
      section?.assistanceRequirements.forEach((item) => {
        aux = {
          ...aux,
          [`${item.id}`]: {
            id: item.id,
            name: item.activityType.name,
            minPercentage: item.minPercentage,
            activityTypeId: item.activityTypeId,
          },
        };
      });
      setAssistanceRequirements(aux);
    }
  }, [section, setAssistanceRequirements]);

  if (isUnexpectedError) {
    return (
      <DisplayError
        title={t('packages.loadErrorTitle')}
        textBody={t('packages.loadErrorBody')}
        retryAction={methods.handleSubmit(onSubmit)}
        loadingAction={methods.formState.isSubmitting}
        insideCard
      />
    );
  }

  return (
    <>
      <FormProvider {...methods}>
        <Form onSubmit={methods.handleSubmit(onSubmit)}>
          <Row className="pb-5">
            <Col xs={12}>
              <SectionTitle text={t(`${prefix}.generalSection`)} />
            </Col>
            <Col xs={12}>
              <p className="fs-16 pb-4">
                {t(`${prefix}.generalSectionDescription`)}
              </p>
            </Col>
          </Row>
          <Row>
            <Col xs={12} lg={4}>
              <ActivityAttendanceFieldsTable
                assistanceRequirements={assistanceRequirements}
                setAssistanceRequirements={setAssistanceRequirements}
              />
            </Col>
            <Col xs={12} lg={8}>
              <SectionFormFields
                sectionId={sectionId}
                campusOptions={campusOptions}
                setIsModalOpen={setIsModalOpen}
              />
            </Col>
          </Row>

          <Row className="py-5 justify-content-end pt-5">
            <Col xs={12} lg={3} className="pb-2 order-2 order-lg-1">
              <Button
                type="button"
                outlined
                onClick={() => history.push(PathsLayouts.sectionsMaintainer)}
                text={t(`common.actions.cancel`)}
                disabled={methods.formState.isSubmitting}
                fullwidth
              />
            </Col>
            <Col xs={12} lg={3} className="pb-2 order-1 order-lg-2">
              <Button
                type="submit"
                text={t(`common.actions.save`)}
                loading={methods.formState.isSubmitting}
                fullwidth
              />
            </Col>
          </Row>
        </Form>
      </FormProvider>
      <ModalCreateAssistant
        isModalOpen={isModalOpen}
        closeModal={() => setIsModalOpen(false)}
        onSave={createAssistant}
      />
    </>
  );
};

export default SectionForm;
