// Components
import { Tab, TabPanel, TabPanels, Tabs, TabsList } from '@octano/global-ui';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useRouteMatch } from 'react-router-dom';
import DisplayPermissionError from '../../components/info/DisplayPermissionError';

// Hooks
import useQuery from '../../hooks/useQuery';
import useUserState from '../../hooks/useUserState';
// Types
import { PermissionName } from '../../types/Auth';
import AuthorizedStudents from './AuthorizedStudents';
import GraduatedStudents from './GraduatedStudents';

enum TabName {
  Authorizeds = 'authorizeds',
  Graduateds = 'graduateds',
}

type TabRoute = {
  Component: () => JSX.Element;
  name: TabName;
};

// Constants
const TAB_ROUTES: TabRoute[] = [
  {
    Component: () => <AuthorizedStudents />,
    name: TabName.Authorizeds,
  },
  {
    Component: () => <GraduatedStudents />,
    name: TabName.Graduateds,
  },
];

const tabsPrefix = `interimDegreeGraduationProcess.students.tabs`;

export const MODULE_PERMISSIONS = [
  PermissionName.INTERIM_DEGREE_GRADUATION_PROCESS_ALL,
];

const InterimDegreeGraduationStudents = () => {
  const history = useHistory();
  const { path } = useRouteMatch();
  const params = useQuery();
  const { isAuthorizedTo } = useUserState();

  const { t } = useTranslation();

  const defaultTab = useMemo(() => {
    if (!TAB_ROUTES?.length) {
      return TabName.Authorizeds;
    }
    return TAB_ROUTES[0].name;
  }, []);

  const initialTab = useMemo(() => {
    const queryTab = params.get('tab');
    if (!queryTab) return defaultTab;

    const isValidTab = TAB_ROUTES.some((ar) => ar.name === queryTab);
    return isValidTab ? queryTab : defaultTab;
  }, [params, defaultTab]);

  const handleTabSelection = (selectedTab: number | string) => {
    history.push({ pathname: path, search: `?tab=${selectedTab}` });
    return null;
  };

  if (!isAuthorizedTo(MODULE_PERMISSIONS, true)) {
    return (
      <div className="w-full px-3">
        <DisplayPermissionError permissions={MODULE_PERMISSIONS} insideCard />
      </div>
    );
  }

  return (
    <div className="mx-3">
      <Tabs defaultTab={initialTab} onSelection={handleTabSelection}>
        <TabsList>
          {TAB_ROUTES.map((route) => (
            <Tab id={route.name} key={route.name}>
              {t(`${tabsPrefix}.${route.name}`)}
            </Tab>
          ))}
        </TabsList>
        <TabPanels>
          {TAB_ROUTES.map(({ Component, ...route }) => (
            <TabPanel
              id={route.name}
              key={route.name}
              children={<Component />}
            />
          ))}
        </TabPanels>
      </Tabs>
    </div>
  );
};

export default InterimDegreeGraduationStudents;
