import { addToast } from '@octano/global-ui';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'reactstrap';

import { CurriculumInfoBody } from '../../../../api/requests/studyPlans';
import { PeriodType } from '../../../../types/PeriodType';
import { getCurriculumInfoByStudyPlan } from '../api/study-plan-curriculums';

const style: any = {
  table: {
    border: '1px solid',
    borderRadius: '4px',
    padding: '12px 30px',
  },
};

interface CurriculumStudyPlanInfoProps {
  id: string;
  versionId: string;
  setStudyPlanName?: React.Dispatch<React.SetStateAction<string>>;
  setPeriodType?: React.Dispatch<React.SetStateAction<PeriodType | undefined>>;
}

const CurriculumStudyPlanInfo = (props: CurriculumStudyPlanInfoProps) => {
  const [
    curriculumHeadBody,
    setCurriculumHeadBody,
  ] = useState<CurriculumInfoBody>();
  const prefix = 'studyPlans.curriculum';
  const { t } = useTranslation();
  const { id, versionId } = props;

  const getCurriculumHeadBody = useCallback(async () => {
    const { data, error } = await getCurriculumInfoByStudyPlan(
      Number(id),
      Number(versionId),
    );
    if (data) {
      setCurriculumHeadBody(data.data);
    }
    if (error) {
      addToast({
        text: t(`${prefix}.errorOnSearch`),
        color: 'danger',
        icon: 'error',
      });
    }
  }, [id, versionId, t]);

  useEffect(() => {
    getCurriculumHeadBody();
  }, [getCurriculumHeadBody]);

  useEffect(() => {
    props?.setStudyPlanName &&
      props.setStudyPlanName(curriculumHeadBody?.name || '');
    !!(curriculumHeadBody?.periodType && props?.setPeriodType) &&
      props.setPeriodType(curriculumHeadBody?.periodType);
  }, [curriculumHeadBody, props]);

  return (
    <div
      style={style.table}
      className="border-secondary d-flex flex-column text-secondary mb-4 mb-md-0"
    >
      <Row>
        <Col xs={12} md={6}>
          <Row>
            <span
              style={{
                fontWeight: 'bold',
                textTransform: 'uppercase',
              }}
            >
              {t(`${prefix}.studyPlanName`)}
            </span>
          </Row>
          <Row>
            <span>{curriculumHeadBody?.name}</span>
          </Row>
        </Col>
        <Col xs={12} md={6}>
          <Row>
            <span
              style={{
                fontWeight: 'bold',
                textTransform: 'uppercase',
              }}
            >
              {t(`${prefix}.versionName`)}
            </span>
          </Row>
          <Row>
            <span>{curriculumHeadBody?.version}</span>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default CurriculumStudyPlanInfo;
