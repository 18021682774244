import { useCallback, useEffect, useState } from 'react';

import { Period } from '../../../api/requests/MantainerPortal';
import DisplayError from '../../../components/info/DisplayError';
import Loading from '../../../components/info/Loading';
import { useLoadingState } from '../../../hooks/useLoadingState';
import { getPeriodOFMantainerPortalV2 } from '../api';

export interface RequestsData {
  periods: Period[];
  currentPeriod?: Period;
  campuses: { id: number; name: string }[];
  schools: { id: number; name: string }[];
}

export interface PeriodsLoaderProps {
  renderPeriods: (data: RequestsData) => JSX.Element;
}

export const PeriodsLoader = ({ renderPeriods }: PeriodsLoaderProps) => {
  const [campuses, setCampuses] = useState<RequestsData['campuses']>([]);
  const [schools, setSchools] = useState<RequestsData['schools']>([]);
  const [periods, setPeriods] = useState<Period[]>([]);
  const [currentPeriod, setCurrentPeriod] = useState<Period>();
  const {
    loading,
    setLoading,
    errorLoading,
    setErrorLoading,
  } = useLoadingState();

  const getPeriods = useCallback(async () => {
    setLoading(true);
    const { data, error } = await getPeriodOFMantainerPortalV2();
    if (error) {
      setErrorLoading(error.code);
    } else if (data) {
      setErrorLoading(undefined);
      setPeriods(data.periods);
      setCurrentPeriod(data.currentPeriod);
      setCampuses(data.campuses);
      setSchools(data.schools);
    }
    setLoading(false);
  }, [setLoading, setErrorLoading]);

  useEffect(() => {
    getPeriods();
  }, [getPeriods]);

  if (loading) {
    return <Loading insideCard />;
  }

  if (errorLoading) {
    return <DisplayError insideCard textBody={errorLoading} />;
  }

  return renderPeriods({ periods, currentPeriod, campuses, schools });
};
