import {
  Modal,
  DisplayInfo,
  TextInput,
  TextAreaInput,
  CheckInput,
  Button,
  SelectOptionType,
} from '@octano/global-ui';
import React, {
  forwardRef,
  useImperativeHandle,
  useState,
  useCallback,
  useEffect,
  useMemo,
} from 'react';
import { InterimDegreeActivityFormItem } from './InterimDegreeForm';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { InterimDegreeActivity } from '../interfaces/interim-degree-activity.interface';
import { getInterimDegreeActivities } from '../../../../api/requests/academicOfferInterimDegree';
import RemoteSearchSelect from '../../../../components/form/RemoteSearchSelect';

export type InterimDegreeTableActivitiesModalMethods = {
  open: (item?: InterimDegreeActivityFormItem) => void;
  close: () => void;
};

interface InterimDegreeTableActivitiesModalProps {
  dictPrefix: string;
  onSave?: (item: InterimDegreeActivityFormItem) => void;
}

interface FormFields extends Omit<InterimDegreeActivityFormItem, 'code'> {
  code: SelectOptionType | null;
}

const InterimDegreeTableActivitiesModal = (
  { dictPrefix, onSave }: InterimDegreeTableActivitiesModalProps,
  ref: React.Ref<InterimDegreeTableActivitiesModalMethods>,
) => {
  const { t } = useTranslation();

  const [visible, setVisible] = useState<boolean>(false);
  const [mounted, setMounted] = useState<boolean>(false);
  const [activities, setActivities] = useState<InterimDegreeActivity[]>([]);

  const { control, reset, handleSubmit, setError, watch } = useForm<FormFields>(
    {
      defaultValues: {
        name: '',
        code: null,
        description: '',
        hasQualification: false,
      },
    },
  );

  const [selectedCode] = watch(['code']);

  const selectedActivity = useMemo(() => {
    return activities?.find((e) => e?.code === selectedCode?.value);
  }, [activities, selectedCode?.value]);

  const handleOpen = useCallback(
    (item?: InterimDegreeActivityFormItem) => {
      setVisible(true);
      if (item) {
        reset({ ...item, code: { label: item.code, value: item.code } });
      }
    },
    [reset],
  );

  const handleClose = useCallback(() => {
    setVisible(false);
    reset({
      name: '',
      code: null,
      description: '',
      hasQualification: false,
    });
  }, [reset]);

  useImperativeHandle(ref, () => ({
    open: handleOpen,
    close: handleClose,
  }));

  const handleValidate = useCallback(
    (values: FormFields) => {
      let hasErrors = false;
      if (!values?.name?.trim()) {
        setError('name', { message: t(`${dictPrefix}.fieldIsRequired`) });
        hasErrors = true;
      }
      if (!values?.code) {
        setError('code', { message: t(`${dictPrefix}.fieldIsRequired`) });
        hasErrors = true;
      }
      if (!values?.description?.trim()) {
        setError('description', {
          message: t(`${dictPrefix}.fieldIsRequired`),
        });
        hasErrors = true;
      }
      return !hasErrors;
    },
    [t, dictPrefix, setError],
  );

  const handleSave = useCallback(
    (values: FormFields) => {
      if (!handleValidate(values)) {
        return;
      }
      handleClose();
      !!onSave &&
        onSave({ ...values, code: (values?.code?.value ?? '') as any });
    },
    [handleClose, onSave, handleValidate],
  );

  const fetchActivities = useCallback(async () => {
    const response = await getInterimDegreeActivities();
    if (!response?.error && response?.data?.length) {
      setActivities(response?.data);
    }
  }, []);

  const searchActivities = useCallback(
    async (search: string) => {
      search = search?.trim();
      let filtereds = [...activities].map((e) => ({
        label: e.code,
        value: e.code,
      }));

      filtereds = filtereds.filter((e) =>
        e.label.toLowerCase().includes(search?.toLowerCase()),
      );

      if (!filtereds?.some((e) => e.value === search)) {
        filtereds.push({
          label: search,
          value: search,
        });
      }

      return filtereds;
    },
    [activities],
  );

  useEffect(() => {
    if (!mounted) {
      fetchActivities();
      setMounted(true);
    }
  }, [mounted, fetchActivities]);

  useEffect(() => {
    if (selectedActivity) {
      reset({
        ...selectedActivity,
        code: { label: selectedActivity.code, value: selectedActivity.code },
      });
    }
  }, [selectedActivity, reset]);

  return (
    <Modal
      isOpen={visible}
      closeOnBackdrop={false}
      toggle={handleClose}
      size="md"
      unmountOnClose
    >
      <DisplayInfo
        title={t(`${dictPrefix}.createModal.title`)}
        textBody={t(`${dictPrefix}.createModal.description`)}
        iconName="academic_offer"
      />
      <div className="w-100">
        <RemoteSearchSelect
          control={control}
          name="code"
          filterOptions={searchActivities}
          label={t(`${dictPrefix}.labels.code`)}
        />
        <TextInput
          control={control}
          readOnly={!!selectedActivity}
          name="name"
          label={t(`${dictPrefix}.labels.name`)}
          placeholder="..."
        />
        <TextAreaInput
          control={control}
          readOnly={!!selectedActivity}
          name="description"
          label={t(`${dictPrefix}.labels.description`)}
          placeholder="..."
          className="mb-3"
        />
        <CheckInput
          control={control}
          readOnly={!!selectedActivity}
          name="hasQualification"
          label={t(`${dictPrefix}.labels.qualification`)}
        />
        <div className="d-flex flex-wrap w-100 align-items-center justify-content-center">
          <div className="flex-fill px-2" />
          <Button
            type="button"
            outlined
            text={t(`${dictPrefix}.actions.cancel`)}
            onClick={handleClose}
            size="md"
          />
          <Button
            type="button"
            text={t(`${dictPrefix}.actions.save`)}
            className="ml-2"
            size="md"
            onClick={handleSubmit(handleSave)}
          />
        </div>
      </div>
    </Modal>
  );
};

export default forwardRef(InterimDegreeTableActivitiesModal);
