import { IconName } from '@octano/global-ui';

export interface PeriodType {
  id: number;
  periodCode: string;
  sectionCount: number;
  packageCount: number;
  lastLoadDate: string | null;
  loadingDeadline: string | null;
  state: PeriodStates;
}

export type PeriodStates = 'SIN_CARGA' | 'CARGADO' | 'CERRADO';

export type IconColors =
  | 'primary'
  | 'secondary'
  | 'danger'
  | 'white'
  | 'info'
  | 'inherit'
  | 'success'
  | 'disabled';

export interface PeriodStateIcons {
  ERROR: {
    name: IconName;
    color: IconColors;
  };
  WARNING: {
    name: IconName;
    color: IconColors;
  };
  SUCCESS: {
    name: IconName;
    color: IconColors;
  };
}

export const PERIOD_STATES = {
  EMPTY: 'SIN_CARGA',
  LOADED: 'CARGADO',
  CLOSED: 'CERRADO',
};

export const PERIOD_STATES_TRANSLATION = {
  [PERIOD_STATES.EMPTY]: 'empty',
  [PERIOD_STATES.LOADED]: 'loaded',
  [PERIOD_STATES.CLOSED]: 'closed',
};

export const PERIOD_STATE_ICONS: PeriodStateIcons = {
  ERROR: { name: IconName.error, color: 'danger' },
  WARNING: { name: IconName.warning, color: 'info' },
  SUCCESS: { name: IconName.success, color: 'success' },
};

export const PERIOD_STATE_BUTTONS = {
  [PERIOD_STATES.EMPTY]: 'loadButton',
  [PERIOD_STATES.LOADED]: 'editButton',
  [PERIOD_STATES.CLOSED]: 'viewButton',
};
