import { ReactElement, useCallback } from 'react';
import { DefaultValues } from 'react-hook-form';

import { getTariffInfo } from '../../../../../../../api/requests/tuitionProcess';
import { createFetchContext } from '../../../../../../../components/contexts/FetchContextFactory';
import DisplayError from '../../../../../../../components/info/DisplayError';
import Loading from '../../../../../../../components/info/Loading';
import { useParameters } from '../../../../../../../hooks/useParameters';
import { TariffData } from '../../../../../../../types/tariffTypes';
import { useTuitionProcessNoSua } from '../../../hooks/useTuitionProcessNoSua';
import tariffDefaultValues from '../utils/tariffFormatPreviusData';

/**
 * debería moverse data de este loader al base loader.
 */
export interface TariffLoaderProps<T> {
  options: any;
  children: (props: {
    tariffData: T;
    defaultValues: DefaultValues<any>;
    refresh: () => Promise<void>;
  }) => ReactElement;
}

const { FetchProvider, FetchConsumer } = createFetchContext<
  undefined,
  TariffData
>();

export const SustainerLoaderConsumer = FetchConsumer;

export default function TariffLoader({
  options,
  children,
}: TariffLoaderProps<TariffData>) {
  const { cityOptions } = useParameters();
  const { postulationDetail } = useTuitionProcessNoSua();

  const request = useCallback(async () => {
    return getTariffInfo(postulationDetail!.id);
  }, [postulationDetail]);

  return (
    <FetchProvider request={request} defaultQuery={undefined} fetchImmediately>
      <FetchConsumer>
        {({ data: tariffData, loading, error, refresh }) => {
          if (error) {
            if (error.code === 'HTTP_ERROR' && error.status === 401) {
              //setIsSessionExpired(true);
            }

            return (
              <DisplayError
                insideCard
                textBody={error.code}
                retryAction={refresh}
                loadingAction={loading}
              />
            );
          }

          if (loading) {
            return <Loading insideCard />;
          }

          if (!tariffData) {
            return (
              <DisplayError
                insideCard
                textBody="Data no cargada"
                retryAction={refresh}
                loadingAction={loading}
              />
            );
          }

          return children({
            defaultValues: tariffDefaultValues(
              { ...options, cityOptions },
              tariffData,
            ),
            tariffData,
            refresh,
          });
        }}
      </FetchConsumer>
    </FetchProvider>
  );
}
