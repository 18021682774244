import { addToast, Button } from '@octano/global-ui';
import { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Card, Col, Container, Row } from 'reactstrap';
import { createScheduleRequest } from '../../../api/requests/schedules';
import GoBackButton from '../../../components/buttons/GoBackButton';
import { useLayoutState } from '../../../hooks/useLayoutState';
import ScheduleForm from './ScheduleForm';

const ScheduleRecordCreate = () => {
  const { hideTabs } = useLayoutState();
  useEffect(() => {
    hideTabs();
  }, [hideTabs]);

  let history = useHistory();
  const goBack = useCallback(() => {
    history.push(`/maintainers/schedules`);
  }, [history]);

  const prefix = 'maintainers.scheduleForm';
  const { t } = useTranslation();

  const onSubmit = useCallback(
    async (values) => {
      const response = await createScheduleRequest(values);
      if (!response.error) {
        addToast({
          icon: 'success',
          color: 'success',
          text: t(`${prefix}.createSuccess`),
        });
        goBack();
      } else {
        return response.error.code;
      }
    },
    [goBack, t],
  );

  return (
    <Container>
      <Card className="p-3 p-md-4">
        <div className="mb-4">
          <GoBackButton onClick={goBack} />
        </div>
        <ScheduleForm onSubmit={onSubmit}>
          {({ isSubmitting }) => (
            <Row>
              <Col
                xs={{ order: 2, size: 12 }}
                md={{ order: 1, size: 4 }}
                className="ml-auto"
              >
                <div>
                  <Button
                    type="button"
                    text={t(`common.actions.cancel`)}
                    outlined
                    onClick={goBack}
                    fullwidth
                  />
                </div>
              </Col>

              <Col xs={{ order: 1, size: 12 }} md={{ order: 2, size: 4 }}>
                <div className="pb-3 pb-md-0">
                  <Button
                    type="submit"
                    text={t(`${prefix}.createSchedule`)}
                    loading={isSubmitting}
                    fullwidth
                  />
                </div>
              </Col>
            </Row>
          )}
        </ScheduleForm>
      </Card>
    </Container>
  );
};

export default ScheduleRecordCreate;
