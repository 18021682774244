import request from '../../api/request';

const CURRENT_ACCOUNT = {
  SEARCH_STUDEN: `/maintainers-portal/current-accounts/students/search/`,
};

export default function searchStudent(query: {
  identifier: string | number;
  country: string | null;
}) {
  return request<{ studentId: number; rutOrPassport: string; name: string }>(
    CURRENT_ACCOUNT.SEARCH_STUDEN,
    {
      params: query,
    },
  );
}
