import { DetailBox } from '@octano/global-ui';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

export interface TuitionContinuityProcessManagementDetailBoxProps {
  studyPlanVersionName: string;
  campusName: string;
  scheduleName: string;
}

export function TuitionContinuityProcessManagementDetailBox({
  studyPlanVersionName,
  campusName,
  scheduleName,
}: TuitionContinuityProcessManagementDetailBoxProps) {
  const { t } = useTranslation();
  const prefix = 'tuitionContinuityProcessManagementDetail.box';

  const texts = useMemo(
    () => ({
      studyPlanVersionName: t(`${prefix}.studyPlanVersion`),
      campusName: t(`${prefix}.campus`),
      scheduleName: t(`${prefix}.schedule`),
    }),
    [t],
  );

  const headerColumns = useMemo(() => {
    return [
      {
        title: texts.studyPlanVersionName,
        body: studyPlanVersionName,
        size: { xs: 12, md: 6 },
      },
      {
        title: texts.campusName,
        body: campusName,
        size: { xs: 6, md: 3 },
      },
      {
        title: texts.scheduleName,
        body: scheduleName,
        size: { xs: 6, md: 3 },
      },
    ];
  }, [texts, studyPlanVersionName, campusName, scheduleName]);

  return <DetailBox columns={headerColumns} />;
}
