import { Button } from '@octano/global-ui';
import { useCallback } from 'react';

interface DownloadDocumentProps {
  userFileName: string;
  fileURL: string;
  buttonText: string;
  outlined: boolean;
}

const DownloadDocumentButton = ({
  userFileName,
  fileURL,
  buttonText,
  outlined,
}: DownloadDocumentProps): JSX.Element => {
  const fetchLinkDownloadDocument = useCallback(() => {
    let link = document.createElement('a');
    link.download = userFileName;
    link.href = fileURL;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }, [fileURL, userFileName]);

  return (
    <Button
      type="button"
      text={buttonText}
      size="md"
      icon="download"
      outlined={outlined}
      color="primary"
      className="float-right"
      onClick={() => fetchLinkDownloadDocument()}
    />
  );
};

export default DownloadDocumentButton;
