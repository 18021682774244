import { Button, ColumnTable } from '@octano/global-ui';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import Tooltip from '../../../components/tooltip/Tooltip';
import { SectionMaintainerListType } from '../../../types/sectionMaintainerTypes';

export const useColumnsSectionsList = () => {
  const prefix = 'sectionsMaintainer.list.columns';
  const { t } = useTranslation();
  const history = useHistory();

  const columns: ColumnTable<SectionMaintainerListType>[] = useMemo(() => {
    return [
      {
        columnName: 'period',
        width: '10%',
        thClassName: 'text-center',
        tdClassName: 'text-center text-nowrap',
        headerText: t(`${prefix}.period`),
        cellFormat: ({ row }) => row.period.name,
      },
      {
        columnName: 'name',
        width: '10%',
        thClassName: 'text-center',
        tdClassName: 'text-center',
        headerText: t(`${prefix}.sectionCode`),
      },
      {
        columnName: 'course',
        width: '10%',
        thClassName: 'text-center',
        tdClassName: 'text-center',
        headerText: t(`${prefix}.courseCode`),
        cellFormat: ({ row }) => row.course.shortening,
      },
      {
        columnName: 'course',
        width: '15%',
        thClassName: 'text-center',
        tdClassName: 'text-center',
        headerText: t(`${prefix}.courseName`),
        cellFormat: ({ row }) => row.course.name,
      },
      {
        columnName: 'course',
        width: '15%',
        thClassName: 'text-center',
        tdClassName: 'text-center',
        headerText: t(`${prefix}.faculty`),
        cellFormat: ({ row }) => row.course?.school?.name,
      },
      {
        columnName: 'course',
        width: '10%',
        thClassName: 'text-center',
        tdClassName: 'text-center',
        headerText: t(`${prefix}.campus`),
        cellFormat: ({ row }) => row.campus.name,
      },
      {
        columnName: 'teacher',
        width: '20%',
        thClassName: 'text-center',
        tdClassName: 'text-center text-nowrap',
        headerText: t(`${prefix}.teacher`),
        cellFormat: ({ row }) => {
          return row.professors.map((prof) => {
            return (
              <div key={`${row.id}_${prof.id}`}>{prof?.account?.fullName}</div>
            );
          });
        },
      },

      {
        columnName: 'id',
        width: '10%',
        thClassName: 'text-center',
        tdClassName: 'text-center text-nowrap',
        headerText: t(`common.actions.action`),
        cellFormat: ({ row }) => {
          return (
            <>
              {row.closedAt || row.closedWithId ? (
                <Tooltip id={`_${row.id}_x`} text={t(`${prefix}.closed`)}>
                  <Button
                    size="sm"
                    text={t(`common.actions.update`)}
                    onClick={() => {
                      history.push(`/sections-maintainer/${row.id}`);
                    }}
                    fullwidth
                    disabled
                  />
                </Tooltip>
              ) : (
                <Button
                  size="sm"
                  text={t(`common.actions.update`)}
                  onClick={() => {
                    history.push(`/sections-maintainer/${row.id}`);
                  }}
                  fullwidth
                />
              )}
            </>
          );
        },
      },
    ];
  }, [t, history]);

  return columns;
};
