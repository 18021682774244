import { Modal } from '@octano/global-ui';
import { Card } from 'reactstrap';

import PetitionsExcelReportForm, {
  SearchParamsExcelF,
  ShowSearchParamsExcelF,
} from './PetitionsExcelReportForm';

interface PetitionsExcelReportModalProps {
  texts: any;
  show: boolean;
  toggleModal: () => void;
  onDownload?: (requestBody?: any) => void;
  filters: SearchParamsExcelF;
  showFilters?: ShowSearchParamsExcelF;
}

export const PetitionsExcelReportModal = ({
  texts,
  show,
  toggleModal,
  onDownload = () => null,
  filters,
  showFilters,
}: PetitionsExcelReportModalProps) => {
  return (
    <Modal isOpen={show} toggle={toggleModal} size="lg" unmountOnClose>
      <Card className="align-items-center font-600 mb-4">
        <h3>{texts.title}</h3>
        <p>{texts.subTitle}</p>
      </Card>

      <section className="petition-form mb-4">
        <PetitionsExcelReportForm
          texts={texts}
          filters={filters}
          onDownload={onDownload}
          onCancel={toggleModal}
          showFilters={showFilters}
        />
      </section>
    </Modal>
  );
};
