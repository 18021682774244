import { Button, FileInput, Modal, TextAreaInput } from '@octano/global-ui';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'reactstrap';

import { showToast } from '../../../utils/toast';
import {
  uploadDocumentOfPostulantProcess,
  uploadIdentityCardPostulantProcess,
  uploadOtherDocumentOfPostulantProcess,
} from '../api';

export function ModalAddDocument(props: {
  open: boolean;
  onClose: () => void;
  isOtherDocument: boolean;
  title?: string;
  details_id: number;
  document?: {
    type: string;
    side?: string;
  };
  onConfirm?: () => void;
}) {
  const {
    open,
    onClose,
    isOtherDocument: isNewDocument,
    title,
    document,
    onConfirm,
    details_id,
  } = props;
  const { t } = useTranslation();
  const [loading, setLoading] = useState<boolean>(false);
  const [file, setFile] = useState<any | null>();
  let [description, setDescription] = useState<string>('');

  const saveChange = useCallback(async () => {
    if (file && document) {
      const formData = new FormData();
      formData.append('doc', file);
      formData.append('type', document.type);
      if (document.side) {
        formData.append('side', document.side);
      }
      let { error } = document.side
        ? await uploadIdentityCardPostulantProcess(details_id, formData)
        : await uploadDocumentOfPostulantProcess(details_id, formData);
      if (error) {
        showToast(true, t);
      } else {
        showToast(false, t);
        if (onConfirm) onConfirm();
      }
    }
  }, [file, details_id, document, t, onConfirm]);

  const saveOtherDocument = useCallback(async () => {
    setLoading(true);
    if (file) {
      const formData = new FormData();
      formData.append('postulationDetailId', `${details_id}`);
      if (description !== '') formData.append('description', description);
      formData.append('doc', file);
      let { error } = await uploadOtherDocumentOfPostulantProcess(formData);
      if (error) {
        showToast(true, t);
      } else {
        showToast(false, t);
      }
    }

    if (onConfirm) onConfirm();
  }, [file, t, description, details_id, onConfirm]);

  const submit = useCallback(async () => {
    setLoading(true);
    if (isNewDocument) {
      await saveOtherDocument();
    } else {
      await saveChange();
    }
    setLoading(false);
    setFile(null);
    onClose();
    setDescription('');
  }, [
    isNewDocument,
    saveOtherDocument,
    saveChange,
    setFile,
    onClose,
    setDescription,
  ]);

  const onChange = useCallback(
    (e) => {
      if (e) {
        setFile(e);
      } else {
        setFile(null);
      }
    },
    [setFile],
  );
  return (
    <Modal isOpen={open} toggle={onClose} size="md">
      <h6
        className="fs-22 text-center text-black"
        style={{ fontWeight: '600' }}
      >
        {title ? title : t('documents.studyDocuments.modalAddDocument.title')}
      </h6>
      <p className="fs-16 text-light text-center mt-3">
        {!isNewDocument
          ? t('documents.studyDocuments.modalAddDocument.subtitle.oldDocument')
          : t('documents.studyDocuments.modalAddDocument.subtitle.newDocument')}
      </p>
      <FileInput
        name="document"
        label="Archivo"
        maxFiles={1}
        btnText={t('documents.studyDocuments.modalAddDocument.upload')}
        value={file}
        onChange={onChange}
        accept={
          'image/png, image/jpeg, application/pdf, application/xls, application/xlsx, application/docx'
        }
      />
      {isNewDocument && (
        <div className="mt-2">
          <TextAreaInput
            name="description"
            label={t('documents.studyDocuments.modalAddDocument.description')}
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          />
        </div>
      )}
      <Row className="mt-5">
        <Col>
          <Button
            text={t('documents.studyDocuments.modalAddDocument.cancel')}
            fullwidth
            outlined
            onClick={() => {
              setFile(null);
              setDescription('');
              onClose();
            }}
          />
        </Col>
        <Col>
          <Button
            type="button"
            text={t('documents.studyDocuments.modalAddDocument.save')}
            fullwidth
            loading={loading}
            onClick={submit}
          />
        </Col>
      </Row>
    </Modal>
  );
}
