import { Button, addToast } from '@octano/global-ui';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';

import {
  downloadErrorDocument,
  uploadSectionsDocument,
} from '../../api/requests/sectionsAndPackages';
import ConfirmationModal from '../../components/modals/ConfirmationModal';
import { PathsLayouts } from '../../config/routes';
import UploadSectionsModal from './UploadSectionsModal';

enum ModalType {
  NONE,
  LOADING,
  UPLOAD_SECTIONS_FILE,
  UPLOAD_SUCCESS,
  UNEXPECTED_ERROR,
  FILE_DATA_ERROR,
  FILE_NO_CONTENT_ERROR,
  CUSTOM_ERROR,
  INVALID_FILE_STRUCTURE_ERROR,
}

interface UploadSectionsProps {
  beforeUpload?: () => void;
  afterUpload?: () => void;
  beforeDownload?: () => void;
  afterDownload?: () => void;
  setOpenModal?: (bool: boolean) => void;
  disabled?: boolean;
  loadAgain?: boolean;
}

const UploadSections = ({
  beforeUpload,
  afterUpload,
  beforeDownload,
  afterDownload,
  setOpenModal,
  disabled,
  loadAgain,
}: UploadSectionsProps): JSX.Element => {
  const { t } = useTranslation();
  const prefix = 'packagesMaintainer.upload';
  const [openedModal, setOpenedModal] = useState<ModalType>();
  const [errorDocumentId, setErrorDocumentId] = useState<number>(0);
  const [customErrorMessage, setCustomErrorMessage] = useState<string>('');
  const { periodId } = useParams<{ periodId?: string }>();

  const history = useHistory();

  const handleFileUpload = useCallback(
    async (file: File) => {
      const data = new FormData();
      data.append('file', file);

      if (beforeUpload) beforeUpload();

      const response = await uploadSectionsDocument(periodId || '', data);

      if (response.data) {
        setOpenedModal(ModalType.UPLOAD_SUCCESS);
      } else if (response.error && response.error.data.statusCode === 204) {
        setOpenedModal(ModalType.FILE_NO_CONTENT_ERROR);
      } else if (response.error && response.error.data.statusCode === 400) {
        if (response.error.data.error_file_id) {
          setErrorDocumentId(response.error.data.error_file_id);
          setOpenedModal(ModalType.FILE_DATA_ERROR);
        } else if (response.error.data.message) {
          setCustomErrorMessage(response.error.data.message);
          setOpenedModal(ModalType.CUSTOM_ERROR);
        } else {
          setOpenedModal(ModalType.UNEXPECTED_ERROR);
        }
      } else {
        setOpenedModal(ModalType.UNEXPECTED_ERROR);
      }

      if (setOpenModal) setOpenModal(false);
    },
    [beforeUpload, setOpenModal, periodId],
  );

  const goToPeriodDetails = useCallback(
    (id: string = '') =>
      history.push(`${PathsLayouts.packagesMaintainer}/${id}`),
    [history],
  );

  const handleErrorFileDownload = useCallback(
    async (id: number) => {
      if (beforeDownload) beforeDownload();
      const res = await downloadErrorDocument(id);

      if (res.error) {
        addToast({
          icon: 'error',
          color: 'danger',
          text: t(`${prefix}.fileDataErrorModal.errorDownloadingFile`),
        });
      } else {
        let link = document.createElement('a');
        link.download = 'Archivo_Error_Carga_Paquetes_y_Secciones';
        link.href = res.data.url;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
      if (afterDownload) afterDownload();
    },
    [beforeDownload, afterDownload, t],
  );

  return (
    <>
      {/* MODAL DE SUBIDA DE ARCHIVO */}
      <UploadSectionsModal
        isOpen={openedModal === ModalType.UPLOAD_SECTIONS_FILE}
        onCancel={() => setOpenedModal(ModalType.NONE)}
        onConfirm={handleFileUpload}
      />

      {/* MODAL DE SUCCESS */}
      <ConfirmationModal
        iconName="success"
        isOpen={openedModal === ModalType.UPLOAD_SUCCESS}
        toggle={() => setOpenedModal(ModalType.NONE)}
        title={t(`${prefix}.successModal.title`)}
        body={t(`${prefix}.successModal.body`)}
        primaryBtn={{
          text: t(`common.actions.understood`),
          action: () => {
            setOpenedModal(ModalType.NONE);
            goToPeriodDetails(periodId);
            if (afterUpload) afterUpload();
          },
        }}
      />

      {/* MODAL DE ERROR INESPERADO */}
      <ConfirmationModal
        iconName="error"
        iconColor="danger"
        isOpen={openedModal === ModalType.UNEXPECTED_ERROR}
        toggle={() => setOpenedModal(ModalType.NONE)}
        title={t(`${prefix}.unexpectedErrorModal.title`)}
        body={t(`${prefix}.unexpectedErrorModal.body`)}
        primaryBtn={{
          text: t(`${prefix}.unexpectedErrorModal.button`),
          action: () => setOpenedModal(ModalType.UPLOAD_SECTIONS_FILE),
        }}
      />

      {/* MODAL DE CUSTOM ERROR, (ERRORES VARIOS) STATUS 400 */}
      <ConfirmationModal
        iconName="error"
        iconColor="danger"
        isOpen={openedModal === ModalType.CUSTOM_ERROR}
        toggle={() => setOpenedModal(ModalType.NONE)}
        title={t(`${prefix}.customErrorModal.title`)}
        body={customErrorMessage}
        primaryBtn={{
          text: t(`${prefix}.unexpectedErrorModal.button`),
          action: () => setOpenedModal(ModalType.UPLOAD_SECTIONS_FILE),
        }}
      />

      {/* MODAL DE ERROR EN LA ESTRUCTURA DEL ARCHIVO / ARCHIVO INVÁLIDO / STATUS CODE 400 SIN ID */}
      <ConfirmationModal
        iconName="error"
        iconColor="danger"
        isOpen={openedModal === ModalType.INVALID_FILE_STRUCTURE_ERROR}
        toggle={() => setOpenedModal(ModalType.NONE)}
        title={t(`${prefix}.invalidFileErrorModal.title`)}
        body={t(`${prefix}.invalidFileErrorModal.body`)}
        primaryBtn={{
          text: t(`common.actions.download`),
          action: () => {
            handleErrorFileDownload(errorDocumentId);
            setOpenedModal(ModalType.NONE);
          },
        }}
      />

      {/* MODAL DE ERROR EN LOS DATOS DEL ARCHIVO / STATUS CODE 400 CON ID */}
      <ConfirmationModal
        iconName="error"
        iconColor="danger"
        isOpen={openedModal === ModalType.FILE_DATA_ERROR}
        toggle={() => setOpenedModal(ModalType.NONE)}
        title={t(`${prefix}.fileDataErrorModal.title`)}
        body={t(`${prefix}.fileDataErrorModal.body`)}
        primaryBtn={{
          text: t(`common.actions.download`),
          action: () => {
            handleErrorFileDownload(errorDocumentId);
            setOpenedModal(ModalType.NONE);
          },
        }}
      />

      {/* MODAL DE ERROR EN LOS DATOS DEL ARCHIVO, NO POSEE CONTENIDO / STATUS CODE 204 */}
      <ConfirmationModal
        iconName="error"
        iconColor="danger"
        isOpen={openedModal === ModalType.FILE_NO_CONTENT_ERROR}
        toggle={() => setOpenedModal(ModalType.NONE)}
        title={t(`${prefix}.noContentErrorModal.title`)}
        body={t(`${prefix}.noContentErrorModal.body`)}
        primaryBtn={{
          text: t(`${prefix}.unexpectedErrorModal.button`),
          action: () => setOpenedModal(ModalType.UPLOAD_SECTIONS_FILE),
        }}
      />

      <div
        className={`upload-doc-container text-center ${
          disabled ? 'disabled' : ''
        }`}
      >
        <p className="fs-18 fw-700 text-uppercase title">
          {t(`${prefix}.title`)}
        </p>
        <p className="fs-14">{t(`${prefix}.description`)}</p>
        <Button
          size="md"
          color="primary"
          text={loadAgain ? t(`${prefix}.loadAgain`) : t(`${prefix}.button`)}
          onClick={() => setOpenedModal(ModalType.UPLOAD_SECTIONS_FILE)}
          disabled={disabled}
        />
      </div>
    </>
  );
};

export default UploadSections;
