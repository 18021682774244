import { PARAMETERS } from '../endpoints';
import request from '../request';

/**
 * Obtiene el listado de roles
 */
export const getRoles = () =>
  request<{ id: string; key: string; name: string; description: string }[]>(
    PARAMETERS.ROLES,
  );
