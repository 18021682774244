import { ReactNode, useCallback } from 'react';

import { getTcDocumentReviewDocuments } from '../../../api/requests/tuitionContinuityDocumentReviewDocuments';
import FetchProvider from '../../../components/contexts/FetchContext';

export interface TuitionContinuityDocumentReviewDocumentsLoaderProps {
  children: ReactNode;
  tcProcessId: number | string;
}

export function TuitionContinuityDocumentReviewDocumentsLoader({
  tcProcessId,
  children,
}: TuitionContinuityDocumentReviewDocumentsLoaderProps) {
  const request = useCallback(async () => {
    return getTcDocumentReviewDocuments(tcProcessId);
  }, [tcProcessId]);
  return (
    <FetchProvider request={request} fetchImmediately defaultQuery={undefined}>
      {children}
    </FetchProvider>
  );
}
