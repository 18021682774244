import request from '../../../../../api/request';
import { PersonalBackgroundFormDataResponse } from './type';

const PERSONAL_BACKGROUND = {
  FORM_DATA: (teacherId: number) =>
    `maintainers-portal/teacher-file/${teacherId}/personal-background/form-data`,
  SAVE_DYNAMIC_FORM: (teacherId: number) =>
    `maintainers-portal/teacher-file/${teacherId}/personal-background/`,
  UPLOAD_PHOTO: (teacherId: number) =>
    `maintainers-portal/teacher-file/${teacherId}/personal-background/upload-photo`,
};

export async function getFormData(teacherId: number) {
  return request<PersonalBackgroundFormDataResponse>(
    PERSONAL_BACKGROUND.FORM_DATA(teacherId),
    {
      method: 'GET',
    },
  );
}
export async function saveDynamicForm(teacherId: number, data: any) {
  return request(PERSONAL_BACKGROUND.SAVE_DYNAMIC_FORM(teacherId), {
    method: 'POST',
    data,
  });
}

export async function uploadPhoto(teacherId: number, photo?: File) {
  const formData = new FormData();
  if (photo) {
    formData.append('photo', photo);
  }
  return request<unknown>(PERSONAL_BACKGROUND.UPLOAD_PHOTO(teacherId), {
    method: 'POST',
    data: formData,
  });
}
