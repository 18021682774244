import clsx from 'clsx';
import { useCallback } from 'react';
import { Nav, NavLink } from 'reactstrap';

export interface TabsProps {
  tabs: string[];
  value: number;
  onChange: (index: number) => void;
  fullwidth?: boolean;
}

export const Tabs = ({
  tabs,
  value,
  onChange,
  fullwidth = false,
}: TabsProps) => {
  const toggleTab = useCallback(
    (index: number) => {
      onChange(index);
    },
    [onChange],
  );

  return (
    <>
      {/** DIV PARA TELEFONOS  */}
      <div className="d-block d-sm-none">
        <div className="g-tabs-container g-tabs-container-fix">
          <Nav className="g-tabs flex-nowrap overflow-auto hide-scroll" tabs>
            {tabs.map((tab, index) => {
              return (
                <NavLink
                  style={{ width: '100%' }}
                  key={index}
                  className={clsx({
                    'active': value === index,
                    'flex-fill': fullwidth,
                  })}
                  onClick={() => toggleTab(index)}
                >
                  <h1> {tab} </h1>
                </NavLink>
              );
            })}
          </Nav>
        </div>
      </div>

      {/** DIV PARA TODAS LAS PANTALLAS  */}
      <div className="d-none d-sm-block">
        <div className="g-tabs-container">
          <Nav className="g-tabs" tabs>
            {tabs.map((tab, index) => (
              <NavLink
                key={index}
                className={clsx({
                  'active': value === index,
                  'flex-fill': fullwidth,
                })}
                onClick={() => toggleTab(index)}
              >
                <h1> {tab} </h1>
              </NavLink>
            ))}
          </Nav>
        </div>
      </div>
    </>
  );
};
