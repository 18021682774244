import { SectionDetail } from '../../../types/sectionMaintainerTypes';
import SchedulesLoader from '../contexts/SchedulesLoader';
import SectionSchedules from './SectionSchedules';

export default function SectionUpdateSchedules({
  section,
}: {
  section: SectionDetail;
}) {
  return (
    <SchedulesLoader sectionId={section.id}>
      {({ data, refresh }) => (
        <div className="mt-2 mx-3">
          <SectionSchedules data={data} onRefresh={refresh} />
        </div>
      )}
    </SchedulesLoader>
  );
}
