import { Select, SelectOptionType, TextInput } from '@octano/global-ui';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'reactstrap';

import RemoteSearchSelect from '../../../components/form/RemoteSearchSelect';
import { useSearchAssistant } from '../../../hooks/remoteSearchSelect/useSearchAssistant';
import { useSearchTeachers } from '../../../hooks/remoteSearchSelect/useSearchTeachers';
import { useValidations } from '../../../hooks/useValidations';
import CreateAssistantButton from './CreateAssistantButton';
import ExtraFields from './ExtraFields';
import { SectionValuesForm } from './SectionForm';

type SectionFormFieldsProps = {
  sectionId: string;
  campusOptions: SelectOptionType[];
  setIsModalOpen: (value: boolean) => void;
};

const SectionFormFields = ({
  sectionId,
  campusOptions,
  setIsModalOpen,
}: SectionFormFieldsProps) => {
  const { t } = useTranslation();
  const prefix = 'sectionsMaintainer.update';
  const { msgValidations, rulesPatternNumeric } = useValidations();

  const { control } = useFormContext<SectionValuesForm>();
  const searchTeachers = useSearchTeachers(sectionId);
  const searchAssistants = useSearchAssistant(sectionId);

  return (
    <Row>
      <Col xs={12} md={6}>
        <TextInput
          name="quota"
          label={t(`${prefix}.quotaLabel`)}
          control={control}
          rules={{
            required: msgValidations.required,
            pattern: rulesPatternNumeric,
          }}
        />
      </Col>
      <Col xs={12} md={6}>
        <Select
          name="campus"
          label={t(`${prefix}.campusLabel`)}
          options={campusOptions}
          control={control}
          rules={{ required: msgValidations.required }}
        />
      </Col>
      <Col xs={12} md={6}>
        <Row>
          <Col>
            <RemoteSearchSelect
              name="teacher"
              label={t(`${prefix}.teacher`)}
              filterOptions={searchTeachers}
              control={control}
              rules={{ required: msgValidations.required }}
            />
          </Col>
        </Row>
        <ExtraFields
          title="teachers"
          inputLabel={t(`${prefix}.teacher`)}
          search={searchTeachers}
          buttonLabel={t(`${prefix}.addTeacher`)}
        />
      </Col>
      <Col xs={12} md={6}>
        <Row>
          <Col>
            <RemoteSearchSelect
              name="assistant"
              label={t(`${prefix}.assistant`)}
              filterOptions={searchAssistants}
              control={control}
            />
          </Col>
        </Row>
        <ExtraFields
          title="assistants"
          inputLabel={t(`${prefix}.assistant`)}
          search={searchAssistants}
          buttonLabel={t(`${prefix}.addAssistant`)}
          children={
            <CreateAssistantButton onClick={() => setIsModalOpen(true)} />
          }
        />
      </Col>
    </Row>
  );
};

export default SectionFormFields;
