import { useMemo } from 'react';

import Course, { CourseProps } from './Course';
import { SemesterCourse } from './types';

export interface CoursesBySemesterProps {
  title: string;
  texts: { credits: string };
  getColor: (state?: string) => string | undefined;
  onClick?: (semesterCourse: SemesterCourse) => void;
  semesterCourses: SemesterCourse[];
}

export default function CoursesBySemester({
  title,
  texts,
  getColor,
  onClick: onClickProp,
  semesterCourses,
}: CoursesBySemesterProps) {
  const courses = useMemo<
    Array<Omit<CourseProps, 'texts'> & { key: number }>
  >(() => {
    return semesterCourses.map((semesterCourse) => {
      let onClick = undefined;
      if (onClickProp && !semesterCourse.state) {
        onClick = () => onClickProp(semesterCourse);
      }
      if (semesterCourse.course) {
        return {
          key: semesterCourse.id,
          name: semesterCourse.course.name,
          code: semesterCourse.course.code,
          credits: semesterCourse.course.credits,
          color: getColor(
            semesterCourse.state === 'A' && semesterCourse.isValidated
              ? 'V'
              : semesterCourse.state,
          ),
          onClick,
        };
      } else {
        return {
          key: semesterCourse.id,
          name: semesterCourse.courseType.name,
          credits: semesterCourse.optionalSlot?.credits,
          color: getColor(semesterCourse.state),
          onClick,
        };
      }
    });
  }, [onClickProp, semesterCourses, getColor]);

  return (
    <div>
      <div className="d-block text-center mb-3 px-1">{title}</div>
      <div
        className="d-flex"
        style={{
          flexDirection: 'column',
          gap: 16,
        }}
      >
        {courses.map((course) => (
          <Course {...course} texts={texts} />
        ))}
      </div>
    </div>
  );
}
