import { Button } from '@octano/global-ui';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

interface GoBackButtonProps {
  text?: string;
  onClick?: () => void;
}
const GoBackButton = ({ text, onClick }: GoBackButtonProps) => {
  const history = useHistory();
  const { t } = useTranslation();

  return (
    <Button
      type="button"
      text={text ? text : t(`common.actions.back`)}
      icon="back"
      size="sm"
      outlined
      rounded
      color="secondary"
      onClick={() => (onClick ? onClick() : history.goBack())}
    />
  );
};

export default GoBackButton;
