import {
  StudentPostCloseDetail,
  StudentPostCloseSearch,
} from '../../types/studentPostClose';
import { STUDENTS_POST_CLOSE } from '../endpoints';
import request from '../request';

export function searchStudentsPostClose(params: {
  search: string;
  searchBy: 'identificator' | 'name';
  page: number | string;
}) {
  return request<StudentPostCloseSearch.SearchResponse>(
    STUDENTS_POST_CLOSE.SEARCH,
    { params },
  );
}

export function getStudentPostCloseDetail(id: string | number) {
  const url = STUDENTS_POST_CLOSE.STUDENT_DETAIL(id);
  return request<StudentPostCloseDetail.DetailResponse>(url);
}

export function updateStudentPostCloseGrades({
  courseEnrollmentId,
  studentTestGrades,
  studentFinalGrade,
}: {
  courseEnrollmentId: string;
  studentTestGrades?: Array<{
    testGradeId: number;
    grade: string;
  }>;
  studentFinalGrade?: {
    finalGradeId: number;
    grade: string;
  };
}) {
  const url = STUDENTS_POST_CLOSE.STUDENT_GRADES_UPDATE(courseEnrollmentId);
  const data = { studentTestGrades, studentFinalGrade };
  const method = 'POST';
  return request(url, { method, data });
}
