import { Button, ColumnTable, Table } from '@octano/global-ui';
import { CellFormatOptions } from '@octano/global-ui/dist/components/Table/types/TableTypes';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { format } from 'rut.js';

import TableEmptyContent from '../../../../../components/text/TableEmptyContent';
import { PathsLayouts } from '../../../../../config/routes';
import { CampusOrScheduleRow } from '../../types/CampusOrSchedule';

const dictPrefix = 'changeStudyPlan.campusOrScheduleList.table';

interface Props {
  data: CampusOrScheduleRow[];
  isLoadingResults: boolean;
  totalPetitions: number;
  totalPages: number;
  currentPage: number;
  onChangePage: (page: number) => void;
  hasFilters?: boolean;
}

export default function CampusOrScheduleTable({
  data,
  isLoadingResults,
  totalPages,
  totalPetitions,
  currentPage,
  onChangePage,
  hasFilters,
}: Props) {
  const ITEMS_PER_PAGE = 10;
  const { t } = useTranslation();
  const texts = useMemo(
    () => ({
      studentName: t(`${dictPrefix}.columns.studentName`),
      studyPlanVersionName: t(`${dictPrefix}.columns.studyPlanVersionName`),
      campusName: t(`${dictPrefix}.columns.campusName`),
      scheduleName: t(`${dictPrefix}.columns.scheduleName`),
      studentPhone: t(`${dictPrefix}.columns.studentPhone`),
      studentEmail: t(`${dictPrefix}.columns.studentEmail`),
      createdAt: t(`${dictPrefix}.columns.createdAt`),
      action: t(`${dictPrefix}.columns.action`),
      makeChange: t(`${dictPrefix}.columns.makeChange`),
      noResultsTitle: t(`${dictPrefix}.noResultsTitle`),
      noResultsBody: t(`${dictPrefix}.noResultsBody`),
    }),
    [t],
  );
  const history = useHistory();

  const columns: ColumnTable<CampusOrScheduleRow>[] = [
    {
      headerText: texts.studentName,
      columnName: 'studentName',
      tdClassName: 'text-center',
      thClassName: 'text-center',
      width: '15%',
      cellFormat: ({ row }) => {
        return (
          <p>
            {row?.studentRun && format(row.studentRun)}
            <br />
            {row?.studentName}
          </p>
        );
      },
    },
    {
      headerText: texts.studyPlanVersionName,
      columnName: 'studyPlanVersionName',
      tdClassName: 'text-center',
      thClassName: 'text-center',
      width: '20%',
      cellFormat: (options: CellFormatOptions) => options.value,
    },
    {
      headerText: texts.campusName,
      columnName: 'campusName',
      tdClassName: 'text-center',
      thClassName: 'text-center',
    },
    {
      headerText: texts.scheduleName,
      columnName: 'scheduleName',
      tdClassName: 'text-center',
      thClassName: 'text-center',
    },
    {
      headerText: texts.studentPhone,
      columnName: 'studentPhone',
      tdClassName: 'text-center',
      thClassName: 'text-center',
    },
    {
      headerText: texts.studentEmail,
      columnName: 'studentEmail',
      thClassName: 'text-center',
      tdClassName: 'text-center',
      width: '50px',
    },
    {
      headerText: texts.createdAt,
      columnName: 'periodName',
      tdClassName: 'text-center',
      thClassName: 'text-center',
    },
    {
      headerText: texts.action,
      columnName: 'studyPlanEnrollmentId',
      thClassName: 'text-center',
      tdClassName: 'text-center',
      cellFormat: (options) => {
        return (
          <Button
            text={texts.makeChange}
            size="sm"
            onClick={() => {
              history.push(
                `${PathsLayouts.enrollmentSoftChange}/${options.value}`,
              );
            }}
          />
        );
      },
    },
  ];

  return (
    <Table
      isLoadingResults={isLoadingResults}
      data={data}
      columns={columns}
      pagination={{
        currentPage: currentPage + 1,
        itemsPerPage: ITEMS_PER_PAGE,
        totalItems: totalPetitions,
        totalPages: totalPages,
        onChangePage: (page) => onChangePage(page - 1),
      }}
      noResultsText={
        <TableEmptyContent
          title={texts.noResultsTitle}
          subtitle={texts.noResultsBody}
        />
      }
    />
  );
}
