import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { Card, Container } from 'reactstrap';

import GoBackToListButton from '../../../../components/buttons/GoBackToListButton';
import { PathsLayouts } from '../../../../config/routes';
import ChangeForm from './parts/ChangeForm';
import ModifyEnrollmentFormLoader from './parts/ChangeFormLoader';
import Completed from './parts/Completed';
import Detail from './parts/Detail';

export default function ContinuityChangeStudyPlan() {
  const { studyPlanEnrollmentId: studyPlanEnrollmentIdParam } = useParams<{
    studyPlanEnrollmentId: string;
  }>();

  const studyPlanEnrollmentId = parseInt(studyPlanEnrollmentIdParam);

  const { t } = useTranslation();
  const prefix = 'changeStudyPlan.continuityDetail';
  const texts = useMemo(
    () => ({
      body: t(`${prefix}.body`),
    }),
    [t],
  );

  const history = useHistory();

  const goBack = useCallback(() => {
    history.goBack();
  }, [history]);

  const [completed, setCompleted] = useState(false);
  const onUpdate = useCallback(() => {
    setCompleted(true);
  }, []);

  if (isNaN(studyPlanEnrollmentId)) {
    goBack();
  }
  return (
    <Container fluid className="h-100">
      <Card className="p-3 h-100">
        <span className="mt-2 mb-4">
          <GoBackToListButton path={PathsLayouts.enrollmentSoftChange} />
        </span>
        <ModifyEnrollmentFormLoader
          studyPlanEnrollmentId={studyPlanEnrollmentId}
        >
          {({ data, defaultValues, refresh }) => (
            <>
              <Detail data={data.detail} completed={completed} />
              {!completed ? (
                <>
                  <p className="mt-3">{texts.body}</p>
                  <ChangeForm
                    studyPlanEnrollmentId={studyPlanEnrollmentId}
                    studyPlanVersionOfferId={
                      data.detail.studyPlanVersionOfferId
                    }
                    defaultValues={defaultValues}
                    offers={data.offers}
                    onUpdate={() => {
                      onUpdate();
                      refresh();
                    }}
                    onCancel={goBack}
                  />
                </>
              ) : (
                <Completed onBack={goBack} />
              )}
            </>
          )}
        </ModifyEnrollmentFormLoader>
      </Card>
    </Container>
  );
}
