import { ColumnTable, Table } from '@octano/global-ui';
import { useTranslation } from 'react-i18next';
import { format } from 'rut.js';

import { useMemo } from 'react';
import { InterimDegreeAuthorizedStudentSingle } from '../interfaces/interim-degree-authorized-student-single.interface';

const dictPrefix = 'interimDegreeGraduationProcess.single';

interface Props {
  data: InterimDegreeAuthorizedStudentSingle[];
}

export function InterimDegreeFailedStudentTable({ data }: Props) {
  const { t } = useTranslation();

  const columns = useMemo(() => {
    const cols: ColumnTable<InterimDegreeAuthorizedStudentSingle>[] = [
      {
        headerText: t(`${dictPrefix}.table.name`),
        columnName: 'name',
        tdClassName: 'text-center',
        thClassName: 'text-center',
        cellFormat: ({ value }) => (
          <span className="text-uppercase">{value}</span>
        ),
      },
      {
        headerText: t(`${dictPrefix}.table.identification`),
        columnName: 'identification',
        tdClassName: 'text-center',
        thClassName: 'text-center',
        cellFormat: ({ value }) => format(value),
      },
      {
        headerText: t(`${dictPrefix}.table.studyPlan`),
        columnName: 'studyPlan',
        tdClassName: 'text-center',
        thClassName: 'text-center',
        cellFormat: ({ value }) => (
          <span className="text-uppercase">{value}</span>
        ),
      },
      {
        headerText: t(`${dictPrefix}.table.interimDegree`),
        columnName: 'interimDegree',
        tdClassName: 'text-center',
        thClassName: 'text-center',
        cellFormat: ({ value }) => (
          <span className="text-uppercase">{value}</span>
        ),
      },
    ];
    return cols;
  }, [t]);

  return (
    <div className="w-100">
      <Table data={data} columns={columns} />
    </div>
  );
}
