import { STUDENT_PETITION_DETAIL } from '../../../api/endpoints';
import request from '../../../api/request';
import {
  RequestStudentsPetitionsUpdateData,
  ResponselinkedFile,
  StudentsPetitionsDetail,
} from '../Received/types/petitionDetail';
import { ResponseStudentsPetitionsUpdateData } from '../Received/types/petitionDetailResponse';

export function getStudentPetitionDetail(petitionId: number | string) {
  const url = STUDENT_PETITION_DETAIL.GET_STUDENT_PETITION(petitionId);
  return request<StudentsPetitionsDetail>(url, {
    method: 'get',
  });
}

export const putChangePetitionStatus = async (
  petitionId: number | string,
  data: RequestStudentsPetitionsUpdateData,
) => {
  const formData = new FormData();
  Object.entries(data).forEach(([key, value]) => {
    if (value instanceof File) formData.append(key, value, value.name);
    else if (value) {
      formData.append(key, value);
    }
  });
  const url = STUDENT_PETITION_DETAIL.PUT_STUDENT_PETITION(petitionId);
  return request<ResponseStudentsPetitionsUpdateData>(url, {
    method: 'PUT',
    data: formData,
  });
};

export function getPetitionStatusPdf(fileId: number | string) {
  const url = STUDENT_PETITION_DETAIL.GET_STUDENT_PETITION_FILES(fileId);
  return request<ResponselinkedFile>(url, {
    method: 'GET',
  });
}
