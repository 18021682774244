import { ColumnTable, DisplayInfo, Table, Icon } from '@octano/global-ui';
import { useTranslation } from 'react-i18next';

import { useMemo } from 'react';
import { InterimDegreeActivityFormItem } from './InterimDegreeForm';

interface Props {
  dictPrefix: string;
  loading?: boolean;
  data: InterimDegreeActivityFormItem[];
  removeEnabled?: boolean;
  onPressDelete?: (index: number) => void;
}

export function InterimDegreeTableActivitiesTable({
  dictPrefix,
  data,
  loading,
  onPressDelete,
  removeEnabled,
}: Props) {
  const { t } = useTranslation();

  const columns = useMemo(() => {
    const cols: ColumnTable<InterimDegreeActivityFormItem>[] = [
      {
        headerText: t(`${dictPrefix}.table.name`),
        columnName: 'name',
        tdClassName: 'text-center',
        thClassName: 'text-center',
      },
      {
        headerText: t(`${dictPrefix}.table.description`),
        columnName: 'description',
        tdClassName: 'text-center',
        thClassName: 'text-center',
      },
      {
        headerText: t(`${dictPrefix}.table.qualification`),
        columnName: 'hasQualification',
        tdClassName: 'text-center',
        thClassName: 'text-center',
        cellFormat: ({ value }) =>
          value
            ? t(`${dictPrefix}.table.hasQualification`)
            : t(`${dictPrefix}.table.hasntQualification`),
      },
      {
        headerText: t(`${dictPrefix}.table.action`),
        columnName: 'id',
        tdClassName: 'text-center',
        thClassName: 'text-center',
        width: 250,
        cellFormat: ({ index }) => (
          <>
            {removeEnabled && onPressDelete ? (
              <div className="d-flex w-100 align-items-center justify-content-center">
                <button
                  type="button"
                  className="btn"
                  onClick={() => onPressDelete(index)}
                >
                  <Icon name="trash" size={18} />
                </button>
              </div>
            ) : (
              <></>
            )}
          </>
        ),
      },
    ];
    return cols;
  }, [t, dictPrefix, removeEnabled, onPressDelete]);

  return (
    <Table
      data={!loading ? data : []}
      isLoadingResults={loading}
      columns={columns}
      noResultsText={
        <div className="d-flex justify-content-center align-items-center py-5">
          <DisplayInfo
            iconName="information"
            title={t(`${dictPrefix}.noResults.title`)}
            textBody={t(`${dictPrefix}.noResults.description`)}
            maxWidth="650px"
          />
        </div>
      }
    />
  );
}
