import {
  Modal,
  Button,
  TextAreaInput,
  TextInput,
  DateInput,
  Select,
  SelectOptionType,
  addToast,
} from '@octano/global-ui';
import React, {
  useState,
  forwardRef,
  useImperativeHandle,
  useCallback,
  useMemo,
} from 'react';
import { useTranslation } from 'react-i18next';
import { Card, Row, Col } from 'reactstrap';
import { InterimDegreeActivity } from '../../academicOffers/InterimDegree/interfaces/interim-degree-activity.interface';
import { useForm } from 'react-hook-form';
import {
  InterimDegreesStudentActivityStatus,
  InterimDegreeStudentActivity,
} from '../interfaces/interim-degree-student-activity.interface';
import { useValidations } from '../../../hooks/useValidations';
import { createStudentActivityRecord } from '../../../api/requests/interimDegreeGraduationActivities';
import { InterimDegreeStudentActivityCreation } from '../interfaces/interim-degree-student-activity-creation.interface';

export type CreateStudentActivityModalMethods = {
  open: (
    interimDegreeActivity: InterimDegreeActivity,
    interimDegreeAuthorizedStudentId: number,
  ) => void;
  close: () => void;
};

interface Form
  extends Omit<InterimDegreeStudentActivity, 'id' | 'date' | 'status'> {
  date: InterimDegreeStudentActivity['date'] | null;
  status: SelectOptionType | null;
}

interface CreateStudentActivityModalProps {
  onSuccess?: () => void;
}

const defaultForm = {
  interimDegreeAuthorizedStudentId: 0,
  interimDegreeActivityId: 0,
  editorId: null,
  status: null,
  date: null,
  grade: null,
  maxGrade: null,
  comments: null,
};

const dictPrefix = 'interimDegreeGraduationProcess.activities.createModal';
const statusesPrefix = 'interimDegreeGraduationProcess.activities.statuses';

const CreateStudentActivityModal = (
  { onSuccess }: CreateStudentActivityModalProps,
  ref: React.Ref<CreateStudentActivityModalMethods>,
) => {
  const { t } = useTranslation();

  const { msgValidations, rulesPatternDecimal, validateGreaterField } =
    useValidations();

  const [open, setOpen] = useState<boolean>(false);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [interimDegreeActivity, setInterimDegreeActivity] =
    useState<InterimDegreeActivity>();

  const { control, handleSubmit, watch, reset } = useForm<Form>({
    defaultValues: defaultForm,
  });

  const [gradeWatch] = watch(['grade']);

  const statuses: SelectOptionType[] = useMemo(() => {
    return [
      {
        value: InterimDegreesStudentActivityStatus.Approved,
        label: t(`${statusesPrefix}.approved`),
      },
      {
        value: InterimDegreesStudentActivityStatus.Failed,
        label: t(`${statusesPrefix}.failed`),
      },
    ];
  }, [t]);

  const handleClose = useCallback(() => {
    setInterimDegreeActivity(undefined);
    setOpen(false);
    reset(defaultForm);
  }, [reset]);

  const handleOpen = useCallback(
    (
      nextInterimDegreeActivity: InterimDegreeActivity,
      interimDegreeAuthorizedStudentId: number,
    ) => {
      setInterimDegreeActivity(nextInterimDegreeActivity);
      reset({
        ...defaultForm,
        interimDegreeActivityId: nextInterimDegreeActivity.id,
        interimDegreeAuthorizedStudentId,
      });
      setOpen(true);
    },
    [reset],
  );

  const handleCreate = useCallback(
    async ({ grade, date, maxGrade, status, ...formValues }: Form) => {
      if (!status?.value || !date) {
        return;
      }
      let formParams: InterimDegreeStudentActivityCreation = {
        ...formValues,
        status: status?.value as any,
        date: date as any,
        comments: formValues?.comments?.trim() ?? undefined,
      };

      if (interimDegreeActivity?.hasQualification) {
        formParams = {
          ...formParams,
          grade: grade ? grade : undefined,
          maxGrade: maxGrade ? maxGrade : undefined,
        };
      }
      setIsSubmitting(true);
      const { error } = await createStudentActivityRecord(formParams);
      setIsSubmitting(false);
      if (error) {
        addToast({
          text: t(`${dictPrefix}.errorOnCreate`),
          color: 'danger',
          icon: 'error',
        });
      } else {
        addToast({
          text: t(`${dictPrefix}.successOnCreate`),
          color: 'success',
          icon: 'check',
        });
        !!onSuccess && onSuccess();
        handleClose();
      }
    },
    [t, handleClose, onSuccess, interimDegreeActivity?.hasQualification],
  );

  useImperativeHandle(ref, () => ({
    open: handleOpen,
    close: handleClose,
  }));

  return (
    <Modal isOpen={open} toggle={handleClose} size="lg" unmountOnClose>
      <Card className="w-100">
        <h3 className="w-100 text-center">{t(`${dictPrefix}.title`)}</h3>
        <p className="w-100 text-center">{t(`${dictPrefix}.description`)}</p>
        <form
          className="w-100 mt-5"
          action="#"
          onSubmit={handleSubmit(handleCreate)}
        >
          <Row>
            <Col xs="12" className="mb-3">
              <TextInput
                defaultValue={interimDegreeActivity?.name ?? ''}
                value={interimDegreeActivity?.name ?? ''}
                name="interimDegreeActivityName"
                disabled
                label={t(`${dictPrefix}.name`)}
                rules={{
                  required: msgValidations.required,
                }}
              />
            </Col>
            <Col xs="12" md="6" className="mb-3">
              <DateInput
                name="date"
                label={t(`${dictPrefix}.date`)}
                control={control}
                maxDate={new Date()}
                rules={{
                  required: msgValidations.required,
                }}
              />
            </Col>
            <Col xs="12" md="6" className="mb-3">
              <Select
                name="status"
                label={t(`${dictPrefix}.status`)}
                control={control}
                options={statuses}
                isClearable={false}
                rules={{
                  required: msgValidations.required,
                }}
              />
            </Col>

            {!!interimDegreeActivity?.hasQualification && (
              <>
                <Col xs="12" md="6" className="mb-3">
                  <TextInput
                    name="grade"
                    control={control}
                    label={t(`${dictPrefix}.grade`)}
                    rules={{
                      required: msgValidations.required,
                      pattern: rulesPatternDecimal,
                    }}
                  />
                </Col>
                <Col xs="12" md="6" className="mb-3">
                  <TextInput
                    name="maxGrade"
                    control={control}
                    label={t(`${dictPrefix}.maxGrade`)}
                    rules={{
                      required: msgValidations.required,
                      pattern: rulesPatternDecimal,
                      validate: {
                        require: (value) =>
                          validateGreaterField(
                            String(gradeWatch),
                            String(value),
                          ),
                      },
                    }}
                  />
                </Col>
              </>
            )}
            <Col xs="12" className="mb-3">
              <TextAreaInput
                name="comments"
                control={control}
                label={t(`${dictPrefix}.comments`)}
              />
            </Col>
          </Row>
          <Row>
            <Col className="mt-5">
              <Row>
                <Col xs={{ size: 12, order: 2 }} md={{ size: 6, order: 1 }}>
                  <Button
                    type="button"
                    text={t(`${dictPrefix}.cancel`)}
                    outlined
                    onClick={handleClose}
                    fullwidth
                  />
                </Col>
                <Col xs={{ size: 12, order: 1 }} md={{ size: 6, order: 2 }}>
                  <Button
                    type="submit"
                    text={t(`${dictPrefix}.create`)}
                    loading={isSubmitting}
                    fullwidth
                    className="mb-3 mb-md-0"
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        </form>
      </Card>
    </Modal>
  );
};

export default forwardRef(CreateStudentActivityModal);
