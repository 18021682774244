import React from 'react';
import { Card, Container } from 'reactstrap';

import FormLoader from './parts/FormLoader';
import StudentFile from './parts/StudentFile';

export default function index() {
  return (
    <Container fluid>
      <Card className="p-4 mt-2">
        <FormLoader>{(props) => <StudentFile {...props} />}</FormLoader>
      </Card>
    </Container>
  );
}
