import { TuitionProcessForm } from '../../TuitionProcessForm';

export const SearchStudentOnSiteSingleTuition = ({
  showPassport,
  student,
  preload,
}: any) => {
  return (
    <TuitionProcessForm
      passport={showPassport}
      student={student}
      preload={preload}
    />
  );
};
