import { TextField } from '@octano/global-ui';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'reactstrap';

import { POSTULATION_STATUS } from '../../../../../types/postulationDetailStatus';
import { Student } from '../../../../../types/tuitionProcessOnSite';

interface Props {
  student: Student;
  actionForm: {
    status: string;
    description: string;
    actions?: JSX.Element;
    disabledInputs?: boolean;
  };
}

const TuitionProcessFormHeader = ({ student, actionForm }: Props) => {
  const prefix = 'tuitionProcess.form';
  const { t } = useTranslation();
  const statusNewChange =
    t('tuitionProcessNoSua.status.STUDY_PLAN_CHANGE') +
    student.newAdmission?.studyPlanVersionName +
    ' ' +
    student.newAdmission?.campusName +
    ' ' +
    student.newAdmission?.scheduleName;

  if (student?.hasMultiTuition) {
    return (
      <>
        <header>
          <Row>
            <Col lg={6} xl={7}>
              <h1 className="fs-22 text-primary fw-700">
                {t(`${prefix}.title`)}
              </h1>
              <p className="fs-16">{actionForm.description}</p>
            </Col>
          </Row>
        </header>
        <h2 className="fs-22 text-uppercase text-primary fw-700 mt-4 mb-5">
          {t(`${prefix}.subtitle`)}
        </h2>
      </>
    );
  }

  return (
    <>
      <header>
        <Row>
          <Col lg={6} xl={7}>
            <h1 className="fs-22 text-primary fw-700">
              {t(`${prefix}.title`)}
            </h1>
            <p className="fs-16">{actionForm.description}</p>
          </Col>
          <Col lg={6} xl={5}>
            {!student.newAdmission && (
              <Row>
                <Col md={6} className="pr-md-0">
                  <TextField
                    placeholder={t(`${prefix}.state.placeholder`)}
                    border={false}
                  >
                    {actionForm.status}
                  </TextField>
                </Col>
                <Col md={6}>
                  <TextField placeholder={t(`${prefix}.sua.placeholder`)}>
                    {t(
                      `admissionTypes.${student.admissionTypeId || 'SIN_SUA'}`,
                    )}
                  </TextField>
                </Col>
              </Row>
            )}
            {student.status !== POSTULATION_STATUS.NO_POSTULATION &&
              student.status !== POSTULATION_STATUS.DESISTED && (
                <Row>
                  <Col className="d-flex w-100">
                    <TextField
                      placeholder={t(`${prefix}.studentPlans`)}
                      border={false}
                      style={{ flexGrow: '1', borderRadius: '0px' }}
                    >
                      {student.status === POSTULATION_STATUS.ENROLLED
                        ? t('tuitionProcessNoSua.enrolledStudyPlan') +
                          ' ' +
                          student.studyPlanVersion_name
                        : student.studyPlanVersion_name ?? ''}
                    </TextField>
                    <TextField
                      placeholder={t(`${prefix}.campus_name`)}
                      border={false}
                      style={{ flexGrow: '1', borderRadius: '0px' }}
                    >
                      {student.campus_name ?? ''}
                    </TextField>
                    <TextField
                      placeholder={t(`${prefix}.schedule_name`)}
                      border={false}
                      style={{ flexGrow: '1', borderRadius: '0px' }}
                    >
                      {student.schedule_name ?? ''}
                    </TextField>
                  </Col>
                </Row>
              )}
            {student.newAdmission && (
              <Row>
                <Col>
                  <TextField
                    placeholder={t(`${prefix}.state.placeholder`)}
                    border={false}
                  >
                    {statusNewChange}
                  </TextField>
                </Col>
              </Row>
            )}
          </Col>
        </Row>
      </header>

      <h2 className="fs-22 text-uppercase text-primary fw-700 mt-4 mb-5">
        {t(`${prefix}.subtitle`)}
      </h2>
    </>
  );
};

export default TuitionProcessFormHeader;
