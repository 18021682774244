import { Tab, TabPanel, TabPanels, Tabs, TabsList } from '@octano/global-ui';
import { memo, useCallback, useState } from 'react';
import { Card, Container } from 'reactstrap';
import StudentCurrentAccountLoader from './components/StudentCurrentAccountLoader';
import StudentSearchAndDetail from './components/StudentSearchAndDetail';
import PaymentAgreement from './PaymentAgreetment';
import PaymentHistory from './PaymentHistory';
import { useTranslation } from 'react-i18next';
import Debt from './Debt';

const keyPrefix = 'studentCurrentAccount.tabs';

enum TabPaths {
  CurrentAccount = 'current-account',
  PaymentAgreement = 'payment-agreement',
  PaymentHistory = 'payment-history',
}

interface TabComponentProps {
  currentTab: string;
  onTabChange: (tabId: string | number) => void;
}

const TabComponent = memo(({ currentTab, onTabChange }: TabComponentProps) => {
  const { t } = useTranslation();

  const tabs = [
    {
      path: TabPaths.CurrentAccount,
      name: 'currentAccount',
      component: Debt,
    },
    {
      path: TabPaths.PaymentAgreement,
      name: 'paymentAgreement',
      component: PaymentAgreement,
    },
    {
      path: TabPaths.PaymentHistory,
      name: 'paymentHistory',
      component: PaymentHistory,
    },
  ];

  return (
    <Container fluid className="p-4">
      <Tabs defaultTab={currentTab} onSelection={onTabChange}>
        <TabsList>
          {tabs.map((tab) => (
            <Tab key={tab.path} id={tab.path}>
              {t(`${keyPrefix}.${tab.name}.title`)}
            </Tab>
          ))}
        </TabsList>
        <TabPanels>
          {tabs.map((tab) => (
            <TabPanel key={tab.path} id={tab.path}>
              <tab.component />
            </TabPanel>
          ))}
        </TabPanels>
      </Tabs>
    </Container>
  );
});

function StudentCurrentAccount() {
  const [currentTab, setCurrentTab] = useState<string>(TabPaths.CurrentAccount);

  const handleTabChange = useCallback(
    (tabId: string | number) => {
      if (tabId !== currentTab) {
        setCurrentTab(tabId as string);
      }
    },
    [currentTab],
  );

  return (
    <Card className="mt-5 mx-3">
      <StudentCurrentAccountLoader searchComponent={<StudentSearchAndDetail />}>
        <TabComponent currentTab={currentTab} onTabChange={handleTabChange} />
      </StudentCurrentAccountLoader>
    </Card>
  );
}

export default StudentCurrentAccount;
