import { ColumnTable } from '@octano/global-ui';
import { formatDate } from '../../../../../../utils/dates';
import { useTexts } from './textsContext';

export default function useColumnsCertificates(
  actionsColumns: ColumnTable[],
): ColumnTable[] {
  const texts = useTexts();
  return [
    {
      columnName: 'name',
      headerText: texts.table.certificates.columns.name,
      tdClassName: 'text-center',
      thClassName: 'text-center',
    },
    {
      columnName: 'description',
      headerText: texts.table.certificates.columns.description,
      tdClassName: 'text-center',
      thClassName: 'text-center',
    },
    {
      columnName: 'country',
      headerText: texts.table.certificates.columns.country,
      tdClassName: 'text-center',
      thClassName: 'text-center',
      cellFormat: (options) => options.row?.country?.name,
    },
    {
      columnName: 'originInstitute',
      headerText: texts.table.certificates.columns.originInstitute,
      tdClassName: 'text-center',
      thClassName: 'text-center',
    },
    {
      columnName: 'dateOfIssue',
      headerText: texts.table.certificates.columns.dateOfIssue,
      tdClassName: 'text-center',
      thClassName: 'text-center',
      cellFormat: (options) =>
        formatDate(options.row?.dateOfIssue, 'DD/MM/YYYY'),
    },
    {
      columnName: 'linkedFile',
      headerText: texts.table.certificates.columns.linkedFile,
      tdClassName: 'text-center',
      thClassName: 'text-center',
      cellFormat: (options) =>
        options.row?.linkedFile ? `${options.row?.linkedFile?.name}` : '-',
    },
    ...actionsColumns,
  ];
}
