import { Button, Icon, Modal } from '@octano/global-ui';
import { useTranslation } from 'react-i18next';

export interface PetitionsExcelReportInfoModalProps {
  open?: boolean;
  onClose: () => void;
  onAfterClose?: () => void;
}

const prefix = 'studentsDegree.common.excel';

const PetitionsExcelReportInfoModal = (
  props: PetitionsExcelReportInfoModalProps,
) => {
  const { open = false, onClose, onAfterClose = () => {} } = props;
  const { t } = useTranslation();

  return (
    <Modal
      isOpen={open}
      toggle={() => {
        onClose();
      }}
      size="md"
    >
      <div className="d-flex flex-column align-items-center">
        <Icon name="warning" size="52px" />
        <div className={`mt-4 fw-600 text-black fs-22 text-center`}>
          {t(`${prefix}.cantExportPetitionsTitle`)}
        </div>
        <div className={`mx-auto mt-4 mb-5 fs-16 text-center`}>
          {t(`${prefix}.cantExportPetitionsSubtitle`)}
        </div>
        <Button
          type="button"
          text={t(`common.actions.close`)}
          fullwidth
          onClick={() => {
            onClose();
            onAfterClose();
          }}
        />
      </div>
    </Modal>
  );
};

export default PetitionsExcelReportInfoModal;
