import { Dispatch } from 'react';

export enum LayoutActionType {
  SHOW_MAINTAINER_TABS = 'SHOW_MAINTAINER_TABS',
  SHOW_CONNECTION_ERROR_MODAL = 'SHOW_CONNECTION_ERROR_MODAL',
}
export type LayoutAction =
  | { type: LayoutActionType.SHOW_MAINTAINER_TABS; payload: boolean }
  | { type: LayoutActionType.SHOW_CONNECTION_ERROR_MODAL; payload: boolean };

export type LayoutState = {
  showMaintainerTabs: boolean;
  showConnectionErrorModal: boolean;
};

export type LayoutContextType = {
  state: LayoutState;
  dispatch: Dispatch<LayoutAction>;
};
