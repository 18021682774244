import { Button } from '@octano/global-ui';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Card, Col, Row } from 'reactstrap';
import { PostulantDiscounts } from '../../../../../../../api/requests/studyPlansOffer';
import DisplayError from '../../../../../../../components/info/DisplayError';
import { useLoadingState } from '../../../../../../../hooks/useLoadingState';
import { PostulationDetailType } from '../../../../../../../types/tuitionProcessTypes';
import { getAllDiscounts, getDiscountFromPostulationDetail } from '../api';
import { Discount } from '../type';
import { InProcessDiscountModal } from './InProcessDiscountModal';

interface DiscountPostulationProps {
  postulantId: number | undefined;
  postulationDetail: PostulationDetailType | null;
  reloadComponent: () => void;
}

const prefix = 'common.discount';

export default function DiscountPostulation({
  postulantId,
  postulationDetail,
  reloadComponent,
}: DiscountPostulationProps) {
  const { t } = useTranslation();
  const {
    loading,
    setLoading,
    errorLoading,
    setErrorLoading,
  } = useLoadingState();

  const [showAssignDiscount, setShowAssignDiscount] = useState<boolean>(false);
  const [allDiscounts, setAllDiscounts] = useState<Discount[]>([]);
  const [postulantDiscounts, setPostulantDiscounts] = useState<
    PostulantDiscounts[]
  >([]);

  const toSetDiscount = () => {
    setShowAssignDiscount(true);
  };

  const getDiscounts = useCallback(async () => {
    setLoading(true);
    if (postulantId && postulationDetail) {
      const [allDiscounts, postulantDiscounts] = await Promise.all([
        getAllDiscounts(),
        getDiscountFromPostulationDetail(postulationDetail.id),
      ]);
      allDiscounts.error && setErrorLoading(allDiscounts?.error?.code);
      postulantDiscounts.error &&
        setErrorLoading(postulantDiscounts?.error?.code);
      setErrorLoading(undefined);
      setAllDiscounts(allDiscounts.data ?? []);
      setPostulantDiscounts(postulantDiscounts.data ?? []);
      setLoading(false);
    }
  }, [postulantId, postulationDetail, setErrorLoading, setLoading]);

  useEffect(() => {
    getDiscounts();
  }, [getDiscounts]);

  if (errorLoading) {
    return (
      <DisplayError
        insideCard
        textBody={errorLoading}
        retryAction={() => getDiscounts()}
        loadingAction={loading}
      />
    );
  }

  return (
    <Card className="DiscountPostulation">
      <Row className="DiscountPostulation_content">
        <Col xs={12} lg={12} className="pb-2 order-2 order-lg-1">
          <Button
            text={t(`${prefix}.giveDiscount`)}
            size="sm"
            onClick={() => toSetDiscount()}
            fullwidth
          />
          <InProcessDiscountModal
            show={showAssignDiscount}
            toggleModal={() => {
              setShowAssignDiscount(false);
            }}
            reloadList={() => reloadComponent()}
            allDiscounts={allDiscounts}
            postulantDiscounts={postulantDiscounts}
            postulationDetailId={postulationDetail?.id!}
          />
        </Col>
      </Row>
    </Card>
  );
}
