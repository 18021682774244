export enum PaymentAgreementType {
  Tuition = 'tuition',
  Tariff = 'tariff',
}
export interface Debt {
  concept: string;
  agreementType: PaymentAgreementType;
  numberOfInstallment: string;
  expirationDate: string;
  daysLate: number;
  capitalAmount: number;
  interestRate: number;
  interestAmount: number;
  totalAmount: number;
  paidAmount: number;
  outstandingDebt: number;
}

export interface DebtRsponse {
  debts: Debt[];
}
