import { Button, Icon, Modal, Table } from '@octano/global-ui';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'reactstrap';

interface CurriculumErrorModalProps {
  semesterError: boolean;
  show: boolean;
  onClose: Function;
  errors: any;
  legend: any;
}

export const CurriculumErrorModal = ({
  semesterError,
  show,
  onClose,
  errors,
  legend,
}: CurriculumErrorModalProps) => {
  const { t } = useTranslation();
  const prefix = 'studyPlans.curriculum.errorModal';

  const closeModal = () => {
    onClose();
  };

  const columns = [
    {
      columnName: 'type',
      headerText: t(`${prefix}.creditType`),
    },
    {
      columnName: 'needed',
      headerText: t(`${prefix}.needed`),
    },
    {
      columnName: 'set',
      headerText: t(`${prefix}.set`),
    },
  ];

  const columnsData = [];

  if (errors.total)
    columnsData.push({
      type: t(`${prefix}.total`),
      needed: legend.totalNeeded,
      set: legend.total,
    });
  if (errors.required)
    columnsData.push({
      type: t(`${prefix}.required`),
      needed: legend.requiredNeeded,
      set: legend.required,
    });
  if (errors.optional)
    columnsData.push({
      type: t(`${prefix}.optional`),
      needed: legend.optionalNeeded,
      set: legend.optional,
    });
  if (errors.other)
    columnsData.push({
      type: t(`${prefix}.other`),
      needed: legend.otherNeeded,
      set: legend.other,
    });
  if (errors.activities)
    columnsData.push({
      type: t(`${prefix}.activities`),
      needed: legend.activitiesNeeded,
      set: legend.activities,
    });

  const getDescTitle = () => {
    if (errors.activitiesChecked) {
      return t(`${prefix}.activitiesErrorTitle`);
    } else if (semesterError) {
      return t(`${prefix}.semesterErrorTitle`);
    } else {
      return t(`${prefix}.creditsErrorTitle`);
    }
  };

  const getDescMessage = () => {
    if (errors.activitiesChecked) {
      return t(`${prefix}.emptyActivitiesError`);
    } else if (errors.semester) {
      return t(`${prefix}.emptySemesterError`);
    } else if (semesterError) {
      return t(`${prefix}.semesterErrorDesc`);
    } else {
      return t(`${prefix}.creditsErrorDesc`);
    }
  };

  return (
    <Modal
      isOpen={show}
      toggle={() => {
        closeModal();
      }}
      size="md"
    >
      <div className="error-modal">
        <Row className="mb-4">
          <Col className="text-center">
            <Icon color="secondary" name="warning" size="50" />
          </Col>
        </Row>
        <Row className="mb-4">
          <Col className="text-center tittle">{getDescTitle()}</Col>
        </Row>
        <Row className="mb-4">
          <Col className="text-center desc">{getDescMessage()}</Col>
        </Row>
        {!errors.semester && semesterError && columnsData.length > 0 ? (
          <Row className="mb-4">
            <Col className="text-center desc">
              <Table columns={columns} data={columnsData} striped />
            </Col>
          </Row>
        ) : (
          ''
        )}
        <Row>
          <Col className="mt-4">
            <Button
              type="button"
              text={t(`${prefix}.gotIt`)}
              onClick={closeModal}
              fullwidth
            />
          </Col>
        </Row>
      </div>
    </Modal>
  );
};
