import { Button } from '@octano/global-ui';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { useParams } from 'react-router-dom';
import { Card, Col, Row } from 'reactstrap';

import Loading from '../../components/info/Loading';
import TabControl, { TabControlProps } from '../../components/tabs/TabControl';
import { PathsLayouts } from '../../config/routes';
import { TuitionContinuityDocumentStatusEnum as DocumentStatus } from '../../types/tuitionContinuityDocumentReview';
import { enumToArray } from '../../utils/enumToArray';
import { TuitionContinuityProcessManagementDetailBox } from '../TuitionContinuityProcessManagementDetail/parts/TuitionContinuityProcessManagementDetailBox';
import { TuitionContinuityDocumentReviewDetailLoader } from './parts/TuitionContinuityDocumentReviewDetailLoader';
import TuitionContinuityDocumentReviewTabList from './tabs/TuitionContinuityDocumentReviewTabList';

export default function TuitionContinuityProcessDocumentReviewDetail() {
  const history = useHistory();
  const { t } = useTranslation();
  const { periodId, offerId, status } = useParams<{
    periodId: string;
    offerId: string;
    status: DocumentStatus;
  }>();

  const prefix = 'TuitionContinuityDocumentReview';
  const text = useMemo(
    () => ({
      backToList: t(`${prefix}.form.backToList`),
    }),
    [t],
  );
  const tabs = useMemo<TabControlProps['tabs']>(() => {
    const pathName = `student-list/${periodId}/${offerId}`;
    return enumToArray(DocumentStatus).map((tab) => ({
      name: `${pathName}/${tab}`,
      literalName: t(`${prefix}.tabs.${tab}`),
      content: (
        <TuitionContinuityDocumentReviewTabList
          currentStatus={status}
          status={tab as DocumentStatus}
        />
      ),
    }));
  }, [offerId, periodId, status, t]);
  return (
    <Card className="mx-4 p-4">
      <TuitionContinuityDocumentReviewDetailLoader
        studyPlanVersionOfferId={offerId}
        periodId={periodId}
        currentStatus={status}
      >
        {({ detailBox, isLoading }) => (
          <>
            {isLoading ? (
              <Loading insideCard />
            ) : (
              <Row>
                <Col className="mt-3">
                  <Button
                    size="sm"
                    icon="back"
                    onClick={() =>
                      history.push(PathsLayouts.tuitionContinuityDocumentReview)
                    }
                    text={text.backToList}
                    rounded
                    outlined
                  />
                </Col>
                <Col md={8} lg={6} className="">
                  <TuitionContinuityProcessManagementDetailBox {...detailBox} />
                </Col>
                <Col md={12} className="mt-4">
                  <TabControl
                    tabs={tabs}
                    path={PathsLayouts.tuitionContinuityDocumentReview}
                  />
                </Col>
              </Row>
            )}
          </>
        )}
      </TuitionContinuityDocumentReviewDetailLoader>
    </Card>
  );
}
