import React, { useContext, useState } from 'react';
import type { RolePermission } from '../../../../types/Roles';

interface IPermissionStateContext {
  permissionState: Partial<RolePermission>[];
  setPermissionState: React.Dispatch<
    React.SetStateAction<Partial<RolePermission>[]>
  >;
}
/**
 * Este provider ofrece un estado global para los permisos.
 * Al editar cada una de las tablas de grupos de permisos
 * en la vista de asignacion de permisos a roles, se realiza
 * una modificación de este estado.
 * Al dar al boton guardar en la asignación de permisos se envia  este estado en la request
 */
const PermissionStateContext = React.createContext<
  IPermissionStateContext | undefined
>(undefined);

export const PermissionStateProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [permissionState, setPermissionState] = useState<
    Partial<RolePermission>[]
  >([]);

  return (
    <PermissionStateContext.Provider
      value={{ permissionState, setPermissionState }}
    >
      {children}
    </PermissionStateContext.Provider>
  );
};

export const usePermissionState = () => {
  const context = useContext(PermissionStateContext);
  if (!context) {
    throw new Error('usePermissions must be used within a PermissionProvider');
  }
  return context;
};
