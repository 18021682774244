import { TextField, addToast } from '@octano/global-ui';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Card, Col, Row } from 'reactstrap';

import { getCompleted } from '../../../../../api/requests/tuitionProcess';
import DisplayError from '../../../../../components/info/DisplayError';
import Loading from '../../../../../components/info/Loading';
import { useLoadingState } from '../../../../../hooks/useLoadingState';
import { CompletedStepResponse } from '../../../../../types/completedTypes';
import { STATUS_POSTULATION } from '../../../../../types/tuitionProcessOnSite';
import { useTuitionProcessNoSua } from '../hooks/useTuitionProcessNoSua';
import CompletedErp from './completed/CompletedErp';
import CompletedSoftland from './completed/CompletedSoftland';
import { IntegrationFinantialSystemEnum } from './payment/types';

const TuitionProcessNoSuaCompleted = () => {
  const prefix = `tuitionProcessNoSua.completed`;
  const { t } = useTranslation();

  const {
    loading,
    setLoading,
    errorLoading,
    setErrorLoading,
  } = useLoadingState();
  const {
    postulationDetail,
    postulant,
    statusPostulation,
    newPostulationDetail,
  } = useTuitionProcessNoSua();

  const [completedData, setCompletedData] = useState<CompletedStepResponse>();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const getStatus = useCallback(async () => {
    const { data, error } = await getCompleted(postulationDetail?.id);
    if (data) {
      setCompletedData(data);
      if (data.interesteds.length === 0) {
        addToast({
          icon: 'warning',
          color: 'warning',
          text: t(`${prefix}.warningSave`),
        });
      }
      setErrorLoading(undefined);
    } else if (error) {
      setErrorLoading(error.code);
    }
    setLoading(false);
  }, [setErrorLoading, setLoading, postulationDetail, t, prefix]);

  useEffect(() => {
    getStatus();
  }, [getStatus]);

  // Memoization
  const showChangeMsg = useMemo(() => {
    return (
      statusPostulation === STATUS_POSTULATION.STUDY_PLAN_CHANGE &&
      newPostulationDetail
    );
  }, [statusPostulation, newPostulationDetail]);

  const studyPlanNameByStatus: string = useMemo(() => {
    if (statusPostulation === STATUS_POSTULATION.STUDY_PLAN_CHANGE) {
      return newPostulationDetail?.studyPlanName ?? '';
    }
    return postulationDetail?.studyPlanName ?? '';
  }, [
    statusPostulation,
    postulationDetail?.studyPlanName,
    newPostulationDetail?.studyPlanName,
  ]);

  // Render
  if (errorLoading) {
    return (
      <DisplayError
        insideCard
        textBody={errorLoading}
        retryAction={() => setLoading(true)}
        loadingAction={loading}
      />
    );
  }

  if (loading) {
    return <Loading insideCard />;
  }

  return (
    <>
      <Card className="px-4 px-md-5 py-4">
        <Row>
          {showChangeMsg ? (
            <>
              <Col xs={12} lg={{ size: 6, offset: 6 }}>
                <Row>
                  <Col xs={12} lg={6} className="pr-lg-0 mb-n2">
                    <TextField
                      placeholder={t(`${prefix}.originalStudyPlan`)}
                      border={false}
                      style={{ borderRadius: '0px' }}
                    >
                      {t(`${prefix}.enrolledOn`) +
                        ' ' +
                        postulationDetail?.studyPlanName}
                    </TextField>
                  </Col>
                  <Col xs={6} lg={3} className="p-lg-0 pr-0 mb-n2">
                    <TextField
                      placeholder={t(`${prefix}.campus`)}
                      border={false}
                      style={{ borderRadius: '0px' }}
                    >
                      {postulationDetail?.campus.name ?? ''}
                    </TextField>
                  </Col>
                  <Col xs={6} lg={3} className="p-lg-0 pl-0 mb-n2">
                    <TextField
                      placeholder={t(`${prefix}.schedule`)}
                      border={false}
                      style={{ borderRadius: '0px' }}
                    >
                      {postulationDetail?.schedule.name ?? ''}
                    </TextField>
                  </Col>
                </Row>
                <Row className="">
                  <Col xs={12} className="pr-lg-0 mb-n2">
                    <TextField
                      placeholder={t(`${prefix}.titleStatus`)}
                      border={false}
                      style={{ borderRadius: '0px' }}
                    >
                      {t(`${prefix}.enrolledOn`) +
                        ' ' +
                        newPostulationDetail?.studyPlanName}
                    </TextField>
                  </Col>
                </Row>
              </Col>
            </>
          ) : (
            <>
              <Col xs={12} lg={7} xl={5} className="ml-auto">
                <Row>
                  <Col xs={12} lg={6} className="pr-lg-0 mb-n2">
                    <TextField
                      placeholder={t(`${prefix}.postulant`)}
                      border={false}
                      style={{ borderRadius: '0px' }}
                    >
                      {postulant?.fullName ?? ''}
                    </TextField>
                  </Col>
                  <Col xs={12} lg={6} className="pl-lg-0 mb-n2">
                    <TextField
                      placeholder={t(`${prefix}.titleStatus`)}
                      border={false}
                      style={{ borderRadius: '0px' }}
                    >
                      {t(`tuitionProcessNoSua.status.ENROLLED`)}
                    </TextField>
                  </Col>
                </Row>
              </Col>
              <Col xs={12} lg={3} xl={2} className="mb-n2">
                <TextField placeholder={t(`${prefix}.admissionType`)}>
                  {t(`admissionTypes.SIN_SUA`)}
                </TextField>
              </Col>
            </>
          )}
        </Row>

        {/* Imagen y textos del cuerpo */}
        {completedData?.integration ===
        IntegrationFinantialSystemEnum.SOFTLAND ? (
          <>
            {completedData && (
              <CompletedSoftland
                title={t(
                  `${prefix}.${
                    showChangeMsg ? 'titleStudyPlanChanged' : 'title'
                  }`,
                )}
                body={t(
                  `${prefix}.${
                    showChangeMsg ? 'bodyStudyPlanChanged' : 'body'
                  }`,
                )}
                newSearchText={t(`${prefix}.newSearch`)}
                completedData={completedData}
                studyPlanNameByStatus={studyPlanNameByStatus}
              />
            )}
          </>
        ) : (
          <CompletedErp
            title={t(
              `${prefix}.${showChangeMsg ? 'titleStudyPlanChanged' : 'title'}`,
            )}
            body={t(
              `${prefix}.${showChangeMsg ? 'bodyStudyPlanChanged' : 'body'}`,
            )}
            searchText={t(`${prefix}.newSearch`)}
          />
        )}
      </Card>
    </>
  );
};

export default TuitionProcessNoSuaCompleted;
