import { Button, addToast, useMobile } from '@octano/global-ui';
import dayjs from 'dayjs';
import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import useUserState from '../../../../hooks/useUserState';
import { PermissionName } from '../../../../types/Auth';
import { downloadFromBlob } from '../../../../utils/blob';
import GeneratingFileModal from '../GeneratingFileModal';
import NoResultsModal from '../NoResultsModal';
import SelectDatesModal from '../SelectDatesModal';
import { createReportDownloadHandler } from './handlerCreator';

enum ModalType {
  NONE,
  DATE_RANGE_SELECTOR,
  GENERATING_FILE,
  NO_RESULTS,
}

type DownloadParams = {
  initDate: string;
  endDate: string;
};

interface Props {
  downloadUrl: string;
}

export default function TeacherAttendanceActions({ downloadUrl }: Props) {
  const { isAuthorizedTo } = useUserState();
  const { t } = useTranslation();
  const isMobile = useMobile();

  const [openedModal, setOpenedModal] = useState<ModalType>(ModalType.NONE);
  const fileName = useRef('');

  const handleReportDownload = createReportDownloadHandler({
    beforeDownload: () => setOpenedModal(ModalType.GENERATING_FILE),
    afterDownload: ({ data }) => {
      if (data.size === 0) {
        setOpenedModal(ModalType.NO_RESULTS);
      } else {
        setOpenedModal(ModalType.NONE);
      }
    },
    onSuccess: (data: Blob) => {
      if (data.size > 0) {
        const blob = new Blob([data], { type: 'vnd.ms-excel' });
        downloadFromBlob(blob, fileName.current);
      }
    },
    onError: () => {
      addToast({
        icon: 'error',
        color: 'danger',
        text: t(`reports.errors.errorGeneratingFile`),
      });
    },
  });

  const handleParamsSelection = (params: DownloadParams) => {
    const title = t(`reports.classExecution.fileNameTitle`);
    const connector = t(`reports.classExecution.datesStrConnector`);

    const formatedInit = dayjs(params.initDate).format('DD-MM-YYYY');
    const formatedEnd = dayjs(params.endDate).format('DD-MM-YYYY');

    fileName.current = `${title}_${formatedInit}_${connector}_${formatedEnd}.xlsx`;

    handleReportDownload(downloadUrl, params);
  };

  return (
    <>
      <NoResultsModal
        isOpen={openedModal === ModalType.NO_RESULTS}
        onConfirm={() => setOpenedModal(ModalType.NONE)}
        texts={{
          title: t('reports.classExecution.noResults.title'),
          description: t('reports.classExecution.noResults.description'),
        }}
      />
      <GeneratingFileModal isOpen={openedModal === ModalType.GENERATING_FILE} />
      <SelectDatesModal
        isOpen={openedModal === ModalType.DATE_RANGE_SELECTOR}
        onConfirm={handleParamsSelection}
        onCancel={() => setOpenedModal(ModalType.NONE)}
      />

      <div className="d-flex flex-wrap align-items-center " style={{ gap: 12 }}>
        <Button
          text={t(`common.actions.download`)}
          icon="download"
          size="sm"
          outlined
          style={{ width: isMobile ? '90%' : 123 }}
          disabled={!isAuthorizedTo([PermissionName.REPORTS])}
          onClick={() => setOpenedModal(ModalType.DATE_RANGE_SELECTOR)}
        />
      </div>
    </>
  );
}
