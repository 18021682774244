import { INTERIM_DEGREE_GRADUATION_PROCESS_SINGLE } from '../endpoints';
import request from '../request';
import {
  PaginationQueryDto,
  PaginationRequestType,
} from '../../types/paginationRequestType';

import { InterimDegreeAuthorizedStudentSingleFilters } from '../../views/InterimDegreeGraduationProcess/interfaces/interim-degree-authorized-student-single-filters.interface';
import { InterimDegreeAuthorizedStudent } from '../../views/academicOffers/InterimDegree/interfaces/interim-degree-authorized-student.interface';
import { InterimDegreeAuthorizedStudentSingle } from '../../views/InterimDegreeGraduationProcess/interfaces/interim-degree-authorized-student-single.interface';

export const getStudents = (
  params: PaginationQueryDto<InterimDegreeAuthorizedStudentSingleFilters>,
) =>
  request<PaginationRequestType<InterimDegreeAuthorizedStudentSingle>>(
    INTERIM_DEGREE_GRADUATION_PROCESS_SINGLE.STUDENTS,
    {
      params,
    },
  );

export const getAuthorizationDetails = (params: {
  studyPlanEnrollmentId: number | string;
  interimDegreeId: number | string;
}) =>
  request<{
    student: InterimDegreeAuthorizedStudentSingle;
  }>(INTERIM_DEGREE_GRADUATION_PROCESS_SINGLE.FAILED_DETAILS(params));

export const generateSingleStudent = (data: {
  studyPlanEnrollmentId: number;
  interimDegreeId: number;
}) =>
  request<InterimDegreeAuthorizedStudent>(
    INTERIM_DEGREE_GRADUATION_PROCESS_SINGLE.AUTHORIZE,
    {
      method: 'POST',
      data,
    },
  );
