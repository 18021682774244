import { TariffPaymentMethodSettingValue } from '../../../../types/tariffTypes';

export const hasError = ({
  maxTariffDownPaymentRate,
}: TariffPaymentMethodSettingValue): string | null => {
  const prefix = 'configurations.finantial.toasts';
  if (maxTariffDownPaymentRate < 0) {
    return `${prefix}.minTariffDownPaymentError`;
  }
  if (maxTariffDownPaymentRate > 1) {
    return `${prefix}.maxTariffDownPaymentError`;
  }
  return null;
};
