import { ReactNode, useCallback } from 'react';

import FetchProvider from '../../../components/contexts/FetchContext';
import { getCourseValidationInternalStudents } from '../requests';
import {
  CourseValidationInternalStudents,
  CourseValidationInternalStudentsQuery,
} from '../types';

export interface StudentsLoaderProps {
  children: ReactNode;
}

export default function StudentsLoader({ children }: StudentsLoaderProps) {
  const request = useCallback(
    async (query: CourseValidationInternalStudentsQuery) => {
      return getCourseValidationInternalStudents(query);
    },
    [],
  );
  return (
    <FetchProvider<
      CourseValidationInternalStudentsQuery,
      CourseValidationInternalStudents
    >
      request={request}
      fetchImmediately
      defaultQuery={{
        search: {
          studyPlanVersionId: undefined,
          searchText: undefined,
          periodId: undefined,
          campusId: undefined,
        },
        page: 1,
      }}
    >
      {children}
    </FetchProvider>
  );
}
