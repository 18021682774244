import {
  CheckInput,
  Select,
  SelectOptionType,
  TextInput,
  DateInput,
  Alert,
  addToast,
  Button,
} from '@octano/global-ui';
import { Col, Row } from 'reactstrap';
import { useMemo, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';
import { useValidations } from '../../../hooks/useValidations';
import { updateToGraduated } from '../../../api/requests/interimDegreeGraduationStudents';

export interface ActivitiesResumenFormProps {
  authorizedStudentId: string | number;
  managment: 'integration' | 'input';
  distinctionRanks?: { id: number; name: string }[];
  financialRestriction: boolean;
  disabled?: boolean;
  readonly?: boolean;
  grade?: string;
  onComplete?: () => void;
}

export interface ActivitiesResumenFormValues {
  distinctionRank: SelectOptionType | null;
  financialStateChecked: boolean;
  interimDegreeAverage: string;
  grade: string;
  date: string;
}

const dictPrefix = 'interimDegreeGraduationProcess.activities.form';

export const ActivitiesResumenFormDefaultValues: ActivitiesResumenFormValues = {
  distinctionRank: null,
  financialStateChecked: false,
  interimDegreeAverage: '0',
  grade: '',
  date: '',
};

export default function ActivitiesResumenForm({
  authorizedStudentId,
  managment,
  financialRestriction,
  distinctionRanks = [],
  disabled = false,
  readonly = false,
  onComplete,
}: ActivitiesResumenFormProps) {
  const { t } = useTranslation();

  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const options = useMemo(() => {
    return distinctionRanks.map<SelectOptionType>((d) => ({
      value: d.id,
      label: d.name,
    }));
  }, [distinctionRanks]);

  const {
    msgValidations,
    rulesPatternDecimal,
    validateGreaterField,
  } = useValidations();

  const {
    control,
    handleSubmit,
    watch,
  } = useFormContext<ActivitiesResumenFormValues>();

  const [financialStateCheckedWatch, interimDegreeAverage] = watch([
    'financialStateChecked',
    'interimDegreeAverage',
  ]);

  const handleCreate = useCallback(
    async (formValues: ActivitiesResumenFormValues) => {
      if (
        !formValues.distinctionRank?.value ||
        isSubmitting ||
        readonly ||
        disabled
      ) {
        return;
      }
      setIsSubmitting(true);
      const { error } = await updateToGraduated(authorizedStudentId, {
        degreeDate: formValues.date,
        degreeGrade: formValues.grade,
        degreeDistinctionRankId: formValues.distinctionRank?.value,
      });
      setIsSubmitting(false);
      if (error) {
        addToast({
          text: t(`${dictPrefix}.errorOnUpdate`),
          color: 'danger',
          icon: 'error',
        });
      } else {
        addToast({
          text: t(`${dictPrefix}.successOnUpdate`),
          color: 'success',
          icon: 'check',
        });
        !!onComplete && onComplete();
      }
    },
    [readonly, disabled, onComplete, t, isSubmitting, authorizedStudentId],
  );

  return (
    <Col xs={12} className="mt-4">
      <form className="w-100" onSubmit={handleSubmit(handleCreate)}>
        <Row>
          {!readonly && (
            <Col className="mb-4" xs={12}>
              {managment === 'input' ? (
                <div className="d-flex w-100 align-items-center bg-light py-2 px-4 rounded">
                  <span className="text-dark font-weight-bold mr-1">
                    {t(`${dictPrefix}.financialStateChecked.label`)}
                  </span>
                  <span className="text-dark flex-fill">
                    {t(`${dictPrefix}.financialStateChecked.description`)}
                  </span>
                  <div className="position-relative" style={{ top: 10 }}>
                    <CheckInput
                      name="financialStateChecked"
                      label={t(`${dictPrefix}.financialStateChecked.check`)}
                      control={control}
                      rules={{
                        validate: (v) => v === true,
                      }}
                      disabled={disabled}
                    />
                  </div>
                </div>
              ) : (
                <Alert
                  color={financialRestriction ? 'danger' : 'info'}
                  icon={financialRestriction ? 'error' : 'success'}
                  size="lg"
                  text={t(
                    financialRestriction
                      ? `${dictPrefix}.financialStateChecked.hasBlockade`
                      : `${dictPrefix}.financialStateChecked.hasntBlockade`,
                  )}
                />
              )}
            </Col>
          )}
          <Col className="mb-3" xs={12} lg={5}>
            <Select
              name="distinctionRank"
              label={t(`${dictPrefix}.distinctionRank`)}
              options={options}
              control={control}
              disabled={
                disabled ||
                (managment === 'input' && !financialStateCheckedWatch)
              }
              rules={{
                required: msgValidations.required,
              }}
            />
          </Col>
          <Col className="mb-3" xs={12} lg={3}>
            <DateInput
              name="date"
              label={t(`${dictPrefix}.date`)}
              control={control}
              disabled={
                disabled ||
                (managment === 'input' && !financialStateCheckedWatch)
              }
              rules={{
                required: msgValidations.required,
              }}
            />
          </Col>
          <Col className="mb-3" xs={12} lg={2}>
            <TextInput
              name="interimDegreeAverage"
              label={t(`${dictPrefix}.interimDegreeAverage`)}
              control={control}
              disabled
            />
          </Col>
          <Col className="mb-3" xs={12} lg={2}>
            <TextInput
              name="grade"
              label={t(`${dictPrefix}.grade`)}
              control={control}
              disabled={
                disabled ||
                (managment === 'input' && !financialStateCheckedWatch)
              }
              rules={{
                required: msgValidations.required,
                pattern: rulesPatternDecimal,
                validate: {
                  require: (value) =>
                    validateGreaterField(
                      String(interimDegreeAverage),
                      String(value),
                    ),
                },
              }}
            />
          </Col>
        </Row>

        {!readonly && (
          <Row className="justify-content-end">
            <Col xs={12} lg={4}>
              <Button
                type="submit"
                text={t(`${dictPrefix}.submit`)}
                fullwidth
                disabled={
                  disabled ||
                  (managment === 'input' && !financialStateCheckedWatch)
                }
                loading={isSubmitting}
              />
            </Col>
          </Row>
        )}
      </form>
    </Col>
  );
}
