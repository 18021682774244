import { useCallback, useImperativeHandle, forwardRef, Ref } from 'react';
import { showDialogConfirm } from '@octano/global-ui';

import { useTranslation } from 'react-i18next';

export interface ConfirmExistingAccountModalContext {
  onConfirm: () => void;
}

export interface ConfirmExistingAccountModalMethods {
  open: (ctx: ConfirmExistingAccountModalContext) => void;
}

interface Props {
  onConfirm?: () => void;
}

const ConfirmExistingAccountModal = (
  { onConfirm }: Props,
  ref: Ref<ConfirmExistingAccountModalMethods>,
) => {
  const { t } = useTranslation();

  const handleOpen = useCallback(
    (ctx: ConfirmExistingAccountModalContext) => {
      showDialogConfirm({
        title: t('teacherActivation.existingAccountConfirmation.title'),
        subtitle: t(
          'teacherActivation.existingAccountConfirmation.description',
        ),
        btnConfirm: {
          text: t(`common.actions.confirm`),
          onConfirm: () => {
            ctx.onConfirm();
            !!onConfirm && onConfirm();
          },
        },
        btnClose: {
          text: t(`common.actions.cancel`),
        },
      });
    },
    [t, onConfirm],
  );

  useImperativeHandle(ref, () => ({
    open: handleOpen,
  }));

  return <></>;
};

export default forwardRef(ConfirmExistingAccountModal);
