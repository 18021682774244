import {
  Button,
  OutlinedSelect,
  SearchAccordion,
  SearchBox,
  SelectOptionType,
  addToast,
  useMobile,
} from '@octano/global-ui';
import { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Col, Form, Row } from 'reactstrap';

import { getFormDataRequest } from '../../../api/requests/sectionsMaintainer';
import Loading from '../../../components/info/Loading';

export type Filters = {
  period: SelectOptionType | null;
  search_text: string | null;
  faculty: SelectOptionType | null;
  studyPlan: SelectOptionType | null;
  campus: SelectOptionType | null;
  teacher: SelectOptionType | null;
};

const DEFAULT_VALUES = {
  period: null,
  search_text: null,
  faculty: null,
  studyPlan: null,
  campus: null,
  teacher: null,
};

interface SearchFiltersProps {
  onFiltersChange: (filterValues: Filters) => void;
  onFiltersReady?: () => void;
}

export default function SearchFilters({
  onFiltersChange = () => null,
  onFiltersReady = () => null,
}: SearchFiltersProps) {
  const { t } = useTranslation();
  const prefix = 'sectionsMaintainer.list.searchFilters';
  const [periods, setPeriods] = useState<SelectOptionType[]>([]);
  const [schools, setSchools] = useState<SelectOptionType[]>([]);
  const [studyPlans, setStudyPlans] = useState<SelectOptionType[]>([]);
  const [campuses, setCampuses] = useState<SelectOptionType[]>([]);
  const [teachers, setTeachers] = useState<SelectOptionType[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const isMobile = useMobile();

  const { handleSubmit, control, reset } = useForm<Filters>({
    defaultValues: DEFAULT_VALUES,
    mode: 'onChange',
  });

  const cleanValues = () => {
    reset();
    onFiltersChange(DEFAULT_VALUES);
  };

  const onSubmit = useCallback(
    async (values: Filters) => {
      onFiltersChange(values);
    },
    [onFiltersChange],
  );

  const getFormData = useCallback(async () => {
    setLoading(true);
    const { data, error } = await getFormDataRequest();
    if (data) {
      setCampuses(
        data.campuses.map((item) => ({ value: item.id, label: item.name })),
      );
      setStudyPlans(
        data.studyPlans.map((item) => ({ value: item.id, label: item.name })),
      );
      setSchools(
        data.schools.map((item) => ({ value: item.id, label: item.name })),
      );
      setPeriods(
        data.periods.map((item) => ({ value: item.id, label: item.name })),
      );
      setTeachers(
        data.teachers
          .filter((item) => !!item.account)
          .map((item) => ({
            value: item.account?.id ?? 0,
            label: item.account?.fullName ?? '',
          })),
      );
    } else if (error) {
      addToast({
        icon: 'error',
        color: 'danger',
        text: t('common.displayError.errorUnexpected'),
      });
    }
    setLoading(false);
  }, [setTeachers, setPeriods, setSchools, setStudyPlans, setCampuses, t]);

  useEffect(() => {
    if (!loading) onFiltersReady();
  }, [loading, onFiltersReady]);

  useEffect(() => {
    getFormData();
  }, [getFormData]);

  if (loading) {
    return <Loading insideCard />;
  }

  return (
    <div className="pb-4 pb-lg-1">
      <SearchAccordion btnText={t(`${prefix}.title`)}>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Row className="gutters-filters">
            <Col xs={12} md={6} lg={3}>
              <OutlinedSelect
                name="period"
                options={periods}
                label={t(`${prefix}.period`)}
                control={control}
              />
            </Col>
            <Col xs={12} md={6} lg={3}>
              <SearchBox
                name="search_text"
                label={t(`${prefix}.searchText`)}
                placeholder={t(`${prefix}.searchTextPlaceholder`)}
                control={control}
              />
            </Col>

            <Col xs={12} md={6} lg={3}>
              <OutlinedSelect
                name="faculty"
                options={schools}
                label={t(`${prefix}.faculty`)}
                control={control}
              />
            </Col>
            <Col xs={12} md={6} lg={3}>
              <OutlinedSelect
                name="studyPlan"
                options={studyPlans}
                label={t(`${prefix}.studyPlan`)}
                control={control}
              />
            </Col>
            <Col xs={12} md={6} lg={3}>
              <OutlinedSelect
                name="campus"
                options={campuses}
                label={t(`${prefix}.campus`)}
                control={control}
              />
            </Col>
            <Col xs={12} md={6} lg={3}>
              <OutlinedSelect
                name="teacher"
                options={teachers}
                label={t(`${prefix}.teacher`)}
                control={control}
              />
            </Col>
            <Col xs={12} md={6} lg={3} className="offset-md-6 offset-lg-3">
              <Row
                className="gutters-filters"
                style={{ marginTop: isMobile ? 0 : '1.5rem' }}
              >
                <Col>
                  <Button
                    type="submit"
                    text={t(`common.actions.search`)}
                    size="md"
                    fullwidth
                  />
                </Col>
                <Col>
                  <Button
                    text={t(`common.actions.clean`)}
                    size="md"
                    outlined
                    onClick={cleanValues}
                    fullwidth
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        </Form>
      </SearchAccordion>
    </div>
  );
}
