export interface LegendProps {
  label: string;
  color?: string;
}

export default function Legend({ label, color }: LegendProps) {
  return (
    <div className="d-flex align-items-center py-2">
      <div
        className="box-legend box-P mr-2"
        style={{
          width: '30px',
          height: '25px',
          borderRadius: '4px',
          backgroundColor: color,
        }}
      />
      {label}
    </div>
  );
}
