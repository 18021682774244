export const ITEMS_PER_PAGE_DEFAULT = 10;

interface Permissions {
  id?: number;
  key: string;
  name: string;
}

export interface RolePermission {
  id: number;
  rolesId: number;
  permissionsId: number;
  campusesAll: boolean;
  permission: Permissions;
  campuses: Campus[];
}

interface User {
  id: number;
  fullName: string;
}

export interface Role {
  id?: number;
  key: string;
  name: string;
  description?: string;
  createdAt?: Date;
  updatedAt?: Date;
  lastEditor?: User | null;
  rolePermissions: RolePermission[];
}

export interface IRoleManageForm
  extends Pick<Role, 'key' | 'description' | 'name'> {}

export enum PermissionResourceScope {
  Campus = 'campus',
}

export interface PermissionWithMetadata {
  group: string;
  key: string;
  name: string;
  scope?: PermissionResourceScope[];
}

export interface Campus {
  id: number;
  name?: string;
}

export interface School {
  id: number;
  name: string;
}

//API RESPONSES

export interface ResponseAdminRoles {
  data: Role[];
  total_pages: number;
  total: number;
}

export interface ResponseFormData {
  campuses: Campus[];
  schools: School[];
  permissions: PermissionWithMetadata[][];
}
