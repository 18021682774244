import { Icon, UserMenu, useMobile, useSidenav } from '@octano/global-ui';
import clsx from 'clsx';
import { useHistory } from 'react-router-dom';
import { Col, Container, Row } from 'reactstrap';

import useUserState from '../../hooks/useUserState';
import styles from './TopBar.module.scss';

export interface Props {
  title?: string;
}

export const TopBar = ({ title }: Props) => {
  const { userData, clearUser } = useUserState();
  const history = useHistory();
  const isMobile = useMobile();
  const { toggle: toggleSidenav } = useSidenav();

  const openManual = () => {
    window.open('/tenant/Manual Administrador Campus.pdf', '_blank')?.focus();
  };

  const logOut = () => {
    clearUser();
    history.push('/auth/login');
  };

  return (
    <div className="g-tabs-header">
      <div
        className={clsx({ [styles.bar]: true, [styles.barMobile]: isMobile })}
      >
        <Container fluid>
          <Row className="align-items-center justify-content-between">
            {isMobile && (
              <Col xs="auto">
                <span onClick={() => toggleSidenav()}>
                  <Icon name="menu" size="30px" className={styles.menuIcon} />
                </span>
              </Col>
            )}
            {!isMobile && (
              <Col>
                <span className={styles.title}>{title}</span>
              </Col>
            )}
            <Col xs="auto" className="cursor-pointer">
              <UserMenu
                fullName={userData?.fullname || ''}
                email={userData?.email || ''}
                menuItems={[
                  {
                    text: 'Manual de Usuario',
                    onClick: openManual,
                  },
                  {
                    icon: 'log_out',
                    text: 'Cerrar sesión',
                    onClick: logOut,
                  },
                ]}
              />
            </Col>
          </Row>
        </Container>
      </div>
      {isMobile && (
        <Container fluid>
          <h1 className={styles.title}>{title}</h1>
        </Container>
      )}
    </div>
  );
};
