import { TextInput } from '@octano/global-ui';
import { useMemo } from 'react';
import { Col, Row } from 'reactstrap';

export interface ResumeProps {
  name: string;
  code: string;
  status: string;
  isPrimary: true;
  duration: number;
  totalCredits: number;
  obligatoryCredits: number;
  optionalCredits: number;
  otherCredits: number;
  activityNumber: number;
  texts: {
    mentionCode: string;
    mentionName: string;
    duration: string;
    totalCredits: string;
    obligatory: string;
    optional: string;
    others: string;
    activitiesNumber: string;
    durationValue: (duration: number, periodType: string) => string;
  };
  periodTypeName?: string;
  periodTypeNamePlural?: string;
}

export default function Resume({ texts, ...data }: ResumeProps) {
  const hasMention = useMemo(() => {
    return !data.isPrimary;
  }, [data]);

  return (
    <Row>
      {hasMention && (
        <>
          <Col xs={12} sm={6} md={4} xl={4}>
            <TextInput
              name="mentionCode"
              label={texts.mentionCode}
              value={data.code}
              disabled
            />
          </Col>
          <Col xs={12} sm={6} md={4} xl={4}>
            <TextInput
              name="mentionName"
              label={texts.mentionName}
              value={data.name}
              disabled
            />
          </Col>
        </>
      )}
      <Col xs={12} sm={6} md={4} xl={hasMention ? 4 : ''}>
        <TextInput
          name="duration"
          label={texts.duration}
          value={texts.durationValue(
            data.duration,
            (data.duration === 1
              ? data?.periodTypeName
              : data?.periodTypeNamePlural) ?? '',
          )}
          disabled
        />
      </Col>
      <Col xs={12} sm={6} md={4} xl="">
        <TextInput
          name="totalCredits"
          label={texts.totalCredits}
          value={data.totalCredits.toString()}
          disabled
        />
      </Col>
      <Col xs={12} sm={6} md={4} xl="">
        <TextInput
          name="obligatory"
          label={texts.obligatory}
          value={data.obligatoryCredits.toString()}
          disabled
        />
      </Col>
      <Col xs={12} sm={6} md={4} xl="">
        <TextInput
          name="optional"
          label={texts.optional}
          value={data.optionalCredits.toString()}
          disabled
        />
      </Col>
      <Col xs={12} sm={6} md={4} xl="">
        <TextInput
          name="others"
          label={texts.others}
          value={data.otherCredits.toString()}
          disabled
        />
      </Col>
      <Col xs={12} sm={6} md={4} xl="">
        <TextInput
          name="nActivities"
          label={texts.activitiesNumber}
          value={data.activityNumber.toString()}
          disabled
        />
      </Col>
    </Row>
  );
}
