import { useEffect, useState } from 'react';

import { getScheduleWithCalendar } from '../../../api/requests/schedules';
import DisplayError from '../../../components/info/DisplayError';
import Loading from '../../../components/info/Loading';

export interface ScheduleRecord {
  id: number;
  code: string;
  name: string;
  description?: string | null;
  mondayStartTime: string;
  mondayEndTime: string;
  tuesdayStartTime: string;
  tuesdayEndTime: string;
  wednesdayStartTime: string;
  wednesdayEndTime: string;
  thursdayStartTime: string;
  thursdayEndTime: string;
  fridayStartTime: string;
  fridayEndTime: string;
  saturdayStartTime: string;
  saturdayEndTime: string;
  sundayStartTime: string;
  sundayEndTime: string;
}

export interface ScheduleRecordLoaderProps {
  scheduleId: number | string;
  children: (schedule: ScheduleRecord) => JSX.Element;
}

export const ScheduleRecordLoader = (props: ScheduleRecordLoaderProps) => {
  const { scheduleId, children } = props;
  const [loading, setLoading] = useState(true);
  const [schedule, setSchedule] = useState<ScheduleRecord | null>(null);
  const [error, setError] = useState<'HTTP_ERROR' | 'CONNECTION' | null>(null);

  useEffect(() => {
    if (scheduleId) {
      setLoading(true);
      getScheduleWithCalendar(scheduleId)
        .then((response) => {
          if (response.name) {
            setSchedule(response);
          } else if (response.code) {
            setError(response.code);
          }
        })
        .finally(() => setLoading(false));
    } else {
      setLoading(false);
    }
  }, [scheduleId]);

  if (loading) {
    return <Loading />;
  }

  if (error) {
    return <DisplayError textBody={error} />;
  }

  if (!schedule) {
    return <DisplayError textBody="HTTP_ERROR" />;
  }

  return children(schedule);
};
