import { useCallback, useEffect, useRef, useState } from 'react';

import { getGradesEntryConfig } from '../../../../api/requests/gradesEntry';
import { GradesEntryConfig } from '../../../../types/GradesEntry';

export default function useGradesEntryConfig() {
  const [isLoading, setIsLoading] = useState(true);
  const [isRefetching, setIsRefetching] = useState(false);
  const alreadyFetched = useRef(false);

  const [configs, setConfigs] = useState<GradesEntryConfig[] | null>();
  const [error, setError] = useState(false);

  const fetchConfigs = useCallback(async () => {
    if (alreadyFetched.current) setIsRefetching(true);
    else alreadyFetched.current = true;

    const { data, error: fetchError } = await getGradesEntryConfig();

    if (fetchError) {
      setError(true);
    } else {
      setConfigs(data);
    }

    setIsLoading(false);
    setIsRefetching(false);
  }, [alreadyFetched]);

  useEffect(() => {
    if (!alreadyFetched.current) {
      fetchConfigs();
    }
  }, [fetchConfigs]);

  return {
    isLoading,
    configs,
    error,
    refetch: fetchConfigs,
    isRefetching,
  };
}
