import { DetailBox, DetailBoxColumn } from '@octano/global-ui';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

interface CurriculumBoxProps {
  studyPlan?: string;
  studyPlanCode?: string;
  school?: string;
  version?: string;
  mention?: string;
}

const dictPrefix = 'interimDegree.interimDegrees.curriculumBox';

export default function CurriculumBox(props: CurriculumBoxProps) {
  const { t } = useTranslation();

  const columns = useMemo<DetailBoxColumn[]>(() => {
    return [
      {
        title: t(`${dictPrefix}.studyPlan`),
        body: props.studyPlan,
      },
      {
        title: t(`${dictPrefix}.studyPlanCode`),
        body: props.studyPlanCode,
      },
      {
        title: t(`${dictPrefix}.school`),
        body: props.school,
      },
      {
        title: t(`${dictPrefix}.version`),
        body: props.version,
      },
      {
        title: t(`${dictPrefix}.mention`),
        body: props.mention,
      },
    ];
  }, [props, t]);

  return (
    <div className="d-flex justify-content-end">
      <DetailBox columns={columns} />
    </div>
  );
}
