import { ScheduleLegends } from '@octano/global-ui';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

const CourseTypeLegends = ({
  legends,
}: {
  legends?: { id: number; name: string }[];
}) => {
  const prefix = 'scheduleLegends';
  const { t } = useTranslation();

  const scheduleLegends = useMemo(() => {
    return (
      legends?.map((act) => {
        return {
          id: act.id,
          name: t(`${prefix}.${act.name}`),
        };
      }) ?? [
        { id: 1, name: t(`${prefix}.course`) },
        { id: 2, name: t(`${prefix}.assistantship`) },
        { id: 3, name: t(`${prefix}.lab`) },
        { id: 4, name: t(`${prefix}.field`) },
        { id: 5, name: t(`${prefix}.workshop`) },
        { id: 6, name: t(`${prefix}.practice`) },
        { id: 7, name: t(`${prefix}.thesis`) },
        { id: 8, name: t(`${prefix}.other`) },
      ]
    );
  }, [legends, t]);

  return <ScheduleLegends legends={scheduleLegends} />;
};

export default CourseTypeLegends;
