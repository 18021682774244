import { TextAreaInput, TextInput, addToast } from '@octano/global-ui';
import { ReactChild, useCallback } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Col, Form, Row } from 'reactstrap';

import StartEndHour from '../../../components/inputs/StartEndHour';
import { SectionTitle } from '../../../components/text';
import { useLayoutState } from '../../../hooks/useLayoutState';
import { useValidations } from '../../../hooks/useValidations';

export interface ScheduleValues {
  code: string;
  name: string;
  description?: string;
  mondayStartTime: string;
  mondayEndTime: string;
  tuesdayStartTime: string;
  tuesdayEndTime: string;
  wednesdayStartTime: string;
  wednesdayEndTime: string;
  thursdayStartTime: string;
  thursdayEndTime: string;
  fridayStartTime: string;
  fridayEndTime: string;
  saturdayStartTime: string;
  saturdayEndTime: string;
  sundayStartTime: string;
  sundayEndTime: string;
}

export interface CalendarDayValue {
  day: string;
  startHour: string;
  endHour: string;
}
export interface ScheduleCalendarValues {
  code: string;
  scheduleCalendars: Array<CalendarDayValue>;
}

export interface ScheduleFormProps {
  defaultValues?: Partial<ScheduleValues>;
  children: (props: { isSubmitting: boolean }) => ReactChild;
  onSubmit: (
    values: ScheduleValues,
  ) => Promise<
    | 'CONNECTION'
    | 'HTTP_ERROR'
    | 'NOT_UNIQUE_CODE'
    | 'NOT_CALENDAR_HOURS'
    | 'NOT_VALID_CALENDAR_HOURS'
    | undefined
  >;
  readOnly?: boolean;
}

const ScheduleForm = (props: ScheduleFormProps) => {
  const {
    defaultValues: defaultValuesProp,
    onSubmit: onSubmitProp,
    readOnly,
    children,
  } = props;

  const { showErrorModal } = useLayoutState();

  const prefix = 'maintainers.scheduleForm';
  const { t } = useTranslation();

  const defaultValues = Object.assign(
    {
      code: '',
      name: '',
      description: '',
      mondayStartTime: '',
      mondayEndTime: '',
      tuesdayStartTime: '',
      tuesdayEndTime: '',
      wednesdayStartTime: '',
      wednesdayEndTime: '',
      thursdayStartTime: '',
      thursdayEndTime: '',
      fridayStartTime: '',
      fridayEndTime: '',
      saturdayStartTime: '',
      saturdayEndTime: '',
      sundayStartTime: '',
      sundayEndTime: '',
    },
    defaultValuesProp,
  );

  const methods = useForm<ScheduleValues>({
    mode: 'onSubmit',
    defaultValues,
  });

  const {
    handleSubmit,
    formState: { isSubmitting },
    control,
    setError,
  } = methods;

  const { validateTextNotEmpty } = useValidations();

  const onSubmit = useCallback(
    async (formValues: ScheduleValues) => {
      const response = await onSubmitProp(formValues);
      if (response === 'CONNECTION') {
        showErrorModal();
      } else if (response === 'NOT_UNIQUE_CODE') {
        setError('code', {
          message: t('common.errors.codeError', { code: formValues.code }),
        });
      } else if (response === 'HTTP_ERROR') {
        addToast({
          icon: 'error',
          color: 'danger',
          text: t('common.errors.save'),
        });
      } else if (response === 'NOT_CALENDAR_HOURS') {
        addToast({
          icon: 'error',
          color: 'danger',
          text: t('common.validations.noHoursSet'),
        });
      } else if (response === 'NOT_VALID_CALENDAR_HOURS') {
        addToast({
          icon: 'error',
          color: 'danger',
          text: t('common.validations.hoursNotValid'),
        });
      }
    },
    [onSubmitProp, showErrorModal, setError, t],
  );

  return (
    <FormProvider {...methods}>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Row className="mb-5">
          <Col xs={12} md>
            <SectionTitle text={t(`${prefix}.scheduleData`)} />
            <Row>
              <Col xs={12} md={6}>
                <TextInput
                  name="code"
                  label={t(`common.forms.code`)}
                  control={control}
                  rules={{ validate: validateTextNotEmpty }}
                  disabled={readOnly}
                />
              </Col>
              <Col xs={12} md={6}>
                <TextInput
                  name="name"
                  label={t(`common.forms.name`)}
                  control={control}
                  rules={{ validate: validateTextNotEmpty }}
                  disabled={readOnly}
                />
              </Col>
            </Row>
          </Col>
          <Col xs="auto">
            <div
              className="h-100"
              style={{ border: '1px solid #F4F4F4', opacity: 0.7 }}
            />
          </Col>
          <Col xs={12} md>
            <div className="pt-2">
              <TextAreaInput
                label={`${t('common.forms.description')} ${t(
                  'common.forms.optionalLabel',
                )}`}
                name="description"
                control={control}
                placeholder={t(`common.forms.descriptionPlaceholder`)}
                disabled={readOnly}
              />
            </div>
          </Col>
        </Row>
        <Row className="mb-5">
          <Col xs={12} md>
            <SectionTitle text={t(`${prefix}.weekCalendar`)} />
            <StartEndHour
              day={t(`${prefix}.monday`)}
              startId="mondayStartTime"
              endId="mondayEndTime"
              control={control}
              disabled={readOnly}
            />
            <StartEndHour
              day={t(`${prefix}.tuesday`)}
              startId="tuesdayStartTime"
              endId="tuesdayEndTime"
              control={control}
              disabled={readOnly}
            />
            <StartEndHour
              day={t(`${prefix}.wednesday`)}
              startId="wednesdayStartTime"
              endId="wednesdayEndTime"
              control={control}
              disabled={readOnly}
            />
            <StartEndHour
              day={t(`${prefix}.thursday`)}
              startId="thursdayStartTime"
              endId="thursdayEndTime"
              control={control}
              disabled={readOnly}
            />
          </Col>
          <Col xs={12} md>
            <Row className="d-none d-sm-none d-md-block d-lg-block d-xl-block g-white-space"></Row>
            <StartEndHour
              day={t(`${prefix}.friday`)}
              startId="fridayStartTime"
              endId="fridayEndTime"
              control={control}
              disabled={readOnly}
            />
            <StartEndHour
              day={t(`${prefix}.saturday`)}
              startId="saturdayStartTime"
              endId="saturdayEndTime"
              control={control}
              disabled={readOnly}
            />
            <StartEndHour
              day={t(`${prefix}.sunday`)}
              startId="sundayStartTime"
              endId="sundayEndTime"
              control={control}
              disabled={readOnly}
            />
          </Col>
        </Row>
        {children({ isSubmitting })}
      </Form>
    </FormProvider>
  );
};

export default ScheduleForm;
