import { Icon, Table, TablePagination } from '@octano/global-ui';
import { CellFormatOptions } from '@octano/global-ui/dist/components/Table/types/TableTypes';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { PeriodAcademicCalendarEvent } from '../../../api/requests/periods';
import TableEmptyContent from '../../../components/text/TableEmptyContent';
import { formatDate } from '../../../utils/dates';
import styles from './PeriodAcademicCalendarTable.module.scss';

export interface PeriodAcademicCalendar {
  eventCalendar: PeriodAcademicCalendarEvent;
  startDate?: string | null;
  endDate?: string | null;
}

export interface PeriodAcademicCalendarTableRecord {
  dates: string;
  eventName: string;
  academicCalendar: PeriodAcademicCalendar;
}

export interface PeriodAcademicCalendarTableProps {
  academicCalendars: PeriodAcademicCalendar[];
  onEdit: (academicCalendar: PeriodAcademicCalendar) => void;
}

const PeriodAcademicCalendarTable = ({
  academicCalendars,
  onEdit,
}: PeriodAcademicCalendarTableProps) => {
  const prefix = 'maintainers.periodsForm';
  const eventsTablePageSize = 6;
  const { t } = useTranslation();
  const [currentPage, setCurrentPage] = useState<number>(1);
  const changePage = (page: number = 1) => {
    setCurrentPage(page);
  };

  const events = useMemo<PeriodAcademicCalendarTableRecord[]>(() => {
    return academicCalendars.map((e) => {
      const dates = [];
      if (e.startDate) {
        dates.push(formatDate(e.startDate, 'DD/MM/YYYY'));
      }
      if (e.endDate) {
        dates.push(formatDate(e.endDate, 'DD/MM/YYYY'));
      }
      return {
        dates: dates.join(' - '),
        eventName: e.eventCalendar.name,
        academicCalendar: e,
      };
    });
  }, [academicCalendars]);

  const columns = [
    {
      columnName: 'eventName',
      headerText: t(`${prefix}.event`),
      width: '250px',
      bold: true,
    },
    {
      columnName: 'dates',
      headerText: t(`${prefix}.dates`),
      width: '200px',
    },
    {
      columnName: 'academicCalendar',
      headerText: '',
      width: '40px',
      cellFormat: (options: CellFormatOptions) => (
        <span
          onClick={() => {
            onEdit(options.value);
          }}
        >
          <Icon name="edit" key="edit" color="secondary" />
        </span>
      ),
    },
  ];

  return (
    <div className={styles.eventsTable}>
      <Table
        columns={columns}
        data={events.slice(
          (currentPage - 1) * eventsTablePageSize,
          currentPage * eventsTablePageSize,
        )}
        noResultsText={
          <TableEmptyContent
            title={t(`${prefix}.eventsTableEmptyTitle`)}
            subtitle={t(`${prefix}.eventsTableEmptySubtitle`)}
          />
        }
      />
      {events.length > eventsTablePageSize && (
        <TablePagination
          pagination={{
            currentPage,
            itemsPerPage: eventsTablePageSize,
            onChangePage: (page) => {
              changePage(page);
            },
            totalItems: events.length,
            totalPages: Math.ceil(events.length / eventsTablePageSize),
          }}
        />
      )}
    </div>
  );
};
export default PeriodAcademicCalendarTable;
