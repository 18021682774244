import { ReactElement, useCallback } from 'react';
import { AxiosResultDefaultError } from '../../../../../../../api/request';
import { AuthenticationError } from '../../../../../../../api/requests/tuitionProcess';
import { createFetchContext } from '../../../../../../../components/contexts/FetchContextFactory';
import DisplayError from '../../../../../../../components/info/DisplayError';
import Loading from '../../../../../../../components/info/Loading';
import { getForm } from '../../api';
import { HiringInformationFormDataResponse } from '../../type';

interface BaseLoaderProps {
  children: ReactElement;
}

const { FetchProvider, FetchConsumer, useFetch } = createFetchContext<
  undefined,
  HiringInformationFormDataResponse,
  AuthenticationError | AxiosResultDefaultError
>();

export function useHiringInformationFormDataLoader() {
  return useFetch();
}

export const HiringInformationFormDataLoaderConsumer = FetchConsumer;

export default function HiringInformationFormDataLoader({
  children,
}: BaseLoaderProps) {
  const request = useCallback(async () => {
    return getForm();
  }, []);

  return (
    <FetchProvider request={request} defaultQuery={undefined} fetchImmediately>
      <FetchConsumer>
        {({ loading, data, error, refresh }) => {
          if (loading) {
            return <Loading className="mx-auto" />;
          } else if (error) {
            return (
              <div className="w-100 d-flex justify-content-center">
                <DisplayError textBody={error.code} retryAction={refresh} />
              </div>
            );
          } else if (data) {
            return children;
          } else {
            return null;
          }
        }}
      </FetchConsumer>
    </FetchProvider>
  );
}
