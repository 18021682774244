import { Button } from '@octano/global-ui';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { Col, Row } from 'reactstrap';

import { ReactComponent as CompletedImage } from '../../../../../assets/svg/completed_image.svg';
import { PathsLayouts } from '../../../../../config/routes';

interface CompletedProps {
  onBack: () => void;
}

export default function Completed(props: CompletedProps) {
  const { onBack } = props;
  const prefix = 'changeStudyPlan.campusOrScheduleDetail.success';
  const { t } = useTranslation();
  const texts = useMemo(
    () => ({
      title: t(`${prefix}.title`),
      body: t(`${prefix}.body`),
      back: t(`${prefix}.back`),
      changeSections: t(`${prefix}.changeSections`),
    }),
    [t],
  );

  const history = useHistory();

  return (
    <>
      <span className="py-4 m-auto">
        <CompletedImage width="230px" />
      </span>
      <span className="text-center fs-20 text-dark">{texts.title}</span>
      <span className="text-center fs-18 fw-300">{texts.body}</span>
      <Row className="mt-4 justify-content-center">
        <Col xs={12} md={3} className="pb-2">
          <Button
            outlined
            color="primary"
            size="lg"
            text={texts.back}
            onClick={onBack}
            fullwidth
          />
        </Col>
        <Col xs={12} md={3} className="pb-2">
          <Button
            color="primary"
            size="lg"
            text={texts.changeSections}
            onClick={() => {
              history.push(PathsLayouts.courseRegistration);
            }}
            fullwidth
          />
        </Col>
      </Row>
    </>
  );
}
