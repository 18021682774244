export const CompareObjectValues = <T extends Record<string, any>>(
  object1: T,
  object2: T,
  strict = false,
) => {
  const object1Keys = Object.keys(object1);
  const object2Keys = Object.keys(object2);
  for (const key of object1Keys) {
    const value1 = object1[key];
    const value2 = object2[key];
    if (value1 !== value2) {
      return false;
    }
  }
  return !(strict && object1Keys.length !== object2Keys.length);
};
