import { Button, FileInput, Modal, useMobile } from '@octano/global-ui';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

interface UploadAttributesModalProps {
  isOpen: boolean;
  onConfirm?: (file: File) => void;
  onCancel?: () => void;
}

const UploadAttributesModal = ({
  isOpen,
  onConfirm = () => null,
  onCancel = () => null,
}: UploadAttributesModalProps): JSX.Element => {
  const isMobile = useMobile();
  const [file, setFile] = useState<File>();

  const { t } = useTranslation();
  const prefix = 'sectionAttributes.uploadModal';

  const handleConfirm = () => {
    if (file) onConfirm(file);
  };

  return (
    <Modal
      isOpen={isOpen}
      toggle={() => null}
      onExit={() => setFile(undefined)}
      onClosed={() => setFile(undefined)}
    >
      <div>
        <h1 className="text-dark text-center fw-600 fs-20">
          {t(`${prefix}.title`)}
        </h1>
        <p className=" text-center fs-16 lh-30 my-3">
          {t(`${prefix}.description`)}
        </p>
        <FileInput
          btnText={t(`${prefix}.button`)}
          value={file}
          name="file"
          label="Archivo"
          onChange={(f) => setFile(f)}
          accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
        />
        <div
          className="d-flex flex-wrap justify-content-between mt-4"
          style={{ gap: '1rem' }}
        >
          <Button
            text={t(`common.actions.close`)}
            outlined
            style={{ width: isMobile ? '100%' : 197 }}
            onClick={onCancel}
          />
          <Button
            text={t(`common.actions.process`)}
            style={{ width: isMobile ? '100%' : 197 }}
            onClick={handleConfirm}
          />
        </div>
      </div>
    </Modal>
  );
};

export default UploadAttributesModal;
