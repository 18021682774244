import { ACADEMIC_OFFERS_MAINTAINER } from '../../../api/endpoints';
import request from '../../../api/request';
import { StudyPlansListBySchoolResponse } from '../../../api/requests/studyPlans';
import { VacantOfferFormDataResponse, VacantOfferFormResponse } from './types';

export const getAcademicOfferCreateFormData = () => {
  return request<VacantOfferFormDataResponse>(
    ACADEMIC_OFFERS_MAINTAINER.VACANT_OFFERS_FORM_DATA,
  );
};

export const getAcademicOfferFormData = () => {
  return request<VacantOfferFormResponse>(
    ACADEMIC_OFFERS_MAINTAINER.VACANT_OFFERS_FIND_FORM_DATA,
  );
};

/**
 * VACANT OFFERS
 */

const VACANT_OFFERS = {
  GET_BY_SCHOOL_AND_ACADEMIC_OFFER: (
    schoolId: number,
    academicOfferId: number,
  ) =>
    `/maintainers-portal/academic-offer/vacant-offers/study-plan/school/${schoolId}/${academicOfferId}`,
};

/**
 * Obtiene el listado de planes de estudio de una facultad
 */
export const getStudyPlansBySchoolRequest = (
  periodId: number,
  schoolId: number,
  academicOfferId: number,
  items: number = 10,
  page: number = 0,
) => {
  const url = `${VACANT_OFFERS.GET_BY_SCHOOL_AND_ACADEMIC_OFFER(
    schoolId,
    academicOfferId,
  )}`;
  const params = {
    items_per_page: items,
    page: page,
    periodId,
  };
  return request<StudyPlansListBySchoolResponse>(url, {
    method: 'get',
    params,
  });
};
