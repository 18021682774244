import { useCallback, useEffect, useMemo, useState } from 'react';
import { Card, Col, Row } from 'reactstrap';

import DisplayError from '../../../../../../components/info/DisplayError';
import Loading from '../../../../../../components/info/Loading';
import { useLoadingState } from '../../../../../../hooks/useLoadingState';
import { STATUS_POSTULATION } from '../../../../../../types/tuitionProcessOnSite';
import { useTuitionProcessNoSua } from '../../hooks/useTuitionProcessNoSua';
import HeaderStep from '../HeaderStep';
import PaymentErp from './integrations/erp/Payment';
import PaymentOnSite from './parts/PaymentOnSite/Payment';
import PaymentDetails from './parts/PaymentOnSite/PaymentDetails';
import PaymentOnline from './parts/PaymentOnline/Payment';
import { getPaymentData } from './services/requests';
import {
  GatewayPaymentResponse,
  IntegrationFinantialSystemEnum,
} from './types';

const PaymentStepNoSuaStep = () => {
  const prefix = `tuitionProcessNoSua.payment`;
  const {
    loading,
    setLoading,
    errorLoading,
    setErrorLoading,
  } = useLoadingState();

  const [paymentData, setPaymentData] = useState<GatewayPaymentResponse>();

  //Custom Hooks
  const {
    postulant,
    statusPostulation,
    postulationDetail,
    newPostulationDetail,
    tuitionInfo,
  } = useTuitionProcessNoSua();

  // Memoization
  const studyPlanNameByStatus: string = useMemo(() => {
    if (statusPostulation === STATUS_POSTULATION.STUDY_PLAN_CHANGE) {
      return newPostulationDetail?.studyPlanName ?? '';
    }
    return postulationDetail?.studyPlanName ?? '';
  }, [
    statusPostulation,
    postulationDetail?.studyPlanName,
    newPostulationDetail?.studyPlanName,
  ]);

  const requestPaymentData = useCallback(async () => {
    if (!postulationDetail || !postulant) {
      return;
    }
    setLoading(true);
    try {
      const urlParams = new URLSearchParams(window.location.search);
      const token = urlParams.get('TBK_TOKEN') ?? urlParams?.get('token_ws');

      const { data, error } = await getPaymentData({
        token: token ?? undefined,
        postulationDetailId: postulationDetail.id,
      });

      if (error) {
        setErrorLoading(error?.data?.code);
      } else if (data) {
        setPaymentData(data ?? undefined);
      }
    } catch (_error) {}
    setLoading(false);
  }, [postulant, postulationDetail, setErrorLoading, setLoading]);

  useEffect(() => {
    requestPaymentData();
  }, [requestPaymentData]);

  if (errorLoading) {
    return (
      <DisplayError
        insideCard
        textBody={errorLoading}
        retryAction={() => requestPaymentData()}
        loadingAction={loading}
      />
    );
  }

  if (loading) {
    return <Loading insideCard />;
  }

  return (
    <Card className="px-4 px-lg-5 py-4">
      <Row className="pb-4 pb-md-0">
        <Col xs={12}>
          <HeaderStep prefixStep={prefix} showBackBtn={true} />
        </Col>
      </Row>
      <Row>
        {tuitionInfo?.isOnlineTuition ? (
          <Col xs={12} sm={6} className="pt-lg-4 pb-lg-5">
            {paymentData && <PaymentOnline {...paymentData} />}
          </Col>
        ) : paymentData?.integration ===
          IntegrationFinantialSystemEnum.SOFTLAND ? (
          <>
            <Col
              xs={12}
              sm={12}
              lg={6}
              xl={4}
              className="pt-lg-4 pb-lg-5 d-flex flex-column"
              style={{ gap: '24px' }}
            >
              <PaymentDetails
                postulant={{
                  name: paymentData?.details?.student?.name ?? '',
                  rut: paymentData?.details?.student?.rut ?? '',
                  passport: paymentData?.details?.student?.passport ?? '',
                }}
                sustainer={
                  paymentData?.sustainerName
                    ? {
                        name: paymentData?.sustainerName,
                        rut: paymentData?.sustainerRut,
                        passport: paymentData?.sustainerPassport,
                      }
                    : undefined
                }
                program={studyPlanNameByStatus}
                operation={paymentData?.operation ?? 0}
              />
            </Col>
            <Col
              xs={12}
              sm={12}
              lg={6}
              xl={8}
              className="pt-lg-4 pb-lg-5 d-flex flex-column"
              style={{ gap: '24px' }}
            >
              {paymentData && <PaymentOnSite {...paymentData} />}
            </Col>
          </>
        ) : paymentData?.integration === IntegrationFinantialSystemEnum.ERP ? (
          <PaymentErp paymentData={paymentData} />
        ) : (
          <>Debería hacer una integración</>
        )}
      </Row>
    </Card>
  );
};

export default PaymentStepNoSuaStep;
