import { Table } from '@octano/global-ui';
import { useHiringInformationLoader } from './Loaders/HiringInformationLoader';
import useSectionsColumns from '../hooks/useSectionsColumns';

function SectionsTable() {
  const { data, loading } = useHiringInformationLoader();
  const columns = useSectionsColumns();

  return (
    <div className="mt-5 w-100">
      <Table
        columns={columns}
        data={data?.sections ?? []}
        isLoadingResults={loading}
      />
    </div>
  );
}

export default SectionsTable;
