import { Steps } from '@octano/global-ui';
import { useCallback, useEffect, useState } from 'react';
import { Col, Row } from 'reactstrap';

import { getStatusTuitionProcess } from '../../api/requests/tuitionProcess';
import DisplayError from '../../components/info/DisplayError';
import Loading from '../../components/info/Loading';
import StepProvider from '../../components/step/StepProvider';
import { useStepState } from '../../components/step/useStepState';
import { useLoadingState } from '../../hooks/useLoadingState';
import withProvider from '../../providers/withProvider';
import { useTuitionProcess } from './TuitionProcessContext';
import { useTuitionProcessSteps } from './useTuitionProcessSteps';

/**
 * Vista del proceso de matrícula
 */
const TuitionProcess = () => {
  const {
    loading,
    setLoading,
    errorLoading,
    setErrorLoading,
  } = useLoadingState();
  const [hasGratuity, setHasGratuity] = useState<boolean | null>(null);
  const [isOnlineTuition, setIsOnlineTuition] = useState<boolean | null>(null);
  const {
    namesSteps,
    CurrentStepComponent,
    getIndexStep,
  } = useTuitionProcessSteps(hasGratuity, isOnlineTuition);
  const { currentStep, setCurrentStep } = useStepState();
  const [idStep, setIdStep] = useState<string>();

  const { postulationDetailId, onError } = useTuitionProcess();

  const getStatus = useCallback(async () => {
    const { data, error } = await getStatusTuitionProcess(postulationDetailId);
    if (data) {
      setHasGratuity(data.hasPreassignedGratuity);
      setIsOnlineTuition(data.isOnlineTuition);
      setIdStep(data.step);
      setErrorLoading(undefined);
    } else if (error) {
      setErrorLoading(error.code);
      onError(error.code);
    }
    setLoading(false);
  }, [setErrorLoading, setLoading, postulationDetailId, onError]);

  useEffect(() => {
    if (loading) {
      getStatus();
    }
  }, [loading, getStatus]);

  useEffect(() => {
    if (idStep && hasGratuity !== null) {
      const index = getIndexStep(idStep);
      setCurrentStep(index);
    }
  }, [idStep, hasGratuity, getIndexStep, setCurrentStep]);

  if (errorLoading) {
    return (
      <DisplayError
        insideCard
        textBody={errorLoading}
        retryAction={() => setLoading(true)}
        loadingAction={loading}
      />
    );
  }
  if (loading) {
    return <Loading insideCard />;
  }
  return (
    <Row>
      {/* Pasos del proceso */}
      <Col xs={12} className="pb-4">
        <Steps steps={namesSteps} currentStep={currentStep} />
      </Col>
      <Col xs={12} className="pt-2">
        {/* Se renderiza el componente adecuado para cada paso */}
        {CurrentStepComponent && <CurrentStepComponent />}
      </Col>
    </Row>
  );
};

export default withProvider(TuitionProcess, StepProvider);
