import {
  Button,
  OutlinedSelect,
  SelectOptionType,
  addToast,
  useMobile,
} from '@octano/global-ui';
import { useCallback, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Col, Form, Row } from 'reactstrap';

import { modifyEnrollment } from '../api';

interface FormValues {
  offer: SelectOptionType | null;
}

interface ChangeFormProps {
  studyPlanEnrollmentId: number;
  studyPlanVersionOfferId: number;
  offers: {
    id: number;
    scheduleId: number;
    scheduleName: string;
    campusName: string;
    campusId: number;
  }[];
  defaultValues: {
    offer: SelectOptionType;
  };
  onUpdate: () => void;
  onCancel: () => void;
}
export default function ChangeForm(props: ChangeFormProps) {
  const { t } = useTranslation();
  const texts = useMemo(
    () => ({
      form: {
        offer: t('changeStudyPlan.campusOrScheduleDetail.form.offer'),
        cancel: t('changeStudyPlan.campusOrScheduleDetail.form.cancel'),
        confirm: t('changeStudyPlan.campusOrScheduleDetail.form.confirm'),
      },
      sameOfferError: t(
        'changeStudyPlan.campusOrScheduleDetail.sameOfferError',
      ),
      unknownError: t('changeStudyPlan.campusOrScheduleDetail.unknownError'),
    }),
    [t],
  );

  const {
    studyPlanEnrollmentId,
    studyPlanVersionOfferId,
    defaultValues,
    onUpdate,
    onCancel,
  } = props;
  const isMobile = useMobile();
  const form = useForm<FormValues>({ defaultValues });
  const { handleSubmit, control, formState } = form;

  const offersOptions = props.offers.map((offer) => ({
    label: `${offer.campusName} - ${offer.scheduleName}`,
    value: offer.id,
  }));

  const onSubmit = useCallback(
    async (data: FormValues) => {
      if (data.offer?.value === studyPlanVersionOfferId) {
        addToast({
          icon: 'error',
          color: 'danger',
          text: texts.sameOfferError,
        });
        return;
      }
      if (typeof data.offer?.value !== 'number') {
        throw new Error(
          `La oferta debe ser un number. Su valor es ${data.offer?.value}`,
        );
      }
      const result = await modifyEnrollment(studyPlanEnrollmentId, {
        studyPlanVersionOfferId: data.offer?.value,
      });
      if (result.error) {
        addToast({
          icon: 'error',
          color: 'danger',
          text: texts.unknownError,
        });
      } else {
        onUpdate();
      }
    },
    [
      studyPlanEnrollmentId,
      studyPlanVersionOfferId,
      texts.sameOfferError,
      texts.unknownError,
      onUpdate,
    ],
  );
  return (
    <>
      <Form
        onSubmit={handleSubmit(onSubmit)}
        className=" h-100 d-flex flex-column"
      >
        <div className={isMobile ? undefined : 'w-50 m-auto'}>
          <OutlinedSelect
            isClearable={false}
            control={control}
            label={texts.form.offer}
            name="offer"
            options={offersOptions}
            rules={{
              required: true,
            }}
          />
        </div>
        <Row className="px-2">
          <Col sm={3} className="pb-2 px-3 px-sm-2 ml-auto">
            <Button
              text={texts.form.cancel}
              onClick={onCancel}
              outlined
              fullwidth
            />
          </Col>
          <Col sm={3} className="pb-2 px-3 px-sm-2">
            <Button
              type="submit"
              text={texts.form.confirm}
              fullwidth
              loading={formState.isSubmitting}
            />
          </Col>
        </Row>
      </Form>
    </>
  );
}
