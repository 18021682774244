import { useCallback, useState } from 'react';

import { StudentDocumentReviewInfDocument } from '../../../types/tuitionContinuityDocumentReviewDocuments';

type ModalData =
  | {
      open: true;
      document: StudentDocumentReviewInfDocument;
      onConfirm: (data: StudentDocumentReviewInfDocument) => void;
      onClose: () => void;
    }
  | {
      open: false;
      document?: undefined;
    };

export function useOnUpload({
  onConfirm,
}: {
  onConfirm: (row: StudentDocumentReviewInfDocument) => void;
}) {
  const [modalAddDocument, setModalAddDocument] = useState<ModalData>({
    open: false,
  });

  const onUpload = useCallback(
    async (row: StudentDocumentReviewInfDocument) => {
      const onClose = () => {
        setModalAddDocument({ open: false });
      };

      setModalAddDocument({ open: true, document: row, onConfirm, onClose });
    },
    [onConfirm],
  );

  return {
    data: modalAddDocument,
    onUpload,
  };
}
