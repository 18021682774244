import {
  ReactNode,
  createContext,
  useCallback,
  useEffect,
  useState,
} from 'react';

import SessionExpiredModal from '../components/modals/SessionExpiredModal';
import { Account, Permission, UserSessionEvent } from '../types/Auth';
import { isUserAuthorizedTo, removeJWT } from '../utils/auth';

interface UserData extends Account {
  permissions?: Permission['key'][];
}

interface UserContextType {
  userData?: UserData;
  setUserData: (newData: Partial<UserData>) => void;
  clearUser: () => void;
  isAuthorizedTo: (
    requiredPermissions: string[],
    allPermisionsRequired?: boolean,
  ) => boolean;
}

const DEFAULT_VALUE = {
  userData: undefined,
  setUserData: () => null,
  clearUser: () => null,
  isAuthorizedTo: (rp: string[], allPermisionsRequired?: boolean) => true,
};

export const UserStateContext = createContext<UserContextType>(DEFAULT_VALUE);

interface Props {
  user?: UserData;
  children: ReactNode;
}

export default function UserStateProvider({ user: userData, children }: Props) {
  const [user, setUser] = useState<UserData | undefined>(userData);
  const [showSessionExpiredModal, setShowSessionExpiredModal] = useState(false);

  const setUserData = useCallback((newData: Partial<UserData>) => {
    setUser((prevData) =>
      !!prevData ? { ...prevData, ...newData } : (newData as UserData),
    );
  }, []);

  const clearUser = useCallback(() => {
    setUser(undefined);
    removeJWT();
  }, []);

  const isAuthorizedTo = (
    requiredPermissions: string[],
    allPermisionsRequired?: boolean,
  ) => {
    return isUserAuthorizedTo(
      user?.permissions || [],
      requiredPermissions,
      allPermisionsRequired,
    );
  };

  // Handling session expiration
  useEffect(() => {
    const notifySessionExpiration = () => {
      setShowSessionExpiredModal(true);
    };

    window.addEventListener(UserSessionEvent.EXPIRED, notifySessionExpiration);

    return () => {
      window.removeEventListener(
        UserSessionEvent.EXPIRED,
        notifySessionExpiration,
      );
    };
  }, []);

  return (
    <UserStateContext.Provider
      value={{
        userData: user,
        setUserData,
        clearUser,
        isAuthorizedTo,
      }}
    >
      <>
        <SessionExpiredModal
          isOpen={showSessionExpiredModal}
          onConfirm={() => {
            clearUser();
            setShowSessionExpiredModal(false);
          }}
        />
        {children}
      </>
    </UserStateContext.Provider>
  );
}
