import request from '../../../../api/request';
import { SearchParams } from './types/Filters';
import {
  IRoleManageForm,
  ResponseAdminRoles,
  ResponseFormData,
} from './types/Roles';

export const ROLES = {
  LIST: '/maintainers-portal/admin-roles',
  PERMISSIONS_LIST: '/maintainers-portal/admin-roles/form-data',
  GET: (roleId: number | string) => `/maintainers-portal/admin-roles/${roleId}`,
  SEARCH: '/maintainers-portal/admin-roles/search',
  PUT: (roleId: number | string) => `/maintainers-portal/admin-roles/${roleId}`,
  PUT_ROLE_PERMISSIONS: (roleId: number | string) =>
    `/maintainers-portal/admin-roles/${roleId}/permissions`,
  DELETE: (roleId: number | string) =>
    `/maintainers-portal/admin-roles/${roleId}`,
  POST: '/maintainers-portal/admin-roles',
};

/**
 * Obtiene el listado de las usuarios de tipo administrador
 * @param items
 * @param page
 * @returns
 */
export function getAdminRoleList(
  filter: { items: number; page: number } & SearchParams,
) {
  const URL = ROLES.SEARCH;
  const method = 'GET';
  const params = { ...filter, items_per_page: filter.items };
  return request<ResponseAdminRoles>(URL, { method, params });
}

export function getFormData() {
  return request<ResponseFormData>(ROLES.PERMISSIONS_LIST);
}

export const searchRoleById = (rut: string | number) => {
  return request(ROLES.GET(rut));
};

export const createRole = (data: IRoleManageForm) => {
  return request<{ data: { id: number } }>(ROLES.POST, {
    method: 'POST',
    data,
  });
};

export const updateRole = (roleId: number, data: IRoleManageForm) => {
  return request(ROLES.PUT(roleId), {
    method: 'PUT',
    data,
  });
};

export const updateRolePermissions = async (
  roleId: number | string,
  data: any,
) => {
  return request(ROLES.PUT_ROLE_PERMISSIONS(roleId), {
    method: 'PUT',
    data,
  });
};
