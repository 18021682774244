import React, {
  forwardRef,
  useImperativeHandle,
  useState,
  useCallback,
} from 'react';
import { useTranslation } from 'react-i18next';
import { generateStudents } from '../../../api/requests/interimDegreeGraduationMassive';
import { generateSingleStudent } from '../../../api/requests/interimDegreeGraduationSingle';
import LoadingModal from '../../../components/modals/LoadingModal';

export type InterimDegreeGenerateModalMethods = {
  open: (id: number) => void;
  openSingle: (studyPlanEnrollmentId: number, interimDegreeId: number) => void;
  close: () => void;
};

interface InterimDegreeGenerateModalProps {
  onGenerate?: (id: number, batch: number) => void;
  onFailed?: () => void;
  onFailedSingle?: (
    studyPlanEnrollmentId: number,
    interimDegreeId: number,
    reason: string,
  ) => void;
}

const dictPrefix = 'interimDegreeGraduationProcess.massive.generateModal';

const InterimDegreeGenerateModal = (
  { onGenerate, onFailed, onFailedSingle }: InterimDegreeGenerateModalProps,
  ref: React.Ref<InterimDegreeGenerateModalMethods>,
) => {
  const { t } = useTranslation();

  const [visible, setVisible] = useState<boolean>(false);

  const handleClose = useCallback(() => {
    setVisible(false);
  }, []);

  const handleGenerate = useCallback(
    async (id: number) => {
      try {
        const { error, data } = await generateStudents({ id });
        if (!error) {
          !!onGenerate && onGenerate(id, (data?.lastBatch ?? 0) + 1);
        } else {
          !!onFailed && onFailed();
        }
      } catch (error) {
        !!onFailed && onFailed();
      } finally {
        handleClose();
      }
    },
    [handleClose, onGenerate, onFailed],
  );

  const handleGenerateSingle = useCallback(
    async (params: {
      studyPlanEnrollmentId: number;
      interimDegreeId: number;
    }) => {
      const { studyPlanEnrollmentId, interimDegreeId } = params;
      try {
        const { error, data } = await generateSingleStudent(params);
        if (!error && data) {
          !!onGenerate && onGenerate(0, data.batch);
        } else {
          console.log('error', (error as any)?.data?.message);
          !!onFailedSingle &&
            onFailedSingle(
              studyPlanEnrollmentId,
              interimDegreeId,
              (error as any)?.data?.message ?? '',
            );
        }
      } catch (error) {
        !!onFailedSingle &&
          onFailedSingle(
            studyPlanEnrollmentId,
            interimDegreeId,
            (error as any)?.response?.data?.message ?? '',
          );
      } finally {
        handleClose();
      }
    },
    [handleClose, onGenerate, onFailedSingle],
  );

  const handleOpen = useCallback(
    (id: number) => {
      setVisible(true);
      handleGenerate(id);
    },
    [handleGenerate],
  );

  const handleOpenSingle = useCallback(
    (studyPlanEnrollmentId: number, interimDegreeId: number) => {
      setVisible(true);
      handleGenerateSingle({ studyPlanEnrollmentId, interimDegreeId });
    },
    [handleGenerateSingle],
  );

  useImperativeHandle(ref, () => ({
    open: handleOpen,
    openSingle: handleOpenSingle,
    close: handleClose,
  }));

  return (
    <LoadingModal
      title={t(`${dictPrefix}.title`)}
      description={t(`${dictPrefix}.description`)}
      isOpen={visible}
    />
  );
};

export default forwardRef(InterimDegreeGenerateModal);
