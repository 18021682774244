import { PeriodItem } from '../../views/AcademicStructure/periods/PeriodsList';
import { ACADEMIC_STRUCTURE } from '../endpoints';
import request, {
  AxiosErrorWithResponse,
  AxiosResult,
  AxiosResultDefaultError,
} from '../request';
import { PeriodType } from './periodsType';

interface PeriodsTypesListResponse {
  data: PeriodType[];
  total: number;
  total_pages: number;
}

interface PeriodsListResponse {
  data: PeriodItem[];
  total: number;
  total_pages: number;
}

export interface PeriodAcademicCalendarEvent {
  id: number;
  name: string;
  interval: string;
  hasRequiredCredits: boolean;
}

export interface PeriodAcademicCalendar {
  eventCalendar: PeriodAcademicCalendarEvent;
  startDate: string;
  endDate: string;
}

export interface Period {
  id: number;
  code: string;
  name: string;
  description?: string | null;
  startDate: string;
  endDate: string;
  academicCalendars: PeriodAcademicCalendar[];
  periodType: PeriodType;
  creditMin: number;
  creditMax: number;
}

/**
 * Obtiene el listado de los tipos de periodos
 */
export const getPeriodsAllTypesRequest = () => {
  const url = `${ACADEMIC_STRUCTURE.PERIODS_TYPE_ALL}`;
  return request<PeriodType[]>(url);
};

/**
 * Obtiene el listado de los tipos de periodos
 */
export const getPeriodsTypesRequest = (
  items: number = 10,
  page: number = 0,
) => {
  const url = `${ACADEMIC_STRUCTURE.PERIODS_TYPE}?items_per_page=${items}&page=${page}`;
  return request<PeriodsTypesListResponse>(url);
};

/**
 * Obtiene el listado de periodos
 */
export const getPeriodsRequest = (items: number = 10, page: number = 0) => {
  const url = `${ACADEMIC_STRUCTURE.PERIODS}?items_per_page=${items}&page=${page}`;
  return request<PeriodsListResponse>(url);
};

/**
 * Obtiene el listado de periodos sin oferta academica
 */
export const getPeriodsWithNoOfferRequest = () => {
  const url = `${ACADEMIC_STRUCTURE.PERIODS}/with-no-offer`;
  return request<PeriodsListResponse>(url);
};

/**
 * Obtiene el listado de eventos
 */
export const getEventsRequest = () => {
  const url = `${ACADEMIC_STRUCTURE.PERIODS}/events`;
  return request<PeriodAcademicCalendarEvent[]>(url);
};

/**
 * Obtiene una jornada
 */
export const getPeriod = (id: number | string) => {
  const url = `${ACADEMIC_STRUCTURE.PERIODS}/${id}`;
  return request<{ data: Period }>(url);
};

export type PeriodCodeNotUniqueError = {
  code: 'NOT_UNIQUE_CODE';
  error: AxiosErrorWithResponse;
  status: number;
  data?: any;
};

export type PeriodRequestErrors =
  | AxiosResultDefaultError
  | PeriodCodeNotUniqueError;

export type PeriodValues = {
  code: string;
  name: string;
  description: string;
  startDate: string;
  endDate: string;
  periodTypeId: number | string;
};
/**
 *  Crear una jornada
 */
export const createPeriodRequest = async (
  data: PeriodValues,
): Promise<AxiosResult<Period, PeriodRequestErrors>> => {
  const response = await request<Period>(ACADEMIC_STRUCTURE.PERIODS, {
    method: 'post',
    data,
  });
  if (response.error) {
    if (response.error.code === 'HTTP_ERROR' && response.error.status === 400) {
      return {
        error: {
          ...response.error,
          code: 'NOT_UNIQUE_CODE',
        } as PeriodCodeNotUniqueError,
      };
    } else {
      return response;
    }
  }
  return {
    data: response.data,
  };
};

/**
 *  Actualizar una jornada
 */
export const updatePeriodRequest = async (
  periodId: number | string,
  data: PeriodValues,
): Promise<AxiosResult<Period, PeriodRequestErrors>> => {
  const response = await request<{ data: Period }>(
    `${ACADEMIC_STRUCTURE.PERIODS}/${periodId}`,
    {
      method: 'put',
      data,
    },
  );
  if (response.error) {
    if (response.error.code === 'HTTP_ERROR' && response.error.status === 400) {
      return {
        error: {
          ...response.error,
          code: 'NOT_UNIQUE_CODE',
        } as PeriodCodeNotUniqueError,
      };
    } else {
      return response;
    }
  }
  return {
    data: response.data.data,
  };
};
