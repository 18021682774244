import { ReactElement, useCallback } from 'react';
import { AxiosResultDefaultError } from '../../../../../../../api/request';
import { createFetchContext } from '../../../../../../../components/contexts/FetchContextFactory';
import { getTitles } from '../../api';
import { EmploymentBackgroundResponse } from '../../type';

interface BaseLoaderProps {
  teacherId: number;
  children: ReactElement;
}

const { FetchProvider, FetchConsumer, useFetch } = createFetchContext<
  undefined,
  EmploymentBackgroundResponse,
  AxiosResultDefaultError
>();

export function useEmploymentBackgroundLoader() {
  return useFetch();
}

export const EmploymentBackgroundLoaderConsumer = FetchConsumer;

export default function EmploymentBackgroundLoader({
  teacherId,
  children,
}: BaseLoaderProps) {
  const request = useCallback(async () => {
    return getTitles(teacherId);
  }, [teacherId]);

  return (
    <FetchProvider request={request} defaultQuery={undefined} fetchImmediately>
      {children}
    </FetchProvider>
  );
}
