import { ReactElement, useCallback } from 'react';
import { Col } from 'reactstrap';
import { createFetchContext } from '../../../components/contexts/FetchContextFactory';
import DisplayError from '../../../components/info/DisplayError';
import Loading from '../../../components/info/Loading';
import { getDiscountFromPostulationDetail } from '../../api';
import { PostulationDetailDiscount } from '../../types';

interface PostulantDiscountsLoaderProps {
  children: ReactElement;
  postulationDetailId: string | number;
}

const { FetchProvider, FetchConsumer, useFetch } = createFetchContext<
  undefined,
  PostulationDetailDiscount[]
>();

export function usePostulantDiscountLoader() {
  return useFetch();
}

export const PostulantDiscountsLoaderConsumer = FetchConsumer;

export function PostulantDiscountsLoader({
  children,
  postulationDetailId,
}: PostulantDiscountsLoaderProps) {
  const request = useCallback(() => {
    return getDiscountFromPostulationDetail(postulationDetailId);
  }, [postulationDetailId]);
  return (
    <FetchProvider request={request} defaultQuery={undefined} fetchImmediately>
      <FetchConsumer>
        {({ loading, error, refresh }) => {
          if (error) {
            if (error.code === 'HTTP_ERROR' && error.status !== 404) {
              return (
                <Col md={12}>
                  <DisplayError
                    insideCard
                    textBody={error.code}
                    retryAction={refresh}
                    loadingAction={loading}
                  />
                </Col>
              );
            }
          }
          if (loading) {
            return (
              <Col md={12}>
                <div className="mx-3">
                  <Loading insideCard />
                </div>
              </Col>
            );
          }
          return children;
        }}
      </FetchConsumer>
    </FetchProvider>
  );
}
