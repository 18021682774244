import { StructureType } from '../../interfaces/type';
import { useTexts } from '../../hooks/textsContext';

const Leyenda = ({
  structureType,
  showDescription,
}: {
  structureType: StructureType;
  showDescription?: boolean;
}) => {
  const texts = useTexts();

  return (
    <div className="d-flex align-flex-start justify-content-start align-items-center">
      <div
        style={{
          width: 25,
          height: 25,
          background: structureType?.color,
        }}
      />
      <div>
        {showDescription ? (
          <p className="fs-14 fw-600 pl-2 m-0 mt-2">
            <b>
              {
                texts.structures[
                  structureType?.code as keyof typeof texts.structures
                ]
              }
            </b>
          </p>
        ) : (
          <p className="fs-14 fw-600 pl-2 m-0 mt-2">{structureType?.name}</p>
        )}
        {showDescription && (
          <p className="fs-14 pl-2 m-0 mb-2">{structureType?.description}</p>
        )}
      </div>
    </div>
  );
};

export default Leyenda;
