export enum InterimDegreesStudentActivityStatus {
  Approved = 'approved',
  Failed = 'failed',
}

export interface InterimDegreeStudentActivity {
  id: number;
  interimDegreeAuthorizedStudentId: number;
  interimDegreeActivityId: number;
  editorId: number | null;
  status: InterimDegreesStudentActivityStatus;
  date: string;
  grade: string | null;
  maxGrade: string | null;
  comments: string | null;
}
