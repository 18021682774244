import request from '../../../api/request';
import { PaymentHistoryResponse } from './types';

const CURRENT_ACCOUNT = {
  PAYMENT_HISTORY: (studentId: string | number) =>
    `maintainers-portal/current-accounts/students/${studentId}/payment-history`,
};

export function getPaymentHistory(studentId: string | number) {
  return request<PaymentHistoryResponse>(
    CURRENT_ACCOUNT.PAYMENT_HISTORY(studentId),
  );
}
