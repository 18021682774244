import { Table } from '@octano/global-ui';
import { useHistory } from 'react-router-dom';
import { PathsLayouts } from '../../../../config/routes';
import { useTexts } from '../hooks/textsContext';
import useColumnActions from '../hooks/useColumnActions';
import useColumns from '../hooks/useColumns';
import { AcademicLevels } from '../interfaces/type';
import Leyenda from './Leyenda';
import { useGradingStructureFormLoader } from './Loaders/GradingStructureFormLoader';
import { useGradingStructureLoader } from './Loaders/GradingStructureLoader';

function GradingStructureTable() {
  const texts = useTexts();
  const { data, loading } = useGradingStructureLoader();
  const { data: options } = useGradingStructureFormLoader();
  const history = useHistory();

  const onClickEdit = (row: AcademicLevels) => {
    history.push(`${PathsLayouts.configurations}/grading-structure/${row.id}/`);
  };

  const columnsActions = useColumnActions({
    onClickEdit,
  });
  const columns = useColumns(columnsActions);

  return (
    <div className="mb-5">
      <header className="mb-3">
        <div className="mt-3">
          <p className="fs-16 text-light">{texts.subtitle}</p>
        </div>
      </header>

      <Table
        columns={columns}
        data={data?.data ?? []}
        isLoadingResults={loading}
        loadingView={{
          title: 'Estructura de calificaciones',
          subtitle: 'Espere un momento',
        }}
      />

      <div className="d-flex flex-column align-items-start justify-content-start mt-5">
        {options?.structureType?.length &&
          options?.structureType?.map((option) => (
            <Leyenda key={option.id} structureType={option} showDescription />
          ))}
      </div>
    </div>
  );
}

export default GradingStructureTable;
