import { useTranslation } from 'react-i18next';
import { Col, Row } from 'reactstrap';

export interface PackagesViewBoxProps {
  studyPlanName: string;
  campusName: string;
  scheduleName: string;
}

export default function PackagesViewBox({
  studyPlanName,
  campusName,
  scheduleName,
}: PackagesViewBoxProps) {
  const { t } = useTranslation();

  return (
    <div className="outlined-box">
      <Row className="px-3 text-primary">
        <Col xs={12} sm={5} className="py-2">
          <span className="fs-14 fw-600">
            {t('packages.view.box.studyPlan')}
          </span>
          <br />
          <span className="fs-14">{studyPlanName}</span>
        </Col>
        <Col xs={6} sm={4} className="py-2">
          <span className="fs-14 fw-600">{t('packages.view.box.campus')}</span>
          <br />
          <span className="fs-14">{campusName}</span>
        </Col>
        <Col xs={6} sm={3} className="py-2">
          <span className="fs-14 fw-600">
            {t('packages.view.box.schedule')}
          </span>
          <br />
          <span className="fs-14">{scheduleName}</span>
        </Col>
      </Row>
    </div>
  );
}
