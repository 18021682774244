import { MODIFY_ENROLLMENT } from '../../../../api/endpoints';
import request from '../../../../api/request';
import { ModifyEnrollmentFormDataResponse } from './types';

/**
 * datos para el formulario de modificar jornada y sede
 */
export function modifyEnrollmentFormData(
  studyPlanEnrollmentId: string | number,
) {
  return request<ModifyEnrollmentFormDataResponse>(
    MODIFY_ENROLLMENT.MODIFY_FORM_DATA(studyPlanEnrollmentId),
  );
}

export function modifyEnrollment(
  studyPlanEnrollmentId: string | number,
  data: { studyPlanVersionOfferId: number },
) {
  return request<ModifyEnrollmentFormDataResponse>(
    MODIFY_ENROLLMENT.MODIFY(studyPlanEnrollmentId),
    { method: 'POST', data },
  );
}
