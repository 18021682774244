import { SelectOptionType } from '@octano/global-ui';
import { ReactElement, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { AxiosResultDefaultError } from '../../../api/request';
import { requestStudentStatusOptions } from '../../../api/requests/studentStatus';
import { AuthenticationError } from '../../../api/requests/tuitionProcess';
import { createFetchContext } from '../../../components/contexts/FetchContextFactory';
import DisplayError from '../../../components/info/DisplayError';
import Loading from '../../../components/info/Loading';
import { StudenStatusOptionsResponse } from '../../../types/studentStatusTypes';

export interface StudentStatusFilterLoaderProps {
  onError?: (code: string, onUnknown?: (() => void) | undefined) => void;
  children: (props: {
    refresh: () => Promise<void>;
    statusOptions: SelectOptionType[];
    campusOptions: SelectOptionType[];
  }) => ReactElement;
}

const { FetchProvider, FetchConsumer } = createFetchContext<
  undefined,
  StudenStatusOptionsResponse,
  AuthenticationError | AxiosResultDefaultError
>();

export const SustainerLoaderConsumer = FetchConsumer;

export default function StudentStatusFilterLoader({
  onError,
  children,
}: StudentStatusFilterLoaderProps) {
  const { t } = useTranslation();

  const request = useCallback(async () => {
    return requestStudentStatusOptions();
  }, []);

  return (
    <FetchProvider request={request} defaultQuery={undefined} fetchImmediately>
      <FetchConsumer>
        {({ data, loading, error, refresh }) => {
          if (error) {
            onError && onError(error.code);
            return (
              <DisplayError
                insideCard
                textBody={error.code}
                retryAction={refresh}
                loadingAction={loading}
              />
            );
          }

          if (loading) {
            return <Loading insideCard />;
          }

          if (!data) {
            return (
              <DisplayError
                insideCard
                textBody="Data no cargada"
                retryAction={refresh}
                loadingAction={loading}
              />
            );
          }

          return children({
            refresh,
            statusOptions: data.status.map((s) => ({
              label: t(`studentStatus.status.${s}`),
              value: s,
            })),
            campusOptions: data.campuses.map((c) => ({
              label: c.name,
              value: c.id,
            })),
          });
        }}
      </FetchConsumer>
    </FetchProvider>
  );
}
