import { ColumnTable, Table } from '@octano/global-ui';
import { useTranslation } from 'react-i18next';

const dictPrefix =
  'graduationProcessSinglePendingRequirerments.creditsSummaryTable';
interface Props {
  data: any[];
}

export function CreditsSummaryTable({ data }: Props) {
  const { t } = useTranslation();

  const tableText: { [key: string]: string } = {
    obligatory: t(`${dictPrefix}.mandatory`),
    optional: t(`${dictPrefix}.optative`),
    others: t(`${dictPrefix}.otherCredits`),
    total: t(`${dictPrefix}.total`),
  };

  const columns: ColumnTable<any>[] = [
    {
      headerText: '',
      columnName: 'text',
      thClassName: 'bg-light border-top-0 border-bottom-0 fs-18',
      tdClassName: ({ index }) =>
        index === 0 ? 'bg-light border-top-0 fs-18' : 'bg-light fs-18',
      cellFormat: ({ row, index, data }) => (
        <div className={index === data?.length! - 1 ? 'fw-700 text-dark' : ''}>
          {tableText[row.text]}
        </div>
      ),
    },
    {
      headerText: t(`${dictPrefix}.totalCredits`),
      columnName: 'required',
      thClassName: 'bg-light border-top-0 border-bottom-0 text-center fs-18',
      tdClassName: ({ index }) =>
        index === 0
          ? 'bg-light border-top-0 text-center fs-18'
          : 'bg-light text-center fs-18',
      cellFormat: ({ row, index, data }) => (
        <div className={index === data?.length! - 1 ? 'fw-700 text-dark' : ''}>
          {row.required}
        </div>
      ),
    },
    {
      headerText: t(`${dictPrefix}.passedCredits`),
      columnName: 'obtained',
      thClassName: 'bg-light border-top-0 border-bottom-0 text-center fs-18',
      tdClassName: ({ index }) =>
        index === 0
          ? 'bg-light border-top-0 text-center fs-18'
          : 'bg-light text-center fs-18',
      cellFormat: ({ row, index, data }) => (
        <div className={index === data?.length! - 1 ? 'fw-700 text-dark' : ''}>
          {row.obtained}
        </div>
      ),
    },
    {
      headerText: t(`${dictPrefix}.pending`),
      columnName: 'remaining',
      thClassName: 'bg-light border-top-0 border-bottom-0 text-center fs-18',
      tdClassName: ({ index }) =>
        index === 0
          ? 'bg-light border-top-0 text-center fs-18'
          : 'bg-light text-center fs-18',
      cellFormat: ({ row, index, data }) => (
        <div className={index === data?.length! - 1 ? 'fw-700 text-dark' : ''}>
          {row.remaining}
        </div>
      ),
    },
  ];

  return (
    <div className="bg-light py-2 px-4">
      <Table
        data={data}
        columns={columns}
        borderless={false}
        color="gray"
        striped={false}
      />
    </div>
  );
}
