import { addToast, Button, Table } from '@octano/global-ui';
import { useRef } from 'react';

import { useTranslation } from 'react-i18next';
import HeaderInfo from '../../parts/Header/HeaderInfo';
import useActions from '../hooks/useActions';
import useColumnActions from '../hooks/useColumnActions';
import useColumns from '../hooks/useColumns';
import { TeacherAdditionalDocument } from '../type';
import { useAdditionalDocumentsLoader } from './Loaders/AdditionalDocumentsLoader';
import ConfirmModal, { ConfirmModalRef } from './Modals/ConfirmModal';
import CreateOrUpdateModal, {
  CreateOrUpdateRef,
} from './Modals/CreateOrUpdateModal';

const dictPrefix = 'teacherFile.additionalDocuments';

function AdditionalDocumentsTable() {
  const { t } = useTranslation();
  const { data, loading, refresh } = useAdditionalDocumentsLoader();

  const actions = useActions({
    onSuccess: (a) => {
      if (a === 'create' || a === 'update') {
        createOrUpdateModalRef?.current?.closeModal();
      }
      refresh();
    },
    onError: (_, messsage) => {
      addToast({
        icon: 'error',
        color: 'danger',
        text: messsage ?? 'Ha ocurrido un error',
      });
    },
  });

  //Mandamos data por referencia al modal
  const confirmModalRef = useRef<ConfirmModalRef>(null);
  const createOrUpdateModalRef = useRef<CreateOrUpdateRef>(null);

  const onClickEdit = (row: TeacherAdditionalDocument) => {
    createOrUpdateModalRef.current?.openUpdate(row.id, row);
  };
  const onClickRemove = (row: TeacherAdditionalDocument) => {
    confirmModalRef.current?.openModal(row);
  };
  const onClickDownload = (row: TeacherAdditionalDocument) => {
    actions.downloadDocument(row.id);
  };

  const columnsActions = useColumnActions({
    onClickEdit,
    onClickRemove,
    onClickDownload,
  });

  const columns = useColumns(columnsActions);

  return (
    <>
      <HeaderInfo />

      <div className="w-100 d-flex  mb-4">
        <div className="flex-fill d-block">
          <span className="w-100 d-block fs-16">
            {t(`${dictPrefix}.description`)}
          </span>
        </div>
        {!!data?.teacher?.id && (
          <div>
            <Button
              text={t(`${dictPrefix}.create`)}
              color="primary"
              icon="plus"
              size="md"
              outlined
              onClick={() => {
                createOrUpdateModalRef.current?.openCreate(data?.teacher?.id);
              }}
            />
          </div>
        )}
      </div>

      <Table
        columns={columns}
        data={data?.documents ?? []}
        isLoadingResults={loading}
      />

      <CreateOrUpdateModal
        ref={createOrUpdateModalRef as React.Ref<CreateOrUpdateRef>}
        actions={actions}
      />
      <ConfirmModal
        onDecline={() => confirmModalRef.current?.closeModal()}
        onConfirm={(row) => {
          actions.deleteDocument(row.id);
        }}
        ref={confirmModalRef}
      />
    </>
  );
}

export default AdditionalDocumentsTable;
