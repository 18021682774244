import { DetailBox, DetailBoxColumn } from '@octano/global-ui';
import { useMemo } from 'react';
import { format } from 'rut.js';

interface StudyPlanBoxProps {
  studentName: string;
  studentRut: string;
  studyPlanName: string;
  texts: {
    student: string;
    rut: string;
    studyPlan: string;
  };
}

export default function StudyPlanBox(props: StudyPlanBoxProps) {
  const texts = props.texts;
  const columns = useMemo<DetailBoxColumn[]>(() => {
    return [
      {
        title: texts.student,
        body: props.studentName,
      },
      {
        title: texts.rut,
        body: format(props.studentRut),
      },
      {
        title: texts.studyPlan,
        body: props.studyPlanName,
      },
    ];
  }, [props, texts]);

  return (
    <div className="d-flex justify-content-end">
      <DetailBox columns={columns} />
    </div>
  );
}
