import { useMemo } from 'react';
import { Card, Container } from 'reactstrap';

import TabControl from '../../../components/tabs/TabControl';
import { PathsLayouts } from '../../../config/routes';
import { PermissionName } from '../../../types/Auth';
import CampusOrScheduleList from './EnrollmentSoftChangeList';

export default function ChangeStudyPlanList() {
  const tabsPrefix = 'changeStudyPlan';

  const tabs = useMemo(
    () => [
      {
        name: 'list',
        content: <CampusOrScheduleList />,
        permissions: [PermissionName.STUDENT_MANAGEMENT_ENROLLMENT_SOFT_CHANGE],
      },
      // TODO: Comentada hasta implementar funcionalidad de cambio de plan de estudio continuidad
      // {
      //   name: 'list-continuity',
      //   content: <CampusOrScheduleListContinuity />,
      //   permissions: [PermissionName.STUDENT_MANAGEMENT_ENROLLMENT_SOFT_CHANGE],
      // },
    ],
    [],
  );

  return (
    <Container fluid>
      <Card className="">
        <TabControl
          tabsPrefix={`${tabsPrefix}.tabs`}
          tabs={tabs}
          path={PathsLayouts.enrollmentSoftChange}
        />
      </Card>
    </Container>
  );
}
