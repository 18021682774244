import { PaginationRequestType } from '../../types/paginationRequestType';
import { PeriodType } from '../../types/sectionsAndPackageTypes';
import { PERIODS } from '../endpoints';
import request from '../request';

/**
 * Obtiene los periodos de secciones y paquetes
 */
export const getPeriods = (itemsPerPage: number = 10, page: number = 0) => {
  const url = `${PERIODS.GET_ALL}?items_per_page=${itemsPerPage}&page=${page}`;
  return request<PaginationRequestType<PeriodType>>(url, {
    method: 'GET',
  });
};

/**
 * Obtiene un periodo de secciones y paquetes por id
 */
export const getPeriodById = (periodId: number) => {
  const url = PERIODS.GET_BY_ID;
  return request<PeriodType>(url, {
    method: 'GET',
    params: {
      periodId,
    },
  });
};

/**
 * Descarga el archivo de información base
 */
export const downloadBaseInfoDocument = (periodId: string | number) => {
  return request<any>(PERIODS.BASE_INFO_DOCUMENT(periodId), {
    responseType: 'blob',
  });
};

/**
 * Descarga el archivo de secciones cargadas
 */
export const downloadLoadedInfoDocument = (periodId: number) => {
  const url = `${PERIODS.LOADED_INFO_DOCUMENT}?periodId=${periodId}`;

  return request<any>(url, { responseType: 'blob' });
};

/**
 * Carga el archivo de secciones
 */
export const uploadSectionsDocument = (periodId: string, data: any) => {
  const url = `${PERIODS.UPLOAD_SECTIONS_DOCUMENT}/${periodId}`;

  return request<any>(url, { method: 'POST', data });
};

/**
 * Descarga el archivo de errores de carga de secciones y paquetes
 */
export const downloadErrorDocument = (periodId: number) => {
  const url = `${PERIODS.DOWNLOAD_ERROR_DOCUMENT}/${periodId}`;

  return request<any>(url);
};
