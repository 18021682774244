import { useCallback, useState } from 'react';

import { deleteTcDocumentReviewDocumentOther } from '../../../../api/requests/tuitionContinuityDocumentReviewDocumentsOthers';
import { StudentDocumentReviewInfDocumentOther } from '../../../../types/tuitionContinuityDocumentReviewDocuments';

type ModalData =
  | {
      open: true;
      document: StudentDocumentReviewInfDocumentOther;
      onConfirm: () => void;
      onClose: () => void;
    }
  | {
      open: false;
      document?: undefined;
    };

export function useOnDelete({
  onConfirm: onConfirmProp,
  onError,
}: {
  onConfirm: (row: StudentDocumentReviewInfDocumentOther) => void;
  onError: (row: StudentDocumentReviewInfDocumentOther) => void;
}) {
  const [modalConfirm, setModalConfirm] = useState<ModalData>({ open: false });

  const onDelete = useCallback(
    async (row: StudentDocumentReviewInfDocumentOther) => {
      console.log({ row });
      if (!row.id) {
        return;
      }

      const onConfirm = async () => {
        onConfirmProp(row);
        let { error } = await deleteTcDocumentReviewDocumentOther(row.id);
        if (error) {
          onError(row);
        }
      };

      const onClose = () => {
        setModalConfirm({ open: false });
      };

      setModalConfirm({ open: true, document: row, onConfirm, onClose });
    },
    [onConfirmProp, onError],
  );

  return { data: modalConfirm, onDelete };
}
