import request from '../../../../api/request';
import { PaginationRequestType } from '../../../../types/paginationRequestType';

/**
 * DISCOUNTS
 */
const DISCOUNTS = {
  DELETE: (discountId: number) =>
    `/maintainers-portal/admission-and-enrollments/discounts/${discountId}`,
  DISCOUNTS: '/maintainers-portal/admission-and-enrollments/discounts',
  SEARCH_DISCOUNTS:
    '/maintainers-portal/admission-and-enrollments/discounts/search',
};

type Modify<T, R> = Omit<T, keyof R> & R;

export interface Discount {
  id?: number;
  code: string;
  name: string;
  description: string | null;
  percentage: number;
}

export interface DiscountFields
  extends Modify<Discount, { percentage: string }> {}

export type DiscountsListResponse = PaginationRequestType<Discount>;

/**
 *Obtiene el listado de todos los descuentos
 */
export const getAllDiscounts = () => {
  const url = `${DISCOUNTS.DISCOUNTS}`;
  return request<Discount[]>(url);
};

/**
 * Obtiene el listado de descuentos con paginación
 */
export const getDiscountsRequest = (items: number = 10, page: number = 0) => {
  return request<DiscountsListResponse>(DISCOUNTS.SEARCH_DISCOUNTS, {
    params: { items_per_page: items, page },
  });
};

export const saveDiscountRequest = (data: Discount) => {
  const editing = !!data.id;
  return request<Discount>(`${DISCOUNTS.DISCOUNTS}/${editing ? data.id : ''}`, {
    method: editing ? 'put' : 'post',
    data,
  });
};

export const deleteDiscountRequest = (discountId: number) => {
  return request<Discount>(DISCOUNTS.DELETE(discountId), {
    method: 'delete',
  });
};
