import {
  ActivityType,
  CurriculumDegreeActivity,
  TableCurriculumDegreeActivity,
} from '../types';

export const transformData = (
  data: CurriculumDegreeActivity[],
): TableCurriculumDegreeActivity[] =>
  data.map((element) => ({
    id: element.id,
    typeId: element.type.id,
    name: element.type.name,
    code: element.type.code,
    description: element.type.description,
    allowGrades: element.type.allowGrades,
  }));

export const transformDataActivity = (
  element: ActivityType,
): TableCurriculumDegreeActivity => ({
  id: undefined,
  typeId: element.id,
  name: element.name,
  code: element.code,
  description: element.description,
  allowGrades: element.allowGrades,
});
