import { createContext, useReducer } from 'react';

import parametersReducer, { initialState } from '../reducers/parametersReducer';
import { ParametersContextType } from '../types/parametersTypes';
import ParametersLoaderProvider from './ParametersLoaderProvider';

export const ParametersContext = createContext({} as ParametersContextType);

const ParametersProvider = (props: any) => {
  const [state, dispatch] = useReducer(parametersReducer, initialState);

  return (
    <ParametersContext.Provider value={{ state, dispatch }}>
      <ParametersLoaderProvider>{props.children}</ParametersLoaderProvider>
    </ParametersContext.Provider>
  );
};

export default ParametersProvider;
