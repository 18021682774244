import { Button, Icon, Modal, addToast } from '@octano/global-ui';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'reactstrap';

import CardTitle from '../../components/text/CardTitle';
import { useLayoutState } from '../../hooks/useLayoutState';
import { changeStatusToDesist } from '../../views/AdmissionAndEnrollments/AdmissionManagement/api';

interface DesistAdmissionFormProps {
  id: number;
  name: string;
  show: boolean;
  toggleModal: () => void;
  reloadList: () => void;
}

export const DesistAdmissionModal = ({
  id,
  name,
  show,
  toggleModal,
  reloadList,
}: DesistAdmissionFormProps) => {
  const prefix = 'admission';
  const { t } = useTranslation();

  const { showErrorModal } = useLayoutState();

  const onsubmit = async () => {
    const { data, error } = await changeStatusToDesist(id);

    if (error && error.code === 'CONNECTION') {
      showErrorModal();
      return;
    } else if (error) {
      addToast({
        icon: 'error',
        color: 'danger',
        text: t('common.errors.save'),
      });
      toggleModal();
    }
    if (data) {
      reloadList();
      toggleModal();
      addToast({
        icon: 'success',
        color: 'success',
        text: t(`${prefix}.desistAdmissionMessage`),
      });
    }
  };

  return (
    <Modal isOpen={show} toggle={toggleModal} size="md" unmountOnClose>
      <Row>
        <Col>
          <div className="text-center">
            <Icon color="secondary" name="warning" size="70px" />
          </div>
        </Col>
      </Row>
      <Row className="mt-4">
        <Col>
          <div className="text-center">
            <CardTitle children={t(`${prefix}.desistAdmissionTitle`)} />
          </div>
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <div className="text-center">
            <p className="fs-16 text-light">
              {t(`${prefix}.desistAdmissionBody`)}
            </p>
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <span>{t('tuitionProcess.studentBackground.student')}</span>
          <div className="student-content">
            <ul className="mb-5 mt-2">
              <li className="text-dark">{name}</li>
            </ul>
          </div>
        </Col>
      </Row>
      <Row className="mt-5">
        <Col xs={{ size: 12, order: 2 }} md={{ size: 6, order: 1 }}>
          <Button
            type="button"
            text={t('common.actions.cancel')}
            outlined
            onClick={() => toggleModal()}
            fullwidth
          />
        </Col>
        <Col xs={{ size: 12, order: 1 }} md={{ size: 6, order: 2 }}>
          <Button
            type="button"
            onClick={onsubmit}
            text={t('common.actions.confirm')}
            fullwidth
            className="mb-3 mb-md-0"
          />
        </Col>
      </Row>
    </Modal>
  );
};
