import { useTranslation } from 'react-i18next';

export type StudentStatus = 'A' | 'R' | 'P' | 'calc';

const getStatusColor = (status: StudentStatus) => {
  const colors = {
    A: 'approved',
    R: 'failed',
    P: 'pending',
    calc: 'calculating',
  };

  return colors[status];
};

interface Props {
  status: StudentStatus;
  shortVersion?: boolean;
}

export function StudentStatusBadge({ status, shortVersion = false }: Props) {
  const { t } = useTranslation();

  const getStatusText = (status: StudentStatus) => {
    const fullText = t(`studentGradesClosure.search.studentStatuses.${status}`);
    return shortVersion ? fullText.slice(0, 1) : fullText;
  };

  return (
    <span className={`student-status-badge ${getStatusColor(status)}`}>
      {getStatusText(status)}
    </span>
  );
}
