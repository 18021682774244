import { ReactElement, useCallback } from 'react';

import { createFetchContext } from '../../../components/contexts/FetchContextFactory';
import DisplayError from '../../../components/info/DisplayError';
import Loading from '../../../components/info/Loading';
import { getCourseValidationInternalStudyPlanEnrollmentCurriculum } from '../requests';
import { CourseValidationInternalStudyPlanEnrollmentCurriculum } from '../types';

export interface CurriculumLoaderProps<T> {
  studyPlanEnrollmentId: number | string;
  children: (props: { data: T; refresh: () => Promise<void> }) => ReactElement;
}

const { FetchProvider, FetchConsumer } = createFetchContext<
  undefined,
  CourseValidationInternalStudyPlanEnrollmentCurriculum
>();

export const CurriculumLoaderConsumer = FetchConsumer;

export default function CurriculumLoader({
  studyPlanEnrollmentId,
  children,
}: CurriculumLoaderProps<CourseValidationInternalStudyPlanEnrollmentCurriculum>) {
  const request = useCallback(() => {
    return getCourseValidationInternalStudyPlanEnrollmentCurriculum(
      studyPlanEnrollmentId,
    );
  }, [studyPlanEnrollmentId]);

  return (
    <FetchProvider request={request} defaultQuery={undefined} fetchImmediately>
      <FetchConsumer>
        {({ data, loading, error, refresh }) => {
          if (error) {
            return (
              <DisplayError
                insideCard
                textBody={error.code}
                retryAction={refresh}
                loadingAction={loading}
              />
            );
          }

          if (loading) {
            return <Loading insideCard />;
          }

          if (!data) {
            return (
              <DisplayError
                insideCard
                textBody="Data no cargada"
                retryAction={refresh}
                loadingAction={loading}
              />
            );
          }

          return children({ data, refresh });
        }}
      </FetchConsumer>
    </FetchProvider>
  );
}
