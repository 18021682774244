import { SelectOptionType } from '@octano/global-ui';

/**
 * Función para formatear las opciones del select de países
 * @param data Array con países {id, name}
 * @returns Array de opciones con los valores (value, label)
 */
export const formatBaseOption = (data: {
  id: string | number;
  name: string;
}): SelectOptionType => ({
  value: data.id,
  label: data.name,
});

/**
 * Función para formatear las opciones del select de países
 * @param data Array con países {id, name}
 * @returns Array de opciones con los valores (value, label)
 */
export function formatOptions(
  data: { id: string; name: string }[],
): SelectOptionType[] {
  return data.map(formatBaseOption);
}

export function getSelectedOption(
  id: string | number | undefined,
  options: SelectOptionType[],
) {
  return options.find((row) => row.value === id);
}
