import { Icon } from '@octano/global-ui';
import { Button, Tooltip } from 'reactstrap';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

const dictPrefix = 'teacherFile.hiringInformation.recordsTable';

type Props = {
  target: string;
  hours: {
    total: number;
    teaching: number;
    research: number;
    linking: number;
    others: number;
  };
};

const HoursSummary = ({ target, hours }: Props) => {
  const { t } = useTranslation();

  const [tooltipOpen, setTooltipOpen] = useState<boolean>(false);
  const toggle = () => setTooltipOpen(!tooltipOpen);

  return (
    <span>
      <Button
        className="me-1 text-dark"
        type="button"
        color="transparent"
        id={'Tooltip-' + target}
      >
        {t(`${dictPrefix}.hoursCount`, {
          count: hours?.total,
        })}
        <Icon name="information" className="ml-2" />
      </Button>
      <Tooltip
        placement="bottom"
        isOpen={tooltipOpen}
        target={'Tooltip-' + target}
        toggle={toggle}
      >
        {Object.entries(hours)
          .map(([key, count]) =>
            t(`${dictPrefix}.typesSummary.${key}`, {
              count,
            }),
          )
          .join(', ')}
      </Tooltip>
    </span>
  );
};

export default HoursSummary;
