import React, { CSSProperties } from 'react';
import { useTranslation } from 'react-i18next';
import { Badge } from 'reactstrap';

export default function TariffCustomBadge({
  value,
  style,
  className,
}: {
  value?: any;
  style?: CSSProperties;
  className?: React.HTMLAttributes<HTMLElement>['className'];
}) {
  const { t } = useTranslation();
  const isNumber = typeof value === 'number';
  let baseClass = isNumber ? '' : 'Borrador';
  if (className) {
    baseClass += ' ' + className;
  }
  return (
    <Badge
      className={baseClass}
      color={isNumber ? 'primary' : ''}
      style={style}
    >
      {isNumber
        ? t('tariffAndTuition.tariff.table.updated')
        : t('tariffAndTuition.tariff.table.pending')}
    </Badge>
  );
}
