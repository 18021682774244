import {
  Button,
  CellFormatOptions,
  ColumnTable,
  Icon,
  OutlinedSelectOptionType,
} from '@octano/global-ui';
import clsx from 'clsx';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import {
  StudentDocumentReviewInfDocument,
  TuitionContinuityDocumentStatusEnum,
  TuitionContinuityDocumentTypeEnum,
} from '../../../types/tuitionContinuityDocumentReviewDocuments';
import { TuitionContinuityDocumentReviewDocumentsTableStatusCell } from './TuitionContinuityDocumentReviewDocumentsTableStatusCell';

export function useColumnsListDocumentByStatus({
  onChangeState,
  onDownload,
  onDelete,
  onUpload: onUploadProp,
}: {
  onChangeState: (
    row: StudentDocumentReviewInfDocument,
    status: TuitionContinuityDocumentStatusEnum,
  ) => void;
  onDownload: (documentId: number | string) => Promise<void>;
  onDelete: (row: StudentDocumentReviewInfDocument) => Promise<void>;
  onUpload: (row: StudentDocumentReviewInfDocument) => Promise<void>;
}) {
  const { t } = useTranslation();
  const prefix = 'tuitionContinuityDocumentReviewStudent';

  const onUpload = useCallback(
    (row: StudentDocumentReviewInfDocument) => {
      return (_e: any) => onUploadProp(row);
    },
    [onUploadProp],
  );

  const texts = useMemo(
    () => ({
      headers: {
        type: t(`${prefix}.headers.type`),
        name: t(`${prefix}.headers.name`),
        status: t(`${prefix}.headers.status`),
        upload: t(`${prefix}.headers.upload`),
      },
      document: {
        type: t<string, Record<string, string>>(`${prefix}.document.type`, {
          returnObjects: true,
        }),
        status: t<string, Record<string, string>>(`${prefix}.document.status`, {
          returnObjects: true,
        }),
        extra: t<string, Record<string, string | Record<string, string>>>(
          `${prefix}.document.extra`,
          {
            returnObjects: true,
          },
        ),
        upload: 'Cargar',
      },
      statePlaceholder: t('common.forms.status'),
    }),
    [t],
  );

  const options: OutlinedSelectOptionType[] = useMemo(
    () => [
      {
        value: TuitionContinuityDocumentStatusEnum.NOT_REVIEW,
        label:
          texts.document.status[TuitionContinuityDocumentStatusEnum.NOT_REVIEW],
        icon: {
          name: 'error',
          color: 'danger',
        },
      },
      {
        value: TuitionContinuityDocumentStatusEnum.WAITING,
        label:
          texts.document.status[TuitionContinuityDocumentStatusEnum.WAITING],
        icon: {
          name: 'warning',
          color: 'warning',
        },
      },
      {
        value: TuitionContinuityDocumentStatusEnum.APPROVED,
        label:
          texts.document.status[TuitionContinuityDocumentStatusEnum.APPROVED],
        icon: {
          name: 'success',
          color: 'success',
        },
      },
    ],
    [texts],
  );

  return useMemo<ColumnTable[]>(() => {
    return [
      {
        columnName: 'type',
        headerText: texts.headers.type,
        tdClassName: 'text-center',
        thClassName: 'text-center',
        width: '10%',
        cellFormat: ({
          row,
        }: CellFormatOptions<StudentDocumentReviewInfDocument>) => {
          const typeName = texts.document.type[row.type];
          if (!row.id) {
            return typeName;
          }
          if (
            row.type === TuitionContinuityDocumentTypeEnum.StudentIdentityCard
          ) {
            return getIdentityCardTypeName(typeName, row, texts.document.extra);
          }
          return typeName;
        },
      },
      {
        columnName: 'name',
        headerText: texts.headers.name,
        tdClassName: 'text-center',
        thClassName: 'text-center',
        width: '10%',
        cellFormat: ({
          row,
        }: CellFormatOptions<StudentDocumentReviewInfDocument>) => {
          if (!row.id) {
            return '-';
          }
          return `${row.name}.${row.extension}`;
        },
      },
      {
        columnName: 'action',
        headerText: '',
        width: '10%',
        tdClassName: 'text-center',
        thClassName: 'text-center',
        cellFormat: (
          options: CellFormatOptions<StudentDocumentReviewInfDocument>,
        ) => {
          return (
            <div className="d-flex" style={{ gap: '24px' }}>
              <div
                className={clsx({ 'icon-disabled': !options.row.id })}
                onClick={() => options.row.id && onDownload(options.row.id)}
              >
                <Icon
                  name="download"
                  color={options.row.id ? 'primary' : 'disabled'}
                  className="icon-selector"
                />
              </div>
              <div
                className={clsx({
                  'icon-disabled': !options.row.id || options.row.required,
                })}
                onClick={() => options.row.id && onDelete(options.row)}
              >
                <Icon
                  name="trash"
                  color={
                    !options.row.id || options.row.required
                      ? 'disabled'
                      : 'primary'
                  }
                  className="icon-selector"
                />
              </div>
            </div>
          );
        },
      },
      {
        columnName: 'upload',
        headerText: texts.headers.upload,
        width: '10%',
        tdClassName: 'text-center',
        thClassName: 'text-center',
        cellFormat: ({
          row,
        }: CellFormatOptions<StudentDocumentReviewInfDocument>) => {
          return (
            <Button
              type="button"
              text={texts.document.upload}
              icon="export"
              size="md"
              outlined
              onClick={onUpload(row)}
            />
          );
        },
      },
      {
        columnName: 'status',
        headerText: texts.headers.status,
        width: '10%',
        tdClassName: 'text-center',
        thClassName: 'text-center',
        cellFormat: ({
          row,
        }: CellFormatOptions<StudentDocumentReviewInfDocument>) => (
          <TuitionContinuityDocumentReviewDocumentsTableStatusCell
            options={options}
            onChange={onChangeState}
            row={row}
            placeholder={texts.statePlaceholder}
          />
        ),
      },
    ];
  }, [texts, options, onDownload, onChangeState, onDelete, onUpload]);
}

function getIdentityCardTypeName(
  typeName: string,
  row: { type: string; metadata: unknown },
  extraTexts: Record<string, any>,
) {
  if (typeof extraTexts['identityCard'] !== 'object') {
    return typeName;
  }
  if (typeof row.metadata !== 'object') {
    return typeName;
  }
  if (
    row.metadata &&
    'side' in row.metadata &&
    typeof row.metadata['side' as keyof object] === 'string'
  ) {
    const sideName =
      extraTexts['identityCard'][row.metadata['side' as keyof object]];
    return `${typeName} - ${sideName}`;
  }
  return typeName;
}
