import { Box, useMobile, useWindowSize } from '@octano/global-ui';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

interface Props {
  studentFullName: string;
  rut: string;
  courseName: string;
  shortening: string;
  section: string;
  teacherFullName: string;
}

export function StudentAndSectionInfoBox({
  studentFullName,
  rut,
  courseName,
  shortening,
  section,
  teacherFullName,
}: Props) {
  const isMobile = useMobile();
  const windowSize = useWindowSize();
  const { t } = useTranslation();
  const prefix = 'studentGradesClosure.detail';

  const wrapItems = windowSize && windowSize.width && windowSize.width <= 930;

  return (
    <Box
      color="primary"
      variant="outlined"
      style={{ borderRadius: 4 }}
      className={`py-2 px-3 ${isMobile && 'mt-4 w-100'}`}
      body={
        <div
          className={clsx(
            'd-flex justify-content-between',
            wrapItems && 'flex-wrap',
          )}
        >
          <Box
            color="primary"
            variant="outlined"
            className="p-0 border-0 w-50 text-uppercase py-2"
            title={t(`${prefix}.studentFullName`)}
            style={{ minWidth: isMobile ? 0 : 135 }}
            body={
              <p className="fs-14 mb-0 text-capitalize">{studentFullName}</p>
            }
          />
          <Box
            color="primary"
            variant="outlined"
            className="p-0 border-0 w-50 text-uppercase py-2"
            title={t(`${prefix}.rut`)}
            style={{ minWidth: isMobile ? 0 : 95 }}
            body={<p className="fs-14 mb-0 text-capitalize">{rut}</p>}
          />
          <Box
            color="primary"
            variant="outlined"
            className="p-0 border-0 w-50 text-uppercase py-2"
            title={t(`${prefix}.courseName`)}
            style={{ minWidth: isMobile ? 0 : 225 }}
            body={<p className="fs-14 mb-0 text-capitalize">{courseName}</p>}
          />
          <Box
            color="primary"
            variant="outlined"
            className="p-0 border-0 w-50 text-uppercase py-2"
            title={t(`${prefix}.shortening`)}
            style={{ minWidth: isMobile ? 0 : 80 }}
            body={<p className="fs-14 mb-0 text-capitalize">{shortening}</p>}
          />
          <Box
            color="primary"
            variant="outlined"
            className="p-0 border-0 w-50 text-uppercase py-2"
            title={t(`${prefix}.section`)}
            style={{ minWidth: isMobile ? 0 : 80 }}
            body={<p className="fs-14 mb-0 text-capitalize">{section}</p>}
          />
          <Box
            color="primary"
            variant="outlined"
            className="p-0 border-0 w-50 text-uppercase py-2"
            title={t(`${prefix}.teacherFullName`)}
            style={{ minWidth: isMobile ? 0 : 150 }}
            body={
              <p className="fs-14 mb-0 text-capitalize">{teacherFullName}</p>
            }
          />
        </div>
      }
    />
  );
}
