import { createContext, useReducer } from 'react';

import stepReducer, { initialState } from './stepReducer';
import { StepContextType } from './stepsTypes';

export const StepContext = createContext({} as StepContextType);

const StepProvider = (props: any) => {
  const [state, dispatch] = useReducer(stepReducer, initialState);

  return (
    <StepContext.Provider value={{ state, dispatch }}>
      {props.children}
    </StepContext.Provider>
  );
};

export default StepProvider;
