import { DateInput, Select, TextInput } from '@octano/global-ui';
import { useCallback, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Col, FormGroup, Row } from 'reactstrap';
import { format as formatRut } from 'rut.js';
import { SectionTitle } from '../../../../../../../components/text';
import { useValidations } from '../../../../../../../hooks/useValidations';
import { usePersonalBackgroundFormDataLoader } from '../Loaders/PersonalBackgroundFormDataLoader';
import ImageCropAndUpload, {
  ImageCropAndUploadTexts,
} from '../../../../../../../components/ImageCropAndUpload';
import { uploadPhoto } from '../../api';
import { useParams } from 'react-router';

const prefix = `teacherActivation.teacherInformation.personalBackground.information`;

/**
 * Sección de Antecedentes del estudiante SIN SUA
 */
const PersonalBackgroundInformation = () => {
  const { t } = useTranslation();
  const { teacherId } = useParams<{ teacherId: string }>();
  const { control, watch } = useFormContext();
  const { validateTextNotEmpty } = useValidations();
  const { data } = usePersonalBackgroundFormDataLoader();
  const studentPhotoTexts = t<any, ImageCropAndUploadTexts>(
    `${prefix}.teacherPhoto`,
    { returnObjects: true },
  );

  const { countries, genders, maritalStatus, photo } = data!;
  const [rut] = watch(['rut']);

  const mapFn = ({ id, name }: { id: number; name: string }) => {
    return { label: name, value: id };
  };

  const countriesOptions = useMemo(() => countries.map(mapFn), [countries]);
  const gendersOptions = useMemo(() => genders.map(mapFn), [genders]);
  const maritalStatusOptions = useMemo(() => maritalStatus.map(mapFn), [
    maritalStatus,
  ]);

  const submitFuction = useCallback(
    async (photo?: File) => {
      return uploadPhoto(parseInt(teacherId), photo);
    },
    [teacherId],
  );

  return (
    <>
      <Row>
        <Col xs={12}>
          <SectionTitle text={t(`${prefix}.title`)} />
        </Col>
      </Row>
      <Row>
        <Col className="pb-5" xs={12} lg="auto">
          <FormGroup>
            <div className="m-auto mw-100" style={{ width: '121px' }}>
              <ImageCropAndUpload
                texts={studentPhotoTexts}
                onError={() => {}}
                defaultPhoto={photo}
                submitFuction={submitFuction}
              />
            </div>
          </FormGroup>
        </Col>
        <Col xs={12} lg={true}>
          <Row>
            <Col className="pb-3" xs={12} lg={6}>
              {true ? (
                <TextInput
                  name="rut"
                  label={t(`${prefix}.rut`)}
                  formatter="rut"
                  value={formatRut(rut)}
                  defaultValue={formatRut(rut)}
                  rules={{ validate: validateTextNotEmpty }}
                  disabled
                  shouldUnregister={true}
                />
              ) : (
                <Row>
                  <Col xs={5} className="pr-1 pr-md-2">
                    <Select
                      name="passportCountry"
                      label={t(`${prefix}.passportCountry`)}
                      options={countriesOptions}
                      isClearable={false}
                      control={control}
                      rules={{ validate: validateTextNotEmpty }}
                      shouldUnregister={true}
                      disabled
                    />
                  </Col>
                  <Col xs={7} className="pl-1 pl-md-2">
                    <TextInput
                      name="passportNumber"
                      label={t(`${prefix}.passportNumber`)}
                      control={control}
                      rules={{ validate: validateTextNotEmpty }}
                      shouldUnregister={true}
                      disabled
                    />
                  </Col>
                </Row>
              )}
            </Col>
            <Col className="pb-3" xs={12} lg={6}>
              <TextInput
                name="names"
                label={t(`${prefix}.names`)}
                rules={{ validate: validateTextNotEmpty }}
                control={control}
              />
            </Col>
            <Col className="pb-3" xs={12} lg={6}>
              <TextInput
                name="paternalLastName"
                label={t(`${prefix}.paternalSurname`)}
                control={control}
                rules={{ validate: validateTextNotEmpty }}
              />
            </Col>
            <Col className="pb-3" xs={12} lg={6}>
              <TextInput
                name="maternalLastName"
                label={t(`${prefix}.maternalSurname`)}
                control={control}
                rules={{ validate: validateTextNotEmpty }}
              />
            </Col>
          </Row>
        </Col>
      </Row>
      <Row>
        <Col className="pb-3" xs={12} lg={4}>
          <Select
            name="nationality"
            label={t(`${prefix}.nationality`)}
            control={control}
            options={countries.map((country: any) => ({
              label: country.name,
              value: country.id,
            }))}
            isSearchable={true}
            rules={{ validate: validateTextNotEmpty }}
          />
        </Col>

        <Col className="pb-3" xs={12} lg={4}>
          <DateInput
            name="birthday"
            label={t(`${prefix}.birthday`)}
            control={control}
            rules={{ validate: validateTextNotEmpty }}
          />
        </Col>

        <Col className="pb-3" xs={12} lg={4}>
          <Select
            name="gender"
            label={t(`${prefix}.gender`)}
            control={control}
            options={gendersOptions}
            isSearchable={true}
            rules={{ validate: validateTextNotEmpty }}
          />
        </Col>
      </Row>
      <Row>
        <Col className="pb-3" xs={12} lg={4}>
          <Select
            name="maritalStatus"
            label={t(`${prefix}.maritalStatus`)}
            control={control}
            options={maritalStatusOptions}
            isSearchable={true}
            rules={{ validate: validateTextNotEmpty }}
          />
        </Col>

        <Col className="pb-3" xs={12} lg={4}>
          <TextInput
            name="contactEmail"
            label={t(`${prefix}.contactEmail`)}
            control={control}
            rules={{ validate: validateTextNotEmpty }}
          />
        </Col>

        <Col className="pb-3" xs={12} lg={4}>
          <TextInput
            name="institutionalEmail"
            label={t(`${prefix}.institutionalEmail`)}
            control={control}
            rules={{ validate: validateTextNotEmpty }}
          />
        </Col>
      </Row>
    </>
  );
};

export default PersonalBackgroundInformation;
