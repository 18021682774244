import {
  AcademicOffersType,
  AcademicOffersTypeDuplicate,
} from '../../types/AcademicOffersType';
import { PaginationRequestType } from '../../types/paginationRequestType';
import { ACADEMIC_OFFERS_MAINTAINER } from '../endpoints';
import request from '../request';
import { SchoolsListItem } from './schools';

export interface AcademicOffersBody {
  periodId: number | null;
  duplicatedAcademicOfferId?: number | null;
  quota: number | null;
  status?: string;
}

export type ResolutionItem = {
  id: number;
  resolutionNumber: string;
  resolutionFile: any;
};

export interface AcademicOfferAndSchools {
  data: AcademicOffersType[] | SchoolsListItem[][];
}

export interface UpdateQuotaType {
  studyPlanId: number;
  studyPlanVersionId?: number;
  studyPlanVersionOfferId?: number;
  campusId?: number;
  scheduleId?: number;
  academicOfferStudyPlanVersionOfferId?: number;
  quota: number | null;
}

export interface GetOfferReport {}

/**
 * Obtiene el listado de version del plan de estudio
 */
export const getAcademicOffersRequest = (
  items: number = 10,
  page: number = 0,
) => {
  const url = `${ACADEMIC_OFFERS_MAINTAINER.BASE}`;
  const params = {
    items_per_page: items,
    page: page,
  };
  return request<PaginationRequestType<AcademicOffersType>>(url, {
    method: 'get',
    params,
  });
};

/**
 * Obtiene el listado de version del plan de estudio
 */
export const getAcademicOfferAndSchools = (id: number) => {
  const url = `${ACADEMIC_OFFERS_MAINTAINER.GET_BY_ID(id)}`;
  return request<AcademicOfferAndSchools>(url);
};

/**
 * Obtiene el listado de version del plan de estudio
 */
export const getAcademicOffersNoDraftRequest = (
  periodId: number,
  items: number = 10,
  page: number = 0,
) => {
  return request<PaginationRequestType<AcademicOffersTypeDuplicate>>(
    ACADEMIC_OFFERS_MAINTAINER.VACANT_OFFERS_GET_ALL_NON_DRAFT,
    {
      params: {
        items_per_page: items,
        page: page,
        periodId,
      },
    },
  );
};

/**
 *  Crear oferta academica
 */
export const saveAcademicOffersRequest = (data: AcademicOffersBody) => {
  const url = `${ACADEMIC_OFFERS_MAINTAINER.BASE}`;
  return request<any>(url, {
    method: 'post',
    data,
  });
};

/**
 *  Editar oferta academica
 */
export const editAcademicOffersRequest = (
  data: AcademicOffersBody,
  id: string,
) => {
  const url = `${ACADEMIC_OFFERS_MAINTAINER.PUT_ACADEMIC_OFFER(id)}`;
  return request<any>(url, {
    method: 'put',
    data,
  });
};

/**
 *  Eliminar oferta academica
 */
export const deleteAcademicOfferRequest = (id: string) => {
  const url = `${ACADEMIC_OFFERS_MAINTAINER.DELETE_ACADEMIC_OFFER(id)}`;
  return request<any>(url, {
    method: 'delete',
  });
};

/**
 *  Validar cupos oferta academica
 */
export const validateQuotaRequest = (id: string) => {
  const url = `${ACADEMIC_OFFERS_MAINTAINER.GET_VALIDATE_QUOTA(id)}`;
  return request<any>(url, {
    method: 'get',
  });
};

/**
 *  Confirmar cupos de oferta academica
 */
export const confirmQuota = (data: UpdateQuotaType[], id: number) => {
  const url = `${ACADEMIC_OFFERS_MAINTAINER.POST_VALIDATE_QUOTA(id)}`;
  return request<any>(url, {
    method: 'post',
    data,
  });
};

export interface ConfirmOfferAcademic {
  studyPlanId: number;
  studyPlanName: string;
  studyPlanVersionId: number;
  studyPlanVersionName: string;
  versionOfferId: number;
  versionOfferCampusId: number;
  versionOfferScheduleId: number;
  campusId: number;
  campusName: string;
  scheduleId: number;
  scheduleName: string;
}

export const confirmOfferAcademic = (academicOfferId: number) => {
  return request<Array<ConfirmOfferAcademic>>(
    ACADEMIC_OFFERS_MAINTAINER.VACANT_OFFER_GET_PENDING(academicOfferId),
  );
};

export const getOfferReportRequest = (
  academicOfferId: number,
  items: number,
  page: number,
) => {
  const url = `${ACADEMIC_OFFERS_MAINTAINER.POST_OFFER_REPORT(
    academicOfferId,
  )}`;
  const params = {
    items_per_page: items,
    page: page,
  };
  return request<any>(url, { method: 'post', params });
};

export const getvisualizeRequest = (
  academicOfferId: number,
  items_per_page: number,
  page: number,
) => {
  const url = `${ACADEMIC_OFFERS_MAINTAINER.POST_VISUALIZE(academicOfferId)}`;
  const params = {
    items_per_page: items_per_page,
    page: page,
  };
  return request<any>(url, { method: 'post', params });
};

export const attachResolution = (resolution: ResolutionItem) => {
  const url = `${ACADEMIC_OFFERS_MAINTAINER.POST_RESOLUTION(resolution.id)}`;
  const formData = new FormData();
  formData.append('resolutionFile', resolution.resolutionFile);
  formData.append('resolutionNumber', resolution.resolutionNumber);

  return request<any>(url, {
    method: 'post',
    data: formData,
  });
};

export const getResolutionUrl = (id: number) => {
  const url = `${ACADEMIC_OFFERS_MAINTAINER.GET_RESOLUTION(id)}`;
  return request<any>(url, {
    method: 'get',
  });
};

export const completeOffer = (id: number) => {
  const url = `${ACADEMIC_OFFERS_MAINTAINER.GET_COMPLETE_OFFER(id)}`;
  return request<any>(url, {
    method: 'get',
  });
};
