import { addToast, Button } from '@octano/global-ui';
import { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Card, Col, Container, Row } from 'reactstrap';
import { createPeriodRequest } from '../../../api/requests/periods';
import GoBackButton from '../../../components/buttons/GoBackButton';
import { useLayoutState } from '../../../hooks/useLayoutState';
import PeriodsForm from './PeriodsForm';
import { PeriodsTypeLoader } from './PeriodsTypeLoader';

const PeriodRecordCreate = () => {
  const { hideTabs, hideErrorModal } = useLayoutState();
  useEffect(() => {
    hideTabs();
    hideErrorModal();
  }, [hideTabs, hideErrorModal]);

  let history = useHistory();
  const goBack = useCallback(() => {
    history.push(`/maintainers/periods`);
  }, [history]);

  const prefix = 'maintainers.periodsForm';
  const { t } = useTranslation();

  const onSubmit = useCallback(
    async (values) => {
      const response = await createPeriodRequest(values);
      if (!response.error) {
        addToast({
          icon: 'success',
          color: 'success',
          text: t(`${prefix}.createSuccess`),
        });
        goBack();
      } else {
        return response.error.code;
      }
    },
    [goBack, t],
  );

  return (
    <Container>
      <Card className="p-3 p-md-4">
        <div className="mb-4">
          <GoBackButton onClick={goBack} />
        </div>
        <PeriodsTypeLoader>
          {({ data }) => (
            <PeriodsForm periodsType={data} onSubmit={onSubmit}>
              {({ isSubmitting }) => (
                <Row className="pt-5">
                  <Col
                    xs={{ order: 2, size: 12 }}
                    md={{ order: 1, size: 4 }}
                    className="ml-auto"
                  >
                    <div>
                      <Button
                        type="button"
                        text={t(`common.actions.cancel`)}
                        outlined
                        onClick={() => goBack()}
                        fullwidth
                      />
                    </div>
                  </Col>

                  <Col xs={{ order: 1, size: 12 }} md={{ order: 2, size: 4 }}>
                    <div className="pb-3 pb-md-0">
                      <Button
                        type="submit"
                        text={t(`${prefix}.createPeriod`)}
                        loading={isSubmitting}
                        fullwidth
                      />
                    </div>
                  </Col>
                </Row>
              )}
            </PeriodsForm>
          )}
        </PeriodsTypeLoader>
      </Card>
    </Container>
  );
};

export default PeriodRecordCreate;
