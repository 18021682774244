import request from '../../../../api/request';
import { PeriodType } from '../../../../api/requests/periodsType';
import {
  AcademicLevelListItem,
  SchoolsListItem,
} from '../../../../api/requests/schools';
import {
  StudyPlanBody,
  StudyPlansListResponse,
} from '../../../../api/requests/studyPlans';
import { ModalityListItem } from '../../../../types/ModalityTypes';
import { PaginationRequestType } from '../../../../types/paginationRequestType';
import { VersionTypes } from '../../../../types/VersionTypes';
import { StudyPlanItem } from '../interfaces/sudy-plan-item.interface';

/**
 * PLANES DE ESTUDIO
 */
export const STUDY_PLANS = {
  FORM_DATA: '/maintainers-portal/academic-offer/study-plans/form-data',
  LIST: '/maintainers-portal/academic-offer/study-plans',
  GET: (studyPlanId: number) =>
    `/maintainers-portal/academic-offer/study-plans/${studyPlanId}`,
  CREATE: '/maintainers-portal/academic-offer/study-plans',
  PUT: (studyPlanId: number) =>
    `/maintainers-portal/academic-offer/study-plans/${studyPlanId}`,
};

/**
 * Informacion para llenar el formulario de creación y edición
 */
export function getStudyPlanFormData() {
  return request<{
    modalities: ModalityListItem[];
    schools: SchoolsListItem[];
    periodTypes: PeriodType[];
    academicLevels: AcademicLevelListItem[];
  }>(STUDY_PLANS.FORM_DATA);
}

/**
 * Obtiene el listado de planes de estudio
 */
export const getStudyPlansRequest = (
  items: number = 10,
  page: number = 0,
  searchQuery?: string,
) => {
  const search = searchQuery ? new URLSearchParams(searchQuery) : '';
  const searchParams = search ? Object.fromEntries(search) : {};
  return request<StudyPlansListResponse>(STUDY_PLANS.LIST, {
    method: 'get',
    params: {
      items_per_page: items,
      page: page,
      ...searchParams,
    },
  });
};

/**
 *  Crear o editar un plan de estudio
 */
export const saveStudyPlanRequest = (data: StudyPlanBody) => {
  const isEdit = !!data.id;
  const url = isEdit && data.id ? STUDY_PLANS.PUT(data.id) : STUDY_PLANS.CREATE;
  return request<StudyPlanBody>(url, {
    method: isEdit ? 'put' : 'post',
    data,
  });
};

/**
 * Obtiene un plan de estudios
 */
export const getStudyPlanRequest = (studyPlanId: number) => {
  return request<{ data: StudyPlanItem }>(STUDY_PLANS.GET(studyPlanId));
};

/**
 * Obtiene el listado de version del plan de estudio
 */
export const getVersionsRequest = (
  id: number,
  items: number = 10,
  page: number = 0,
) => {
  const url = `${STUDY_PLANS.LIST}/${id}/versions?items_per_page=${items}&page=${page}`;
  return request<PaginationRequestType<VersionTypes>>(url);
};
