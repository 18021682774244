import { useCallback } from 'react';
import { saveDynamicForm } from '../api';
export interface UseActions {
  saveDynamicForm: (data: any) => Promise<void>;
}
type ActionType = 'create' | 'update' | 'delete';

export default function useActions(props: {
  onSuccess: (action: ActionType) => void;
  onError: (action: ActionType, message?: string) => void;
}) {
  const { onSuccess, onError } = props;
  const saveDynamicFormAction = useCallback(
    async (teacherId: number, data: any) => {
      const responses = Object.entries(data)
        .map(([key, value]) => {
          return {
            fieldName: key,
            value,
          };
        })
        .filter(({ value }) => value !== undefined && value !== null);

      /**
       * si el país es chile igual mando location y custom city.
       * Como el formulario es opcional veo que se haya marcado ciudad y comuna
       */
      if (
        data.commune &&
        data.city &&
        data.String(data?.country?.label).toLowerCase().includes('chile')
      ) {
        responses.push(
          { fieldName: 'location', value: data.commune?.label },
          { fieldName: 'customCity', value: data.city?.label ?? '-' },
        );
      }
      const result = await saveDynamicForm(teacherId, {
        responses,
      });

      if (!result.error) {
        return onSuccess('create');
      }
      onError('create', result.error.data?.message);
    },
    [onSuccess, onError],
  );

  return {
    saveDynamicForm: saveDynamicFormAction,
  };
}
