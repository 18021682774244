import {
  ColumnTable,
  DisplayInfo,
  OutlinedSelect,
  OutlinedSelectOptionType,
  Table,
  Icon,
} from '@octano/global-ui';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { useMemo, useCallback } from 'react';
import {
  InterimDegree,
  InterimDegreeStatus,
} from '../interfaces/interimDegree.interface';
import Tooltip from '../../../../components/tooltip/Tooltip';
import { PathsLayouts } from '../../../../config/routes';

const dictPrefix = 'interimDegree.interimDegrees';

interface Props {
  data: InterimDegree[];
  isLoadingResults: boolean;
  itemPerPage: number;
  totalItems: number;
  totalPages: number;
  currentPage: number;
  showEnabled?: boolean;
  editEnabled?: boolean;
  removeEnabled?: boolean;
  onChangePage: (page: number) => void;
  onPressDelete?: (id: number) => void;
  onPressStatus?: (id: number, status: InterimDegreeStatus) => void;
}

export function InterimDegreeInterimDegressTable({
  data,
  isLoadingResults,
  itemPerPage,
  totalPages,
  totalItems,
  currentPage,
  onChangePage,
  onPressDelete,
  onPressStatus,
  showEnabled = true,
  editEnabled,
  removeEnabled,
}: Props) {
  const { t } = useTranslation();
  const history = useHistory();

  const goToShow = useCallback(
    (id: number) => {
      history.push(`${PathsLayouts.academicOffers}/interim-degree/show/${id}`);
    },
    [history],
  );
  const goToEdit = useCallback(
    (id: number) => {
      history.push(`${PathsLayouts.academicOffers}/interim-degree/edit/${id}`);
    },
    [history],
  );

  const statuses = useMemo<OutlinedSelectOptionType[]>(
    () => [
      {
        value: InterimDegreeStatus.Active,
        label: t(`${dictPrefix}.statuses.active`),
        icon: {
          name: 'check',
          color: 'success',
        },
      },
      {
        value: InterimDegreeStatus.Draft,
        label: t(`${dictPrefix}.statuses.draft`),
        icon: {
          name: 'warning',
          color: 'warning',
        },
      },
      {
        value: InterimDegreeStatus.Closed,
        label: t(`${dictPrefix}.statuses.closed`),
        icon: {
          name: 'close',
          color: 'danger',
        },
      },
    ],
    [t],
  );

  const getStatuses = useCallback(
    (row: InterimDegree) => {
      if (row?.status !== InterimDegreeStatus.Draft) {
        return statuses.filter((s) => s?.value !== InterimDegreeStatus.Draft);
      }
      return statuses;
    },
    [statuses],
  );

  const getStatus = useCallback(
    (row: InterimDegree) => {
      return getStatuses(row).find((s) => s?.value === row.status) ?? undefined;
    },
    [getStatuses],
  );

  const columns = useMemo(() => {
    const cols: ColumnTable<InterimDegree>[] = [
      {
        headerText: t(`${dictPrefix}.table.name`),
        columnName: 'name',
        tdClassName: 'text-center',
        thClassName: 'text-center',
      },
      {
        headerText: t(`${dictPrefix}.table.description`),
        columnName: 'description',
        tdClassName: 'text-center',
        thClassName: 'text-center',
      },
      {
        headerText: t(`${dictPrefix}.table.actions`),
        columnName: 'id',
        tdClassName: 'text-center',
        thClassName: 'text-center',
        width: 250,
        cellFormat: ({ row }) => (
          <>
            <div className="d-flex w-100 align-items-center justify-content-center">
              <Tooltip
                id={`hint_show_${row.id}`}
                placement="bottom"
                text={t(`${dictPrefix}.tooltips.show`)}
              >
                <button
                  type="button"
                  className="btn"
                  onClick={() => !!showEnabled && goToShow(row.id)}
                >
                  <Icon
                    name="eye"
                    size={22}
                    color={showEnabled ? 'primary' : 'disabled'}
                  />
                </button>
              </Tooltip>
              <Tooltip
                id={`hint_edit_${row.id}`}
                placement="bottom"
                text={t(`${dictPrefix}.tooltips.edit`)}
              >
                <button
                  type="button"
                  className="btn"
                  onClick={() =>
                    !!(
                      editEnabled && row?.status === InterimDegreeStatus.Draft
                    ) && goToEdit(row.id)
                  }
                >
                  <Icon
                    name="edit"
                    size={16}
                    color={
                      editEnabled && row?.status === InterimDegreeStatus.Draft
                        ? 'primary'
                        : 'disabled'
                    }
                  />
                </button>
              </Tooltip>
              <Tooltip
                id={`hint_remove_${row.id}`}
                placement="bottom"
                text={t(`${dictPrefix}.tooltips.remove`)}
              >
                <button
                  type="button"
                  className="btn"
                  onClick={() =>
                    !!(
                      removeEnabled &&
                      row?.status === InterimDegreeStatus.Draft &&
                      onPressDelete
                    ) && onPressDelete(row.id)
                  }
                >
                  <Icon
                    name="trash"
                    size={18}
                    color={
                      removeEnabled && row?.status === InterimDegreeStatus.Draft
                        ? 'primary'
                        : 'disabled'
                    }
                  />
                </button>
              </Tooltip>
            </div>
          </>
        ),
      },
      {
        headerText: t(`${dictPrefix}.table.status`),
        columnName: 'status',
        tdClassName: 'text-center',
        thClassName: 'text-center',
        width: 200,
        cellFormat: ({ row }) => (
          <>
            <OutlinedSelect
              value={getStatus(row)}
              name={`status_${row.id}`}
              onChange={(next) =>
                !!(onPressStatus && next?.value !== getStatus(row)?.value) &&
                onPressStatus(row.id, next?.value)
              }
              options={getStatuses(row)}
              isClearable={false}
              isSearchable={false}
              isMulti={false}
            />
          </>
        ),
      },
    ];
    return cols;
  }, [
    t,
    showEnabled,
    editEnabled,
    removeEnabled,
    getStatus,
    getStatuses,
    goToEdit,
    goToShow,
    onPressDelete,
    onPressStatus,
  ]);

  return (
    <div className="w-100 interim-degrees-table">
      <Table
        isLoadingResults={isLoadingResults}
        data={data}
        columns={columns}
        pagination={{
          currentPage: currentPage + 1,
          itemsPerPage: itemPerPage,
          totalItems: totalItems,
          totalPages: totalPages,
          onChangePage: (page) => onChangePage(page - 1),
        }}
        noResultsText={
          <div className="d-flex justify-content-center align-items-center py-5">
            <DisplayInfo
              iconName="information"
              title={t(`${dictPrefix}.noResults.title`)}
              textBody={t(`${dictPrefix}.noResults.description`)}
              maxWidth="650px"
            />
          </div>
        }
      />
    </div>
  );
}
