import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Card, Col, Row } from 'reactstrap';

import {
  getPayment,
  savePayment,
} from '../../../../api/requests/tuitionProcess';
import DisplayError from '../../../../components/info/DisplayError';
import Loading from '../../../../components/info/Loading';
import { useStepState } from '../../../../components/step/useStepState';
import { SectionTitle } from '../../../../components/text';
import { useLoadingState } from '../../../../hooks/useLoadingState';
import { useTuitionProcess } from '../../TuitionProcessContext';
import PaymentStatus from './PaymentStatus';

const Payment = () => {
  const {
    prefix: translationPrefix,
    postulationDetailId,
    onError,
  } = useTuitionProcess();
  const prefix = `${translationPrefix}.payment`;
  const { t } = useTranslation();
  const { loading, setLoading, errorLoading } = useLoadingState();
  const [isPaid, setIsPaid] = useState<boolean>();
  const [fullName, setFullName] = useState<string>('');
  const { nextStep } = useStepState();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const getPaymentStatus = useCallback(async () => {
    const { data, error } = await getPayment(postulationDetailId);
    if (data && !error) {
      setIsPaid(data.isPaid);
      setFullName(data.studentFullName);
    } else if (error) {
      onError(error.code);
    }
    setLoading(false);
  }, [postulationDetailId, onError, setLoading]);

  useEffect(() => {
    getPaymentStatus();
  }, [getPaymentStatus]);

  // TODO: Este flujo cambiará en el futuro, debe ir al portal de pagos a realizar el pago
  const goToPayment = async () => {
    const { error } = await savePayment(postulationDetailId);
    if (!error) {
      setIsPaid(true);
    } else {
      onError(error.code);
    }
  };

  if (errorLoading) {
    return (
      <DisplayError
        insideCard
        textBody={errorLoading}
        retryAction={() => setLoading(true)}
        loadingAction={loading}
      />
    );
  }
  if (loading) {
    return <Loading insideCard />;
  }
  return (
    <Card className="px-4 px-md-5 py-4">
      <Row className="pb-4 pb-md-0">
        <Col className="pb-3" xs={12} lg={7} md={8} xl={9}>
          <span className="fs-18 text-primary">{t(`${prefix}.student`)}</span>
          <br />
          <span className="fs-18 text-primary fw-600">{fullName}</span>
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          <SectionTitle text={t(`${prefix}.title`)} />
        </Col>
      </Row>
      {isPaid ? (
        <PaymentStatus
          iconName="success"
          title={t(`${prefix}.successTitle`)}
          body={t(`${prefix}.successBody`)}
          textBtn={t(`${prefix}.btnNext`)}
          actionBtn={() => nextStep()}
        />
      ) : (
        <PaymentStatus
          iconName="information"
          body={t(`${prefix}.bodyPayment`)}
          textBtn={t(`${prefix}.btnPayment`)}
          actionBtn={goToPayment}
        />
      )}
    </Card>
  );
};

export default Payment;
