import { ColumnTable, Icon } from '@octano/global-ui';

import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import HoursSummary from '../parts/HoursSummary';

const dictPrefix = 'teacherFile.hiringInformation';

export default function useColumns({
  onClickEdit,
  onClickRemove,
}: {
  onClickEdit: (row: any) => void;
  onClickRemove: (row: any) => void;
}): ColumnTable[] {
  const { t } = useTranslation();

  return [
    {
      columnName: 'teacherContractType',
      headerText: t(`${dictPrefix}.recordsTable.type`),
      tdClassName: 'text-center',
      thClassName: 'text-center',
      cellFormat: ({ value }) => value?.name,
    },
    {
      columnName: 'studyPlan',
      headerText: t(`${dictPrefix}.recordsTable.studyPlan`),
      tdClassName: 'text-center',
      thClassName: 'text-center',
      cellFormat: ({ value }) => value?.name,
    },
    {
      columnName: 'teacherAcademicRank',
      headerText: t(`${dictPrefix}.recordsTable.teacherAcademicRank`),
      tdClassName: 'text-center',
      thClassName: 'text-center',
      cellFormat: ({ value }) => value?.name,
    },
    {
      columnName: 'campus',
      headerText: t(`${dictPrefix}.recordsTable.campus`),
      tdClassName: 'text-center',
      thClassName: 'text-center',
      cellFormat: ({ value }) => value?.name,
    },
    {
      columnName: 'hoursSummary',
      headerText: t(`${dictPrefix}.recordsTable.hours`),
      tdClassName: 'text-center',
      thClassName: 'text-center',
      cellFormat: ({ value, row }) => (
        <>
          <HoursSummary
            target={`record-tooltip-${row.id}`}
            hours={{
              total: value?.find((e: any) => e.type === 'total')?.value,
              teaching: value?.find((e: any) => e.type === 'teaching')?.value,
              research: value?.find((e: any) => e.type === 'research')?.value,
              linking: value?.find((e: any) => e.type === 'linking')?.value,
              others: value?.find((e: any) => e.type === 'others')?.value,
            }}
          />
        </>
      ),
    },
    {
      columnName: 'startAt',
      headerText: t(`${dictPrefix}.recordsTable.startAt`),
      tdClassName: 'text-center',
      thClassName: 'text-center',
      cellFormat: ({ value }) => dayjs(value).format('DD/MM/YYYY'),
    },
    {
      columnName: 'endAt',
      headerText: t(`${dictPrefix}.recordsTable.endAt`),
      tdClassName: 'text-center',
      thClassName: 'text-center',
      cellFormat: ({ value }) =>
        value
          ? dayjs(value).format('DD/MM/YYYY')
          : t(`${dictPrefix}.recordsTable.undefinedEnd`),
    },
    {
      columnName: 'id',
      headerText: t(`${dictPrefix}.recordsTable.actions`),
      tdClassName: 'text-center',
      thClassName: 'text-center',
      cellFormat: ({ row }) => {
        return (
          <div className="d-flex justify-content-center">
            <button className="btn" onClick={() => onClickEdit(row)}>
              <Icon color="primary" name="edit" />
            </button>

            <button className="btn" onClick={() => onClickRemove(row)}>
              <Icon name="trash" color="primary" />
            </button>
          </div>
        );
      },
    },
  ];
}
