import { Button, Table, TablePagination } from '@octano/global-ui';
import { CellFormatOptions } from '@octano/global-ui/dist/components/Table/types/TableTypes';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router';
import { Card, Container } from 'reactstrap';

import {
  getAcademicOfferAndSchools,
  getvisualizeRequest,
} from '../../../api/requests/academicOffers';
import { SchoolsListItem } from '../../../api/requests/schools';
import GoBackButton from '../../../components/buttons/GoBackButton';
import DisplayError from '../../../components/info/DisplayError';
import Loading from '../../../components/info/Loading';
import { SectionTitle } from '../../../components/text';
import TableEmptyContent from '../../../components/text/TableEmptyContent';
import { PathsLayouts } from '../../../config/routes';
import { useLoadingState } from '../../../hooks/useLoadingState';
import useQuery from '../../../hooks/useQuery';
import { AcademicOffersType } from '../../../types/AcademicOffersType';
import styles from './AcademicOffers.module.scss';
import AcademicOfferViewTableInfo from './components/AcademicOfferViewTableInfo';
import AcademicOfferViewTableInfoLoader from './components/AcademicOfferViewTableInfoLoader';
import ExpandableCell from './components/ExpandableCell';
import { StudyPlanItemCustom } from './interfaces/study-plan-item-custom.interface';

const ITEMS_PER_PAGE_DEFAULT = 10;

const PAGE_SIZE = 10;

function AcademicOfferViewTablePeriod(props: {
  academicOfferId: number;
  schools: SchoolsListItem[];
}): JSX.Element {
  const { academicOfferId } = props;
  const { t } = useTranslation();
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalItems, setTotalItems] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(1);
  const [studyPlans, setStudyPlans] = useState<StudyPlanItemCustom[]>([]);

  const {
    loading,
    setLoading,
    errorLoading,
    setErrorLoading,
  } = useLoadingState();

  const columns = [
    {
      columnName: 'name',
      headerText: t('academicOffers.form.studyPlan'),
      width: '20%',
      cellFormat: (options: CellFormatOptions<StudyPlanItemCustom>) => {
        return (
          <ExpandableCell
            academicOfferId={academicOfferId}
            studyPlanId={options.row.StudyPlan_id}
            name={options.row.StudyPlan_name}
            studyPlanItem={options.row}
            versions={options.row.offers}
            duplicatedOffers={options.row.duplicatedOffers}
            getStudyPlansBySchool={console.info}
            reloadViewData={console.info}
            onlyView={true}
            textEmpaty={{
              subtitle: t('academicOffers.info.plansOfferSedeEmpatySubtitle'),
            }}
          />
        );
      },
    },
  ];

  const getVisualize = useCallback(async () => {
    setLoading(true);
    let { error, data } = await getvisualizeRequest(
      academicOfferId,
      PAGE_SIZE,
      currentPage - 1,
    );
    if (error) {
      setErrorLoading(error.code);
    } else {
      setStudyPlans([...data.data]);
      setTotalItems(data.total);
      setTotalPages(data.total_pages);
    }
    setLoading(false);
  }, [academicOfferId, currentPage, setErrorLoading, setLoading]);

  useEffect(() => {
    getVisualize();
  }, [currentPage, getVisualize]);

  if (errorLoading) {
    return (
      <DisplayError
        insideCard
        textBody={errorLoading}
        retryAction={() => setLoading(true)}
        loadingAction={loading}
      />
    );
  }
  if (loading) {
    return <Loading insideCard />;
  }
  return (
    <>
      <div
        className={`${styles.academicOffersCreateStep2Table}`}
        style={{ marginTop: '24px', fontWeight: 'normal' }}
      >
        <Table
          columns={columns}
          data={studyPlans}
          noResultsText={
            <TableEmptyContent
              title={t('academicOffers.form.studyPlansTableEmptyTitle')}
              subtitle={t('academicOffers.info.plansOfferEmpatySubtitle')}
            />
          }
        />
        {totalItems > ITEMS_PER_PAGE_DEFAULT && (
          <TablePagination
            pagination={{
              currentPage: currentPage,
              itemsPerPage: ITEMS_PER_PAGE_DEFAULT,
              onChangePage: (page) => {
                setCurrentPage(page);
              },
              totalItems: totalItems,
              totalPages: totalPages,
            }}
          />
        )}
      </div>
    </>
  );
}

function AcademicOffersView() {
  const { t } = useTranslation();
  const history = useHistory();
  const [academicOffer, setAcademicOffer] = useState<AcademicOffersType>({});
  const [schools, setSchools] = useState<SchoolsListItem[]>([]);
  const {
    loading,
    setLoading,
    errorLoading,
    setErrorLoading,
  } = useLoadingState();
  const { id: academicOfferId } = useParams<{ id: string }>();
  const query = useQuery();
  const viewInform: boolean = query.get('viewinform') ? true : false;
  const confirm = query.get('confirm') === 'true';

  const requestAcademicOfferAndSchools = useCallback(async () => {
    const { data, error } = await getAcademicOfferAndSchools(
      Number(academicOfferId),
    );
    if (error) {
      setErrorLoading(error.code);
    } else {
      const { data: acadeimcOfferAndSchools = [] } = data || {};
      const academicOfferData = acadeimcOfferAndSchools[0] as AcademicOffersType;
      const adacemicSchoolsData = acadeimcOfferAndSchools[1] as SchoolsListItem[];
      setAcademicOffer({ ...academicOfferData });
      setSchools([...adacemicSchoolsData]);
      setLoading(false);
    }
  }, [academicOfferId, setErrorLoading, setLoading]);

  useEffect(() => {
    requestAcademicOfferAndSchools();
  }, [requestAcademicOfferAndSchools]);

  function changeViewInfo(): void {
    history.push(`${history.location.pathname}?viewinform=true`);
  }

  function back(): void {
    let path;
    history.push(
      `${PathsLayouts.academicOffers}/vacant-offers/record/${academicOfferId}`,
    );

    if (confirm) {
      history.push(
        `${PathsLayouts.academicOffers}/vacant-offers/record/${academicOfferId}`,
      );
    } else {
      path = viewInform
        ? history.location.pathname
        : `${PathsLayouts.academicOffers}/vacant-offers`;
      history.push(path);
    }
  }

  if (errorLoading) {
    return (
      <DisplayError
        insideCard
        textBody={errorLoading}
        retryAction={() => setLoading(true)}
        loadingAction={loading}
      />
    );
  }
  if (loading) {
    return (
      <div className="mx-3">
        <Loading insideCard />
      </div>
    );
  }
  return (
    <Container>
      <Card className="p-3 p-md-4">
        <div className="mb-4">
          <GoBackButton onClick={back} />
        </div>
        <SectionTitle
          text={`${
            viewInform
              ? t('academicOffers.info.academicInfo')
              : academicOffer?.Period_name
          }`}
        />
        {query.get('viewinform') ? (
          <AcademicOfferViewTableInfoLoader
            academicOfferId={Number(academicOfferId)}
          >
            <AcademicOfferViewTableInfo
              academicOfferId={Number(academicOfferId)}
              academicOfferResolutionNumber={
                academicOffer.AcademicOffer_resolutionNumber
              }
            />
          </AcademicOfferViewTableInfoLoader>
        ) : (
          <>
            <p className="fs-18">{t('academicOffers.info.body')}</p>
            <Button
              type="button"
              text={t('academicOffers.info.viewInfo')}
              size="sm"
              outlined
              color="primary"
              onClick={changeViewInfo}
              style={{ marginLeft: 'auto', padding: '8px 32px' }}
            />
            <AcademicOfferViewTablePeriod
              schools={schools || []}
              academicOfferId={Number(academicOfferId)}
            />
          </>
        )}
      </Card>
    </Container>
  );
}

export default AcademicOffersView;
