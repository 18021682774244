import { IconPaths } from '@octano/global-ui';

export enum DocumentTypesEnum {
  contract = 'contract',
  lem = 'lem',
  nem = 'nem',
  'sustainer-income-one' = 'sustainer-income-one',
  'sustainer-income-others' = 'sustainer-income-others',
  'promissory-note' = 'promissory-note',
  insurance = 'insurance',
  'sustainer-proof-address' = 'sustainer-proof-address',
}

export enum IdentityTypesEnum {
  student = 'student',
  sustainer = 'sustainer',
  cosigner = 'cosigner',
  'postulant-passport' = 'postulant-passport',
  'sustainer-passport' = 'sustainer-passport',
  'cosigner-passport' = 'cosigner-passport',
}
export const GeneralTypesEnum = { ...DocumentTypesEnum, ...IdentityTypesEnum };

export type IdentityTypes = keyof typeof IdentityTypesEnum;
export type DocumentTypes = keyof typeof DocumentTypesEnum;
export type GeneralTypes = keyof typeof GeneralTypesEnum;
export type ModalDocumentType = {
  [key in keyof typeof GeneralTypesEnum]: {
    name: string;
    hasTooltip?: boolean;
    icon: keyof typeof IconPaths;
  };
};

export function isDocumentType(
  input: GeneralTypes,
): input is DocumentTypesEnum {
  return Object.keys(DocumentTypesEnum).includes(input);
}
export function isIdentityType(
  input: GeneralTypes,
): input is IdentityTypesEnum {
  return Object.keys(IdentityTypesEnum).includes(input);
}

/**
 * DocumentCard
 */

export type RequestedDocument = {
  uploaded: boolean;
  downloaded: boolean;
  document: {
    code: string;
    commentRequired: boolean;
    description: string | null;
    generated: boolean;
    hint: string | null;
    icon: string;
    id: number;
    name: string;
    required: boolean;
    uploadMethod: 'default' | 'signature' | 'identification' | 'confirmation';
  };
  requiredDocumentId?: number | null;
};

export type SignDocumentationStepResponse = {
  studentFullName: string;
  documents: Array<{
    type: DocumentTypes;
    isSigned: boolean;
  }>;
  identityCards: Array<{
    type: IdentityTypes;
    isUploaded: boolean;
  }>;
  documentsOthers: Array<{
    type: DocumentTypes;
    isSigned: boolean;
  }>;
  requestedDocuments: RequestedDocument[];
};

export type SignDocumentationBody = {
  type: string;
  pswSustainer: string;
  pswStudent: string;
};

export type SignDocumentationIdentityCardUpload = {
  type: string;
  front: File;
  back: File;
};
