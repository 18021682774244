import {
  Button,
  Select,
  SelectOptionType,
  TextAreaInput,
  TextInput,
} from '@octano/global-ui';
import { useEffect } from 'react';
import { Control, DefaultValues, FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Col, Form, FormGroup, Row } from 'reactstrap';

import { SectionTitle } from '../../text';
import {
  FormField,
  FormSection,
  validationSchema,
} from '../validations/validations';

export type DynamicContactType = 'studentFile' | 'sustainerFile';

export interface ButtonData {
  type: 'button' | 'submit';
  text: string;
  onClick?: () => void;
  disabledInLoading?: boolean;
  disabledInSubmitting?: boolean;
  fullwidth?: boolean;
  outlined?: boolean;
}

function RenderFields(
  section: FormSection,
  control: Control<any>,
  options?: Record<string, SelectOptionType[]>,
) {
  return section.fields.map((field: FormField, index: number) => (
    <Col key={index} md={field.grid.md} xs={field.grid.xs}>
      <FormGroup>
        {field.component === 'text' && (
          <TextInput
            {...{ ...field.properties, control }}
            name={field.name}
            label={field.label}
            maxLength={field.validations?.maxLength}
            rules={validationSchema(field.validations)}
            placeholder={field?.placeholder}
          />
        )}
        {field.component === 'select' && (
          <Select
            {...{ ...field.properties, control }}
            name={field.name}
            label={field.label}
            options={(options && options[field.name]) ?? []}
            rules={validationSchema(field.validations)}
            placeholder={field?.placeholder}
          />
        )}
        {field.component === 'textarea' && (
          <TextAreaInput
            {...{ ...field.properties, control }}
            name={field.name}
            label={field.label}
            maxLength={field.validations?.maxLength}
            rules={validationSchema(field.validations)}
            placeholder={field?.placeholder}
          />
        )}
      </FormGroup>
    </Col>
  ));
}

interface RenderFormProps {
  structureBtns?: ButtonData[];
  structure: any;
  defaultValues: DefaultValues<any>;
  optionalValues: DefaultValues<any>;
  options?: Record<string, SelectOptionType[]>;
  sectionComponents?: Record<string, JSX.Element>;
  onSubmit: (data: any) => Promise<void>;
  onErrors: (error: string[]) => void;
}

function RenderForm(props: RenderFormProps) {
  const {
    structureBtns,
    structure,
    defaultValues,
    optionalValues,
    sectionComponents,
    options,
    onErrors,
    onSubmit,
  } = props;
  const { t } = useTranslation();
  const history = useHistory();

  const buttons: ButtonData[] = [
    {
      type: 'button',
      text: t('common.actions.back'),
      onClick: () => history.push('/tuition-process'),
      disabledInSubmitting: true,
      fullwidth: true,
      outlined: true,
    },

    {
      type: 'submit',
      text: t('common.actions.next'),
      disabledInLoading: true,
      disabledInSubmitting: true,
      fullwidth: true,
    },
  ];

  const methods = useForm<{ hasOptionalValues: boolean; [key: string]: any }>({
    defaultValues,
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    shouldUnregister: true,
  });

  const { formState, setValue } = methods;

  const hasOptionalValues = methods.watch('hasOptionalValues');

  useEffect(() => {
    if (hasOptionalValues && Boolean(optionalValues)) {
      Object.entries(optionalValues).forEach(([key, value]) => {
        setValue(key, value);
      });
    }
  }, [hasOptionalValues, optionalValues, setValue]);

  const fieldNames = structure.flatMap((section: any) =>
    section.fields.map((field: any) => field.name),
  );

  structure
    .flatMap((section: any) => section.fields)
    .forEach((field: any) => {
      field.dependsOn?.forEach((dependency: any) => {
        if (!fieldNames.includes(dependency)) {
          onErrors([
            `El campo "${field.name}" depende de "${dependency}", que no está en el formulario.`,
          ]);
        }
      });
    });

  return (
    <FormProvider {...methods}>
      <Form onSubmit={methods.handleSubmit(onSubmit)}>
        {structure.map((section: FormSection, index: number) => {
          if (section.component && sectionComponents?.[section.component]) {
            return sectionComponents[section.component];
          } else if (
            section.component &&
            !sectionComponents?.[section.component]
          ) {
            return (
              <div key={index} className="pt-5 text-center">
                <SectionTitle
                  text={`No has definido un componente para la sección ${section.title}`}
                />
                <p>Sección sin definir</p>
              </div>
            );
          } else {
            return (
              <div key={index} className="pt-5">
                <SectionTitle text={section.title} />
                <Row>{RenderFields(section, methods.control, options)}</Row>
              </div>
            );
          }
        })}

        <Row className="py-5 justify-content-end">
          {(structureBtns ?? buttons).map((button, index) => (
            <Col
              key={index}
              xs={12}
              lg={4}
              className={`pb-2 order-${index + 1} order-lg-${index + 1}`}
            >
              <Button
                type={button.type}
                text={button.text}
                onClick={button.onClick}
                loading={
                  button.disabledInLoading ? formState.isSubmitting : false
                }
                disabled={
                  button.disabledInSubmitting ? formState.isSubmitting : false
                }
                fullwidth={button.fullwidth}
                outlined={button.outlined}
              />
            </Col>
          ))}
        </Row>
      </Form>
    </FormProvider>
  );
}

export default RenderForm;
