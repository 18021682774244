import request, { AxiosResult } from '../../../../api/request';
import {
  ChangeStatusRequestErrors,
  ChangeStatusVersion,
  VersionRequestErrors,
  VersionValues,
} from '../../../../api/requests/versions';

/**
 * StudyPlan Version
 */

export const STUDY_PLAN_VERSION = {
  FORM_DATA:
    '/maintainers-portal/academic-offer/study-plan-version/create-form-data',
  CREATE: '/maintainers-portal/academic-offer/study-plan-version',
  PUT: (studyPlanVersionId: number) =>
    `/maintainers-portal/academic-offer/study-plan-version/${studyPlanVersionId}`,
};

/**
 * crear unaversion en un plan de estudio
 */
export const createVersionsRequest = async (
  data: VersionValues,
): Promise<AxiosResult<VersionValues, VersionRequestErrors>> => {
  const url = STUDY_PLAN_VERSION.CREATE;
  const response = await request<{ data: VersionValues }>(url, {
    method: 'post',
    data,
  });

  if (response.error) {
    if (response.error.code === 'HTTP_ERROR' && response.error.status === 400) {
      // en el caso si el codigo esta en uso
      if (response.error.data.message && response.error.data.message[0]) {
        const error_code =
          response.error.data.message[0].indexOf('code in use');
        if (error_code !== -1) {
          return {
            error: {
              ...response.error,
              code: 'NOT_UNIQUE_CODE',
            } as VersionRequestErrors,
          };
        }
      }

      // de lo contrario regresa cualquier error
      return response;
    } else {
      return response;
    }
  }

  return {
    data: response.data.data,
  };
};
/**
 * Obtiene un plan de estudios
 */
export const getStudyPlanVersionCreateForm = () => {
  return request<{ isSoftlandEnabled: boolean }>(STUDY_PLAN_VERSION.FORM_DATA);
};

/**
 * cambiar una version y validar que sea correcto
 */
export const changeStatusVersion = async (
  id: number,
  data: ChangeStatusVersion,
): Promise<AxiosResult<{}, ChangeStatusRequestErrors>> => {
  const response = await request(STUDY_PLAN_VERSION.PUT(id), {
    method: 'put',
    data,
  });

  if (response.error) {
    if (response.error.code === 'HTTP_ERROR' && response.error.status === 400) {
      return validateDataError(response);
    } else {
      return response;
    }
  }

  return {
    data: response.data.data,
  };
};

const validateDataError = (response: any) => {
  if (response.error.data.message) {
    const other_version_active = response.error.data.message.indexOf(
      'other_version_active',
    );
    if (other_version_active !== -1) {
      return {
        error: {
          ...response.error,
          code: 'other_version_active',
        } as ChangeStatusRequestErrors,
      };
    }

    const error_code = response.error.data.message.indexOf(
      'no_curriculum_created',
    );
    if (error_code !== -1) {
      return {
        error: {
          ...response.error,
          code: 'no_curriculum_created',
        } as ChangeStatusRequestErrors,
      };
    }

    const error_code_active = response.error.data.message.indexOf(
      'no_curriculum_active',
    );
    if (error_code_active !== -1) {
      return {
        error: {
          ...response.error,
          code: 'no_curriculum_active',
        } as ChangeStatusRequestErrors,
      };
    }
  }
  // de lo contrario regresa cualquier error
  return response;
};
