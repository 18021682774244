import { Button, FileInput, Modal, useMobile } from '@octano/global-ui';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

interface UploadModalProps {
  isOpen: boolean;
  onConfirm?: (file: File) => void;
  onCancel?: () => void;
}

const UploadModal = ({
  isOpen,
  onConfirm = () => null,
  onCancel = () => null,
}: UploadModalProps): JSX.Element => {
  const isMobile = useMobile();
  const [file, setFile] = useState<File>();

  const { t } = useTranslation();
  const prefix = 'academicRecord.uploadModal';
  const texts = useMemo(() => {
    return {
      title: t(`${prefix}.title`),
      description: t(`${prefix}.description`),
      fileLabel: t(`${prefix}.fileLabel`),
      fileButton: t(`${prefix}.fileButton`),
      close: t(`${prefix}.close`),
      confirm: t(`${prefix}.confirm`),
    };
  }, [t]);

  const handleConfirm = () => {
    if (file) {
      onConfirm(file);
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      toggle={() => null}
      onExit={() => setFile(undefined)}
      onClosed={() => setFile(undefined)}
    >
      <div>
        <h1 className="text-dark text-center fw-600 fs-20">{texts.title}</h1>
        <p className=" text-center fs-16 lh-30 my-3">{texts.description}</p>
        <FileInput
          btnText={texts.fileButton}
          value={file}
          name="file"
          label={texts.fileLabel}
          onChange={(f) => setFile(f)}
          accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
        />
        <div
          className="d-flex flex-wrap justify-content-between mt-4"
          style={{ gap: '1rem' }}
        >
          <Button
            text={texts.close}
            outlined
            style={{ width: isMobile ? '100%' : 197 }}
            onClick={onCancel}
          />
          <Button
            text={texts.confirm}
            style={{ width: isMobile ? '100%' : 197 }}
            onClick={handleConfirm}
          />
        </div>
      </div>
    </Modal>
  );
};

export default UploadModal;
