import { ToastProvider } from '@octano/global-ui';
import React from 'react';
import ReactDOM from 'react-dom';

import App from './App';
import './assets/scss/styles.scss';
import './config/date';
import './config/language';
import UserStateProvider from './providers/UserStateProvider';
import { UserStateProviderLoader } from './providers/UserStateProviderLoader';

ReactDOM.render(
  <React.StrictMode>
    <ToastProvider>
      <UserStateProviderLoader>
        {(userData) => (
          <UserStateProvider user={userData}>
            <App />
          </UserStateProvider>
        )}
      </UserStateProviderLoader>
    </ToastProvider>
  </React.StrictMode>,
  document.getElementById('root'),
);
