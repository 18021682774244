import { Controller, useFormContext } from 'react-hook-form';
import CreatableSelect from 'react-select/creatable';

export const OutlinedCreateableSelect = ({
  control,
  name,
  rules,
  ...props
}: any) => {
  const {
    formState: { errors },
  } = useFormContext();

  return (
    <div className={'form-group'}>
      <label className="g-outlined-select-label text-uppercase" htmlFor={name}>
        {props?.label}
      </label>
      <Controller
        control={control}
        name={name}
        rules={rules}
        render={({ field }) => (
          <CreatableSelect
            {...field}
            isClearable
            options={props?.options}
            className={props?.className}
            placeholder={props?.placeholder}
            disabled={props?.rules?.disabled}
            required={props?.rules?.required}
            readOnly={props?.rules?.readOnly || props?.readOnly}
            formatCreateLabel={(value) => `Crear "${value}"`}
          />
        )}
      />
      {errors[name] && (
        <small className="g-input-error form-text text-danger">
          {errors[name].message}
        </small>
      )}
    </div>
  );
};
