import request from '../../api/request';
import type { ReportFormData } from './type';

export const REPORT = { FORM_DATA: 'report/form' };

export function getFormData() {
  return request<ReportFormData>(REPORT.FORM_DATA, {
    method: 'get',
  });
}
