import { ColumnTable, DisplayInfo, Table, Icon } from '@octano/global-ui';
import { useTranslation } from 'react-i18next';

import { useMemo } from 'react';
import { format } from 'rut.js';
import { InterimDegreeAuthorizedStudentMassive } from '../interfaces/interim-degree-authorized-student-massive.interface';

const dictPrefix = 'interimDegreeGraduationProcess.students';

interface Props {
  data: InterimDegreeAuthorizedStudentMassive[];
  isLoadingResults: boolean;
  itemPerPage: number;
  totalItems: number;
  totalPages: number;
  currentPage: number;
  onChangePage: (page: number) => void;
  onPressShow?: (id: number) => void;
}

export function InterimDegressGraduatedStudentTable({
  data,
  isLoadingResults,
  itemPerPage,
  totalPages,
  totalItems,
  currentPage,
  onChangePage,
  onPressShow,
}: Props) {
  const { t } = useTranslation();

  const columns = useMemo(() => {
    const cols: ColumnTable<InterimDegreeAuthorizedStudentMassive>[] = [
      {
        headerText: t(`${dictPrefix}.table.name`),
        columnName: 'id',
        tdClassName: 'text-center',
        thClassName: 'text-center',
        cellFormat: ({ row }) => (
          <div>
            <span className="d-block text-center">
              {format(row?.rut ?? '')}
            </span>
            <span className="d-block text-center">{row?.name}</span>
          </div>
        ),
      },
      {
        headerText: t(`${dictPrefix}.table.period`),
        columnName: 'period',
        tdClassName: 'text-center',
        thClassName: 'text-center',
      },
      {
        headerText: t(`${dictPrefix}.table.enrollmentPeriod`),
        columnName: 'enrollmentPeriod',
        tdClassName: 'text-center',
        thClassName: 'text-center',
      },
      {
        headerText: t(`${dictPrefix}.table.degreePeriod`),
        columnName: 'degreePeriod',
        tdClassName: 'text-center',
        thClassName: 'text-center',
      },
      {
        headerText: t(`${dictPrefix}.table.school`),
        columnName: 'school',
        tdClassName: 'text-center',
        thClassName: 'text-center',
      },
      {
        headerText: t(`${dictPrefix}.table.studyPlan`),
        columnName: 'studyPlan',
        tdClassName: 'text-center',
        thClassName: 'text-center',
      },
      {
        headerText: t(`${dictPrefix}.table.interimDegree`),
        columnName: 'interimDegree',
        tdClassName: 'text-center',
        thClassName: 'text-center',
      },
      {
        headerText: t(`${dictPrefix}.table.interimDegreeAverageGraduated`),
        columnName: 'interimDegreeAverage',
        tdClassName: 'text-center',
        thClassName: 'text-center',
        width: 150,
        cellFormat: ({ value }) => <strong>{value}</strong>,
      },
      {
        headerText: t(`${dictPrefix}.table.degreeGrade`),
        columnName: 'degreeGrade',
        tdClassName: 'text-center',
        thClassName: 'text-center',
        width: 150,
        cellFormat: ({ value }) => <strong>{value}</strong>,
      },
      {
        headerText: t(`${dictPrefix}.table.assistanceAverage`),
        columnName: 'assistanceAverage',
        tdClassName: 'text-center',
        thClassName: 'text-center',
        width: 150,
        cellFormat: ({ value }) => <strong>{value}%</strong>,
      },
      {
        headerText: t(`${dictPrefix}.table.action`),
        columnName: 'id',
        tdClassName: 'text-center',
        thClassName: 'text-center',
        width: 100,
        cellFormat: ({ value }) => (
          <>
            <button
              type="button"
              className="btn"
              onClick={() => !!onPressShow && onPressShow(value)}
            >
              <Icon name="eye" size={16} color={'primary'} />
            </button>
          </>
        ),
      },
    ];
    return cols;
  }, [t, onPressShow]);

  return (
    <div className="w-100">
      <Table
        isLoadingResults={isLoadingResults}
        data={data}
        columns={columns}
        pagination={{
          currentPage: currentPage + 1,
          itemsPerPage: itemPerPage,
          totalItems: totalItems,
          totalPages: totalPages,
          onChangePage: (page) => onChangePage(page - 1),
        }}
        noResultsText={
          <div className="d-flex justify-content-center align-items-center py-5">
            <DisplayInfo
              iconName="information"
              title={t(`${dictPrefix}.noResultsGraduatedsList.title`)}
              textBody={t(`${dictPrefix}.noResultsGraduatedsList.description`)}
              maxWidth="650px"
            />
          </div>
        }
      />
    </div>
  );
}
