import { Card, Col, Row } from 'reactstrap';
import { useState, useEffect, useCallback, useRef } from 'react';
import { Alert } from '@octano/global-ui';

import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import useUserState from '../../hooks/useUserState';
import { PermissionName } from '../../types/Auth';
import DisplayPermissionError from '../../components/info/DisplayPermissionError';
import GoBackToListButton from '../../components/buttons/GoBackToListButton';
import DisplayError from '../../components/info/DisplayError';
import Loading from '../../components/info/Loading';
import InterimDegressAuthorizedStudentBox from './parts/InterimDegressAuthorizedStudentBox';
import { getActivitiesDetails } from '../../api/requests/interimDegreeGraduationActivities';
import { InterimDegreeAuthorizedStudentActivitiesDetails } from './interfaces/interim-degree-authorized-student-activities-details.interface';
import ActivitiesResumenTable from './parts/ActivitiesResumenTable';
import CreateStudentActivityModal, {
  CreateStudentActivityModalMethods,
} from './parts/CreateStudentActivityModal';
import { InterimDegreeActivity } from '../academicOffers/InterimDegree/interfaces/interim-degree-activity.interface';
import ActivitiesResumenForm, {
  ActivitiesResumenFormDefaultValues,
  ActivitiesResumenFormValues,
} from './parts/ActivitiesResumenForm';
import { useForm, FormProvider } from 'react-hook-form';

export const MODULE_PERMISSIONS = [
  PermissionName.INTERIM_DEGREE_GRADUATION_PROCESS_ALL,
];
const dictPrefix = 'interimDegreeGraduationProcess.activities';

const InterimDegreeGraduationProcessActivitiesResumen = () => {
  const { t } = useTranslation();
  const { isAuthorizedTo } = useUserState();

  const createRecordRef = useRef<CreateStudentActivityModalMethods>(null);

  const { interimDegreeAuthorizedStudentId } = useParams<{
    interimDegreeAuthorizedStudentId: string;
  }>();

  const [summary, setSummary] =
    useState<InterimDegreeAuthorizedStudentActivitiesDetails>();
  const [isLoadingData, setIsLoadingData] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>();
  const [isMounted, setIsMounted] = useState<boolean>(false);

  const methods = useForm<ActivitiesResumenFormValues>({
    defaultValues: ActivitiesResumenFormDefaultValues,
  });
  const { reset } = methods;

  const handleData = useCallback(async () => {
    if (isLoadingData) {
      return;
    }
    setIsLoadingData(true);
    setErrorMessage(undefined);
    const { data, error } = await getActivitiesDetails({
      interimDegreeAuthorizedStudentId,
    });

    if (error || !data) {
      if ((error as any)?.data?.statusCode === 404) {
        setErrorMessage(t(`${dictPrefix}.errors.DATA_NOT_FOUND`));
      } else {
        setErrorMessage((error as any)?.data?.message);
      }
    } else {
      setSummary(data);
      reset({
        interimDegreeAverage: `${data.interimDegreeAverage}`,
        grade: data.degreeSummary?.degreeGrade ?? '',
        date: data.degreeSummary?.degreeDate ?? '',
        distinctionRank: data.degreeSummary?.degreeDistinctionRank
          ? {
              value: data.degreeSummary?.degreeDistinctionRank.id,
              label: data.degreeSummary?.degreeDistinctionRank.name,
            }
          : null,
      });
    }
    setIsLoadingData(false);
  }, [t, reset, interimDegreeAuthorizedStudentId, isLoadingData]);

  const handleCreateNewRecord = useCallback(
    (interimDegreeActivity: InterimDegreeActivity) => {
      createRecordRef?.current?.open(
        interimDegreeActivity,
        Number(interimDegreeAuthorizedStudentId),
      );
    },
    [interimDegreeAuthorizedStudentId],
  );

  useEffect(() => {
    if (interimDegreeAuthorizedStudentId && !isMounted) {
      handleData();
      setIsMounted(true);
    }
  }, [handleData, isMounted, interimDegreeAuthorizedStudentId]);

  if (!isAuthorizedTo(MODULE_PERMISSIONS, true)) {
    return (
      <div className="w-full px-3">
        <DisplayPermissionError permissions={MODULE_PERMISSIONS} insideCard />
      </div>
    );
  }

  if (isLoadingData) {
    return (
      <div className="w-full px-3">
        <Loading insideCard />
      </div>
    );
  }

  if (errorMessage?.trim()) {
    return (
      <div className="w-full px-3">
        <DisplayError textBody={errorMessage?.trim()} insideCard />
      </div>
    );
  }

  return (
    <>
      <Card className="p-4 mx-3">
        <Row>
          <Col className="mb-4" xs={12}>
            <div className="d-flex flex-wrap w-100 align-items-start justify-content-center">
              <GoBackToListButton text={t(`${dictPrefix}.back`)} />
              <div className="flex-fill px-2" />
              {!!summary?.summaryBox && (
                <InterimDegressAuthorizedStudentBox {...summary?.summaryBox} />
              )}
            </div>
          </Col>

          <Col className="mb-4" xs={12}>
            <p className="fs-20 text-uppercase text-primary mb-0 fw-700">
              {t(`${dictPrefix}.title`)}
            </p>
            <p className="fs-16 mb-4 mt-3" style={{ maxWidth: 900 }}>
              {t(`${dictPrefix}.description`)}
            </p>
          </Col>
          <Col className="mb-4" xs={12}>
            {!!summary?.activities?.length && (
              <ActivitiesResumenTable
                data={summary?.activities}
                openAddNewRecord={handleCreateNewRecord}
                newRecordEnabled={!!summary?.createNewRecordEnabled}
              />
            )}
            {!summary?.activities?.length && (
              <Alert
                className=""
                color="info"
                icon="information"
                size="lg"
                text={t(`${dictPrefix}.noActivities`)}
              />
            )}
          </Col>
          {!!summary && (
            <FormProvider {...methods}>
              <ActivitiesResumenForm
                authorizedStudentId={interimDegreeAuthorizedStudentId}
                managment={
                  summary.financialBlockade === null ? 'input' : 'integration'
                }
                distinctionRanks={summary.distinctionRanks}
                financialRestriction={!!summary.financialBlockade}
                disabled={!summary.updateEnabled}
                readonly={summary.readonly}
                onComplete={handleData}
              />
            </FormProvider>
          )}
        </Row>
      </Card>
      <CreateStudentActivityModal
        ref={createRecordRef}
        onSuccess={handleData}
      />
    </>
  );
};

export default InterimDegreeGraduationProcessActivitiesResumen;
