import { PaginationRequestType } from '../../types/paginationRequestType';
import { PeriodType } from '../../types/sectionAttributesTypes';
import { SECTION_ATTRIBUTES } from '../endpoints';
import request from '../request';

/**
 * Obtiene los periodos de atributos de secciones
 */
export const getPeriods = (itemsPerPage: number = 10, page: number = 0) => {
  const url = `${SECTION_ATTRIBUTES.GET_ALL_PERIODS}?items_per_page=${itemsPerPage}&page=${page}`;
  return request<PaginationRequestType<PeriodType>>(url, {
    method: 'GET',
  });
};

/**
 * Obtiene un periodo de atributos de secciones por id de periodo
 */
export const getPeriodById = (periodId: number) => {
  const url = `${SECTION_ATTRIBUTES.GET_PERIOD_BY_ID}/${periodId}`;
  return request<PeriodType>(url, {
    method: 'GET',
  });
};

/**
 * Descarga el archivo de información base
 */
export const downloadBaseInfoDocument = (periodId: string | number) => {
  return request(SECTION_ATTRIBUTES.BASE_INFO_DOCUMENT(periodId), {
    responseType: 'blob',
  });
};

/**
 * Carga el archivo de atributos
 */
export const uploadAttributesDocument = (data: any) => {
  const url = SECTION_ATTRIBUTES.UPLOAD_ATTRIBUTES_DOCUMENT;

  return request<any>(url, { method: 'POST', data });
};

/**
 * Descarga el archivo de errores de carga de atributos de secciones
 */
export const downloadErrorDocument = (fileErrorId: number) => {
  const url = `${SECTION_ATTRIBUTES.DOWNLOAD_ERROR_DOCUMENT}/${fileErrorId}`;

  return request<any>(url);
};
