import {
  StudentsDegreeFilterResponse,
  StudentsDegreeResponse,
} from '../../types/studentsDegree';
import { SearchParams } from '../../views/StudentsDegree/types/StudentsDegree';
import { STUDENTS_DEGREE } from '../endpoints';
import request from '../request';

/**
 * Obtiene el listado de los estudiantes egresados o titulados
 * @param items
 * @param page
 * @returns
 */
export function getStudentsDegreeList(
  filter: { items: number; page: number } & SearchParams,
) {
  const URL = STUDENTS_DEGREE.GET_STUDENTS_DEGREE_LIST;
  const method = 'GET';
  const params = { ...filter, items_per_page: filter.items };
  return request<StudentsDegreeResponse>(URL, { method, params });
}

/**
 * Obtiene el listado de los filtros a mostrar para los estudiantes egresados o titulados
 * @returns
 */
export function studentsPetitionsListFilter() {
  const URL = STUDENTS_DEGREE.FILTER_STUDENTS_DEGREE_LIST;
  const method = 'GET';
  return request<StudentsDegreeFilterResponse>(URL, { method });
}

/**
 * Obtiene un archivo excel con la lista de los estudiantes egresados o titulados a partir de los filtros seleccionados
 * @returns
 */
export function downloadPetitionsListByFilter(filter: SearchParams) {
  const method = 'GET';
  return request(STUDENTS_DEGREE.DOWNLOAD_STUDENTS_DEGREE_LIST_BY_FILTER, {
    responseType: 'blob',
    method,
    params: filter,
  });
}
