import { OutlinedSelectOptionType, Select } from '@octano/global-ui';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Container, Row } from 'reactstrap';

import { getPeriodAndSchool } from '../../../api/requests/tuitionContinuityTariff';
import DisplayError from '../../../components/info/DisplayError';
import Loading from '../../../components/info/Loading';
import { useLoadingState } from '../../../hooks/useLoadingState';
import { PeriodAndSchoolResponse } from '../../../types/tuitionContinuityTrariff';
import TcTariffTableLoader from './parts/TcTariffTableLoader';
import TuitionContinuityTariffTable from './parts/TuitionContinuityTariffTable';

export default function TcTariff() {
  const { t } = useTranslation();
  const prefix = 'tuitionContinuityTariff.tariff';
  const {
    loading,
    setLoading,
    errorLoading,
    setErrorLoading,
  } = useLoadingState();
  const [periodAndOffer, setPeriodAnOffer] = useState<PeriodAndSchoolResponse>({
    periods: [],
    offers: [],
  });

  const [selectedOptions, setSelected] = useState<{
    periodId?: number;
    schoolId?: number;
  }>({});
  const loadPeriodAnOffer = useCallback(async () => {
    setLoading(true);
    const { data, error } = await getPeriodAndSchool();
    if (error) {
      setErrorLoading(error.code);
    }
    if (data) {
      setErrorLoading(undefined);
      setPeriodAnOffer(data.data);
    }
    setLoading(false);
  }, [setLoading, setErrorLoading]);

  const options = useMemo(
    () => ({
      periodsOption: periodAndOffer.periods.map<OutlinedSelectOptionType>(
        (p) => ({
          label: p.name,
          value: p.id,
        }),
      ),
      offerOptions: periodAndOffer.offers.map<OutlinedSelectOptionType>(
        (s) => ({ label: s.name, value: s.id }),
      ),
    }),
    [periodAndOffer],
  );

  const handleChangePeriod = useCallback(
    (option: OutlinedSelectOptionType) => {
      setSelected((prevState) => {
        return { ...prevState, periodId: Number(option.value) };
      });
    },
    [setSelected],
  );

  const handleChangeSchool = useCallback(
    (option: OutlinedSelectOptionType) => {
      setSelected((prevState) => {
        return { ...prevState, schoolId: Number(option.value) };
      });
    },
    [setSelected],
  );

  const texts = useMemo(
    () => ({
      select: {
        period: t(`${prefix}.form.selectPeriod`),
        school: t(`${prefix}.form.selectSchool`),
      },
      header: {
        title: t(`${prefix}.title`),
        subtitle: t(`${prefix}.subTitle`),
      },
      error: {
        data: t(`${prefix}.error.data`),
      },
    }),
    [t],
  );

  useEffect(() => {
    loadPeriodAnOffer();
  }, [loadPeriodAnOffer]);
  if (errorLoading) {
    return (
      <DisplayError
        insideCard
        textBody={texts.error.data}
        retryAction={() => loadPeriodAnOffer()}
        loadingAction={loading}
      />
    );
  }
  if (loading) {
    return <Loading insideCard />;
  }

  return (
    <Container fluid className="g-table-container pt-4">
      <p className="fs-20 text-uppercase text-primary fw-700 ">
        {texts.header.title}
      </p>
      <p> {texts.header.subtitle}</p>
      <Row>
        <Col md={6}>
          <Select
            name="selection-period"
            label={texts.select.period}
            isClearable={false}
            options={options.periodsOption}
            onChange={handleChangePeriod}
          />
        </Col>
        <Col md={6}>
          <Select
            name="selection-school"
            label={texts.select.school}
            isClearable={false}
            options={options.offerOptions}
            onChange={handleChangeSchool}
          />
        </Col>
      </Row>
      <br className="mt-4" />
      {selectedOptions.schoolId && selectedOptions.periodId && (
        <TcTariffTableLoader data={selectedOptions}>
          <TuitionContinuityTariffTable periodId={selectedOptions.periodId} />
        </TcTariffTableLoader>
      )}
    </Container>
  );
}
