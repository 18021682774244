import { useTranslation } from 'react-i18next';
import { Col, Row } from 'reactstrap';

import DownloadDocumentButton from '../../components/buttons/DownloadDocumentButton';
import { SECTIONS_ATTRIBUTES_UPLOAD_STRUCTURE_DOCUMENT } from '../../config/constants';
import PeriodsList from '../../sectionAttributes/PeriodsList';

const sectionAttributesStructureFileName = SECTIONS_ATTRIBUTES_UPLOAD_STRUCTURE_DOCUMENT;
const sectionAttributesStructureFileURL = `/tenant/${SECTIONS_ATTRIBUTES_UPLOAD_STRUCTURE_DOCUMENT}`;

const SectionAttributesList = (): JSX.Element => {
  const { t } = useTranslation();
  const prefix = 'sectionAttributes';

  return (
    <div className="g-table-container mt-2 pb-4 pt-4 mx-3">
      <Row className="mb-5">
        <Col lg={7} xl={7}>
          <div className="fs-16">{t(`${prefix}.titleDesc`)}</div>
        </Col>
        <Col lg={5} xl={5}>
          <DownloadDocumentButton
            buttonText={t(`${prefix}.downloadExcelLayout`)}
            userFileName={sectionAttributesStructureFileName}
            fileURL={sectionAttributesStructureFileURL}
            outlined
          />
        </Col>
      </Row>
      <PeriodsList />
    </div>
  );
};

export default SectionAttributesList;
