import {
  Button,
  Tab,
  TabPanel,
  TabPanels,
  Tabs,
  TabsList,
} from '@octano/global-ui';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { Card, Col, Row } from 'reactstrap';

import { PathsLayouts } from '../../config/routes';
import { TuitionContinuityProcessManagementDetailBox } from './parts/TuitionContinuityProcessManagementDetailBox';
import { TuitionContinuityProcessManagementDetailLoader } from './parts/TuitionContinuityProcessManagementDetailLoader';
import TuitionContinuityProcessManagementDetailComplete from './tabs/TuitionContinuityProcessManagementDetailComplete';
import TuitionContinuityProcessManagementDetailNotStarted from './tabs/TuitionContinuityProcessManagementDetailNotStarted';
import TuitionContinuityProcessManagementDetailStarted from './tabs/TuitionContinuityProcessManagementDetailStarted';

export default function TuitionContinuityProcessManagementDetail() {
  const { t } = useTranslation();
  const prefix = 'tuitionContinuityProcessManagementDetail';

  const history = useHistory();
  const { studyPlanVersionOfferId, periodId } = useParams<{
    studyPlanVersionOfferId: string;
    periodId: string;
  }>();

  const texts = useMemo(
    () => ({
      backButton: t(`${prefix}.back`),
      tab: {
        completed: t(`${prefix}.tab.completed`),
        started: t(`${prefix}.tab.started`),
        notStarted: t(`${prefix}.tab.notStarted`),
      },
    }),
    [t],
  );

  const tabs = useMemo<
    {
      name: 'completed' | 'started' | 'notStarted';
      status: string;
    }[]
  >(
    () => [
      {
        name: 'completed',
        status: 'completed',
      },
      {
        name: 'started',
        status: 'started',
      },
      {
        name: 'notStarted',
        status: 'notStarted',
      },
    ],
    [],
  );

  return (
    <div className="student-grades-closure mx-3">
      <Card className="p-4">
        <TuitionContinuityProcessManagementDetailLoader
          studyPlanVersionOfferId={studyPlanVersionOfferId}
          periodId={periodId}
        >
          {(detailData) => (
            <>
              <Tabs defaultTab="completed">
                <TabsList>
                  {tabs.map((tab) => (
                    <Tab
                      key={tab.name}
                      id={tab.name}
                      children={texts.tab[tab.name]}
                    />
                  ))}
                </TabsList>
                <Row className="pt-4 mb-5">
                  <Col>
                    <Button
                      size="sm"
                      icon="back"
                      onClick={() =>
                        history.push(
                          PathsLayouts.tuitionContinuityProcessManagement,
                        )
                      }
                      text={texts.backButton}
                      rounded
                      outlined
                    />
                  </Col>
                  <Col md={8} lg={6} className="py-3 py-md-0 px-3">
                    <TuitionContinuityProcessManagementDetailBox
                      {...detailData}
                    />
                  </Col>
                </Row>
                <TabPanels panelsClassName="p-2">
                  <TabPanel id="completed">
                    <TuitionContinuityProcessManagementDetailComplete
                      studyPlanVersionOfferId={studyPlanVersionOfferId}
                      periodId={periodId}
                    />
                  </TabPanel>
                  <TabPanel id="started">
                    <TuitionContinuityProcessManagementDetailStarted
                      studyPlanVersionOfferId={studyPlanVersionOfferId}
                      periodId={periodId}
                      discounts={detailData.discounts}
                    />
                  </TabPanel>
                  <TabPanel id="notStarted">
                    <TuitionContinuityProcessManagementDetailNotStarted
                      studyPlanVersionOfferId={studyPlanVersionOfferId}
                      periodId={periodId}
                      discounts={detailData.discounts}
                    />
                  </TabPanel>
                </TabPanels>
              </Tabs>
            </>
          )}
        </TuitionContinuityProcessManagementDetailLoader>
      </Card>
    </div>
  );
}
