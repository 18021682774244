import {
  Select,
  SelectOptionType,
  TextInput,
  DateInput,
  CheckInput,
} from '@octano/global-ui';
import { useFormContext } from 'react-hook-form';
import { Col } from 'reactstrap';
import { useValidations } from '../../../../../../../hooks/useValidations';
import { useTranslation } from 'react-i18next';
import { useHiringInformationFormDataLoader } from '../Loaders/HiringInformationFormDataLoader';
import { TeacherAssignmentHourType } from '../../enums/teacher-assignment-hour-type.enum';
import { formatToOnlyPositiveIntegers } from '../../../../../../../utils/currency';

interface Props {
  hoursDataSum?: number;
  hourError?: string;
  endError?: string;
}
export interface FormData {
  campus: SelectOptionType | null;
  studyPlan: SelectOptionType | null;
  teacherContractType: SelectOptionType | null;
  teacherAcademicRank: SelectOptionType | null;
  startAt: string | null;
  endAt: string | null;
  undefinedEnd: boolean;
  hours: {
    [key: string]: number;
  };
}

const dictPrefix = 'teacherFile.hiringInformation.createModal';

const hoursFormsSizes = {
  [TeacherAssignmentHourType.Teaching]: 4,
  [TeacherAssignmentHourType.Research]: 4,
  [TeacherAssignmentHourType.Linking]: 8,
  [TeacherAssignmentHourType.Others]: 4,
};

const CreateOrUpdateForm = ({
  hoursDataSum = 0,
  endError,
  hourError,
}: Props) => {
  const { t } = useTranslation();
  const forms = useFormContext<FormData>();
  const { control, watch } = forms;
  const { data } = useHiringInformationFormDataLoader();
  const { msgValidations, rulesPatternDecimal } = useValidations();

  const undefinedEnd = watch('undefinedEnd');

  return (
    <>
      <Col xs={12} md={4}>
        <Select
          name="teacherContractType"
          options={data?.teacherContractTypes ?? []}
          label={t(`${dictPrefix}.inputs.type.label`)}
          placeholder={t(`${dictPrefix}.inputs.type.placeholder`)}
          control={control}
          rules={{ required: msgValidations.required }}
        />
      </Col>
      <Col xs={12} md={8}>
        <Select
          name="studyPlan"
          options={data?.studyPlans ?? []}
          label={t(`${dictPrefix}.inputs.studyPlan.label`)}
          placeholder={t(`${dictPrefix}.inputs.studyPlan.placeholder`)}
          control={control}
          rules={{ required: msgValidations.required }}
        />
      </Col>
      <Col xs={12} md={6}>
        <Select
          name="teacherAcademicRank"
          options={data?.teacherAcademicRanks ?? []}
          label={t(`${dictPrefix}.inputs.teacherAcademicRank.label`)}
          placeholder={t(
            `${dictPrefix}.inputs.teacherAcademicRank.placeholder`,
          )}
          control={control}
          rules={{ required: msgValidations.required }}
        />
      </Col>
      <Col xs={12} md={6}>
        <Select
          name="campus"
          options={data?.campuses ?? []}
          label={t(`${dictPrefix}.inputs.campus.label`)}
          placeholder={t(`${dictPrefix}.inputs.campus.placeholder`)}
          control={control}
          rules={{ required: msgValidations.required }}
        />
      </Col>
      <Col xs={12} md={6}>
        <DateInput
          name="startAt"
          label={t(`${dictPrefix}.inputs.startAt.label`)}
          control={control}
          maxDate={new Date()}
          rules={{
            required: msgValidations.required,
          }}
        />
      </Col>
      <Col xs={12} md={6}>
        <DateInput
          name="endAt"
          label={t(`${dictPrefix}.inputs.endAt.label`)}
          control={control}
          maxDate={new Date()}
          disabled={undefinedEnd}
          errorText={endError}
        />
        <CheckInput
          control={control}
          name="undefinedEnd"
          label={t(`${dictPrefix}.inputs.undefinedEnd.label`)}
        />
      </Col>

      <Col xs={12}>
        <span className="w-100 text-dark fs-14 mb-2 mt-4 d-block">
          {t(`${dictPrefix}.hoursSection`)}
        </span>
      </Col>

      <Col xs={12} md={4}>
        <TextInput
          name="hours.total"
          value={`${hoursDataSum}`}
          defaultValue={0}
          label={t(`${dictPrefix}.hourTypes.total`)}
          readOnly
          placeholder="0"
          errorText={hourError}
        />
      </Col>

      {Object.entries(hoursFormsSizes).map(([key, value]) => (
        <Col xs={12} md={value} key={key}>
          <TextInput
            name={`hours.${key}`}
            control={control}
            label={t(`${dictPrefix}.hourTypes.${key}`)}
            placeholder="0"
            rules={{
              required: msgValidations.required,
              pattern: rulesPatternDecimal,
            }}
            formatter={formatToOnlyPositiveIntegers}
          />
        </Col>
      ))}
    </>
  );
};

export default CreateOrUpdateForm;
