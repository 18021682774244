import { addToast, Button, Table } from '@octano/global-ui';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { updateGradesEntryConfigValues } from '../../../../api/requests/gradesEntry';
import { GradesEntryConfigUpdatableVariables } from '../../../../types/GradesEntry';
import useGradesEntryConfig from './useGradesEntryConfig';
import useGradesEntryTableColumns from './useGradesEntryTableColumns';

type ConfigValues = { [index: string]: string | null };

// Los nombres de las variables que se mandan
const CONFIG_VALUES = [
  'minimumGrade',
  'maximumGrade',
  'passingGrade',
  'numberDecimalShow',
] as const;

// Las nombres de las variables de config que se reciben son distintas a las que se mandan, hacemos un mapper
const MATCHING_KEYS: { [index: string]: typeof CONFIG_VALUES[number] } = {
  'grades-entry.minimum-grade': 'minimumGrade',
  'grades-entry.maximum-grade': 'maximumGrade',
  'grades-entry.passing-grade': 'passingGrade',
  'grades-entry.number-of-decimals-to-show': 'numberDecimalShow',
};

// Creamos un objeto con todas las variables de configuracion con valores nulos por defecto
const DEFAULT_CONFIG_VALUES = CONFIG_VALUES.reduce(
  (res, curr) => ({ ...res, [curr]: null }),
  {},
);

export default function NotesScaleSection() {
  const { t } = useTranslation();
  const prefix = 'configurations.academics.notesScale';

  const { control, handleSubmit, watch, setValue } = useForm<ConfigValues>({
    defaultValues: DEFAULT_CONFIG_VALUES,
  });

  const {
    configs: fetchedConfigs,
    isLoading,
    refetch: refetchConfigs,
    isRefetching,
  } = useGradesEntryConfig();

  const [isUpdating, setIsUpdating] = useState(false);

  // Pasamos la data de la configuracion que viene de la API al formulario
  const loadFetchedConfigsInForm = useCallback(() => {
    fetchedConfigs?.forEach((config) => {
      setValue(
        MATCHING_KEYS[config.key],
        config.value === null ? null : `${config.value}`,
      );
    });
  }, [fetchedConfigs, setValue]);

  // Cuando llegan las configs las cargamos a la data del formulario
  useEffect(() => {
    if (fetchedConfigs && !isRefetching) loadFetchedConfigsInForm();
  }, [loadFetchedConfigsInForm, fetchedConfigs, isRefetching]);

  const saveConfigurations = async (values: ConfigValues) => {
    setIsUpdating(true);
    const finalValues = Object.entries(values)
      .filter((entry) => entry[1])
      .reduce(
        (values, entry) => ({
          ...values,
          [entry[0]]: +entry[1]!,
        }),
        {},
      );

    const { error } = await updateGradesEntryConfigValues(
      finalValues as GradesEntryConfigUpdatableVariables,
    );
    setIsUpdating(false);

    if (error) {
      addToast({
        icon: 'error',
        text: t(`${prefix}.errorSavingChanges`),
        color: 'danger',
      });
    } else {
      await refetchConfigs();
      addToast({
        icon: 'success',
        text: t(`${prefix}.changesSaved`),
        color: 'success',
      });
    }
  };

  const currConfigValues = watch();

  const showSave = useMemo(() => {
    const emptyVal = fetchedConfigs?.some((fc) => {
      return fc.value === null;
    });

    return emptyVal;
  }, [fetchedConfigs]);

  const COLUMNS = useGradesEntryTableColumns({ formControl: control });
  const TABLE_DATA = CONFIG_VALUES.map((cv) => ({
    key: cv,
    value: currConfigValues[cv],
  }));

  useEffect(() => {
    loadFetchedConfigsInForm();
  }, [fetchedConfigs, loadFetchedConfigsInForm]);

  return (
    <form onSubmit={handleSubmit(saveConfigurations)}>
      <h2 className="text-primary fs-18 fw-600 text-uppercase mb-3">
        {t(`${prefix}.title`)}
      </h2>
      <p className="fs-14 text-medium mb-3">{t(`${prefix}.description`)}</p>
      <div className="text-center">
        <Table
          data={TABLE_DATA}
          columns={COLUMNS}
          isLoadingResults={isLoading}
        />
      </div>
      {showSave && (
        <Button
          type="submit"
          text={t(`${prefix}.saveConfig`)}
          size="md"
          className="float-right mt-5"
          disabled={isLoading || isRefetching || isUpdating}
        />
      )}
    </form>
  );
}
