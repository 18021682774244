import { SectionsScheduleDays } from '../types/sectionSchedules';

export default function dayToNumber(day: SectionsScheduleDays): number {
  const days: Record<SectionsScheduleDays, number> = {
    [SectionsScheduleDays.Monday]: 0,
    [SectionsScheduleDays.Tuesday]: 1,
    [SectionsScheduleDays.Wednesday]: 2,
    [SectionsScheduleDays.Thursday]: 3,
    [SectionsScheduleDays.Friday]: 4,
    [SectionsScheduleDays.Saturday]: 5,
    [SectionsScheduleDays.Sunday]: 6,
  };
  return days[day] ?? 7;
}
