import { ColumnTable } from '@octano/global-ui';

import { useTranslation } from 'react-i18next';

const dictPrefix = 'teacherFile.hiringInformation';

export default function useSectionsColumns(): ColumnTable[] {
  const { t } = useTranslation();

  return [
    {
      columnName: 'period',
      headerText: t(`${dictPrefix}.sectionsTable.period`),
      tdClassName: 'text-center',
      thClassName: 'text-center',
    },
    {
      columnName: 'code',
      headerText: t(`${dictPrefix}.sectionsTable.code`),
      tdClassName: 'text-center',
      thClassName: 'text-center',
    },
    {
      columnName: 'course',
      headerText: t(`${dictPrefix}.sectionsTable.course`),
      tdClassName: 'text-center',
      thClassName: 'text-center',
    },
    {
      columnName: 'campus',
      headerText: t(`${dictPrefix}.sectionsTable.campus`),
      tdClassName: 'text-center',
      thClassName: 'text-center',
    },
    {
      columnName: 'school',
      headerText: t(`${dictPrefix}.sectionsTable.school`),
      tdClassName: 'text-center',
      thClassName: 'text-center',
    },
  ];
}
