import request from '../../../../../api/request';
import {
  TeacherAdditionalDocumentResponse,
  CreateTeacherAdditionalDocumentRequest,
  UpdateTeacherAdditionalDocumentRequest,
} from './type';

const ADDITIONAL_DOCUMENTS = {
  CREATE_DOCUMENT:
    '/maintainers-portal/teacher-management/teacher-file/additional-documents/documents',
  GET_DOCUMENTS: (id: string) =>
    `/maintainers-portal/teacher-management/teacher-file/additional-documents/documents/${id}`,
  URL_DOCUMENT: (id: string) =>
    `/maintainers-portal/teacher-management/teacher-file/additional-documents/documents-url/${id}`,
  UPDATE_DOCUMENT: (id: string) =>
    `/maintainers-portal/teacher-management/teacher-file/additional-documents/documents-update/${id}`,
  DELETE_DOCUMENT: (id: string) =>
    `/maintainers-portal/teacher-management/teacher-file/additional-documents/documents/${id}`,
};

export async function getDocuments({ teacherId }: { teacherId: string }) {
  return request<TeacherAdditionalDocumentResponse>(
    ADDITIONAL_DOCUMENTS.GET_DOCUMENTS(teacherId),
  );
}

export async function createDocument(
  data: CreateTeacherAdditionalDocumentRequest,
) {
  const formData = new FormData();
  Object.entries(data).forEach(([key, value]: [string, any]) => {
    if (value instanceof File) formData.append(key, value, value.name);
    else if (value) {
      formData.append(key, value);
    }
  });
  return request<void>(ADDITIONAL_DOCUMENTS.CREATE_DOCUMENT, {
    method: 'POST',
    data: formData,
  });
}

export async function updateDocument({
  id,
  ...data
}: UpdateTeacherAdditionalDocumentRequest) {
  const formData = new FormData();
  Object.entries(data).forEach(([key, value]: [string, any]) => {
    if (value instanceof File) formData.append(key, value, value.name);
    else if (value) {
      formData.append(key, value);
    }
  });
  return request<void>(ADDITIONAL_DOCUMENTS.UPDATE_DOCUMENT(`${id}`), {
    method: 'POST',
    data: formData,
  });
}

export async function deleteDocument(documentId: string | number) {
  return request<void>(ADDITIONAL_DOCUMENTS.DELETE_DOCUMENT(`${documentId}`), {
    method: 'DELETE',
  });
}
export async function documentURL(documentId: string | number) {
  return request<{ url: string; name: string }>(
    ADDITIONAL_DOCUMENTS.URL_DOCUMENT(`${documentId}`),
  );
}
