import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { useStepState } from '../../../../../components/step/useStepState';
import { STATUS_POSTULATION } from '../../../../../types/tuitionProcessOnSite';
import { TuitionProcessNoSuaStepsParams } from '../interfaces';
import TuitionProcessNoSuaCompleted from '../steps/TuitionProcessNoSuaCompleted';
import DocumentationNoSua from '../steps/documentation/DocumentationNoSua';
import PaymentStepNoSuaStep from '../steps/payment';
import SelectCareer from '../steps/selectCareer/SelectCareer';
import StudentFileNoSuaStep from '../steps/studentFile';
import SustainerFileNoSuaStep from '../steps/sustainerFile';
import Tariff from '../steps/tariff';

export enum STEP_NAME {
  SELECT_CAREER = 'SELECT_CAREER',
  STUDENT_FILE = 'STUDENT_FILE',
  SUSTAINER = 'SUSTAINER',
  SIGN = 'SIGN',
  TARIFF = 'TARIFF',
  PAYMENT = 'PAYMENT',
  COMPLETED = 'COMPLETED',
}
export const useTuitionProcessNoSuaSteps = (statusPostulation: string) => {
  const { t } = useTranslation();
  const prefix = 'tuitionProcessNoSua';
  const { currentStep } = useStepState();
  const { fromStart } = useParams<TuitionProcessNoSuaStepsParams>();

  /**
   * Contiene todos los pasos disponibles para el proceso de matrícula
   */
  const steps = useMemo(
    () => ({
      [STEP_NAME.SELECT_CAREER]: {
        id: 'study-plan-selection',
        name: t(`${prefix}.steps.selectCareer`),
        component: SelectCareer,
      },
      [STEP_NAME.STUDENT_FILE]: {
        id: 'student-file',
        name: t(`${prefix}.steps.studentFile`),
        component: StudentFileNoSuaStep,
      },
      [STEP_NAME.SUSTAINER]: {
        id: 'sustainer',
        name: t(`${prefix}.steps.tuitionPayer`),
        component: SustainerFileNoSuaStep,
      },
      [STEP_NAME.TARIFF]: {
        id: 'tariff',
        name: t(`${prefix}.steps.tariffQuotas`),
        component: Tariff,
      },
      [STEP_NAME.SIGN]: {
        id: 'sign-documentation',
        name: t(`${prefix}.steps.sign`),
        component: DocumentationNoSua,
      },
      [STEP_NAME.PAYMENT]: {
        id: 'payment',
        name: t(`${prefix}.steps.payment`),
        component: PaymentStepNoSuaStep,
      },
      [STEP_NAME.COMPLETED]: {
        id: 'completed',
        name: t(`${prefix}.steps.completed`),
        component: TuitionProcessNoSuaCompleted,
      },
    }),
    [t],
  );

  /**
   * Contiene los pasos del proceso de matrícula SIN SUA
   * Si el alumno está cambiando de carrera luego de matricularse
   * (statusPostulation === 'ENROLLED') se despliegan menos pasos del proceso
   */
  const stepsTuitionProcess = useMemo(() => {
    if (
      statusPostulation === STATUS_POSTULATION.STUDY_PLAN_CHANGE ||
      fromStart === 'true'
    ) {
      return [
        steps[STEP_NAME.SELECT_CAREER],
        steps[STEP_NAME.TARIFF],
        steps[STEP_NAME.SIGN],
        steps[STEP_NAME.PAYMENT],
        steps[STEP_NAME.COMPLETED],
      ];
    }
    return [
      steps[STEP_NAME.SELECT_CAREER],
      steps[STEP_NAME.STUDENT_FILE],
      steps[STEP_NAME.SUSTAINER],
      steps[STEP_NAME.TARIFF],
      steps[STEP_NAME.SIGN],
      steps[STEP_NAME.PAYMENT],
      steps[STEP_NAME.COMPLETED],
    ];
  }, [fromStart, statusPostulation, steps]);

  /**
   * Contiene dos arrays con las partes de los pasos
   * (Un array con los nombres de los pasos y
   * un array con los componentes asociados a cada paso)
   */
  const availableParts = useMemo(() => {
    const namesSteps: string[] = [];
    const componentsSteps: (React.FunctionComponent | undefined)[] = [];
    stepsTuitionProcess.forEach((step) => {
      namesSteps.push(step.name);
      componentsSteps.push(step.component);
    });
    return { namesSteps: namesSteps.slice(0, -1), componentsSteps };
  }, [stepsTuitionProcess]);

  const getIndexStep = useCallback(
    (id: string) => {
      return stepsTuitionProcess.findIndex((step) => step.id === id);
    },
    [stepsTuitionProcess],
  );

  /**
   * El paso actual en base a la variable currentStep
   */
  const CurrentStepComponent = useMemo(() => {
    return availableParts.componentsSteps[currentStep];
  }, [currentStep, availableParts]);

  return {
    stepsTuitionProcess,
    ...availableParts,
    CurrentStepComponent,
    getIndexStep,
  };
};
