import { useMemo } from 'react';
import { useParams } from 'react-router';
import { validate } from 'rut.js';

import { onSearch } from '../types';

const useGetRutOrPassport = () => {
  const { id } = useParams<{ id?: string }>();

  const showPassport = useMemo<boolean>(() => {
    if (!id) {
      return false;
    }
    return id.split('|').length === 2;
  }, [id]);

  const queryParams = useMemo<onSearch | null>(() => {
    if (!id) {
      return null;
    }
    const isRut = validate(id);
    if (isRut) {
      return {
        rut: id,
      };
    }
    const passport = id.split('|');
    if (passport.length !== 2) {
      return {
        rut: id,
      };
    }
    return {
      country: passport[0],
      passport: passport[1],
    };
  }, [id]);

  return {
    showPassport,
    queryParams,
  };
};

export default useGetRutOrPassport;
