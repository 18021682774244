interface IPaymentDetailsItemProps {
  title: string;
  value: string;
}

export default function PaymentDetailsItem({
  title,
  value,
}: IPaymentDetailsItemProps) {
  return (
    <div className="w-100">
      <span className="w-100 d-block text-primary font-weight-bold fs-14 text-uppercase">
        {title}
      </span>
      <span className="w-100 d-block text-primary fs-14 text-capitalize">
        {value}
      </span>
    </div>
  );
}
