import { Select, SelectOptionType, TextInput } from '@octano/global-ui';
import { Fragment, ReactChild, useCallback } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Col, Form, Row } from 'reactstrap';

import {
  AcademicLevelListItem,
  SchoolsListItem,
} from '../../../../api/requests/schools';
import { SectionTitle } from '../../../../components/text';
import { useLayoutState } from '../../../../hooks/useLayoutState';
import { useValidations } from '../../../../hooks/useValidations';
import { ModalityListItem } from '../../../../types/ModalityTypes';
import { PeriodType } from '../../../../types/PeriodType';

export interface StudyPlanValues {
  code: string;
  name: string;
  school: SelectOptionType | null;
  modality: SelectOptionType | null;
  periodType: SelectOptionType | null;
  academicLevel: SelectOptionType | null;
}

export interface RecordToEditBody {
  code: string;
  name: string;
  school: SelectOptionType;
  modality: SelectOptionType;
  periodType: SelectOptionType;
  academicLevel: SelectOptionType;
}

const getDefaultValues = (defaultValues?: RecordToEditBody) =>
  defaultValues
    ? {
        code: defaultValues.code,
        name: defaultValues.name,
        school: defaultValues.school,
        modality: defaultValues.modality,
        periodType: defaultValues.periodType,
        academicLevel: defaultValues.academicLevel,
      }
    : {
        code: '',
        name: '',
        school: null,
        modality: null,
        periodType: null,
        academicLevel: null,
      };

export interface StudyPlanFormProps {
  modalities: ModalityListItem[];
  periodTypes: PeriodType[];
  schools: SchoolsListItem[];
  academicLevels: AcademicLevelListItem[];
  defaultValues?: RecordToEditBody;
  children: (props: { isSubmitting: boolean; isDirty?: boolean }) => ReactChild;
  onSubmit: (
    values: StudyPlanValues,
  ) => Promise<'CONNECTION' | 'HTTP_ERROR' | 'NOT_UNIQUE_CODE' | undefined>;
  readOnly?: boolean;
  isUpdate?: boolean;
}

const StudyPlanForm = (props: StudyPlanFormProps) => {
  const {
    onSubmit: onSubmitProp,
    children,
    modalities,
    periodTypes,
    schools,
    academicLevels,
    defaultValues,
    readOnly,
    isUpdate,
  } = props;

  const { showErrorModal } = useLayoutState();

  const { t } = useTranslation();
  const prefix = 'studyPlans.form';

  const methods = useForm<StudyPlanValues>({
    mode: 'onSubmit',
    defaultValues: getDefaultValues(defaultValues),
  });
  const {
    handleSubmit,
    formState: { isSubmitting, isDirty },
    control,
    setError,
  } = methods;

  const { msgValidations } = useValidations();
  const onSubmit = useCallback(
    async (formValues: StudyPlanValues) => {
      const response = await onSubmitProp(formValues);
      if (response === 'CONNECTION') {
        showErrorModal();
      } else if (response === 'HTTP_ERROR') {
        setError('code', {
          message: t('common.errors.codeError', { code: formValues.code }),
        });
      }
    },
    [onSubmitProp, showErrorModal, setError, t],
  );

  return (
    <Fragment>
      <FormProvider {...methods}>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Row>
            <Col xs={12}>
              <SectionTitle text={t(`${prefix}.data`)} />
              <Row>
                <Col xs={12} md={6}>
                  <div className="pb-3">
                    <TextInput
                      name="code"
                      label={t(`${prefix}.planCode`)}
                      control={control}
                      rules={{
                        required: msgValidations.required,
                        maxLength: {
                          value: 50,
                          message: t('common.validations.maxLength', {
                            length: 50,
                          }),
                        },
                        pattern: {
                          value: /^[0-9a-zA-Z]+$/,
                          message: t('common.validations.invalidAlphanumeric'),
                        },
                      }}
                      disabled={readOnly}
                    />
                  </div>
                </Col>
                <Col xs={12} md={6} className="pb-3">
                  <TextInput
                    name="name"
                    label={t('common.forms.name')}
                    control={control}
                    rules={{
                      required: msgValidations.required,
                      minLength: {
                        value: 5,
                        message: t('common.validations.minLength', {
                          length: 5,
                        }),
                      },
                      maxLength: {
                        value: 255,
                        message: t('common.validations.maxLength', {
                          length: 255,
                        }),
                      },
                    }}
                    disabled={readOnly}
                  />
                </Col>
              </Row>
              <Row>
                <Col xs={12} md={6} className="pb-3">
                  <Select
                    name="school"
                    label={t('common.forms.school')}
                    options={schools.map((e) => ({
                      label: e.name,
                      value: e.id,
                    }))}
                    control={control}
                    rules={{ required: msgValidations.required }}
                    disabled={readOnly}
                  />
                </Col>
                <Col xs={12} md={6}>
                  <Select
                    name="modality"
                    label={t('common.forms.modality')}
                    options={modalities.map((e) => ({
                      label: e.name,
                      value: e.id,
                    }))}
                    control={control}
                    rules={{ required: msgValidations.required }}
                    disabled={readOnly}
                  />
                </Col>
                <Col xs={12} md={6}>
                  <Select
                    name="periodType"
                    label={t('common.forms.periodType')}
                    options={periodTypes.map((e) => ({
                      label: e.name,
                      value: e.id,
                    }))}
                    control={control}
                    rules={{ required: msgValidations.required }}
                    disabled={readOnly || isUpdate}
                  />
                </Col>
                <Col xs={12} md={6}>
                  <Select
                    name="academicLevel"
                    label={t('common.forms.academicLevel')}
                    options={academicLevels.map((e) => ({
                      label: e.name,
                      value: e.id,
                    }))}
                    control={control}
                    rules={{ required: msgValidations.required }}
                    disabled={readOnly}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
          {children({ isSubmitting, isDirty })}
        </Form>
      </FormProvider>
    </Fragment>
  );
};

export default StudyPlanForm;
