import {
  Button,
  CellFormatOptions,
  ColumnTable,
  PaginationType,
  Table,
} from '@octano/global-ui';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';

import { PathsLayouts } from '../../config/routes';
import { PeriodType } from '../../types/sectionAttributesTypes';
import { formatDate } from '../../utils/dates';

export interface PeriodsTableProps {
  periods: PeriodType[];
  pagination?: PaginationType;
  loading?: boolean;
}

const LOAD_COLORS = {
  EMPTY: 'red',
  SOME: 'blue',
  FULL: 'green',
};

const PeriodsTable = ({ periods, pagination, loading }: PeriodsTableProps) => {
  const { t } = useTranslation();
  const prefix = 'sectionAttributes.table';
  const history = useHistory();
  const { periodId } = useParams<{ periodId?: string }>();

  const getLoadColor = useCallback((loadPercentage: number) => {
    if (loadPercentage === 0) return LOAD_COLORS.EMPTY;
    if (loadPercentage === 100) return LOAD_COLORS.FULL;
    return LOAD_COLORS.SOME;
  }, []);

  const getButtonName = useCallback(
    (loadPercentage: number) => {
      if (loadPercentage === 0) return t(`${prefix}.loadButton`);
      if (loadPercentage === 100) return t(`${prefix}.viewButton`);
      return t(`${prefix}.editButton`);
    },
    [t],
  );

  const goToPeriodDetails = useCallback(
    (id: number) => history.push(`${PathsLayouts.sectionAttributes}/${id}`),
    [history],
  );

  const columns = useMemo<ColumnTable<PeriodType>[]>(() => {
    const defaultColumns = [
      {
        columnName: 'name',
        headerText: t(`${prefix}.period`),
      },
      {
        columnName: 'total',
        headerText: t(`${prefix}.nSections`),
      },
      {
        columnName: 'withSchedule',
        headerText: t(`${prefix}.nWithSchedule`),
      },
      {
        columnName: 'lastLoadDate',
        headerText: t(`${prefix}.lastLoadDate`),
        cellFormat: (options: CellFormatOptions<PeriodType>) =>
          formatDate(options.row.lastLoadDate || '', 'DD/MM/YYYY'),
      },
      {
        columnName: 'loadingDeadline',
        headerText: t(`${prefix}.endLoadDate`),
        cellFormat: (options: CellFormatOptions<PeriodType>) =>
          formatDate(options.row.loadingDeadline || '', 'DD/MM/YYYY'),
      },
      {
        columnName: 'loadPercentage',
        headerText: t(`${prefix}.loadPercentage`),
        cellFormat: (options: CellFormatOptions<PeriodType>) => {
          const color = getLoadColor(options.row.loadPercentage);
          return (
            <div className={`text-center text-${color}`}>
              {Math.floor(options.row.loadPercentage)}%
            </div>
          );
        },
      },
    ];
    const listColumns = [
      {
        width: '16%',
        columnName: 'id',
        headerText: t(`${prefix}.action`),
        cellFormat: (options: CellFormatOptions<PeriodType>) => (
          <Button
            size="sm"
            fullwidth
            color="primary"
            text={getButtonName(options.row.loadPercentage)}
            onClick={() => goToPeriodDetails(options.row.periodId)}
          />
        ),
      },
    ];
    return periodId ? defaultColumns : [...defaultColumns, ...listColumns];
  }, [periodId, getButtonName, getLoadColor, goToPeriodDetails, t]);

  return (
    <Table
      columns={columns}
      borderless
      isLoadingResults={loading}
      data={periods}
      noResultsText={t(`${prefix}.noPeriodsBody`)}
      pagination={pagination}
    />
  );
};

export default PeriodsTable;
