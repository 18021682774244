import { ColumnTable, Table } from '@octano/global-ui';
import { useTranslation } from 'react-i18next';

import { StudentSummary } from '../../../types/graduationProcessSingle';

const dictPrefix =
  'graduationProcessSinglePendingRequirerments.studentDataTable';

export type StudentSummaryTableRow = Pick<
  StudentSummary,
  'studentName' | 'studentId' | 'studyPlanName'
>;

interface Props {
  data: StudentSummaryTableRow[];
}

export function StudentDataTable({ data }: Props) {
  const { t } = useTranslation();

  const columns: ColumnTable<StudentSummaryTableRow>[] = [
    {
      headerText: t(`${dictPrefix}.name`),
      columnName: 'studentName',
      thClassName: 'text-center',
      tdClassName: 'text-center',
    },
    {
      headerText: t(`${dictPrefix}.rut`),
      columnName: 'studentId',
      thClassName: 'text-center',
      tdClassName: 'text-center',
    },
    {
      headerText: t(`${dictPrefix}.studyPlan`),
      columnName: 'studyPlanName',
      thClassName: 'text-center',
      tdClassName: 'text-center',
    },
  ];
  return <Table data={data} columns={columns} />;
}
