import { CURRICULUM_PROGRESS } from '../../api/endpoints';
import request from '../../api/request';
import { CurriculumProgressBase } from './types/progress';

export function getCurriculumProgress(studyPlanEnrollmentId: number | string) {
  const url = CURRICULUM_PROGRESS.STUDENT(studyPlanEnrollmentId);
  return request<CurriculumProgressBase>(url, {
    method: 'get',
  });
}

export function getCourseHistoryPdf(studyPlanEnrollmentId: number) {
  const url = CURRICULUM_PROGRESS.HISTORY_DOWNLOAD(studyPlanEnrollmentId);
  return request<any>(url, {
    method: 'GET',
    responseType: 'blob',
  });
}
