import {
  Tab,
  TabPanel,
  TabPanels,
  Tabs,
  TabsList,
  addToast,
} from '@octano/global-ui';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Card, Col, Row } from 'reactstrap';

import { SearchParams } from '../../api/requests/Filters';
import { getSectionByPeriodIDAndState } from '../../api/requests/MantainerPortal';
import { BaseLoader as PeriodsLoader } from './parts/PeriodsLoader';
import { SearchControls } from './parts/SearchControls';
import TableClosingPeriod from './parts/Table';

type SectionStateEnum = 'open' | 'closed_with_pendind' | 'closed';
const sectionState: Record<SectionStateEnum, string> = {
  open: 'open',
  closed_with_pendind: 'closed_with_pendind',
  closed: 'closed',
};

export const ITEMS_PER_PAGE_DEFAULT = 10;

export default function ClosingPeriod() {
  const { t } = useTranslation();

  const [state, setState] = useState<SectionStateEnum>('open');
  const [isLoadingTable, setIsTableLoading] = useState(false);
  const [tableData, setTableData] = useState<any[]>([]);
  const [totalItems, setTotalItems] = useState<number>(0);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [filters, setFilters] = useState<SearchParams>({});

  const onChangeState = useCallback((tab) => {
    setState(tab as SectionStateEnum);
    setCurrentPage(0);
    return null;
  }, []);

  const handleSearch = useCallback(
    async ({
      searchParams,
      page = 0,
    }: {
      searchParams?: SearchParams;
      page: number;
    }) => {
      setIsTableLoading(true);
      const { data, error } = await getSectionByPeriodIDAndState({
        page,
        itemsPerPage: ITEMS_PER_PAGE_DEFAULT,
        state,
        periodId: searchParams?.periodId,
        schoolId: searchParams?.schoolId,
        campusId: searchParams?.campusId,
        searchSection: searchParams?.searchSection,
        hasFilters: searchParams?.hasFilters,
      });
      if (error) {
        addToast({
          text: t(`closingPeriod.errorOnSearch`),
          color: 'danger',
          icon: 'error',
        });
      } else if (data) {
        setTableData(data.data ?? []);
        setTotalItems(data.total ?? 0);
        setTotalPages(data.total_pages ?? 0);
        page === 0 && setCurrentPage(page);
      }
      setIsTableLoading(false);
    },
    [t, state],
  );

  const handleClear = async () => {
    setTableData([]);
    setFilters({});
    setCurrentPage(0);
  };

  useEffect(() => {
    handleSearch({ searchParams: filters, page: currentPage });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handleSearch, currentPage]);

  return (
    <PeriodsLoader>
      {({ data }) => (
        <Card className="p-4 mt-2 mx-3">
          <br className="mb-4" />
          <Row>
            <Col md={12}>
              <SearchControls
                periods={data.periods}
                currentPeriod={data.currentPeriod}
                onSearch={handleSearch}
                onClear={handleClear}
                setFilters={setFilters}
              />
            </Col>
          </Row>

          <br className="mt-4" />
          <Tabs size="md" defaultTab={state} onSelection={onChangeState}>
            <TabsList>
              <Tab id={sectionState.open}>{t('closingPeriod.state.open')}</Tab>
              <Tab id={sectionState.closed_with_pendind}>
                {t('closingPeriod.state.closed_with_pendind')}
              </Tab>
              <Tab id={sectionState.closed}>
                {t('closingPeriod.state.closed')}
              </Tab>
            </TabsList>

            <br className="mt-4" />
            <TabPanels>
              <TabPanel id={sectionState.open}>
                <br className="mt-4" />
                <TableClosingPeriod
                  state={sectionState.open}
                  isLoadingResults={isLoadingTable}
                  data={tableData}
                  totalPages={totalPages}
                  totalItems={totalItems}
                  currentPage={currentPage}
                  onChangePage={(page) => {
                    setCurrentPage(page);
                  }}
                  hasFilters={filters?.hasFilters}
                />
              </TabPanel>

              <TabPanel id="closed_with_pendind">
                <br className="mt-4" />
                <TableClosingPeriod
                  state={sectionState.closed_with_pendind}
                  isLoadingResults={isLoadingTable}
                  data={tableData}
                  totalPages={totalPages}
                  totalItems={totalItems}
                  currentPage={currentPage}
                  onChangePage={(page) => {
                    setCurrentPage(page);
                  }}
                  hasFilters={filters?.hasFilters}
                />
              </TabPanel>

              <TabPanel id="closed">
                <br className="mt-4" />
                <TableClosingPeriod
                  state={sectionState.closed}
                  isLoadingResults={isLoadingTable}
                  data={tableData}
                  totalPages={totalPages}
                  totalItems={totalItems}
                  currentPage={currentPage}
                  onChangePage={(page) => {
                    setCurrentPage(page);
                  }}
                  hasFilters={filters?.hasFilters}
                />
              </TabPanel>
            </TabPanels>
          </Tabs>
        </Card>
      )}
    </PeriodsLoader>
  );
}
