import { ColumnTable } from '@octano/global-ui';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import BadgeState from '../../StudentsDegreeStudent/parts/BadgeState';
import { InterimDegreeStudentActivityRow } from '../interfaces/interim-degree-authorized-student-activities-details.interface';

const prefix = 'interimDegreeGraduationProcess.activities';

export function useActivitiesResumenColumns() {
  const { t } = useTranslation();

  const columns: ColumnTable<InterimDegreeStudentActivityRow>[] =
    useMemo(() => {
      return [
        {
          columnName: 'code',
          width: '40px',
          thClassName: 'text-center',
          tdClassName: 'text-center',
          headerText: t(`${prefix}.table.code`),
        },
        {
          columnName: 'name',
          width: '15%',
          thClassName: 'text-center',
          tdClassName: 'text-center',
          headerText: t(`${prefix}.table.activity`),
        },
        {
          columnName: 'description',
          width: '25%',
          thClassName: 'text-center',
          tdClassName: 'text-center',
          headerText: t(`${prefix}.table.description`),
        },
        {
          columnName: 'status',
          width: '40px',
          thClassName: 'text-center',
          tdClassName: 'text-center',
          headerText: t(`${prefix}.table.status`),
          cellFormat: ({ value }) => {
            if (!value) {
              return (
                <BadgeState
                  status="pending"
                  text={t(`${prefix}.statuses.pending`)}
                />
              );
            }
            return (
              <BadgeState
                status={value}
                text={t(`${prefix}.statuses.${value}`)}
              />
            );
          },
        },
        {
          columnName: 'grade',
          width: '40px',
          thClassName: 'text-center',
          tdClassName: 'text-center',
          headerText: t(`${prefix}.table.grade`),
          cellFormat: ({ value, row }) =>
            !row.hasQualification ? 'N/A' : value ?? '-',
        },
        {
          columnName: 'maxGrade',
          width: '40px',
          thClassName: 'text-center',
          tdClassName: 'text-center',
          headerText: t(`${prefix}.table.maxGrade`),
          cellFormat: ({ value, row }) =>
            !row.hasQualification ? 'N/A' : value ?? '-',
        },
      ];
    }, [t]);

  return columns;
}
