import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Card } from 'reactstrap';

import LoadingModal from '../../components/modals/LoadingModal';
import DownloadBase from './parts/DownloadBase';
import DownloadTemplate from './parts/DownloadTemplate';
import Table from './parts/Table';
import Upload from './parts/Upload';

export default function AcademicRecord() {
  const prefix = 'academicRecord';
  const { t } = useTranslation();
  const texts = useMemo(
    () => ({
      title: t(`${prefix}.title`),
      body: t(`${prefix}.body`),
      loadingModal: {
        download: {
          title: t(`${prefix}.loadingModal.download.title`),
          description: t(`${prefix}.loadingModal.download.description`),
        },
        upload: {
          title: t(`${prefix}.loadingModal.upload.title`),
          description: t(`${prefix}.loadingModal.upload.description`),
        },
      },
    }),
    [t],
  );

  const [modal, setModal] = useState<{
    title: string;
    description: string;
  } | null>(null);

  const beforeDownload = () => {
    setModal({
      title: texts.loadingModal.download.title,
      description: texts.loadingModal.download.description,
    });
  };

  const beforeUpload = () => {
    setModal({
      title: texts.loadingModal.upload.title,
      description: texts.loadingModal.upload.description,
    });
  };

  return (
    <>
      <Card className="p-4 mt-2 mx-3">
        <h1 className="fs-22 text-primary fw-700">{texts.title}</h1>
        <p className="text-medium fs-16 mt-3 mb-5">{texts.body}</p>
        <Table>
          <div className="d-inline-flex" style={{ gap: 8 }}>
            <DownloadBase
              beforeDownload={beforeDownload}
              afterDownload={() => setModal(null)}
            />
            <DownloadTemplate
              beforeDownload={beforeDownload}
              afterDownload={() => setModal(null)}
            />
            <Upload
              beforeUpload={beforeUpload}
              afterUpload={() => setModal(null)}
            />
          </div>
        </Table>
      </Card>
      {modal && (
        <LoadingModal
          isOpen={Boolean(modal)}
          title={modal.title}
          description={modal.description}
        />
      )}
    </>
  );
}
