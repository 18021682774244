import { Button, PaginationType } from '@octano/global-ui';
import { useMemo } from 'react';
import { useHistory } from 'react-router';
import { Card } from 'reactstrap';
import { useAdminRoleLoader } from './parts/AdminRoleLoader';
import RoleTable from './parts/RoleTable';
import { ITEMS_PER_PAGE_DEFAULT } from '../types/Roles';

export default function RoleList() {
  const { error, loading, data, query, updateQuery } = useAdminRoleLoader();
  const history = useHistory();

  const paginationTable = useMemo<PaginationType | undefined>(() => {
    if (data) {
      return {
        totalItems: data.total,
        onChangePage: (page) => updateQuery({ ...query, page }),
        itemsPerPage: ITEMS_PER_PAGE_DEFAULT,
        totalPages: data.total_pages,
        currentPage: query.page,
      };
    } else return undefined;
  }, [data, query, updateQuery]);

  return (
    <Card className="mx-3 p-4">
      <div className="ml-auto my-4">
        <Button
          icon="plus"
          text="Crear nuevo rol"
          color="primary"
          size="sm"
          onClick={() => {
            history.push(`/users-and-roles/roles/create`);
          }}
        />
      </div>

      <RoleTable
        isLoadingResults={loading}
        data={data?.data ?? []}
        pagination={paginationTable}
        hasError={Boolean(error)}
        hasFilters={Object.values(query).some((value) => value !== null)}
      />
    </Card>
  );
}
