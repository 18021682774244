import { useCallback } from 'react';
import { updateGradingStructure } from '../api';
import { FormDataAction } from '../interfaces/type';

export interface UseActions {
  updateTitle: (degreedId: number, data: FormDataAction) => Promise<void>;
}
type ActionType = 'create' | 'update';

export default function useActions(props: {
  onSuccess: (action: ActionType) => void;
  onError: (action: ActionType, message?: string) => void;
}) {
  const { onSuccess, onError } = props;

  const updateAction = useCallback(
    async (degreedId: number, data: FormDataAction) => {
      const result = await updateGradingStructure(degreedId, data);
      if (!result.error) {
        return onSuccess('update');
      }
      onError('update');
    },
    [onSuccess, onError],
  );

  return {
    update: updateAction,
  };
}
