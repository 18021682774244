import { ReactElement } from 'react';
import { useParams } from 'react-router-dom';

export function AdmissionsListParamId({
  children,
}: {
  children: (id: number) => ReactElement;
}) {
  const { id: rawId } = useParams<{ id: string }>();

  if (!rawId) {
    return <>No hay academicOfferStudyPlanVersionOffersRawId</>;
  }

  const id = Number(rawId);

  if (isNaN(id)) {
    return <>No es un número el academicOfferStudyPlanVersionOffersRawId</>;
  }

  return children(id);
}
