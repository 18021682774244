import { TimeModuleType } from '../types/timeModuleTypes';

export function formatTime(time: string) {
  return time.length === 8 ? time.slice(0, 5) : time;
}

// Función para mostrar la hora de inicio y fin de una clase
// Ej: 10:00 - 12:00
export function formatClassSchedule(
  startModule: TimeModuleType,
  endModule: TimeModuleType | null,
) {
  return `${formatTime(startModule.startTime)} -
    ${formatTime(endModule ? endModule.endTime : startModule.endTime)}`;
}
