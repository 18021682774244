import {
  TuitionProcessAction,
  TuitionProcessActionTypes,
  TuitionProcessState,
} from '../types/tuitionProcessTypes';

export const initialState: TuitionProcessState = {
  postulant: null,
  statusPostulation: '',
  postulationDetail: null,
  newPostulationDetail: null,
  tuitionInfo: null,
};

const tuitionProcessReducer = (
  state: TuitionProcessState,
  action: TuitionProcessAction,
): TuitionProcessState => {
  switch (action.type) {
    case TuitionProcessActionTypes.SET_POSTULANT:
      return {
        ...state,
        postulant: action.payload,
      };

    case TuitionProcessActionTypes.SET_STATUS:
      return {
        ...state,
        statusPostulation: action.payload,
      };

    case TuitionProcessActionTypes.SET_POSTULATION_DETAIL:
      return {
        ...state,
        postulationDetail: action.payload,
      };

    case TuitionProcessActionTypes.SET_NEW_POSTULATION_DETAIL:
      return {
        ...state,
        newPostulationDetail: action.payload,
      };

    case TuitionProcessActionTypes.SET_TUITION_INFO:
      return {
        ...state,
        tuitionInfo: action.payload,
      };

    default:
      return state;
  }
};

export default tuitionProcessReducer;
