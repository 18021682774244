import { ReactNode, useCallback } from 'react';

import { getTuitionContinuityProcessManagementDetailStudents } from '../../../api/requests/tuitionContinuityProcessManagement';
import FetchProvider from '../../../components/contexts/FetchContext';

export interface TuitionContinuityProcessManagementDetailStudentsLoaderProps {
  studyPlanVersionOfferId: number | string;
  periodId: number | string;
  status: string;
  children: ReactNode;
}

export default function TuitionContinuityProcessManagementDetailStudentsLoader({
  studyPlanVersionOfferId,
  periodId,
  status,
  children,
}: TuitionContinuityProcessManagementDetailStudentsLoaderProps) {
  const request = useCallback(
    (query: { page: number; search?: string }) => {
      return getTuitionContinuityProcessManagementDetailStudents(
        studyPlanVersionOfferId,
        periodId,
        { status },
        query,
      );
    },
    [studyPlanVersionOfferId, periodId, status],
  );

  return (
    <FetchProvider
      request={request}
      defaultQuery={{ page: 1 }}
      fetchImmediately
    >
      {children}
    </FetchProvider>
  );
}
