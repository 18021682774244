import { Box, Button, Icon } from '@octano/global-ui';
import { useMemo } from 'react';
import { Col, Row } from 'reactstrap';

import useDownload from '../hooks/useDownload';
import { CourseValidatedHistorySection } from '../types/history';
import CourseHistoryCollapse, {
  ICourseHistoryAccordeonProps,
} from './CourseHistoryCollapse';

interface CourseHistoryProps {
  coursesValidated: CourseValidatedHistorySection[];
  periods: {
    periodId: number;
    periodName: string;
    periodAvg: number;
    sections: CourseValidatedHistorySection[];
  }[];
  studyPlanEnrollmentId: number;
  generalAverage: string | number | null;
  texts: {
    generalAverage: string;
    coursesValidated: string;
    noCourses: string;
    download: {
      button: string;
      failed: string;
      success: string;
    };
  };
}

export default function CourseHistory({
  coursesValidated,
  periods,
  studyPlanEnrollmentId,
  generalAverage: generalAverageProp,
  texts,
}: CourseHistoryProps) {
  const generalAverage =
    generalAverageProp !== null ? String(generalAverageProp) : '-';

  const { loading, download } = useDownload({
    studyPlanEnrollmentId,
    texts: texts.download,
  });

  const periodCourses = useMemo(() => {
    const courses: Array<
      ICourseHistoryAccordeonProps & { key: string | number }
    > = [];

    if (coursesValidated.length > 0) {
      courses.push({
        key: 'courses_validated',
        title: texts.coursesValidated,
        courses: coursesValidated,
      });
    }
    for (const p of periods) {
      courses.push({
        key: p.periodId,
        title: p.periodName,
        average: p.periodAvg,
        courses: p.sections,
      });
    }
    return courses;
  }, [periods, coursesValidated, texts.coursesValidated]);

  if (!periodCourses.length) {
    return (
      <div className="pt-5">
        <div className="d-flex flex-column align-items-center justify-content-center py-5">
          <Icon name="information" size={55} />
          <p className="fs-20 text-dark mt-4">{texts.noCourses}</p>
        </div>
      </div>
    );
  }

  return (
    <>
      <Row className="align-items-center pb-4">
        <Col>
          <Box
            variant="standard"
            color="secondary"
            title={texts.generalAverage}
            body={generalAverage}
          />
        </Col>
        <Col className="text-right">
          <div className="py-4">
            <Button
              icon="download"
              onClick={download}
              outlined
              size="md"
              style={{ width: '223px' }}
              loading={loading}
              text={texts.download.button}
            />
          </div>
        </Col>
      </Row>
      {periodCourses.map((course) => (
        <CourseHistoryCollapse
          key={course.key}
          title={course.title}
          average={course.average}
          courses={course.courses}
        />
      ))}
    </>
  );
}
