import { IInput } from '../../components/modals/UploadModal';
import { CompletedStepResponse } from '../../types/completedTypes';
import { CosignerBody, CosignerStepResponse } from '../../types/cosignerTypes';
import {
  DocumentTypesEnum,
  GeneralTypes,
  SignDocumentationIdentityCardUpload,
  SignDocumentationStepResponse,
  isDocumentType,
  isIdentityType,
} from '../../types/signDocumentationTypes';
import { StudentFileResponse } from '../../types/studentFileSectionTypes';
import { SustainerStepResponse } from '../../types/sustainerTypes';
import {
  Payday,
  TariffData,
  TariffFormData,
  TariffPaymentTypeCodesEnum,
} from '../../types/tariffTypes';
import {
  StudyPlanQuotaResponse,
  WaitingListInformationResponse,
} from '../../types/tuitionProcessResponseTypes';
import { baseURL } from '../api';
import { POSTULATION, TUITION_PROCESS } from '../endpoints';
import request, {
  AxiosErrorWithResponse,
  AxiosResult,
  AxiosResultDefaultError,
  RequestOptionsType,
} from '../request';

export type AuthenticationError = {
  code: 'AUTHENTICATION';
  error: AxiosErrorWithResponse;
  status: number;
  data?: any;
};

export async function requestAuth<T>(
  url: string,
  options?: RequestOptionsType,
): Promise<AxiosResult<T, AuthenticationError | AxiosResultDefaultError>> {
  const response = await request<T>(url, options);
  if (response.error) {
    if (response.error.code === 'HTTP_ERROR' && response.error.status === 401) {
      return {
        error: {
          ...response.error,
          code: 'AUTHENTICATION',
        } as AuthenticationError,
      };
    } else {
      return response;
    }
  }
  return {
    data: response.data,
    error: false,
  };
}

/**
 * Retorna la información inicial para los pasos del proceso de matrícula
 */
export const getStatusTuitionProcess = (
  postulationDetailId?: number | string,
  postulantId?: number | string,
) => {
  return requestAuth<{
    studentFullName: string;
    studentRut: string;
    hasPreassignedGratuity: boolean;
    isOnlineTuition: boolean;
    step: string;
    status: string;
    postulationDetailId?: number;
    admission?: AdmissionType;
    newAdmission?: AdmissionType;
    admissionMethod: 'con-sua' | 'sin-sua';
    postulantId: number;
  }>(
    TUITION_PROCESS.TUITION_PROCESS_STEPS_STATUS(
      postulationDetailId === 'null' ? 'undefined' : postulationDetailId,
      postulantId,
    ),
  );
};

export type AdmissionType = {
  admissionId: number;
  studyPlanName: string;
  campus: { id: number; code: string; name: string };
  schedule: { id: number; code: string; name: string };
};

/**
 * Obtiene la información del paso "Ficha Alumno" del proceso de matrícula
 */
export const getStudentFile = (postulationDetailId: number | string) => {
  return requestAuth<StudentFileResponse>(
    `${TUITION_PROCESS.STUDENT_FILE}/${postulationDetailId}`,
  );
};

/**
 * Guarda la información del paso "Ficha Alumno"  del proceso de matrícula
 */
export const saveStudentFile = (
  postulationDetailId: number | string,
  data: any,
) => {
  return requestAuth(`${TUITION_PROCESS.STUDENT_FILE}/${postulationDetailId}`, {
    method: 'post',
    data,
  });
};

/**
 * Guarda la información del paso "Ficha Alumno"  del proceso de matrícula
 */
export const saveStudentFilePhoto = (
  postulationDetailId?: number | string,
  photo?: File,
) => {
  const formData = new FormData();
  if (photo) {
    formData.append('photo', photo);
  }
  let url = `${TUITION_PROCESS.STUDENT_FILE}/photo`;
  if (postulationDetailId) {
    url = `${TUITION_PROCESS.STUDENT_FILE}/${postulationDetailId}/photo`;
  }
  return requestAuth(url, {
    method: 'post',
    data: formData,
  });
};

/**
 * Obtiene la información asociada al paso "Datos Sustentador" del proceso de matrícula
 */
export const getSustainer = (postulationDetailId: number | string) => {
  return requestAuth<SustainerStepResponse>(
    `${TUITION_PROCESS.SUSTAINER}/${postulationDetailId}`,
  );
};

/**
 * Guarda la información asociada al paso "Datos Sustentador" del proceso de matrícula
 */
export const saveSustainer = (
  postulationDetailId: number | string,
  data: any,
) => {
  return requestAuth(`${TUITION_PROCESS.SUSTAINER}/${postulationDetailId}`, {
    method: 'post',
    data,
  });
};

/**
 * Obtiene la información asociada al paso "Datos Aval" del proceso de matrícula
 */
export const getCosigner = (postulationDetailId: number | string) => {
  return requestAuth<CosignerStepResponse>(
    `${TUITION_PROCESS.COSIGNER}/${postulationDetailId}`,
  );
};

/**
 * Guarga la información asociada al paso "Datos Aval" del proceso de matrícula
 */
export const saveCosigner = (
  postulationDetailId: number | string,
  data: CosignerBody,
) => {
  return requestAuth(`${TUITION_PROCESS.COSIGNER}/${postulationDetailId}`, {
    method: 'post',
    data,
  });
};

/**
 * Obtiene la información asociada al paso "Pago Matrícula"
 */
export const getPayment = (postulationDetailId?: number | string) => {
  let url = `${TUITION_PROCESS.PAYMENT}`;
  if (postulationDetailId) {
    url = `${TUITION_PROCESS.PAYMENT}/${postulationDetailId}`;
  }
  return requestAuth<{ studentFullName: string; isPaid: boolean }>(url);
};

/**
 * Guarga el pago de la matrícula como realizado
 */
export const savePayment = (postulationDetailId?: number | string) => {
  let url = `${TUITION_PROCESS.PAYMENT}`;
  if (postulationDetailId) {
    url = `${TUITION_PROCESS.PAYMENT}/${postulationDetailId}`;
  }
  return requestAuth(url, { method: 'post' });
};

/**
 * Obtiene el contrato que debe firmar el postulante
 * @param token
 * @returns
 */
export const getPreviewDocument = (
  postulationDetailId: number | string,
  token: string,
): string => {
  return `${baseURL}${TUITION_PROCESS.SIGN_DOCUMENTATION_PREVIEW(
    postulationDetailId,
  )}?token=${token}`;
};

export const uploadPostulationProcessDocument = (
  postulationDetailId: number | string,
  uploadMethod: string,
  code: string,
  values?: IInput,
) => {
  let url = TUITION_PROCESS.SIGN_DOCUMENTATION_UPLOAD_DOCUMENTATION(
    postulationDetailId,
    uploadMethod,
  );
  let formData: FormData | Record<string, any>;
  if (['confirmation'].includes(uploadMethod)) {
    formData = { type: code };
  } else {
    url = TUITION_PROCESS.SIGN_DOCUMENTATION_UPLOAD_DOCUMENTATION(
      postulationDetailId,
      uploadMethod,
    );

    formData = new FormData();
    formData.append('type', code);

    if (values) {
      Object.entries(values).forEach(([key, value]) => {
        if (value instanceof File) formData.append(key, value, value.name);
        else formData.append(key, value);
      });
    }
  }

  return request(url, {
    method: 'post',
    data: formData,
  });
};

/**
 * Obtiene el token para poder obtener documento para firmar la documentación
 * @returns string
 */
export const tokenSignPreviewDocument = (
  postulationDetailId: number | string,
  code: string,
) => {
  return request<string>(
    TUITION_PROCESS.SIGN_DOCUMENTATION_PREVIEW_TOKEN(postulationDetailId),
    {
      method: 'post',
      data: { code },
    },
  );
};

/**
 * Envía el correo para que el postulante firme
 * @returns string
 */
export const postRequestSignDocument = (
  postulationDetailId: number | string,
  code: string,
) => {
  return request<string>(
    TUITION_PROCESS.SIGN_DOCUMENTATION_REQUEST_SIGN(postulationDetailId),
    {
      method: 'post',
      data: { code },
    },
  );
};

/**
 * Obtiene la información asociada al paso "Firmar Documentación"
 */
export const getSignDocumentation = (postulationDetailId?: number | string) => {
  if (!postulationDetailId) {
    throw new Error('debe haber un postulationDetailId');
  }
  const url = TUITION_PROCESS.SIGN_DOCUMENTATION(postulationDetailId);
  return requestAuth<SignDocumentationStepResponse>(url);
};

/**
 * Obtiene el token para poder obtener el documento de los términos y condiciones
 * @returns string
 */
export const getSignedDocumentPreviewToken = (
  postulationDetailId?: number | string,
) => {
  let path = `${TUITION_PROCESS.SIGN_DOCUMENTATION_PREVIEW_TOKEN}`;
  if (postulationDetailId) {
    path = `${TUITION_PROCESS.SIGN_DOCUMENTATION_PREVIEW_TOKEN}/${postulationDetailId}`;
  }
  return requestAuth<string>(path, { method: 'post' });
};

/**
 * Obtiene la url para abrir el documento pdf asociado a los terminos y condiciones
 * @param token
 * @returns
 */
export const getSignedDocumentPreview = (
  postulationDetailId?: number | string,
  params?: { token: string; documentType: string },
): string => {
  let path = `${TUITION_PROCESS.SIGN_DOCUMENTATION_PREVIEW}`;
  if (postulationDetailId) {
    path = `${TUITION_PROCESS.SIGN_DOCUMENTATION_PREVIEW}/${postulationDetailId}`;
  }

  const url = new URL(path, baseURL);
  url.searchParams.set('token', params?.token ?? '');
  url.searchParams.set('type', params?.documentType ?? '');
  return url.toString();
};

/*
 * Verificar la firma de los documentos y la carga de los CI
 */
export const verifySignDocumentation = (
  postulationDetailId?: number | string,
) => {
  if (!postulationDetailId) {
    throw new Error('debe haber un postulationDetailId');
  }
  const url = TUITION_PROCESS.SIGN_DOCUMENTATION_VERIFY(postulationDetailId);
  return requestAuth(url, { method: 'post' });
};

/**
 * Obtiene la información asociada al paso "Completado"
 */
export const getCompleted = (postulationDetailId?: number | string) => {
  let url = `${TUITION_PROCESS.COMPLETED}`;
  if (postulationDetailId) {
    url = `${TUITION_PROCESS.COMPLETED}/${postulationDetailId}`;
  }
  return requestAuth<CompletedStepResponse>(url);
};

/**
 * Obtiene listado de planes de estudio con sus cupos disponibles
 * para el proceso de admisión SIN SUA
 */
export const getStudyPlanQuota = () => {
  return request<StudyPlanQuotaResponse[]>(TUITION_PROCESS.STUDY_PLAN_QUOTA);
};

/**
 * Guarda la selección de carrera para un alumno en proceso SIN SUA
 * @param data
 * admissionId es el id de la carrera/campus/jornada seleccionada
 * acceptsWaitingList si el usuario confirma quedar en lista de espera
 * sub pertenece al postulantId
 * @returns
 */
export const requestSaveCareerSelection = (data: {
  admissionId: number;
  acceptsWaitingList: boolean;
  sub?: number;
}) => {
  return request<{
    admissionType: { id: string };
    id: number;
    postulantId: number;
    details: { id: number; status: string; admissionId: number }[];
  }>(POSTULATION.CREATE, { method: 'post', data });
};

/**
 * Obtiene información del estudiante en lista de espera
 * y email de ayuda
 * @returns
 */
export const requestWaitingListInformation = (postulantId?: number) => {
  return request<{
    data: WaitingListInformationResponse;
  }>(POSTULATION.INFORMATION_WAITING_LIST(postulantId), {
    params: { sub: postulantId },
  });
};

/**
 * Actualiza la selección de carrera para un alumno en proceso SIN SUA
 * @param data
 * admissionId es el id de la carrera/campus/jornada seleccionada
 * acceptsWaitingList si el usuario confirma quedar en lista de espera
 * sub pertenece al postulantId
 * @returns
 */
export const requestUpdateCareerSelection = (
  data: {
    admissionId: number;
    acceptsWaitingList: boolean;
    sub?: number;
  },
  postulationDetailId?: any,
) => {
  return request<{
    admissionType: { id: string };
    id: number;
    postulantId: number;
    details: { id: number; status: string; admissionId: number }[];
  }>(POSTULATION.UPDATE(postulationDetailId), { method: 'put', data });
};

/**
 * Obtiene el contrato que debe firmar el postulante
 * @param token
 * @returns
 */
export const getPreviewContract = (
  token: string,
  postulationDetailId?: number,
): string => {
  return `${baseURL}${TUITION_PROCESS.SIGN_DOWNLOAD_CONTRACT(
    postulationDetailId,
  )}?token=${token}`;
};

/**
 * Toma un nombre de documento y un objeto de valores, y devuelve una promesa que se resuelve en la
 * respuesta de una solicitud POST al servidor.
 * @param {GeneralTypes} documentName - Tipos de documentos del paso de documentación,
 * @param {IInput} values - compuesto de names y data (datos del formulario)
 * @returns El regreso es una promesa.
 */
export const uploadPostulationDocs = (
  documentName: GeneralTypes,
  values: IInput,
  postulationDetailId?: number,
) => {
  let url = '';

  if (isDocumentType(documentName)) {
    if (documentName === DocumentTypesEnum['sustainer-income-one']) {
      url =
        TUITION_PROCESS.SIGN_DOCUMENTATION_SUSTAINER_INCOME(
          postulationDetailId,
        );
    } else if (documentName === DocumentTypesEnum['sustainer-income-others']) {
      url =
        TUITION_PROCESS.SIGN_DOCUMENTATION_SUSTAINER_INCOME_OTHERS(
          postulationDetailId,
        );
    } else
      url = TUITION_PROCESS.SIGN_DOCUMENTATION_LEM_NEM(postulationDetailId);
  }

  if (isIdentityType(documentName)) {
    if (
      [
        'postulant-passport',
        'sustainer-passport',
        'cosigner-passport',
      ].includes(documentName)
    ) {
      url = TUITION_PROCESS.SIGN_DOCUMENTATION_LEM_NEM(postulationDetailId);
    } else {
      url =
        TUITION_PROCESS.SIGN_DOCUMENTATION_IDENTITY_CARD_UPLOAD(
          postulationDetailId,
        );
    }
  }

  const formData = new FormData();
  formData.append('type', documentName);

  Object.entries(values).forEach(([key, value]) => {
    if (value instanceof File) formData.append(key, value, value.name);
    else formData.append(key, value);
  });

  return request(url, {
    method: 'post',
    data: formData,
  });
};

/**
 * Subir CI por frente y por detrás
 */
export const uploadIdentityCard = (
  data: SignDocumentationIdentityCardUpload,
  postulationDetailId?: number,
) => {
  const formData = new FormData();
  formData.append('type', data.type);
  formData.append('front', data.front, data.front.name);
  formData.append('back', data.front, data.front.name);
  return request(
    TUITION_PROCESS.SIGN_DOCUMENTATION_IDENTITY_CARD_UPLOAD(
      postulationDetailId,
    ),
    {
      method: 'post',
      data: formData,
    },
  );
};

export const requestChangeEnrolledStudyPlan = (data: {
  currentPostulationDetailId?: number;
  admissionId: number;
  sub: number; //id del postulante
  postulationId?: number;
}) => {
  return requestAuth<{
    data: {
      admissionId: number;
      amount: number;
      enrolledDate: string | null;
      id: number;
      isPaid: boolean;
      mustPay: boolean;
      position: number | null;
      postulationId: number;
      retractedDate: string | null;
      score: number | null;
      status: string;
    };
  }>(TUITION_PROCESS.CHANGE_ENROLLED_STUDY_PLAN, {
    method: 'post',
    data,
  });
};

export const desistStudyPlanChange = (postulationDetailId: number) => {
  return request<any>(
    TUITION_PROCESS.GET_DESIST_STUDY_PLAN_CHANGE(postulationDetailId),
    {
      method: 'PUT',
    },
  );
};

/**********************
 * ARANCEL Y CUOTAS
 *********************/

export const getTariffFormData = (postulationDetailId: number | string) => {
  return request<TariffFormData>(
    TUITION_PROCESS.TARIFF_QUOTAS_FORM_DATA(postulationDetailId),
  );
};

export const getTariffInfo = (postulationDetailId: number | string) => {
  return request<TariffData>(
    TUITION_PROCESS.TARIFF_QUOTAS(postulationDetailId),
  );
};

export const getTariffQuotasSimulation = ({
  postulationDetailId,
  ...data
}: {
  postulationDetailId: number | string;
  //
  tariff: number;
  tariffDownPayment: number;
  matriculation: number;
  numberOfInstalment: number;
  payday: Payday;
  tariffPaymentType: TariffPaymentTypeCodesEnum;
  tariffPaymentMethod: string;
}) => {
  return request<any>(
    TUITION_PROCESS.TARIFF_QUOTAS_SIMULATION(postulationDetailId),
    {
      method: 'POST',
      data,
    },
  );
};

export const saveTariffInfo = (
  postulationDetailId: number,
  params: {
    quotaId: number;
    tariffDownPayment?: number;
    paymentMethodCode?: string;
    tariffPaymentTypeCode?: string;
    documentTypeCode?: string;
    businessActivity?: string;
    economicActivityCode?: string | number;
    businessCity?: number;
  },
) => {
  return requestAuth<any>(
    TUITION_PROCESS.TARIFF_QUOTAS_SAVE(postulationDetailId),
    {
      method: 'POST',
      data: {
        quotaId: params.quotaId,
        paymentMethodCode: params.paymentMethodCode,
        tariffDownPayment: params.tariffDownPayment ?? 0,
        tariffPaymentTypeCode: params.tariffPaymentTypeCode,
        documentTypeCode: params.documentTypeCode,
        businessActivity:
          params.economicActivityCode ?? params.businessActivity,
        businessCity: params.businessCity,
      },
    },
  );
};
