import { Card } from 'reactstrap';
import { TextsProvider } from './hooks/textsContext';
import GradingStructureTable from './parts/GradingStructureTable';
import GradingStructureFormLoader from './parts/Loaders/GradingStructureFormLoader';
import GradingStructureLoader from './parts/Loaders/GradingStructureLoader';

function GradingStructure() {
  const prefix = 'configurations.gradingStructure';

  return (
    <Card className="p-4">
      <TextsProvider prefix={prefix}>
        <GradingStructureFormLoader>
          <GradingStructureLoader>
            <GradingStructureTable />
          </GradingStructureLoader>
        </GradingStructureFormLoader>
      </TextsProvider>
    </Card>
  );
}
export default GradingStructure;
