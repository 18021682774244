import { Button, Select, SelectOptionType } from '@octano/global-ui';
import { FunctionComponent } from 'react';
import { useFormContext } from 'react-hook-form';
import { Col, Row } from 'reactstrap';
import { v4 as uuidv4 } from 'uuid';

import { AsignPackageToSectionFormType } from './AsignPackageToSectionForm';
import { CreateSectionFormType } from './CreateSectionForm';
import { SectionValuesForm } from './SectionForm';

type ExtraSelectFieldsProps = {
  title: 'teachers' | 'assistants' | 'pkgs';
  inputLabel: string;
  options: SelectOptionType[];
  buttonLabel: string;
  onChangeArray: (param: SelectOptionType[]) => void;
  disableAdd?: boolean;
};

const ExtraSelectFields: FunctionComponent<ExtraSelectFieldsProps> = ({
  title,
  inputLabel,
  options,
  buttonLabel,
  children,
  onChangeArray,
  disableAdd = false,
}) => {
  const { setValue, watch, reset } = useFormContext<
    | SectionValuesForm
    | CreateSectionFormType
    | AsignPackageToSectionFormType
    | any
  >();

  const data: SelectOptionType[] = watch(title);
  const pkg: SelectOptionType[] = watch('pkg');

  return (
    <>
      {data?.map((elem, index) => (
        <div className="d-flex" key={`row-${index}`}>
          <div className="flex-grow-1 pr-3">
            <Select
              name={`${title}-${elem.code}`}
              label={inputLabel}
              options={options}
              isClearable
              onChange={(val) => {
                const code = elem.code;
                const label = val ? val.label : '';
                const value = val ? val.value : '';

                const newData = data.map((item) =>
                  elem.code === item.code ? { label, value, code } : item,
                );

                reset({
                  pkg,
                  pkgs: newData,
                });
                onChangeArray(newData);
              }}
              value={elem}
            />
          </div>
          <div>
            <Button
              type="button"
              outlined
              size="lg"
              onClick={() => {
                const newData = data.filter((item) => item.code !== elem.code);

                reset({
                  pkg,
                  pkgs: newData,
                });
                onChangeArray(newData);
              }}
              icon="trash"
              className="pr-3"
            />
          </div>
        </div>
      ))}
      <Row>
        <Col md={{ size: 5, offset: 2 }}>{children}</Col>
        <Col md={5} className="mb-4">
          <Button
            type="button"
            outlined
            size="sm"
            onClick={() =>
              setValue(title, [
                ...data,
                {
                  label: '',
                  value: '',
                  code: uuidv4(), // se usa el uuidv4 para crear una referencia única porque cuando se agregan campos nuevos, el "value" y el "label" están en blanco, y al imprimirlos en pantalla no hay forma de referenciarlos
                },
              ])
            }
            text={buttonLabel}
            fullwidth
            icon="plus"
            disabled={disableAdd}
          />
        </Col>
      </Row>
    </>
  );
};

export default ExtraSelectFields;
