import { DetailBox, DetailBoxColumn } from '@octano/global-ui';
import { useMemo } from 'react';
import { format } from 'rut.js';

interface StudentBoxProps {
  studentName: string;
  studentRut: string;
  studentMail: string;
  studentPhone: string;
  texts: {
    student: string;
    studentId: string;
    email: string;
    phone: string;
  };
}

export default function StudentBox(props: StudentBoxProps) {
  const texts = props.texts;
  const columns = useMemo<DetailBoxColumn[]>(() => {
    return [
      {
        title: texts.student,
        body: props.studentName,
      },
      {
        title: texts.studentId,
        body: format(props.studentRut),
      },
      {
        title: texts.email,
        body: props.studentMail,
      },
      {
        title: texts.phone,
        body: props.studentPhone,
      },
    ];
  }, [props, texts]);

  return (
    <div className="d-flex justify-content-end">
      <DetailBox columns={columns} />
    </div>
  );
}
