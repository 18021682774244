import {
  Button,
  OutlinedSelect,
  SearchBox,
  SelectOptionType,
  useMobile,
} from '@octano/global-ui';
import { useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Col, Form, Row } from 'reactstrap';

export interface SearchFiltersType {
  status: SelectOptionType | null;
  campusId: SelectOptionType | null;
  nameOrId: string | null;
}

interface SearchFiltersProps {
  onSearch: (filters: SearchFiltersType) => void;
  statusOptions: SelectOptionType[];
  campusOptions: SelectOptionType[];
}

const SearchFilters = ({
  onSearch,
  statusOptions,
  campusOptions,
}: SearchFiltersProps) => {
  const prefix = 'studentStatus.filters';
  const { t } = useTranslation();
  const { control, handleSubmit, setValue } = useForm<SearchFiltersType>();
  const isMobile = useMobile();

  const onSubmit = (values: SearchFiltersType) => {
    onSearch(values);
  };

  const allStatusOptions = useMemo(
    () => [
      {
        value: 0,
        label: t(`studentStatus.status.all`),
      },
      ...statusOptions,
    ],
    [statusOptions, t],
  );

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Row className="pb-4 gutters-filters">
        <Col xs={12} md={3} lg={3}>
          <OutlinedSelect
            name="status"
            options={allStatusOptions}
            label={t(`${prefix}.status`)}
            placeholder={t(`${prefix}.placeholderStatus`)}
            control={control}
            isClearable
          />
        </Col>
        <Col xs={12} md={3} lg={3}>
          <SearchBox
            name="nameOrId"
            label="Nombre o ID"
            placeholder="Ingresa un nombre o ID"
            control={control}
            clear={() => setValue('nameOrId', '')}
          />
        </Col>
        <Col xs={12} md={3} lg={3}>
          <OutlinedSelect
            name="campusId"
            options={campusOptions}
            label={t(`${prefix}.campus`)}
            placeholder={t(`${prefix}.placeholderCampus`)}
            control={control}
            isClearable
          />
        </Col>
        <Col
          xs={12}
          md={3}
          lg={3}
          style={{ marginTop: isMobile ? 0 : '1.5rem' }}
        >
          <Button
            type="submit"
            text={t(`common.actions.search`)}
            size="md"
            fullwidth
          />
        </Col>
      </Row>
    </Form>
  );
};

export default SearchFilters;
