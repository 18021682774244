import { Select, SelectOptionType, TextInput } from '@octano/global-ui';
import { useCallback, useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'reactstrap';

import {
  getCoursesListRequest,
  getCreateFormDataRequest,
} from '../../../api/requests/sectionsMaintainer';
import RemoteSearchSelect from '../../../components/form/RemoteSearchSelect';
import { SectionTitle } from '../../../components/text';
import { useSearchAssistant } from '../../../hooks/remoteSearchSelect/useSearchAssistant';
import { useSearchTeachers } from '../../../hooks/remoteSearchSelect/useSearchTeachers';
import { useLoadingState } from '../../../hooks/useLoadingState';
import { useValidations } from '../../../hooks/useValidations';
import { Campus } from '../../../types/campus';
import {
  CourseType,
  PeriodType,
  SchoolType,
} from '../../../types/sectionMaintainerTypes';
import { CreateSectionFormType } from './CreateSectionForm';
import ExtraFields from './ExtraFields';

const CreateSectionFields = () => {
  const { t } = useTranslation();
  const prefix = 'sectionsMaintainer.create';
  const [periodOptions, setPeriodOptions] = useState<SelectOptionType[]>([]);
  const [facultyOptions, setFacultyOptions] = useState<SelectOptionType[]>([]);
  const [campusOptions, setCampusOptions] = useState<SelectOptionType[]>([]);
  const [coursesOptions, setCoursesOptions] = useState<SelectOptionType[]>([]);

  const { msgValidations, rulesPatternNumeric } = useValidations();
  const { setLoading, setErrorLoading } = useLoadingState();

  const { control, watch, setValue } = useFormContext<CreateSectionFormType>();
  const searchTeachers = useSearchTeachers();
  const searchAssistants = useSearchAssistant();
  const [faculty, course] = watch(['faculty', 'courses']);

  const getFormData = useCallback(async () => {
    const { data, error } = await getCreateFormDataRequest();

    if (data) {
      setPeriodOptions(
        data.periods.map(
          (row: PeriodType): SelectOptionType => ({
            value: row.id,
            label: row.name,
          }),
        ),
      );
      setFacultyOptions(
        data.schools.map(
          (row: SchoolType): SelectOptionType => ({
            value: row.id,
            label: row.name,
          }),
        ),
      );
      setCampusOptions(
        data.campuses.map(
          (row: Campus): SelectOptionType => ({
            value: row.id,
            label: row.name,
          }),
        ),
      );
    }
    if (error) {
      setErrorLoading(error.code);
    }
  }, [setErrorLoading]);

  const getCourses = useCallback(
    async (auxFaculty?: number) => {
      const { data, error } = await getCoursesListRequest(auxFaculty);

      if (data) {
        setCoursesOptions(
          data.map(
            (row: CourseType): SelectOptionType => ({
              value: row.code,
              label: row.shortening,
              name: row.name,
            }),
          ),
        );
      }
      if (error) {
        setErrorLoading(error.code);
      }
    },
    [setErrorLoading],
  );

  const loadSelectors = useCallback(async () => {
    setLoading(true);
    await getFormData();
    await getCourses();
    setLoading(false);
  }, [setLoading, getFormData, getCourses]);

  useEffect(() => {
    if (
      faculty &&
      faculty.value &&
      !isNaN(parseInt(faculty.value.toString()))
    ) {
      getCourses(parseInt(faculty.value.toString()));
      setValue('courses', null);
    } else {
      getCourses();
    }
  }, [faculty, getCourses, setValue]);

  useEffect(() => {
    if (course && course.name) setValue('courseName', course.name);
  }, [course, setValue]);

  useEffect(() => {
    loadSelectors();
  }, [loadSelectors]);

  return (
    <>
      <Row className="pb-5">
        <Col xs={12}>
          <SectionTitle text={t(`${prefix}.generalSection`)} />
        </Col>
        <Col xs={12}>
          <p className="fs-16 pb-4">
            {t(`${prefix}.generalSectionDescription`)}
          </p>
        </Col>
        <Col xs={12} md={3}>
          <Select
            name="period"
            label={t(`${prefix}.periodLabel`)}
            options={periodOptions}
            control={control}
            rules={{ required: msgValidations.required }}
          />
        </Col>
        <Col xs={12} md={3}>
          <Select
            name="faculty"
            label={t(`${prefix}.facultyLabel`)}
            options={facultyOptions}
            control={control}
            rules={{ required: msgValidations.required }}
          />
        </Col>
        <Col xs={12} md={3}>
          <TextInput
            name="quota"
            label={t(`${prefix}.quotaLabel`)}
            control={control}
            rules={{
              required: msgValidations.required,
              pattern: rulesPatternNumeric,
            }}
          />
        </Col>
        <Col xs={12} md={3}>
          <Select
            name="campus"
            label={t(`${prefix}.campusLabel`)}
            options={campusOptions}
            control={control}
            rules={{ required: msgValidations.required }}
          />
        </Col>
        <Col xs={12} md={4}>
          <Select
            name="courses"
            label={t(`${prefix}.coursesLabel`)}
            options={coursesOptions}
            control={control}
            rules={{ required: msgValidations.required }}
            isClearable
          />
        </Col>
        <Col xs={12} md={4}>
          <TextInput
            name="courseName"
            label={t(`${prefix}.courseNameLabel`)}
            control={control}
            disabled
          />
        </Col>
        <Col xs={12} md={4}>
          <TextInput
            name="section"
            label={t(`${prefix}.sectionLabel`)}
            control={control}
            rules={{
              required: msgValidations.required,
            }}
          />
        </Col>
      </Row>
      <Row className="pb-5">
        <Col xs={12}>
          <SectionTitle text={t(`${prefix}.teacherTitle`)} />
        </Col>
        <Col xs={12} md={6}>
          <Row>
            <Col>
              <RemoteSearchSelect
                name="teacher"
                label={t(`${prefix}.teacher`)}
                filterOptions={searchTeachers}
                control={control}
                rules={{ required: msgValidations.required }}
              />
            </Col>
          </Row>
          <ExtraFields
            title="teachers"
            inputLabel={t(`${prefix}.teacher`)}
            search={searchTeachers}
            buttonLabel={t(`${prefix}.addTeacher`)}
          />
        </Col>
        <Col xs={12} md={6}>
          <Row>
            <Col>
              <RemoteSearchSelect
                name="assistant"
                label={t(`${prefix}.assistant`)}
                filterOptions={searchAssistants}
                control={control}
              />
            </Col>
          </Row>
          <ExtraFields
            title="assistants"
            inputLabel={t(`${prefix}.assistant`)}
            search={searchAssistants}
            buttonLabel={t(`${prefix}.addAssistant`)}
          />
        </Col>
      </Row>
    </>
  );
};

export default CreateSectionFields;
