import { Col, Row } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { useCallback } from 'react';
import { useFormContext } from 'react-hook-form';
import { InterimDegreeFormFields } from './InterimDegreeForm';
import CurriculumSemester from '../../studyPlans/curriculum/CurriculumSemester';
import { SemesterCourse } from '../../../../components/curriculum/types';
import { Semester } from '../../../../components/curriculum/CoursesBySemesters';
import { CourseInfo, COURSE_TYPES } from '../../../../types/CurriculumTypes';

const mapSemesterCourseToCourseInfo = (
  semesterCourse: SemesterCourse,
  semester: Semester,
): CourseInfo => {
  const courseTypeAlias = Object.values(COURSE_TYPES)?.find(
    (t) => t?.id === semesterCourse?.courseTypeId,
  )?.alias;
  return {
    id: `${semesterCourse.id}`,
    type: courseTypeAlias ?? '',
    name: semesterCourse?.course?.name,
    code: semesterCourse?.course?.code,
    credits: Number(semesterCourse?.course?.credits ?? 0),
    shortening: semesterCourse?.course?.code,
    semester: semester.semesterIndex + 1,
    semesterId: semester.id as any,
    semesterCourseId: semesterCourse.id as any,
  };
};

const InterimDegreeCoursesForm = ({
  dictPrefix,
  periodTypeName = '',
  semesters = [],
  disabled = false,
}: {
  dictPrefix: string;
  disabled?: boolean;
  semesters?: Semester[];
  periodTypeName?: string;
}) => {
  const { t } = useTranslation();

  const { watch, setValue } = useFormContext<InterimDegreeFormFields>();

  const [semesterCoursesIds] = watch(['semesterCoursesIds']);

  const handleAddCourse = useCallback(
    (courseInfo: CourseInfo) => {
      if (!courseInfo.semesterCourseId || disabled) {
        return;
      }
      const selecteds = new Set([...semesterCoursesIds]);
      if (selecteds.has(courseInfo.semesterCourseId)) {
        selecteds.delete(courseInfo.semesterCourseId);
      } else {
        selecteds.add(courseInfo.semesterCourseId);
      }
      setValue('semesterCoursesIds', Array.from(selecteds));
    },
    [semesterCoursesIds, disabled, setValue],
  );

  return (
    <Row>
      <Col xs={12}>
        <p className="fs-20 text-uppercase text-primary mb-0 fw-700">
          {t(`${dictPrefix}.courses.title`)}
        </p>
        <p className="mb-0">{t(`${dictPrefix}.courses.description`)}</p>
      </Col>
      <Col xs={12}>
        <div className="semesters-list pt-3" style={{ maxWidth: '100%' }}>
          {semesters?.map((semester, i) => (
            <CurriculumSemester
              key={i}
              id={i + 1}
              periodTypeName={periodTypeName}
              hideShowCourse={true}
              hideCreditsCount={true}
              isCourseSelected={(item) =>
                !!item.semesterCourseId &&
                semesterCoursesIds?.includes(item.semesterCourseId)
              }
              setCurrentSemester={() => {}}
              setShowCourseModal={() => {}}
              setCoursesList={() => {}}
              semesterCoursesList={semester?.semesterCourses?.map((item) =>
                mapSemesterCourseToCourseInfo(item, semester),
              )}
              onPressCourse={handleAddCourse}
              coursesList={[]}
              readOnly={true}
              legend={{} as any}
              setLegend={() => {}}
              setDisabledEditButton={() => {}}
            />
          ))}
        </div>
      </Col>
    </Row>
  );
};

export default InterimDegreeCoursesForm;
