import { useCallback, useContext } from 'react';

import { LayoutContext } from '../providers/LayoutProvider';
import { LayoutActionType } from '../types/LayoutTypes';

/**
 * Provee el estado y el manejo de estado de lo que se muestra en el layout
 */
export const useLayoutState = () => {
  const { state, dispatch } = useContext(LayoutContext);

  const showTabs = useCallback(() => {
    dispatch({
      type: LayoutActionType.SHOW_MAINTAINER_TABS,
      payload: true,
    });
  }, [dispatch]);

  const hideTabs = useCallback(() => {
    dispatch({
      type: LayoutActionType.SHOW_MAINTAINER_TABS,
      payload: false,
    });
  }, [dispatch]);

  const showErrorModal = useCallback(() => {
    dispatch({
      type: LayoutActionType.SHOW_CONNECTION_ERROR_MODAL,
      payload: true,
    });
  }, [dispatch]);

  const hideErrorModal = useCallback(() => {
    dispatch({
      type: LayoutActionType.SHOW_CONNECTION_ERROR_MODAL,
      payload: false,
    });
  }, [dispatch]);

  return {
    ...state,
    showTabs,
    hideTabs,
    showErrorModal,
    hideErrorModal,
  };
};
