import { Button, TextAreaInput, TextInput } from '@octano/global-ui';
import { useCallback } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { Col, Container, Form, Row } from 'reactstrap';
import { IRoleManageForm } from '../../../types/Roles';
import { useActionRoleManageCreateForm } from './hooks/useActionAdminRolesForm';

const prefix = 'usersAndRoles.roles.form';

export default function RoleManageCreateForm(): JSX.Element {
  const { t } = useTranslation();
  const { createAdminRole } = useActionRoleManageCreateForm();
  const history = useHistory();

  const methods = useForm<IRoleManageForm>({ mode: 'onChange' });
  const {
    handleSubmit,
    control,
    formState: { isSubmitting },
    reset,
  } = methods;

  const onSubmit = useCallback(
    async (values: IRoleManageForm) => {
      await createAdminRole(values);
      reset();
    },
    [createAdminRole, reset],
  );

  return (
    <Row>
      <Col md={12}>
        <p className="text-primary fs-20 fw-700 mt-4 mb-2 text-uppercase">
          {t(`${prefix}.titleCreate`)}
        </p>
        <p className="fs-16 mt-3 mb-4">{t(`${prefix}.subtitle`)}</p>
      </Col>

      <Form onSubmit={handleSubmit(onSubmit)} className="w-100">
        <Col>
          <p className="text-primary fs-20 fw-700 mt-4 mb-2 text-uppercase">
            {t(`${prefix}.roleData`)}
          </p>
        </Col>

        <Col>
          <TextInput
            name="name"
            label={t(`${prefix}.roleKeyLabel`)}
            placeholder={t(`${prefix}.roleNamePlaceholder`)}
            control={control}
            maxLength={255}
          />
        </Col>

        <Col>
          <TextInput
            name="key"
            label={t(`${prefix}.roleNameLabel`)}
            placeholder={t(`${prefix}.roleNamePublicPlaceholder`)}
            control={control}
            maxLength={255}
          />
        </Col>

        <Col>
          <TextAreaInput
            name="description"
            label={t(`${prefix}.descriptionLabel`)}
            placeholder={t(`${prefix}.descriptionPlaceholder`)}
            control={control}
            maxLength={255}
          />
        </Col>

        <Container fluid className="mt-5">
          <Row>
            <div className="col-sm-12 col-md-3 offset-md-6">
              <Button
                type="button"
                onClick={() => history.push('/users-and-roles/roles')}
                text={t(`${prefix}.cancel`)}
                outlined
                disabled={isSubmitting}
                fullwidth
              />
            </div>

            <div className="col-sm-12 mt-sm-3 col-md-3 mt-md-0">
              <Button
                className=""
                type="submit"
                text={t(`${prefix}.submitCreate`)}
                loading={isSubmitting}
                fullwidth
              />
            </div>
          </Row>
        </Container>
      </Form>
    </Row>
  );
}
