import {
  Button,
  Select,
  SelectOptionType,
  TextInput,
  addToast,
} from '@octano/global-ui';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { DefaultValues, FormProvider, useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Card, Col, Form, Row } from 'reactstrap';

import {
  getTariffQuotasSimulation,
  saveTariffInfo,
} from '../../../../../../../api/requests/tuitionProcess';
import { useStepState } from '../../../../../../../components/step/useStepState';
import { SectionTitle } from '../../../../../../../components/text';
import { useParameters } from '../../../../../../../hooks/useParameters';
import { useValidations } from '../../../../../../../hooks/useValidations';
import {
  InterestsCalculatorVariant,
  QuotaSimulationItem,
  TariffData,
  TariffPaymentMethodSettingValue,
  TariffPaymentTypeCodesEnum,
} from '../../../../../../../types/tariffTypes';
import { STATUS_POSTULATION } from '../../../../../../../types/tuitionProcessOnSite';
import { TariffFormatter } from '../../../../../../../utils/currency';
import { SustainerType } from '../../../../../../../utils/selectOptions';
import { useTuitionProcessNoSua } from '../../../hooks/useTuitionProcessNoSua';
import HeaderStep from '../../HeaderStep';
import DiscountPostulation from './DiscountModal';
import TariffDisplayData from './TariffDisplayData';
import TariffTable from './TariffTable';

interface TariffFormProps {
  tariffData: TariffData;
  //Desencadena el refresco de la vista al dar un descuento
  refresh: () => void;
  //Valores guardados en el formulario
  defaultValues: DefaultValues<any>;
  //Opciones para select
  selectOptions: Record<string, SelectOptionType[]>;
  settings: {
    dateLocalOffset: string;
    paydaySetting: { value: number; description: string };
    paymentMethodSettingValue: TariffPaymentMethodSettingValue;
    tuitionFree: boolean;
  };
}

export default function TariffForm(props: TariffFormProps) {
  const { tariffData, refresh, defaultValues, selectOptions, settings } = props;
  const prefix = 'tuitionProcessNoSua.tariffQuotas';
  const methods = useForm({ defaultValues });
  const history = useHistory();
  const { cityOptions } = useParameters();
  const { handleSubmit, formState, control, watch, setValue } = methods;
  const { t } = useTranslation();
  const { nextStep } = useStepState();
  const { msgValidations } = useValidations();
  const { postulationDetail, statusPostulation, postulant } =
    useTuitionProcessNoSua();

  const [quotas, setQuotas] = useState<QuotaSimulationItem[]>([]);
  const [activityCodeError, setActivityCodeError] = useState<string>();
  const activityCode: string | undefined = watch('economicActivityCode');
  const quota: SelectOptionType = watch('quota');
  const documentType: SelectOptionType = watch('documentType');
  const tariffPaymentType: SelectOptionType & {
    value: TariffPaymentTypeCodesEnum;
  } = watch('tariffPaymentType');

  const tariffPaymentMethod: SelectOptionType & {
    value: string;
  } = watch('paymentMethod');

  const [finalTariff, setFinalTariff] = useState(
    tariffData.studyPlan.tariff.value -
      tariffData.postulationDetail.tariffDownPayment,
  );

  const tuitionValue = useMemo(
    () =>
      !settings.tuitionFree
        ? tariffData.studyPlan.admissions?.[0].postulationsDetail?.[0]
            .postulation?.period?.tuitionPeriod?.value ?? 0
        : 0,
    [settings.tuitionFree, tariffData.studyPlan.admissions],
  );

  const paymentMethodOptions = useMemo(
    () =>
      selectOptions.paymentMethodsOptions.filter(
        (item) => item.paymentType === tariffPaymentType?.value,
      ),
    [selectOptions.paymentMethodsOptions, tariffPaymentType?.value],
  );

  const maxTariffDownPayment = useMemo(() => {
    return Math.trunc(
      (settings.paymentMethodSettingValue?.maxTariffDownPaymentRate ?? 0) *
        tariffData.studyPlan.tariff.value,
    );
  }, [
    settings.paymentMethodSettingValue?.maxTariffDownPaymentRate,
    tariffData.studyPlan.tariff.value,
  ]);

  const tariffDownPaymentEnabled = useMemo(() => {
    return !!(
      tariffPaymentType?.value === TariffPaymentTypeCodesEnum.CR &&
      maxTariffDownPayment > 0
    );
  }, [maxTariffDownPayment, tariffPaymentType?.value]);

  const quotasEnabled = useMemo(() => {
    // Las cuotas solo son seleccionables cuando es a credito. Si es contado se establece 1 cuota con el total
    if (!tariffPaymentType?.value) {
      return false;
    }
    return [
      TariffPaymentTypeCodesEnum.CR,
      TariffPaymentTypeCodesEnum.CRI,
    ].includes(tariffPaymentType?.value);
  }, [tariffPaymentType?.value]);

  const tariffDownPaymentValue = useMemo(() => {
    return tariffDownPaymentEnabled
      ? tariffData.studyPlan.tariff.value - finalTariff
      : 0;
  }, [
    finalTariff,
    tariffData.studyPlan.tariff.value,
    tariffDownPaymentEnabled,
  ]);

  // Selecciona la quota por defecto para pago de contado o contado3
  useEffect(() => {
    if (tariffPaymentType?.value !== TariffPaymentTypeCodesEnum.CR) {
      setValue('tariffDownPayment', TariffFormatter(0));
      setFinalTariff(tariffData.studyPlan.tariff.value);
    }
    if (tariffPaymentType?.value === TariffPaymentTypeCodesEnum.CO) {
      setValue('quota', selectOptions.quotaOptions[0]);
    } else if (tariffPaymentType?.value === TariffPaymentTypeCodesEnum.CO3) {
      setValue('quota', selectOptions.quotaOptions[2]);
    }
  }, [
    selectOptions.quotaOptions,
    setValue,
    tariffData.studyPlan.tariff.value,
    tariffPaymentType?.value,
  ]);

  const onSubmit = useCallback(
    async (values) => {
      const economicActivity = values?.economicActivityCode
        ?.trim()
        ?.split(' ')[0];
      const activity = selectOptions?.businessActivitiesOptions?.find(
        (e) => e?.value === economicActivity,
      );
      const { data, error } = await saveTariffInfo(postulationDetail!.id, {
        documentTypeCode: values.documentType?.value,
        tariffDownPayment: tariffDownPaymentEnabled
          ? Number(`${values?.tariffDownPayment ?? '0'}`.replace(/\D/g, ''))
          : 0,
        paymentMethodCode: values.paymentMethod?.value,
        tariffPaymentTypeCode: values.tariffPaymentType?.value,
        quotaId: values.quota?.value,
        businessActivity: values.businessActivity?.value,
        economicActivityCode: activity?.value,
        businessCity:
          values.businessCity?.value && parseInt(values.businessCity?.value),
      });

      if (data) {
        addToast({
          icon: 'check',
          color: 'success',
          text: t(`${prefix}.successOnSaveTariff`),
        });
        nextStep();
      }
      if (error) {
        addToast({
          icon: 'error',
          color: 'danger',
          text: t(`${prefix}.errorOnSaveTariff`),
        });
      }
    },
    [
      selectOptions?.businessActivitiesOptions,
      postulationDetail,
      tariffDownPaymentEnabled,
      t,
      nextStep,
    ],
  );

  useEffect(() => {
    getTariffQuotasSimulation({
      postulationDetailId: postulationDetail?.id ?? 0,
      tariff: finalTariff,
      numberOfInstalment: Number(quota?.label ?? 0),
      payday: settings.paydaySetting?.value,
      tariffPaymentType: tariffPaymentType?.value,
      tariffPaymentMethod: tariffPaymentMethod?.value,
      tariffDownPayment: tariffDownPaymentEnabled ? tariffDownPaymentValue : 0,
      matriculation: tuitionValue,
    })
      .then(({ data }) => setQuotas(data ?? []))
      .catch(() => setQuotas([]));
    //
  }, [
    tuitionValue,
    postulationDetail?.id,
    quota?.label,
    settings.paydaySetting?.value,
    tariffPaymentType?.value,
    tariffPaymentMethod?.value,
    finalTariff,
    tariffData.postulationDetail.tariffDownPayment,
    tariffDownPaymentEnabled,
    tariffDownPaymentValue,
  ]);

  const handleBlurActivityCode = useCallback(async () => {
    const code = activityCode?.split(' ')[0];
    const activity = selectOptions?.businessActivitiesOptions?.find(
      (e) => e?.value === code,
    );
    if (code?.trim()) {
      if (!activity) {
        setActivityCodeError(
          t(`${prefix}.displayData.invalidEconomicActivityCode`),
        );
      } else {
        setActivityCodeError(undefined);
        setValue(
          'economicActivityCode',
          `${code?.trim()} ${activity?.label?.trim()}`,
        );
      }
    } else {
      setActivityCodeError(undefined);
    }
  }, [activityCode, selectOptions?.businessActivitiesOptions, setValue, t]);

  return (
    <Card className="px-4 px-lg-5 py-4">
      <HeaderStep
        prefixStep={prefix}
        showBackBtn={statusPostulation !== STATUS_POSTULATION.STUDY_PLAN_CHANGE}
      />
      <Row>
        <Col xs={12} className="pt-lg-4 pb-lg-5">
          <Row>
            <Col xs={12} md={10}>
              <SectionTitle text={t(`${prefix}.displayData.title`)} />
            </Col>
            <Col xs={12} md={2}>
              <DiscountPostulation
                postulantId={postulant?.id}
                postulationDetail={postulationDetail}
                reloadComponent={refresh}
              />
            </Col>
          </Row>
          <FormProvider {...methods}>
            <Form onSubmit={handleSubmit(onSubmit)}>
              <TariffDisplayData
                tariffData={tariffData}
                paydayDescription={settings.paydaySetting.description}
                showTuitionAmount={
                  settings.paymentMethodSettingValue.hasTuition
                }
                tuitionValue={tuitionValue}
                onChangeFinalTariff={(next) => {
                  setFinalTariff(next);
                  if (next === tariffData.studyPlan.tariff.value) {
                    setValue(
                      'tariffPaymentType',
                      selectOptions.tariffPaymentTypesOptions?.find(
                        (e) => e?.value === TariffPaymentTypeCodesEnum.CO,
                      ),
                    );
                    setValue('paymentMethod', null);
                  }
                }}
                tariffDownPaymentEnabled={tariffDownPaymentEnabled}
                maxTariffDownPayment={maxTariffDownPayment}
              >
                <>
                  <Col className="pb-3" xs={12} lg={4}>
                    <Select
                      name="tariffPaymentType"
                      label={t(`${prefix}.displayData.tariffPaymentType`)}
                      isClearable={false}
                      options={selectOptions.tariffPaymentTypesOptions}
                      control={control}
                      rules={{ required: msgValidations.required }}
                      onInputChange={() => {
                        setValue('paymentMethod', null);
                      }}
                    />
                  </Col>
                  {/* los inputs a continuación solo se muestran si la setting es true */}
                  {Boolean(
                    settings.paymentMethodSettingValue.hasPaymentMethod,
                  ) ? (
                    <Col className="pb-3" xs={12} lg={4}>
                      <Select
                        name="paymentMethod"
                        label={t(`${prefix}.displayData.paymentMethod`)}
                        isClearable={false}
                        options={paymentMethodOptions}
                        control={control}
                        rules={{ required: msgValidations.required }}
                      />
                    </Col>
                  ) : null}

                  {!!quotasEnabled ? (
                    <Col className="pb-3" xs={12} lg={4}>
                      <Select
                        name="quota"
                        label={t(`${prefix}.displayData.quotas`)}
                        isClearable={false}
                        options={selectOptions.quotaOptions}
                        control={control}
                        rules={{ required: msgValidations.required }}
                      />
                    </Col>
                  ) : null}

                  {/* los inputs a continuación solo se muestran si es una persona jurídica*/}
                  {tariffData.sustainer.type === SustainerType.LegalPerson ? (
                    <>
                      <Col className="pb-3" xs={12} lg={4}>
                        <Select
                          name="documentType"
                          label={t(`${prefix}.displayData.documentType`)}
                          isClearable={false}
                          options={selectOptions.documentTypesOptions}
                          control={control}
                          rules={{ required: msgValidations.required }}
                        />
                      </Col>
                      {documentType?.value === 'FA' ? (
                        <>
                          <Col className="pb-3" xs={12} lg={4}>
                            {settings.paymentMethodSettingValue
                              ?.businessActivityStyle === 'text' ? (
                              <div className="w-100">
                                <TextInput
                                  name="economicActivityCode"
                                  label={t(
                                    `${prefix}.displayData.economicActivityCode`,
                                  )}
                                  control={control}
                                  onBlur={handleBlurActivityCode}
                                  errorText={activityCodeError}
                                />
                                <p className="text-light fw-400">
                                  <Trans
                                    i18nKey={`${prefix}.displayData.economicActivityCodeDescription`}
                                    components={{
                                      linked: (
                                        // eslint-disable-next-line jsx-a11y/anchor-has-content
                                        <a
                                          rel="noreferrer"
                                          href="https://www.sii.cl/ayudas/ayudas_por_servicios/1956-codigos-1959.html"
                                          target="_blank"
                                          className="text-primary"
                                        />
                                      ),
                                    }}
                                  />
                                </p>
                              </div>
                            ) : (
                              <Select
                                name="businessActivity"
                                label={t(
                                  `${prefix}.displayData.businessActivity`,
                                )}
                                isClearable={false}
                                options={
                                  selectOptions.businessActivitiesOptions
                                }
                                control={control}
                                rules={{ required: msgValidations.required }}
                              />
                            )}
                          </Col>
                          <Col className="pb-3" xs={12} lg={4}>
                            <Select
                              name="businessCity"
                              label={t(`${prefix}.displayData.businessCity`)}
                              isClearable={false}
                              options={cityOptions}
                              control={control}
                              rules={{ required: msgValidations.required }}
                            />
                          </Col>
                        </>
                      ) : null}
                    </>
                  ) : null}
                </>
              </TariffDisplayData>

              <TariffTable
                quotas={quotas}
                tariffDownPayment={
                  tariffDownPaymentEnabled ? tariffDownPaymentValue : 0
                }
                showInterestsDetails={
                  settings.paymentMethodSettingValue
                    ?.interestCalculatorVariant ===
                  InterestsCalculatorVariant.SkipInterest
                }
              />

              <Row className="pt-5 mt-5 justify-content-end">
                <Col xs={12} lg={4} className="pb-2 order-2 order-lg-1">
                  <Button
                    type="button"
                    outlined
                    onClick={() => history.push('/tuition-process')}
                    text={t(`common.actions.cancel`)}
                    loading={formState.isSubmitting}
                    fullwidth
                  />
                </Col>

                <Col xs={12} lg={4} className="pb-2 order-1 order-lg-2">
                  <Button
                    type="submit"
                    text={t(`common.actions.next`)}
                    loading={formState.isSubmitting}
                    fullwidth
                  />
                </Col>
              </Row>
            </Form>
          </FormProvider>
        </Col>
      </Row>
    </Card>
  );
}
