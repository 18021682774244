import { SelectOptionType } from '@octano/global-ui';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

export default function useSelectOptions() {
  const { t } = useTranslation();

  const daysOptions: SelectOptionType[] = useMemo(() => {
    return [
      { value: 'Lu', label: t('common.days.Lu') },
      { value: 'Ma', label: t('common.days.Ma') },
      { value: 'Mi', label: t('common.days.Mi') },
      { value: 'Ju', label: t('common.days.Ju') },
      { value: 'Vi', label: t('common.days.Vi') },
      { value: 'Sa', label: t('common.days.Sa') },
    ];
  }, [t]);

  return { daysOptions };
}
