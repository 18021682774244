import { Card, Col, Row } from 'reactstrap';
import { useState, useEffect, useCallback } from 'react';
import {
  getStudents,
  studentsDownload,
} from '../../api/requests/interimDegreeGraduationMassive';
import { useTranslation } from 'react-i18next';
import { addToast, Button } from '@octano/global-ui';
import useUserState from '../../hooks/useUserState';
import { useParams } from 'react-router-dom';
import { PermissionName } from '../../types/Auth';
import DisplayPermissionError from '../../components/info/DisplayPermissionError';
import { InterimDegressProcessStudentTable } from './parts/InterimDegressProcessStudentTable';
import GoBackToListButton from '../../components/buttons/GoBackToListButton';
import { InterimDegreeAuthorizedStudentMassive } from './interfaces/interim-degree-authorized-student-massive.interface';
import { downloadFromBlob } from '../../utils/blob';

export const MODULE_PERMISSIONS = [
  PermissionName.INTERIM_DEGREE_GRADUATION_PROCESS_ALL,
];
const dictPrefix = 'interimDegreeGraduationProcess.students';
const ITEMS_PER_PAGE = 20;

const InterimDegreeGraduationProcessStudents = () => {
  const { t } = useTranslation();
  const { isAuthorizedTo } = useUserState();

  const { processId, batch } = useParams<{
    processId: string;
    batch: string;
  }>();

  const [tableData, setTableData] = useState<
    InterimDegreeAuthorizedStudentMassive[]
  >([]);
  const [isLoadingTable, setIsTableLoading] = useState<boolean>(false);
  const [isDownloadLoading, setDownloadLoading] = useState<boolean>(false);

  const [currentPage, setCurrentPage] = useState<number>(0);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [totalItems, setTotalItems] = useState<number>(0);

  const handleSearch = useCallback(
    async (params: { page: number }) => {
      setIsTableLoading(true);
      const { data, error } = await getStudents({
        ...params,
        id: processId,
        items_per_page: ITEMS_PER_PAGE,
        batch,
      });

      if (error) {
        addToast({
          text: t(`${dictPrefix}.errorOnSearch`),
          color: 'danger',
          icon: 'error',
        });
      } else {
        setTableData(data?.data ?? []);
        setTotalPages(data?.total_pages || 0);
        setTotalItems(data?.total || 0);
      }
      setIsTableLoading(false);
    },
    [t, processId, batch],
  );

  const handleDownload = useCallback(async () => {
    if (isDownloadLoading) {
      return;
    }
    setDownloadLoading(true);
    const response = await studentsDownload({
      id: processId,
      batch,
    });
    const { data, error, status } = response;

    if (error) {
      if (error?.status === 400) {
        addToast({
          text: t(`${dictPrefix}.generatingFile.errorOnDownload`),
          color: 'danger',
          icon: 'error',
        });
      } else {
        addToast({
          text: t(`${dictPrefix}.generatingFile.errorOnDownload`),
          color: 'danger',
          icon: 'error',
        });
      }
    }
    if (status === 200) {
      addToast({
        text: t(`${dictPrefix}.generatingFile.download`),
        color: 'success',
        icon: 'success',
      });
      const blob = new Blob([data], {
        type: 'vnd.ms-excel',
      });
      downloadFromBlob(
        blob,
        `estudiantes-habilitados-${processId}-${batch}.xlsx`,
      );
    }
    setDownloadLoading(false);
  }, [t, processId, batch, isDownloadLoading]);

  useEffect(() => {
    handleSearch({ page: currentPage });
  }, [handleSearch, currentPage]);

  if (!isAuthorizedTo(MODULE_PERMISSIONS, true)) {
    return (
      <div className="w-full px-3">
        <DisplayPermissionError permissions={MODULE_PERMISSIONS} insideCard />
      </div>
    );
  }

  return (
    <>
      <Card className="p-4 mx-3">
        <Row>
          <Col className="mb-4" xs={12}>
            <div className="d-flex flex-wrap w-100 align-items-start justify-content-center">
              <GoBackToListButton text={t(`${dictPrefix}.back`)} />
              <div className="flex-fill px-2" />
            </div>
          </Col>
          <Col className="mb-4" xs={12}>
            <div className="d-flex flex-wrap w-100 align-items-center justify-content-center">
              <p className="fs-20 text-uppercase text-primary mb-0 fw-700">
                {t(`${dictPrefix}.title`)}
              </p>
              <div className="flex-fill px-2" />
              {!!totalItems && (
                <Button
                  type="button"
                  text={t(`${dictPrefix}.download`)}
                  size="md"
                  outlined
                  loading={isDownloadLoading}
                  icon="download"
                  onClick={handleDownload}
                />
              )}
            </div>
          </Col>
          <Col xs={12}>
            <InterimDegressProcessStudentTable
              data={tableData}
              itemPerPage={ITEMS_PER_PAGE}
              totalItems={totalItems}
              totalPages={totalPages}
              currentPage={currentPage}
              isLoadingResults={isLoadingTable}
              onChangePage={(page) => {
                setCurrentPage(page);
              }}
            />
          </Col>
        </Row>
      </Card>
    </>
  );
};

export default InterimDegreeGraduationProcessStudents;
