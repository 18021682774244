import { Col, Row } from 'reactstrap';

import Legend from './Legend';
import { getColor as getColorCore } from './utils';

export interface LegendsProps {
  texts: {
    enrolled: string;
    approved: string;
    pending: string;
    failed: string;
    validated: string;
  };
  getColor?: (state?: string) => string | undefined;
}

export default function Legends({
  texts,
  getColor = getColorCore,
}: LegendsProps) {
  return (
    <Row className="d-flex">
      <Col xs={6} sm="auto">
        <Legend label={texts.enrolled} color={getColor('E')} />
      </Col>
      <Col xs={6} sm="auto">
        <Legend label={texts.approved} color={getColor('A')} />
      </Col>
      <Col xs={6} sm="auto">
        <Legend label={texts.pending} color={getColor('P')} />
      </Col>
      <Col xs={6} sm="auto">
        <Legend label={texts.failed} color={getColor('R')} />
      </Col>
      <Col xs={6} sm="auto">
        <Legend label={texts.validated} color={getColor('V')} />
      </Col>
    </Row>
  );
}
