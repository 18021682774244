import {
  Box,
  Button,
  CheckInput,
  DateInput,
  Icon,
  Select,
  SelectOptionType,
  TextInput,
  addToast,
} from '@octano/global-ui';
import { useCallback, useMemo, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { Col, Form as FormCore, Row } from 'reactstrap';
import { Subject, firstValueFrom } from 'rxjs';

import { ModalConfirmation } from '../../../components/modals/ModalConfirmation';
import { useValidations } from '../../../hooks/useValidations';
import { complete } from '../requests';

export interface FormValues {
  distinctionRank: SelectOptionType;
  degreeGrade: string;
  financialStateChecked: boolean;
  date: string;
}

export interface FormProps {
  studyPlanEnrollmentId: number | string;
  graduationGrade: string;
  degreeGrade?: string;
  distinctionRanks: { id: number; name: string }[];
  financialBlockade: boolean | null;
  disabled: boolean;
  onComplete: () => void;
}

const dictPrefix = 'studentsDegreeStudent';

export default function Form({
  studyPlanEnrollmentId,
  onComplete,
  disabled: disabledProp,
  ...props
}: FormProps) {
  const confirmResponse$ = useRef(new Subject<boolean>());
  const [open, setOpen] = useState(false);

  const options = useMemo(() => {
    return props.distinctionRanks.map<SelectOptionType>((d) => ({
      value: d.id,
      label: d.name,
    }));
  }, [props.distinctionRanks]);

  const { msgValidations, rulesPatternDecimal } = useValidations();

  const { t } = useTranslation();

  const texts = useMemo(
    () => ({
      distinctionRankLabel: t(`${dictPrefix}.form.distinctionRank`),
      graduationGradeLabel: t(`${dictPrefix}.form.graduationGrade`),
      degreeGradeLabel: t(`${dictPrefix}.form.degreeGrade`),
      dateLabel: t(`${dictPrefix}.form.date`),
      button: t(`${dictPrefix}.form.action`),
      financialStatus: {
        description: (
          <Trans i18nKey={`${dictPrefix}.financialStatus.description`} />
        ),
        label: t(`${dictPrefix}.financialStatus.label`),
      },
      confirm: {
        title: t(`${dictPrefix}.confirm.title`),
        body: t(`${dictPrefix}.confirm.body`),
      },
      error: t(`${dictPrefix}.form.error`),
      success: t(`${dictPrefix}.form.success`),
    }),
    [t],
  );

  const methods = useForm<FormValues>({
    mode: 'onSubmit',
    defaultValues: {
      degreeGrade: '',
      financialStateChecked:
        props.financialBlockade === false ? true : undefined,
      date: '',
    },
  });

  const { control, handleSubmit, formState, watch } = methods;

  const financialStateChecked = watch('financialStateChecked');

  const disabled = disabledProp || !financialStateChecked;

  const requestConfirmation = useCallback(async () => {
    setOpen(true);
    const confirmResponse = await firstValueFrom(confirmResponse$.current);
    setOpen(false);
    return confirmResponse;
  }, []);

  const onSubmit = useCallback(
    async (values: FormValues) => {
      const confirmResponse = await requestConfirmation();
      if (!confirmResponse) {
        return;
      }

      const result = await complete(studyPlanEnrollmentId, {
        distinctionRankId: values.distinctionRank.value,
        degreeGrade: values.degreeGrade,
        date: values.date,
      });

      if (result.error) {
        addToast({
          icon: 'error',
          color: 'danger',
          text: texts.error,
        });
      } else {
        addToast({
          icon: 'success',
          color: 'success',
          text: texts.success,
        });
        onComplete();
      }
    },
    [studyPlanEnrollmentId, requestConfirmation, texts, onComplete],
  );

  return (
    <>
      <FormCore onSubmit={handleSubmit(onSubmit)}>
        <div className="mb-4">
          {props.financialBlockade === null && (
            <Box
              body={
                <Row>
                  <Col className="align-self-center">
                    {texts.financialStatus.description}
                  </Col>
                  <Col xs="auto" className="align-self-center">
                    <div className="mt-4">
                      <CheckInput
                        name="financialStateChecked"
                        label={texts.financialStatus.label}
                        control={control}
                        rules={{
                          validate: (v) => v === true,
                        }}
                        disabled={disabledProp}
                      />
                    </div>
                  </Col>
                </Row>
              }
              color="secondary"
              title=""
              variant="standard"
              fullwidth
            />
          )}
          {props.financialBlockade === true && (
            <p className="text-dark d-flex align-items-center">
              <Icon className="mr-2" name="error" color="danger" size="15px" />
              Estudiante con bloqueo financiero
            </p>
          )}
          {props.financialBlockade === false && (
            <p className="text-dark d-flex align-items-center">
              <Icon
                className="mr-2"
                name="success"
                color="success"
                size="15px"
              />
              Estudiante sin bloqueo financiero
            </p>
          )}
        </div>

        <Row>
          <Col className="pb-3" xs={12} lg={5}>
            <Select
              name="distinctionRank"
              label={texts.distinctionRankLabel}
              options={options}
              control={control}
              disabled={disabled}
              rules={{
                required: msgValidations.required,
              }}
            />
          </Col>
          <Col className="pb-3" xs={12} lg={3}>
            <DateInput
              name="date"
              label={texts.dateLabel}
              control={control}
              disabled={disabled}
              rules={{
                required: msgValidations.required,
              }}
            />
          </Col>
          <Col className="pb-3" xs={12} lg={2}>
            <TextInput
              name="graduationGrade"
              label={texts.graduationGradeLabel}
              value={props.graduationGrade}
              disabled
            />
          </Col>
          <Col className="pb-3" xs={12} lg={2}>
            <TextInput
              name="degreeGrade"
              label={texts.degreeGradeLabel}
              control={control}
              disabled={disabled}
              rules={{
                required: msgValidations.required,
                pattern: rulesPatternDecimal,
              }}
            />
          </Col>
        </Row>
        <Row className="justify-content-end">
          <Col xs={12} lg={4}>
            <Button
              type="submit"
              text={texts.button}
              fullwidth
              disabled={disabled}
              loading={formState.isSubmitting}
            />
          </Col>
        </Row>
      </FormCore>
      <ModalConfirmation
        icon="information"
        onConfirm={(result) => confirmResponse$.current.next(result)}
        title={texts.confirm.title}
        subtitle={texts.confirm.body}
        open={open}
      />
    </>
  );
}
