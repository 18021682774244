import { ReactElement, useCallback, useEffect, useState } from 'react';

import { AxiosResultDefaultError } from '../api/request';
import { getBase } from '../api/requests/base';
import DisplayError from '../components/info/DisplayError';
import Loading from '../components/info/Loading';
import { useLoadingState } from '../hooks/useLoadingState';
import { Account, Permission } from '../types/Auth';

interface UserData extends Account {
  permissions?: Permission['key'][];
}

export interface UserStateProviderLoaderProps<T> {
  children: (props: T) => ReactElement;
}

export function UserStateProviderLoader({
  children,
}: UserStateProviderLoaderProps<UserData | undefined>) {
  const { loading, setLoading } = useLoadingState();

  const [data, setData] = useState<UserData | undefined>();
  const [error, setError] = useState<AxiosResultDefaultError>();

  /* Función que se llama cuando se monta el componente. Trae la info utilizada en la vista */
  const load = useCallback(async () => {
    setLoading(true);

    const response = await getBase();

    if (response.data) {
      setData(response.data);
      setError(undefined);
    } else if (response.error?.status === 403) {
      setData(undefined);
      setError(undefined);
    } else if (response.error?.status === 401) {
      setData(undefined);
      setError(undefined);
    } else if (response.error) {
      setError(response.error);
    }
    setLoading(false);
  }, [setLoading]);

  useEffect(() => {
    load();
  }, [load]);

  if (error) {
    return (
      <DisplayError
        insideCard
        textBody={error.code}
        retryAction={load}
        loadingAction={loading}
      />
    );
  }

  if (loading) {
    return (
      <div className="vh-100 d-flex">
        <div className="m-auto">
          <Loading />
        </div>
      </div>
    );
  }

  return children(data);
}
