import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Card, Col, Row } from 'reactstrap';

import { CurriculumProgressLoader } from './parts/CurriculumProgressLoader';
import { CurriculumProgressLoaderTable } from './parts/CurriculumProgressLoaderTable';
import { BaseLoader as PeriodsLoader } from './parts/PeriodsLoader';
import SearchFilters from './parts/SearchFilters';
import Table from './parts/Table';

export default function CurriculumProgress() {
  const prefix = 'curriculumProgress';
  const { t } = useTranslation();
  const texts = useMemo(
    () => ({
      title: t(`${prefix}.title`),
      body: t(`${prefix}.body`),
    }),
    [t],
  );

  return (
    <Card className="p-4 mt-2 mx-3">
      <h1 className="fs-22 text-primary fw-700">{texts.title}</h1>
      <p className="text-medium fs-16 mt-3 mb-5">{texts.body}</p>
      <CurriculumProgressLoader>
        {({ studyPlanVersions, status }) => (
          <PeriodsLoader>
            {({ data }) => (
              <CurriculumProgressLoaderTable>
                <Row>
                  <Col xs={12}>
                    <SearchFilters
                      studyPlanVersions={studyPlanVersions}
                      status={status}
                      periods={data.periods}
                      campuses={data.campuses}
                    />
                  </Col>
                  <Col xs={12}>
                    <Table />
                  </Col>
                </Row>
              </CurriculumProgressLoaderTable>
            )}
          </PeriodsLoader>
        )}
      </CurriculumProgressLoader>
    </Card>
  );
}
