import { addToast } from '@octano/global-ui';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Card } from 'reactstrap';

import DynamicForm from '../../../../components/DynamicForm/DynamicForm';
import DymanicHealthCareCampus from '../../../../components/DynamicForm/parts/DymanicHealthCareCampus';
import DynamicContact from '../../../../components/DynamicForm/parts/DynamicContact';
import DynamicContactCampus from '../../../../components/DynamicForm/parts/DynamicContactCampus';
import DynamicFamilyContactCampus from '../../../../components/DynamicForm/parts/DynamicFamilyContactCampus';
import { ButtonData } from '../../../../components/DynamicForm/parts/RenderSections';
import { useParameters } from '../../../../hooks/useParameters';
import {
  educationalLevelOptions,
  familyGroupOptions,
  parentalSurvivalOptions,
} from '../../../../utils/selectOptions';
import StudentBackgroundNoSua from '../../../AdmissionAndEnrollments/OnSiteEnrollment/TuitionProcessNoSua/steps/studentFile/parts/StudentBackgroundNoSua';
import StudentBackgroundNoSuaCampus from '../../../AdmissionAndEnrollments/OnSiteEnrollment/TuitionProcessNoSua/steps/studentFile/parts/StudentBackgroundNoSuaCampus';
import { savePostulant } from '../api';

const PostulantUpdateForm = ({
  postulantId,
  defaultValues,
  photo,
  prefix: rootPrefix,
  onError,
}: {
  postulantId: number;
  defaultValues: any;
  photo: string | null;
  prefix: string;
  onError: (code: string, onUnknown?: (() => void) | undefined) => void;
}) => {
  //Custom Hooks
  const { t } = useTranslation();
  const history = useHistory();
  const {
    countryOptions,
    institutionTypeOptions,
    communeOptions,
    regionOptions,
  } = useParameters();

  //Step Prefix
  const prefix = useMemo(() => `${rootPrefix}.studentFile`, [rootPrefix]);

  //Submit form function
  const onSubmit = useCallback(
    async (values: any) => {
      if (postulantId) {
        const responses = Object.entries(values)
          .map(([key, value]) => ({
            fieldName: key,
            value,
          }))
          .filter(({ value }) => value);

        /**
         * si el país es chile igual mando location y custom city
         */
        if (String(values.country?.label).toLowerCase().includes('chile')) {
          responses.push(
            { fieldName: 'location', value: values.commune?.label },
            { fieldName: 'customCity', value: values.city?.label ?? '-' },
          );
        }

        const { data, error } = await savePostulant(postulantId, { responses });

        if (data) {
          addToast({
            icon: 'success',
            color: 'success',
            text: 'Datos guardados correctamente',
          });
        } else if (error) {
          if (error.status === 400) {
            addToast({
              icon: 'error',
              color: 'danger',
              text: t('common.errors.emailDuplicate'),
            });
          } else {
            addToast({
              icon: 'error',
              color: 'danger',
              text: 'Ha ocurrido un error al guardar la información',
            });
          }
        }
      }
    },
    [postulantId, t],
  );

  const sectionComponents = (photo: string | null) => {
    return {
      postulantInformation: (
        <StudentBackgroundNoSua
          postulantId={postulantId}
          isStudentFileUpdate={true}
          photo={photo}
          key={Math.floor(Math.random() * 100)}
        />
      ),
      postulantInformationCampus: (
        <StudentBackgroundNoSuaCampus
          postulantId={postulantId}
          isStudentFileUpdate={true}
          photo={photo}
          key={Math.floor(Math.random() * 100)}
        />
      ),
      academicContact: (
        <DynamicContact
          type="studentFile"
          prefix={`tuitionProcess.onSite.studentFile.academicContactSection`}
          optionalFields={['addressExtra', 'phone']}
          key={Math.floor(Math.random() * 100)}
        />
      ),
      academicContactCampus: (
        <DynamicContactCampus
          type="studentFile"
          prefix={`tuitionProcess.onSite.studentFile.academicContactSection`}
          key={Math.floor(Math.random() * 100)}
          hasEmail={true}
        />
      ),
      healthCareCampus: (
        <DymanicHealthCareCampus
          type="studentFile"
          prefix={`tuitionProcess.onSite.studentFile.healtCareSection`}
          key={Math.floor(Math.random() * 100)}
        />
      ),
      familyContactCampus: (
        <DynamicFamilyContactCampus
          type="sustainerFile"
          key={Math.floor(Math.random() * 100)}
          prefix={`${prefix}.familyGroupSection`}
          disabled={false}
          hasEmail
        />
      ),
    };
  };

  const formButtons: ButtonData[] = [
    {
      type: 'button',
      text: t(`common.actions.cancel`),
      onClick: () => history.goBack(),
      disabledInSubmitting: true,
      fullwidth: true,
      outlined: true,
    },
    {
      type: 'submit',
      text: t(`common.actions.save`),
      disabledInLoading: true,
      disabledInSubmitting: true,
      fullwidth: true,
    },
  ];

  return (
    <Card className="px-4 px-md-5 py-4">
      <DynamicForm
        key={1}
        dynamicFormId={1}
        defaultValues={defaultValues}
        sectionComponents={sectionComponents(photo)}
        options={{
          countryHighSchool: countryOptions,
          typeHighSchool: institutionTypeOptions,
          regionFamily: regionOptions,
          communeFamily: communeOptions,
          healthCareSystem: regionOptions,
          healthCareProvider: communeOptions,
          fatherEducationalLevel: educationalLevelOptions,
          motherEducationalLevel: educationalLevelOptions,
          familyGroup: familyGroupOptions,
          parentalSurvival: parentalSurvivalOptions,
        }}
        onSubmit={onSubmit}
        formButtons={formButtons}
      />
    </Card>
  );
};

export default PostulantUpdateForm;
