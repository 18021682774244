import { addToast, Button } from '@octano/global-ui';
import { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Redirect, useHistory, useParams } from 'react-router-dom';
import { Card, Col, Container, Row } from 'reactstrap';
import { updateScheduleRequest } from '../../../api/requests/schedules';
import GoBackButton from '../../../components/buttons/GoBackButton';
import { useLayoutState } from '../../../hooks/useLayoutState';
import ScheduleForm from './ScheduleForm';
import { ScheduleRecordLoader } from './ScheduleRecordLoader';

const SchedulesRecordEdit = () => {
  const { hideTabs } = useLayoutState();
  useEffect(() => {
    hideTabs();
  }, [hideTabs]);

  let history = useHistory();
  const goBack = useCallback(() => {
    history.push(`/maintainers/schedules`);
  }, [history]);

  const prefix = 'maintainers.scheduleForm';
  const { t } = useTranslation();

  const { id: scheduleId } = useParams<{ id?: string }>();

  const onSubmit = useCallback(
    async (values) => {
      if (!scheduleId) {
        return;
      }
      const response = await updateScheduleRequest(scheduleId, values);
      if (!response.error) {
        addToast({
          icon: 'success',
          color: 'success',
          text: t(`${prefix}.editSuccess`),
        });
        goBack();
      } else {
        return response.error.code;
      }
    },
    [scheduleId, goBack, t],
  );

  if (!scheduleId) {
    return <Redirect to="/maintainers/schedules/record" />;
  }

  return (
    <Container>
      <Card className="p-3 p-md-4">
        <div className="mb-4">
          <GoBackButton onClick={goBack} />
        </div>
        <ScheduleRecordLoader scheduleId={scheduleId}>
          {(schedule) => {
            const defaultValues = {
              code: schedule.code,
              name: schedule.name,
              description: schedule.description ?? '',
              mondayStartTime: schedule.mondayStartTime,
              mondayEndTime: schedule.mondayEndTime,
              tuesdayStartTime: schedule.tuesdayStartTime,
              tuesdayEndTime: schedule.tuesdayEndTime,
              wednesdayStartTime: schedule.wednesdayStartTime,
              wednesdayEndTime: schedule.wednesdayEndTime,
              thursdayStartTime: schedule.thursdayStartTime,
              thursdayEndTime: schedule.thursdayEndTime,
              fridayStartTime: schedule.fridayStartTime,
              fridayEndTime: schedule.fridayEndTime,
              saturdayStartTime: schedule.saturdayStartTime,
              saturdayEndTime: schedule.saturdayEndTime,
              sundayStartTime: schedule.sundayStartTime,
              sundayEndTime: schedule.sundayEndTime,
            };
            return (
              <ScheduleForm
                defaultValues={defaultValues}
                onSubmit={onSubmit}
                readOnly={false}
              >
                {({ isSubmitting }) => (
                  <Row>
                    <Col xs={12} md={4} className="ml-auto">
                      <div>
                        <Button
                          type="submit"
                          text={t(`${prefix}.editSchedule`)}
                          loading={isSubmitting}
                          fullwidth
                        />
                      </div>
                    </Col>
                  </Row>
                )}
              </ScheduleForm>
            );
          }}
        </ScheduleRecordLoader>
      </Card>
    </Container>
  );
};

export default SchedulesRecordEdit;
