import { OutlinedSelect } from '@octano/global-ui';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Card, Col, Row } from 'reactstrap';

import TuitionContinuityDocumentReviewDetailList from './parts/TuitionContinuityDocumentReviewDetailList';
import { TuitionContinuityDocumentReviewLoader } from './parts/TuitionContinuityDocumentReviewLoader';

export default function TuitionContinuityProcessDocumentReview() {
  const { t } = useTranslation();
  const text = useMemo(
    () => ({
      periodSelect: t('TuitionContinuityDocumentReview.form.periodSelect'),
      schoolSelect: t('TuitionContinuityDocumentReview.form.schoolSelect'),
      campusSelect: t('TuitionContinuityDocumentReview.form.campusSelect'),
    }),
    [t],
  );
  return (
    <Card className="mx-4 p-4">
      <TuitionContinuityDocumentReviewLoader>
        {({
          loading,
          periods,
          offerDetail,
          onChangePeriod,
          schoolOptions,
          onChangeSchool,
          campusOptions,
          onChangeCampus,
        }) => (
          <>
            <Row>
              <Col xs={12} md={4} lg={4} className="pb-3">
                <OutlinedSelect
                  name="selection-period"
                  label={text.periodSelect}
                  options={periods.periods}
                  value={periods.current}
                  onChange={onChangePeriod}
                  isClearable={false}
                />
              </Col>
              <Col xs={12} md={4} lg={4}>
                <OutlinedSelect
                  name="selection-campus"
                  label={text.campusSelect}
                  placeholder="Todas las sedes"
                  options={campusOptions}
                  onChange={onChangeCampus}
                  isClearable={true}
                />
              </Col>
              <Col xs={12} md={4} lg={4}>
                <OutlinedSelect
                  name="selection-school"
                  label={text.schoolSelect}
                  placeholder="Todas las facultades"
                  options={schoolOptions}
                  onChange={onChangeSchool}
                  isClearable={true}
                />
              </Col>
            </Row>
            <Row>
              <TuitionContinuityDocumentReviewDetailList
                offerList={offerDetail}
                isLoading={loading}
              />
            </Row>
          </>
        )}
      </TuitionContinuityDocumentReviewLoader>
    </Card>
  );
}
