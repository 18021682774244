import { addToast } from '@octano/global-ui';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { requestActivationEmail } from '../../../../../../api/requests/auth';
import { showToast } from '../../../../../../utils/toast';
import { createAdminAccount, updateAdminAccount } from '../services';
import { AdminAccountForm } from '../types';
import {
  adminAccountBodyAdapter,
  adminAccountUpdateBodyAdapter,
} from '../utils/adminAccountBodyAdapter';

const FORM_ERRORS = {
  MINIMUN_AGE: 'MINIMUN_AGE_FAILED',
  EMAIL: 'EMAIL_ALREADY_EXIST',
};

interface UseActionUserFormProps {
  user: AdminAccountForm;
  refresh: () => void;
}

export const useActionAdminAccountForm = ({
  user,
  refresh,
}: UseActionUserFormProps) => {
  const { t } = useTranslation();

  const [loading, setLoading] = useState<boolean>(false);

  const createAdminAccountS = useCallback(
    async (values: AdminAccountForm) => {
      setLoading(true);
      const { data, error } = await createAdminAccount(
        adminAccountBodyAdapter(values),
      );
      if (!error) {
        await requestActivationEmail(values.email);
      }
      setLoading(false);

      if (data) {
        showToast(false, t);
        setTimeout(() => {
          refresh();
        }, 1000);
      }

      if (error && Array.isArray(error.data.message)) {
        if (
          error.data.message.some((el: string) =>
            el.includes(FORM_ERRORS.MINIMUN_AGE),
          )
        ) {
          const err = Array.isArray(error.data.message)
            ? error.data.message[0]
            : error;
          const minimunAge = parseInt(err.replace(/^\D+/g, ''));

          return addToast({
            icon: 'error',
            color: 'danger',
            text: `Para matricularte debes tener ${
              minimunAge ?? 17
            } años o más.`,
          });
        }
        if (
          error.data.message.some((el: string) =>
            el.includes(FORM_ERRORS.EMAIL),
          )
        ) {
          addToast({
            icon: 'error',
            color: 'danger',
            text: 'El correo institucional ingresado ya está registrado en el sistema.',
          });
        }
      } else if (error) {
        showToast(true, t);
      }
    },
    [t, refresh],
  );

  const updateAdminAccountS = useCallback(
    async (values: AdminAccountForm) => {
      if (!values?.id) {
        return;
      }

      setLoading(true);
      const { error } = await updateAdminAccount(
        values?.id,
        adminAccountUpdateBodyAdapter(values),
      );
      setLoading(false);

      if (!error) {
        showToast(false, t);
        return;
      }

      if (error && Array.isArray(error.data.message)) {
        if (
          error.data.message.some((el: string) =>
            el.includes(FORM_ERRORS.MINIMUN_AGE),
          )
        ) {
          const err = Array.isArray(error.data.message)
            ? error.data.message[0]
            : error;
          const minimunAge = parseInt(err.replace(/^\D+/g, ''));

          return addToast({
            icon: 'error',
            color: 'danger',
            text: `Para matricularte debes tener ${
              minimunAge ?? 17
            } años o más.`,
          });
        }
        if (
          error.data.message.some((el: string) =>
            el.includes(FORM_ERRORS.EMAIL),
          )
        ) {
          addToast({
            icon: 'error',
            color: 'danger',
            text: 'El correo institucional ingresado ya está registrado en el sistema.',
          });
        }
      } else if (error) {
        showToast(true, t);
      }
      return;
    },
    [setLoading, t],
  );

  return {
    createAdminAccountS,
    updateAdminAccountS,
    loading,
  };
};
