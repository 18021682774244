import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { showToast } from '../../../../../../../../utils/toast';
import { createRole } from '../../../../api';
import { IRoleManageForm } from '../../../../types/Roles';

export const useActionRoleManageCreateForm = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const [loading, setLoading] = useState<boolean>(false);
  const createAdminRole = useCallback(
    async (values: IRoleManageForm) => {
      setLoading(true);
      const { data, error } = await createRole(values);
      setLoading(false);
      if (data) {
        showToast(false, t);
        history.push('/users-and-roles/roles');
      }

      if (error) {
        showToast(true, t);
      }
    },
    [t, history],
  );

  return {
    createAdminRole,
    loading,
  };
};
