import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { showToast } from '../../../../../../../../utils/toast';
import { updateRolePermissions } from '../../../../api';

export const useActionRolePermissionsForm = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const [loading, setLoading] = useState<boolean>(false);
  const updatePermissions = useCallback(
    async (roleId: number, values: any) => {
      setLoading(true);
      const { data, error } = await updateRolePermissions(roleId, values);
      if (data) {
        showToast(false, t);
        history.push('/users-and-roles/roles');
      }
      if (error) {
        showToast(true, t);
      }
      setLoading(false);
    },
    [t, history],
  );

  return {
    updatePermissions,
    loading,
  };
};
