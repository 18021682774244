import { ReactElement, useCallback } from 'react';
import { AxiosResultDefaultError } from '../../../../../../api/request';
import { createFetchContext } from '../../../../../../components/contexts/FetchContextFactory';
import { getTeacherInfo } from '../../EducationalBackground/api';
import { TeacherInfoResponse } from '../type';

interface BaseLoaderProps {
  teacherId: number;
  children: ReactElement;
}

const { FetchProvider, FetchConsumer, useFetch } = createFetchContext<
  undefined,
  TeacherInfoResponse,
  AxiosResultDefaultError
>();

export function useTeacherInfoLoader() {
  return useFetch();
}

export const TeacherInfoLoaderConsumer = FetchConsumer;

export default function TeacherInfoLoader({
  teacherId,
  children,
}: BaseLoaderProps) {
  const request = useCallback(async () => {
    return getTeacherInfo(teacherId);
  }, [teacherId]);

  return (
    <FetchProvider request={request} defaultQuery={undefined} fetchImmediately>
      {children}
    </FetchProvider>
  );
}
