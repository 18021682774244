import { Button, Icon, Modal } from '@octano/global-ui';
import { useTranslation } from 'react-i18next';

interface Props {
  isOpen: boolean;
  onConfirm: () => void;
  onCancel: () => void;
  isLoading?: boolean;
}

export default function ExistingAccountConfirmModal({
  isOpen,
  onConfirm = () => null,
  onCancel = () => null,
  isLoading = false,
}: Props) {
  const { t } = useTranslation();
  const prefix = 'teacherActivation.existingAccountConfirmation';

  return (
    <Modal isOpen={isOpen} toggle={() => null}>
      <div className="d-flex flex-column align-items-center">
        <Icon name="warning" size={40} />
        <h1 className="text-dark fs-20 mb-0 mt-5 text-center">
          {t(`${prefix}.title`)}
        </h1>
        <p className="fs-16 my-4 lh-30 text-center">
          {t(`${prefix}.description`)}
        </p>

        <div className="d-flex w-100 flex-wrap mt-3" style={{ gap: 22 }}>
          <Button
            text={t(`common.actions.cancel`)}
            outlined
            size="lg"
            onClick={onCancel}
            className="flex-grow-1"
            style={{ width: 191 }}
            disabled={isLoading}
          />
          <Button
            text={t(`common.actions.confirm`)}
            size="lg"
            onClick={onConfirm}
            className="flex-grow-1"
            style={{ width: 191 }}
            loading={isLoading}
          />
        </div>
      </div>
    </Modal>
  );
}
