import {
  addToast,
  Button,
  FileInput,
  Table,
  TablePagination,
  TextInput,
  useMobile,
} from '@octano/global-ui';
import { CellFormatOptions } from '@octano/global-ui/dist/components/Table/types/TableTypes';
import { useCallback, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { Col, Form, Row } from 'reactstrap';
import {
  attachResolution,
  completeOffer,
  getResolutionUrl,
} from '../../../../api/requests/academicOffers';
import { SectionTitle } from '../../../../components/text';
import TableEmptyContent from '../../../../components/text/TableEmptyContent';
import { PathsLayouts } from '../../../../config/routes';
import { useLayoutState } from '../../../../hooks/useLayoutState';
import useQuery from '../../../../hooks/useQuery';
import { useValidations } from '../../../../hooks/useValidations';
import styles from '../AcademicOffers.module.scss';
import { Resolution } from '../types';
import { useAcademicOfferViewTableInfoLoader } from './AcademicOfferViewTableInfoLoader';

const PAGE_SIZE = 10;

export default function AcademicOfferViewTableInfo(props: {
  academicOfferId: number;
  academicOfferResolutionNumber?: string;
}) {
  console.log('AcademicOfferViewTableInfo');
  const { data, query, updateQuery } = useAcademicOfferViewTableInfoLoader();
  const { academicOfferId, academicOfferResolutionNumber } = props;
  const isMobile = useMobile();
  const { t } = useTranslation();
  const inputFileRef = useRef<HTMLDivElement>(null);
  const currentPage = query.page;
  const totalItems = data.total;
  const totalPages = data.total_pages;
  const [resolutionFileFront, setResolutionFile] = useState<any>(null);
  const history = useHistory();
  const { msgValidations, validateTextNotEmpty } = useValidations();
  const [gettingUrl, setGettingUrl] = useState<boolean>(false);
  const [incorrectFileType, setIncorrectFileType] = useState<boolean>(false);
  const [validFormats, setValidFormats] = useState<string>(
    t('academicOffers.info.fileFormats'),
  );
  const [btnConfirmLoading, setBtnConfirmLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [resolutionFileName, setResolutionFileName] = useState<any>(null);
  const infoPlanesStudyQuota = data.data;
  const hasStudyPlans =
    data.academicOfferResume.AcademicOfferStudyPlanVersionOffers_count > 0;

  const academicOfferResume = useMemo(() => {
    return {
      quota: `${data.academicOfferResume.AcademicOffer_quota}`,
      enrolled: `${data.academicOfferResume.enrolled}`,
      previousQuotas: `${data.academicOfferResume.duplicatedQuota}`,
    };
  }, [data]);
  const labelInputFile = !errorMessage ? validFormats : '';
  const btnDisabledConfirm = errorMessage !== '' || !resolutionFileName;

  const labelHidden = resolutionFileName !== null ? styles.labelHidden : '';

  const methods = useForm<Resolution>({
    mode: 'onSubmit',
    defaultValues: { resolutionNumber: '', fileName: null, file: null },
  });
  const { showErrorModal } = useLayoutState();
  const { handleSubmit, control } = methods;

  const onChangeFile = (e: any) => {
    if (!e) {
      setResolutionFile(null);
      setErrorMessage('');
      setValidFormats(t('academicOffers.info.fileFormats'));
      setIncorrectFileType(false);
      setResolutionFileName(null);
    } else if (e.size >= 5000000) {
      setErrorMessage('limite maximo');
    } else {
      setErrorMessage('');
    }
  };

  const onDropFile = (e: any) => {
    try {
      let fileName = e[0].name.split('.');
      setResolutionFile(e[0]);
      setResolutionFileName({ name: e[0].name });
      if (
        ['pdf', 'jpg', 'xls', 'xlsx', 'docx', 'png'].indexOf(
          fileName[fileName.length - 1].toLowerCase(),
        ) !== -1
      ) {
        setValidFormats(t('academicOffers.info.fileFormats'));
        setIncorrectFileType(false);
      } else {
        setErrorMessage(t('academicOffers.info.incorrectFileFormat'));
        setValidFormats('');
        setIncorrectFileType(true);
      }
    } catch (error) {
      setResolutionFile(null);
      setValidFormats(t('academicOffers.info.fileFormats'));
      setIncorrectFileType(false);
      setResolutionFileName(null);
    }
  };
  const confirmOffer = useCallback(async () => {
    const confirmResult = await completeOffer(Number(academicOfferId));
    const { error } = confirmResult;
    if (error && error.code === 'CONNECTION') {
      showErrorModal();
      return;
    } else if (error) {
      addToast({
        icon: 'error',
        color: 'danger',
        text: t('common.errors.save'),
      });
      return;
    }
    history.push(`${PathsLayouts.academicOffers}/vacant-offers`);
  }, [academicOfferId, history, showErrorModal, t]);

  const onSubmit = useCallback(
    async (values: Resolution) => {
      setBtnConfirmLoading(true);
      if (!hasStudyPlans) {
        addToast({
          icon: 'error',
          color: 'danger',
          text: t('academicOffers.form.noStudyPlans'),
        });
        setBtnConfirmLoading(false);
        return;
      }
      const { resolutionNumber } = values;
      if (resolutionFileName && !incorrectFileType) {
        const requestBody = {
          id: Number(academicOfferId),
          resolutionNumber,
          resolutionFile: resolutionFileFront,
        };
        const result = await attachResolution(requestBody);
        const { error } = result;
        if (error && error.code === 'CONNECTION') {
          showErrorModal();
        } else if (error) {
          addToast({
            icon: 'error',
            color: 'danger',
            text: t('common.errors.save'),
          });
        }
        confirmOffer();
      } else {
        setErrorMessage(
          incorrectFileType
            ? t('academicOffers.info.incorrectFileFormat')
            : t('academicOffers.info.noFileMessage'),
        );
        setValidFormats('');
        setIncorrectFileType(true);
      }
      setBtnConfirmLoading(false);
    },
    [
      academicOfferId,
      confirmOffer,
      hasStudyPlans,
      incorrectFileType,
      resolutionFileFront,
      resolutionFileName,
      showErrorModal,
      t,
    ],
  );

  const getUrl = async () => {
    setGettingUrl(true);
    const result = await getResolutionUrl(Number(academicOfferId));
    const { error, data } = result;
    setGettingUrl(false);
    if (error && error.code === 'CONNECTION') {
      showErrorModal();
    } else if (error) {
      addToast({
        icon: 'error',
        color: 'danger',
        text: t('common.errors.get'),
      });
    }
    if (data) {
      window.open(data, '_blanck');
    }
  };

  const columnFacultyName = {
    columnName: 'name',
    headerText: t('academicOffers.info.faculty'),
    width: '40%',
    cellFormat: (options: CellFormatOptions<any>) => {
      return <div>{options.row.School_name}</div>;
    },
  };
  const columnPreviousQuotas = {
    columnName: 'name',
    headerText: (
      <div className="text-center">
        {isMobile
          ? t('academicOffers.info.previousQuotasMin')
          : t('academicOffers.info.previousQuotas')}
      </div>
    ),
    width: '20%',
    cellFormat: (options: CellFormatOptions<any>) => {
      return <div className="text-center">{options.row.duplicatedQuota}</div>;
    },
  };
  const columsEnrolled = {
    columnName: 'name',
    headerText: (
      <div className="text-center">{t('academicOffers.info.enrolled')}</div>
    ),
    width: '20%',
    cellFormat: (options: CellFormatOptions<any>) => {
      return <div className="text-center">{options.row.enrolled}</div>;
    },
  };
  const columnQuota = {
    columnName: 'name',
    headerText: (
      <div className="text-center">
        {isMobile
          ? t('academicOffers.info.newQuotasMin')
          : t('academicOffers.info.newQuotas')}
      </div>
    ),
    width: '20%',
    cellFormat: (options: CellFormatOptions<any>) => {
      return <div className="text-center">{options.row.quota}</div>;
    },
  };

  const columns = isMobile
    ? [columnFacultyName, columnPreviousQuotas, columnQuota]
    : [columnFacultyName, columnPreviousQuotas, columsEnrolled, columnQuota];

  const queryParam = useQuery();
  const confirm = queryParam.get('confirm') === 'true';

  const ChangeTextButtonFileUpload = useCallback(() => {
    if (inputFileRef.current) {
      let element = inputFileRef.current.querySelector('button span');
      if (element) element.textContent = t('academicOffers.info.adResolution');
    }
  }, [t]);

  const initialized = useRef(false);

  useLayoutEffect(() => {
    if (!initialized.current) {
      ChangeTextButtonFileUpload();
      initialized.current = true;
    }
  }, [inputFileRef, ChangeTextButtonFileUpload]);

  function DownloadResolution({
    resolutionNum,
  }: {
    resolutionNum: null | string;
  }) {
    if (resolutionNum === null) {
      return null;
    }
    return (
      <Button
        type="button"
        text={t('academicOffers.info.downloadDoc')}
        size="md"
        loading={gettingUrl}
        icon="download"
        outlined
        color="primary"
        onClick={getUrl}
        style={{ padding: '8px 32px', margin: 'auto' }}
      />
    );
  }

  function cancel(): void {
    history.push(
      `${PathsLayouts.academicOffers}/vacant-offers/record/${academicOfferId}`,
    );
  }

  return (
    <>
      {confirm && (
        <p className="fs-18">{t('academicOffers.info.bodyInfoConfirmation')}</p>
      )}
      <Row
        className="mt-2"
        style={{
          margin: '0 auto',
          width: 'calc(100%)',
          maxWidth: '920px',
        }}
      >
        <Col md={4} className="p-0">
          <TextInput
            name="previousQuotas"
            disabled
            value={academicOfferResume.previousQuotas}
            label={t('academicOffers.info.previousQuotas')}
          />
        </Col>
        <Col md={4} className="p-0">
          <TextInput
            name="enrolled"
            disabled
            value={academicOfferResume.enrolled}
            label={t('academicOffers.info.enrolled')}
          />
        </Col>
        <Col md={4} className="p-0">
          <TextInput
            name="newQuotasTotal"
            disabled
            value={academicOfferResume.quota}
            label={t('academicOffers.info.newQuotasTotal')}
          />
        </Col>
      </Row>
      <div
        className={styles.academicOffersCreateStep2Table}
        style={{
          margin: '24px auto',
          width: 'calc(100%)',
          maxWidth: '920px',
          fontWeight: 'normal',
        }}
      >
        <Table
          columns={columns}
          data={infoPlanesStudyQuota}
          noResultsText={
            <TableEmptyContent
              title={t('academicOffers.form.studyPlansTableEmptyTitle')}
              subtitle={t('academicOffers.info.periodEmpatySubtitle')}
            />
          }
        />

        {totalPages > 1 && (
          <TablePagination
            pagination={{
              currentPage: currentPage,
              itemsPerPage: PAGE_SIZE,
              onChangePage: (page) => updateQuery({ page }),
              totalItems,
              totalPages,
            }}
          />
        )}
      </div>
      {confirm ? (
        <FormProvider {...methods}>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <SectionTitle text={t('academicOffers.info.adResolution')} />
            <p>{t('academicOffers.info.body2')}</p>
            <Row>
              <Col md={5}>
                <TextInput
                  name="resolutionNumber"
                  value={''}
                  control={control}
                  label={t('academicOffers.info.nResolution')}
                  rules={{
                    required: msgValidations.required,
                    minLength: {
                      value: 1,
                      message: t('common.validations.minLength', {
                        length: 1,
                      }),
                    },
                    maxLength: {
                      value: 50,
                      message: t('common.validations.minLength', {
                        length: 50,
                      }),
                    },
                    validate: validateTextNotEmpty,
                  }}
                />
              </Col>
              <Col md={7}>
                <div
                  className={`${styles.fileUpload} ${labelHidden} ${styles.messageError}`}
                  ref={inputFileRef}
                >
                  <FileInput
                    name="file"
                    label={t('academicOffers.info.doc')}
                    maxFiles={1}
                    onChange={onChangeFile}
                    onDropAccepted={onDropFile}
                    value={resolutionFileName}
                    onFileDialogCancel={() => 1}
                    error={errorMessage}
                    accept={
                      'image/png, image/jpeg, application/pdf, application/xls, application/xlsx, application/docx'
                    }
                  />
                </div>
                <span>{labelInputFile}</span>
              </Col>
            </Row>
            <Row className="mt-5">
              <Col md={4} />
              <Col md={4} className="mb-2">
                <Button
                  type="button"
                  text={t(`common.actions.cancel`)}
                  outlined
                  fullwidth
                  onClick={cancel}
                />
              </Col>
              <Col md={4} className="mb-2">
                <Button
                  fullwidth
                  type="submit"
                  text={t(`academicOffers.info.confirm`)}
                  loading={btnConfirmLoading}
                  disabled={btnDisabledConfirm}
                />
              </Col>
            </Row>
          </Form>
        </FormProvider>
      ) : (
        <Row
          className="mt-8"
          style={{
            margin: 'auto',
            width: 'calc(100% - 32px)',
            maxWidth: '920px',
          }}
        >
          <Col>
            <TextInput
              name="nResolution"
              value={`${academicOfferResolutionNumber}`}
              label={t('academicOffers.info.nResolution')}
            />
          </Col>
          <Col style={{ display: 'flex' }}>
            {academicOfferResolutionNumber && (
              <DownloadResolution
                resolutionNum={academicOfferResolutionNumber}
              />
            )}
          </Col>
        </Row>
      )}
    </>
  );
}
