import { STUDENTS_DEGREE_STUDENT } from '../../../api/endpoints';
import request from '../../../api/request';
import { Base, CreateRenditionParams } from '../types';
import {
  ResponseCreateRendition,
  ResponseGetDegreeActivity,
} from '../types/response';

export const getBase = (studyPlanEnrollmentId: number | string) => {
  return request<Base>(STUDENTS_DEGREE_STUDENT.GET_BASE(studyPlanEnrollmentId));
};

/**
 * Obtiene el listado de los filtros a mostrar para el reporte en excel de solicitudes de los estudiantes
 * @returns
 */
export const getDegreeActivity = (degreeProcessActivityId: number | string) => {
  const URL = STUDENTS_DEGREE_STUDENT.GET_ACTIVITY(degreeProcessActivityId);
  const method = 'GET';
  return request<ResponseGetDegreeActivity>(URL, { method });
};

export const createRendition = (
  degreeProcessActivityId: number | string,
  data: CreateRenditionParams,
) => {
  return request<ResponseCreateRendition>(
    STUDENTS_DEGREE_STUDENT.POST_CREATE_RENDITION(degreeProcessActivityId),
    {
      method: 'POST',
      data,
    },
  );
};

export const complete = (
  studyPlanEnrollmentId: number | string,
  data: {
    distinctionRankId: number | string;
    degreeGrade: string;
    date: string;
  },
) => {
  return request(STUDENTS_DEGREE_STUDENT.POST_COMPLETE(studyPlanEnrollmentId), {
    method: 'POST',
    data,
  });
};
