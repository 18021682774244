import { ReactElement, useCallback } from 'react';
import { Col } from 'reactstrap';

import { createFetchContext } from '../../../components/contexts/FetchContextFactory';
import DisplayError from '../../../components/info/DisplayError';
import Loading from '../../../components/info/Loading';
import { getAllDiscounts } from '../../api';
import { Discount } from '../../types';

interface DiscountsLoaderProps {
  children: ReactElement;
}

const { FetchProvider, FetchConsumer, useFetch } = createFetchContext<
  undefined,
  Discount[]
>();

export function useDiscountLoader() {
  return useFetch();
}

export const DiscountsLoaderConsumer = FetchConsumer;

export function DiscountsLoader({ children }: DiscountsLoaderProps) {
  const request = useCallback(() => {
    return getAllDiscounts();
  }, []);

  return (
    <FetchProvider request={request} defaultQuery={undefined} fetchImmediately>
      <FetchConsumer>
        {({ loading, error, refresh }) => {
          if (error) {
            if (error.code === 'HTTP_ERROR' && error.status !== 404) {
              return (
                <Col md={12}>
                  <DisplayError
                    insideCard
                    textBody={error.code}
                    retryAction={refresh}
                    loadingAction={loading}
                  />
                </Col>
              );
            }
          }
          if (loading) {
            return (
              <Col md={12}>
                <div className="mx-3">
                  <Loading insideCard />
                </div>
              </Col>
            );
          }
          return children;
        }}
      </FetchConsumer>
    </FetchProvider>
  );
}
