// Components
import { CheckInput, Select, TextInput } from '@octano/global-ui';
// Hooks
import { useContext, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'reactstrap';

import { useValidations } from '../../../../hooks/useValidations';
import { InterestsCalculatorVariant } from '../../../../types/tariffTypes';
import { formatToOnlyPositiveScaledIntegers } from '../../../../utils/currency';
// Utils
import { FinantialSettingsContext } from '../contexts/finantial-settings.context';
// Types
import { FinantialConfig } from '../interfaces/finantial-config.interface';

// Render
export default function InterestsRatesSection() {
  const { t } = useTranslation();
  const prefix = 'configurations.finantial.interestsRates';

  const finantialSettingsContext = useContext(FinantialSettingsContext);

  const { msgValidations } = useValidations();

  const { control, watch, setValue } = useFormContext<FinantialConfig>();

  const [
    maxInstallments,
    noInterestsInstallments,
    interestCalculatorVariant,
    hasInterests,
  ] = watch([
    'maxInstallments',
    'noInterestsInstallments',
    'interestCalculatorVariant',
    'hasInterests',
  ]);

  const maxInstallmentsOptions = useMemo(() => {
    return Array.from({ length: 61 }, (_v, index) => ({
      value: index,
      label: `${index}`,
    }));
  }, []);

  const noInterestsInstallmentsOptions = useMemo(() => {
    return Array.from(
      { length: maxInstallments ? maxInstallments + 1 : 0 },
      (_v, index) => ({
        value: index,
        label: `${index}`,
      }),
    );
  }, [maxInstallments]);

  const interestCalculatorVariantOptions = useMemo(
    () => [
      {
        value: InterestsCalculatorVariant.ProrateInterest,
        label: t(`${prefix}.calculatorVariants.prorate`),
      },
      {
        value: InterestsCalculatorVariant.SkipInterest,
        label: t(`${prefix}.calculatorVariants.skip`),
      },
    ],
    [t],
  );

  return (
    <Row className="mt-4">
      <Col xs={12}>
        <h2 className="text-primary fs-16 fw-600 text-uppercase">
          {t(`${prefix}.title`)}
        </h2>
      </Col>
      <Col sm={12} md={6} lg={3}>
        <Select
          name="maxInstallments"
          label={t(`${prefix}.inputs.maxInstallments`)}
          options={maxInstallmentsOptions}
          value={{
            label: `${maxInstallments ?? 0}`,
            value: maxInstallments ?? 0,
          }}
          disabled={finantialSettingsContext.disabled}
          onChange={(e) => {
            setValue('maxInstallments', e?.value);
            if (e?.value) {
              if (e?.value <= noInterestsInstallments) {
                setValue('noInterestsInstallments', e?.value);
              }
            } else {
              setValue('noInterestsInstallments', 0);
            }
          }}
          rules={{ required: msgValidations.required }}
        />
        <CheckInput
          name="hasInterests"
          label={t(`${prefix}.inputs.hasInterests`)}
          control={control}
          disabled={finantialSettingsContext.disabled}
        />
      </Col>
      {!!hasInterests && (
        <>
          <Col sm={12} md={6} lg={3}>
            <Select
              name="interestCalculatorVariant"
              label={t(`${prefix}.inputs.interestCalculatorVariant`)}
              options={interestCalculatorVariantOptions}
              value={
                interestCalculatorVariantOptions?.find(
                  (e) => e?.value === interestCalculatorVariant,
                ) ?? undefined
              }
              disabled={finantialSettingsContext.disabled}
              onChange={(e) => setValue('interestCalculatorVariant', e?.value)}
              rules={{ required: msgValidations.required }}
            />
          </Col>
          <Col sm={12} md={6} lg={3}>
            <TextInput
              control={control}
              name="interestRate"
              label={t(`${prefix}.inputs.interestRate`)}
              disabled={finantialSettingsContext.disabled}
              formatter={formatToOnlyPositiveScaledIntegers}
            />
          </Col>
          <Col sm={12} md={6} lg={3}>
            <Select
              name="noInterestsInstallments"
              label={t(`${prefix}.inputs.noInterestsInstallments`)}
              options={noInterestsInstallmentsOptions}
              value={
                maxInstallments
                  ? {
                      label: `${noInterestsInstallments ?? 0}`,
                      value: noInterestsInstallments ?? 0,
                    }
                  : undefined
              }
              disabled={finantialSettingsContext.disabled || !maxInstallments}
              onChange={(e) => setValue('noInterestsInstallments', e?.value)}
            />
          </Col>
        </>
      )}
    </Row>
  );
}
