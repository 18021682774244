export function format(
  num: number,
  options?: Omit<Intl.NumberFormatOptions, 'style'>,
) {
  return new Intl.NumberFormat('default', {
    style: 'percent',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
    ...options,
  }).format(num / 100);
}
