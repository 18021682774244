import { TEACHER_FILE } from '../endpoints';
import request from '../request';
import {
  PaginationQueryDto,
  PaginationRequestType,
} from '../../types/paginationRequestType';
import { TeacherFileFiltersForm } from '../../views/TeacherManagement/TeacherFile/TeacherList/interfaces/teacher-file-filters-form.interface';
import { TeacherFileItem } from '../../views/TeacherManagement/TeacherFile/TeacherList/interfaces/teacher-file-item.interface';
import { TeacherFileFilters } from '../../views/TeacherManagement/TeacherFile/TeacherList/interfaces/teacher-file-filters.interface';

/**
 * Obtiene el listado de docentes
 */
export const getTeachers = (params: PaginationQueryDto<TeacherFileFilters>) =>
  request<PaginationRequestType<TeacherFileItem>>(TEACHER_FILE.TEACHERS, {
    params,
  });

/**
 * Obtiene los datos para filtrar los docentes
 */
export const getForm = () => request<TeacherFileFiltersForm>(TEACHER_FILE.FORM);
