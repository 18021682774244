import { addToast } from '@octano/global-ui';
import dayjs from 'dayjs';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Card } from 'reactstrap';

import {
  downloadAllGraduatesByFilter,
  getGraduatesList,
} from '../../api/requests/graduationProcessMassive';
import { GraduateRow } from '../../types/graduationProcessMassive';
import { downloadFromBlob } from '../../utils/blob';
import { GraduatesTable } from './parts';
import { SearchControls, SearchParams } from './parts/SearchControls';

const dictPrefix = 'graduatesList';
const ITEMS_PER_PAGE = 10;

export default function GraduatesList() {
  const { t } = useTranslation();
  const [isLoadingTable, setIsTableLoading] = useState(false);
  const [isDownloadLoading, setDownloadLoading] = useState(false);
  const [tableData, setTableData] = useState<GraduateRow[]>([]);
  const [totalPages, setTotalPages] = useState(0);
  const [totalGraduates, setTotalGraduates] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [filters, setFilters] = useState<SearchParams>({});

  const handleSearch = useCallback(
    async ({
      searchParams,
      page = 0,
    }: {
      searchParams?: SearchParams;
      page: number;
    }) => {
      setIsTableLoading(true);
      const { data, error } = await getGraduatesList({
        items: ITEMS_PER_PAGE,
        page: page,
        searchBy: searchParams?.searchBy,
        search:
          searchParams?.searchBy === 'identificator'
            ? searchParams?.search?.replace(/[^a-z0-9]/gi, '')
            : searchParams?.search,
        cohortPeriodId: searchParams?.cohortPeriodId,
        schoolId: searchParams?.schoolId,
        studyPlanId: searchParams?.studyPlanId,
        campusId: searchParams?.campusId,
        graduationPeriodId: searchParams?.graduationPeriodId,
      });

      if (error) {
        addToast({
          text: t(`${dictPrefix}.errorOnSearch`),
          color: 'danger',
          icon: 'error',
        });
      } else {
        setTableData(data?.data ?? []);
        setTotalPages(data?.total_pages || 0);
        setTotalGraduates(data?.total || 0);
      }
      setIsTableLoading(false);
    },
    [t],
  );

  const handleDownload = useCallback(async () => {
    setDownloadLoading(true);
    const { data, error, status } = await downloadAllGraduatesByFilter({
      searchBy: filters?.searchBy,
      search:
        filters?.searchBy === 'identificator'
          ? filters?.search?.replace(/[^a-z0-9]/gi, '')
          : filters?.search,
      cohortPeriodId: filters?.cohortPeriodId,
      schoolId: filters?.schoolId,
      studyPlanId: filters?.studyPlanId,
      campusId: filters?.campusId,
      graduationPeriodId: filters?.graduationPeriodId,
    });

    if (error) {
      addToast({
        text: t(`${dictPrefix}.generatingFile.errorOnDownload`),
        color: 'danger',
        icon: 'error',
      });
    }
    if (status === 200) {
      addToast({
        text: t(`${dictPrefix}.generatingFile.download`),
        color: 'success',
        icon: 'success',
      });
      const blob = new Blob([data], { type: 'vnd.ms-excel' });
      const todaysDate = dayjs().format('DD_MMYY');
      const fileName = `ListaEgresados_${todaysDate}.xlsx`;
      downloadFromBlob(blob, fileName);
    }
    setDownloadLoading(false);
  }, [filters, t]);

  const handleClear = async () => {
    setTableData([]);
    setFilters({});
    setCurrentPage(0);
  };

  useEffect(() => {
    handleSearch({ searchParams: filters, page: currentPage });
  }, [handleSearch, currentPage, filters]);

  return (
    <Card className="mx-3 p-4">
      <h1 className="text-uppercase text-primary fs-20 fw-600">
        {t(`${dictPrefix}.searchTitle`)}
      </h1>

      <p className="text-medium fs-16 mt-3 mb-5">
        {t(`${dictPrefix}.searchDescription`)}
      </p>
      <div className="mb-5">
        <SearchControls
          onSearch={(filters) => {
            setCurrentPage(0);
            setFilters(filters);
          }}
          onClear={handleClear}
          onDownload={handleDownload}
          downloadIsAvailable={tableData.length > 0}
          isDownloadLoading={isDownloadLoading}
        />
      </div>

      <GraduatesTable
        isLoadingResults={isLoadingTable}
        data={tableData}
        totalPages={totalPages}
        totalGraduates={totalGraduates}
        currentPage={currentPage}
        onChangePage={(page) => {
          setCurrentPage(page);
        }}
        hasFilters={filters?.hasFilters}
      />
    </Card>
  );
}
