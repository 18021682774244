import { ReactElement, useCallback } from 'react';

import {
  AxiosResult,
  AxiosResultDefaultError,
} from '../../../../../../api/request';
import { AuthenticationError } from '../../../../../../api/requests/tuitionProcess';
import { createFetchContext } from '../../../../../../components/contexts/FetchContextFactory';
import { getAdminRoleList as getAdminRoletList } from '../../api';
import { onSearch } from '../../types/Filters';
import { ITEMS_PER_PAGE_DEFAULT, ResponseAdminRoles } from '../../types/Roles';

interface BaseLoaderProps {
  children: ReactElement;
}

const { FetchProvider, FetchConsumer, useFetch } = createFetchContext<
  onSearch,
  ResponseAdminRoles,
  AuthenticationError | AxiosResultDefaultError
>();

export function useAdminRoleLoader() {
  const context = useFetch();

  return {
    error: context.error,
    loading: context.loading,
    data: context.data,
    query: context.query,
    updateQuery: context.updateQuery,
  };
}

export const AdminRoleLoaderConsumer = FetchConsumer;

export default function AdminRoleLoader({ children }: BaseLoaderProps) {
  const request = useCallback(
    async (
      query: onSearch,
    ): Promise<AxiosResult<ResponseAdminRoles, AxiosResultDefaultError>> => {
      return getAdminRoletList({
        items: ITEMS_PER_PAGE_DEFAULT,
        page: query.page - 1,
        search: query?.searchParams?.search,
      });
    },
    [],
  );

  return (
    <FetchProvider
      request={request}
      defaultQuery={{ page: 1 }}
      fetchImmediately
    >
      {children}
    </FetchProvider>
  );
}
