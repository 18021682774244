import { SelectOptionType } from '@octano/global-ui';
import { DefaultValues } from 'react-hook-form';
import { format as formatRut } from 'rut.js';

import { FieldsStudentNoSuaFile } from '../types/studentFileNoSuaTypes';
import { StudentFileResponse } from '../types/studentFileSectionTypes';
import { getSelectedOption } from './selectFormat';
import {
  educationalLevelOptions,
  familyGroupOptions,
  healthCareProviderOptions,
  healthCareSystemOptions,
  healthInsuranceOptions,
  maritalStatusOptions,
  parentalSurvivalOptions,
  sexCIOptions,
} from './selectOptions';

export const formatCommonDefaultValuesStudentFile = (
  data: StudentFileResponse,
  communeOptions: SelectOptionType[],
  regionOptions: SelectOptionType[],
  countriesOptions: SelectOptionType[],
) => {
  const {
    contact,
    familyContact,
    emergencyContact,
    parentalEducationalBackground,
    healthCare,
    student,
    studyPlan,
  } = data;

  const selectedCommune = getSelectedOption(contact?.communeId, communeOptions);
  const selectedCommuneFamily = getSelectedOption(
    familyContact?.communeId,
    communeOptions,
  );

  return {
    // Student
    rut: student?.rut ? formatRut(student?.rut) : undefined,
    passportCountry: getSelectedOption(
      student.passport?.countryId ?? undefined,
      countriesOptions,
    ),
    passportNumber: student.passport?.number,
    namesStudent: student.names,
    paternalSurname: student.paternalName,
    maternalSurname: student.maternalName,
    officialEmail: student?.account?.email,
    personalEmail: student?.account?.personalEmail,
    birthday: student?.birthday,
    sexID: getSelectedOption(student.sexId ?? undefined, sexCIOptions),
    maritalStatus: getSelectedOption(
      student.maritalStatusId ?? undefined,
      maritalStatusOptions,
    ),
    // Student - Education
    cv: studyPlan?.code,
    studyPlan: studyPlan?.name,
    campus: studyPlan?.campus,
    schedule: studyPlan?.schedule,
    // Contact
    addressStreet: contact?.addressStreet,
    addressNumber: contact?.addressNumber,
    addressExtra: contact?.addressExtra ?? undefined,
    region: getSelectedOption(selectedCommune?.regionId, regionOptions),
    commune: selectedCommune,
    phone: contact?.phone ?? undefined,
    cellPhone: contact?.cellPhone,
    email: contact?.email,
    // Family
    addressStreetFamily: familyContact?.addressStreet,
    addressNumberFamily: familyContact?.addressNumber,
    addressExtraFamily: familyContact?.addressExtra ?? undefined,
    regionFamily: getSelectedOption(
      selectedCommuneFamily?.regionId,
      regionOptions,
    ),
    communeFamily: selectedCommuneFamily,
    phoneFamily: familyContact?.phone ?? undefined,
    cellphoneFamily: familyContact?.cellPhone,
    emailFamily: familyContact?.email,
    // Emergency
    emergencyNames: emergencyContact?.name,
    mobilePhoneNumberEmergency: emergencyContact?.cellPhone,
    emailEmergency: emergencyContact?.email,
    // Parental
    fatherEducationalLevel: getSelectedOption(
      parentalEducationalBackground?.educationalLevelPaternalId,
      educationalLevelOptions,
    ),
    motherEducationalLevel: getSelectedOption(
      parentalEducationalBackground?.educationalLevelMaternalId,
      educationalLevelOptions,
    ),
    familyGroup: getSelectedOption(
      parentalEducationalBackground?.familyGroupId,
      familyGroupOptions,
    ),
    parentalSurvival: getSelectedOption(
      parentalEducationalBackground?.parentalSurvivalId,
      parentalSurvivalOptions,
    ),
    // Health
    healthCareSystem: getSelectedOption(
      healthCare?.systemId,
      healthCareSystemOptions,
    ),
    healthCareProvider: getSelectedOption(
      healthCare?.providerId ?? undefined,
      healthCareProviderOptions,
    ),
    healthCareContributorID: healthCare?.contributorRut
      ? formatRut(healthCare.contributorRut)
      : undefined,
    healthInsurance: getSelectedOption(
      healthCare?.hasInsurance !== undefined
        ? +healthCare?.hasInsurance
        : undefined,
      healthInsuranceOptions,
    ),
    healthInsuranceContributorID: healthCare?.insuranceContributorRut
      ? formatRut(healthCare.insuranceContributorRut)
      : undefined,
    phoneNumberHealthCare: healthCare?.insuranceCellPhone ?? undefined,
    otherHealthCare: healthCare?.otherSystem,
  };
};

export const formatDefaultValuesStudentFileNoSua = (
  data: StudentFileResponse,
  communeOptions: SelectOptionType[],
  regionOptions: SelectOptionType[],
  countries: SelectOptionType[],
  institutionTypes: SelectOptionType[],
): DefaultValues<FieldsStudentNoSuaFile> => {
  return {
    ...formatCommonDefaultValuesStudentFile(
      data,
      communeOptions,
      regionOptions,
      countries,
    ),
    // Student - Nationality
    nationality: getSelectedOption(data.student?.nationality?.id, countries),
    // Educational Background
    highSchool: data.educationalBackground.highSchool,
    countryHighSchool: getSelectedOption(
      data.educationalBackground?.country?.id,
      countries,
    ),
    regionHighSchool: getSelectedOption(
      data.educationalBackground?.region?.id,
      regionOptions,
    ),
    communeHighSchool: getSelectedOption(
      data.educationalBackground?.commune?.id,
      communeOptions,
    ),
    typeHighSchool: getSelectedOption(
      data.educationalBackground.institutionType?.id,
      institutionTypes,
    ),
    graduationYear: data.educationalBackground.graduationYear,
  };
};
