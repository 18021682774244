import { Button, Icon, Modal } from '@octano/global-ui';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'reactstrap';

interface VersionStatusModalProps {
  show: boolean;
  onCloseModal: () => void;
  title: string;
  subtitle: string;
}

const VersionStatusModal = ({
  show,
  onCloseModal,
  title,
  subtitle,
}: VersionStatusModalProps) => {
  const { t } = useTranslation();

  return (
    <Modal
      isOpen={show}
      toggle={() => {
        onCloseModal();
      }}
      size="md"
      unmountOnClose
    >
      <div className="d-flex flex-column align-items-center">
        <Icon name="warning" size="52px" color="secondary" />
        <div className="mt-4 fw-600 text-black fs-22 text-center">{title}</div>
        <div
          className={`modal-status-version mx-auto mt-4 mb-4 fs-18 text-center`}
        >
          {subtitle}
        </div>
      </div>
      <Row>
        <Col
          xs={{ order: 2, size: 12 }}
          md={{ order: 1, size: 12 }}
          className="m-auto"
        >
          <div>
            <Button
              type="button"
              text={t(`common.actions.ok`)}
              onClick={() => onCloseModal()}
              fullwidth
            />
          </div>
        </Col>
      </Row>
    </Modal>
  );
};
export default VersionStatusModal;
