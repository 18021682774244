import { ColumnTable } from '@octano/global-ui';
import { useTranslation } from 'react-i18next';
const dictPrefix = 'studentCurrentAccount.tabs.paymentHistory.table';

interface actionsColumns {
  onClickDetail: (row: any) => void;
}

export default function useColumns(parms: actionsColumns): ColumnTable[] {
  const { onClickDetail } = parms;
  const { t } = useTranslation();

  return [
    {
      columnName: 'amount',
      headerText: t(`${dictPrefix}.amount`),
      tdClassName: 'text-center',
      thClassName: 'text-center',
      cellFormat: ({ row }) => {
        return row.amount;
      },
    },
    {
      columnName: 'paymentDate',
      headerText: t(`${dictPrefix}.paymentDate`),
      tdClassName: 'text-center',
      thClassName: 'text-center',
    },
    {
      columnName: 'paymentType',
      headerText: t(`${dictPrefix}.paymentType`),
      tdClassName: 'text-center',
      thClassName: 'text-center',
    },
    {
      columnName: 'paymentChannel',
      headerText: t(`${dictPrefix}.paymentChannel`),
      tdClassName: 'text-center',
      thClassName: 'text-center',
    },
    {
      columnName: 'voucherId',
      headerText: t(`${dictPrefix}.voucherId`),
      tdClassName: 'text-center',
      thClassName: 'text-center',
    },
    {
      columnName: 'transactionCode',
      headerText: t(`${dictPrefix}.transactionCode`),
      tdClassName: 'text-center',
      thClassName: 'text-center',
    },
    {
      columnName: 'paidBy',
      headerText: t(`${dictPrefix}.paidBy`),
      tdClassName: 'text-center',
      thClassName: 'text-center',
    },
    {
      columnName: 'action',
      headerText: t(`${dictPrefix}.action`),
      tdClassName: 'text-center',
      thClassName: 'text-center',
      width: 180,
      cellFormat: ({ row }) => {
        return (
          <div className="d-flex justify-content-center">
            <button
              className="btn btn-primary"
              onClick={() => onClickDetail(row)}
            >
              {t(`${dictPrefix}.viewDetail`)}
            </button>
          </div>
        );
      },
    },
  ];
}
