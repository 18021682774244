import {
  Button,
  CellFormatOptions,
  ColumnTable,
  Icon,
  Table,
  TablePagination,
} from '@octano/global-ui';
import dayjs from 'dayjs';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Badge, Container } from 'reactstrap';
import { getTuitionContinuityValuesRequest } from '../../../api/requests/tuitionContinuityTariff';
import DisplayError from '../../../components/info/DisplayError';
import TableEmptyContent from '../../../components/text/TableEmptyContent';
import { useLayoutState } from '../../../hooks/useLayoutState';
import { useLoadingState } from '../../../hooks/useLoadingState';
import { TuitionTable } from '../../../types/periodTuition';
import { moneyFormatter } from '../../../utils/currency';
import styles from '../../AdmissionAndEnrollments/TariffAndTuition/style.module.scss';
import { TuitionModal } from '../../AdmissionAndEnrollments/TariffAndTuition/Tuition/parts/TuitionModal';

export default function TcTuition() {
  const { t } = useTranslation();
  const prefix = 'tuitionContinuityTariff.tuition';
  const { showTabs } = useLayoutState();
  const {
    loading,
    setLoading,
    errorLoading,
    setErrorLoading,
  } = useLoadingState();

  const [tuitions, setTuitions] = useState<TuitionTable[]>([]);
  const [totalItems, setTotalItems] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(1);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [showPagination, setShowPagination] = useState<boolean>(false);

  const [showModal, setShowModal] = useState<boolean>(false);
  const [recordToEdit, setRecordToEdit] = useState(null);

  useEffect(() => {
    showTabs();
  }, [showTabs]);

  const getTuitionData = useCallback(
    async (items: number = 10, page: number = 0) => {
      setLoading(true);
      const { data, error } = await getTuitionContinuityValuesRequest(
        items,
        page,
      );

      if (error) {
        setErrorLoading(error.code);
        return;
      }

      if (data) {
        setErrorLoading(undefined);
        const { data: tuitionsItems = [], total = 0, total_pages = 1 } = data;
        setTuitions(
          tuitionsItems.map<TuitionTable>((t) => ({
            code: t.id,
            name: t.period.name,
            value: t.continuityValue ?? 0,
            periodId: t.period.id,
            startDate: String(t.period.startDate),
            endDate: String(t.period.endDate),
            status: t.period.active,
          })),
        );
        setTotalItems(total);
        setTotalPages(total_pages);
        setShowPagination(true);
        setCurrentPage(1);
      }

      setLoading(false);
    },
    [setErrorLoading, setLoading],
  );

  const columns = useMemo<ColumnTable[]>(() => {
    const formatDate = 'DD/MM/YYYY';
    return [
      {
        columnName: 'code',
        headerText: t(`${prefix}.fields.code`),
        bold: true,
      },
      {
        columnName: 'name',
        headerText: t(`${prefix}.fields.name`),
      },
      {
        columnName: 'startDate',
        headerText: t(`${prefix}.fields.startDate`),
        cellFormat: ({ value }: CellFormatOptions) =>
          dayjs(value).format(formatDate),
      },
      {
        columnName: 'endDate',
        headerText: t(`${prefix}.fields.endDate`),
        cellFormat: ({ value }: CellFormatOptions) =>
          dayjs(value).format(formatDate),
      },
      {
        columnName: 'value',
        headerText: t(`${prefix}.fields.tuition`),
        cellFormat: ({ value }: CellFormatOptions) => {
          return moneyFormatter('es-CL', 'CLP').format(value);
        },
      },
      {
        columnName: 'status',
        headerText: t(`${prefix}.fields.status`),
        width: '100px',
        cellFormat: ({ value }: CellFormatOptions) => (
          <Badge color={value ? 'primary' : 'danger'}>
            {t(`${prefix}.fields.${value ? 'active' : 'inactive'}`)}
          </Badge>
        ),
      },
      {
        columnName: 'id',
        headerText: '',
        width: '40px',
        cellFormat: (options: CellFormatOptions) => (
          <div className="d-flex justify-content-end">
            <span
              onClick={() => {
                setRecordToEdit(options.row);
                setShowModal(true);
              }}
              className="cursor-pointer"
            >
              <Icon name="edit" key="edit" color="primary" />
            </span>
          </div>
        ),
      },
    ];
  }, [t]);
  const changePage = useCallback(
    async (page: number = 1) => {
      await getTuitionData(10, page - 1);
      setCurrentPage(page);
    },
    [getTuitionData, setCurrentPage],
  );

  useEffect(() => {
    getTuitionData();
  }, [getTuitionData]);

  if (errorLoading) {
    return (
      <DisplayError
        insideCard
        textBody={errorLoading}
        retryAction={() => setLoading(true)}
      />
    );
  }
  return (
    <Container className="g-table-container pt-4" fluid>
      <Button
        text={t(`${prefix}.btnNewTuitionValue`)}
        type="button"
        size="sm"
        icon="plus"
        onClick={() => setShowModal(true)}
        className="g-add-button mb-3 mt-3"
      />

      <div className={styles.list}>
        <Table
          columns={columns}
          data={tuitions}
          striped
          isLoadingResults={loading}
          noResultsText={
            <TableEmptyContent
              title={t(`${prefix}.table.tableEmptyTitle`)}
              subtitle={t(`${prefix}.table.tableEmptySubtitle`)}
            />
          }
        />

        {showPagination && totalItems > 10 && (
          <TablePagination
            pagination={{
              currentPage: currentPage,
              itemsPerPage: 10,
              onChangePage: (page) => changePage(page),
              totalItems: totalItems,
              totalPages: totalPages,
            }}
          />
        )}
      </div>
      <TuitionModal
        show={showModal}
        toggleModal={() => {
          setShowModal(!showModal);
          setRecordToEdit(null);
        }}
        reloadDiscountList={getTuitionData}
        recordToEdit={recordToEdit}
        isContinuity={true}
      />
    </Container>
  );
}
