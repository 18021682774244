import { Icon } from '@octano/global-ui';
import { useTranslation } from 'react-i18next';
import { Col, Container, Row } from 'reactstrap';

export const IdNotFound = () => {
  const { t } = useTranslation();
  const prefix = 'tuitionProcess.searchStudent.notFoundID';

  return (
    <Container className="mb-5">
      <Row className="text-center justify-content-center">
        <Col xs={10}>
          <Icon name="error" color="secondary" size="65px" />
        </Col>
        <Col xs={10} className="py-4">
          <h4 className="text-dark">{t(`${prefix}.title`)}</h4>
        </Col>
        <Col xs={7}>
          <span className="text-light fw-400 fs-16">{t(`${prefix}.body`)}</span>
        </Col>
      </Row>
    </Container>
  );
};
