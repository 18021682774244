import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

/**
 * Obtiene el listado de días de la semana
 */
export const useScheduledays = () => {
  const prefix = 'common.days';
  const { t } = useTranslation();

  return useMemo(() => {
    return [
      { id: 'Lu', headerTitle: t(`${prefix}.Lu`) },
      { id: 'Ma', headerTitle: t(`${prefix}.Ma`) },
      { id: 'Mi', headerTitle: t(`${prefix}.Mi`) },
      { id: 'Ju', headerTitle: t(`${prefix}.Ju`) },
      { id: 'Vi', headerTitle: t(`${prefix}.Vi`) },
      { id: 'Sa', headerTitle: t(`${prefix}.Sa`) },
    ];
  }, [t]);
};
