import { Card, Col, Row } from 'reactstrap';
import { useState, useEffect, useCallback, useRef } from 'react';
import {
  updateInterimDegree,
  showInterimDegree,
} from '../../../api/requests/academicOfferInterimDegree';
import { useTranslation } from 'react-i18next';
import { addToast } from '@octano/global-ui';
import useUserState from '../../../hooks/useUserState';
import { PermissionName } from '../../../types/Auth';
import CurriculumBox from './parts/CurriculumBox';
import { useParams } from 'react-router-dom';
import GoBackToListButton from '../../../components/buttons/GoBackToListButton';
import DisplayPermissionError from '../../../components/info/DisplayPermissionError';
import InterimDegreeForm, {
  InterimDegreeFormFields,
} from './parts/InterimDegreeForm';
import { useForm, FormProvider } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import {
  InterimDegree,
  InterimDegreeStatus,
} from './interfaces/interimDegree.interface';
import InterimDegreeEmptyActivitiesModal, {
  InterimDegreeEmptyActivitiesModalMethods,
} from './parts/InterimDegreeEmptyActivitiesModal';

export const MODULE_PERMISSIONS = [
  PermissionName.ACADEMIC_OFFER_INTERIM_DEGREE,
];
const dictPrefix = 'interimDegree.edit';

const InterimDegreeEdit = () => {
  const { t } = useTranslation();
  const { isAuthorizedTo } = useUserState();
  const history = useHistory();

  const emptyActivitiesRef = useRef<InterimDegreeEmptyActivitiesModalMethods>(
    null,
  );

  const { interimDegreeId } = useParams<{ interimDegreeId: string }>();

  const [interimDegree, setInterimDegree] = useState<InterimDegree>();
  const [detailLoaded, setDetailLoaded] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const methods = useForm<InterimDegreeFormFields>({
    defaultValues: {
      name: '',
      description: '',
      hasntActivities: false,
      semesterCoursesIds: [],
      activities: [],
    },
  });
  const { setError, handleSubmit, reset } = methods;

  const handleDetails = useCallback(
    async (id: string | number) => {
      setLoading(true);
      const { data, error } = await showInterimDegree(id);
      setLoading(false);

      if (error) {
        addToast({
          text: t(`${dictPrefix}.errorOnSearch`),
          color: 'danger',
          icon: 'error',
        });
      } else {
        setInterimDegree(data ?? undefined);
        reset({
          name: data?.name ?? '',
          description: data?.description ?? '',
          hasntActivities:
            !data?.hasActivities || !data?.interimDegreeActivities?.length,
          semesterCoursesIds:
            data?.interimDegreeSemesterCourse?.map(
              (e) => e?.semesterCourseId,
            ) ?? [],
          activities: data?.interimDegreeActivities ?? [],
        });
      }
    },
    [t, reset],
  );

  const handleValidate = useCallback(
    (values: InterimDegreeFormFields) => {
      let hasErrors = false;
      if (!values?.name?.trim()) {
        setError('name', { message: t(`${dictPrefix}.nameIsRequired`) });
        hasErrors = true;
      } else if (!values?.semesterCoursesIds?.length) {
        setError('name', { message: t(`${dictPrefix}.courseIsRequired`) });
        hasErrors = true;
      }
      if (!values?.description?.trim()) {
        setError('description', {
          message: t(`${dictPrefix}.descriptionIsRequired`),
        });
        hasErrors = true;
      }
      if (hasErrors) {
        addToast({
          text: t(`${dictPrefix}.validationError`),
          color: 'danger',
          icon: 'error',
        });
      }
      return !hasErrors;
    },
    [t, setError],
  );

  const handleUpdate = useCallback(
    async (values: InterimDegreeFormFields) => {
      if (!handleValidate(values) || !interimDegree?.id || loading) {
        return;
      }
      setLoading(true);
      const { error } = await updateInterimDegree({
        ...values,
        hasActivities: !values?.hasntActivities,
        activities: values?.activities ?? [],
        id: interimDegree.id,
      });
      setLoading(false);

      if (error) {
        addToast({
          text: t(`${dictPrefix}.errorOnUpdate`),
          color: 'danger',
          icon: 'error',
        });
      } else {
        addToast({
          text: t(`${dictPrefix}.successUpdate`),
          color: 'success',
          icon: 'check',
        });
        history.goBack();
      }
    },
    [t, interimDegree?.id, handleValidate, history, loading],
  );

  const handleRequestUpdate = useCallback(
    async (values: InterimDegreeFormFields) => {
      if (!handleValidate(values) || loading) {
        return;
      }
      if (values?.activities?.length) {
        return handleUpdate(values);
      } else {
        emptyActivitiesRef?.current?.open(values);
      }
    },
    [handleUpdate, loading, handleValidate],
  );

  useEffect(() => {
    if (!detailLoaded && interimDegreeId) {
      handleDetails(interimDegreeId);
      setDetailLoaded(true);
    }
  }, [handleDetails, detailLoaded, interimDegreeId]);

  if (
    !isAuthorizedTo(MODULE_PERMISSIONS, true) ||
    (interimDegree && interimDegree?.status !== InterimDegreeStatus.Draft)
  ) {
    return (
      <div className="w-full px-3">
        <DisplayPermissionError permissions={MODULE_PERMISSIONS} insideCard />
      </div>
    );
  }

  return (
    <>
      <Card className="p-4 mx-3">
        <Row>
          <Col className="mb-4" xs={12}>
            <div className="d-flex flex-wrap w-100 align-items-start justify-content-center">
              <GoBackToListButton text={t(`${dictPrefix}.back`)} />
              <div className="flex-fill px-2" />
              <CurriculumBox
                studyPlan={
                  interimDegree?.curriculum?.studyPlanVersion?.studyPlan?.name
                }
                studyPlanCode={
                  interimDegree?.curriculum?.studyPlanVersion?.studyPlan?.code
                }
                version={interimDegree?.curriculum?.studyPlanVersion?.name}
                mention={
                  !interimDegree?.curriculum?.isPrimary
                    ? interimDegree?.curriculum?.name
                    : '-'
                }
                school={
                  interimDegree?.curriculum?.studyPlanVersion?.studyPlan?.school
                    ?.name
                }
              />
            </div>
          </Col>
        </Row>
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(handleRequestUpdate)}>
            <InterimDegreeForm
              dictPrefix={dictPrefix}
              curriculum={interimDegree?.curriculum}
              loading={loading}
            />
          </form>
        </FormProvider>
      </Card>
      <InterimDegreeEmptyActivitiesModal
        ref={emptyActivitiesRef}
        dictPrefix={`${dictPrefix}.emptyActivities`}
        onSave={handleUpdate}
      />
    </>
  );
};

export default InterimDegreeEdit;
