import { FormData } from './parts/Modals/CreateOrUpdateForm';
import { TeacherAssignmentRecord } from './type';

const optionFormat = (option: { id: number; name: string }) => ({
  label: option.name,
  value: option.id,
});

export const mapTeacherAssignmentRecordToFormData = (
  row: TeacherAssignmentRecord,
  hourTypes: string[],
): FormData => {
  const hours: any = {};
  if (hourTypes?.length) {
    hourTypes?.forEach((e) => (hours[e] = '0'));
  }
  Object.keys(hours ?? {}).forEach((hourType) => {
    const finded = row?.hours?.find((h) => h?.type === hourType);
    if (finded?.value) {
      hours[hourType] = `${finded?.value}`;
    }
  });
  return {
    hours,
    startAt: row.startAt,
    endAt: row.endAt,
    undefinedEnd: !row.endAt,
    campus: row.campus ? optionFormat(row.campus) : null,
    studyPlan: row.studyPlan ? optionFormat(row.studyPlan) : null,
    teacherAcademicRank: row.teacherAcademicRank
      ? optionFormat(row.teacherAcademicRank)
      : null,
    teacherContractType: row.teacherContractType
      ? optionFormat(row.teacherContractType)
      : null,
  };
};
