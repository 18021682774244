import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { getTemplate } from '../requests';
import Download from './Download';

interface DownloadTemplateProps {
  beforeDownload?: () => void;
  afterDownload?: () => void;
}

export default function DownloadTemplate({
  beforeDownload,
  afterDownload,
}: DownloadTemplateProps): JSX.Element {
  const { t } = useTranslation();
  const prefix = 'academicRecord.downloadTemplate';
  const texts = useMemo(
    () => ({
      error: t(`${prefix}.error`),
      action: t(`${prefix}.action`),
    }),
    [t],
  );

  return (
    <Download
      beforeDownload={beforeDownload}
      afterDownload={afterDownload}
      request={getTemplate}
      texts={texts}
      filename="RecordAcademicoTemplate"
    />
  );
}
