import { Button, Icon, useTimeout } from '@octano/global-ui';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

// En milisegundos
const TIME_TO_REDIRECT = 6000;

export default function InvalidLink() {
  const { t } = useTranslation();
  const history = useHistory();

  const goToLogin = () => history.push('/auth/login');

  useTimeout(goToLogin, TIME_TO_REDIRECT);

  return (
    <div className="d-flex justify-content-center align-items-center p-5">
      <div
        className="d-flex flex-column align-items-center mt-5"
        style={{ maxWidth: 386 }}
      >
        <Icon name="information" size={55} />
        <h1 className="text-dark text-center fw-600 fs-20 mt-4">
          {t(`auth.changePasswordExpiredLinkTitle`)}
        </h1>
        <p className="text-dark text-center fs-18 lh-30 mt-4">
          {t(`auth.changePasswordExpiredLinkDescription`)}
        </p>
        <Button
          text={t(`auth.changePasswordExpiredLinkBtnText`)}
          className="mt-4"
          onClick={goToLogin}
        />
      </div>
    </div>
  );
}
