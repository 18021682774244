import { useCallback, useState } from 'react';

import { deleteTcDocumentReviewDocument } from '../../../api/requests/tuitionContinuityDocumentReviewDocuments';
import {
  StudentDocumentReviewInfDocument,
  TuitionContinuityDocumentStatusEnum,
} from '../../../types/tuitionContinuityDocumentReviewDocuments';

type ModalData =
  | {
      open: true;
      document: StudentDocumentReviewInfDocument;
      onConfirm: () => void;
      onClose: () => void;
      approved: boolean;
    }
  | {
      open: false;
      document?: undefined;
    };

export function useOnDelete({
  onConfirm: onConfirmProp,
  onError,
}: {
  onConfirm: (row: StudentDocumentReviewInfDocument) => void;
  onError: (row: StudentDocumentReviewInfDocument) => void;
}) {
  const [modalConfirm, setModalConfirm] = useState<ModalData>({ open: false });

  const onDelete = useCallback(
    async (row: StudentDocumentReviewInfDocument) => {
      if (!row.id) {
        return;
      }

      const onConfirm = async () => {
        onConfirmProp(row);
        let { error } = await deleteTcDocumentReviewDocument(row.id);
        if (error) {
          onError(row);
        }
      };

      const onClose = () => {
        setModalConfirm({ open: false });
      };

      setModalConfirm({
        open: true,
        document: row,
        onConfirm,
        onClose,
        approved: row.status === TuitionContinuityDocumentStatusEnum.APPROVED,
      });
    },
    [onConfirmProp, onError],
  );

  return { data: modalConfirm, onDelete };
}
