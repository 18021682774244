import request from '../../../../api/request';
import { Period } from '../../../../api/requests/MantainerPortal';
import {
  TuitionItem,
  TuitionListResponse,
} from '../../../../types/periodTuition';

const TUITION = {
  PERIODS: '/maintainers-portal/admission-and-enrollments/tuitions/periods',
  TUITION_PERIODS:
    '/maintainers-portal/admission-and-enrollments/tuitions/tuition-periods',
};

/**
 * Obtiene el listado de periodos sin valores de matriculas
 */
export function getPeriod() {
  return request<Period[]>(TUITION.PERIODS, {
    method: 'get',
  });
}

/**
 * Obtiene el listado de matrículas con paginación
 */
export const getTuitionRequest = (items: number = 10, page: number = 0) => {
  const url = `${TUITION.TUITION_PERIODS}`;
  return request<TuitionListResponse>(url, {
    params: { items_per_page: items, page },
  });
};

/**
 * Crear/editar una matrícula
 */
export const saveTuitionRequest = (data: any) => {
  const editing = !!data.id;
  const url = `${TUITION.TUITION_PERIODS}/${editing ? data.id : ''}`;
  return request<TuitionItem>(url, {
    method: editing ? 'put' : 'post',
    data,
  });
};
