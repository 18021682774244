import { SelectOptionType } from '@octano/global-ui';
import { useCallback } from 'react';

import { getEditResponsiblesRequest } from '../../api/requests/sectionsMaintainer';

export const useSearchResponsible = (sectionId: number | string) => {
  return useCallback(
    async (searchText: string): Promise<SelectOptionType[]> => {
      const { data } = await getEditResponsiblesRequest(sectionId, searchText);
      if (data) {
        return data.map((responsible) => ({
          value: responsible.id,
          label: responsible.fullName ?? '',
        }));
      }
      return [];
    },
    [sectionId],
  );
};
