import { PaginationType, Table } from '@octano/global-ui';
import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { format } from 'rut.js';

import { PackagesViewsStudent } from '../../types/packageTypes';

function usePagination({
  itemsPerPage,
  totalItems,
}: {
  itemsPerPage: number;
  totalItems: number;
}): PaginationType {
  const [page, setPage] = useState(1);
  const onChangePage = useCallback((p: number) => {
    setPage(p);
  }, []);
  const totalPages = Math.ceil(totalItems / itemsPerPage);

  return {
    currentPage: page,
    onChangePage,
    totalPages,
    itemsPerPage,
    totalItems,
  };
}

export interface PackagesViewStudentsProps {
  data: PackagesViewsStudent[];
  loading: boolean;
}

export default function PackagesViewStudents({
  data,
  loading,
}: PackagesViewStudentsProps) {
  const { t } = useTranslation();
  const prefix = 'packages.view.enrolledStudents';

  const pagination = usePagination({
    itemsPerPage: 10,
    totalItems: data.length,
  });

  const paginatedData = useMemo(() => {
    return (data ?? []).slice(
      (pagination.currentPage - 1) * pagination.itemsPerPage,
      pagination.currentPage * pagination.itemsPerPage,
    );
  }, [data, pagination]);

  return (
    <Table
      pagination={pagination}
      borderless
      noResultsText={t(`${prefix}.noStudents`)}
      columns={[
        {
          columnName: 'run',
          headerText: t(`${prefix}.id`),
          cellFormat: ({ row }) => format(row.run),
        },
        {
          columnName: 'name',
          headerText: t(`${prefix}.names`),
        },
        {
          columnName: 'lastName',
          headerText: t(`${prefix}.lastNames`),
          cellFormat: ({ row }) =>
            `${row.paternalLastName} ${row.maternalLastName}`,
        },
        {
          columnName: 'phone',
          headerText: t(`${prefix}.phone`),
          cellFormat: ({ row }) => row.phone?.cellPhone,
        },
        {
          columnName: 'email',
          headerText: t(`${prefix}.email`),
        },
      ]}
      data={paginatedData}
      isLoadingResults={loading}
    />
  );
}
