import { addToast } from '@octano/global-ui';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router';
import { Col, Row } from 'reactstrap';
import GoBackButton from '../../../../../components/buttons/GoBackButton';
import DynamicForm from '../../../../../components/DynamicForm/DynamicForm';
import { ButtonData } from '../../../../../components/DynamicForm/parts/RenderSections';
import useActions from './hooks/useActions';
import PersonalBackgroundContact from './parts/DynamicFormSections/PersonalBackgroundContact';
import PersonalBackgroundHealthSystem from './parts/DynamicFormSections/PersonalBackgroundHealthSystem';
import PersonalBackgroundInformation from './parts/DynamicFormSections/PersonalBackgroundInformation';
import PersonalBackgroundFormDataLoader from './parts/Loaders/PersonalBackgroundFormDataLoader';

function PersonalBackground() {
  const { t } = useTranslation();
  const { teacherId } = useParams<{ teacherId: string }>();
  const actions = useActions({
    onSuccess: () => {
      addToast({
        icon: 'success',
        color: 'success',
        text: t('common.messages.saveSuccess'),
      });
    },
    onError: (_, message) => {
      addToast({
        icon: 'error',
        color: 'danger',
        text: message ?? t('common.errors.save'),
      });
    },
  });
  const history = useHistory();

  const sectionComponents = {
    information: <PersonalBackgroundInformation />,
    contactData: <PersonalBackgroundContact />,
    healthSystem: <PersonalBackgroundHealthSystem />,
  };

  const buttons: ButtonData[] = [
    {
      type: 'button',
      text: t('common.actions.back'),
      onClick: () => history.goBack(),
      disabledInSubmitting: true,
      fullwidth: true,
      outlined: true,
    },

    {
      type: 'submit',
      text: t('common.actions.save'),
      disabledInLoading: true,
      disabledInSubmitting: true,
      fullwidth: true,
    },
  ];

  return (
    <PersonalBackgroundFormDataLoader teacherId={Number(teacherId)}>
      {({ defaultValues }) => {
        return (
          <div className="m-4">
            <Row>
              <Col className="mb-4">
                <GoBackButton
                  onClick={() => {
                    history.goBack();
                  }}
                  text="Volver al listado"
                />
              </Col>
            </Row>
            <DynamicForm
              dynamicFormId={3}
              defaultValues={defaultValues}
              sectionComponents={sectionComponents}
              formButtons={buttons}
              onSubmit={async (values) =>
                actions.saveDynamicForm(parseInt(teacherId), values)
              }
            />
          </div>
        );
      }}
    </PersonalBackgroundFormDataLoader>
  );
}

export default PersonalBackground;
