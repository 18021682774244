export enum PostulationDetailStatus {
  EN_PROCESO = 'en_proceso',
  MATRICULADO = 'matriculado',
  EN_ESPERA = 'en_espera',
  DESISTIDO = 'desistido',
  RETRACTADO = 'retractado',
}

export enum POSTULATION_STATUS {
  IN_PROCESS = 'en_proceso',
  ENROLLED = 'matriculado',
  IN_WAITING_LIST = 'en_espera',
  DESISTED = 'desistido',
  CHANGE_STUDY_PLAN = 'cambio_de_plan_de_studio',
  NO_POSTULATION = 'sin_postulacion',
}
