import { ReactElement, useCallback, useEffect, useState } from 'react';

import { AxiosResultDefaultError } from '../../../api/request';
import DisplayError from '../../../components/info/DisplayError';
import Loading from '../../../components/info/Loading';
import { useLoadingState } from '../../../hooks/useLoadingState';
import { getCourseValidationInternal } from '../requests';
import { CourseValidationInternalBase } from '../types';

export interface BaseLoaderProps<T> {
  children: (props: T) => ReactElement;
}

export default function BaseLoader({
  children,
}: BaseLoaderProps<CourseValidationInternalBase>) {
  const { loading, setLoading } = useLoadingState();

  const [data, setData] = useState<CourseValidationInternalBase>();
  const [error, setError] = useState<AxiosResultDefaultError>();

  /* Función que se llama cuando se monta el componente. Trae la info utilizada en la vista */
  const load = useCallback(async () => {
    setLoading(true);

    const response = await getCourseValidationInternal();

    if (response.data) {
      setData(response.data);
      setError(undefined);
    }
    if (response.error) {
      setError(response.error);
    }
    setLoading(false);
  }, [setLoading]);

  useEffect(() => {
    load();
  }, [load]);

  if (error) {
    return (
      <DisplayError
        insideCard
        textBody={error.code}
        retryAction={load}
        loadingAction={loading}
      />
    );
  }

  if (loading) {
    return <Loading insideCard />;
  }

  if (!data) {
    return (
      <DisplayError
        insideCard
        textBody="Data no cargada"
        retryAction={load}
        loadingAction={loading}
      />
    );
  }

  return children(data);
}
