import { Button, DisplayInfo, addToast } from '@octano/global-ui';
import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'reactstrap';

import { saveCourseValidationInternal } from '../requests';
import { CourseEnrollment } from '../types';

export interface ModalConfirmBodyProps {
  studyPlanEnrollmentId: number;
  courseEnrollment: CourseEnrollment;
  course: { id: number; name: string };
  onClose: () => void;
  onSuccess: () => void;
}

export default function ModalConfirmBody({
  studyPlanEnrollmentId,
  courseEnrollment,
  course,
  onClose,
  onSuccess,
}: ModalConfirmBodyProps) {
  const prefix = 'courseValidationInternalStudent.curriculum.modalConfirm';
  const { t } = useTranslation();
  const texts = useMemo(
    () => ({
      title: t(`${prefix}.title`),
      course: t(`${prefix}.course`),
      courseEnrollment: t(`${prefix}.courseEnrollment`),
      body: t(`${prefix}.body`),
      grade: t(`${prefix}.grade`),
      error: t(`${prefix}.error`),
      cancel: t(`common.actions.cancel`),
      accept: t(`${prefix}.accept`),
    }),
    [t],
  );

  const [loading, setLoading] = useState(false);
  const onSubmit = useCallback(async () => {
    setLoading(true);
    const result = await saveCourseValidationInternal(studyPlanEnrollmentId, {
      semesterCourseId: course.id,
      courseEnrollmentId: courseEnrollment.id,
    });
    if (result.error) {
      addToast({
        icon: 'error',
        color: 'danger',
        text: texts.error,
      });
    } else {
      onSuccess();
    }
    setLoading(false);
  }, [onSuccess, texts.error, studyPlanEnrollmentId, course, courseEnrollment]);

  return (
    <>
      <DisplayInfo
        iconName="information"
        title={texts.title}
        textBody={
          <>
            <div>
              <p className="fs-16 fw-300">{texts.course}</p>
              <p className="fs-16 fw-700 text-medium">
                <li>{course.name}</li>
              </p>
              <p className="fs-16 fw-300">{texts.courseEnrollment}</p>
              <p className="fs-16 fw-700 text-medium">
                <li>{courseEnrollment.course.name}</li>
              </p>
              <p className="fs-16 fw-300">{texts.body}</p>
              <p className="fs-16 fw-700 text-dark">
                {texts.grade} {courseEnrollment.grade}
              </p>
            </div>
          </>
        }
      />
      <Row>
        <Col xs={12} sm={6} className="pb-2">
          <Button
            onClick={onClose}
            text={t('postulationDocs.buttons.close')}
            outlined
            fullwidth
            disabled={loading}
          />
        </Col>
        <Col xs={12} sm={6} className="pb-2">
          <Button
            onClick={onSubmit}
            text={t('postulationDocs.buttons.save')}
            fullwidth
            loading={loading}
          />
        </Col>
      </Row>
    </>
  );
}
