import { CellFormatOptions, ColumnTable, Table } from '@octano/global-ui';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import TableEmptyContent from '../../../../../components/text/TableEmptyContent';
import { useLoadingState } from '../../../../../hooks/useLoadingState';
import { StudyPlanVersion } from '../../../../../types/studyPlan';
import { getAcademicOffersTariffRequest } from '../../api/tariff';
import TariffAccordion from './TariffAccordion';
import { TariffConfirmationModal } from './TariffConfirmationModal';

interface ITariffTableProps {
  academicOfferId: number;
}

const ITEMS_PER_PAGE_DEFAULT = 10;

export default function TariffTable({ academicOfferId }: ITariffTableProps) {
  const { t } = useTranslation();
  const [studyPlanVersion, setStudyPlanVersion] = useState<StudyPlanVersion[]>(
    [],
  );
  const [totalItems, setTotalItems] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(1);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const { loading, setLoading, setErrorLoading } = useLoadingState();

  const [formData, setFormData] = useState<any[]>([]);

  const getStudyplanOffers = useCallback(
    async (items: number = 10, page: number = 1) => {
      setLoading(true);
      const { data, error } = await getAcademicOffersTariffRequest(
        academicOfferId,
        items,
        page,
      );
      if (error) {
        setErrorLoading(error.code);
        setCurrentPage(1);
        setTotalItems(1);
        setTotalPages(1);
      } else if (data) {
        setStudyPlanVersion(data.data);
        setTotalItems(data.total);
        setTotalPages(data.total_pages);
      }
      setLoading(false);
    },
    [academicOfferId, setErrorLoading, setLoading],
  );

  const columns = useMemo<ColumnTable<StudyPlanVersion>[]>(() => {
    return [
      {
        columnName: 'name',
        headerText: t('academicOffers.form.studyPlan'),
        width: '20%',
        tdClassName: 'pl-4 pr-2 p-0',
        cellFormat: (options: CellFormatOptions<StudyPlanVersion>) => {
          return (
            <TariffAccordion
              row={options.row}
              formData={(values) => {
                setFormData(values);
              }}
            />
          );
        },
      },
    ];
  }, [t]);

  useEffect(() => {
    getStudyplanOffers(ITEMS_PER_PAGE_DEFAULT, currentPage);
  }, [getStudyplanOffers, currentPage]);

  return (
    <React.Fragment>
      <Table
        columns={columns}
        data={studyPlanVersion}
        pagination={{
          currentPage,
          itemsPerPage: ITEMS_PER_PAGE_DEFAULT,
          totalItems,
          totalPages,
          onChangePage: (newCurrentPage) => setCurrentPage(newCurrentPage),
        }}
        isLoadingResults={loading}
        noResultsText={
          <TableEmptyContent
            title={t('academicOffers.form.studyPlansTableEmptyTitle')}
            subtitle={t('academicOffers.info.plansOfferEmpatySubtitle')}
          />
        }
      />
      <TariffConfirmationModal
        formData={formData}
        setFormData={setFormData}
        getStudyplanOffers={getStudyplanOffers}
      />
    </React.Fragment>
  );
}
