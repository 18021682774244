import { ReactElement, useCallback } from 'react';
import { createFetchContext } from '../../../../../../../../components/contexts/FetchContextFactory';
import { getFormData } from '../../../../api';
import { ResponseFormData } from '../../../../types/Roles';

interface PermissionsLoaderProps {
  children: ReactElement;
}

const { FetchProvider, FetchConsumer, useFetch } = createFetchContext<
  undefined,
  ResponseFormData
>();

export function usePermissionsLoader() {
  return useFetch();
}

export const PermissionsLoaderConsumer = FetchConsumer;

export default function PermissionsLoader({
  children,
}: PermissionsLoaderProps) {
  const request = useCallback(async () => {
    return getFormData();
  }, []);

  return (
    <FetchProvider request={request} defaultQuery={undefined} fetchImmediately>
      <PermissionsLoaderConsumer>
        {({ data }) => {
          if (data) {
            return children;
          }
          return null;
        }}
      </PermissionsLoaderConsumer>
    </FetchProvider>
  );
}
