import { DetailBox, DetailBoxColumn } from '@octano/global-ui';
import { useMemo } from 'react';

interface StudentBoxProps {
  studentName: string;
  studyPlanName: string;
  scheduleName: string;
  campusName: string;
  texts: {
    student: string;
    studyPlan: string;
    schedule: string;
    campus: string;
  };
}

export default function StudentBox(props: StudentBoxProps) {
  const texts = props.texts;
  const columns = useMemo<DetailBoxColumn[]>(() => {
    return [
      {
        title: texts.student,
        body: props.studentName,
      },
      {
        title: texts.studyPlan,
        body: props.studyPlanName,
      },
      {
        title: texts.schedule,
        body: props.scheduleName,
      },
      {
        title: texts.campus,
        body: props.campusName,
      },
    ];
  }, [props, texts]);

  return (
    <div className="d-flex justify-content-end">
      <DetailBox columns={columns} />
    </div>
  );
}
