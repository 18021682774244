import { Button, SelectOptionType } from '@octano/global-ui';
import { FunctionComponent, useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { Col, Row } from 'reactstrap';
import { v4 as uuidv4 } from 'uuid';

import RemoteSearchSelect from '../../../components/form/RemoteSearchSelect';
import { useValidations } from '../../../hooks/useValidations';
import { CreateSectionFormType } from './CreateSectionForm';
import { SectionValuesForm } from './SectionForm';

type ExtraFieldsProps = {
  title: 'teachers' | 'assistants';
  inputLabel: string;
  search: (searchText: string) => Promise<SelectOptionType[]>;
  buttonLabel: string;
};

const ExtraFields: FunctionComponent<ExtraFieldsProps> = ({
  title,
  inputLabel,
  search,
  buttonLabel,
  children,
}) => {
  const [data, setData] = useState<SelectOptionType[]>([]);

  const { setValue, getValues } = useFormContext<
    SectionValuesForm | CreateSectionFormType
  >();
  const { msgValidations } = useValidations();

  useEffect(() => {
    if (getValues(title) && Array.isArray(getValues(title)))
      setData([...getValues(title)]);
  }, [title, getValues]);

  useEffect(() => {
    setValue(title, [...data]);
  }, [title, data, setValue]);

  return (
    <>
      {data &&
        data.length > 0 &&
        data.map((elem, index) => (
          <div className="d-flex" key={`row-${elem.code}`}>
            <div className="flex-grow-1 pr-3">
              <RemoteSearchSelect
                name={`${title}-${elem.code}`}
                label={inputLabel}
                filterOptions={search}
                rules={{ required: msgValidations.required }}
                onChange={(val) =>
                  setData(
                    data.map((item, i) =>
                      i !== index
                        ? item
                        : val
                        ? {
                            label: val.label,
                            value: val.value,
                            code: elem.code,
                          }
                        : {
                            label: '',
                            value: '',
                            code: elem.code,
                          },
                    ),
                  )
                }
                value={elem}
              />
            </div>
            <div>
              <Button
                type="button"
                outlined
                size="lg"
                onClick={() => {
                  setData(data.filter((item) => item.code !== elem.code));
                }}
                icon="trash"
                className="pr-3"
              />
            </div>
          </div>
        ))}
      <Row>
        <Col md={6}>{children}</Col>
        <Col md={6} className="mb-4">
          <Button
            type="button"
            outlined
            size="sm"
            onClick={() =>
              setData([
                ...data,
                {
                  label: '',
                  value: '',
                  code: uuidv4(), // se usa el uuidv4 para crear una referencia única porque cuando se agregan campos nuevos, el "value" y el "label" están en blanco, y al imprimirlos en pantalla no hay forma de referenciarlos
                },
              ])
            }
            text={buttonLabel}
            fullwidth
            icon="plus"
          />
        </Col>
      </Row>
    </>
  );
};

export default ExtraFields;
