import React from 'react';
import { Col, Row } from 'reactstrap';
import CardInfo from './components/CardInfo';
import PaymentAgreementLoader, {
  usePaymentAgreetmentLoader,
} from './components/PaymentAgreementLoader';
import { useTranslation } from 'react-i18next';

const keyPrefix = 'studentCurrentAccount.tabs.paymentAgreement';

function PaymentAgreement() {
  const { t } = useTranslation();
  const { data } = usePaymentAgreetmentLoader();
  return (
    <div>
      <p className="fs-16 mt-4">{t(`${keyPrefix}.description`)}</p>
      <p className="fs-16 font-weight-bold text-primary">
        {t(`${keyPrefix}.currentDebts`)}{' '}
      </p>
      <Row className="mb-4">
        {data?.currentPaymentAgreements.map(
          (currentPaymentAgreement, index) => (
            <Col md={3} key={index}>
              <CardInfo data={currentPaymentAgreement} />
            </Col>
          ),
        )}
      </Row>
      <p className="fs-16 font-weight-bold text-primary">
        {t(`${keyPrefix}.previousDebts`)}
      </p>
      <Row>
        {data?.overduePaymentAgreements.map(
          (overduepaymentAgreement, index) => (
            <Col md={3} key={index}>
              <CardInfo data={overduepaymentAgreement} />
            </Col>
          ),
        )}
      </Row>
    </div>
  );
}

function PaymentAgreementWrapper() {
  return (
    <PaymentAgreementLoader>
      <PaymentAgreement />
    </PaymentAgreementLoader>
  );
}

export default PaymentAgreementWrapper;
