import {
  addToast,
  Button,
  DateInput,
  TextInput,
  useMobile,
} from '@octano/global-ui';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Col, Row } from 'reactstrap';
import {
  deactivateTeacherAccount,
  sendTeacherAccountActivationLink,
  sendTeacherPasswordRecoveryLink,
} from '../../../../api/requests/teacherActivation';
import { PathsLayouts } from '../../../../config/routes';
import { useValidations } from '../../../../hooks/useValidations';
import ConfirmModal, { ConfirmModalRef } from './ConfirmModal';
import DeactivateConfirmModal from './DeactivateConfirmModal';
import EmailConfirmModal from './EmailConfirmModal';
import ExistingAccountConfirmModal from './ExistingAccountConfirmModal';

type FormValues = {
  rut: string;
  names: string;
  paternalLastName: string;
  maternalLastName: string;
  email: string;
  personalEmail: string;
  birthday: string;
};

const DEFAULT_VALUES: FormValues = {
  rut: '',
  names: '',
  paternalLastName: '',
  maternalLastName: '',
  email: '',
  personalEmail: '',
  birthday: '',
};

enum ModalType {
  NONE,
  EMAIL_CONFIRM,
  DEACTIVATE_CONFIRM,
  EXISTING_ACCOUNT_CONFIRM,
}

enum SubmitAction {
  ACTIVATING,
  PWD_RECOVERY,
  DEACTIVATING,
}

interface Props {
  canDeactivateAccount?: boolean;
  canActivateAccount?: boolean;
  canRecoverPassword?: boolean;
  initialValues?: Partial<FormValues>;
  enableAllFields?: boolean;
  onEmailChangeSuccess?: (newEmail: string) => void;
  onDeactivationSuccess?: () => void;
}

export default function TeacherDataForm({
  initialValues,
  canDeactivateAccount = false,
  canActivateAccount = false,
  canRecoverPassword = false,
  enableAllFields = false,
  onEmailChangeSuccess = () => null,
  onDeactivationSuccess = () => null,
}: Props) {
  const isMobile = useMobile();
  const { t } = useTranslation();
  const prefix = 'teacherActivation.teacherForm';
  const history = useHistory();
  const { validateTextNotEmpty, msgValidations } = useValidations();
  const {
    handleSubmit,
    control,
    getValues,
    reset,
    watch,
    setValue,
  } = useForm<FormValues>({
    defaultValues: { ...DEFAULT_VALUES, ...initialValues },
  });

  const [openedModal, setOpenedModal] = useState<ModalType>(ModalType.NONE);
  const [executingAction, setExecutingAction] = useState<SubmitAction>();
  const [isLoadingRequest, setIsLoadingRequest] = useState(false);

  /* Usado para guardar el email introducido previamente en la confirm de cuenta ya existente */
  const savedEmailForConfirmation = useRef<string>();
  const educationalBackgroundModal = useRef<ConfirmModalRef>(null);

  useEffect(() => {
    if (initialValues) {
      reset(initialValues);
    }
  }, [initialValues, reset]);

  const { rut, email } = watch();

  useEffect(() => {
    const newValue = rut?.replace('.', '').replace('-', '').toUpperCase();
    setValue('rut', newValue);
  }, [setValue, rut]);

  const sendInvitationLink = async (data: FormValues, force?: boolean) => {
    setIsLoadingRequest(true);

    const { error, data: response } = await sendTeacherAccountActivationLink({
      rut: data.rut,
      email: data.email,
      personalEmail: data.personalEmail,
      name: data.names,
      maternalLastName: data.maternalLastName,
      paternalLastName: data.paternalLastName,
      birthday: data.birthday,
      ignoreWarnings: force,
    });

    setIsLoadingRequest(false);

    if (error) {
      if (typeof error.data.message === 'object' && error.data.message.length) {
        // Si la cuenta ya existia
        savedEmailForConfirmation.current = data.email;
        setOpenedModal(ModalType.EXISTING_ACCOUNT_CONFIRM);
      } else if (error.data.message === 'Email Duplicate') {
        addToast({
          icon: 'error',
          color: 'danger',
          text: t(`${prefix}.duplicatedEmail`),
        });
      } else {
        addToast({
          icon: 'error',
          color: 'danger',
          text: t(`${prefix}.errorSendingInvitation`),
        });
      }
      return;
    }

    if (force) {
      addToast({
        icon: 'success',
        color: 'success',
        text: t(`${prefix}.grantedPermissions`),
      });
    } else {
      addToast({
        icon: 'success',
        color: 'success',
        text: t(`${prefix}.invitationSent`),
      });
    }
    setOpenedModal(ModalType.NONE);
    onEmailChangeSuccess(data.email);
    if (response?.teacherId) {
      educationalBackgroundModal.current?.openModal(response?.teacherId);
    }
  };

  const sendResetPasswordLink = async (
    data: Pick<FormValues, 'email' | 'rut'>,
  ) => {
    setIsLoadingRequest(true);
    const { error } = await sendTeacherPasswordRecoveryLink(data);

    setIsLoadingRequest(false);

    if (error) {
      if (error.data.message === 'Email Duplicate') {
        addToast({
          icon: 'error',
          color: 'danger',
          text: t(`${prefix}.duplicatedEmail`),
        });
      } else {
        addToast({
          icon: 'error',
          color: 'danger',
          text: t(`${prefix}.errorSendingRecoveryPasswordEmail`),
        });
      }
      return;
    }

    addToast({
      icon: 'success',
      color: 'success',
      text: t(`${prefix}.recoveryPasswordEmailSent`),
    });

    setOpenedModal(ModalType.NONE);
    onEmailChangeSuccess(data.email);
  };

  const deactivateAccount = async (data: Pick<FormValues, 'email' | 'rut'>) => {
    setIsLoadingRequest(true);
    const { error } = await deactivateTeacherAccount(data);

    setIsLoadingRequest(false);

    if (error) {
      return addToast({
        icon: 'error',
        color: 'danger',
        text: t(`${prefix}.failedDeactivatingTeacherAccount`),
      });
    }

    addToast({
      icon: 'success',
      color: 'success',
      text: t(`${prefix}.deactivatedTeacherAccount`),
    });

    setOpenedModal(ModalType.NONE);
    onDeactivationSuccess();
  };

  /* 
    Todos los botones primarios del formulario ejecutan el submit, por eso se guarda cual fue la accion
    que queria ejecturase y se toman acciones segun ese valor
  */
  const onSubmit = () => {
    if (executingAction === SubmitAction.DEACTIVATING) {
      setOpenedModal(ModalType.DEACTIVATE_CONFIRM);
    } else {
      setOpenedModal(ModalType.EMAIL_CONFIRM);
    }
  };

  const handleEmailConfirmation = (confirmedEmail: string, force?: boolean) => {
    const data = { ...getValues(), email: confirmedEmail };

    switch (executingAction) {
      case SubmitAction.ACTIVATING: {
        sendInvitationLink(data, force);
        break;
      }
      case SubmitAction.PWD_RECOVERY: {
        sendResetPasswordLink({ email: data.email, rut: data.rut });
        break;
      }
    }
  };

  const handleCancel = () => {
    setOpenedModal(ModalType.NONE);
    savedEmailForConfirmation.current = undefined;
  };

  const goToPersonalBackground = useCallback(
    (techerId: number) => {
      history.push(
        `${PathsLayouts.teacherFile}/${techerId}/personal-background`,
      );
    },
    [history],
  );

  return (
    <>
      <EmailConfirmModal
        initialEmail={email}
        isOpen={openedModal === ModalType.EMAIL_CONFIRM}
        onConfirm={({ email: confirmedEmail }) =>
          handleEmailConfirmation(confirmedEmail)
        }
        onCancel={handleCancel}
        isLoading={isLoadingRequest}
      />

      <ExistingAccountConfirmModal
        isOpen={openedModal === ModalType.EXISTING_ACCOUNT_CONFIRM}
        onConfirm={() =>
          handleEmailConfirmation(savedEmailForConfirmation.current || '', true)
        }
        onCancel={handleCancel}
        isLoading={isLoadingRequest}
      />

      <DeactivateConfirmModal
        isOpen={openedModal === ModalType.DEACTIVATE_CONFIRM}
        onConfirm={() => {
          const data = getValues();
          deactivateAccount({
            email: initialValues?.email || '',
            rut: data.rut,
          });
        }}
        onCancel={handleCancel}
        isLoading={isLoadingRequest}
      />

      <form onSubmit={handleSubmit(onSubmit)}>
        <Row>
          <Col xs={12} md={4} className="mb-3">
            <TextInput
              name="rut"
              label={t(`${prefix}.rut`)}
              control={control}
              disabled={openedModal === ModalType.NONE}
              rules={{ validate: { notEmpty: validateTextNotEmpty } }}
            />
          </Col>
          <Col xs={12} md={4} className="mb-3">
            <TextInput
              name="names"
              label={t(`${prefix}.names`)}
              control={control}
              disabled={!enableAllFields && openedModal === ModalType.NONE}
              rules={{ validate: { notEmpty: validateTextNotEmpty } }}
            />
          </Col>
          <Col xs={12} md={4} className="mb-3">
            <TextInput
              name="paternalLastName"
              label={t(`${prefix}.paternalLastName`)}
              control={control}
              disabled={!enableAllFields && openedModal === ModalType.NONE}
              rules={{ validate: { notEmpty: validateTextNotEmpty } }}
            />
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={4} className="mb-3">
            <TextInput
              name="maternalLastName"
              label={t(`${prefix}.maternalLastName`)}
              control={control}
              disabled={!enableAllFields && openedModal === ModalType.NONE}
              rules={{ validate: { notEmpty: validateTextNotEmpty } }}
            />
          </Col>
          <Col xs={12} md={4} className="mb-3">
            <TextInput
              name="personalEmail"
              label={t(`${prefix}.personalEmail`)}
              control={control}
              disabled={!enableAllFields && openedModal === ModalType.NONE}
            />
          </Col>
          <Col md={4} offset={8}>
            <DateInput
              name="birthday"
              label={t(`${prefix}.birthday`)}
              control={control}
              rules={{
                required: msgValidations.required,
              }}
              disabled={!enableAllFields}
            />
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={4}>
            <TextInput
              name="email"
              label={t(`${prefix}.email`)}
              control={control}
              tooltip={t(`${prefix}.emailTooltip`)}
              rules={{ validate: { notEmpty: validateTextNotEmpty } }}
            />
          </Col>
        </Row>
        <div
          className="d-flex flex-wrap justify-content-end mt-5"
          style={{ gap: 30 }}
        >
          {/* DESACTIVAR CUENTA */}
          {canDeactivateAccount && (
            <Button
              text={t(`${prefix}.deactivate`)}
              outlined
              style={{ width: isMobile ? '100%' : 280 }}
              onClick={() => setOpenedModal(ModalType.DEACTIVATE_CONFIRM)}
            />
          )}

          {/* ENVIAR EMAIL DE RECUPERACION */}
          {canRecoverPassword && (
            <Button
              type="submit"
              text={t(`${prefix}.recoverPassword`)}
              style={{ width: isMobile ? '100%' : 280 }}
              onClick={() => setExecutingAction(SubmitAction.PWD_RECOVERY)}
            />
          )}

          {/* ENVIAR EMAIL DE ACTIVACION */}
          {canActivateAccount && (
            <Button
              type="submit"
              text={t(`${prefix}.sendInvitation`)}
              style={{ width: isMobile ? '100%' : 280 }}
              onClick={() => setExecutingAction(SubmitAction.ACTIVATING)}
            />
          )}
        </div>
      </form>
      <ConfirmModal
        ref={educationalBackgroundModal}
        onConfirm={goToPersonalBackground}
      />
    </>
  );
}
