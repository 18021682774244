import { Col, Row } from 'reactstrap';
import { Button } from '@octano/global-ui';
import InterimDegreeDetailsForm from './InterimDegreeDetailsForm';
import InterimDegreeCoursesForm from './InterimDegreeCoursesForm';
import InterimDegreeCreditsForm from './InterimDegreeCreditsForm';
import InterimDegreeActivitiesForm from './InterimDegreeActivitiesForm';
import { Curriculum } from '../interfaces/curriculum.interface';
import { useTranslation } from 'react-i18next';
import { InterimDegreeActivity } from '../interfaces/interim-degree-activity.interface';

export interface InterimDegreeActivityFormItem
  extends Omit<InterimDegreeActivity, 'id'> {
  id?: number;
  index?: number;
}

export interface InterimDegreeFormFields {
  name: string;
  description: string;
  hasntActivities: boolean;
  semesterCoursesIds: number[];
  activities: InterimDegreeActivityFormItem[];
}

const InterimDegreeForm = ({
  dictPrefix,
  curriculum,
  disabled,
  loading,
  hideActivities,
}: {
  curriculum?: Curriculum;
  dictPrefix: string;
  disabled?: boolean;
  loading?: boolean;
  hideActivities?: boolean;
}) => {
  const { t } = useTranslation();

  return (
    <Row>
      <Col xs={12}>
        <InterimDegreeDetailsForm
          dictPrefix={dictPrefix}
          disabled={disabled || loading}
        />
      </Col>
      <Col className="mt-5" xs={12}>
        <InterimDegreeCoursesForm
          dictPrefix={dictPrefix}
          periodTypeName={
            curriculum?.studyPlanVersion?.studyPlan?.periodType?.name?.trim() ??
            ''
          }
          semesters={curriculum?.semesters}
          disabled={disabled || loading}
        />
      </Col>
      <Col className="mt-5" xs={12}>
        <InterimDegreeCreditsForm
          dictPrefix={dictPrefix}
          semesters={curriculum?.semesters}
        />
      </Col>
      {!hideActivities && (
        <Col className="mt-5" xs={12}>
          <InterimDegreeActivitiesForm
            dictPrefix={dictPrefix}
            disabled={disabled || loading}
          />
        </Col>
      )}
      {!disabled && (
        <Col className="mt-5" xs={12}>
          <div className="d-flex flex-wrap w-100 align-items-center justify-content-center">
            <div className="flex-fill px-2" />
            <Button
              type="submit"
              text={t(`${dictPrefix}.save`)}
              loading={loading}
            />
          </div>
        </Col>
      )}
    </Row>
  );
};

export default InterimDegreeForm;
