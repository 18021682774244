interface DegreeLevels {
  id: number;
  name: string;
  type: string;
  createdAt: string;
  updatedAt: string;
}

interface LinkedFile {
  id: number;
  name: string;
  extension: string;
  type: string;
  uploadedById: number;
}

export interface EducationRecord {
  id: number;
  teacherId: number;
  name: string;
  dateOfIssue: string;
  description: string;
  originInstitute: string;
  studyCarriedOut: string;
  country: Country;
  degreeLevel: DegreeLevels;
  linkedFile: LinkedFile;
  type: string;
}

export interface EducationalBackgroundResponse {
  academicDegrees: EducationRecord[];
  specialties: EducationRecord[];
  certificates: EducationRecord[];
}

interface DegreeLevel {
  value: number;
  label: string;
}

interface Country {
  value: number;
  label: string;
}

export interface EducationalBackgroundFormDataResponse {
  degreeLevels: DegreeLevel[];
  specialtyLevels: DegreeLevel[];
  countries: Country[];
}

export enum EducationalBackgroundType {
  AcademicDegree = 'grados',
  Specialty = 'especialidades',
  Certificate = 'certificados',
}

export interface FormDataAction {
  name: string;
  originInstitute: string;
  dateOfIssue: Date;
  studyCarriedOut: string;
  description: string;
  countryId: string | number | undefined;
  degreeLevelId: number | undefined;
  doc: File;
  type: string;
}
