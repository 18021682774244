import { ColumnTable, Icon } from '@octano/global-ui';
import { useCallback, useEffect, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Prerequisite } from '../../../../types/courseMaintainerTypes';
import { CourseFormFields, CourseFormMode } from './CourseForm';

export const useColumnsPrerequisites = ({ mode }: { mode: CourseFormMode }) => {
  const prefix = 'courses.update';
  const { t } = useTranslation();
  const { setValue, getValues, register } = useFormContext<CourseFormFields>();

  const onDeletePrerequisite = useCallback(
    (index: number) => {
      const newPrerequisites = getValues('prerequisites') || [];
      newPrerequisites.splice(index, 1);
      setValue('prerequisites', newPrerequisites);
    },
    [setValue, getValues],
  );

  useEffect(() => {
    register('prerequisites');
  }, [register]);

  const columns: ColumnTable<Prerequisite>[] = useMemo(() => {
    return [
      {
        columnName: 'shortening',
        width: '30%',
        thClassName: 'text-center',
        tdClassName: 'text-center',
        headerText: t(`${prefix}.prerequisiteShortening`),
      },
      {
        columnName: 'name',
        width: '40%',
        thClassName: 'text-center',
        tdClassName: 'text-center',
        headerText: t(`${prefix}.prerequisiteName`),
      },
      {
        columnName: 'credits',
        width: '15%',
        thClassName: 'text-center',
        tdClassName: 'text-center',
        headerText: t(`${prefix}.prerequisiteCredits`),
      },
      {
        columnName: 'id',
        width: '15%',
        thClassName: 'text-center',
        tdClassName: 'text-center',
        headerText: t(`${prefix}.prerequisiteDelete`),
        cellFormat: ({ index }) => (
          <div
            className={mode !== CourseFormMode.WATCH ? 'cursor-pointer' : ''}
            onClick={() => {
              if (mode !== CourseFormMode.WATCH) onDeletePrerequisite(index);
            }}
          >
            <Icon
              size="15"
              name="trash"
              color={mode === CourseFormMode.WATCH ? 'disabled' : 'primary'}
            />
          </div>
        ),
      },
    ];
  }, [t, onDeletePrerequisite, mode]);

  return columns;
};
