import { addToast, SelectOptionType, Button } from '@octano/global-ui';
import { useCallback } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Form } from 'reactstrap';
import { AdminAccountForm } from '../../../../ManagementTools/UsersAndRoles/User/UserNew/types';
import { useTexts } from '../../hooks/textsContext';
import useActions from '../../hooks/useActions';
import { useGradingStructureInfoLoader } from '../Loaders/GradingStructureInfoLoader';
import UpdateStructureTypeFormFields from './UpdateStructureTypeFormFields';
import UpdateStructureTypeFormSemiStaticFields from './UpdateStructureTypeFormSemiStaticFields';
import UpdateStructureTypeFormStaticFields from './UpdateStructureTypeFormStaticFields';
import { useHistory } from 'react-router-dom';
import { Col, Row } from 'reactstrap';
import { StructureTypeCode } from '../../interfaces/type';
import UpdateStructureTypeFormFreeFields from './UpdateStructureTypeFormFreeFields';

export function UpdateStructureTypeForm(): JSX.Element {
  const texts = useTexts();
  const history = useHistory();

  const { data: defaultValues } = useGradingStructureInfoLoader();

  const { update: updateTitle } = useActions({
    onSuccess: (a) => {
      if (a === 'create' || a === 'update') {
        history.goBack();
        addToast({
          icon: 'success',
          color: 'success',
          text: 'Estructura guardada con éxito',
        });
      }
    },
    onError: (_, messsage) => {
      addToast({
        icon: 'error',
        color: 'danger',
        text: messsage ?? 'Ha ocurrido un error',
      });
    },
  });

  const methods = useForm<{
    id: number | null;
    structureTypeId: SelectOptionType | null;
  }>({
    mode: 'onChange',
    defaultValues: {
      structureTypeId: {
        value: defaultValues?.structureType?.id,
        label: defaultValues?.structureType?.name,
      },
    },
  });
  const { handleSubmit, watch } = methods;
  const structureType = watch('structureTypeId');

  const onSubmit = useCallback(
    async (values: AdminAccountForm, e: any) => {
      // Si existe el id del user se modifica la información
      if (defaultValues?.id) {
        return updateTitle(defaultValues.id, values as any);
      }
    },
    [defaultValues?.id, updateTitle],
  );

  return (
    <FormProvider {...methods}>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <UpdateStructureTypeFormFields />

        {structureType?.value === StructureTypeCode.FIXED && false && (
          <article>
            <header>
              <h2 className="fs-22 text-uppercase text-primary fw-700 mt-4 mb-4">
                {texts.form.fijo.title}
              </h2>
            </header>
            <UpdateStructureTypeFormStaticFields />
          </article>
        )}

        {structureType?.value === StructureTypeCode.SEMIFIXED && false && (
          <article>
            <header>
              <h2 className="fs-22 text-uppercase text-primary fw-700 mt-4 mb-4">
                {texts.form.semifijo.title}
              </h2>
            </header>
            <UpdateStructureTypeFormSemiStaticFields />
          </article>
        )}

        {structureType?.value === StructureTypeCode.FREE && (
          <article>
            <UpdateStructureTypeFormFreeFields />
          </article>
        )}

        <Row className="justify-content-end p-0 m-0">
          <Col xs={12} sm={4} className="p-0 m-0">
            <Button
              type="submit"
              text={texts.actions.save}
              fullwidth
              size="md"
            />
          </Col>
        </Row>
      </Form>
    </FormProvider>
  );
}
