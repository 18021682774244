import { SelectOptionType } from '@octano/global-ui';
import { Dispatch } from 'react';

export enum ParametersActionType {
  SET_ROLES = 'SET_ROLES',
  SET_COUNTRIES = 'SET_COUNTRIES',
  SET_NATIONALITIES = 'SET_NATIONALITIES',
  SET_REGIONS = 'SET_REGIONS',
  SET_CITIES = 'SET_CITIES',
  SET_COMMUNES = 'SET_COMMUNES',
  SET_INSTITUTION_TYPES = 'SET_INSTITUTION_TYPES',
  SET_PARAMETER_ERROR = 'SET_PARAMETER_ERROR',
}

export type ParametersAction =
  | {
      type: ParametersActionType.SET_COUNTRIES;
      payload: SelectOptionType[];
    }
  | {
      type: ParametersActionType.SET_NATIONALITIES;
      payload: SelectOptionType[];
    }
  | {
      type: ParametersActionType.SET_REGIONS;
      payload: SelectOptionType[];
    }
  | {
      type: ParametersActionType.SET_CITIES;
      payload: SelectOptionType[];
    }
  | {
      type: ParametersActionType.SET_COMMUNES;
      payload: SelectOptionType[];
    }
  | {
      type: ParametersActionType.SET_INSTITUTION_TYPES;
      payload: SelectOptionType[];
    }
  | {
      type: ParametersActionType.SET_PARAMETER_ERROR;
      payload: ParameterErrorType;
    }
  | {
      type: ParametersActionType.SET_ROLES;
      payload: SelectOptionType[];
    };

export type ParametersState = {
  countryOptions: SelectOptionType[];
  nationalityOptions: SelectOptionType[];
  communeOptions: SelectOptionType[];
  cityOptions: SelectOptionType[];
  regionOptions: SelectOptionType[];
  institutionTypeOptions: SelectOptionType[];
  parameterErrors: ParameterErrorType;
  roleOptions: SelectOptionType[];
};

export type ParameterErrorType = {
  country?: string;
  nationality?: string;
  commune?: string;
  region?: string;
  city?: string;
  institutionType?: string;
  role?: string;
};

export type ParametersContextType = {
  state: ParametersState;
  dispatch: Dispatch<ParametersAction>;
};

export type Course = {
  id: number;
  shortening: string;
  code: string;
  name: string;
  credits: number;
  isEnable: true;
  schoolId: number;
  school: {
    id: number;
    name: string;
  };
};
