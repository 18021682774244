import { useCallback, useEffect, useState } from 'react';

import { getAcademicOfferStudyPlanVersionOffer } from '../../api/requests/packages';
import DisplayError from '../../components/info/DisplayError';
import Loading from '../../components/info/Loading';

export interface PackagesPendingStudentsOffer {
  studyPlanName: string;
  campusName: string;
  scheduleName: string;
}

export interface PackagesPendingStudentsOfferLoaderProps {
  academicOfferStudyPlanVersionOfferId: number | string;
  children: (data: PackagesPendingStudentsOffer) => JSX.Element;
}

export const PackagesPendingStudentsOfferLoader = (
  props: PackagesPendingStudentsOfferLoaderProps,
) => {
  const { academicOfferStudyPlanVersionOfferId, children } = props;
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState<PackagesPendingStudentsOffer | null>(null);
  const [error, setError] = useState<'HTTP_ERROR' | 'CONNECTION' | null>(null);

  const fetchData = useCallback(async (id: string | number) => {
    setLoading(true);
    const response = await getAcademicOfferStudyPlanVersionOffer(id);
    if (response.error) {
      setError(response.error.code);
    } else if (response.data.id) {
      setData({
        studyPlanName: response.data.studyPlan,
        campusName: response.data.campus,
        scheduleName: response.data.schedule,
      });
    } else {
      setError('HTTP_ERROR');
    }
    setLoading(false);
  }, []);

  useEffect(() => {
    fetchData(academicOfferStudyPlanVersionOfferId);
  }, [fetchData, academicOfferStudyPlanVersionOfferId]);

  if (loading) {
    return <Loading />;
  }

  if (error) {
    return <DisplayError textBody={error} />;
  }

  if (!data) {
    return <DisplayError textBody="HTTP_ERROR" />;
  }

  return children(data);
};
