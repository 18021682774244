import { OutlinedSelect, SelectOptionType } from '@octano/global-ui';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'reactstrap';
import { useEffect } from 'react';

import { InterimDegreesFilters } from '../interfaces/interim-degrees-filters.interface';

type FormValues = {
  school: SelectOptionType | null;
};

const DEFAULT_FORM_VALUES: FormValues = {
  school: null,
};

const dictPrefix = 'interimDegreeGraduationProcess.massive.searchControls';

interface Props {
  schools?: SelectOptionType[];
  onSearch?: (filters: InterimDegreesFilters) => void;
}

export function InterimDegreesSearchControls({
  schools = [],
  onSearch = () => null,
}: Props) {
  const { t } = useTranslation();

  const { control, watch } = useForm<FormValues>({
    defaultValues: DEFAULT_FORM_VALUES,
  });

  const [school] = watch(['school']);

  useEffect(() => {
    if (school?.value) {
      !!onSearch && onSearch({ schoolId: school?.value });
    } else {
      !!onSearch && onSearch({});
    }
  }, [school?.value, onSearch]);

  return (
    <Row className="mb-5">
      <Col xs={12} sm={6} md={6} lg={4}>
        <OutlinedSelect
          control={control}
          name="school"
          label={t(`${dictPrefix}.school.label`)}
          placeholder={t(`${dictPrefix}.school.placeholder`)}
          options={schools ?? []}
          disabled={!schools?.length}
        />
      </Col>
    </Row>
  );
}
