import { Icon } from '@octano/global-ui';
import { ReactElement } from 'react';
import { Card, Col, Row } from 'reactstrap';

type DisplayInfoProps = {
  textBody: string | ReactElement;
  title?: string;
  insideCard?: boolean;
  maxWidth?: any;
};

/**
 * Componente utilizada para mostrar mensaje de información.
 */
const DisplayInfo = ({
  title,
  textBody,
  insideCard = false,
  maxWidth = '400px',
}: DisplayInfoProps) => {
  const Content = () => (
    <div style={{ maxWidth, alignSelf: 'center' }}>
      <Row className="text-center py-4 px-3">
        <Col xs={12}>
          <Icon name="information" color="secondary" size="65px" />
        </Col>
        <Col xs={12} className="py-4">
          <span className="fs-20 text-dark">{title}</span>
        </Col>
        <Col xs={12}>
          <p className="fs-18 fw-300">{textBody}</p>
        </Col>
      </Row>
    </div>
  );

  if (insideCard) {
    return (
      <Card className="py-4">
        <Content />
      </Card>
    );
  } else {
    return <Content />;
  }
};

export default DisplayInfo;
