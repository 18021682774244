import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';

import { Student } from '../../../../../types/tuitionProcessOnSite';
import { generateUrlToTuitionProcess } from '../helper/generateUrlToTuitionProcess';

export interface GoToPostulation {
  fromStart: boolean;
  postulantId?: number;
  postulantDetailId?: number | string;
  addNewPostulationDetail?: boolean;
}

interface UseGotoProps {
  student: Student;
}

export const useGoTo = ({ student }: UseGotoProps) => {
  const history = useHistory();

  const getStudentPostulantId = useCallback(
    (student: Student, postulantId?: number) =>
      postulantId ?? student.postulant_id,
    [],
  );

  const getStudentDetailId = useCallback(
    (student: Student, postulantDetailId?: number | string) =>
      postulantDetailId ??
      student.newAdmission?.postulationDetailId ??
      student.Details_id,
    [],
  );

  const getStudentAdmissionTypeId = useCallback(
    (student: Student) => student.admissionTypeId ?? 'SIN_SUA',
    [],
  );

  const goToPostulation = useCallback(
    ({
      fromStart,
      postulantId,
      postulantDetailId,
      addNewPostulationDetail = false,
    }: GoToPostulation) => {
      const studentPostulantId = getStudentPostulantId(student, postulantId);
      const studentDetailId = getStudentDetailId(student, postulantDetailId);
      const studentAdmissionTypeId = getStudentAdmissionTypeId(student);

      let url = '';
      if (addNewPostulationDetail) {
        url = generateUrlToTuitionProcess({
          studentPostulantId,
          studentAdmissionTypeId,
          fromStart,
        });
      } else {
        url = generateUrlToTuitionProcess({
          studentPostulantId,
          studentDetailId,
          studentAdmissionTypeId,
          fromStart,
        });
      }
      history.push(url);
    },
    [
      getStudentAdmissionTypeId,
      getStudentDetailId,
      getStudentPostulantId,
      history,
      student,
    ],
  );

  return { goToPostulation };
};
