import { Col, Row } from 'reactstrap';
import { TextInput, TextAreaInput } from '@octano/global-ui';
import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';
import { InterimDegreeFormFields } from './InterimDegreeForm';

const InterimDegreeDetailsForm = ({
  dictPrefix,
  disabled,
}: {
  dictPrefix: string;
  disabled?: boolean;
}) => {
  const { t } = useTranslation();

  const { control } = useFormContext<InterimDegreeFormFields>();

  return (
    <Row>
      <Col xs={12}>
        <p className="fs-20 text-uppercase text-primary mb-0 fw-700">
          {t(`${dictPrefix}.details.title`)}
        </p>
        <p className="mb-0">{t(`${dictPrefix}.details.description`)}</p>
      </Col>
      <Col xs={12} sm={6} md={6}>
        <div className="w-100" style={{ marginTop: 24 }}>
          <TextInput
            control={control}
            name="name"
            label={t(`${dictPrefix}.details.inputs.name`)}
            placeholder="..."
            disabled={disabled}
          />
        </div>
      </Col>
      <Col xs={12} sm={6} md={6}>
        <TextAreaInput
          control={control}
          name="description"
          label={t(`${dictPrefix}.details.inputs.description`)}
          placeholder="..."
          height={90}
          disabled={disabled}
        />
      </Col>
    </Row>
  );
};

export default InterimDegreeDetailsForm;
