import { Button, Icon, Table, TablePagination } from '@octano/global-ui';
import { CellFormatOptions } from '@octano/global-ui/dist/components/Table/types/TableTypes';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useHistory } from 'react-router-dom';
import { Badge } from 'reactstrap';
import { getPeriodsRequest } from '../../../api/requests/periods';
import DisplayError from '../../../components/info/DisplayError';
import Loading from '../../../components/info/Loading';
import TableEmptyContent from '../../../components/text/TableEmptyContent';
import { useLayoutState } from '../../../hooks/useLayoutState';
import { useLoadingState } from '../../../hooks/useLoadingState';
import { formatDate } from '../../../utils/dates';
import styles from './PeriodsList.module.scss';

export interface PeriodItem {
  active: boolean;
  code: string;
  endDate: string;
  id: number;
  name: string;
  startDate: string;
}

interface TableColumns {
  width?: string;
  bold?: boolean;
  headerText: string;
  columnName: string;
  [key: string]: any;
}

export const PeriodsList = () => {
  const [periods, setPeriods] = useState<PeriodItem[]>([]);
  const [totalItems, setTotalItems] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(1);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [showPagination, setShowPagination] = useState<boolean>(false);
  const {
    loading,
    setLoading,
    errorLoading,
    setErrorLoading,
  } = useLoadingState();

  const { showTabs } = useLayoutState();
  useEffect(() => {
    showTabs();
  }, [showTabs]);

  let history = useHistory();
  const { t } = useTranslation();
  const prefix = 'maintainers.periodsList';

  // Columns definitios
  const columns: TableColumns[] = [
    {
      columnName: 'code',
      headerText: t(`${prefix}.code`),
      bold: true,
    },
    {
      columnName: 'name',
      headerText: t(`${prefix}.name`),
    },
    {
      columnName: 'startDate',
      headerText: t(`${prefix}.startDate`),
      cellFormat: (options: CellFormatOptions) =>
        formatDate(options.value, 'DD/MM/YYYY'),
    },
    {
      columnName: 'endDate',
      headerText: t(`${prefix}.endDate`),
      cellFormat: (options: CellFormatOptions) =>
        formatDate(options.value, 'DD/MM/YYYY'),
    },
    {
      columnName: 'active',
      headerText: t(`${prefix}.status`),
      width: '100px',
      cellFormat: (options: CellFormatOptions) =>
        buildActiveColumn(options.value),
    },
    {
      columnName: 'id',
      headerText: '',
      width: '40px',
      cellFormat: (options: CellFormatOptions) => {
        return (
          <Link to={`/maintainers/periods/record/${options.value}`}>
            <Icon className="custom-eye" name="eye" key="eye" size="22px" />
          </Link>
        );
      },
    },
  ];

  /**
   * getPeriods REQUEST
   * @param items cantidad de items a traer
   * @param page pagina a cargar
   * @returns
   */
  const getPeriods = useCallback(
    async (items: number = 10, page: number = 0) => {
      const { data, error } = await getPeriodsRequest(items, page);
      if (error) {
        setErrorLoading(error.code);
        return;
      }

      if (data) {
        setErrorLoading(undefined);
        const { data: items = [], total = 0, total_pages = 1 } = data;
        setPeriods(items);
        setTotalItems(total);
        setTotalPages(total_pages);
        setShowPagination(true);
      }

      setLoading(false);
    },
    [setErrorLoading, setLoading],
  );

  const buildActiveColumn = (active: boolean) => {
    if (active) {
      return (
        <div>
          <Badge color="primary">{t(`${prefix}.active`)}</Badge>
        </div>
      );
    }

    if (!active) {
      return (
        <div>
          <Badge color="danger">{t(`${prefix}.inactive`)}</Badge>
        </div>
      );
    }
  };

  /**
   * changePage
   * @param page
   */
  const changePage = async (page: number = 1) => {
    await getPeriods(10, page - 1);
    setCurrentPage(page);
  };

  useEffect(() => {
    getPeriods();
  }, [getPeriods]);

  const goToRecord = () => {
    history.push(`/maintainers/periods/record`);
  };

  if (errorLoading) {
    return (
      <DisplayError
        insideCard
        textBody={errorLoading}
        retryAction={() => setLoading(true)}
        loadingAction={loading}
      />
    );
  }
  if (loading) {
    return <Loading insideCard />;
  }
  return (
    <div className="g-table-container">
      <Button
        text={t(`${prefix}.newPeriod`)}
        type="button"
        size="sm"
        icon="plus"
        onClick={() => goToRecord()}
        className="g-add-button mb-3 mt-3"
      />

      <div id={styles.periodsTable}>
        <Table
          columns={columns}
          data={periods}
          noResultsText={
            <TableEmptyContent
              title={t(`${prefix}.periodTableEmptyTitle`)}
              subtitle={t(`${prefix}.periodTableEmptySubtitle`)}
            />
          }
        />

        {showPagination && totalItems > 10 && (
          <TablePagination
            pagination={{
              currentPage: currentPage,
              itemsPerPage: 10,
              onChangePage: (page) => {
                changePage(page);
              },
              totalItems: totalItems,
              totalPages: totalPages,
            }}
          />
        )}
      </div>
    </div>
  );
};
