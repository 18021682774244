import { Button, ColumnTable, Table, TableProps } from '@octano/global-ui';
import { useTranslation } from 'react-i18next';

import { StudentRow } from '../helpers';

const dictPrefix = 'graduationProcessSingle.studentsTable';

export type TableRowData = StudentRow;

interface Props {
  data: TableRowData[];
  isLoading?: boolean;
  noResultsText?: TableProps<TableRowData>['noResultsText'];
  onProcessGraduate?: (studyPlanEnrollmentId: number) => void;
}

export function StudentsTable({
  data,
  isLoading = false,
  onProcessGraduate = () => null,
  noResultsText,
}: Props) {
  const { t } = useTranslation();

  const columns: ColumnTable<TableRowData>[] = [
    {
      headerText: t(`${dictPrefix}.name`),
      columnName: 'fullName',
      thClassName: 'text-center',
      tdClassName: 'text-center',
    },
    {
      headerText: t(`${dictPrefix}.rut`),
      columnName: 'run',
      thClassName: 'text-center',
      tdClassName: 'text-center',
    },
    {
      headerText: t(`${dictPrefix}.studyPlan`),
      columnName: 'studyPlanName',
      thClassName: 'text-center',
      tdClassName: 'text-center',
    },
    {
      headerText: t(`${dictPrefix}.action`),
      columnName: 'studyPlanEnrolmentId',
      thClassName: 'text-center',
      tdClassName: 'text-center',
      width: '200px',
      cellFormat: ({ value }) => {
        return (
          <Button
            text={t(`${dictPrefix}.processGraduate`)}
            size="sm"
            onClick={() => onProcessGraduate(value)}
          />
        );
      },
    },
  ];

  return (
    <Table
      data={data}
      columns={columns}
      isLoadingResults={isLoading}
      noResultsText={noResultsText}
    />
  );
}
