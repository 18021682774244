import { SelectOptionType } from '@octano/global-ui';
import { ReactElement, useCallback } from 'react';

import { AxiosResultDefaultError } from '../../../../api/request';
import { AuthenticationError } from '../../../../api/requests/tuitionProcess';
import { createFetchContext } from '../../../../components/contexts/FetchContextFactory';
import DisplayError from '../../../../components/info/DisplayError';
import Loading from '../../../../components/info/Loading';
import { getFormData } from '../api';
import { StudentFileFormDataResponse } from '../types';

export interface StudentFileFilterLoaderProps {
  onError?: (code: string, onUnknown?: (() => void) | undefined) => void;
  children: (props: {
    refresh: () => Promise<void>;
    schoolOptions: SelectOptionType[];
    campusOptions: SelectOptionType[];
    studyPlanOptions: SelectOptionType[];
  }) => ReactElement;
}

const { FetchProvider, FetchConsumer } = createFetchContext<
  undefined,
  StudentFileFormDataResponse,
  AuthenticationError | AxiosResultDefaultError
>();

export const SustainerLoaderConsumer = FetchConsumer;

export default function FormLoader({
  onError,
  children,
}: StudentFileFilterLoaderProps) {
  const request = useCallback(async () => {
    return getFormData();
  }, []);

  return (
    <FetchProvider request={request} defaultQuery={undefined} fetchImmediately>
      <FetchConsumer>
        {({ data, loading, error, refresh }) => {
          if (error) {
            onError && onError(error.code);
            return (
              <DisplayError
                insideCard
                textBody={error.code}
                retryAction={refresh}
                loadingAction={loading}
              />
            );
          }

          if (loading) {
            return <Loading insideCard />;
          }

          if (!data) {
            return (
              <DisplayError
                insideCard
                textBody="Data no cargada"
                retryAction={refresh}
                loadingAction={loading}
              />
            );
          }

          return children({
            refresh,
            campusOptions: data.campuses.map((c) => ({
              label: c.name,
              value: c.id,
            })),
            schoolOptions: data.schools.map((s) => ({
              label: s.name,
              value: s.id,
            })),
            studyPlanOptions: data.studyPlans.map((p) => ({
              label: p.name,
              value: p.id,
            })),
          });
        }}
      </FetchConsumer>
    </FetchProvider>
  );
}
