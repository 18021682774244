import { addToast, Button, Table, TablePagination } from '@octano/global-ui';
import { CellFormatOptions } from '@octano/global-ui/dist/components/Table/types/TableTypes';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import DisplayError from '../../components/info/DisplayError';
import SearchRut from '../../components/inputs/SearchRutValue';
import { ModalConfirmation } from '../../components/modals/Confirmation';
import TableEmptyContent from '../../components/text/TableEmptyContent';
import { useLayoutState } from '../../hooks/useLayoutState';
import { deletePostulant } from '../../views/AdmissionAndEnrollments/AdmissionManagement/api';
import { IdNotFound } from '../components/IdNotFound';
import { useAdmissionPendingList } from '../contexts/AdmissionPendingListContext';
import { getAdmissionColumnsStudent } from '../getAdmissionColumns';
import { AdmissionsStudent, TableColumns } from '../types';

const ITEMS_PER_PAGE_DEFAULT = 10;
const prefix = 'admission';

export function AdmissionListPostulansPending(): JSX.Element {
  const { t } = useTranslation();
  const {
    loading,
    data,
    error,
    query,
    updateQuery,
  } = useAdmissionPendingList();

  const currentPage = query.page;
  const totalItems = 10;
  const totalPages = data.total_pages;
  const searching = loading;

  const admissionsPostulansPendingList = useMemo(() => {
    return data.data.map(
      ({
        id,
        rut,
        passports,
        names,
        paternalLastName,
        maternalLastName,
        contact,
        postulations,
      }) => ({
        id,
        detail_id: postulations[0].details[0],
        rut: rut || passports[0]?.number || '',
        names,
        lastnames: `${paternalLastName} ${maternalLastName}`,
        phone: contact.cellPhone,
        email: contact.email,
      }),
    );
  }, [data]);

  const [modalConfirm, setModalConfirm] = useState<{
    open: boolean;
    title?: string;
    subtitle?: string;
    rut?: string;
    student?: string;
    id?: number;
    action?: number;
  }>({
    open: false,
    title: '',
    student: '',
    id: 0,
    action: 0,
  });

  const { showErrorModal } = useLayoutState();

  const toDeletePostulant = async (id: number) => {
    const deleteResult = await deletePostulant(id);
    if (deleteResult.error && deleteResult.error.code === 'CONNECTION') {
      showErrorModal();
      return;
    } else if (deleteResult.error) {
      addToast({
        icon: 'error',
        color: 'danger',
        text: t('common.errors.save'),
      });
      onClose();
    } else if (deleteResult.data) {
      updateQuery();
      onClose();
      addToast({
        icon: 'success',
        color: 'success',
        text: t(`${prefix}.postulantDeletedMessage`),
      });
    }
  };

  const columnsListStudentPending = useMemo<TableColumns[]>(() => {
    return [
      ...getAdmissionColumnsStudent(t),
      {
        columnName: 'action',
        headerText: '',
        width: '10%',
        cellFormat: (options: CellFormatOptions<AdmissionsStudent>) => {
          return (
            <div
              className="d-flex justify-content-center align-items-center"
              style={{ gap: '16px' }}
            >
              <Button
                text={t('admission.postulansPending.goPlanStudent')}
                size="sm"
                color="primary"
                onClick={() => {
                  setModalConfirm({
                    open: true,
                    title: t(
                      'admission.postulansPending.modalConfirGoPlansStudent.titleNotPlanStudent',
                    ),
                    subtitle: '',
                    rut: options.row.rut,
                    student: `${options.row.names} ${options.row.lastnames}`,
                    id: Number(options.row.id),
                    action: 1,
                  });
                }}
              />
              {/* <div
                onClick={() => {
                  if (isAuthorizedTo([PermissionName.EDIT_ADMISIONS]))
                    setModalConfirm({
                      open: true,
                      title: t(
                        'admission.postulansPending.modalConfirGoPlansStudent.titleRemoveStudent',
                      ),
                      subtitle: t(
                        'admission.postulansPending.modalConfirGoPlansStudent.body',
                      ),
                      student: `${options.row.names} ${options.row.lastnames}`,
                      id: Number(options.row.id),
                      action: 2,
                    });
                }}
              >
                <Icon
                  name="trash"
                  className={`d-flex justify-content-center align-items-center cursor-pointer ${
                    !isAuthorizedTo([PermissionName.EDIT_ADMISIONS])
                      ? 'icon-disabled'
                      : ''
                  }`}
                />
              </div> */}
            </div>
          );
        },
      },
    ];
  }, [t]);

  function onClose(): void {
    setModalConfirm({ open: false });
  }

  const NoResults = useMemo(() => {
    if (query.search) {
      return <IdNotFound />;
    }
    return (
      <TableEmptyContent
        title={t(`${prefix}.postulansPending.notPostulantsPending`)}
        subtitle={''}
      />
    );
  }, [query, t]);

  const history = useHistory();

  async function onConfirm(): Promise<void> {
    if (modalConfirm.open && modalConfirm.action === 2) {
      toDeletePostulant(Number(modalConfirm.id));
    } else if (modalConfirm.rut) {
      history.push(`/tuition-process?id=${modalConfirm.rut}`);
    }
  }

  if (error) {
    return (
      <div className="mx-3">
        <DisplayError
          insideCard
          textBody={error}
          retryAction={() => updateQuery()}
          loadingAction={loading}
        />
      </div>
    );
  }

  return (
    <div className="mt-3 text-info mx-3">
      <SearchRut
        value={query.search}
        onChange={(search) => {
          updateQuery({ page: 1, search });
        }}
        searching={searching}
      />
      <div>
        <Table
          columns={columnsListStudentPending}
          data={admissionsPostulansPendingList}
          noResultsText={NoResults}
          isLoadingResults={loading}
        />
      </div>
      {totalPages > 1 && (
        <TablePagination
          pagination={{
            currentPage: currentPage,
            itemsPerPage: ITEMS_PER_PAGE_DEFAULT,
            onChangePage: (page) => {
              updateQuery({ page, search: query.search });
            },
            totalItems: totalItems,
            totalPages: totalPages,
          }}
        />
      )}
      <ModalConfirmation
        open={modalConfirm.open}
        title={modalConfirm.title || ''}
        subtitle={modalConfirm.subtitle}
        onClose={onClose}
        onConfirm={onConfirm}
        columns={{
          title: t('admission.modalConfirmation.student'),
          li: <li>{modalConfirm.student || ''}</li>,
        }}
      />
    </div>
  );
}
