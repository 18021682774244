import { Button } from '@octano/global-ui';
import { useCallback, useEffect, useRef, useState } from 'react';
import ReactCrop, { Crop } from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import { Col, FormGroup, Row } from 'reactstrap';
import { blobToFile } from '../../utils/blob';

const MIN_WIDTH = 300;
const MIN_HEIGHT = 300;

export interface ImageCropTexts {
  title: string;
  body: string;
  cancel: string;
  crop: string;
}
export interface ImageCropProp {
  texts: ImageCropTexts;
  onCompleted: (blob: { file: File; src: string } | null) => void;
  upImg?: string;
}

const ImageCrop = ({ texts, onCompleted, upImg = '' }: ImageCropProp) => {
  const imgRef = useRef<HTMLImageElement | null>(null);
  const previewCanvasRef = useRef<HTMLCanvasElement>(null);
  const [crop, setCrop] = useState<Crop>({
    aspect: 1,
    width: 100,
    unit: '%',
  } as Crop);
  const [completedCrop, setCompletedCrop] = useState<Crop | null>(null);

  const onLoad = useCallback((img) => {
    imgRef.current = img;
  }, []);

  useEffect(() => {
    if (!completedCrop || !previewCanvasRef.current || !imgRef.current) {
      return;
    }

    const image = imgRef.current;
    const canvas = previewCanvasRef.current;
    const cropData = completedCrop;

    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    const ctx = canvas.getContext('2d');

    if (!ctx) {
      return;
    }

    const pixelRatio = window.devicePixelRatio;

    canvas.width = cropData.width * pixelRatio * scaleX;
    canvas.height = cropData.height * pixelRatio * scaleY;

    ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
    ctx.imageSmoothingQuality = 'high';

    ctx.drawImage(
      image,
      cropData.x * scaleX,
      cropData.y * scaleY,
      cropData.width * scaleX,
      cropData.height * scaleY,
      0,
      0,
      cropData.width * scaleX,
      cropData.height * scaleY,
    );
  }, [completedCrop]);

  const minHeight = imgRef.current
    ? (MIN_HEIGHT * imgRef.current.height) / imgRef.current.naturalHeight
    : undefined;
  const minWidth = imgRef.current
    ? (MIN_WIDTH * imgRef.current.width) / imgRef.current.naturalWidth
    : undefined;

  return (
    <div>
      <div className="d-flex flex-column align-items-center">
        <div className="mt-4 text-black fs-22 text-center">{texts.title}</div>
        <div className={`mx-auto mt-4 mb-4 fs-16 text-center`}>
          {texts.body}
        </div>
      </div>
      <ReactCrop
        src={upImg}
        onImageLoaded={onLoad}
        crop={crop}
        onChange={(c) => {
          setCrop(c);
        }}
        onComplete={(c) => setCompletedCrop(c)}
        minHeight={minHeight}
        minWidth={minWidth}
        keepSelection
      />
      <div className="d-none">
        <canvas
          ref={previewCanvasRef}
          // Rounding is important so the canvas width and height matches/is a multiple for sharpness.
          style={{
            width: Math.round(completedCrop?.width ?? 0),
            height: Math.round(completedCrop?.height ?? 0),
          }}
        />
      </div>

      <Row>
        <Col xs="12" lg="6" className="order-2 order-lg-1">
          <FormGroup>
            <Button
              outlined
              fullwidth
              color="primary"
              text={texts.cancel}
              onClick={() => {
                onCompleted(null);
              }}
            />
          </FormGroup>
        </Col>
        <Col xs="12" lg="6" className="order-1 order-lg-2">
          <FormGroup>
            <Button
              fullwidth
              color="primary"
              text={texts.crop}
              onClick={() => {
                const canvas = previewCanvasRef.current;

                if (!completedCrop || !canvas) {
                  return;
                }

                canvas.toBlob(
                  (blob: Blob | null) => {
                    if (!blob) {
                      onCompleted(null);
                    } else {
                      onCompleted({
                        file: blobToFile(blob, 'cropped-image.jpeg'),
                        src: URL.createObjectURL(blob),
                      });
                    }
                  },
                  'image/jpeg',
                  1,
                );
              }}
            />
          </FormGroup>
        </Col>
      </Row>
    </div>
  );
};

export default ImageCrop;
