import {
  Button,
  OutlinedSelect,
  OutlinedSelectOptionType,
  SearchBox,
  useMobile,
} from '@octano/global-ui';
import { useCallback, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Col, Form, Row } from 'reactstrap';

import { useValidations } from '../../../hooks/useValidations';
import { useStudentsDegreeFormLoader } from '../contexts/StudentsDegreeFormLoader';
import { useStudentsDegreeLoader } from '../contexts/StudentsDegreeLoader';
import { SelectOptionWithDefaultValue } from '../types/StudentsDegree';

const dictPrefix = 'studentsDegree.searchControls';

const DEFAULT_VALUES = {
  search: '',
  studyPlanId: null,
  campusId: null,
  status: {
    label: 'Todos los estados',
    value: null,
  },
};

interface FormValues {
  search: string;
  studyPlanId: OutlinedSelectOptionType | null;
  campusId: OutlinedSelectOptionType | null;
  status: SelectOptionWithDefaultValue;
}

export function SearchControls() {
  const { t } = useTranslation();
  const isMobile = useMobile();
  const { validateMinLength } = useValidations();

  const { updateQuery } = useStudentsDegreeLoader();
  const { data } = useStudentsDegreeFormLoader();

  const studyPlans = useMemo<OutlinedSelectOptionType[]>(() => {
    return data.studyPlans.map((i) => ({
      label: i.name,
      value: i.id,
    }));
  }, [data]);
  const campuses = useMemo<OutlinedSelectOptionType[]>(() => {
    return data.campuses.map((i) => ({
      label: i.name,
      value: i.id,
    }));
  }, [data]);

  const { handleSubmit, control, reset } = useForm<FormValues>({
    defaultValues: DEFAULT_VALUES,
  });

  const handleSearch = useCallback(
    (values: FormValues) => {
      const search = values.search?.trim() !== '' ? values.search : undefined;
      const studyPlanId = values.studyPlanId?.value as number;
      const campusId = values.campusId?.value as number;

      updateQuery({ search, studyPlanId, campusId, page: 1 });
    },
    [updateQuery],
  );

  const onClear = useCallback(() => {
    reset();
    updateQuery();
  }, [reset, updateQuery]);

  return (
    <Form onSubmit={handleSubmit(handleSearch)}>
      <Row className="pb-4 gutters-filters">
        <Col xs={12} md={6} lg={3}>
          <SearchBox
            name="search"
            label={t(`${dictPrefix}.nameOrId`)}
            placeholder={t(`${dictPrefix}.nameOrIdPlaceholder`)}
            control={control}
            rules={{
              validate: {
                minLength: validateMinLength(3),
              },
            }}
          />
        </Col>

        <Col xs={12} md={6} lg={4}>
          <OutlinedSelect
            name="studyPlanId"
            label={t(`${dictPrefix}.studyPlan`)}
            placeholder={t(`${dictPrefix}.studyPlanPlaceholder`)}
            control={control}
            options={studyPlans}
          />
        </Col>

        <Col xs={12} md={6} lg={3}>
          <OutlinedSelect
            name="campusId"
            label={t(`${dictPrefix}.campus`)}
            placeholder={t(`${dictPrefix}.campusPlaceholder`)}
            control={control}
            options={campuses}
          />
        </Col>

        <Col
          xs={12}
          md={{ size: 3, offset: 6 }}
          lg={{ size: 1, offset: 0 }}
          style={{ marginTop: isMobile ? 0 : '1.5rem' }}
        >
          <Button
            type="submit"
            text={t(`common.actions.search`)}
            size="md"
            fullwidth
          />
        </Col>

        <Col
          xs={12}
          md={{ size: 3 }}
          lg={1}
          style={{ marginTop: isMobile ? 0 : '1.5rem' }}
        >
          <Button
            text={t(`common.actions.clean`)}
            size="md"
            fullwidth
            outlined
            onClick={onClear}
          />
        </Col>
      </Row>
    </Form>
  );
}
