import { CheckInput } from '@octano/global-ui';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';
import { useHistory } from 'react-router-dom';
import { Card } from 'reactstrap';
import { validate } from 'rut.js';

import {
  SearchPostulant,
  searchByPassportNumberAndCountry,
  searchRUTRequest,
} from '../../../../../api/requests/postulants';
import DisplayError from '../../../../../components/info/DisplayError';
import DisplayInfo from '../../../../../components/info/DisplayInfo';
import Loading from '../../../../../components/info/Loading';
import { useLoadingState } from '../../../../../hooks/useLoadingState';
import { Student } from '../../../../../types/tuitionProcessOnSite';
import TuitionProcessSearchStudentForm from '../TuitionProcessSearchStudentForm';
import { TuitionProcessSearchStudentByPassport } from '../TuitionProcessSearchStudentFormByPassport';
import {
  isMultiTuition,
  studentDataAdapter,
} from '../helper/studentDataAdapter';
import { SearchStudentOnSiteMultiTuition } from './parts/SearchStudentOnSiteMultiTuition';
import { SearchStudentOnSiteSingleTuition } from './parts/SearchStudentOnSiteSingleTuition';

export interface ValuesStudentOnSite {
  rut?: string;
  country?: string;
  passport?: string;
}

const prefix = `tuitionProcess.searchStudent`;

export const SearchStudentOnSite = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const history = useHistory();

  const {
    loading,
    setLoading,
    errorLoading,
    setErrorLoading,
  } = useLoadingState();
  const [student, setStudent] = useState<Student[] | Student | null>();
  const [submited, setSubmited] = useState<boolean>(false);

  const id = useMemo<string | null>(() => {
    return location.search.split('?id=')[1];
  }, [location]);

  const query = useMemo<ValuesStudentOnSite | null>(() => {
    if (!id) {
      return null;
    } else {
      let isRut = validate(id);
      let passport = id.split('|');
      return isRut
        ? {
            rut: id,
          }
        : {
            country: passport[0],
            passport: passport[passport.length - 1],
          };
    }
  }, [id]);

  const [showPassport, setShowPassport] = useState<boolean>(
    id ? !validate(id) : false,
  );

  const newStudentPending = (data: {
    rut?: string;
    passport?: string;
    country?: string;
  }) => {
    setStudent({
      rut: data.rut ? data.rut : '',
      passportNumber: data.passport ? data.passport : '',
      country: data.country ? data.country : '',
      names: '',
      lastnamePather: '',
      lastnameMother: '',
      email: '',
      phone: '',
      status: 'sin_postulacion',
      // TODO: Como sabemos a que proceso se va a inscribir ahora?
      admissionTypeId: 'SIN_SUA',
    });
  };

  /**
   * Al obtener la data se validara si es un array o no. Si es array es porque esta
   * ON el modo multiTuition y sino es porque esta OFF el modo multiTuition.
   * If isMultiTuition is true them se actualizara el estado como un array de student,
   * else them se actualizara el estado como un objeto de student
   *
   * @param data SearchPostulant[] | SearchPostulant
   * @param values Values
   */
  const olderStudentPending = useCallback(
    (
      data: SearchPostulant[] | SearchPostulant,
      values: ValuesStudentOnSite,
    ) => {
      if (isMultiTuition(data)) {
        const students = data.map((student) => {
          return studentDataAdapter(student, values);
        });
        setStudent(students);
      } else {
        const student = studentDataAdapter(data, values);
        setStudent(student);
      }
    },
    [],
  );

  const onsubmit = useCallback(
    async (values: ValuesStudentOnSite) => {
      setSubmited(false);
      setLoading(true);
      const { data, error } = values.rut
        ? await searchRUTRequest(values.rut)
        : await searchByPassportNumberAndCountry(
            values.country ?? '',
            values.passport ?? '',
          );

      if (error) {
        if (error?.data?.statusCode === 404) {
          newStudentPending(values);
        } else {
          setErrorLoading(error.code);
        }
      }
      if (data) {
        olderStudentPending(data, values);
      }

      setLoading(false);
      setSubmited(true);
    },
    [olderStudentPending, setErrorLoading, setLoading],
  );

  const onSubmitAndSavePath = useCallback(
    async (data: ValuesStudentOnSite) => {
      const queryID = showPassport
        ? `${data.country}|${data.passport}`
        : data.rut;
      await onsubmit(data);
      history.replace(`${location.pathname}?id=${queryID}`);
    },
    [onsubmit, showPassport, history, location.pathname],
  );

  useEffect(() => {
    if (query) {
      onsubmit(query);
    } else {
      setLoading(false);
    }
  }, [onsubmit, setLoading, query]);

  useEffect(() => {
    setSubmited(false);
    setStudent(null);
  }, [showPassport]);

  const preload = useCallback((): void => {
    query && onsubmit(query);
  }, [onsubmit, query]);

  if (errorLoading) {
    return (
      <div className="mx-3">
        <DisplayError
          insideCard
          textBody={errorLoading}
          retryAction={() => setLoading(true)}
          loadingAction={loading}
        />
      </div>
    );
  } else if (loading) {
    return (
      <div className="mx-3">
        <Loading insideCard />
      </div>
    );
  }

  return (
    <div className="mx-3">
      <div className="mb-n2">
        {!showPassport ? (
          <TuitionProcessSearchStudentForm onSubmit={onSubmitAndSavePath} />
        ) : (
          <TuitionProcessSearchStudentByPassport
            onSubmit={onSubmitAndSavePath}
          />
        )}
      </div>
      <CheckInput
        name="idType"
        value={showPassport}
        label={t(`${prefix}.enterPassport`)}
        onChange={() => {
          setSubmited(false);
          setShowPassport(!showPassport);
        }}
      />

      <Card className="p-3 p-md-4">
        {!submited && !student && (
          <div
            className="d-flex align-items-center justify-content-center"
            style={{ height: '100%', margin: 'auto' }}
          >
            <DisplayInfo
              maxWidth="376px"
              title={t(`${prefix}.enterRutPassportTitle`)}
              textBody={t(`${prefix}.enterRutPassportBody`)}
            />
          </div>
        )}

        {/* SingleTuition */}
        {student && !Array.isArray(student) && (
          <SearchStudentOnSiteSingleTuition
            showPassport={showPassport}
            student={student}
            submited={submited}
            preload={preload}
          />
        )}

        {/* MultiTuition */}
        {student && Array.isArray(student) && (
          <SearchStudentOnSiteMultiTuition
            showPassport={showPassport}
            students={student}
            preload={preload}
          />
        )}
      </Card>
    </div>
  );
};
