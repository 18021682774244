import { Button, Icon, Modal } from '@octano/global-ui';
import { forwardRef, useImperativeHandle, useState } from 'react';
import { Container } from 'reactstrap';
import { useTranslation } from 'react-i18next';

interface Props {
  onConfirm: (data: any) => void;
  onDecline: () => void;
}

export interface ConfirmModalRef {
  openModal: (row: any) => void;
  closeModal: () => void;
}

const dictPrefix = 'teacherFile.hiringInformation.confirmDelete';

const ConfirmModal = forwardRef<ConfirmModalRef, Props>(
  ({ onConfirm, onDecline }, ref) => {
    const { t } = useTranslation();
    const [data, setData] = useState<any>();

    const [modal, setModal] = useState(false);

    useImperativeHandle(ref, () => ({
      openModal(row: any) {
        setData(row);
        setModal(true);
      },
      closeModal() {
        setModal(false);
      },
    }));

    return (
      <Modal isOpen={modal} toggle={() => setModal(!modal)}>
        <Container fluid className="text-center">
          <Icon name="warning" size={45} />
          <h1 className="text-dark fs-20 mt-5 mb-5">
            {t(`${dictPrefix}.title`)}
          </h1>

          <div className="d-flex justify-content-around" style={{ gap: 10 }}>
            <div className="flex-fill">
              <Button
                className="w-100"
                text={t(`${dictPrefix}.confirm`)}
                onClick={() => {
                  onConfirm(data!);
                  setModal(false);
                }}
              />
            </div>
            <div className="flex-fill">
              <Button
                className="w-100"
                outlined
                text={t(`${dictPrefix}.cancel`)}
                onClick={() => {
                  onDecline();
                  setModal(false);
                }}
              />
            </div>
          </div>
        </Container>
      </Modal>
    );
  },
);

export default ConfirmModal;
