import request from '../../../../../api/request';
import {
  CreateTeacherAssignmentRecordRequest,
  HiringInformationFormDataResponse,
  HiringInformationResponse,
  UpdateTeacherAssignmentRecordRequest,
} from './type';

const HIRING_INFORMATION = {
  FORM: '/maintainers-portal/teacher-management/teacher-file/hiring-information/form',
  CREATE_RECORD:
    '/maintainers-portal/teacher-management/teacher-file/hiring-information/records',
  GET_RECORDS: (id: string) =>
    `/maintainers-portal/teacher-management/teacher-file/hiring-information/records/${id}`,
  UPDATE_RECORD: (id: string) =>
    `/maintainers-portal/teacher-management/teacher-file/hiring-information/records/${id}`,
  DELETE_RECORD: (id: string) =>
    `/maintainers-portal/teacher-management/teacher-file/hiring-information/records/${id}`,
};

export async function getForm() {
  return request<HiringInformationFormDataResponse>(HIRING_INFORMATION.FORM, {
    method: 'GET',
  });
}

export async function getRecords({ teacherId }: { teacherId: string }) {
  return request<HiringInformationResponse>(
    HIRING_INFORMATION.GET_RECORDS(teacherId),
  );
}

export async function createRecord(data: CreateTeacherAssignmentRecordRequest) {
  return request<void>(HIRING_INFORMATION.CREATE_RECORD, {
    method: 'POST',
    data,
  });
}

export async function updateRecord({
  id,
  ...data
}: UpdateTeacherAssignmentRecordRequest) {
  return request<void>(HIRING_INFORMATION.UPDATE_RECORD(`${id}`), {
    method: 'PUT',
    data,
  });
}

export async function deleteRecord(recordId: string | number) {
  return request<void>(HIRING_INFORMATION.DELETE_RECORD(`${recordId}`), {
    method: 'DELETE',
  });
}
