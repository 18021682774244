import React from 'react';
import PaymentHistorytLoader from './components/PaymentHistoryLoader';
import PaymentHistoryTable from './components/PaymentHistoryTable';

function PaymentHistory() {
  return (
    <PaymentHistorytLoader>
      <PaymentHistoryTable />
    </PaymentHistorytLoader>
  );
}

export default PaymentHistory;
