import { Button, Modal } from '@octano/global-ui';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Card, Col, Row } from 'reactstrap';

import { TextEllipsis } from '../../../../components/text';
import PetitionsStoryBox from './PetitionsStoryBox';
import PetitionsStoryLoader from './PetitionsStoryLoader';
import PetitionsStoryTable from './PetitionsStoryTable';

interface PetitionsStoryModalProps {
  show: boolean;
  petitionId: number | string;
  toggleModal: () => void;
}

export const PetitionsStoryModal = ({
  petitionId,
  show,
  toggleModal,
}: PetitionsStoryModalProps) => {
  const prefix = 'studentPetitions.common.story';
  const { t } = useTranslation();

  const texts = useMemo(
    () => ({
      title: t(`${prefix}.title`),
      box: {
        petitionPlan: t(`${prefix}.box.petitionPlan`),
        petitiontype: t(`${prefix}.box.petitiontype`),
        petitionDate: t(`${prefix}.box.petitionDate`),
      },
      body: {
        petitionTitle: t(`${prefix}.body.petitionTitle`),
        petitionReason: t(`${prefix}.body.petitionReason`),
      },
      table: {
        column1: t(`${prefix}.table.columns.status`),
        column2: t(`${prefix}.table.columns.createdAt`),
        column3: t(`${prefix}.table.columns.hour`),
        column4: t(`${prefix}.table.columns.message`),
        column5: t(`${prefix}.table.columns.modifiedBy`),
      },
      btn: {
        action: t(`${prefix}.btn.finish`),
      },
    }),
    [t],
  );

  return (
    <Modal isOpen={show} toggle={toggleModal} size="lg" unmountOnClose>
      {petitionId && (
        <PetitionsStoryLoader petitionId={petitionId}>
          {(data) => {
            return (
              <>
                <Card className="align-items-center font-600 mb-4">
                  <h3>{texts.title}</h3>
                </Card>

                <section className="petition-info mb-4">
                  <Row>
                    <Col xs={12} sm={12}>
                      <PetitionsStoryBox
                        petitionPlan={data?.studyPlanVersion?.name}
                        petitiontype={data?.petition?.type?.name}
                        petitionDate={data?.petition?.createdAt}
                        texts={texts.box}
                      />
                    </Col>
                  </Row>
                  <br className="mt-4" />
                </section>

                <section className="petition-body mb-4">
                  <Card
                    className="petition-card mb-4 p-4"
                    style={{ boxShadow: '0 0 5px #00000029' }}
                  >
                    <Row style={{ height: '100%' }}>
                      <Col xs={12} className="pb-2">
                        <p>
                          <b>{texts.body.petitionTitle}: </b>
                          <span>{data?.petition?.title}</span>
                        </p>
                        <p>
                          <span>
                            <TextEllipsis
                              key={`petitionReason_${data?.petition?.id}`}
                              justifyCenter={false}
                              textWidth={650}
                              tooltip={data?.petition?.description || ''}
                            >
                              <b>{texts.body.petitionReason}: </b>
                              {data?.petition?.description || 'N/A'}
                            </TextEllipsis>
                          </span>
                        </p>
                      </Col>
                    </Row>
                  </Card>
                </section>

                <section className="petition-table mb-4">
                  <PetitionsStoryTable
                    isLoadingResults={!data?.history}
                    data={data?.history}
                    hasFilters={false}
                  />
                </section>

                <section className="petition-action mb-4">
                  <Button
                    text={t(texts.btn.action)}
                    size="md"
                    outlined
                    onClick={() => toggleModal()}
                    style={{
                      width: '100%',
                    }}
                  />
                </section>
              </>
            );
          }}
        </PetitionsStoryLoader>
      )}
    </Modal>
  );
};
