import {
  Button,
  CellFormatOptions,
  ColumnTable,
  Icon,
  Table,
  TablePagination,
} from '@octano/global-ui';
import dayjs from 'dayjs';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Badge } from 'reactstrap';
import DisplayError from '../../../../components/info/DisplayError';
import Loading from '../../../../components/info/Loading';
import TableEmptyContent from '../../../../components/text/TableEmptyContent';
import { useLayoutState } from '../../../../hooks/useLayoutState';
import { useLoadingState } from '../../../../hooks/useLoadingState';
import { TuitionTable } from '../../../../types/periodTuition';
import { moneyFormatter } from '../../../../utils/currency';
import { getTuitionRequest } from '../api/tuition';
import styles from '../style.module.scss';
import { TuitionModal } from './parts/TuitionModal';

const prefix = 'tariffAndTuition.tuition';

function Tuition() {
  const { t } = useTranslation();
  const { showTabs } = useLayoutState();
  const {
    loading,
    setLoading,
    errorLoading,
    setErrorLoading,
  } = useLoadingState();

  const [tuitions, setTuitions] = useState<TuitionTable[]>([]);
  const [totalItems, setTotalItems] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(1);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [showPagination, setShowPagination] = useState<boolean>(false);

  const [showModal, setShowModal] = useState<boolean>(false);
  const [recordToEdit, setRecordToEdit] = useState(null);

  useEffect(() => {
    showTabs();
  }, [showTabs]);

  const columns: ColumnTable[] = [
    {
      columnName: 'code',
      headerText: t(`${prefix}.fields.code`),
      bold: true,
    },
    {
      columnName: 'name',
      headerText: t(`${prefix}.fields.name`),
    },
    {
      columnName: 'startDate',
      headerText: t(`${prefix}.fields.startDate`),
      cellFormat: ({ value }: CellFormatOptions) => {
        return dayjs(value).format('DD/MM/YYYY');
      },
    },
    {
      columnName: 'endDate',
      headerText: t(`${prefix}.fields.endDate`),
      cellFormat: ({ value }: CellFormatOptions) => {
        return dayjs(value).format('DD/MM/YYYY');
      },
    },
    {
      columnName: 'value',
      headerText: t(`${prefix}.fields.tuition`),
      cellFormat: ({ value }: CellFormatOptions) => {
        return moneyFormatter('es-CL', 'CLP').format(value);
      },
    },
    {
      columnName: 'status',
      headerText: t(`${prefix}.fields.status`),
      width: '100px',
      cellFormat: ({ value }: CellFormatOptions) => (
        <Badge color={value ? 'primary' : 'danger'}>
          {t(`${prefix}.fields.${value ? 'active' : 'inactive'}`)}
        </Badge>
      ),
    },
    {
      columnName: 'id',
      headerText: '',
      width: '40px',
      cellFormat: (options: CellFormatOptions) => (
        <div className="d-flex justify-content-end">
          <span
            onClick={() => {
              setRecordToEdit(options.row);
              setShowModal(true);
            }}
            className="cursor-pointer"
          >
            <Icon name="edit" key="edit" color="primary" />
          </span>
        </div>
      ),
    },
  ];

  const getTuitions = useCallback(
    async (items: number = 10, page: number = 0) => {
      const { data, error } = await getTuitionRequest(items, page);

      if (error) {
        setErrorLoading(error.code);
        return;
      }

      if (data) {
        setErrorLoading(undefined);
        const { data: tuitionsItems = [], total = 0, total_pages = 1 } = data;
        setTuitions(formatData(tuitionsItems));
        setTotalItems(total);
        setTotalPages(total_pages);
        setShowPagination(true);
        setCurrentPage(1);
      }

      setLoading(false);
    },
    [setErrorLoading, setLoading],
  );

  const formatData = (tuitionsItems: any[]) => {
    return tuitionsItems.reduce((acc: TuitionTable[], tuitionItem) => {
      acc.push({
        code: tuitionItem.id,
        name: tuitionItem.period.name,
        value: tuitionItem.value,
        periodId: tuitionItem.period.id,
        startDate: tuitionItem.period.startDate,
        endDate: tuitionItem.period.endDate,
        status: tuitionItem.period.active,
      });
      return acc;
    }, Array<TuitionTable>());
  };

  const changePage = async (page: number = 1) => {
    await getTuitions(10, page - 1);
    setCurrentPage(page);
  };

  useEffect(() => {
    getTuitions();
  }, [getTuitions]);

  if (errorLoading) {
    return (
      <DisplayError
        insideCard
        textBody={errorLoading}
        retryAction={() => setLoading(true)}
      />
    );
  }
  if (loading) {
    return <Loading insideCard className="mt-4" />;
  }

  return (
    <div className="g-table-container">
      <Button
        text={t(`${prefix}.btnNewTuitionValue`)}
        type="button"
        size="sm"
        icon="plus"
        onClick={() => setShowModal(true)}
        className="g-add-button mb-3 mt-3"
      />

      <div className={styles.list}>
        <Table
          columns={columns}
          data={tuitions}
          noResultsText={
            <TableEmptyContent
              title={t(`${prefix}.table.tableEmptyTitle`)}
              subtitle={t(`${prefix}.table.tableEmptySubtitle`)}
            />
          }
        />

        {showPagination && totalItems > 10 && (
          <TablePagination
            pagination={{
              currentPage: currentPage,
              itemsPerPage: 10,
              onChangePage: (page) => {
                changePage(page);
              },
              totalItems: totalItems,
              totalPages: totalPages,
            }}
          />
        )}
      </div>

      <TuitionModal
        show={showModal}
        toggleModal={() => {
          setShowModal(!showModal);
          setRecordToEdit(null);
        }}
        reloadDiscountList={getTuitions}
        recordToEdit={recordToEdit}
      />
    </div>
  );
}
export default Tuition;
