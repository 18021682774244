import { Tab, TabPanel, TabPanels, Tabs, TabsList } from '@octano/global-ui';
import { memo, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { Card } from 'reactstrap';
import PersonalBackground from './PersonalBackground';
import { PathsLayouts } from '../../../../config/routes';
import EducationalBackground from './EducationalBackground';
import EmploymentBackground from './EmploymentBackground';
import HiringInformation from './HiringInformation';
import AdditionalDocumentation from './AdditionalDocumentation';
import TeacherInfoLoader from './parts/Loaders/TeacherInfoLoader';

interface TabComponentProps {
  currentTab: string;
  onTabChange: (tabId: string | number) => void;
}

const keyPrefix = 'teacherActivation.teacherInformation';

const TabComponent = memo(({ currentTab, onTabChange }: TabComponentProps) => {
  const { t } = useTranslation();

  const tabs = [
    {
      path: 'personal-background',
      name: 'personalBackground',
      component: PersonalBackground,
    },
    {
      path: 'educational-background',
      name: 'educationalBackground',
      component: EducationalBackground,
    },
    {
      path: 'employment-background',
      name: 'employmentBackground',
      component: EmploymentBackground,
    },
    {
      path: 'hiring-information',
      name: 'hiringInformation',
      component: HiringInformation,
    },
    {
      path: 'additional-documentation',
      name: 'additionalDocumentation',
      component: AdditionalDocumentation,
    },
  ];

  return (
    <Card className="mt-5 mx-3">
      <Tabs defaultTab={currentTab} onSelection={onTabChange}>
        <TabsList>
          {tabs.map((tab) => (
            <Tab key={tab.path} id={tab.path}>
              {t(`${keyPrefix}.${tab.name}.title`)}
            </Tab>
          ))}
        </TabsList>
        <TabPanels>
          {tabs.map((tab) => (
            <TabPanel key={tab.path} id={tab.path}>
              <tab.component />
            </TabPanel>
          ))}
        </TabPanels>
      </Tabs>
    </Card>
  );
});

function TeacherInformation() {
  const location = useLocation();
  const history = useHistory();
  const { teacherId } = useParams<{ teacherId: string }>();

  const getTabFromPath = useCallback(() => {
    return location.pathname.split('/').pop() || 'sections';
  }, [location.pathname]);

  const [currentTab, setCurrentTab] = useState(getTabFromPath);

  const handleTabChange = useCallback(
    (tabId: string | number) => {
      if (tabId !== currentTab) {
        const routeToGo = `${PathsLayouts.teacherFile}/${teacherId}/${tabId}`;
        history.replace(routeToGo);
        setCurrentTab(tabId as string);
      }
    },
    [currentTab, history, teacherId],
  );

  useEffect(() => {
    setCurrentTab(getTabFromPath());
  }, [location.pathname, getTabFromPath]);

  return (
    <TeacherInfoLoader teacherId={Number(teacherId)}>
      <>
        <TabComponent currentTab={currentTab} onTabChange={handleTabChange} />
      </>
    </TeacherInfoLoader>
  );
}

export default TeacherInformation;
