import { Button, ColumnTable } from '@octano/global-ui';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { format } from 'rut.js';

import { CourseRegistrationList } from '../../../types/courseRegistrationTypes';

export const useColumnsCourseRegistrationList = () => {
  const prefix = 'courseRegistrationList';
  const { t } = useTranslation();
  const history = useHistory();

  const columns: ColumnTable<CourseRegistrationList>[] = useMemo(() => {
    return [
      {
        columnName: 'rut',
        width: '10%',
        thClassName: 'text-center',
        tdClassName: 'text-center text-nowrap',
        headerText: t(`${prefix}.id`),
        cellFormat: ({ row }) => (row.rut ? format(row.rut) : row.passport),
      },
      {
        columnName: 'names',
        width: '20%',
        thClassName: 'text-center',
        tdClassName: 'text-center',
        headerText: t(`${prefix}.names`),
      },
      {
        columnName: 'lastNames',
        width: '20%',
        thClassName: 'text-center',
        tdClassName: 'text-center',
        headerText: t(`${prefix}.lastName`),
      },
      {
        columnName: 'email',
        width: '20%',
        thClassName: 'text-center',
        tdClassName: 'text-center',
        headerText: t(`${prefix}.email`),
      },
      {
        columnName: 'studyPlan',
        thClassName: 'text-center',
        tdClassName: 'text-center',
        width: '20%',
        headerText: (
          <div className="text-center">{t(`${prefix}.studyPlan`)}</div>
        ),
        cellFormat: ({ row }) => {
          return <div>{row.studyPlan.name}</div>;
        },
      },
      {
        columnName: 'schedule',
        thClassName: 'text-center',
        tdClassName: 'text-center',
        width: '20%',
        headerText: (
          <div className="text-center">{t(`${prefix}.schedule`)}</div>
        ),
        cellFormat: ({ row }) => {
          return <div>{row.schedule?.name}</div>;
        },
      },
      {
        columnName: 'campus',
        thClassName: 'text-center',
        tdClassName: 'text-center',
        width: '20%',
        headerText: <div className="text-center">{t(`${prefix}.campus`)}</div>,
        cellFormat: ({ row }) => {
          return <div>{row.campus?.name}</div>;
        },
      },
      {
        columnName: 'id',
        width: '10%',
        tdClassName: 'text-center text-nowrap',
        headerText: '',
        cellFormat: ({ row }) => {
          return (
            <Button
              size="sm"
              text={t(`${prefix}.btnViewRegistration`)}
              onClick={() => {
                history.push(
                  `/course-registration/${row.id}/${row.studyPanEnrollmentId}`,
                );
              }}
            />
          );
        },
      },
    ];
  }, [t, history]);

  return columns;
};
