import { Steps, addToast } from '@octano/global-ui';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { Col, Row } from 'reactstrap';

import { getStatusTuitionProcess } from '../../../../api/requests/tuitionProcess';
import DisplayError from '../../../../components/info/DisplayError';
import Loading from '../../../../components/info/Loading';
import StepProvider from '../../../../components/step/StepProvider';
import { useStepState } from '../../../../components/step/useStepState';
import { useLoadingState } from '../../../../hooks/useLoadingState';
import withProvider from '../../../../providers/withProvider';
import { useTuitionProcessNoSua } from './hooks/useTuitionProcessNoSua';
import { useTuitionProcessNoSuaSteps } from './hooks/useTuitionProcessNoSuaSteps';
import { TuitionProcessNoSuaStepsParams } from './interfaces';

/**
 * Vista del proceso de matrícula del proceso SIN SUA
 */
const TuitionProcessNoSua = () => {
  const { t } = useTranslation();

  const {
    postulantId,
    postulationDetailId,
    fromStart,
  } = useParams<TuitionProcessNoSuaStepsParams>();
  const {
    loading,
    setLoading,
    errorLoading,
    setErrorLoading,
  } = useLoadingState();

  const {
    setPostulant,
    setStatusPostulation,
    setPostulationDetail,
    setNewPostulationDetail,
    setTuitionInfo,
    statusPostulation,
  } = useTuitionProcessNoSua();
  const { currentStep, setCurrentStep } = useStepState();
  const {
    namesSteps,
    CurrentStepComponent,
    getIndexStep,
  } = useTuitionProcessNoSuaSteps(statusPostulation);

  const [idStep, setIdStep] = useState<string>();

  const getStatus = useCallback(async () => {
    setLoading(true);
    const { data, error } = await getStatusTuitionProcess(
      postulationDetailId,
      postulantId,
    );
    if (data) {
      // Si fromStart es true lo deja en el primer paso,
      // de lo contrario calcula en el paso que iba el proceso
      if (fromStart !== 'true') {
        setIdStep(data.step);
      }
      setPostulant({ id: data.postulantId, fullName: data.studentFullName });
      setPostulationDetail(
        data.postulationDetailId && data.admission
          ? {
              id: data.postulationDetailId,
              admissionId: data.admission.admissionId,
              campus: {
                id: data.admission.campus.id,
                name: data.admission.campus.name,
              },
              schedule: {
                id: data.admission.schedule.id,
                name: data.admission.schedule.name,
              },
              studyPlanName: data.admission.studyPlanName,
              admissionMethod: data.admissionMethod,
            }
          : null,
      );
      setStatusPostulation(data.status);
      setNewPostulationDetail(data.newAdmission || null);
      setTuitionInfo({
        hasPreassignedGratuity: data.hasPreassignedGratuity,
        isOnlineTuition: data.isOnlineTuition,
      });
      setErrorLoading(undefined);
    } else if (error) {
      addToast({
        icon: 'error',
        color: 'danger',
        text: t('common.displayError.errorUnexpected'),
      });
      setErrorLoading(error.code);
    }

    setLoading(false);
  }, [
    setLoading,
    postulationDetailId,
    postulantId,
    fromStart,
    setPostulant,
    setPostulationDetail,
    setStatusPostulation,
    setNewPostulationDetail,
    setTuitionInfo,
    setErrorLoading,
    t,
  ]);

  useEffect(() => {
    getStatus();
  }, [getStatus, fromStart, postulantId]);

  useEffect(() => {
    if (idStep) {
      const index = getIndexStep(idStep);
      setCurrentStep(index);
    }
  }, [idStep, getIndexStep, setCurrentStep]);

  if (errorLoading) {
    return (
      <DisplayError
        insideCard
        textBody={errorLoading}
        retryAction={getStatus}
        loadingAction={loading}
      />
    );
  }
  if (loading) {
    return <Loading insideCard />;
  }

  return (
    <Row>
      {/* Pasos del proceso */}
      <Col xs={12} className="pb-4">
        <Steps steps={namesSteps} currentStep={currentStep} />
      </Col>
      <Col xs={12} className="pt-2">
        {/* Se renderiza el componente adecuado para cada paso */}
        {CurrentStepComponent && <CurrentStepComponent />}
      </Col>
    </Row>
  );
};

export default withProvider(TuitionProcessNoSua, StepProvider);
