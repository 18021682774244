import { INTERIM_DEGREE_GRADUATION_PROCESS_MASSIVE } from '../endpoints';
import request from '../request';
import {
  PaginationQueryDto,
  PaginationRequestType,
} from '../../types/paginationRequestType';
import { InterimDegree } from '../../views/academicOffers/InterimDegree/interfaces/interimDegree.interface';
import { CurriculumForm } from '../../views/academicOffers/InterimDegree/interfaces/curriculum-form.interface';
import { InterimDegreesFilters } from '../../views/InterimDegreeGraduationProcess/interfaces/interim-degrees-filters.interface';
import { InterimDegreeAuthorizedStudent } from '../../views/academicOffers/InterimDegree/interfaces/interim-degree-authorized-student.interface';
import { InterimDegreeAuthorizedStudentMassive } from '../../views/InterimDegreeGraduationProcess/interfaces/interim-degree-authorized-student-massive.interface';

/**
 * Obtiene el listado de titulos intermedios
 */
export const getInterimDegress = (
  params: PaginationQueryDto<InterimDegreesFilters>,
) =>
  request<PaginationRequestType<InterimDegree>>(
    INTERIM_DEGREE_GRADUATION_PROCESS_MASSIVE.INTERIM_DEGREES,
    {
      params,
    },
  );

export const generateStudents = ({ id = 0 }: { id: number }) =>
  request<{ data: InterimDegreeAuthorizedStudent[]; lastBatch: number }>(
    INTERIM_DEGREE_GRADUATION_PROCESS_MASSIVE.STUDENTS(id),
    {
      method: 'POST',
    },
  );

export const getStudents = ({
  id = 0,
  ...params
}: PaginationQueryDto<{
  id: number | string;
  batch: number | string;
}>) =>
  request<PaginationRequestType<InterimDegreeAuthorizedStudentMassive>>(
    INTERIM_DEGREE_GRADUATION_PROCESS_MASSIVE.STUDENTS(id),
    {
      params,
    },
  );

export const studentsDownload = ({
  id = 0,
  batch = 0,
}: {
  id: number | string;
  batch: number | string;
}) =>
  request(INTERIM_DEGREE_GRADUATION_PROCESS_MASSIVE.STUDENTS_DOWNLOAD(id), {
    responseType: 'blob',
    method: 'GET',
    params: { batch },
  });

/**
 * Obtiene los datos para filtrar los titulos intermedios
 */
export const getForm = () =>
  request<CurriculumForm>(INTERIM_DEGREE_GRADUATION_PROCESS_MASSIVE.FORM);
