import { Button, CellFormatOptions, ColumnTable } from '@octano/global-ui';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { TuitionContinuityProcessDetailStudentResponseDto } from '../../../api/requests/tuitionContinuityProcessManagement';
import { FetchContext } from '../../../components/contexts/FetchContext';
import { format as formatPercentage } from '../../../utils/percentage';
import TuitionContinuityProcessManagementDetailStudentsLoader from '../parts/TuitionContinuityProcessManagementDetailStudentsLoader';
import TuitionContinuityProcessManagementDetailTab from '../parts/TuitionContinuityProcessManagementDetailTab';
import TuitionContinuityProcessManagementDiscountModal from '../parts/TuitionContinuityProcessManagementDiscountModal';

export interface TuitionContinuityProcessManagementDetailNotStartedProps {
  studyPlanVersionOfferId: number | string;
  periodId: number | string;
  discounts: {
    id: number;
    name: string;
    code: string;
    description: string | null;
    percentage: number;
  }[];
}

export default function TuitionContinuityProcessManagementDetailNotStarted({
  studyPlanVersionOfferId,
  periodId,
  discounts,
}: TuitionContinuityProcessManagementDetailNotStartedProps) {
  const { t } = useTranslation();
  const [toDiscount, setToDiscount] = useState<{
    discount: any;
    studyPlanEnrollmentId: number;
  }>();

  const columns: ColumnTable<TuitionContinuityProcessDetailStudentResponseDto>[] = [
    {
      columnName: 'rut',
      headerText: t(`common.forms.rut`),
      width: '110px',
      tdClassName: 'text-center',
      thClassName: 'text-center',
      bold: true,
    },
    {
      columnName: 'names',
      headerText: t(`common.forms.name`),
      width: '320px',
      tdClassName: 'text-center',
      thClassName: 'text-center',
      cellFormat: (options: CellFormatOptions) =>
        `${options.row['lastNames']}, ${options.row['names']}`,
    },
    {
      columnName: 'cellPhone',
      headerText: t(`common.forms.phone`),
      width: '150px',
      tdClassName: 'text-center',
      thClassName: 'text-center',
    },
    {
      columnName: 'email',
      headerText: t(`common.forms.email`),
      width: '150px',
      tdClassName: 'text-center',
      thClassName: 'text-center',
    },
    {
      columnName: 'discountPercentage',
      headerText: t(`common.forms.discount`),
      width: '150px',
      tdClassName: 'text-center',
      thClassName: 'text-center',
      cellFormat: (
        options: CellFormatOptions<TuitionContinuityProcessDetailStudentResponseDto>,
      ) => formatPercentage(options.row.discountPercentage ?? 0),
    },
    {
      columnName: 'setDiscount',
      headerText: t(`common.forms.discount`),
      width: '150px',
      tdClassName: 'text-center',
      thClassName: 'text-center',
      cellFormat: (
        options: CellFormatOptions<TuitionContinuityProcessDetailStudentResponseDto>,
      ) => (
        <Button
          text={t(`tuitionContinuityProcessManagementDetail.discount.button`)}
          size="sm"
          onClick={() => {
            setToDiscount({
              discount: options.row.discounts.length
                ? options.row.discounts[0]
                : undefined,
              studyPlanEnrollmentId: options.row.id,
            });
          }}
        />
      ),
    },
  ];

  return (
    <>
      <TuitionContinuityProcessManagementDetailStudentsLoader
        studyPlanVersionOfferId={studyPlanVersionOfferId}
        periodId={periodId}
        status="notStarted"
      >
        <TuitionContinuityProcessManagementDetailTab columns={columns} />
        {toDiscount !== undefined && (
          <FetchContext.Consumer>
            {({ query, updateQuery }) => (
              <TuitionContinuityProcessManagementDiscountModal
                studyPlanEnrollmentId={toDiscount.studyPlanEnrollmentId}
                show
                periodId={periodId}
                discount={toDiscount.discount}
                discounts={discounts}
                onComplete={(completed) => {
                  if (completed) {
                    updateQuery({ ...query });
                  }
                  setToDiscount(undefined);
                }}
              />
            )}
          </FetchContext.Consumer>
        )}
      </TuitionContinuityProcessManagementDetailStudentsLoader>
    </>
  );
}
