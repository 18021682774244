import {
  DocumentReviewStudentDocumentOtherUploadResponseDto,
  DocumentReviewStudentDocumentResponseDto,
} from '../../types/tuitionContinuityDocumentReviewDocuments';
import { TUITION_CONTINUITY_DOCUMENT_REVIEW } from '../endpoints';
import request from '../request';

export async function getTcDocumentReviewDocumentsOthersUrl(
  documentId: number | string,
) {
  return request<DocumentReviewStudentDocumentResponseDto>(
    TUITION_CONTINUITY_DOCUMENT_REVIEW.DOCUMENT_OTHER(documentId),
    {
      method: 'get',
    },
  );
}

export async function deleteTcDocumentReviewDocumentOther(
  documentId: number | string,
) {
  return request(
    TUITION_CONTINUITY_DOCUMENT_REVIEW.DOCUMENT_OTHER_DELETE(documentId),
    { method: 'delete' },
  );
}

export async function uploadTcDocumentReviewDocumentsOthers(
  tcProcessId: string,
  {
    file,
    description,
  }: {
    file: File;
    description: string;
  },
) {
  const formData = new FormData();
  formData.append('doc', file);
  formData.append('description', description);
  return request<DocumentReviewStudentDocumentOtherUploadResponseDto>(
    TUITION_CONTINUITY_DOCUMENT_REVIEW.DOCUMENT_OTHER_UPLOAD(tcProcessId),
    {
      method: 'post',
      data: formData,
    },
  );
}
