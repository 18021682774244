import { PaginationRequestType } from '../../types/paginationRequestType';
import { ACADEMIC_STRUCTURE } from '../endpoints';
import request from '../request';

export type AcademicLevelListItem = {
  id: number;
  name: string;
};

export type SchoolsListItem = {
  id: number;
  name: string;
  code: string;
  description?: string | null;
  quota?: string | null;
  enrolled?: string | null;
  duplicatedQuota?: string | null;
};

export type SchoolItemBody = {
  id?: number;
  name: string;
  code: string;
  description?: string | null;
};

export type SchoolsListResponse = PaginationRequestType<SchoolsListItem>;

/**
 * Obtiene el listado de facultades
 */
export const getSchoolsRequest = (items: number = 10, page: number = 0) => {
  return request<SchoolsListResponse>(`${ACADEMIC_STRUCTURE.SCHOOLS}`, {
    params: { items_per_page: items, page },
  });
};

/**
 *  Crear o editar una facultad
 */
export const saveSchoolRequest = (data: SchoolItemBody) => {
  const isEdit = !!data.id;
  return request<SchoolsListItem>(
    `${ACADEMIC_STRUCTURE.SCHOOLS}/${isEdit ? data.id : ''}`,
    {
      method: isEdit ? 'put' : 'post',
      data,
    },
  );
};
