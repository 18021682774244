import { useTranslation } from 'react-i18next';
import { Col, Container, Row } from 'reactstrap';
import { format } from 'rut.js';

import { moneyFormatter } from '../../../../../../../../utils/currency';
import PaymentDetailsItem from './PaymentDetailsItem';

interface Transaction {
  id: string;
  label?: string;
  detail: string;
  amount: number;
}

interface IPaymentDetailsProps {
  postulant: {
    name: string;
    rut?: string;
    passport?: string;
  };
  sustainer?: {
    name: string;
    rut?: string;
    passport?: string;
  };
  transactions?: Transaction[];
}

export default function PaymentDetails({
  sustainer,
  postulant,
  transactions = [],
}: IPaymentDetailsProps) {
  const prefix = 'tuitionProcessNoSua.payment.details';
  const { t } = useTranslation();

  return (
    <Container>
      <Row>
        <Col className="border border-primary rounded p-3 mb-3" xs={12}>
          <Container>
            <Row>
              <Col className="mb-3" xs={12} lg={6}>
                <PaymentDetailsItem
                  title={t(`${prefix}.postulantName`)}
                  value={postulant?.name}
                />
              </Col>
              <Col className="mb-3" xs={12} lg={6}>
                <PaymentDetailsItem
                  title={t(
                    postulant?.rut
                      ? `${prefix}.postulantRut`
                      : `${prefix}.postulantPassport`,
                  )}
                  value={
                    postulant?.rut
                      ? format(postulant?.rut)
                      : postulant?.passport ?? ''
                  }
                />
              </Col>
            </Row>
          </Container>
        </Col>
        <Col className="border border-primary rounded p-3 mb-3" xs={12}>
          <Container>
            <Row>
              {!!sustainer && (
                <Col className="mb-3" xs={12} lg={6}>
                  <PaymentDetailsItem
                    title={t(`${prefix}.sustainerName`)}
                    value={sustainer?.name}
                  />
                </Col>
              )}
              {!!sustainer && (
                <Col className="mb-3" xs={12} lg={6}>
                  <PaymentDetailsItem
                    title={t(
                      sustainer?.rut
                        ? `${prefix}.sustainerRut`
                        : `${prefix}.sustainerPassport`,
                    )}
                    value={
                      sustainer?.rut
                        ? format(sustainer?.rut)
                        : sustainer?.passport ?? ''
                    }
                  />
                </Col>
              )}
            </Row>
          </Container>
        </Col>
        {!!transactions?.length && (
          <Col className="border border-primary rounded p-3 mb-3" xs={12}>
            <Container>
              {transactions?.map((item, i) => (
                <Row className={i > 0 ? 'mt-3' : ''} key={i?.toString()}>
                  <Col xs={12}>
                    <PaymentDetailsItem
                      title={t(`${prefix}.salenoteDetail`)}
                      value={t(
                        `${prefix}.salenoteDetails.${
                          item?.label ?? item?.detail
                        }`,
                      )}
                    />
                  </Col>
                  <Col className="mt-3" xs={12} lg={6}>
                    <PaymentDetailsItem
                      title={t(`${prefix}.salenoteId`)}
                      value={`${item?.id}`}
                    />
                  </Col>
                  <Col className="mt-3" xs={12} lg={6}>
                    <PaymentDetailsItem
                      title={t(`${prefix}.salenoteAmount`)}
                      value={moneyFormatter().format(item?.amount)}
                    />
                  </Col>
                </Row>
              ))}
            </Container>
          </Col>
        )}
      </Row>
    </Container>
  );
}
