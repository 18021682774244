import { ColumnTable, DisplayInfo, Table, Button } from '@octano/global-ui';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';

import { useMemo } from 'react';
import { InterimDegree } from '../../academicOffers/InterimDegree/interfaces/interimDegree.interface';

const dictPrefix = 'interimDegreeGraduationProcess.massive';

interface Props {
  data: InterimDegree[];
  isLoadingResults: boolean;
  hasFilters: boolean;
  itemPerPage: number;
  totalItems: number;
  totalPages: number;
  currentPage: number;
  onChangePage: (page: number) => void;
  onPressGenerate?: (id: number) => void;
}

const getLastBatch = (
  interimDegreeAuthorizedStudents: { batch: number; createdAt: string }[] = [],
) => {
  if (interimDegreeAuthorizedStudents.length) {
    interimDegreeAuthorizedStudents.sort((a, b) => b.batch - a.batch);
    return dayjs(interimDegreeAuthorizedStudents[0].createdAt).format(
      'DD-MM-YYYY',
    );
  }
  return '-';
};

export function InterimDegressTable({
  data,
  isLoadingResults,
  hasFilters,
  itemPerPage,
  totalPages,
  totalItems,
  currentPage,
  onChangePage,
  onPressGenerate,
}: Props) {
  const { t } = useTranslation();

  const columns = useMemo(() => {
    const cols: ColumnTable<InterimDegree>[] = [
      {
        headerText: t(`${dictPrefix}.table.studyPlan`),
        columnName: 'curriculum',
        tdClassName: 'text-center',
        thClassName: 'text-center',
        cellFormat: ({ value }) => value?.studyPlanVersion?.studyPlan?.name,
      },
      {
        headerText: t(`${dictPrefix}.table.interimDegree`),
        columnName: 'name',
        tdClassName: 'text-center',
        thClassName: 'text-center',
      },
      {
        headerText: t(`${dictPrefix}.table.lastAuthorizationBatch`),
        columnName: 'interimDegreeAuthorizedStudents',
        tdClassName: 'text-center',
        thClassName: 'text-center',
        cellFormat: ({ value }) => getLastBatch(value),
      },
      {
        headerText: t(`${dictPrefix}.table.action`),
        columnName: 'id',
        tdClassName: 'text-center',
        thClassName: 'text-center',
        width: 180,
        cellFormat: ({ value }) => (
          <>
            <Button
              text={t(`${dictPrefix}.actions.generateAuthorizations`)}
              type="button"
              size="sm"
              onClick={() => !!onPressGenerate && onPressGenerate(value)}
            />
          </>
        ),
      },
    ];
    return cols;
  }, [t, onPressGenerate]);

  return (
    <div className="w-100">
      <Table
        isLoadingResults={isLoadingResults}
        data={data}
        columns={columns}
        pagination={{
          currentPage: currentPage + 1,
          itemsPerPage: itemPerPage,
          totalItems: totalItems,
          totalPages: totalPages,
          onChangePage: (page) => onChangePage(page - 1),
        }}
        noResultsText={
          <div className="d-flex justify-content-center align-items-center py-5">
            <DisplayInfo
              iconName="information"
              textBody={t(
                `${dictPrefix}.${
                  hasFilters ? 'noResultsFilter' : 'noResults'
                }.description`,
              )}
              maxWidth="650px"
            />
          </div>
        }
      />
    </div>
  );
}
