import { Modal } from '@octano/global-ui';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Card } from 'reactstrap';

import CreateRenditionForm from './CreateRenditionForm';

interface CreateRenditionModalProps {
  show: boolean;
  toggleModal: () => void;
  onSave?: (requestBody?: any) => void;
  activityId: string | number;
}

const dictPrefix = 'studentsDegreeStudent';

export const CreateRenditionModal = ({
  show,
  toggleModal,
  onSave = () => null,
  activityId,
}: CreateRenditionModalProps) => {
  const { t } = useTranslation();
  const texts = useMemo(
    () => ({
      title: t(`${dictPrefix}.tableRenditions.modal.title`),
      subTitle: t(`${dictPrefix}.tableRenditions.modal.subTitle`),
      modal: t<
        string,
        Record<
          | 'title'
          | 'subTitle'
          | 'activityName'
          | 'renditionDate'
          | 'approved'
          | 'approvedPlaceholder'
          | 'grade'
          | 'gradeMax'
          | 'comment',
          string
        >
      >(`${dictPrefix}.tableRenditions.modal`, {
        returnObjects: true,
      }),
      badgeState: t<string, Record<'approved' | 'failed', string>>(
        `${dictPrefix}.tableRenditions.badgeState`,
        {
          returnObjects: true,
        },
      ),
      action: {
        send: t(`${dictPrefix}.tableRenditions.btn.add`),
        cancel: t(`${dictPrefix}.tableRenditions.btn.cancel`),
        addRendition: t(`${dictPrefix}.tableRenditions.btn.addRendition`),
      },
    }),
    [t],
  );

  return (
    <Modal isOpen={show} toggle={toggleModal} size="lg" unmountOnClose>
      <Card className="align-items-center font-600 mb-4">
        <h3>{texts.title}</h3>
        <p>{texts.subTitle}</p>
      </Card>

      <section className="petition-form mb-4">
        <CreateRenditionForm
          texts={texts}
          onSave={onSave}
          onCancel={toggleModal}
          activityId={activityId}
        />
      </section>
    </Modal>
  );
};
