import {
  Button,
  ColumnTable,
  Table,
  TextOutlinedInput,
} from '@octano/global-ui';
import clsx from 'clsx';
import { useCallback, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Accordion } from '../../../../../components/accordion/Accordion';
import {
  StudyPlanVersion,
  StudyPlanVersionOffer,
} from '../../../../../types/studyPlan';
import { moneyFormatter } from '../../../../../utils/currency';
import CustomBadge from './TariffCustomBadge';

/**
 * Interface para los inputs del formulario
 * nuevos valores son string
 * valores de la bd son number
 */

interface IForm {
  [key: string]: string;
}

export default function TariffAccordion({
  row,
  formData,
}: {
  row: StudyPlanVersion;
  formData: (details: any[]) => void;
}) {
  const { t } = useTranslation();
  const { handleSubmit, control } = useForm<IForm>({ mode: 'onChange' });

  const onSubmit = useCallback(
    async (data: Record<string, string>) => {
      /**
       * Los names de los formularios contienen el academicOfferStudyPlanVersionOfferId (offerId).
       * el valor es parseado y transformado en objetos. Lugo acomodamos la data al formato deseado.
       */
      const chackTariffValue = (tariffValue: string): number | null => {
        return tariffValue === ''
          ? null
          : Number(tariffValue.replace(/\D/g, ''));
      };

      formData(
        Object.entries(data).map(([inputName, tariffValue]) => {
          const values = inputName.replace(/-/g, '"');
          const { offerId } = JSON.parse('{' + values + '}');

          const offer = row.offers.find((myOffer) => myOffer.id === offerId);

          return {
            tariffId: offer!.currentTariff?.id,
            tariffValue: chackTariffValue(tariffValue),
            academicOfferStudyPlanVersionOfferId: offer!
              .academicOfferStudyPlanVersionOfferId,
            studyPlanName: row.name,
            campusName: offer!.campus!.name,
            scheduleName: offer!.schedule!.name,
          };
        }),
      );
    },
    [formData, row],
  );

  const printClassName = (
    index: number,
    data: Partial<StudyPlanVersionOffer>[],
    className?: string,
  ) => {
    return clsx(className, index === data.length - 1 && 'bg-white');
  };

  const columns: ColumnTable<Partial<StudyPlanVersionOffer>>[] = [
    {
      columnName: 'campusName',
      bold: false,
      headerText: t('tariffAndTuition.tariff.table.campus'),
      width: '20%',
      tdClassName: ({ index, data }) => printClassName(index, data!),
      cellFormat: (options) => {
        return <p>{options.row.campus?.name}</p>;
      },
    },
    {
      columnName: 'schedule',
      headerText: t('tariffAndTuition.tariff.table.schedule'),
      width: '20%',
      tdClassName: ({ index, data }) => printClassName(index, data!),
      cellFormat: (options) => {
        return <p>{options.row.schedule?.name}</p>;
      },
    },
    {
      columnName: 'previousTariff',
      headerText: t('tariffAndTuition.tariff.table.previousTariff'),
      width: '20%',
      tdClassName: ({ index, data }) => printClassName(index, data!),
      cellFormat: ({ row: offer }) => {
        const tariffFormatter = moneyFormatter(
          offer.oldTariff?.currency?.locale,
          offer.oldTariff?.currency?.currency,
        );

        return (
          <p>
            {offer.oldTariff?.id
              ? tariffFormatter.format(offer.oldTariff.value)
              : ''}
          </p>
        );
      },
    },
    {
      columnName: 'newTariff',
      headerText: t('tariffAndTuition.tariff.table.newTariff'),
      tdClassName: ({ index, data }) => {
        return printClassName(index, data!, 'd-flex justify-content-center');
      },
      cellFormat: ({ row: offer, index, data }) => {
        const currentTariff = offer.currentTariff?.value;

        const inputName = `-offerId-:${offer.id}`;
        if (data!.length - 1 === index) {
          return (
            <Button
              type="submit"
              text={t('tariffAndTuition.tariff.form.submitButton')}
              size="sm"
            />
          );
        }

        return (
          <div style={{ maxWidth: 144 }}>
            <TextOutlinedInput
              textAlign="center"
              name={inputName}
              defaultValue={
                typeof currentTariff === 'number'
                  ? moneyFormatter().format(currentTariff)
                  : ''
              }
              formatter={formatter}
              rules={{
                validate: {
                  checkprevius: (value: string) => {
                    if (
                      value.replace(/\D/g, '') === '' &&
                      typeof offer.currentTariff?.id === 'number'
                    ) {
                      return 'El campo es obligatorio';
                    }
                    return undefined;
                  },
                },
              }}
              control={control}
              type="text"
            />
          </div>
        );
      },
    },
    {
      columnName: 'status',
      headerText: t('tariffAndTuition.tariff.table.status'),
      width: '20%',
      tdClassName: ({ index, data }) =>
        printClassName(index, data!, 'text-center'),
      cellFormat: ({ row: offer, index, data }) => {
        return (
          index !== data!.length - 1 && (
            <CustomBadge value={offer.currentTariff?.value} />
          )
        );
      },
    },
  ];

  const formatter = (value: string) => {
    if (value === '$') return '';
    return moneyFormatter().format(Number(value.replace(/\D/g, '')));
  };

  const badgeValue = useMemo(
    () =>
      row.offers.some(
        (offer) => typeof offer.currentTariff?.value !== 'number',
      ),
    [row.offers],
  );

  return (
    <form onSubmit={handleSubmit(onSubmit)} key={row.id}>
      <Accordion open={1}>
        <Accordion.Item>
          <Accordion.Header>
            <p className="fs-14 text-dark fw-400">{row.name}</p>
            <CustomBadge value={badgeValue ? undefined : 1} />
          </Accordion.Header>
          <Accordion.Body>
            <Table
              columns={columns}
              data={[...row.offers, {}]}
              striped
              color="transparent"
              borderless={false}
            />
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </form>
  );
}
