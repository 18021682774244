export interface PersonalBackgroundFormDataGeneric {
  id: number;
  name: string;
}

export interface PersonalBackgroundFormDataResponse {
  photo?: string;
  countries: PersonalBackgroundFormDataGeneric[];
  genders: PersonalBackgroundFormDataGeneric[];
  maritalStatus: PersonalBackgroundFormDataGeneric[];
  regions: PersonalBackgroundFormDataGeneric[];
  cities: PersonalBackgroundFormDataGeneric[];
  communes: { id: number; name: string; regionId: number }[];
  healthSystems: PersonalBackgroundFormDataGeneric[];
  healthInstitutions: PersonalBackgroundFormDataGeneric[];
  responses: Record<string, any>;
}

export enum healthSystemsCodes {
  Private = 'PV', // en chile equivalente a isapre
  Public = 'PU', // en chile equivalente a fonasa
  Mixed = 'MI', // en chile equivalente fuerzas armadas u otro sistema mixto
}
