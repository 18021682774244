import {
  OutlinedSelect,
  SelectOptionType,
  TextOutlinedInput,
  Button,
} from '@octano/global-ui';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'reactstrap';
import { useCallback } from 'react';

import { InterimDegreeAuthorizedStudentFilters } from '../interfaces/interim-degree-authorized-student-filters.interface';

type FormValues = {
  search: string | null;
  school: SelectOptionType | null;
  studyPlan: SelectOptionType | null;
  interimDegree: SelectOptionType | null;
  enrollmentPeriod: SelectOptionType | null;
  period: SelectOptionType | null;
  degreePeriod: SelectOptionType | null;
};

const DEFAULT_FORM_VALUES: FormValues = {
  search: '',
  school: null,
  studyPlan: null,
  interimDegree: null,
  enrollmentPeriod: null,
  period: null,
  degreePeriod: null,
};

const dictPrefix = 'interimDegreeGraduationProcess.students.searchControls';

interface Props {
  schools?: SelectOptionType[];
  studyPlans?: SelectOptionType[];
  periods?: SelectOptionType[];
  interimDegrees?: SelectOptionType[];
  loading?: boolean;
  usesDegreePeriod?: boolean;
  onSearch?: (filters: InterimDegreeAuthorizedStudentFilters) => void;
  onDownload?: () => void;
}

export function InterimDegreesStudentsSearchControls({
  schools = [],
  studyPlans = [],
  periods = [],
  interimDegrees = [],
  loading = false,
  usesDegreePeriod = false,
  onSearch,
  onDownload,
}: Props) {
  const { t } = useTranslation();

  const { control, handleSubmit, reset } = useForm<FormValues>({
    defaultValues: DEFAULT_FORM_VALUES,
  });

  const handleSearch = useCallback(
    (values: FormValues) => {
      if (loading) {
        return;
      }
      !!onSearch &&
        onSearch({
          search: values?.search?.trim() ?? undefined,
          studyPlanId: values?.studyPlan?.value ?? undefined,
          interimDegreeId: values?.interimDegree?.value ?? undefined,
          enrollmentPeriodId: values?.enrollmentPeriod?.value ?? undefined,
          schoolId: values?.school?.value ?? undefined,
          periodId: values?.period?.value ?? undefined,
          degreePeriodId: usesDegreePeriod
            ? values?.degreePeriod?.value ?? undefined
            : undefined,
        });
    },
    [onSearch, loading, usesDegreePeriod],
  );

  const handleClear = useCallback(() => {
    if (loading) {
      return;
    }
    reset(DEFAULT_FORM_VALUES);
    !!onSearch && onSearch({});
  }, [onSearch, reset, loading]);

  return (
    <Row className="mb-5">
      <Col xs={12} sm={6} md={6} lg={4}>
        <TextOutlinedInput
          control={control}
          name="search"
          label={t(`${dictPrefix}.search.label`)}
          placeholder={t(`${dictPrefix}.search.placeholder`)}
          disabled={loading}
        />
      </Col>
      <Col xs={12} sm={6} md={6} lg={4}>
        <OutlinedSelect
          control={control}
          name="studyPlan"
          label={t(`${dictPrefix}.studyPlan.label`)}
          placeholder={t(`${dictPrefix}.studyPlan.placeholder`)}
          options={studyPlans ?? []}
          disabled={!studyPlans?.length || loading}
        />
      </Col>
      <Col xs={12} sm={6} md={6} lg={4}>
        <OutlinedSelect
          control={control}
          name="interimDegree"
          label={t(`${dictPrefix}.interimDegree.label`)}
          placeholder={t(`${dictPrefix}.interimDegree.placeholder`)}
          options={interimDegrees ?? []}
          disabled={!interimDegrees?.length || loading}
        />
      </Col>
      <Col xs={12} sm={6} md={6} lg={4}>
        <OutlinedSelect
          control={control}
          name="enrollmentPeriod"
          label={t(`${dictPrefix}.enrollmentPeriod.label`)}
          placeholder={t(`${dictPrefix}.enrollmentPeriod.placeholder`)}
          options={periods ?? []}
          disabled={!periods?.length || loading}
        />
      </Col>
      <Col xs={12} sm={6} md={6} lg={4}>
        <OutlinedSelect
          control={control}
          name="school"
          label={t(`${dictPrefix}.school.label`)}
          placeholder={t(`${dictPrefix}.school.placeholder`)}
          options={schools ?? []}
          disabled={!schools?.length || loading}
        />
      </Col>
      <Col xs={12} sm={6} md={6} lg={4}>
        <OutlinedSelect
          control={control}
          name="period"
          label={t(`${dictPrefix}.period.label`)}
          placeholder={t(`${dictPrefix}.period.placeholder`)}
          options={periods ?? []}
          disabled={!periods?.length || loading}
        />
      </Col>
      {!!usesDegreePeriod && (
        <Col xs={12} sm={6} md={6} lg={4}>
          <OutlinedSelect
            control={control}
            name="degreePeriod"
            label={t(`${dictPrefix}.degreePeriod.label`)}
            placeholder={t(`${dictPrefix}.degreePeriod.placeholder`)}
            options={periods ?? []}
            disabled={!periods?.length || loading}
          />
        </Col>
      )}
      <Col
        xs={12}
        sm={usesDegreePeriod ? 6 : 12}
        md={usesDegreePeriod ? 6 : 12}
        lg={usesDegreePeriod ? 8 : 12}
      >
        <div className="d-flex flex-wrap w-100 align-items-center justify-content-center">
          <div className="flex-fill px-2" />
          <div>
            <div className="d-flex w-100 align-items-center" style={{ gap: 8 }}>
              <div className="flex-fill">
                <Button
                  size="md"
                  text={t(`${dictPrefix}.actions.search`)}
                  loading={loading}
                  onClick={handleSubmit(handleSearch)}
                  className="w-100"
                />
              </div>
              <div className="flex-fill">
                <Button
                  size="md"
                  text={t(`${dictPrefix}.actions.clear`)}
                  className="w-100"
                  loading={loading}
                  onClick={handleClear}
                  outlined
                />
              </div>
            </div>
            {!!onDownload && (
              <Button
                size="md"
                icon="download"
                text={t(`${dictPrefix}.actions.download`)}
                className="w-100 mt-2"
                loading={loading}
                onClick={onDownload}
                outlined
              />
            )}
          </div>
        </div>
      </Col>
    </Row>
  );
}
