import { Container } from 'reactstrap';
import HiringInformationTable from './parts/HiringInformationTable';
import HiringInformationFormDataLoader from './parts/Loaders/HiringInformationFormDataLoader';
import HiringInformationLoader from './parts/Loaders/HiringInformationLoader';
import { useParams } from 'react-router-dom';
import SectionsTable from './parts/SectionsTable';

function HiringInformation() {
  const { teacherId } = useParams<{ teacherId: string }>();

  return (
    <Container fluid className="mt-4">
      <HiringInformationFormDataLoader>
        <HiringInformationLoader teacherId={teacherId}>
          <>
            <HiringInformationTable />
            <SectionsTable />
          </>
        </HiringInformationLoader>
      </HiringInformationFormDataLoader>
    </Container>
  );
}

export default HiringInformation;
