import { INTERIM_DEGREE_GRADUATION_PROCESS_STUDENTS } from '../endpoints';
import request from '../request';
import {
  PaginationQueryDto,
  PaginationRequestType,
} from '../../types/paginationRequestType';
import { InterimDegreeAuthorizedStudentMassive } from '../../views/InterimDegreeGraduationProcess/interfaces/interim-degree-authorized-student-massive.interface';
import { InterimDegreeAuthorizedStudentFilters } from '../../views/InterimDegreeGraduationProcess/interfaces/interim-degree-authorized-student-filters.interface';
import { InterimDegreeStudentsForm } from '../../views/InterimDegreeGraduationProcess/interfaces/interim-degree-students-form.interface';

/**
 * Obtiene el listado de estudiantes habilitados o titulados
 */
export const getStudents = (
  params: PaginationQueryDto<InterimDegreeAuthorizedStudentFilters>,
) =>
  request<PaginationRequestType<InterimDegreeAuthorizedStudentMassive>>(
    INTERIM_DEGREE_GRADUATION_PROCESS_STUDENTS.STUDENTS,
    {
      params,
    },
  );

export const studentsDownload = (
  params: InterimDegreeAuthorizedStudentFilters,
) =>
  request(INTERIM_DEGREE_GRADUATION_PROCESS_STUDENTS.STUDENTS_DOWNLOAD, {
    responseType: 'blob',
    method: 'GET',
    params,
  });

/**
 * Obtiene los datos para filtrar los estudiantes
 */
export const getForm = () =>
  request<InterimDegreeStudentsForm>(
    INTERIM_DEGREE_GRADUATION_PROCESS_STUDENTS.FORM,
  );

/**
 * Titula al estudiante
 */
export const updateToGraduated = (
  id: number | string,
  data: {
    degreeDistinctionRankId: string | number;
    degreeGrade: string;
    degreeDate: string | Date;
  },
) =>
  request<InterimDegreeStudentsForm>(
    INTERIM_DEGREE_GRADUATION_PROCESS_STUDENTS.UPDATE_TO_GRADUATED(id),
    {
      data,
      method: 'put',
    },
  );
