import { Icon } from '@octano/global-ui';

export interface PackagesViewSectionsDetailsHeaderProps {
  text: string;
  goBackAction: () => void;
}

const PackagesViewSectionsDetailsHeader = ({
  text,
  goBackAction,
}: PackagesViewSectionsDetailsHeaderProps) => {
  return (
    <div className="px-2 d-flex justify-content-between">
      <div
        className="cursor-pointer align-items-center"
        onClick={goBackAction}
        style={{ width: 'fit-content' }}
      >
        <Icon name="chevron_left" color="inherit" size={12} />
        <span className="pl-2">{text}</span>
      </div>
    </div>
  );
};

export default PackagesViewSectionsDetailsHeader;
