import { Button, Icon, Modal } from '@octano/global-ui';
import { forwardRef, useImperativeHandle, useState } from 'react';

interface Props {
  onConfirm: (teacherId: number) => void;
  onDecline?: () => void;
}

export interface ConfirmModalRef {
  openModal: (teacherId: number) => void;
  closeModal: () => void;
}

const ConfirmModal = forwardRef<ConfirmModalRef, Props>(
  ({ onConfirm, onDecline }, ref) => {
    const [teacherId, setTeacherId] = useState<number>();

    const [modal, setModal] = useState(false);

    useImperativeHandle(ref, () => ({
      openModal(teacherId: number) {
        setTeacherId(teacherId);
        setModal(true);
      },
      closeModal() {
        setModal(false);
      },
    }));

    return (
      <Modal isOpen={modal} toggle={() => setModal(!modal)} size="lg">
        <div className="text-center" style={{ padding: '20px' }}>
          <Icon name="information" size={45} />
          <h1 className="text-dark fs-20 mt-3 mb-4">
            Completar ficha del docente
          </h1>

          <p className="fs-16 lh-30 text-center">
            Puedes ingresar la ficha ahora o hacerlo más tarde, desde el módulo
            "Ficha docente".
          </p>

          <div className="d-flex justify-content-around">
            <Button
              outlined
              text="COMPLETAR MÁS TARDE"
              onClick={() => {
                onDecline && onDecline();
                setModal(false);
              }}
            />
            <Button
              text="COMPLETAR FICHA AHORA"
              onClick={() => {
                onConfirm(teacherId!);
                setModal(false);
              }}
            />
          </div>
        </div>
      </Modal>
    );
  },
);

export default ConfirmModal;
