import { Modal } from '@octano/global-ui';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Card } from 'reactstrap';

import { CreateActivityParams } from '../../types';
import CreateActivityForm from './CreateActivityForm';

interface CreateActivityModalProps {
  show: boolean;
  toggleModal: () => void;
  onSave?: (requestBody?: CreateActivityParams) => Promise<void>;
}

const dictPrefix = 'studyPlans.curriculum.degreeActivities.modal';

export const CreateActivityModal = ({
  show,
  toggleModal,
  onSave = async () => {},
}: CreateActivityModalProps) => {
  const { t } = useTranslation();
  const texts = useMemo(
    () => ({
      modal: t<
        string,
        Record<
          | 'title'
          | 'subtitle'
          | 'activityName'
          | 'activityDescription'
          | 'activityCode'
          | 'activityAllowGrades',
          string
        >
      >(`${dictPrefix}`, {
        returnObjects: true,
      }),
      action: {
        send: t(`${dictPrefix}.btn.addActivity`),
        cancel: t(`${dictPrefix}.btn.cancel`),
      },
    }),
    [t],
  );

  return (
    <Modal isOpen={show} toggle={toggleModal} size="lg" unmountOnClose>
      <Card className="align-items-center font-600 mb-4">
        <h3>{texts.modal.title}</h3>
        <p>{texts.modal.subtitle}</p>
      </Card>

      <section className="petition-form mb-4">
        <CreateActivityForm
          texts={texts}
          onSave={onSave}
          onCancel={toggleModal}
        />
      </section>
    </Modal>
  );
};
