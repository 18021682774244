import { SelectOptionType } from '@octano/global-ui';
import { useCallback } from 'react';

import { getCoursesRequest } from '../../views/academicOffers/courses/api';

export const useSearchCourse = () => {
  return useCallback(async (searchText: string): Promise<
    SelectOptionType[]
  > => {
    const { data } = await getCoursesRequest(searchText);
    if (data) {
      return data.map((course) => ({
        value: course.id,
        label: `${course.name} - ${course.school.name}`,
        id: course.id,
        name: course.name,
        shortening: course.shortening,
        credits: course.credits,
      }));
    }
    return [];
  }, []);
};
