import {
  DocumentReviewStudentDocumentResponseDto,
  DocumentReviewStudentDocumentUploadResponseDto,
  StudentDocumentReviewInfResponseDto,
} from '../../types/tuitionContinuityDocumentReviewDocuments';
import { TUITION_CONTINUITY_DOCUMENT_REVIEW } from '../endpoints';
import request from '../request';

export async function getTcDocumentReviewDocuments(
  tcProcessId: number | string,
) {
  return request<StudentDocumentReviewInfResponseDto>(
    TUITION_CONTINUITY_DOCUMENT_REVIEW.STUDENT(tcProcessId),
    {
      method: 'get',
    },
  );
}

export async function getTcDocumentReviewDocumentsUrl(
  documentId: number | string,
) {
  return request<DocumentReviewStudentDocumentResponseDto>(
    TUITION_CONTINUITY_DOCUMENT_REVIEW.DOCUMENT(documentId),
    {
      method: 'get',
    },
  );
}

export async function updateTcDocumentReviewDocumentStatus(
  documentId: number | string,
  status: string,
) {
  return request<DocumentReviewStudentDocumentResponseDto>(
    TUITION_CONTINUITY_DOCUMENT_REVIEW.DOCUMENT_CHANGE_STATUS(documentId),
    {
      method: 'put',
      data: { status },
    },
  );
}

export async function deleteTcDocumentReviewDocument(
  documentId: number | string,
) {
  return request(
    TUITION_CONTINUITY_DOCUMENT_REVIEW.DOCUMENT_DELETE(documentId),
    { method: 'delete' },
  );
}

export async function uploadTcDocumentReviewDocuments(
  tcProcessId: string,
  {
    file,
    type,
  }: {
    file: File;
    type: string;
  },
) {
  const formData = new FormData();
  formData.append('doc', file);
  formData.append('type', type);
  return request<DocumentReviewStudentDocumentUploadResponseDto>(
    TUITION_CONTINUITY_DOCUMENT_REVIEW.DOCUMENT_UPLOAD(tcProcessId),
    {
      method: 'post',
      data: formData,
    },
  );
}
