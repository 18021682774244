import { Box, Button, Icon, Modal, addToast } from '@octano/global-ui';
import React, { useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { saveTariffRequest } from '../../api/tariff';

interface ITariffModalProps {
  formData: any[];
  setFormData: React.Dispatch<React.SetStateAction<any[]>>;
  getStudyplanOffers: () => Promise<void>;
}

export function TariffConfirmationModal({
  formData,
  setFormData,
  getStudyplanOffers,
}: ITariffModalProps) {
  const { t } = useTranslation();

  const pendingTariff = useMemo(
    () =>
      formData.filter(
        (value) =>
          typeof value.tariffValue === 'object' && value.tariffValue === null,
      ),
    [formData],
  );

  const onSubmit = useCallback(async () => {
    const values = formData.filter(
      (value) =>
        typeof value.tariffValue !== 'object' && value.tariffValue !== null,
    );
    if (values.length > 0) {
      const result = await saveTariffRequest({
        academicOfferTariffs: values,
      });

      if (result.error && result.error.code === 'HTTP_ERROR') {
        addToast({
          icon: 'error',
          color: 'danger',
          text: t('common.errors.save'),
        });
      }
      if (result.data) {
        addToast({
          icon: 'success',
          color: 'success',
          text: 'Cambios guardados exitosamente',
        });
        getStudyplanOffers();
      }
    }
    setFormData([]);
  }, [t, formData, setFormData, getStudyplanOffers]);

  useEffect(() => {
    if (formData.length > 0 && pendingTariff.length === 0) onSubmit();
  }, [formData, pendingTariff, onSubmit]);

  return (
    <Modal
      isOpen={pendingTariff.length > 0}
      size="md"
      toggle={() => null}
      unmountOnClose
    >
      <div className="text-center">
        <Icon name="warning" size="52px" />
        <p className="mt-5 mb-2 fs-22 text-dark">
          {t(`tariffAndTuition.tariff.modal.title`)}
        </p>
        <p className="fs-18 mb-5">
          {t(`tariffAndTuition.tariff.modal.subTitle`)}
        </p>

        <h5 className="fs-12 text-medium text-left mb-1">
          {t(`tariffAndTuition.tariff.modal.description`)}
        </h5>
        <Box
          fullwidth
          color="primary"
          body={
            <ul className="px-3 text-left text-dark fs-14 py-0">
              {pendingTariff.map(
                ({ studyPlanName, campusName, scheduleName }, index) => (
                  <>
                    <li key={index.toString()}>
                      {t('tariffAndTuition.tariff.modal.studyPlan')}:{' '}
                      <strong>{studyPlanName}</strong>
                      <ul>
                        <li>
                          {t('tariffAndTuition.tariff.modal.schedule')}:{' '}
                          <strong>{scheduleName}</strong>
                        </li>
                        <li>
                          {t('tariffAndTuition.tariff.modal.campus')}:
                          <strong>{campusName}</strong>
                        </li>
                      </ul>
                    </li>
                    <br />
                  </>
                ),
              )}
            </ul>
          }
          style={{ height: 145, padding: '0.75rem 1rem', overflow: 'auto' }}
        />
        <div
          className="d-flex justify-content-between mt-5 mb-3"
          style={{ gap: '1.25rem' }}
        >
          <Button
            fullwidth
            text={t(`common.selectCareer.underStood`)}
            className="mt-0"
            onClick={() => {
              onSubmit();
            }}
          />
        </div>
      </div>
    </Modal>
  );
}
