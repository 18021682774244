import {
  PackagesListHeader,
  PackagesViewsSection,
  PackagesViewsStudent,
} from '../../types/packageTypes';
import { TimeModuleType } from '../../types/timeModuleTypes';
import { PACKAGES } from '../endpoints';
import request, { AxiosResult } from '../request';

export type PackagesListResponse = Array<PackagesListHeader>;

export interface PackagesViewResponse {
  id?: number;
  name?: string;
  studyPlan?: string;
  campus?: string;
  schedule?: string;
}

export interface Period {
  id: number;
  code: string;
  name: string;
  description: string;
  active: boolean;
}

/**
 * Obtiene el detalle de un paquete
 */
export async function getPackageDetail(
  packageId: number | string,
): Promise<AxiosResult<PackagesViewResponse>> {
  const url = PACKAGES.DETAIL;

  return request<PackagesViewResponse>(url, {
    params: {
      sectionPackagesId: packageId,
    },
    method: 'GET',
  });
}

/**
 * Obtiene los cursos del paquete
 */
export async function getPackageSections(packageId: number | string) {
  const url = PACKAGES.SECTIONS(packageId);

  return request<PackagesViewsSection[]>(url, {
    method: 'GET',
  });
}

/**
 * Obtiene el horario del paquete
 */
export async function getPackageTimeCalendar() {
  const url = PACKAGES.TIME_CALENDAR;

  return request<{ data: TimeModuleType[] }>(url, {
    method: 'GET',
  });
}

/**
 * Obtiene los estudiantes enrolados del paquete
 */
export async function getPackageEnrolledStudents(packageId: number | string) {
  const url = `${PACKAGES.ENROLLED_STUDENTS}/${packageId}`;

  return request<{ data: PackagesViewsStudent[] }>(url, {
    method: 'GET',
  });
}

/**
 * Obtiene los estudiantes pendientes de la oferta
 */
export async function getAcademicOfferStudyPlanVersionOffer(
  academicOfferStudyPlanVersionOfferId: number | string,
) {
  const url = `${PACKAGES.ACADEMIC_OFFER_STUDY_PLAN_VERSION_OFFER}`;

  return request<{
    campus: string;
    id: number;
    schedule: string;
    studyPlan: string;
  }>(url, {
    method: 'GET',
    params: {
      academicOfferStudyPlanVersionOffersId: academicOfferStudyPlanVersionOfferId,
    },
  });
}

/**
 * Obtiene los estudiantes pendientes de la oferta
 */
export async function getPackagePendingStudents(
  academicOfferStudyPlanVersionOffer: number | string,
  query: { page: number; totalPages: number } = { page: 1, totalPages: 10 },
) {
  const url = `${PACKAGES.PENDING_STUDENTS}/${academicOfferStudyPlanVersionOffer}`;

  return request<{
    data: PackagesViewsStudent[];
    total: number;
    total_pages: number;
  }>(url, {
    method: 'GET',
    params: {
      page: query.page - 1,
      items_per_page: query.totalPages,
    },
  });
}

/**
 * Inscribir alumnos en paquetes de secciones
 */
export const enrollStudentsRequest = (admissionOfferId: number) => {
  const url = PACKAGES.ENROLL_NEW_STUDENTS;
  const data = {
    admissionOfferId,
  };
  return request<any>(url, { method: 'POST', data });
};

export const getPackageFormData = () => {
  const url = PACKAGES.GET_SECTION_PACKAGE_FORM_DATA;
  return request<{ periods: Period[]; currentPeriod: Period }>(url, {
    method: 'get',
  });
};

export const getPackageOfSection = (periodId: number) => {
  const url = `${PACKAGES.GET_SECTION_PACKAGE}?periodId=${periodId}`;
  return request<PackagesListResponse>(url, {
    method: 'get',
  });
};
