import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Redirect, useParams } from 'react-router-dom';
import { Card, Col, Row } from 'reactstrap';

import GoBackToListButton from '../../../components/buttons/GoBackToListButton';
import { SectionTitle } from '../../../components/text';
import { PathsLayouts } from '../../../config/routes';
import { isNumber } from '../../../utils/math';
import BaseLoader from '../parts/BaseLoader';
import StudentBox from '../parts/StudentBox';
import TextBox from '../parts/TextBox';
import StudentPetitionInfo from './parts/StudentPetitionInfo';
import SummaryPetition, {
  SummaryPetitionProps,
} from './parts/StudentPetitionSummary';

const prefix = 'studentPetitionDetail.resolved';
export default function StudentsPetitionsResolvedDetail() {
  const { t } = useTranslation();
  const texts = useMemo(
    () => ({
      box: {
        student: t(`${prefix}.box.student`),
        studentId: t(`${prefix}.box.studentId`),
        email: t(`${prefix}.box.email`),
        phone: t(`${prefix}.box.phone`),
      },
      resumeTitle: t(`${prefix}.resume.title`),
      resumeSubtitle: t(`${prefix}.resume.subtitle`),
      resume: {
        ...t<string, SummaryPetitionProps['texts']>(`${prefix}.resume`, {
          returnObjects: true,
        }),
      },
      bodyTitle: t(`${prefix}.body.title`),
      body: {
        resolvedDateLabel: t(`${prefix}.body.resolvedDateLabel`),
        lastUpdateLabel: t(`${prefix}.body.lastUpdateLabel`),
        complementaryFileLabel: t(`${prefix}.body.complementaryFileLabel`),
        petitionStatusLabel: t(`${prefix}.body.petitionStatusLabel`),
        petitionCommentLabel: t(`${prefix}.body.petitionCommentLabel`),
      },
      download: {
        filesLabel: t(`${prefix}.body.complementaryFileLabel`),
        label: t(`${prefix}.body.complementaryFileLabel`),
        button: t(`${prefix}.downloads.downloadHistory`),
        failed: t(`${prefix}.downloads.downloadFailed`),
        success: t(`${prefix}.downloads.downloadSuccess`),
      },
    }),
    [t],
  );

  const { petitionId: petitionIdStr } = useParams<{ petitionId: string }>();
  const petitionId = useMemo(() => {
    if (isNumber(petitionIdStr)) {
      return Number(petitionIdStr);
    }
    return null;
  }, [petitionIdStr]);

  if (!petitionId) {
    return <Redirect to={PathsLayouts.studentPetitions} />;
  }

  return (
    <Card className="p-4 mt-2 mx-3">
      <BaseLoader petitionId={petitionId}>
        {(data) => (
          <>
            <section className="petition-info mb-4">
              <Row>
                <Col xs={12} sm={3}>
                  <GoBackToListButton
                    path={`${PathsLayouts.studentPetitions}/resolved`}
                  />
                </Col>
                <Col xs={12} sm={9}>
                  <StudentBox
                    studentName={data.student.fullName}
                    studentRut={data.student.run}
                    studentMail={data.email}
                    studentPhone={data.phone}
                    texts={texts.box}
                  />
                </Col>
              </Row>
              <br className="mt-4" />
            </section>

            <section className="petition-summary mb-4">
              <Row>
                <Col xs={12} sm={12}>
                  <div className="mb-3">
                    <SectionTitle text={texts.resumeTitle} />
                  </div>
                  <div className="mb-3">
                    <p>{texts.resumeSubtitle}</p>
                  </div>
                </Col>
                <Col xs={12} sm={12}>
                  <SummaryPetition
                    texts={texts.resume}
                    {...{
                      petitionTitle: data.title,
                      petitionType: data.type.name,
                      studyPlan: data.studyPlanVersion.name,
                    }}
                  />
                </Col>
              </Row>
            </section>

            <section className="petition-info mb-4">
              <Card className="petition-info__subject mb-3">
                <Row>
                  <Col md={12} className="mb-3">
                    <SectionTitle text={texts.bodyTitle} />
                  </Col>
                  <Col md={12} className="mb-3">
                    <TextBox text={data.description} />
                  </Col>
                  <Col md={12} className="mb-3">
                    <StudentPetitionInfo
                      onlyView={true}
                      texts={texts}
                      currentStatus={data.status}
                      lastUpdate={data.lastUpdate.comment}
                      lastUpdatedAt={data.lastUpdate.updatedAt}
                      downloadFile={
                        data?.pdfFile
                          ? {
                              name: data.linkedFile.name,
                              url: data.pdfFile.url,
                            }
                          : undefined
                      }
                      downloadFileResponse={
                        data?.fileResponse
                          ? {
                              name: data.linkedFileResponse.name,
                              url: data.fileResponse.url,
                            }
                          : undefined
                      }
                    />
                  </Col>
                </Row>
              </Card>
            </section>
          </>
        )}
      </BaseLoader>
    </Card>
  );
}
