import { ColumnTable } from '@octano/global-ui';
import { formatDate } from '../../../../../../utils/dates';
import { useTexts } from './textsContext';

export default function useColumns(
  actionsColumns: ColumnTable[],
): ColumnTable[] {
  const texts = useTexts();
  return [
    {
      columnName: 'position',
      headerText: texts.table.workExperience.columns.position,
      tdClassName: 'text-center',
      thClassName: 'text-center',
    },
    {
      columnName: 'areaSpecialty',
      headerText: texts.table.workExperience.columns.areaSpecialty,
      tdClassName: 'text-center',
      thClassName: 'text-center',
    },
    {
      columnName: 'institution',
      headerText: texts.table.workExperience.columns.institution,
      tdClassName: 'text-center',
      thClassName: 'text-center',
    },
    {
      columnName: 'startDate',
      headerText: texts.table.workExperience.columns.startDate,
      tdClassName: 'text-center',
      thClassName: 'text-center',
      cellFormat: (options) => formatDate(options.row?.startDate, 'DD/MM/YYYY'),
    },
    {
      columnName: 'endDate',
      headerText: texts.table.workExperience.columns.endDate,
      tdClassName: 'text-center',
      thClassName: 'text-center',
      cellFormat: (options) =>
        options.row?.isCurrent
          ? 'Indefinido.'
          : formatDate(options.row?.endDate, 'DD/MM/YYYY'),
    },
    {
      columnName: 'linkedFile',
      headerText: texts.table.workExperience.columns.linkedFile,
      tdClassName: 'text-center',
      thClassName: 'text-center',
      cellFormat: (options) =>
        options.row?.linkedFile ? `${options.row?.linkedFile?.name}` : '-',
    },
    ...actionsColumns,
  ];
}
